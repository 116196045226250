import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import homemade from '../../../assets/images/home-made.png';
import { createPaymentIntent, confirmPayment } from '../../../Config/stripeService';
import creditCard from '../../../assets/images/credit-cards.svg';
import paypal from '../../../assets/images/PayPal.svg';
import stripeImg from '../../../assets/images/Stripe.svg';
import arrowBtn from '../../../assets/images/arrow-btn.svg';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { postApi } from '../../../Services/apiService';
import backbutton from '../../../assets/images/back-btn-gray.svg'

function PaymentModal({ setFiltersModal, setSuccessModal, type, itemDetails, paymentDetails, addressDetailsToPayment,addressId }) {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [expiryError, setExpiryError] = useState('');
  const [isCardComplete, setIsCardComplete] = useState(false); // New state to track if card details are complete
  const [cardNumberError, setCardNumberError] = useState('');
  const [cvcError, setCvcError] = useState('');
  const [cardName, setCardName] = useState('');
  const [errors, setErrors] = useState({});


  const [attach, setAttach] = useState(0); // Initially set to 0, meaning checkbox is unchecked

  console.log('this is type', type, 'this is data', itemDetails,'this is payment details', paymentDetails, 'this is address details', addressDetailsToPayment);

  // const handlePay = () => {
  //   setFiltersModal(false); // Close PaymentModal
  //   setSuccessModal(true);  // Open CheckoutSuccess Modal
  // };



  const handlePay = async () => {
    if (!stripe || !elements) {
      setError('Payment system is not ready. Try again later.');
      return;
    }
  
    const validationErrors = {};
  
    if (type === 'billing' && !cardName.trim()) {
      validationErrors.cardName = 'Name on Card is required';
    }
  
    const cardNumberElement = elements.getElement(CardNumberElement);
    const expiryElement = elements.getElement(CardExpiryElement);
    const cvcElement = elements.getElement(CardCvcElement);
  
    if (!cardNumberElement || !expiryElement || !cvcElement) {
      setError('Please complete all card details.');
      return;
    }
  
    const cardNumberValidation = await stripe.createToken(cardNumberElement);
    if (cardNumberValidation.error) {
      validationErrors.cardNumberError = 'Invalid Card Number';
    }
  
    if (!expiryElement._complete) {
      validationErrors.expiryError = 'Invalid Expiry Date';
    }
  
    if (!cvcElement._complete) {
      validationErrors.cvcError = 'Invalid CVC';
    }
  
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
  
    setLoading(true);
    setError('');

    // if (type === 'offering') {
      try {
        // Get the card details from the individual elements
        const cardElement = elements.getElement(CardNumberElement); // CardNumberElement can be passed for token creation

        // Create a token using the card details
        const { token, error: tokenError } = await stripe.createToken(cardElement);

        if (tokenError) {
          setError(tokenError.message || 'Failed to generate token. Please try again.');
        } else {
          console.log('Generated token:', token); // Here, you would send the token to your backend
          const stripeData = {
            token: token.id,
            amount: paymentDetails?.price * 100,
            // amount: parseInt(itemDetails?.minimum_price || itemDetails?.price) * 100,
            currency: 'usd',
            connector_id:itemDetails?.user_id,
            // address_id:addressId ? addressId :null
          }
          // Conditionally add the 'attach' field if it's 1
          if (attach === 1) {
            stripeData.attach = attach;
          }
          if (type === 'offering') {
          const response = await postApi('/payment/stripe-payment', stripeData);
          if (response.statusCode === 200) {
            if(addressId!== null){
              paymentDetails.addressId = addressId
            }
            const response = await postApi('/user/book-slot', paymentDetails);
            if (response.statusCode === 200) {
              setFiltersModal(false); // Close PaymentModal
              setSuccessModal(true);  // Open CheckoutSuccess Modal or any further actions
            }

          }
        }
        else if(type === 'billing') {
          const addCardDetail = {
            token: token.id,
          }
          // Conditionally add the 'attach' field if it's 1
          if (attach === 1) {
            addCardDetail.attach = attach;
          }
          const response = await postApi('/payment/add-card', addCardDetail);
          if (response.statusCode === 200) {
              setFiltersModal(false); // Close PaymentModal
              setSuccessModal(true);  // Open CheckoutSuccess Modal or any further actions
          }
        }
          else if (type === 'digital' || type === 'physical') {
            let totalPrice =null
            if(paymentDetails){
              totalPrice = itemDetails?.minimum_price * paymentDetails
            }
            else{
              totalPrice = itemDetails?.minimum_price
            }

            const orderData = {
              offer_service_id:itemDetails?.id ,
              quantity:parseInt(paymentDetails) || '',
              delivery_address:addressDetailsToPayment?.address || '',
              address:addressDetailsToPayment?.address || '',
              country:addressDetailsToPayment?.country || '',
              state:addressDetailsToPayment?.state || '',
              zipCode:addressDetailsToPayment?.zipCode || '',
              city:addressDetailsToPayment?.address || '',
              token:token?.id,
              amount:parseInt(totalPrice) * 100,
              // amount:parseInt(itemDetails?.minimum_price || itemDetails?.price) * 100,
              currency:'usd',
              paymentMethodId:'',
              address_id:addressId ? addressId :null
            };
            if(type === 'digital'){
              orderData.delivery_method = '';
            }
            else if (type === 'physical'){
              orderData.delivery_method = addressDetailsToPayment?.address ? 2 : 1;
            }
              // Conditionally add the 'attach' field if it's 1
            if (attach === 1) {
              orderData.attach = attach;
            }
            const response = await postApi('/payment/orders', orderData);
            if (response.statusCode === 200) {
              setFiltersModal(false); 
              setSuccessModal(true);  
            } else {
              setError('Failed to process order. Please try again.');
            }
          }
        }
      } catch (error) {
        setError(error.message || 'An error occurred. Please try again.');
      } finally {
        setLoading(false);
      }
    // }
    // else {
    //   setFiltersModal(false); // Close PaymentModal
    //   setSuccessModal(true);  // Open CheckoutSuccess Modal
    // }
  };
  const handleCheckboxChange = () => {
    setAttach((prevAttach) => (prevAttach === 0 ? 1 : 0)); // Toggle between 0 and 1
  };
  const handleChange = (e) => {

    setCardName(e.target.value)
    

    
  }
  // Card Expiry Validation
  const handleExpiryChange = (event) => {
    if (!event.complete) {
      setExpiryError(event.error?.message || 'Invalid expiration date');
    } else {
      setExpiryError('');
    }
  };

  // Card Number Validation
  const handleCardChange = (event) => {
    if (!event.complete) {
      setCardNumberError('Invalid card number');
    } else {
      setCardNumberError('');
    }

    // Check if card is complete (no errors in all fields)
    setIsCardComplete(event.complete && !cardNumberError && !expiryError && !cvcError);
  };

  // CVC Validation
  const handleCvcChange = (event) => {
    if (!event.complete) {
      setCvcError('Invalid CVC');
    } else {
      setCvcError('');
    }
  };
  return (
    <>
      {/* <div className='modal-header'>
        <button type="button" class="back-arrow" data-dismiss="modal" aria-label="back-arrow" onClick={() => setFiltersModal(false)}> */}
          {/* <span><img src={arrowBtn} /> </span> */}
        {/* </button> */}

        {/* <button className="close-modal" onClick={() => setFiltersModal(false)}>
              <img src={cross} alt="close" />
            </button> */}
      {/* </div> */}
      <div className="payment-modal">
                <button className='close-button back-btn' ><img src={backbutton} alt='' /></button>
      </div>
      
      <div className="payment-modal">
        <div className="modal-body">
          <div class="main">

            <div className='main-starts'>
              <div className='main-first'>
                <h1>Checkout</h1>
                {/* <h1>{type !=='billimg' ? 'Add card details' : 'Checkout'}</h1> */}
                { type !=='billing' && (
                  <div class="top">
                  <label className='mb-2'>Choose payment method</label>
                  <div class="row">

                    <div class="col-sm-4"> <label class="radio-check">
                      <input type="radio" checked="checked" name="radio" />
                      <span class="checkmark credit "><img src={creditCard} /><p>Credit/Debit card</p></span>
                    </label></div>

                    <div class="col-sm-4"> <label class="radio-check">
                      <input type="radio" checked="checked" name="radio" />
                      <span class="checkmark pay-pal"><img src={paypal} /></span>
                    </label></div>

                    <div class="col-sm-4"> <label class="radio-check">
                      <input type="radio" checked="checked" name="radio" />
                      <span class="checkmark stripe chosepayment active"><img src={stripeImg} /></span>
                    </label></div>

                  </div>
                </div>
                )}
                
                <div class="bottom">
                  {type === 'billing' && (
                    <div className='row card-head-set'>
                      <div className='col-md-12'>
                      <div className='form-group'>
                              <label htmlFor='cardName'>Name of Card</label>
                              <input type='text' className='form-control' id='cardName' value={cardName} onChange={handleChange} placeholder="Enter card name"
                                 onKeyDown={(e) => {
                                  // Prevent typing 0 or empty
                                  if (e.key === '0' && (e.target.value === '' || e.target.value === '0')) {
                                    e.preventDefault();
                                  }
                                }} />
                              {errors.cardName && <div className="error-message">{errors.cardName}</div>}

                            </div>
                      </div>
                    </div>
                  )}
               
                  {/* <CardElement /> */}
                  <div className='row'>
                    <div className='col-sm-6 hide-on-res' >
                      <label className='mb-2' htmlFor="card-number">Card Number</label>
                    </div>
                    <div className='col-sm-3 hide-on-res'>
                      <label htmlFor="expiry-date">Exp to</label>
                    </div>
                    <div className='col-sm-3 hide-on-res'>
                      <label htmlFor="cvc">CVC</label>
                    </div>
                  </div>
                  <div className='row'>
                  <div className='col-sm-6 show-on-res'>
                      <label className='mb-2' htmlFor="card-number">Card Number</label>
                    </div>
                    <div className="col-sm-6">

                      <CardNumberElement
                        onChange={handleCardChange}
                        options={{
                          style: {
                            base: {
                              fontSize: '16px',
                              color: '#424770',
                              letterSpacing: '0.025em',
                              fontFamily: 'Source Code Pro, monospace',
                              // '::placeholder': {
                              //   color: '#aab7c4',
                              // },
                              placeholder: {
                                color: '#aab7c4', // Placeholder color
                              },
                            },
                            invalid: {
                              color: '#9e2146',
                            },
                          },
                        }}
                        className="custom-card-number"
                      />
                          {errors.cardNumberError && <div className="error-message">{errors.cardNumberError}</div>}

                      {/* {cardNumberError && <div className="error-message">{cardNumberError}</div>} */}
                    </div>
                    <div className='col-sm-3 show-on-res'>
                      <label htmlFor="expiry-date">Exp to</label>
                    </div>
                    <div className="col-sm-3 ">
                      {/* <label htmlFor="expiry-date">Exp to</label> */}
                      <CardExpiryElement
                        onChange={handleExpiryChange}
                        options={{
                          style: {
                            base: {
                              fontSize: '16px',
                              color: '#424770',
                              letterSpacing: '0.025em',
                              fontFamily: 'Source Code Pro, monospace',
                              border: '1px solid #c3c3c3 !important',
                              placeholder: { color: '#aab7c4' },
                            },
                            invalid: { color: '#9e2146' },
                          },
                        }}
                        className="custom-card-expiry"
                      />
    {errors.expiryError && <div className="error-message">{errors.expiryError}</div>}

                      {/* {expiryError && <div className="error-message">{expiryError}</div>} */}
                    </div>
                    <div className='col-sm-3 show-on-res' >
                      <label htmlFor="cvc">CVC</label>
                    </div>
                    <div className="col-sm-3 ">
                      {/* <label htmlFor="cvc">CVC</label> */}
                      <CardCvcElement
                        onChange={handleCvcChange}
                        options={{
                          style: {
                            base: {
                              fontSize: '16px',
                              color: '#424770',
                              letterSpacing: '0.025em',
                              fontFamily: 'Source Code Pro, monospace',
                              placeholder: {
                                color: '#aab7c4', // Placeholder color
                              },
                            },
                            invalid: {
                              color: '#9e2146',
                            },
                          },
                        }}
                        className="custom-card-cvc"
                      />
                          {errors.cvcError && <div className="error-message">{errors.cvcError}</div>}

                      {/* {cvcError && <div className="error-message">{cvcError}</div>} */}

                    </div>
                  </div>
                </div>
                <div class="payment-method">
                  <label class="check3"> Save this payment method
                    <input
                      type="checkbox"
                      checked={attach === 1} // Check the state for whether the box is checked
                      onChange={handleCheckboxChange} // Call the handler on change
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                {/* <button
              className="pay"
              onClick={() => handlePay()}
            >
              Pay
            </button> */}
                {/* <button
                  className={`pay ${(cardNumberError || expiryError || cvcError) ? 'disabled' : ''}`}
                  onClick={handlePay}
                  disabled={loading || cardNumberError || expiryError || cvcError}
                > */}
                  <button
            className="pay"
            onClick={handlePay}
            disabled={loading}
          >
               {loading ? (type === 'billing' ? 'Saving...' : 'Processing...') : (type === 'billing' ? 'Save' : 'Pay')}
                </button>
              </div>
              {(type === 'digital' || type === 'physical') && (
                <div className='main-pay pt-0 pe-0'>
                  <div className='main-pay-div'>
                  <div className='d-flex align-items-center checked-imge'> <span><img src={itemDetails?.cover_image || homemade } /></span><h3 className='mb-0'>{itemDetails?.name}</h3></div>
                  <div class="price-details pt-3">
                    <h6>Price Details</h6>
                    <ul className='ps-0 mb-1'>
              {( type === 'physical') && (
                <>
                      <li><span>{itemDetails?.name} *{paymentDetails}</span> <strong>${(itemDetails?.minimum_price)}</strong> </li>
                      {itemDetails?.delivery_charge !== '0' &&<li><span>Delivery Charge</span> <strong>${(itemDetails?.delivery_price)}</strong> </li>}
                      </>
                      // <li><span>{itemDetails?.name} *{paymentDetails}</span> <strong>${(itemDetails?.minimum_price * paymentDetails)}</strong> </li>
              )}
                      {/* <li><span>Marketplace fee</span> <strong>${itemDetails?.minimum_price}</strong> </li> */}
                    </ul>

                    {( type === 'physical') && (
                <>
                      <div className='sub-total mt-2 pt-3'>
                      {itemDetails?.delivery_charge !=='0' ?
                      <p className='w-100'>Subtotal Price <span>${(Number(itemDetails?.minimum_price * paymentDetails) + Number(itemDetails?.delivery_price))}</span></p>
                      :
                      <p className='w-100'>Subtotal Price <span>${(itemDetails?.minimum_price * paymentDetails)}</span></p>
}
                    </div>
                    </>
              )}
              {( type === 'digital') && (
                <>
                      <div className='sub-total mt-2 pt-3'>
                      
                      <p className='w-100'>Subtotal Price<span>${(itemDetails?.minimum_price)}</span></p>
                    </div>
                    </>
              )}



                    
                  </div>

                </div>
                </div>
              )}
              {(type === 'offering') && (
                <div className='main-pay pt-0 pe-0'>
                    <div className='main-pay-div'>
                 <div className='d-flex align-items-center checked-imge'><span><img src={itemDetails?.cover_image || homemade} /></span><h3 className='mb-0'>{itemDetails?.name}</h3></div>
                  <div class="price-details pt-3">
                    <h6>Price Details</h6>
                    <ul className='ps-0 mb-1'>
                      {/* <li><span>{itemDetails?.offer_name}</span> <strong>${(itemDetails?.minimum_price)}</strong> </li> */}
                      {/* <li><span>Marketplace fee</span> <strong>${itemDetails?.minimum_price}</strong> </li> */}
                    </ul>
                    <div className='sub-total mt-2 pt-3'>
                      <p className='w-100'>Subtotal Price<span>${paymentDetails?.price}</span></p>
                      {/* <p className='w-100'>Subtotal Price<span>${itemDetails?.minimum_price}</span></p> */}
                    </div>
                  </div>

                </div>
                </div>
              )}


            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default PaymentModal;
