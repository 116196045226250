import React, { useEffect, useState } from 'react'
import search from '../../../assets/images/search.svg'
import { getApi } from '../../../Services/apiService'
import UserSidebar from '../../../Layout/userSidebar'
import burger from '../../../assets/images/burger-menu.svg'
import hearts from '../../../assets/images/heart.svg'
import { useNavigate } from 'react-router-dom'
import bannerRight from '../../../assets/images/dummy-profile.jpg'
import arrow from '../../../assets/images/arrow.svg'
import location from '../../../assets/images/map-pin1.svg'
import addtag from '../../../assets/images/add-tag.svg'
import { useDispatch, useSelector } from 'react-redux';
import { clearOfferingData, insertOfferingData } from '../../../Counter/offeringSLice';
import { clearProductData, insertProductData } from '../../../Counter/productSlice';
import Modal from 'react-modal';
import tick from '../../../assets/images/green-tick.svg';
import cross from '../../../assets/images/cross-button.svg';
import { toast } from 'react-toastify'
import filterList from '../../../assets/images/filter-list.svg';




function AdminOffering() {
    const dispatch = useDispatch()
    const [offeringData, setOfferingData] = useState([])
    const [classState, setClassState] = useState(false)
    const [loadData, setLoadData] = useState(6);
    const [page, setPage] = useState(1);
    const [tags, setTags] = useState([]);
    const [category, setCategory] = useState([]);
    const [selectedTagIds, setSelectedTagIds] = useState([]); // New state for selected tag IDs
    const [selectedCategoryId, setSelectedCategoryId] = useState(null); // Single selected category ID
    const [selectedLocationTypes, setSelectedLocationTypes] = useState([]);
    const [searchText, setSearchText] = useState(''); // New state for search text
    const [creatorText, setCreatorSearchText] = useState(''); // New state for search text
    const [shouldFetch, setShouldFetch] = useState(true); // State to trigger fetch based on filters
    const [showSearchResults, setShowSearchResults] = useState(false); // New state for managing search results
    const [creatorModal, setCreatorModal] = useState(false);
    const [creatorList, setCreatorList] = useState('');
    const [selectedCreatorId, setSelectedCreatorId] = useState(null); // State for selected creator ID
    const [loader, setLoader] = useState(false)
    const [totalCount,setTotalCount] = useState(0)


    const navigate = useNavigate();


    const singleService = (id,x) => {
        if(x?.service_type==='2'){
            navigate(`/admin/creator/offering/product/${id}`)
        }else{
        navigate(`/admin/creator/offering/${id}`)
        }
        // navigate(`/user/offering/${id}`)
    }
    const handleLoadData = () => {
        setLoadData(loadData + 6)
    }

    const closeCreatorModal = () => {
        setCreatorModal(false)
    }

    useEffect(() => {
        fetchCreatorList();
    }, [creatorText]);

    const fetchCreatorList = async () => {
        const searchParam = creatorText ? `&search=${encodeURIComponent(creatorText)}` : '';
        try {
            const response = await getApi(`/admin/creators?search=${creatorText}`);
            if (response?.statusCode === 200) {
                setCreatorList(response?.data);
            }
        } catch (error) {
            console.error('Error fetching offerings:', error);
        }
    }

    const handleFetchOffering = async () => {
        setLoader(true)
        const tagIdsParam = selectedTagIds.join(',');
        const categoryIdParam = selectedCategoryId || '';
        const locationTypeIdsParam = selectedLocationTypes.join(',');
        const searchParam = searchText ? `&search=${encodeURIComponent(searchText)}` : '';

        if (tagIdsParam.length || categoryIdParam !== '' || locationTypeIdsParam.length || searchParam !== '') {
            setShowSearchResults(true); // Show search results section
        }
        else {
            setShowSearchResults(false); // Remove search results section
        }
        try {
            const response = await getApi(`/admin/offerings?page=${page}&pageSize=${loadData}&tag_ids=${tagIdsParam}&category_id=${categoryIdParam}&location_type=${locationTypeIdsParam}${searchParam}`);
            if (response?.statusCode === 200) {
                setOfferingData(response?.data);
                setTotalCount(response?.totalCount)
            }
            setLoader(false)
        } catch (error) {
            console.error('Error fetching offerings:', error);
        }
    };


   
    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };
    const handleCreatorSearchChange = (e) => {
        setCreatorSearchText(e.target.value);
    };

    useEffect(() => {
        handleFetchOffering();
        const handleClick = () => setClassState(false);
        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    },[selectedTagIds, selectedCategoryId, selectedLocationTypes, searchText, shouldFetch,loadData]);

    // const handleSearch = () => {
    //     setShouldFetch(true); // Trigger fetch when search is initiated
    // };

    const handleSidebarClick = (event) => {
        event.stopPropagation();
        setClassState(!classState);
    };
     // New function to handle creator selection and close the modal
     const handleSelectCreator = async(creatorId,creator) => {
        if(creator?.stripe_connector_id==null){
        const resp= await getApi(`/admin/creator/connector-account?creatorId=${creatorId}`)
        toast.error('first creator should connect the card');
        closeCreatorModal()
        return
        }
        setSelectedCreatorId(creatorId); // Store the selected creator's ID
        console.log('this is creator_id',creatorId);
        
    };
    const handleSubmit = () => {
        console.log('this is creator_id in hanfle submit',selectedCreatorId);
        if(selectedCreatorId){
            setCreatorModal(false); // Close the modal
            dispatch(clearOfferingData());
            dispatch(clearProductData())
            const val ={
                creator_id:selectedCreatorId
            }
            dispatch(insertProductData(val))
            dispatch(insertOfferingData(val))
            navigate('/admin/offering/create')
        }
        else{
            toast.error("Please select a creator")
        }
    }

   const  openModal=()=>{

    }

    return (
        <div className="creators-detail offering-user-side">
            <UserSidebar classState={classState} />
            <div className='creators right-main min-height-pera offering'>
                <section className="creators-top">
                    <div className="members-top-right">
                        {/* <button className='new-offering' onClick={() => {setCreatorModal(true)} }> */}
                        <button className='new-offering' onClick={() => { setCreatorModal(true) }}>
                            <img src={addtag} />
                            Create a new offering
                        </button>
                    </div>
                    <div className="members-top">
                        <h2><button className="burger-menu" onClick={handleSidebarClick}><img src={burger} alt='' /></button>Offerings</h2>

                        <div className="member-filter">
            <div className="filter-search filter-respon">
                           <button>
                            <img src={search} alt='' />
                            </button>
                            <input
                                type="text"
                                className="search"
                                placeholder="Search offer"
                                value={searchText}
                                onChange={handleSearchChange}
                            />
                            </div>

                               <button className="filter-btn" >
              <img src={filterList} alt="filters" />Filters
            </button>
                        </div>
                    </div>

                </section>

                {offeringData.length ? (
                    <>
                        {showSearchResults ? (
                            <section className="other-class-main">

                                <h3>Search Results</h3>
                                {/* <OfferingData data={offeringData} type='offering' /> */}

                                <div className="row">
                                    {offeringData && offeringData.length > 0 && offeringData.map((x, index) => {
                                        return (
                                            (index < loadData) &&
                                            // <div className="col-sm-4" key={index} >
                                            <div className="col-sm-4" onClick={() => singleService(x?.id,x)} key={index} >
                                                <div className="product-box">
                                                    <div className="product-img">
                                                        <img src={x?.cover_image} alt='' />
                                                        <div className="product-image-data">
                                                        {x?.service_type === "1" && (
                                                            <span><img src={x?.category && x?.category?.category_image} alt='' /></span>
                                                        )}
                                                            <h5>
                                                                {x?.service_type === "2"
                                                                    ? (x?.product_kind === "0" ? 'Physical Product' : 'Digital Product')
                                                                    : (x?.service_type === "1"
                                                                        ? (x?.location_type === "1" ? 'Virtual'
                                                                            : (x?.location_type === "2" ? 'In Person'
                                                                                : (x?.location_type === "3" ? 'Virtual/In Person' : '')))
                                                                        : '')}
                                                            </h5>

                                                            <button><img src={hearts} alt='' /></button>
                                                            {(x?.address?.city || x?.address?.country) &&<h6><img src={location} alt='' />{(x?.address?.city != '' && x?.address?.city != undefined ? x?.address?.city:'') + (x?.address?.country != '' && x?.address?.country != undefined ? `,  ${x?.address?.country}` :'')}</h6>}
                                                            {/* <h6><img src={location} alt='' />Austin, Texas</h6> */}
                                                        </div>
                                                    </div>
                                                    <div className="product-data">
                                                        <h3>{x?.title} </h3>
                                                        <ul>
                                                            {x?.tags && x?.tags.length > 0 && x?.tags.slice(0, 3).map((y, i) => {
                                                                return (
                                                                    <li className={i === 0 && "active"}>{y?.tag_name}</li>
                                                                )
                                                            })}
                                                        </ul>
                                                        <p>
                                                            {x?.description}
                                                        </p>
                                                        <div className="product-data-bottom">
                                                            <span><img src={x?.user?.user_profile_image && x?.user?.user_profile_image || bannerRight} alt='' /></span>
                                                            <h6><strong>{x?.user && x?.user?.name}</strong> {x?.user?.specializations && x?.user?.specializations[0]?.area_of_work}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                {/* {type === 'offering' && */}
                                {
                                    <button className="explore" onClick={() => handleLoadData()}>Load more</button>
                                }
                            </section>
                        ) : (
                            <>
                                <section className="other-class-main pb-0">
                                    <div className='offering-scroll'>
                                    {/* <OfferingData data={offeringData} type='offering' /> */}

                                    <div className="row">
                                        {offeringData && offeringData.length > 0 && offeringData.map((x, index) => {
                                            return (
                                                (index < loadData) &&
                                                <div className="col-sm-4" onClick={() => singleService(x?.id,x)} key={index} >
                                                {/* <div className="col-sm-4" key={index} > */}
                                                    <div className="product-box">
                                                        <div className="product-img">
                                                            <img src={x?.cover_image} alt='' />
                                                            <div className="product-image-data">
                                                        {x?.service_type === "1" && (
                                                                <span><img src={x?.category && x?.category?.category_image} alt='' /></span>
                                                        )}
                                                                <h5>
                                                                {x?.service_type === "2"
                                                                    ? (x?.product_kind === "0" ? 'Physical Product' : 'Digital Product')
                                                                    : (x?.service_type === "1"
                                                                        ? (x?.location_type === "1" ? 'Virtual'
                                                                            : (x?.location_type === "2" ? 'In Person'
                                                                                : (x?.location_type === "3" ? 'Virtual/In Person' : '')))
                                                                        : '')}
                                                            </h5>
                                                                <button><img src={hearts} alt='' /></button>
                                                                {(x?.address?.city || x?.address?.country) &&<h6><img src={location} alt='' />{(x?.address?.city != '' && x?.address?.city != undefined ? x?.address?.city:'') + (x?.address?.country != '' && x?.address?.country != undefined ? `,  ${x?.address?.country}` :'')}</h6>}
                                                                {/* <h6><img src={location} alt='' />Austin, Texas</h6> */}
                                                            </div>
                                                        </div>
                                                        <div className="product-data">
                                                            <h3>{x?.title} </h3>
                                                            <ul>
                                                                {x?.tags && x?.tags.length > 0 && x?.tags.slice(0, 3).map((y, i) => {
                                                                    return (
                                                                        <li className={i === 0 && "active"}>{y?.tag_name}</li>
                                                                    )
                                                                })}
                                                            </ul>
                                                            <p>
                                                                {x?.description}
                                                            </p>
                                                            <div className="product-data-bottom">
                                                                <span><img src={x?.user?.user_profile_image && x?.user?.user_profile_image || bannerRight} alt='' /></span>
                                                                <h6><strong>{x?.user && x?.user?.name}</strong> {x?.user?.specializations && x?.user?.specializations[0]?.area_of_work}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    </div>
                                    {/* {type === 'offering' && */}
                                    { loadData < totalCount &&
                                        <button className="explore" onClick={() => handleLoadData()} disabled={loader}>{loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Load more</button>
                                    }
                                </section>
                            </>
                        )
                        }
                    </>
                ) : (
                    <>
                        <p>Opps..No data available</p>
                    </>
                )
                }
            </div>
            <Modal isOpen={creatorModal} onRequestClose={closeCreatorModal} shouldCloseOnOverlayClick={false} ariaHideApp={false}
                overlayClassName={`modal-overlay select-creator`}>
                <button className='close-button' onClick={closeCreatorModal}>
                    <img src={cross} alt='close' />
                </button>
                <h1 className='text-center'>Select Creator</h1>
                <p className='d-block'>For which you want to add Offerings</p>
                <div class="form-group">
                    <label for="exampleInputEmail1">Creator Name</label>
                    <div className="creators-input mt-1">
                    <img className='float-end' src={search} alt='' / >
                    <input type="text" className="search" placeholder="Type Creator Name" value={creatorText} onChange={handleCreatorSearchChange} />
                </div>
                   </div>
           
                <div className="creators-list  position-relative">
                        {creatorList.length > 0 ? (
                            creatorList.map((creator) => (
                                <div key={creator.id}
                                className={`creator-item ${selectedCreatorId === creator.id ? 'active' : ''}`}
                                 onClick={() => handleSelectCreator(creator.id,creator)}>
                           
                                    <div className="creator-info">
                                        <ul className='ps-0 mb-0'>
                                            <li>
                                            
                                            <span>
                                    <img
                                        src={creator.user_profile_image ? creator.user_profile_image : bannerRight}
                                        alt={creator.name}
                                        className="creator-image"
                                    />
                                    </span>
                                    <div class="user-info request ps-2">
                                        <h3>{creator.name}</h3>
                                        <h4>{creator.email ? creator.email : 'abc@gmai.com'}</h4>
                                        
                                  </div>
                                 
                                  {selectedCreatorId === creator.id && (
                                    <button className='remove-creator float-end'>
                                        <img src={tick} alt="remove" />
                                    </button>
                                )}
                               
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            ))
                            ) : (
                                <p>No creators found</p>
                                )}
                          
                    </div>  
                    <div className='bottom-button continue-btn mt-3' >
                                <button onClick={() => handleSubmit()}>Continue</button>
                                </div>             
            </Modal>
        </div>
    )
}

export default AdminOffering