import React from "react";
import check from '../../../assets/images/green-check.svg'
const AcceptModal = ({singleOrderData}) => {

  return (
    <>
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="share-main">
              <div className='header'>
                <span><img src={check} alt=''></img> </span>
                <h5>You have successfully accepted the order!</h5>
                <h6>Please proceed to the order details to start working. </h6>
              </div>
              <div class="top">
                <img src={singleOrderData?.offerService?.cover_image} alt='' />

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AcceptModal