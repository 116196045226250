import React, { useEffect, useState } from 'react'
import leftArrow from '../../../assets/images/left-arrow_black.svg'
import { Link } from 'react-router-dom'
import calendar from '../../../assets/images/calender.png'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { clearData, insertData } from '../../../Counter/signupSlice';
import { bookingInterviews, bookingInterviewsCreator } from '../../../Validations/formValidation';
import { getApi,postApi } from '../../../Services/apiService';
import Header from './header';
import { requestNotificationPermission } from '../../FirebaseConfig/NotificationComponent'; // Import the function


function BookInterview({ nextStep, prevStep, data,submitForm,loader,loginStatus,setDashboardModal,setBookingInterviw,setInterviewData }) {

  const aboutDetail = useSelector((state) => state.counter)
  const userDetail = JSON.parse(localStorage.getItem('full_user_detail'))
  const [duplicateLoader,setDuplicateLoader] = useState(false)
  const initialValues = {
    selectedDate: data?.selectedDate || '',
    selectedTimes: data?.selectedTimes || '',
  }
  const [selectedTime, setSelectedTime] = useState('');
  const [value, onChange] = useState('');
  // const [value, onChange] = useState(new Date());
  const [activeStartDate, setActiveStartDate] = useState(new Date());
  const [monthDate,setMonthDate] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [activeMonth,setActiveMonth] = useState(new Date());
  const convertTo24HourFormat = (time) => {
    const [hours, minutesPart] = time.split(':');
    const [minutes, period] = minutesPart.match(/[a-zA-Z]+|[0-9]+/g);
    let hours24 = parseInt(hours, 10);
  
    if (period.toLowerCase() === 'pm' && hours24 !== 12) {
      hours24 += 12;
    }
    if (period.toLowerCase() === 'am' && hours24 === 12) {
      hours24 = 0;
    }
  
    return `${hours24.toString().padStart(2, '0')}:${minutes}`;
  };
  const dispatch = useDispatch();
  // const onSubmit = async (datas) => {
  //   const values ={
  //     selectedDate:datas?.selectedDate || value,
  //     selectedTimes:datas?.selectedTimes
  //   }
  //   dispatch(insertData(values));
  //   nextStep()
  // }

  useEffect(()=>{
    if(value !==''){
    handleDateSlotData();
    }
  },[value])
  useEffect(()=>{
    handleActiveStartDateChange();
  },[activeMonth])

  const handleDateSlotData = async() =>{
    const month = new Date(value).getMonth();
    const year = new Date(value).getFullYear();
    const currentDate = new Date(value).getDate();
    const response = await getApi(`/availbility-slot?date=${year}-${month+1}-${currentDate}`)
    if(response?.statusCode === 200){
      setTimeSlots(response?.data)
    }
    else{

    }
  }

  const onSubmit = async (datas) => {
    try {
      const selectedTime = timeSlots.find(time => time.id === datas?.selectedTimes);
      if(loginStatus === 'googleLogin'){
        
        const values = {
          interview_date: datas?.selectedDate || value,
          interview_time: `${selectedTime?.start_time} - ${selectedTime?.end_time}` ,
          slotId: datas?.selectedTimes,

          workArea: aboutDetail?.workArea,
          link: aboutDetail?.link,
          experience: aboutDetail?.experience,
          location: aboutDetail?.location

        }
        const response = await postApi('/creator/onboardingInterview',values)
        // const response = {
        //   statusCode :200
        // }
        if(response.statusCode === 200){
          setInterviewData(values)
          setBookingInterviw(false)
          clearData()
          setDashboardModal(true)

        }
      } else if(loginStatus === 'becomeCreator'){
        setDuplicateLoader(true)
        const fcmToken = await requestNotificationPermission();
      
        
        const insertData ={
          name:userDetail?.name,
          email:userDetail?.email,
          fcmtoken:fcmToken === 'not_granted' ? '' : fcmToken,
          // password:formState?.password,
          // gender:formState?.gender,
          account_type:'email',
          user_role:'creator',
          interview_time:`${selectedTime?.start_time} - ${selectedTime?.end_time}` ,
          interview_date:datas?.selectedDate || value ,
          area_of_work :data?.workArea || '',
          experience :data?.experience || '',
          link:data?.link || '',
          slotId: datas?.selectedTimes,
          location : data?.location
          
        }
        const response = await postApi('user/register-user',insertData)
        if(response?.statusCode === 200){
          setInterviewData(insertData)
          localStorage.setItem("userDetail",JSON.stringify(response?.data))
          localStorage.setItem("token", JSON.stringify(response?.data?.token))
          // localStorage.setItem('user_role',JSON.stringify(response?.data?.roles))


          localStorage.setItem('user_role',response?.data?.roles[0]?.user_role)
          setDuplicateLoader(false)
          nextStep()
          
         
        }
        else{
          setDuplicateLoader(false)
          nextStep()
        }
      }
      else{
    const values = {
      selectedDate: datas?.selectedDate || value,
      selectedTimes: `${selectedTime?.start_time} - ${selectedTime?.end_time}` ,
      slotId: datas?.selectedTimes,
      email: data?.email,
      role:'creator'
    };

    dispatch(insertData(values));
  
    submitForm('booking',values)

      console.log('Data to send:', values);
  }
      // const response = await postApi('/verify-email', {email:values.email});
      // if (response.statusCode === 200) {
      //    // Dispatch the data to Redux store
      //    dispatch(insertData(values));
      //    // Proceed to the next step
      //   //  nextStep()
      //   }
    } catch (error) {
      console.error('Error submitting booking:', error);
    }
   
  };

  
  // const timeSlots = [
  //   '07:00am', '08:00am', '09:00am', '10:00am', '12:00pm', '01:00pm', '02:00pm', '03:00pm', '04:00pm', '05:00pm',
  // ];

  const shortWeekdayLabels = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

  const formatShortWeekday = (_, date) => {
    const day = date.getDay(); // getDay() returns 0 for Sunday, 1 for Monday, etc.
    return shortWeekdayLabels[day];
  };

  const handleActiveStartDateChange = async( ) => {
    const month = new Date(activeMonth).getMonth();
    const year = new Date(activeMonth).getFullYear();

    const response = await getApi(`/availbility-date?monthYear=${month+1}-${year}`)
    if(response?.statusCode === 200){
      const formattedDates = response?.data.map(dateTime => dateTime.slice(0, 10));
      setMonthDate(formattedDates)
    }
    // setActiveStartDate(activeStartDate);
    // Fetch availability for the new month
    // getAvailability();
  }
  
  function convertTo12HourFormat(time) {
    const [hours, minutes] = time.split(':');
    let period = 'AM';
    let hour = parseInt(hours, 10);
  
    if (hour >= 12) {
      period = 'PM';
      if (hour > 12) hour -= 12;
    } else if (hour === 0) {
      hour = 12;
    }
  
    return `${hour}:${minutes} ${period}`;
  }

  return (
    <div className="detail-page booking signup-cs ">
      <Header/>
      <div className="container">

        <h2>Now, Book An Onboarding Interview With A Member Of Our Team</h2>
        <h3>You can start exploring the platform and host your first offering after the onboarding call.</h3>
        <div className="detail-main">
          <Formik
            initialValues={initialValues}
            validationSchema={bookingInterviewsCreator}
            onSubmit={onSubmit}
          >
            {({ setFieldValue }) => (
              <Form>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="select-date">
                      <h4>Select date</h4>
                      <div className="calender">
                        <Calendar
                          formatShortWeekday={formatShortWeekday}
                          onChange={(newValue)=>{onChange(newValue);setFieldValue("selectedTimes", "")}}
                          // onChange={onChange}
                          value={value}
                          minDate={new Date()}
                          onActiveStartDateChange={(e)=>setActiveMonth(e?.activeStartDate)}
                          tileClassName={({ date,view }) => {
                            // const formattedDate = date.toISOString().split('T')[0];
                            const formattedDate = date.toLocaleDateString('en-CA');
                            const isToday = date.getDate() === new Date().getDate() &&
                        date.getMonth() === new Date().getMonth() &&
                        date.getFullYear() === new Date().getFullYear();
                        const currentMonth = activeStartDate.getMonth();
  const currentYear = activeStartDate.getFullYear();

  const dateMonth = date.getMonth();
  const dateYear = date.getFullYear();
                        const isPreviousMonth = view === 'month' && (dateMonth < currentMonth && dateYear === currentYear || dateYear < currentYear);
                        const isNextMonth = view === 'month' && (dateMonth > currentMonth+1 && dateYear === currentYear || dateYear >  currentYear);
                            let classNames = '';
                            if (isPreviousMonth || !isNextMonth) {
                              classNames += ' previous-date';
                              // return 'previous-date'; // Add class for previous month dates
                            }
                            if (monthDate.includes(formattedDate)) {
                              // return 'highlight';
                            }
                            else{
                              classNames += ' sanket'
                            }
                            if (value && formattedDate === new Date(value).toLocaleDateString('en-CA')) {
                              classNames += ' highlight selected-date'; // Custom class for the clicked date
                            }
                            return classNames;
                          }}
                        />
                        <ErrorMessage name="selectedDate" component="div" className="text-danger" />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="select-date ">
                      <h4>Select time</h4>
                      <div className="row select-log-tag">
                        {/* {timeSlots.map((time) => ( */}
                        {timeSlots.map((time,index) => (
                          <div className="col-sm-5" key={index}>
                            <label className="time">
                              <Field type="radio" name="selectedTimes" value={time?.id} />
                              {/* <Field type="radio" name="selectedTimes" value={convertTo24HourFormat(time)} /> */}
                              <span className="checkmark">
                                <h5>{convertTo12HourFormat(time?.start_time)} - {convertTo12HourFormat(time?.end_time)}</h5>
                                {/* <h5>{time?.start_time} - {time?.end_time}</h5> */}
                              </span>
                            </label>
                          </div>
                        ))}
                        {timeSlots?.length === 0 && <p>Please select a date on the left calendar to view available time slots</p>}
                        <ErrorMessage name="selectedTimes" component="div" className="text-danger" />
                      </div>
                      <button type="submit" className="choose" disabled={loader || duplicateLoader}>{(loader || duplicateLoader) && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Choose</button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {loginStatus !=='googleLogin' &&
        <div className="back-button">
          <button className="button" onClick={() => prevStep()}><img src={leftArrow} alt='' /> </button>
        </div>}
      </div>
    </div>
  )
}

export default BookInterview