import React from "react";

const DeleteOffering = ({deleteOffer,delteOfferingId,delteOfferingData,setDeleteOffering,deleteloader}) =>{
    return(
        <>
        <div className="">
        <h1 className="mt-1 mb-2">Are you sure you want to delete this offering?</h1>
        <p className="mb-3 text-center">It will be deleted permanently without the possibility of recovery.</p>
        <div className=" mt-3">
        <div class="bottom-button d-flex">
            <button class="btn btn-cancel" onClick={()=>setDeleteOffering(false)}>Cancel</button>
            <button class="btn btn-delete"  onClick={()=>deleteOffer(delteOfferingId,delteOfferingData)} disabled={deleteloader}>{deleteloader && <div className="spinner-border" role="status"><span className="sr-only"></span> </div>}Yes, Delete</button></div>
        </div>
        </div>
        {/* <button onClick={()=>deleteOffer(delteOfferingId,delteOfferingData)}>Delete</button> */}
        </>
    )
}

export default DeleteOffering