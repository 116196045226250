import React, { useEffect, useState } from 'react'
import productImg from '../../../assets/images/product-img.png'
import wellness from '../../../assets/images/wellness.svg'
import hearts from '../../../assets/images/heart.svg'
import { useNavigate } from 'react-router-dom'
import bannerRight from '../../../assets/images/banner-right1.png'
import { getApi } from '../../../Services/apiService'
import location from '../../../assets/images/map-pin1.svg'
import dummy from '../../../assets/images/dummy-profile.jpg'

function DiscoverOffering({selectedCategoryId,selectedTagIds,searchValue}) {

	const navigate = useNavigate()
	const [page,setPage] = useState(1)
	const [pageSize,setPageSize] = useState(6)
	const [totoalCount,setTotalCount] = useState(0)
	const [userData,setUserData] = useState([])
  const [btnloader, setBtnloader] = useState(false)
  const singleService = (id) =>{
    navigate(`/discovering/${id}`)
  }
  // useEffect(()=>{
	//   handleGetData()
  // },[pageSize])

  useEffect(()=>{
    handleGetData()
  },[pageSize,selectedCategoryId,searchValue,selectedTagIds])

  const handleGetData = async() =>{
    if(userData?.length===0){
    setBtnloader(true)
    }
	  const response = await getApi(`/services/list?page=${page}&pageSize=${pageSize}&category_id=${selectedCategoryId}&search=${searchValue}&tag_ids=${selectedTagIds}`)
	  if(response?.statusCode===200){
		setUserData(response?.data)
		setTotalCount(response?.totalCount)
	  }
    if(userData?.length===0){
    setBtnloader(false)
    }
  }
  const handleLoadData = () =>{
	setPageSize(pageSize+6)
  }

  return (
    <>
    <section class="creators-mid craetor-mid-home">
			<div class="container">
				<div class="row">
          {userData && userData?.length>0 && userData.map((x,index)=>{
            return(
              <div class="col-sm-4" onClick={()=>singleService(x?.id)} key={index}>
						<div class="product-box">
							<div class="product-img">
								<img src={x?.cover_image} alt=''/>
								<div class="product-image-data">
                      {x?.service_type === "1" && (
                        <span><img src={x?.category && x?.category?.category_image} alt='' /></span>
                      )}
                      <h5>
                        {x?.service_type === "2"
                          ? (x?.product_kind === "0" ? 'Physical Product' : 'Digital Product')
                          : (x?.service_type === "1"
                            ? (x?.location_type === "1" ? 'Virtual'
                              : (x?.location_type === "2" ? 'In Person'
                                // : (x?.location_type === "2" ? 'Offline'
                                : (x?.location_type === "3" ? 'Virtual/In person' : '')))
                            : 'In Person')}
                        {/* : 'Offline')} */}
                      </h5>
                      {x?.address && x?.address !== null && <h6><img src={location} alt='' />{x?.address!== null &&x?.address?.city + ', ' + x?.address?.country}</h6>}
                      {/* } */}
                      <button><img src={hearts} alt='' /></button>
								</div>
                {/* {x?.address!== null &&<h6><img src={location} alt='' />{x?.address?.city + ', ' + x?.address?.country}</h6>} */}
							</div>
							<div class="product-data">
								<h3>{x?.name !='null' && x?.name !='undefined' && x?.name} </h3>
                    <ul className='product-data-tags'>
                      {x?.tags && x?.tags.length > 0 && x?.tags.map((y, i) => (
                        <li className={i === 0 ? "active" : ''} key={i}>
                          {y?.tag_name}
                        </li>
                      ))}
                    </ul>
                    <p>{x?.description != 'undefined' && x?.description !='null' && x?.description}</p>
								<div class="product-data-bottom">
                <span><img src={x?.user?.user_profile_image && x?.user?.user_profile_image || dummy} alt='' /></span>
                <h6><strong>{x?.user && x?.user?.name}</strong> {x?.user?.specializations && x?.user?.specializations[0]?.area_of_work}</h6>
								</div>
							</div>
						</div>
					</div>
            )
          })}
					
				</div>
				{pageSize < totoalCount &&
			<button class="explore" onClick={()=>handleLoadData()} disabled={btnloader}>{btnloader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Load more Offerings</button>
				}
			</div>
		</section>
    </>
  )
}

export default DiscoverOffering