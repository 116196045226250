import React, { useEffect, useState, useCallback } from 'react';
import UserSidebar from '../../../../Layout/userSidebar';
import burger from '../../../../assets/images/burger-menu.svg';
import { getApi, putApi, deleteApi } from '../../../../Services/apiService'
import addtag from '../../../../assets/images/add-tag.svg'
import { useNavigate } from 'react-router-dom';
import bannerRight from '../../../../assets/images/dummy-profile.jpg'
import hearts from '../../../../assets/images/heart.svg'
import NoDataFound from '../../../AuthComponent/noDataFound';
import search from '../../../../assets/images/search.svg'
import { toast } from 'react-toastify';
import { clearOfferingData, insertOfferingData } from '../../../../Counter/offeringSLice';
import { useDispatch } from 'react-redux';
import { clearProductData } from '../../../../Counter/productSlice';
import { insertProductData } from '../../../../Counter/productSlice';
import doublebox from '../../../../assets/images/double-box.svg'
import threedots from '../../../../assets/images/three-dots-horizontal.svg'
import resotre from '../../../../assets/images/restore-icon2.svg'
import edit from '../../../../assets/images/edit-icon-2.svg'
import deleteoffer from '../../../../assets/images/delete-2.svg'
import publish from '../../../../assets/images/publish-ison2.svg'
import details from '../../../../assets/images/ep_top-right.svg'
import journyimg from '../../../../assets/images/journryimg.svg'
import clender from '../../../../assets/images/clender.svg'
import clock from '../../../../assets/images/clock2.svg'
import cross from '../../../../assets/images/cross-tick-image.svg'
import readyPickup from '../../../../assets/images/ready-to-pick.svg'
import attendus from '../../../../assets/images/view-attendus.svg'
import AttendeeList from '../../CreatorSession/attendeeList';
import Modal from 'react-modal';
import share from '../../../../assets/images/share-2.svg'
import messageCreator from '../../../../assets/images/chat-msg.svg'



function Journey() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [classState, setClassState] = useState(false);
    const [offeringData, setOfferingData] = useState([])
    const [page, setPage] = useState(1);
    const [pageLimit, setPageLimit] = useState(6);
    const [totalCount, setTotalCount] = useState(0);
    const [status, setStatus] = useState(1); // Default to "Active"
    const [searchQuery, setSearchQuery] = useState(''); // State for search query
    const [loader, setloader] = useState(false); // State for search query
    const [deleteloader, setdeleteloader] = useState(false); // State for search query
    const [activeDropdown, setActiveDropdown] = useState(null); // State to track which dropdown is open
    const [btnloader, setBtnLoader] = useState(false)
    const [offerType, setOfferType] = useState('current')
    const [locationType, setLoctionType] = useState([])
    const [locationValue, setLocationValue] = useState([])
    const [pageLoader,setPageLoader] = useState(false)
    const [attendeeData, setAttendeeData] = useState([]);
    const [attendeeModal, setAttendeeModal] = useState(false);
    const [singleOfferingData,setSingleOfferingData] = useState({})


    const handleSidebarClick = (event) => {
        event.stopPropagation();
        setClassState(!classState);
    };

    const toggleDropdown = (id) => {
        setActiveDropdown((prevDropdown) => (prevDropdown === id ? null : id)); // Toggle the dropdown
    };
    const handleSetLocationType = (val, valu) => {
        setLoctionType((prev) => {
            if (prev.includes(val)) {
                return prev.filter((item) => item !== val);
            } else {
                return [...prev, val];
            }
        });
        setLocationValue((prev) => {
            if (prev.includes(valu)) {
                return prev.filter((item) => item !== valu);
            } else {
                return [...prev, valu];
            }
        });
    }

    useEffect(() => {
        const handleClick = () => setClassState(false);
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [page, status, searchQuery]);
    useEffect(() => {
        fetchUserOfferings()

    }, [page, status, searchQuery, locationValue, offerType, pageLimit])

    const fetchUserOfferings = async () => {
        setBtnLoader(true)
        const dates = new Date();
        const year = dates.getFullYear();
        const month = String(dates.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(dates.getDate()).padStart(2, '0');

        const customDate = `${year}-${month}-${day}`;
        {pageLimit===6 && setPageLoader(true)}
        const response = await getApi(`user/journey?date=${customDate}&location_type=${locationValue}&type=${offerType}&page=1&pageSize=${pageLimit}`);
        if (response?.statusCode === 200) {
            setOfferingData(response.data)
            setTotalCount(response.totalCount);
            setBtnLoader(false)
        } else {
            // localStorage.clear();
            // navigate('/');
            setBtnLoader(false)
            toast.error(response?.message)
        }
        setPageLoader(false)
    };

    const handleTabClick = (selectedStatus) => {
        setPage(1); // Reset page to 1 when switching tabs
        setOfferingData([]); // Clear current offerings when switching tabs
        setStatus(selectedStatus); // Update status based on the selected tab
    };

    //   / Function to handle loading more offerings
    const loadMoreOfferings = () => {
        // setBtnLoader(true)
        setPage((prevPage) => prevPage + 1);
        setPageLimit((prevPage) => prevPage + 6);
    };

    const offerStatusChange = async (id, datas) => {
        setloader(true)
        const data = {
            serviceId: id,
            status: status === 1 ? '2' : '1'
        }

        // const response= await putApi('/creator/service/status', data)
        fetchUserOfferings()
        setloader(false)


    }
    const deleteOffer = async (id, datas) => {
        setdeleteloader(true)
        try {
            const response = await deleteApi(`/creator/service?offerId=${id}`);
            fetchUserOfferings()
            setdeleteloader(false)
            console.log('Delete successful', response);
        } catch (error) {
            setdeleteloader(false)
            console.error('Error deleting offer', error);
        }

    }
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPage(1); // Reset page when search query changes
        setOfferingData([]); // Clear current offerings when search query changes
    };

    const singleService = () => {

    }
    const handleViewAttendee = async (id,x) => {
        setSingleOfferingData(x)
        const response = await getApi(`/creator/service/user?offerServiceId=${id}`)
        if (response?.statusCode === 200) {
            setAttendeeData(response?.data)
            setActiveDropdown(false)
            setAttendeeModal(true)
        }
    }

    const handleClickMesaage = (id,details) =>{
        const userDetailRole = JSON.parse(localStorage.getItem('userDetail'));
        const isCreator = userDetailRole.roles.some(role => role.user_role === "creator");
        if (isCreator) {
            navigate('/creator/messages');
          } else {
            navigate('/user/messages');
          }
    }

    return (
        <div>
            <div className="HomeMainDiv">
                <UserSidebar classState={classState} />
                <div className="creator-offering home min-height-pera journy">
                    <div className="RightSideDiv right-main">
                        <div className='header'>
                            <button className="burger-menu" onClick={handleSidebarClick}>
                                <img src={burger} alt='burger menu' />
                            </button>
                            <h2>Journey</h2>
                            {/* <button className='new-offering' onClick={() => { dispatch(clearOfferingData()); navigate('/creator/offering') }}>
                                <img src={addtag} />
                                Create a new offering
                            </button> */}
                        </div>
                        <div className='top-buttons'>
                            {/* <div className='input-container'>
                                <img src={search} alt="Add tag" />
                                <input
                                    type="text"
                                    placeholder='Find Offerings or Creators'
                                    value={searchQuery}
                                    onChange={handleSearchChange} // Update searchQuery on input change
                                />
                            </div> */}
                            <div class="creator-offering-tab"
                            >  <ul>
                                    <li><a className={status === 1 ? 'active' : ''} onClick={() => { setOfferType('current'); handleTabClick(1) }}>
                                        Current
                                    </a> </li>
                                    <li><a className={status === 2 ? 'active' : ''} onClick={() => { setOfferType('past'); handleTabClick(2) }}>
                                        Past
                                    </a> </li>
                                </ul>
                            </div>

                        </div>

                        <div>
                            <div className='journy-clendar'>
                                <h3>My Offerings</h3>
                                <button className='btn '>View on calendar <span> <img src={details} class="card-img" alt="Yoga class" /></span></button>
                            </div>
                            {/* <div className='fav-div-sec'>
                                <div className=''>
                                    <div className='mb-3'>
                                        <img src={journyimg} alt='' />
                                    </div>
                                    <p>You haven't purchased any products yet. <br></br>
                                    Visit our <span className='fw-bold'> Discovery </span>section to explore what's available!</p>
                                </div>
                            </div> */}

                            <div className="what-are-you">
                                <p>What are you looking for</p>
                                <ul className='jouirny-list'>
                                    <li>
                                        <label className="container2">
                                            Online Session
                                            <input
                                                type="checkbox"
                                                checked={locationType.includes('online')}
                                                onClick={() => handleSetLocationType('online', 1)}
                                                name="check"
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="container2">
                                            Offline Session
                                            <input
                                                type="checkbox"
                                                checked={locationType.includes('offline')}
                                                onClick={() => handleSetLocationType('offline', 2)}
                                                name="check"
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="container2">
                                            Physical Session
                                            <input
                                                type="checkbox"
                                                checked={locationType.includes('physical')}
                                                onClick={() => handleSetLocationType('physical', 3)}
                                                name="check"
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="container2">
                                            Digital Session
                                            <input
                                                type="checkbox"
                                                checked={locationType.includes('digital')}
                                                onClick={() => handleSetLocationType('digital', 4)}
                                                name="check"
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            {/* <input type="checkbox" name="check" checked={locationType.includes('online')} onClick={() => handleSetLocationType('online', 1)} />
                            <label>Online Session</label>
                            <input type="checkbox" name="check" checked={locationType.includes('offline')} onClick={() => handleSetLocationType('offline', 2)} />
                            <label>Offline Session</label>
                            <input type="checkbox" name="check" checked={locationType.includes('physical')} onClick={() => handleSetLocationType('physical', 3)} />
                            <label>Physical Session</label>
                            <input type="checkbox" name="check" checked={locationType.includes('digital')} onClick={() => handleSetLocationType('digital', 4)} />
                            <label>Digital Session</label> */}
                        </div>

                        {pageLoader ? 
                        // <div class="spinner"></div>
                        <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    :
                    <>
                        <div className={`${(offeringData?.length > 0 && offeringData?.length < totalCount) ? 'data-scroll' : 'fordtata'}`}>
                            {offeringData.length === 0 ? (
                                <>
                                    <NoDataFound />
                                </>
                            ) : (
                                <div className="row">
                                    {offeringData.map((x, index) => (
                                        <div className="col-sm-4" key={index}>
                                            <div className="product-box">
                                                <div className="product-img" onClick={() => singleService(x?.offerService?.id, x)} >
                                                    <img src={x?.offerService?.cover_image} alt='' />
                                                    <div className="product-image-data">
                                                        {x?.offerService?.service_type === "1" && (
                                                            <span><img src={x?.offerService?.category && x?.offerService?.category?.category_image} alt='' /></span>
                                                        )}
                                                        <h5>
                                                            {x?.service_type === "2"
                                                                ? (x?.offerService?.product_kind === "0" ? 'Physical' : 'Digital')
                                                                : (x?.offerService?.service_type === "1"
                                                                    ? (x?.offerService?.location_type === "1" ? 'Online'
                                                                        : (x?.offerService?.location_type === "2" ? 'Offline'
                                                                            : (x?.offerService?.location_type === "3" ? 'Hybrid' : '')))
                                                                    : 'Offline')}
                                                        </h5>
                                                        <button><img src={hearts} alt='' /></button>
                                                    </div>
                                                </div>
                                                <div className="product-data" onClick={() => singleService(x?.offerService?.id, x)}>
                                                    <h3>{x?.offerService?.title} </h3>
                                                    <ul className='product-data-tags'>
                                                        {x?.offerService?.tags && x?.offerService?.tags.length > 0 && x?.offerService?.tags.map((y, i) => (
                                                            <li className={i === 0 ? "active" : ''} key={i}>
                                                                {y?.tag_name}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                    <p>{x?.offerService?.description != 'undefined' && x?.offerService?.description}</p>
                                                    <div className='col-md-12'>
                                                        <div className='date-&-time d-flex w-100 pt-2 justify-content-between'>
                                                            {x?.status === null ?
                                                            <>
                                                            <div className='clender d-flex align-items-center '>
                                                                <span ><img src={clender} alt='box' className='pe-1' />{x?.slot?.date}</span>
                                                            </div>
                                                            <div className='clender d-flex align-items-center '>
                                                                <span className='ms-2'><img src={clock} alt='box' className='pe-1' />{x?.slot?.start_time}</span>
                                                            </div>
                                                            </>
                                                            :
                                                            <>
                                                                    {x?.status === '3' &&
                                                                        <div className='clender d-flex align-items-center cursor-pointer'>
                                                                            <span className='ms-2'><img src={readyPickup} alt='box' className='pe-1' />Ready to pick up</span>
                                                                        </div>
                                                                    }
                                                                    {x?.status === '4' &&
                                                                        <div className='clender d-flex align-items-center cursor-pointer'>
                                                                            <span className='ms-2'><img src={clender} alt='box' className='pe-1' />Cancelled</span>
                                                                        </div>
                                                                    }
                                                                    {x?.status === '5' &&
                                                                        <div className='clender d-flex align-items-center cursor-pointer'>
                                                                            <span className='ms-2'><img src={clender} alt='box' className='pe-1' />Completed</span>
                                                                        </div>
                                                                    }
                                                                    {x?.status === '1' &&
                                                                        <div className='clender d-flex align-items-center cursor-pointer'>
                                                                            <span className='ms-2'><img src={clender} alt='box' className='pe-1' />Pending</span>
                                                                        </div>
                                                                    }
                                                                    {x?.status === '2' &&
                                                                        <div className='clender d-flex align-items-center cursor-pointer'>
                                                                            <span className='ms-2'><img src={clender} alt='box' className='pe-1' />Processing</span>
                                                                        </div>
                                                                    }
                                                            </>
}
                                                            <div className='clender d-flex align-items-center cursor-pointer'>
                                                            <span className='action' onClick={() => toggleDropdown(index)}><img src={threedots} alt='action' /></span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    {/* <div className='row archived-data position-relative'>
                                                        <div className='col-md-8'>
                                                            <p className='py-1'>
                                                                <span className='pe-1'><img src={doublebox} alt='box' /></span>
                                                                {status === 1 ? 'Published' : status === 2 ? 'Archived' : status === 3 ? 'Draft' : ''}
                                                            </p>
                                                        </div>
                                                    </div> */}

                                                    <div className="product-data-bottom">
                                                        <span><img src={x?.offerService?.user?.user_profile_image && x?.offerService?.user?.user_profile_image || bannerRight} alt='' /></span>
                                                        <h6><strong>{x?.offerService?.user && x?.offerService?.user?.name}</strong> {x?.offerService?.user?.specializations && x?.offerService?.user?.specializations[0]?.area_of_work}</h6>
                                                    </div>
                                                </div>
                                                {/* <div className='col-md-4 text-end action-pointer-cs'>
                                                    <span className='action' onClick={() => toggleDropdown(x?.serviceSlot?.id)}><img src={threedots} alt='action' /></span>
                                                </div> */}
                                                {/* {activeDropdown === x?.serviceSlot?.id && ( */}
                                                {activeDropdown === index && (
                                                    <div className='arcived-list'>
                                                        <ul>
                                                        
                                                              <li onClick={() => handleClickMesaage(x?.id, x)}>
                                                                <img src={messageCreator} alt='box' />
                                                                Message Creator
                                                            </li>
                                                            <li onClick={() => handleViewAttendee(x?.id, x)}>
                                                                <img src={attendus} alt='box' />
                                                                View Attendees
                                                            </li>
                                                            <li >
                                                                <img src={share} disabled={''} alt='box' />Share
                                                            </li>
                                                            <li onClick={() => setActiveDropdown(null)} >
                                                                <img src={cross} alt='box' />
                                                                Cancel
                                                            </li>
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        {offeringData?.length < totalCount && (
                            <button className="explore" onClick={loadMoreOfferings}>
                                {/* <button className="explore" onClick={loadMoreOfferings} disabled={btnloader}> */}
                                {btnloader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}
                                Load more Offerings
                            </button>
                        )}
                        </>
}
                    </div>
                </div>
            </div>
            <Modal
                isOpen={attendeeModal}
                onRequestClose={() => setAttendeeModal(false)}
                contentLabel="Availbility Modal"
                shouldCloseOnOverlayClick={false}
                ariaHideApp={false}
                portalClassName={`address-modal address-modal-diff-cs view-attendent  select-creator`}
            >
                <button className='close-button ' onClick={() => setAttendeeModal(false)}><img src={cross} alt='' /></button>
                <AttendeeList attendeeData={attendeeData} userData={singleOfferingData}/>

            </Modal>
        </div>
    );
}
export default Journey;
