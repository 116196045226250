import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import burger from '../../../assets/images/burger-menu.svg'
import leftArrow from '../../../assets/images/backarrow.svg'
import UserSidebar from '../../../Layout/userSidebar'
import cross from '../../../assets/images/videocros.svg'
import blankimage from '../../../assets/images/blank-bg.svg'
import { useDispatch } from 'react-redux'
import { insertOfferingData } from '../../../Counter/offeringSLice'
import ActiveLinks from './activeLinks'
import OfferingDetails from './OfferingDetails'
import Modal from 'react-modal';
import OfferingViewData from './offeringViewData'
import { toast } from 'react-toastify'
import LiveModal from './liveModal'
import PublishModal from './publishModal'
import eyeimage from '../../../assets/images/eye-icon.svg'
import dollar from '../../../assets/images/dollar.svg'
import TermsAndPolicy from '../../AuthComponent/termsAndPolicy'
import DraftModal from './draftModal'
import { getApi } from '../../../Services/apiService'
import termscross from '../../../assets/images/gray-close5.svg'
 
function SessionDiscovery({ nextStep, prevStep, step, data, submitForm,adminType,type }) {
  const dispath = useDispatch();
  const [classState, setClassState] = useState(false)
  const [imagePreview, setImagePreview] = useState(data?.coverImage || '');
  const [showImagePreview, setShowImagePreview] = useState(data?.showImagePreview|| data?.cover_image ||'');
  const [title, setTitle] = useState(data?.title || '')
  const [description, setDescription] = useState(data?.description || '')
  const [discoveryCall, setDiscoveryCall] = useState(false)
  const [discoveryAvailability, setDiscoveryAvailbility] = useState('')
  const [discoveryStartTime, setDiscoveryStartTime] = useState('')
  const [discoveryDuration, setDiscoveryDuration] = useState('')
  const [discoveryPrice, setDiscoveryPrice] = useState(data?.discoveryPrice || 'fixed_price')
  // const [socialImages, setSocialImages] = useState([])
  // const [showSocialImages, setShowSocialImages] = useState([])
  const [discoverMinPrice, setDiscoveryMinPrice] = useState(data?.discoveryMinPrice || null)
  const [discoverManPrice, setDiscoveryManPrice] = useState(data?.discoveryMaxPrice || null)
  const [additionalImages, setAdditionalImages] = useState(data?.additionalImages || [])
  // const [additionalImages, setAdditionalImages] = useState(data?.additionalImages || [])
  const [additionalImagesPreview, setAdditionalImagesPreview] = useState([])
  const [errors, setErrors] = useState({});
  const [isModal, setIsModal] = useState(false)
  const [terms, setTerms] = useState(data?.terms || false)
  const [readyPublish, setReadyPublish] = useState(false)
  const [offeringLive, setOfferingLive] = useState(false)
  const [additionalImageType,setAdditionalImageType] = useState('image')
  const [messageUser,setMessageUser] = useState(data?.messageUser || '')
  const [stateModal, setStateModal] = useState(false)
  const [removedImageIds, setRemovedImageIds] = useState([]);
  const [addOptionalImages,setAddOptionalImages] = useState(data?.offerMaterials || [])
  const [termslIsOpen, setTermslIsOpen] = useState(false);
  const [isModals, setIsModals] = useState(false)

  // const [showData,setShowData] = useState('')
  const offeringValue = ['Does not repeat', 'Daily', 'Weekly', 'Monthly', 'Every weekday(from Monday to Friday)', 'Every weekends(Saturday and Sunday)', 'custom...']
  console.log("titletitletitletitle", additionalImages);

  const validateForm = () => {
    const newErrors = {};

    const fiveDigitPattern = /^\d{1,6}$/;
    if (!showImagePreview) {
        newErrors.coverImage = 'Cover image is required.';
    }

    if (!title) {
        newErrors.title = 'Title is required.';
    }
    else if (title.trim().length === 0) {
      newErrors.title = 'Title is required';
  }
  // else if (title.trim() !== title) {
  //   newErrors.title = 'Title cannot start or end with spaces.';
  // }

    if (!description) {
        newErrors.description = 'Description is required.';
    }
    else if (description.trim().length === 0) {
      newErrors.description = 'Description is required';
    }

    // if (!discoveryPrice) {
    //     newErrors.discoveryMinPrice = 'Price is required.';
    // } else if (discoveryPrice === 'variable_price') {
    //     if (!discoverMinPrice && !discoverManPrice) {
    //         newErrors.discoveryMinPrice = 'please fill in at least one price slot.';
    //     }
    //     // if (!discoverManPrice) {
    //     //     newErrors.discoveryManPrice = 'Maximum price is required.';
    //     // }
    // } else if (discoveryPrice === 'fixed_price' && !discoverMinPrice) {
    //     newErrors.discoveryMinPrice = 'Fixed price is required.';
    // }

    if (!discoveryPrice) {
      newErrors.discoveryMinPrice = 'Price is required.';
    } else if (discoveryPrice === 'variable_price') {
      if (!discoverMinPrice.trim() && !discoverManPrice.trim()) {
        newErrors.discoveryMinPrice = 'Please fill in at least one price slot.';
      } else if (discoverMinPrice.trim() !== discoverMinPrice) {
        newErrors.discoveryMinPrice = 'Minimum price cannot start or end with spaces.';
      } else if (!fiveDigitPattern.test(discoverMinPrice.trim())) {
      // } else if (discoverMinPrice && !fiveDigitPattern.test(discoverMinPrice.trim())) {
        newErrors.discoveryMinPrice = 'Minimum price too large';
      }
  
      if (discoverManPrice.trim() !== discoverManPrice) {
        newErrors.discoveryManPrice = 'Maximum price cannot start or end with spaces.';
      } else if (!fiveDigitPattern.test(discoverManPrice.trim())) {
        newErrors.discoveryManPrice = 'Maximum price too large ';
      }  else if(Number(discoverMinPrice) >= Number(discoverManPrice)){
        newErrors.discoveryManPrice = 'Maximum price is greater than minimum price';
      }
    } else if (discoveryPrice === 'fixed_price') {
      if (!discoverMinPrice) {
        newErrors.discoveryMinPrice = 'Fixed price is required.';
      } else if (discoverMinPrice.trim() !== discoverMinPrice) {
        newErrors.discoveryMinPrice = 'Fixed price cannot start or end with spaces.';
      } else if (!fiveDigitPattern.test(discoverMinPrice.trim())) {
        newErrors.discoveryMinPrice = 'price too large';
      } 
    }

    // if (!terms) {
    //     newErrors.terms = 'You must agree to the terms.';
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    const handleClick = () => setClassState(false);

    document.addEventListener('click', handleClick);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [])
  const handleSidebarClick = (event) => {
    event.stopPropagation();
    setClassState(!classState);
  };

  const handleFileChange = (e, type) => {
    // setUpdateProfileData(true)
    const file = e.target.files[0];
    if (file) {
      if (type === 'cover') {
        setImagePreview(file)
      } else {
        setAdditionalImagesPreview(prevState => [...prevState, file])
      }

      // setNewCategory(prevState => ({ ...prevState, image: file }));
      const reader = new FileReader();

      reader.onloadend = () => {
        if (type === 'cover') {
          setShowImagePreview(reader.result);
        } else {
          setAdditionalImages(prevState => [...prevState, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
        if (file.type.startsWith('image/')) {
          setAdditionalImagesPreview(prevState => [...prevState, file])
            const reader = new FileReader();
            reader.onloadend = () => {
                setAdditionalImages(prevState => [...prevState, reader.result]);
            };
            reader.readAsDataURL(file);
        } else {
            toast.error('Please upload an image file');
        }
    }
};

const handleVideoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
        if (file.type.startsWith('video/')) {
          setAdditionalImagesPreview(prevState => [...prevState, file])
            const reader = new FileReader();
            reader.onloadend = () => {
                setAdditionalImages(prevState => [...prevState, reader.result]);
            };
            reader.readAsDataURL(file);
        } else {
            toast.error('Please upload a video file');
        }
    }
};

  const handleSave = async(type) => {

    const values = {
      title: title,
      description: description,
      discoveryCall: discoveryCall,
      discoveryAvailability: discoveryAvailability,
      discoveryStartTime: discoveryStartTime,
      discoveryDuration: discoveryDuration,
      discoveryPrice: discoveryPrice,
      discoveryMinPrice: discoverMinPrice,
      discoveryMaxPrice: discoverManPrice,
      coverImage: imagePreview,
      additionalImagesPreview: additionalImagesPreview,
      showImagePreview: showImagePreview,
      additionalImages: additionalImages,
      messageUser:messageUser,
      removedImageIds:removedImageIds,
      step:step

    }
    // if(viewData === 'viewData'){
    //   values.showData = 'viewData'
    // }s
    // if()
    if(type=== 'prev'){
      dispath(insertOfferingData(values))
      prevStep()
    }
    else{
    if (validateForm()) {
      if (terms) {
        localStorage.setItem('imagePreview',showImagePreview)
        dispath(insertOfferingData(values))
        // nextStep()
        // submitForm()
        if(type=== 'draft'){
          // submitForm('draft',values)
          setStateModal(true)
          // if(type === 'product'){
      
          // } else{
          const resp = await submitForm('draft',values)
          if(resp === true){
            setStateModal(false)
            setIsModals(true)
          } else{
            setStateModal(false)
          }
      }
      else{
        setReadyPublish(true);
      }
        
      }
      else {
        toast.error("Please accept the Terms and Privacy Policy to proceed.")
      }
    }
  }

    // if(viewData === 'viewData'){
    //   nextStep('viewData')
    // }
    // else{
    // submitForm()
    // }
  }

  const closeModal = () => {
    setIsModal(false);
  }

  const handleViewModal = () => {
    const values = {
      title: title,
      description: description,
      discoveryCall: discoveryCall,
      discoveryAvailability: discoveryAvailability,
      discoveryStartTime: discoveryStartTime,
      discoveryDuration: discoveryDuration,
      discoveryPrice: discoveryPrice,
      discoveryMinPrice: discoverMinPrice,
      discoveryMaxPrice: discoverManPrice,
      coverImage: imagePreview,
      additionalImagesPreview: additionalImagesPreview,
      showImagePreview: showImagePreview,
      additionalImages: additionalImages,
      messageUser:messageUser,
      removedImageIds:removedImageIds,
      step:step

    }
    // if(viewData === 'viewData'){
    //   values.showData = 'viewData'
    // }
    // if()
    if (validateForm()) {
      if (terms) {
        // localStorage.setItem('imagePreview',showImagePreview)
        const dataSize = new Blob([showImagePreview]).size;
if (dataSize < 5000000) { // ~5MB limit
  localStorage.setItem('imagePreview', showImagePreview);
} else {
  console.error('Data too large for localStorage');
}
        dispath(insertOfferingData(values))
        // setIsModal(true)
        nextStep()
      }
      else {
        toast.error("Please accept the Terms and Privacy Policy to proceed.")
      }
      // nextStep()
      // submitForm()
    }
  }
  const closeModals = (type) => {
    
    if (type === 'ready') {
      setReadyPublish(false)
    }
    if (type === 'live') {
      localStorage.removeItem('imagePreview')
      setOfferingLive(false)
    }
  }
  const handlePublish = (type) => {
    if (type === 'ready') {
      setReadyPublish(true)
    }
    if (type === 'live') {
      setReadyPublish(false)
      setOfferingLive(true)
    }
  }

  const handleRemoveMedia = (indexToRemove,ids) => {

    if (ids !== '') {
      setRemovedImageIds(prevIds => [...prevIds, ids]);
      setAddOptionalImages(prevState =>
        prevState.filter(image => image?.id !== ids)
      );
    }
    if(ids === ''){
    setAdditionalImages(prevState => 
      prevState.filter((_, index) => index !== indexToRemove)
    );
    setAdditionalImagesPreview(prevState => 
      prevState.filter((_, index) => index !== indexToRemove)
    );
  }
  };

  const openTermsModal = ()=>{

    setTermslIsOpen(true)
  }
  const closeTermsIsOpen = () => {
    setTermslIsOpen(false)

  }

  const handleDiscoveryCall = async() =>{
    const response = await getApi('/creator/discovery/status')
    if(response?.statusCode === 200){
      if(response?.data == true){
        setDiscoveryCall(true)
      } else{
        toast.error('Discovery call availability is currently disabled. Please enable it in the settings to allow this option')
        setDiscoveryCall(false)
      }
    } 
  }

  const updateReduxData = (updatedValues) => {
    dispath(insertOfferingData(updatedValues));
  };

  useEffect(() => {
    if (data?.editype === 'edit') {
      const updatedData = {
        title: title,
      description: description,
      discoveryCall: discoveryCall,
      discoveryAvailability: discoveryAvailability,
      discoveryStartTime: discoveryStartTime,
      discoveryDuration: discoveryDuration,
      discoveryPrice: discoveryPrice,
      discoveryMinPrice: discoverMinPrice,
      discoveryMaxPrice: discoverManPrice,
      coverImage: imagePreview,
      additionalImagesPreview: additionalImagesPreview,
      showImagePreview: showImagePreview,
      additionalImages: additionalImages,
      messageUser:messageUser,
      removedImageIds:removedImageIds,
      };

      updateReduxData(updatedData);
    }
  }, [
    title,
    description,
    discoveryCall,
    discoveryAvailability,
    discoveryStartTime,
    discoveryDuration,
    discoveryPrice,
    discoverMinPrice,
    discoverManPrice,
    imagePreview,
    additionalImagesPreview,
    showImagePreview,
    additionalImages,
    messageUser,
    removedImageIds,
  ]);

  return (
    <div>
      <UserSidebar classState={classState} />
        <div className='right-main'>
      <div class="session where before-publish">
          <div class="header">
            <h1><button class="burger-menu" onClick={handleSidebarClick}><img src={burger} alt='' /></button>Session or Service</h1>
            {type!== 'edit' &&
                    <>
            <div class="top-buttons">
              <button class="save-draft" onClick={()=>handleSave('draft')} disabled={stateModal}>{stateModal && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Save to Draft</button>
              <button class="next" onClick={() => handleSave()}>Next</button>
              <button class="eye" onClick={() => handleViewModal()}><img src={eyeimage} alt=''/></button>
            </div>
            </>
            }
          </div>
          {/* <!-- active links --> */}
          {type!== 'edit' &&
                    <>
          <div class="active-links">

            <button className="back" onClick={() => handleSave('prev')}><img src={leftArrow} alt='' /> </button>

            <ActiveLinks step={step} />
          </div>
          </>
            }




          {/* <!-- Main --> */}
          <div class="main">
            <div class="row">
              <div class="col-sm-7">
                <section class="first">
                  <h6>Cover image *</h6>
                  <div class={showImagePreview ? "cover-image now-select" :"cover-image"}>
                    {/* <button class="select-image">Select Image</button> */}
                    {showImagePreview && <img src={showImagePreview} alt='' class="browser-image" />}
                    {data?.cover_image && <img src={data?.cover_image} alt='' class="browser-image" />}
                    {/* <input type='file' name='coverImage' placeholder='Select Image' onChange={(e) => handleFileChange(e, 'cover')} /> */}
                    <div class="upload_file_container">
                      {showImagePreview ? 'Change Image' :'Select Image'}
                      <input type="file" name="photo" accept=".png,.jpg,.jpeg,.avif,.svg" onChange={(e) => handleFileChange(e, 'cover')} />
                    </div>

                    <p>This image appears in the Microcosm Library, Discover and Profile pages. Your image should be JPG, PNG or GIF format up to 5 MB</p>
                    {errors.coverImage && <div className="error">{errors.coverImage}</div>}
                  </div>
                </section>
                <section class="second">
                  <h6 class="pt-4 pb-2">Details</h6>
                  <div class="details">
                    <form action="detail-section">
                      <div class="title">
                        <label for="title">Title *</label>
                        <input type="text" id="title" placeholder="My Title" value={title} onChange={(e) => setTitle(e?.target?.value)} />
                        {errors.title && <div className="error">{errors.title}</div>}
                      </div>
                      <div class={`description ${description && 'dailyinputenter'}`}>
                        <label for="description">Description *</label>
                        <textarea id="description" name="description" placeholder="Add more details about Your offer" value={description} onChange={(e) => setDescription(e?.target?.value)} ></textarea>
                        {errors.description && <div className="error">{errors.description}</div>}
                      </div>
                    </form>
                  </div>
                </section>

                <section class="third">
                  <div class="switch-button">
                    <label class="switch">
                      <input type="checkbox" onClick={()=>handleDiscoveryCall()} checked={discoveryCall} />
                      {/* <input type="checkbox" onClick={()=>setDiscoveryCall(!discoveryCall)} /> */}
                      <span class="slider"></span>
                    </label>
                    <h3>Discovery call</h3>
                  </div>
                  <p>This session will help you discover the ideal service or tailor a customized experience for the client. It's a complimentary consultation lasting 15-20 minutes, where you can understand the client's preferences and tailor the session accordingly.</p>
                </section>

                {/* <section class="third">
                  <h3>When can you afford free consultation?</h3>
                  <div className="offering top">
                    <h6>Choose type of offering </h6>
                    <form action="">
                      <select name="cars" id="cars" >
                        {offeringValue.map((x, index) => {
                          return (
                            <option key={index} value={x}>{x}</option>
                          )
                        })}
                      </select>
                    </form>
                  </div>
                </section> */}

                <section class="fourth">
                  <div class="size-range toggler-button">
                    <div class="bottom-buttons">
                      <label class="radio-check3">
                        <input type="radio" checked={discoveryPrice === 'fixed_price'} name="radio" onClick={() => setDiscoveryPrice('fixed_price')} />
                        <span class="checkmark">Fixed price</span>
                      </label>
                      <label class="radio-check3">
                        <input type="radio" checked={discoveryPrice === 'variable_price'} name="radio" onClick={() => setDiscoveryPrice('variable_price')} />
                        <span class="checkmark">Variable price</span>
                      </label>
                    </div>
                  </div>
                  <div class="row">

                    <div class="col-sm-6">
                      <div class="minimum min-session">
                        <label for="minimum ">{discoveryPrice === 'fixed_price' ? 'Price *' : 'Minimum *'}</label>
                        <input type="number" id="minimum" placeholder="0.00" value={discoverMinPrice} onChange={(e) => (e?.target?.value >= 1 || e.target.value === '') && setDiscoveryMinPrice(e?.target?.value)} min={1}/>
                        <img src={dollar} class="input-img" alt='' />
                        {errors.discoveryMinPrice && <div className="error">{errors.discoveryMinPrice}</div>}
                      </div>
                    </div>
                    {discoveryPrice === 'variable_price' &&
                    <div class="col-sm-6">
                      <div class="recommended min-session">
                        <label for="recommended">Maximum *</label>
                        <input type="number" id="recommended" placeholder="0.00" value={discoverManPrice} onChange={(e) => (e?.target?.value >= 1 || e.target.value === '') && setDiscoveryManPrice(e?.target?.value)} min={1} />
                        <img src={dollar} class="input-img" alt='' />
                        {errors.discoveryManPrice && <div className="error">{errors.discoveryManPrice}</div>}
                      </div>
                    </div>
} 
<div class="col-sm-12">
  {discoveryPrice === 'variable_price' &&
                      <div class={`minimum ${messageUser && 'dailyinputenter'}` }>
                        <label for="minimum">Message to users (optional)</label>
                        <textarea type="text" id="textarea" placeholder="Tell  you users why you supoort a sliding scale" value={messageUser} onChange={(e) => {e?.target?.value.trim() !== '' ? setMessageUser(e?.target?.value) : setMessageUser('')}} />
                        {/* <img src="images/dollar.svg" class="input-img" alt='' /> */}
                      </div>}
                    </div>
                  </div>
                </section>
                <section class="fifth">
                  <h5>Additional Materials</h5>
                  <h6>Add the file which help</h6>
                  <div class="additional">
                    <div class="top">
                      <button class="image" onClick={()=>setAdditionalImageType('image')} className={additionalImageType === 'image' && 'active'}>Image</button>
                      <button class="video" onClick={()=>setAdditionalImageType('video')} className={additionalImageType === 'video' && 'active'}>Video</button>
                    </div>
                    <div class="middle">
                      {/* <button class="select-video">Select Video</button> */}
                      {/* <input type='file' placeholder='Select Video' onChange={(e) => handleFileChange(e, 'additional')} /> */}
                      {additionalImageType === 'image' ?
                      <div class="upload_file_container">
                        Select Image
                        <input type="file" name="photo" accept=".png,.jpg,.jpeg,.avif,.svg,.webp" onChange={(e) => handleImageUpload(e, 'additional')} />
                      </div>
                      :
                      <div class="upload_file_container">
                        Select Video
                        <input type="file" name="photo" onChange={(e) => handleVideoUpload(e, 'additional')} />
                      </div>
}
{additionalImageType === 'image' ?
                      <p class="video-data">Any png,jpg,jpeg,avif,svg,webp file</p>
                      :
                      <p class="video-data">Any MP4, AVi files up to 200 MB</p>
}
                    </div>
                    <div class="bottom">
                      <ul>
                        {addOptionalImages && addOptionalImages.length > 0 && addOptionalImages.map((x, index) => {
                          return (
                            <>
                            {
                            x?.media_type === 'image' ?
                            <li key={index}> <button class="button" onClick={() => handleRemoveMedia(index,x?.id)}><img src={cross} class="cross-btn" alt='' /></button> <img src={x?.file_url} alt='' /> </li>
                            :
                            
                            <li key={index}> <button class="button" onClick={() => handleRemoveMedia(index,x?.id)}> <img src={cross} class="cross-btn" alt='' /></button> <video controls><source src={x?.file_url} type='video/mp4' /></video></li>
                        }
                            </>
                          )
                        })
                        }
                        {additionalImages && additionalImages.length > 0 && additionalImages.map((x, index) => {
                          return (
                            <>
                            {
                            x?.startsWith("data:image/") ?
                            <li key={index}> <button class="button" onClick={() => handleRemoveMedia(index,'')}><img src={cross} class="cross-btn" alt='' /></button> <img src={x} alt='' /> </li>
                            :
                            
                            <li key={index}> <button class="button" onClick={() => handleRemoveMedia(index,'')}> <img src={cross} class="cross-btn" alt='' /></button> <video controls><source src={x} type='video/mp4' /></video></li>
                        }
                            </>
                          )
                        })
                        }
                        {/* <video width='600' controls>
                          <source src='' type='video/mp4' />
                        </video> */}
                        {/* <li> <button class="button"><img src={cross} class="cross-btn" alt='' /></button> <img src={blankimage} alt='' /> </li>
                        <li> <button class="button"><img src={cross} class="cross-btn" alt='' /></button> <img src={blankimage} alt='' /> </li>
                        <li> <button class="button"><img src={cross} class="cross-btn" alt='' /></button> <img src={blankimage} alt='' /> </li>
                        <li> <button class="button"><img src={cross} class="cross-btn" alt='' /></button> <img src={blankimage} alt='' /> </li> */}
                      </ul>
                    </div>
                  </div>
                </section>
                <section class="footer">
                  <label class="custom-checkbox">
                    <input type="checkbox" checked={terms} onClick={() => setTerms(!terms)} />
                    <span class="checkmark"></span>
                  </label>
                  <p>I agree to microcosm’s <span onClick={() => openTermsModal()}> Terms of Use and Privacy Policy </span></p>
                </section>

              </div>
              {type !== 'edit' &&
              <OfferingDetails data={data} />
}

              {/* <Modal
                isOpen={isModal}
                onRequestClose={() => closeModal('user')}
                contentLabel="Login Modal"
                shouldCloseOnOverlayClick={false}
              >
                <button className='close-button' onClick={() => closeModal('user')}><img src={cross} alt='' /></button>
                <OfferingViewData closeModal={closeModal} nextStep={nextStep} prevStep={prevStep} step={step} data={data} submitForm={submitForm} />
              </Modal> */}


              <Modal
                isOpen={readyPublish}
                onRequestClose={() => closeModals('ready')}
                contentLabel="Publish Modal"
                shouldCloseOnOverlayClick={false}
                portalClassName="creatorLiveModal creatiive-before-publish publish-cross-set"
              >
                {/* <button className='close-button' onClick={() => closeModals('ready')}><img src={cross} alt='' /></button> */}
                <PublishModal submitForm={submitForm} handlePublish={handlePublish} closeModal={closeModals} type={''} setIsModal={setIsModals} offerType={'service'}/>
                {/* <h3>Ready To Publish</h3>
                <h4>Once you publish your first product, your offer will go live. People will be able to see the offering page tab and purchase your products</h4>
                <button onClick={() => submitForm('draft')}>Save To Draft</button>
                <button onClick={() => handlePublish('live')}>Publish</button> */}
              </Modal>
              <Modal
                isOpen={offeringLive}
                onRequestClose={() => closeModals('live')}
                contentLabel="Publish Modal"
                shouldCloseOnOverlayClick={false}
                portalClassName="creatorPublishModal"
              >
                {/* <button className='close-button' onClick={() => closeModals('live')}><img src={cross} alt='' /></button> */}
                <LiveModal submitForm={submitForm} closeModal={closeModals} type={''} imagePreview={showImagePreview} adminType={adminType}/>
                {/* <h3>Your offering is live</h3>
                <button onClick={() => submitForm('publish')}>Done</button> */}
              </Modal>
              <Modal
                isOpen={termslIsOpen}
                onRequestClose={closeTermsIsOpen}
                ariaHideApp={false}
                shouldCloseOnOverlayClick={false}
                overlayClassName={`modal-overlay terms-condition`}
            >
              
              <button className='close-button' onClick={() => closeTermsIsOpen()}><img src={termscross} alt='' /></button>
              <TermsAndPolicy setTerms={setTerms} closeTermsIsOpen={closeTermsIsOpen}/>
                
            </Modal>

            </div>
          </div>
        </div>
      </div>
      <DraftModal setIsModal={setIsModals} isModal={isModals} steps={'final'}/>
    </div>
  )
}

export default SessionDiscovery