import React, { useEffect, useState, useCallback } from 'react';
import UserSidebar from '../../Layout/userSidebar';
import Calendar from 'react-calendar';
import burger from '../../assets/images/burger-menu.svg';
import 'react-calendar/dist/Calendar.css';
import add from '../../assets/images/add-slot.svg';
import cross from '../../assets/images/cross-button.svg';
import reset from '../../assets/images/reset.svg';
import AvailabilityModal from './availabilityModal';
import Modal from 'react-modal';
import { getApi, postApi } from '../../Services/apiService';

function AdminAvailability() {
  const [classState, setClassState] = useState(false);
  const [value, onChange] = useState(new Date());
  const [activeStartDate, setActiveStartDate] = useState(new Date());
  const [selectedDays, setSelectedDays] = useState([]);
  const [timeSlots, setTimeSlots] = useState({});
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [sameForAllDays, setSameForAllDays] = useState(true);  // Set initial state to true
  const [isModal, setIsModal] = useState(false);
  const [dataFromModal, setdataFromModal] = useState(''); // State to store startTime
  const [apiDates, setApiDates] = useState([]); // State to store dates from API
  const [availableTimes, setAvailableTimes] = useState([]);
  const [slotIdMap, setSlotIdMap] = useState({});
  const [selectedDate,setSelectedDate] = useState({});

  console.log('this is available times',availableTimes);


  const handleDayClick = (day) => {
    if (selectedDays.includes(day)) {
      setSelectedDays((prev) => prev.filter((d) => d !== day));
      setTimeSlots((prev) => {
        const updatedSlots = { ...prev };
        delete updatedSlots[day];
        return updatedSlots;
      });
    } else {
      setSelectedDays((prev) => [...prev, day]);
      if (sameForAllDays) {
        const commonSlot = timeSlots[selectedDays[0]] ? timeSlots[selectedDays[0]][0] : { from: '', to: '' };
        setTimeSlots((prev) => ({ ...prev, [day]: [commonSlot] }));
      } else {
        setTimeSlots((prev) => ({ ...prev, [day]: [{ from: '', to: '' }] }));
      }
    }
  };

  const closeModal = () => {
    setIsModal(false);
  };

  const getDayOfWeek = (date) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[date.getDay()];
  };

  const handleActiveStartDateChange = useCallback(({ activeStartDate }) => {
    setActiveStartDate(activeStartDate);
    setSelectedDate('');  // Clear the selected date when the month changes
    setAvailableTimes('')
    // Fetch availability for the new month
    // getAvailability();
  }, []);
  console.log('thisi s is ssisiissisi', activeStartDate);

  const isDaySelected = (date) => {
    const dayOfWeek = getDayOfWeek(date);
    const today = new Date();
    return selectedDays.includes(dayOfWeek) && date > today && date.getMonth() === today.getMonth();
  };

  const isCurrentDate = (date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const handleTimeSlotClick = (slot) => {
    setSelectedTimeSlot(slot);
  };

  const fullDayNames = {
    Mo: 'Monday',
    Tu: 'Tuesday',
    We: 'Wednesday',
    Th: 'Thursday',
    Fr: 'Friday',
    Sa: 'Saturday',
    Su: 'Sunday',
  };

  const handleSidebarClick = (event) => {
    event.stopPropagation();
    setClassState(!classState);
  };

   // Fetch availability based on activeStartDate (when the calendar's active month changes)
  useEffect(() => {
    const getAvailability = async () => {
      try {
        const month = activeStartDate.getMonth() + 1; // getMonth() is zero-indexed
        const year = activeStartDate.getFullYear();
        const formattedDate = `${month}-${year}`;

        // API call to fetch available dates for the active month
        const response = await getApi(`/admin/availbility-date?monthYear=${formattedDate}`);
        const dates = response.data;

        // Store API dates in state
        setApiDates(dates.map(dateString => new Date(dateString)));
      } catch (error) {
        console.error('Error fetching availability:', error);
        setApiDates([]); // Clear apiDates if error occurs
      }
    };

    getAvailability();
  }, [activeStartDate]); 

  const getFormattedMonthYear = (activeStartDate) => {
    const month = activeStartDate.getMonth() + 1;  // getMonth() is zero-indexed
    const year = activeStartDate.getFullYear();
    
    // Return the formatted date as "M-YYYY"
    return `${month}-${year}`;
  };
  

  const getAvailability = async () => {
    try {
      // Extract the month and year from activeStartDate
      const month = activeStartDate.getMonth() + 1; // getMonth() is zero-indexed, so we add 1
      const year = activeStartDate.getFullYear();
      
      // Format it as "9-2024" or "MM-YYYY"
      const formattedDate = `${month}-${year}`;
  
      // Now make the API call with the formatted date
      const response = await getApi(`/admin/availbility-date?monthYear=${formattedDate}`);
      const dates = response.data;
      setApiDates(dates.map(dateString => new Date(dateString)));
    } catch (error) {
      console.error('Error fetching availability:', error);
    }
  };
  const getNextDayOfWeek = (startDate, dayOfWeek) => {
    const date = new Date(startDate);
    let diff = (dayOfWeek - date.getDay() + 7) % 7;  // Use `let` instead of `const`
    
    if (diff === 0) diff = 7;  // Ensure we move to the next week if it's the same day
    
    date.setDate(date.getDate() + diff);
    return date;
  };
  
  

  const tileClassName = ({ date, view }) => {
    let className = '';
    const dayOfWeek = date.getDay();
    const currentMonth = date.getMonth();
    const isCurrentMonth = currentMonth === activeStartDate.getMonth();
    
    // Get the current date and determine next Monday and Tuesday
    const today = new Date();
    const isToday = date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
    const nextMonday = getNextDayOfWeek(today, 1); // 1 is Monday
    const nextTuesday = getNextDayOfWeek(today, 2); // 2 is Tuesday
  
    // Check if the date is from the previous month
    if (view === 'month' && date.getMonth() < activeStartDate.getMonth()) {
      className = 'previous-date'; // Add class for previous month dates
    }
    // Check if the date is from the next month in month view (we have added class previous month because it has css class)
    if (view === 'month' && date.getMonth() > activeStartDate.getMonth()) {
      className += 'previous-date '; // Add class for next month dates
    }
  
    // Check if the date is in the current month and apply 'highlight' class for selectedDays
    if (isCurrentMonth) {
      if (
        selectedDays.includes(getDayOfWeek(date)) &&
        (date > nextMonday || date > nextTuesday)
      ) {
        className += ' highlight';
      }
    }
  
    // Highlight if the date is in the API dates
    if (isDateInApiDates(date)) {
      className = 'highlight'; // Ensure 'highlight' is applied for API dates
    }
  
    // Add 'sanket' class if there are dates in apiDates, and the date is not today or in apiDates
    if (apiDates.length > 0 && !isToday && !isDateInApiDates(date)) {
      className += ' sanket';
    }
  
    // Additional class for current date
    if (isToday) {
      className += ' black-current';
    }
  
    // Add 'yellow' class if the date is the selected date and apiDates has data
    if (
      apiDates.length > 0 && selectedDate &&
      date.getDate() === new Date(selectedDate).getDate() &&
      date.getMonth() === new Date(selectedDate).getMonth() &&
      date.getFullYear() === new Date(selectedDate).getFullYear()
    ) {
      className += ' yellow';
    }
  
    return className.trim();
  };
  
  

  const handleModalClose = (availabilityData) => {
    console.log('this is from modal',availabilityData);
    setIsModal(false);
    if(availabilityData === true){
      getAvailability()
      setSelectedDays('')
    }

};


// Helper function to check if a date is in the array of available dates
const isDateInApiDates = (date) => {
  return apiDates.some(apiDate => 
    apiDate.getDate() === date.getDate() &&
    apiDate.getMonth() === date.getMonth() &&
    apiDate.getFullYear() === date.getFullYear()
  );
};
console.log('data form modal',dataFromModal);

const formatDateForSlotsAPI = (date) => {
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
};

const fetchSlotsData = useCallback(async (formattedDate) => {
  try {
    const response = await getApi(`/admin/availbility-slot?date=${formattedDate}`);
    console.log('Service slots:', response.data);
    const slots = response.data;
     // Create a map of slot time to ID
     const idMap = slots.reduce((acc, slot) => {
      acc[slot.start_time] = slot.id;
      return acc;
    }, {});
    setAvailableTimes(slots); // Save the entire slot object
    setSlotIdMap(idMap);
  } catch (error) {
    console.error('Error fetching slots data:', error);
  }
}, []);

const handleDateClick = useCallback(async (date) => {
  const formattedDate = formatDateForSlotsAPI(date);
  await fetchSlotsData(formattedDate);
  setSelectedDate(formattedDate)
}, [fetchSlotsData]);

const convertTo12HourFormat = (time24) => {
  const [hours, minutes] = time24.split(':');
  let hours12 = parseInt(hours, 10);
  const ampm = hours12 >= 12 ? 'PM' : 'AM';
  hours12 = hours12 % 12 || 12; // Convert 0 to 12 for midnight
  return `${hours12}:${minutes} ${ampm}`;
};

 const resetSlot = async () => {
  try {
    const response = await getApi(`/admin/reset-slot?date=${getFormattedMonthYear(activeStartDate)}`);
    console.log('Service slots:', response.data);
  } catch (error) {
    console.error('Error fetching slots data:', error);
  }
 }

  return (
    <div className="users meal-suggestion">
      <div className="HomeMainDiv">
        <UserSidebar classState={classState} />
        <div className="right-main reset avalibility-heading">
          <h2>
            <button className="burger-menu" onClick={handleSidebarClick}>
              <img src={burger} alt='burger menu' />
            </button>
            Availability
          </h2>
          {/* {apiDates.length !== 0 && (
            <button className='reset' onClick={() =>  resetSlot()}>
              <img src={reset} alt='reset' />Reset
            </button>
          )
          } */}


        {apiDates.length === 0 && 
          <div className='availability-top avalibilty-check avalibilty-check-radius'>
            <ul>
              {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
                <li
                  key={day}
                  className={selectedDays.includes(day) ? 'active' : ''}
                >
                  <label
                    className="aval-check"
                  >
                    <input
                      type="checkbox"
                      checked={selectedDays.includes(day)}
                      onClick={() => handleDayClick(day)}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <span onClick={() => handleDayClick(day)}>{day}</span>
                </li>
              ))}
            </ul>
          </div>
          }

          <div className="users meal-suggestion">
            <div className="availability-main">
              <div className="row">
                <div className="col-sm-6">
                  <div className='discovery-call-left'>
                    <div className="select-date">
                      <div className="calender">
                        <h4>Select date</h4>
                        <Calendar
                      onChange={onChange}
                      value={value}
                      onActiveStartDateChange={handleActiveStartDateChange}
                      tileClassName={tileClassName}

                      tileDisabled={({ date }) => {
                        return apiDates.length > 0 && !isDateInApiDates(date);
                      }}
                      
                      onClickDay= {apiDates.length >0 &&(handleDateClick) }

                    />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-6'>
                  <div className='discovery-call-right'>
                  {apiDates.length === 0 ? (
                      <div className='select-days'>
                        {/* <h4>Select Time</h4> */}
                        <div className='row'>
                          <p>Please select day from the week to add available time slots.</p>
                        </div>
                      </div>
                    ) :availableTimes?.length > 0 ? (
                      <div className='select-days'>
                        <h4>Available Time Slots for {selectedDate}</h4>
                        <div className='row avalibility-scroll'>
                          {availableTimes.map((slot, index) => (
                            <div className='col-xl-4 col-lg-6' key={index}>
                              <div
                                className={`duration ${selectedTimeSlot === slot.start_time ? 'active' : ''}`}
                                onClick={() => handleTimeSlotClick(slot.start_time)}
                              >
                                {convertTo12HourFormat(slot.start_time)} - {convertTo12HourFormat(slot.end_time)}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : apiDates.length !==0 &&  (
                      <div className='select-days'>
                        <h4>Select Time</h4>
                        <div className='row'>
                          <p>Please select date from the left calendar to add/view available time slots.</p>
                        </div>
                      </div>
                    )}
                    
                    {apiDates.length === 0 &&
                     <button onClick={() =>  setIsModal(true)}
                     className={`save-sch ${selectedDays.length === 0 ? 'disabled' : ''}`}
                     disabled={selectedDays.length === 0}
                   >
                     Update availability
                   </button>
                    }
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModal}
        onRequestClose={() => closeModal()}
        contentLabel="Login Modal"
        portalClassName="availability-modal"
        shouldCloseOnOverlayClick={false}
      >
  <AvailabilityModal data={selectedDays}  handleModalClose={handleModalClose} modalStartDate={activeStartDate} /> {/* Pass selectedDays as data */}
      </Modal>

    </div>
  );
}

export default AdminAvailability;
