import * as Yup from 'yup';

export const loginValidation = Yup.object().shape({
  email: Yup.string().transform((value) => value.trim()).email('Invalid email address').matches(/^\S+@\S+\.\S+$/, 'Invalid email address format').required('Email is required'),
  password: Yup.string().transform((value) => value.trim()).required('Password is required')
});

export const userDetailValidation = Yup.object({
  name: Yup.string()
    .required('Name is required')
    .test(
      'not-only-spaces',
      'Name cannot be empty spaces',
      (value) => value && value.trim().length > 0
    )
    .min(1, 'Name is too short - should be 2 Characters minimum')
    .max(30, 'Name is too long - should be 30 Characters maximum'),
    // .matches(/^[^\s].*[^\s]$/, 'Name cannot start or end with a space'),
  email: Yup.string()
  .transform((value) => value.trim())
    .email('Invalid email address')
    .required('Email is required')
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'Invalid email format'
    ),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password is too short - should be 8 Characters minimum')
    .matches(/[a-zA-Z]/, 'Password can only contain Latin letters'),
});

export const userIdentityValidation = Yup.object({
  gender: Yup.string().required('Gender is required'),
  // otherGender: Yup.string().when('gender', {
  //   is: 'other',
  //   then: Yup.string().required('Please specify the gender identity'),
  // }),
  otherGender: Yup.string().optional(),
});

//   const urlValidation = Yup.string().url({
//   allowLocal: true, // Allow localhost URLs
//   allowDataUrl: true, // Allow data URLs
//   schemes: ['http', 'https'] // Allow http and https schemes
// });
export const creatorAboutValidation = Yup.object({
  workArea: Yup.string()
    .required('Area of work is required')
    .test(
      'not-only-spaces',
      'Workarea cannot be empty spaces',
      (value) => value && value.trim().length > 0
    )
    // .matches(/^[^\s].*[^\s]$/, 'Name cannot start or end with a space')
    .matches(/^[^0-9]*$/, 'Work area cannot contain numbers'),
  experience: Yup.string()
  .typeError('Experience must be a number')
  .required('Experience is required')
  // .matches(/^\d{1,2}$/, 'Experience must be a maximum of 2 digits'),
  .matches(
    /^\d{1,2}(\.\d{1,2})?$/,
    'Experience must be up to 2 digits'
  ),
  experienceData: Yup.string()
    .required('Experience period is required'),
  link: Yup.string()
    .url('Invalid URL format')
    .required('Link is required'),
  country: Yup.string()
    .required('Country is required')
    .matches(/^\S.*$/, 'Country cannot contain only spaces'),
  city: Yup.string()
    .required('City is required')
    .matches(/^\S.*$/, 'City cannot contain only spaces'),
});

export const bookingInterviews = Yup.object().shape({
  selectedTimes: Yup.string().required('Please select a time'),
  price: Yup.number().min(20, 'Price must be at least $20').max(100, 'Price cannot exceed $100').required('Price is required')
});
export const bookingInterviewsCreator = Yup.object().shape({
  selectedTimes: Yup.string().required('Please select a time'),
  // selectedDate: Yup.string().required('date is required')
  // price: Yup.number().min(20, 'Price must be at least $20').max(100, 'Price cannot exceed $100').required('Price is required')
});

export const userRoles = Yup.object().shape({
  role: Yup.string().required('Please select a role'),
});

// export const travelAddress = Yup.object({
//   country: Yup.string().required('Country is required'),
//   state: Yup.string().required('State is required'),
//   zipCode: Yup.string().required('Zip code is required').matches(/^\d{0,9}$/, 'Invalid zip code'),
//   city: Yup.string().required('City is required'),
//   // travelArea: Yup.string().required('Travel area is required'),
//   address: Yup.string().required('Address is required'),
// });
export const travelAddress = Yup.object().shape({
  address: Yup.string()
    .trim()
    .required('Address is required.')
    .test(
      'no-only-spaces',
      'Address cannot be only spaces',
      (value) => value && value.trim().length > 0
    ),
  country: Yup.string().required('Country is required.'),
  state: Yup.string().required('State is required.'),
  zipCode: Yup.string()
    .required('Zip Code is required.'),
    // .matches(/^[0-9]{5,6}$/, 'Invalid Zip Code.')
  city: Yup.string().required('City is required.'),
});
export const travelAreaAddress = Yup.object({
  country: Yup.string().required('Country is required'),
  state: Yup.string().required('State is required'),
  city: Yup.string().required('City is required'),
  travelArea: Yup.string().required('Travel area is required'),
});

// ADMIN VALIDATIONS

// Validation for admin users
const noOnlySpaces = 'Cannot be empty or contain only spaces';
export const adminValidationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .matches(/^(?!\s*$).+/, noOnlySpaces) // Ensures not only spaces
    .required('First name is required'),
  lname: Yup.string()
    .trim()
    .matches(/^(?!\s*$).+/, noOnlySpaces) // Ensures not only spaces
    .required('Last name is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
});

export const changePasswordValidation = Yup.object().shape({
  // newPassword: Yup.string().required('New Password is required'),
  // repeatPassword: Yup.string().required('Repeat Password is required')
  newPassword: Yup.string()
    .trim() // Remove leading/trailing spaces
    // .strict(true) // Prevents spaces-only passwords
    .required('New Password is required')
    .max(12, 'Password cannot be longer than 12 characters')
    .test(
      'no-spaces-only',
      'Password cannot contain only spaces',
      (value) => value && value.trim().length > 0
    ),
    
  repeatPassword: Yup.string()
    .trim()
    // .strict(true)
    .required('Repeat Password is required')
    .max(12, 'Password cannot be longer than 12 characters')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match') // Ensures passwords match
    .test(
      'no-spaces-only',
      'Password cannot contain only spaces',
      (value) => value && value.trim().length > 0
    ),
});
export const forgotValidation = Yup.object().shape({
  email: Yup.string()
    .trim() // Ensure no leading/trailing spaces are considered
    .required('Please enter email address.') // Check if the field is empty or has only spaces
    .email('Invalid email address.'), // Check if the format is correct
});