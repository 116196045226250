import React, { useState } from 'react'
import logo from '../../../assets/images/logo.svg';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useDispatch } from 'react-redux';
import { insertUserData } from '../../../Counter/userSignUpSlice';
import leftArrow from '../../../assets/images/left-arrow_black.svg'
import { userIdentityValidation } from '../../../Validations/formValidation';
import Header from '../../CreatorComponents/CreatorJoin/header';

function UserIdentify({ nextStep, prevStep,data }) {
const [gender,setGender] = useState('')
const [othergender,setOtherGender] = useState('')
const [othergenderError,setOtherGenderError] = useState('')
const dispatch = useDispatch();
  const initialValues = {
    gender: data?.gender || '', 
    otherGender: data?.otherGender || ''
  };
  const handleData = (e) =>{
    setOtherGender(e?.target?.value)
  }
  const handleSubmit = async (data) => {
      const values = {
      gender: data?.gender!= 'other' ? data?.gender : data?.otherGender !='' ? data?.otherGender :othergender
    }
    if(data?.gender === 'other'){
      if(data?.otherGender === ''){
        setOtherGenderError('Gender is required');
      } else{
        dispatch(insertUserData( values));
    nextStep()
      }
    }else{
    dispatch(insertUserData( values));
    nextStep()
    }
//     rm -rf node_modules package-lock.json
// npm install

  }
  const usergender = data?.gender
  return (
    <div class="detail-page  user-identify signup-cs">
       <Header/>
      {/* <header>
        <div class="container">
          <nav class="navbar navbar-expand-lg navbar-light">
            <a class="navbar-brand" href="#">
              <img src={logo} />
            </a>
          </nav>
        </div>

      </header> */}
      <div class="main">

        <Formik
          initialValues={initialValues}
          validationSchema={userIdentityValidation}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange }) => (
            <Form>
              <div className="radio-buttons idintify-radio mb-2">
                <h1>What is the user’s gender identity? *</h1>
                {/* <span>1/2</span>   */}
                <label className="radio">
                  {/* <Field type="radio" name="gender" value="male" checked={usergender !=='' ? usergender === values?.gender === 'male' ? usergender:values?.gender:values} onChange={handleChange} /> */}
                  <Field type="radio" name="gender" value="male" checked={usergender === 'male' || values.gender === 'male'} onChange={handleChange} />
                  Male
                  <span className="checkmark"></span>
                </label>
                <label className="radio">
                  <Field type="radio" name="gender" value="female" checked={usergender === 'female' || values.gender === 'female'} onChange={handleChange} />
                  Female
                  <span className="checkmark"></span>
                </label>
                <label className="radio">
                  <Field type="radio" name="gender" value="other" checked={usergender === 'other' || values.gender === 'other'} onChange={handleChange} />
                  Other
                  <span className="checkmark"></span>
                </label>
                {values?.gender === 'other' && (
                  <Field
                    type="text"
                    name="otherGender"
                    className="identity-input my-2"
                    placeholder="Enter user’s gender identity"
                    // onChange={(e)=>handleData(e)} 
                    onChange={handleChange}
                  />
                )}
                 <div> {othergenderError !== '' && othergenderError} </div>
              </div>
              <ErrorMessage name="gender" component="div" className="text-danger" />
              <button type="submit" className="continue">Continue</button>
            </Form>
          )}
        </Formik>
      </div>
      <div className="back-button">
  <button className="button" onClick={()=>prevStep()}><img src={leftArrow} alt=''/> </button>
  </div>
    </div>
  )
}

export default UserIdentify