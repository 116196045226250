import React, { useState } from 'react'
import DefineOffering from './defineOffering'
import AvailabilityOffering from './availabilityOffering'
import SessionSessionHybrid from './sessionSessionHybrid'
import SessionDiscovery from './sessionDiscovery'
import OfferingDetails from './OfferingDetails'
import { useDispatch, useSelector } from 'react-redux'
import { postApi, putApi } from '../../../Services/apiService'
import { clearOfferingData } from '../../../Counter/offeringSLice'
import AvailabilityOfferingGroup from './availabilityOfferingGroup'
import { useNavigate } from 'react-router-dom'

function EditOffering() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const formState = useSelector((state) => state.offerCounter);
  const [loader,setLoader] = useState(false)

  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const submitEditData = async()=>{
    setLoader(true)
    console.log("formstateedit",formState);
    await delay(1000);
  
    const getValue = (key) =>  formState?.[key];
  
    let locationType = getLocationType(getValue('classMode'));
    let session_offer_type = getSessionOfferType(getValue('sessionType'));
    let session_type = getSessionType(getValue('sessionTypes'));
    let time_type = getTimeType(getValue('specifyTime'));
    let package_type = getPackageType(getValue('sessionMultipleType'));
    let session_repeat = getSessionRepeat(getValue('sessionRepeat'));
    let pricing_type = getPricingType(getValue('discoveryPrice'));
    let availability_period = getAvailabilityPeriod(getValue('availabilityPeriod'));
    let discoveryValue = getDiscovery(getValue('discoveryCall'))
  
    let formData = new FormData();
    formData.append('name', getValue('name'));
    formData.append('description', getValue('description'));
    formData.append('category_id', getValue('categoryId'));
    formData.append('offer_tag', getValue('tags'));
    formData.append('offer_type', getValue('type'));
    // formData.append('offer_type', 'b80291e6-708b-4d62-81f5-3529da84a241');
    formData.append('address_id', getValue('address_id') || getValue('address'));
    formData.append('location_type', locationType);
    formData.append('invitation_link', getValue('invitationLink'));
    formData.append('session_occurrences', JSON.stringify(getValue('recurrences') || getValue('recurrencess'))|| null);
    formData.append('session_type', session_type);
    formData.append('min_group_size', getValue('minGroupSize') || 0);
    formData.append('max_group_size', getValue('maxGroupSize'));
    formData.append('pricing_type', pricing_type);
    formData.append('price', getValue('discoveryMinPrice'));
    formData.append('minimum_price', getValue('discoveryMinPrice'));
    formData.append('maximum_price', getValue('discoveryMaxPrice'));
    formData.append('session_offer_type', session_offer_type);
    formData.append('time_type', 2);
    formData.append('package_type', package_type);
    formData.append('session_repeat', session_repeat);
    formData.append('cover_image', getValue('coverImage'));
    // formData.append('stepvalue', step - 1);
    formData.append('travel_area', getValue('travelArea'));
    formData.append('travel_charge_fee', getValue('chargeTravel'));
    formData.append('in_person_option', 3);
    formData.append('service_type', 1);
    formData.append('location', getValue('locationInput'));
    formData.append('message_to_user', getValue('messageUser'));
    // formData.append('offer_status', offerStatus);
    formData.append('no_of_sessions', Number(getValue('sessionPackage') || getValue('sessionPackage') || 0));
    // formData.append('no_of_sessions', Number(getValue('sessionPackage') || getValue('no_of_sessions') || 0));
    formData.append('package_timeframe', getValue('timeFrame')?.number || getValue('timeFrame')?.number);
    // formData.append('package_timeframe', getValue('timeFrame')?.number || getValue('package_timeframe'));
    formData.append('package_period', getValue('timeFrame')?.months || getValue('timeFrame')?.months);
    // formData.append('package_period', getValue('timeFrame')?.months || getValue('package_period'));
    formData.append('title', getValue('title'));
    formData.append('deleted_ids', getValue('removedImageIds'));
    formData.append('draft_same_time', getValue('sameTime'));
    formData.append('draft_same_duration', JSON.stringify(getValue('sameTimeDuratioon')));
    formData.append('draft_times', JSON.stringify(getValue('selectedDayLabel')));
    formData.append('draft_slot', JSON.stringify(getValue('addDatesCalendar') || getValue('addDateCalendar')));
    formData.append('draft_add_day', JSON.stringify(getValue('addOnDay') || getValue('addOnDay')));
    formData.append('availbility_period', availability_period);
    formData.append('discovery_call', discoveryValue);
    var service_Pattern= {
      same_time:getValue('sameTime'),
      same_duration:JSON.stringify(getValue('sameTimeDuratioon')),
      selectedDayLabel:JSON.stringify(getValue('selectedDayLabel')),
      addonDay:JSON.stringify(getValue('addOnDay') || getValue('addOnDay')),
      addDatesCalendar:JSON.stringify(getValue('addDatesCalendar') || getValue('addDateCalendar')),
      rdates:getValue('rdates'),
      exdates:getValue('exdates'),
      sessionDuration:getValue('sessionDuration'),
      sessionOccurences:JSON.stringify(getValue('recurrences'))
    }
    

    const additionalImages = getValue('additionalImagesPreview');
    if (Array.isArray(additionalImages)) {
      additionalImages.forEach((image) => {
        if (image instanceof File || image instanceof Blob) {
          formData.append('materials', image);
        }
      });
    }
  
    console.log("ApiData>>>>>>>>>>>>>>>>>>>>>", formData);
  
    let response;
    // if (offerStatus === 'draft') {
    //   formData.append('id', getValue('id'));
    //   response = await postApi('/creator/draft', formData);
    //   if (response?.statusCode === 200) {
    //     dispatch(insertOfferingData({ id: response?.data?.id }));
    //     console.log("Draft saved successfully");
    //     return true;
    //   } else {
    //     return false;
    //   }
    // } else {
      formData.append('id', getValue('id') || null);
      formData.append('session_occurrence_pattern',JSON.stringify(service_Pattern))
      response = await putApi('/creator/Service', formData);
      console.log("response>>>>>>>", response);
      setLoader(false)
      if (response?.statusCode === 200) {
        dispatch(clearOfferingData());
        navigate('/creator/offering/home')
        return true;
      } else {
        
        return false;
      }
    // }
  }
  const getLocationType = (classMode) =>
    ({ online: 1, in_person: 2, hybrid: 3 }[classMode] || 0);
  
  const getSessionOfferType = (sessionType) =>
    ({ single_session: 1, multiple_session: 2 }[sessionType] || 0);
  
  const getSessionType = (sessionTypes) =>
    ({ private_session: 1, group_session: 2 }[sessionTypes] || 0);
  
  const getTimeType = (specifyTime) =>
    ({ exact: 1, availability: 2 }[specifyTime] || 0);
  
  const getPackageType = (sessionMultipleType) =>
    ({ weekly: 1, monthly: 2, custom: 3, daily: 4 }[sessionMultipleType] || 0);
  
  const getSessionRepeat = (sessionRepeat) =>
    ({ one: 1, daily: 2, weekly: 3, monthly: 4, weekday: 5, weekend: 6 }[sessionRepeat] || 0);
  
  const getPricingType = (discoveryPrice) =>
    ({ fixed_price: 1, variable_price: 2 }[discoveryPrice] || 0);
  
  const getAvailabilityPeriod = (availabilityPeriod) =>
    ({ indefinitely: 1, date: 2 }[availabilityPeriod] || 0);
  const getDiscovery = (discoveryValue) =>
    ({ true: '1', false: '0' }[discoveryValue] || '0');

  return (
    // <div>EditOffering</div>
    <>
     <div className='edit-offering'>
     <div className='edit-offer-one'>
    <DefineOffering  nextStep='' prevStep='' data={formState} step='' submitForm='' setDraftData='' typess={'edit'} submitEditData={submitEditData} loader={loader}/>
    </div>
    <div className='edit-offer-two'>
          {formState?.session_type === '1' ?
            <AvailabilityOffering nextStep='' prevStep='' step='' data={formState} submitFormtype='' type={'edit'} />
            :
            <AvailabilityOfferingGroup nextStep={''} prevStep={''} step={''} data={formState} submitForm={''} type={'edit'} />
          }
    </div>
    <div className='edit-offer-three'>
    <SessionSessionHybrid nextStep='' prevStep='' step='' data={formState} submitFormtype='' type={'edit'}/>
    </div>
    <div className='edit-offer-four'>
    <SessionDiscovery nextStep='' prevStep='' step='' data={formState} submitFormtype='' adminType='' type={'edit'}/>
    </div>
    </div>
    {/* <OfferingDetails data={'data'} /> */}
   
    </>
  )
}

export default EditOffering