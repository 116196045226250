import React from 'react'
import facebook from '../../../assets/images/facebook-logo.svg'
import instagram from '../../../assets/images/Instagram-Logo.wine 1.svg'
import twitter from '../../../assets/images/twitter-x-seeklogo-3 1.svg';
import link1 from '../../../assets/images/link-1.svg'
import yogaGirl from '../../../assets/images/yoga-girl.png'
import check from '../../../assets/images/green-check.svg'
// import cross from '../../../assets/images/cros.svg'
import cross from '../../../assets/images/cross-button.svg'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

function LiveModal({submitForm,closeModal,type,imagePreview,adminType}) {
  const navigate = useNavigate()
  const data = useSelector((state) => state.productCounter);
  const insertImage = localStorage.getItem('imagePreview')
  const roles = localStorage.getItem('user_role')
  const navigateBasedOnRole = () => {
    if (roles === "admin") {
      navigate("/admin/offering");
    } else {
      localStorage.removeItem("imagePreview");
      navigate("/creator/offering/home");
    }
  };

  return (
    <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
       {/* <button className='close-button' onClick={() => closeModal('live')}><img src={cross} alt='' /></button> */}
       <button className='close-button' onClick={() =>navigateBasedOnRole()}><img src={cross} alt='' /></button>
       {/* <button className='close-button' onClick={() => closeModal('live')}><img src={cross} alt='' /></button> */}
        <div class="share-main">
          <div className='header'>
            <span><img src={check} alt=''></img> </span>
            <h5>Your {type ==='product' ? 'product' : 'offering'} is live</h5>
            <h6>Share this offering so people cane take a look it in your profile.</h6>
          </div>
          <div class="top">
             <img src={data?.product_view_image ? data.product_view_image : (imagePreview ? imagePreview : insertImage)} alt=''/>
             {/* <img src={data?.product_view_image ? data.product_view_image : yogaGirl} alt=''/> */}
          </div>
          <div class="row">
              <div class="col-sm-6">
              <div className='social' onClick={()=>navigateBasedOnRole()}>
                  <a href={()=>navigateBasedOnRole()} class="share-middle">
                      <span><img src={facebook} alt=''/> Share on Facebook </span>
                  </a>
                  </div>
              </div>
              <div class="col-sm-6">
              <div className='social' onClick={()=>navigateBasedOnRole()}>
                  <a href={()=>navigateBasedOnRole()} class="share-middle">
                      <span><img src={instagram} alt=''/> Share on Instagram </span>
                  </a>
                  </div>
              </div>
              <div class="col-sm-6">
              <div className='social' onClick={()=>navigateBasedOnRole()}>
                  <a href={()=>navigateBasedOnRole()} class="share-middle">
                      <span><img src={twitter} alt=''/> Share on Twitter </span>
                  </a>
                  </div>
              </div>
              <div class="col-sm-6">
              <div className='social' onClick={()=>navigateBasedOnRole()}>
                  <a href={()=>navigateBasedOnRole()} class="share-middle">
                      <span><img src={link1} alt=''/>Copy Link </span>
                  </a>
                  </div>
              </div>
              

          </div>
          <button class="done" onClick={() =>navigateBasedOnRole()}>Done</button>
          {/* <button class="done" onClick={() => submitForm('publish')}>Done</button> */}
        </div>
    </div>
  </div>
</div>
  )
}

export default LiveModal