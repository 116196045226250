import React from 'react'
import logo from '../../../assets/images/logo.svg'
import { useNavigate } from 'react-router-dom'

function Header() {
  const navigate = useNavigate()
  return (
    <>
      <header>
        <span onClick={()=>navigate('/')}><img src={logo} alt=''/> </span>
      </header>
    </>
  )
}

export default Header