import React, { useState } from 'react'
import Modal from 'react-modal';
import cross from '../../../assets/images/gray-closebtn12.svg'
import GalleryCarousel from './galleryCarousel';

function Gallery({ galleryImages }) {
  console.log('this is gallery images', galleryImages);
  const [imgModal, setImgModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [startIndex,setStartIndex]= useState(null)

  // const openModal = (image) => {
  //   setSelectedImage(image);
  //   setImgModal(true);
  // };
  const openModal = (src,index) => {
    // setModalContent(src);
    setModalIsOpen(true);
    setStartIndex(index)
};
  const closeModal = () => {
    setModalIsOpen(false);
    setStartIndex(null);
  };

  return (
    <>
      <section className="gallery">
        <h3>Gallery</h3>
        <div className='gallery pt-0'>
        <div className="row">
          <div className="col-sm-8">
            <div className="gallery-left" onClick={() => openModal(galleryImages && galleryImages[0]?.file_url,0)}>
              {/* <img src={galleryImages && galleryImages[0]?.file_url} alt="Main Gallery" /> */}
              {galleryImages && galleryImages[0]?.media_type === "image" ? (
    <img src={galleryImages[0]?.file_url} alt="Main Gallery" />
  ) : galleryImages[0]?.media_type === "video" ? (
    <video controls>
      <source src={galleryImages[0]?.file_url} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  ) : null}
            </div>
          </div>
          <div className="col-sm-4">
            <div className="row gallery-scroll">
              { galleryImages && galleryImages.slice(1).map((image, index) => (
                <div className="col-sm-6" key={index}>
                  <div className="gallery-small" onClick={() => openModal(image.file_url,index+1)}>
                    {/* <img src={image.file_url} alt={`Gallery ${index + 1}`} /> */}
                    {image?.media_type === "image" ? (
                      <img src={image?.file_url} alt="Main Gallery" />
                    ) : image?.media_type === "video" ? (
                      <video controls>
                        <source src={image?.file_url} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        </div>
        {/* <div className="dots">
          <ul>
            <li className="active"></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div> */}

        {/* <Modal
          isOpen={imgModal}
          onRequestClose={closeModal}
          contentLabel="Media-Modal"
		  portalClassName="Media-Modal"
		  ariaHideApp={false}

        >
          <button className='close-button' onClick={closeModal}><img src={cross} alt='Close' /></button>
		  {selectedImage && (selectedImage.endsWith('.png') || selectedImage.endsWith('.jpg') || selectedImage.endsWith('.jpeg') || selectedImage.endsWith('.svg')) ? (
                        <img src={selectedImage}/>
                    ) : selectedImage.endsWith('.mp4') ? (
                        <video controls style={{ width: '100%' }}>
                            <source src={selectedImage} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    ) : null}
        </Modal> */}

        <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={()=>closeModal}
                    contentLabel="Media-Modal"
                    portalClassName="Media-Modal media-open-fullimg"
                    ariaHideApp={false}
                >
                    <button className='close-button' onClick={() => closeModal()}> <img src={cross} alt='' /></button>
                <GalleryCarousel userDetails={galleryImages} startIndex={startIndex}/>
                </Modal>
      </section>
    </>
  );
}

export default Gallery;
