import React, { useState } from 'react';
import clock from '../../../../assets/images/clock3.svg'
import selcttime from '../../../../assets/images/select-time.svg'



const TimePicker = ({values,handleInputChange,index,timeLimit,val,num,strin,label,position,manageCalendar,showManage,isOpen,setIsOpen}) => {
  const [times, period] = values && values?.split(' ');

// Split the hour and minute
const [hour, minute] = values && times?.split(':');

  const [time, setTime] = useState('');
  // const [time, setTime] = useState({
  //   hour: hour || '00',
  //   minute: minute || '00',
  //   period: period || 'AM',
  // });

  

  // const [isOpen, setIsOpen] = useState(false); // Control if dropdowns are open


  const handleChange = (type, value) => {
    const updatedTime = {
      ...time,
      [type]: value,
    };
    // setTime(updatedTime);
    // setTime((prev) => ({
    //   ...prev,
    //   [type]: value,
    // }));
    const inputfunc = handleInputChange(index, timeLimit,value, num, strin, label, position)
    // const inputfunc = handleInputChange(index, timeLimit,`${updatedTime.hour}:${updatedTime.minute} ${updatedTime.period}`, num, strin, label, position)
    if(inputfunc === true){
    setTime(value);
    // setTime(updatedTime);
    }
  };

  const toggleDropdowns = (position) => {
    // setIsOpen(!isOpen);
    // const newIsOpen = isOpen.map((open, i) => (i === position ? !open : false)); // Toggle only the clicked one, close others
    setIsOpen();
  };
  

  const hours = Array.from({ length: 12 }, (_, i) => ((i + 12) % 12 || 12).toString());
  // const hours = Array.from({ length: 12 }, (_, i) => (i + 1).toString());
  const minutes = ['00', '15', '30', '45'];
  const periods = ['AM', 'PM'];

  // Inline CSS Styles
  const styles = {
    timePicker: {
      width: '100%',
      position: 'relative',
    },
    dropdownContainer: {
      cursor: 'pointer',
      border: '1px solid #ccc',
      padding: '12px 40px',
      height: '48px',
      borderRadius: '12px',
      userSelect: 'none',
      width: '100%',
    },
    dropdownHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    dropdowns: {
      display: 'flex',
      flexDirection: 'row',
      gap: '10px',
      position: 'absolute',
      top: '100%',
      left: '0',
      backgroundColor: 'white',
      border: '1px solid #ccc',
      borderRadius: '4px',
      zIndex: 1000,
      width: '100%',

    },
    dropdownList: {
      display: 'flex',
      flexDirection: 'column',
      padding: '10px',
      textAlign: 'center',
      fontSize: '15px', 

    },
    dropdownItem: {
      padding: '8px',
      cursor: 'pointer',
    },
    selected: {
      fontWeight: 'bold',
      backgroundColor: '#e0e0e0',
      borderRadius:'4px',
      cursor: 'pointer',
      background: '#007F73',
      color:'#fff'
    },
    dropdownItemHover: {
      backgroundColor: '#f0f0f0',
    },
  };

  const timeOptions = [];
periods.forEach(period => {
  hours.forEach(hour => {
    minutes.forEach(minute => {
      timeOptions.push(`${hour}:${minute} ${period}`);
    });
  });
});

  return (
    <div style={styles.dropdownList} className={`time-options time timedropdown daily-weeks-selct-ontime ${isOpen === true ? 'openclock':''}`}>
      <img src={clock} alt='timeset' className='clock-imges'/>
      <div
        style={styles.dropdownContainer}
        onClick={()=>{((manageCalendar===true && showManage===true) || (manageCalendar===false && showManage===false)) && toggleDropdowns()}} 
        className='time-set text-start'
      >
        <p className='text-start'>{values ? values : !isOpen &&'-:-'}</p>
        {/* <p className='text-start'>{isOpen && values ? values : '-:-'}</p> */}
        { isOpen &&
        <ul className='hour-set' >
    {timeOptions.map((option, index) => (
      
      // <div
      //   key={index}
      //   style={{
      //     ...styles.dropdownItem,
      //     ...(time === option ? styles.selected : {}),
      //   }}
      //   onClick={() => {
      //     if ((manageCalendar === true && showManage === true) || (manageCalendar === false && showManage === false)) {
      //       handleChange('time', option); // Save the selected time in one click
      //     }
      //   }}
      // >
      <li onClick={() => {
            if ((manageCalendar === true && showManage === true) || (manageCalendar === false && showManage === false)) {
              handleChange('time', option); // Save the selected time in one click
            }
          }}
          >
        {option}
        </li>
      // </div>
    ))}
    </ul>
        }
    </div>
  </div>
  );
};

export default TimePicker;
