import { getApi } from "../Services/apiService";

const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };
//   export const handleAddressChange = debounce(async (value) => {
  export const handleAddressChanges = async (value) => {
    const resp = await getApi(`/places/autocomplete?search=${value}`);
    if (resp?.statusCode === 200) {
        return {resp}
    //   setGetAddressData(resp?.data);
    }
  };

  export const convertSlotTo12HourFormatAmPm = (slot,duration) => {
    const [date, time] = slot.split(', '); // Split the date and time
    let [hours, minutes] = time.split(':').map(Number); // Convert to numbers
  
    // Format the original time
    let hours12 = hours % 12 || 12; 
    hours12 = hours12.toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';
    const originalTime = `${hours12}:${minutes.toString().padStart(2, '0')} ${ampm}`;
  
    // Add 60 minutes to the original time
    const newDate = new Date(0, 0, 0, hours, minutes + Number(duration)); // Create a Date object
    let newHours = newDate.getHours();
    let newMinutes = newDate.getMinutes();
    const newAmpm = newHours >= 12 ? 'pm' : 'am';
    newHours = newHours % 12 || 12; 
    newHours = newHours.toString().padStart(2, '0');
    const newTime = `${newHours}:${newMinutes.toString().padStart(2, '0')} ${newAmpm}`;
  
    return `${originalTime} - ${newTime}`;
  };

  export const formatDateWeekSlot = (dateString) => {
    // Convert DD/MM/YYYY to MM/DD/YYYY
    // const [month,day, year] = dateString.split('/');
    const [day, month, year] = dateString.split('/');
    const validDateString = `${month}/${day}/${year}`;
  
    const date = new Date(validDateString); // Now it's valid
    const options = { weekday: 'short', day: 'numeric', month: 'short' };
  
    const parts = date.toLocaleDateString('en-US', options).split(' ');
  
    return `${parts[0]} ${parts[2]} ${parts[1]}`;
  };

export const formatDateWeek = (dateString) => {
        const [datePart, timePart] = dateString.split(", ");
        // const [year, month, day] = datePart.split("-").map(Number); 
        // const [day, month, year] = datePart.split("/").map(Number);
        let year, month, day;

        if (datePart.includes("/")) {
            [day, month, year] = datePart.split("/").map(Number);
        } else if (datePart.includes("-")) {
            [year, month, day] = datePart.split("-").map(Number);
        } else {
            throw new Error("Invalid date format");
        }
        const date = new Date(year, month - 1, day);
        const months = [
          "January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];
        const formattedDate = `${months[date.getDate()]} ${date.getMonth()}}`;
        
        return formattedDate;
      };

export const formatDateToSlotMapFormat = (dateString) => {
  const [day, month, year] = dateString.split('/'); // Split the date string
  return `${year}-${month}-${day}`; // Return the date in "yyyy-MM-dd" format
};

export const formatDateandTime = (isoDate) => {
  const date = new Date(isoDate);

  // Extract parts of the date
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = date.getFullYear();

  // Extract time parts
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12 || 12; // Convert 24-hour to 12-hour clock

  return `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;
};