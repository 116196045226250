import React, { useEffect, useState, useCallback } from 'react';
import UserSidebar from '../../../Layout/userSidebar';
import burger from '../../../assets/images/burger-menu.svg'
import leftArrow from '../../../assets/images/backarrow.svg'
import { useNavigate } from 'react-router-dom';
import cardJewellery from '../../../assets/images/card jwellery.png'
import share from '../../../assets/images/share.svg'
import heart from '../../../assets/images/heart.svg'
import location from '../../../assets/images/location1.svg'
import doubleBox from '../../../assets/images/double-box.svg'
import whiteArrow from '../../../assets/images/white-arrow.svg'
import Modal from 'react-modal';
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux';
import dummyImage from '../../../assets/images/profile3.svg'
import { useParams } from 'react-router-dom'
import { getApi } from '../../../Services/apiService'
import CheckoutSuccess from './checkoutSuccess';
import graycross from '../../../assets/images/gray-cross.svg'
import greencross from '../../../assets/images/green-cross.svg'
import PaymentModal from './payment';
import ProductDetailsModal from './productPaymentModals/productDetailsModal';
import ProductDeliveryAddressModal from './productPaymentModals/productDeliveryAddress';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { stripePromise } from '../../../Config/stripeService';  // Import stripePromise from the service
import CaroselImage from '../../CreatorComponents/CreatorOffering/caroselImage';
import cross from '../../../assets/images/gray-closebtn.svg'



function UserSingleProductView({ }) {

  const [classState, setClassState] = useState(false);
  const [readyPublish, setReadyPublish] = useState(false)
  const [offeringLive, setOfferingLive] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [addressDetails, setAddressDetails] = useState('');
  const creatorDetail = JSON.parse(localStorage.getItem('full_user_detail'));
  const [data, setUserDetails] = useState([])
  const [successModal, setSuccessModal] = useState(false)
  const [productDetailsModal, setProductDetailsModal] = useState(false)
  const [productDeliveryAddressModal, setProductDeliveryAddressModal] = useState(false)
  const [filtersModal, setFiltersModal] = useState(false);
  const [type,setType]=useState('physical')
  const [selectedOption,setSelectedOption]=useState('')
  const [quantity,setQuantity]=useState('')
  const [pickType,setPickType] = useState('')


  const navigate = useNavigate()
  const params = useParams();
  console.log("params>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", params?.id)

  useEffect(() => {
    const handleClick = () => setClassState(false);
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  useEffect(() => {
    fetchDetails();
    
}, [])

useEffect(() => {
  if(successModal === true){
    fetchDetails();
  }
  
}, [successModal])

console.log('this is data from api',data);

  const handleSidebarClick = (event) => {
    event.stopPropagation();
    setClassState(!classState);
  };
  const dispatch = useDispatch()

  const openModal = (src) => {
    setModalContent(src);
    setModalIsOpen(true);
  };
  const closeImgModal = () => {
    setModalIsOpen(false);
    setModalContent('');
};
 
  const [isExpanded, setIsExpanded] = useState(false);

  const fetchDetails = async () => {
    const response = await getApi(`/user/Service?offerId=${params?.id}`)
    if (response?.statusCode === 200) {
        setUserDetails(response?.data)
    }
}

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };
  // Ensure description is defined and is a string
  const description = data?.description || '';
  const isLongDescription = description?.length > 300;
  const truncatedDescription = description?.length > 300 ? `${description.slice(0, 300)}...` : description;
  const navBack = () => {
    navigate('/user/offering')
  }
  const handleCheckout = () => {
    if (data?.product_kind === '1') {
      setType('digital')
      setFiltersModal(true)
    } else if (data?.product_kind === '0') {
      setType('physical')
      setProductDetailsModal(true)
    } else {
      console.log('Unknown product kind');
    }
  };
  console.log('seelected option------',selectedOption);


  return (
    <>

     {/* <h2 onClick={() => singleService()}><img src={arrowBtn} /> {userDetails?.title}</h2> */}
      <UserSidebar classState={classState} />
      <div className='right-main'>
        <div class="session">
          <div class="header  d-none">
            <h1> <button className="burger-menu" onClick={handleSidebarClick}><img src={burger} alt='' /></button>  </h1>
          </div>
          <div class="active-links mb-2">
          <h2  onClick={() => { navBack() }} className='text-start'><img src={leftArrow} />  {data?.name}</h2>
       </div>
          {/* <div class="active-links arrow-height">
            <button className="back" onClick={() => { navBack() }}><img src={leftArrow} alt='' /> </button>
          </div> */}

          <div class="main mt-2">
            <div class="row">
              <div class="col-sm-12 col-xl-7 col-lg-12">
                {/* <div class="left">
                  <h1>{data?.name}</h1>
                </div> */}

                {data && data?.tags && data?.tags?.length > 0 && (
                  <div className="choose-tags">
                    <ul>
                      {data.tags.map((tag, index) => (
                        <li key={tag.id} className="custom-checkbox">
                          <input disabled
                            type="checkbox"
                            id={`option${index}`}
                          // You might want to manage checked state if needed
                          />
                          <label htmlFor={`option${index}`}>{tag.tag_name}</label>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                <div class="row">
                  <div class="col-lg-10  col-sm-10">
                    <div class="main-image">
                      <img src={data?.cover_image ? data.cover_image : cardJewellery} alt='' class="img-fluid " onClick={() => openModal(data.cover_image)}/>
                    </div>
                  </div>

                  <div className="col-lg-2 col-sm-2">
                    <div className= {data.offerMaterials?.length <= 3 ? 'jewellery-img-height' : 'jewellery-img-height image'} >
                    <div className="row jw-cs">
                      {data?.offerMaterials && data.offerMaterials?.length > 0 ? (
                        data.offerMaterials
                          .filter((item) => item.media_type === "image" || item.media_type === "video") // Filter for images and videos
                          .map((item, index) => (
                            <div className="col-6 col-lg-12 col-sm-12" key={index} >
                              <div className="jewellery-img">
                                <img src={item.file_url} alt={`additional-image-${index}`} className="img-fluid" onClick={() => openModal(item.file_url)}/>
                              </div>
                            </div>
                          ))
                      ) : (
                        <>

                        </>
                      )}
                    </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-9">
                  <div className='creator-detail-right creator-product'>
                    <h2 className='fw-bold'> About product</h2>
                    <p>
                      {isExpanded || !isLongDescription ? description : truncatedDescription}
                      <br />
                      {isLongDescription && (
                        <strong onClick={toggleText} style={{ cursor: 'pointer' }}>
                          {isExpanded ? 'Read less' : 'Read more'}
                        </strong>
                      )}
                    </p>
                  </div>
                </div>
               
              </div>
              <div class="col-sm-5 col-xl-5 col-lg-5">
                <div class="share-product share-product-rs">
                  <div class="row profuct-head d-flex justify-content-between align-items-top ">
                  <div className='col-md-8'>
                    <h2>{data?.title}</h2>
                    </div>
                    <div className='col-md-4'>
                    <div class="float-end">
                      <img src={share} alt='' class="px-3" />
                      <img src={heart} alt='' />
                    </div>
                    </div>
                  </div>
                  <div class="product-jwelley">
                    {data?.product_kind === '0' && (
                      <>
                    <p class="py-2" ><img src={doubleBox} alt='' class="pe-2" />{data?.quantity_items || data?.maximum_quantity_order} available items</p>
                    <p className="py-2">
                      <img src={location} alt="" className="pe-2" />
                      {data?.delivery_option === '1' ?  'Pick up' && `Pick up in ${data?.address?.city}` : ''}
                      {data?.delivery_option === '2' ? 'Delivery' && `Delivery in ${data?.address?.city}` : ''}
                      {data?.delivery_option ==='3' ? 'both' && `Pick up and Deliver in ${data?.address?.city}` : ''}
                    </p>
                    </>
                    )}
                  </div>
                  <div class="border-grays py-2"></div>
                  <div class="row align-items-center">
                    <div class="col-xl-6 col-lg-12">
                      {data?.pricing_type ==='1' && (
                        <>
                           <ul className='p-0 mb-0 digital--product'>
                        <li>Price <span className='fw-bold px-2'>${data?.minimum_price || data?.maximum_price }</span></li>
                      </ul>
                      {/* <p class="d-flex">Price <span className='fw-bold px-2'>${data?.minimum_price || data?.maximum_price }</span></p> */}
                        </>
                      )}
                        {data?.pricing_type ==='2' && (
                        <>
                        <ul className='p-0 mb-0 digital--product'>
                        <li>Price <span className='fw-bold px-2'>${data?.minimum_price}-${data?.maximum_price }</span></li>
                      </ul>
                      {/* <p class="d-flex">Price <span className='fw-bold px-2'>${data?.minimum_price}-${data?.maximum_price }</span></p> */}
                        </>
                      )}
                    </div>
                    <div class="col-xl-6 col-lg-12 text-end">
                    <button class="cutom-invoice" onClick={handleCheckout}>Go to Checkout</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-sm-7 col-xl-7 col-lg-7'>
              <div className='row align-item-center res-creator-cs '>
                  <div className='col-sm-5 col-xl-4 col-lg-5'>
                    <div className='creator-detail-img'>
                    <span>
                    <img src={data?.userData?.user_profile_image || dummyImage} alt='' />
                    </span>
                    </div>
                  </div>
                  <div className='col-sm-7 col-xl-8 col-lg-7'>
                    <div className='creator-detail-right create-dt-cs'>
                      <h2 className='fw-bold'>{data?.userData?.name || ''}</h2>
                      <h4>{data?.userData?.specializations[0]?.area_of_work || ''}</h4>
                      <p className='d-block'>{data?.userData?.specializations[0]?.experience || ''} of experience</p>
                      {/* <button class="discovery">Discovery call<img src={whiteArrow} alt='' class="px-1" /></button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
          isOpen={filtersModal} onRequestClose={() => setFiltersModal(false)} contentLabel="Payment Method"
          overlayClassName={`modal-overlay checkout-pay digital`}
          portalClassName="checkout">
          <button className='close-button' onClick={() => setFiltersModal(false)}><img src={graycross} alt='' /></button>
          {/* <Elements stripe={stripePromise}> */}
          <PaymentModal setFiltersModal={setFiltersModal} setSuccessModal={setSuccessModal} type ={type} itemDetails={data} paymentDetails={quantity} addressDetailsToPayment={addressDetails}></PaymentModal>
          {/* </Elements> */}

        </Modal>
        {/* product flow starts */}
      <Modal
          isOpen={productDetailsModal} onRequestClose={() => setProductDetailsModal(false)} contentLabel="Payment Method"
          overlayClassName={  `modal-overlay product-details product-detail-address`}
          portalClassName="checkout">
          <button className='close-button' onClick={() => setProductDetailsModal(false)}><img src={greencross} alt='' /></button>
          <ProductDetailsModal setProductDetailsModal={setProductDetailsModal}  setProductDeliveryAddressModal={setProductDeliveryAddressModal} setFiltersModal={setFiltersModal}  type ={type} setSelectedOption={setSelectedOption} setQuantity={setQuantity} originalQuantity={data} setPickType={setPickType}/>
        </Modal>

        <Modal
          isOpen={productDeliveryAddressModal} onRequestClose={() => setProductDeliveryAddressModal(false)} contentLabel="Payment Method"
          overlayClassName={`modal-overlay product-detail-address`}
          portalClassName="checkout">
          <button className='close-button' onClick={() => setProductDeliveryAddressModal(false)}><img src={greencross} alt='' /></button>
          <ProductDeliveryAddressModal setProductDeliveryAddressModal={setProductDeliveryAddressModal} setFiltersModal={setFiltersModal} type ={type} setAddressDetails={setAddressDetails} setProductDetailsModal={setProductDetailsModal}/>
        </Modal>
        
        {/* product flow ends */}

      <Modal
          isOpen={successModal}
          onRequestClose={() => setSuccessModal(false)}
          contentLabel="Payment Method"
          portalClassName="checkout success"
          overlayClassName={type ==='digital' ? `modal-overlay thanks-pay digital` : `modal-overlay thanks-pay ` }
          shouldCloseOnOverlayClick={false}
        >
          <button className='close-button' onClick={() => setSuccessModal(false)}><img src={graycross} alt='' /></button>
            <CheckoutSuccess setFiltersModal={setFiltersModal} type ={type} itemDetails={data} slotDisplay={''} locationProvide={''} pickType={pickType} ></CheckoutSuccess>
        </Modal>
        
      

      {/* <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeImgModal}
                    contentLabel="Media-Modal"
                    portalClassName="Media-Modal"
                    ariaHideApp={false}
                >
                    <button className='close-button' onClick={() => closeImgModal()}><img src={cross} alt='' /></button>

                    {modalContent && (modalContent.endsWith('.png') || modalContent.endsWith('.jpg') || modalContent.endsWith('.jpeg') || modalContent.endsWith('.svg')) ? (
                        <img src={modalContent} alt="Modal Content"/>
                    ) : modalContent.endsWith('.mp4') ? (
                        <video controls style={{ width: '100%' }}>
                            <source src={modalContent} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    ) : null}
                </Modal> */}

<Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeImgModal}
                    contentLabel="Media-Modal"
                    portalClassName="Media-Modal media-open-fullimg"
                    ariaHideApp={false}
                >
                    <button className='close-button' onClick={() => closeImgModal()}> <img src={cross} alt='' /></button>
                  
                <CaroselImage userDetails={data}/>
                </Modal>
    </>
  );
}

export default UserSingleProductView;
