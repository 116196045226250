import React, { useEffect, useState } from 'react'
// import bannerRight from '../../../assets/images/banner-right.svg'
import search from '../../../assets/images/search.svg'
import heart from '../../../assets/images/heart2.svg'
import clock from '../../../assets/images/clock.svg'
import calendar from '../../../assets/images/calendar.svg'
import people from '../../../assets/images/people.svg'
import mapPin from '../../../assets/images/map-pin.svg'
import productImg from '../../../assets/images/product-img.png'
import wellness from '../../../assets/images/wellness.svg'
import arrow from '../../../assets/images/arrow.svg'
import UniqueOffering from '../../HomeComponent/uniqueOffering'
import hearts from '../../../assets/images/heart.svg'
import DiscoverOffering from './discoverOffering'
import { getApi } from '../../../Services/apiService'

function Discover() {

	const [popularTag,setPopularTag] = useState([])
	const [selectedCategoryId,setSelectedCategoryId] = useState(null)
	const [selectedTagIds,setSelectedTagIds] = useState([])
	const [searchValue,setSearchValue] = useState('')


	useEffect(()=>{
		getPopularTag()
	},[])

	const getPopularTag = async()=>{
		const response = await getApi('/popular-tag')
		if(response?.statusCode === 200){
			setPopularTag(response?.data)
		}
	}
const handleTagClick = (tagId) => {
        let updatedTagIds = [...selectedTagIds];
        if (updatedTagIds.includes(tagId)) {
            updatedTagIds = updatedTagIds.filter(id => id !== tagId);
        } else {
            updatedTagIds.push(tagId);
        }
        setSelectedTagIds(updatedTagIds);
    };
    
  return (
    <div className='creators'>
       <section class="creators-top">
			<div class="container">
			<div class="discover-home">
				<h1>Discover  Unique Offerings</h1>
			  <div class="creators-input">
				<img src={search} alt=''/>
				<input type="text" class="search" placeholder="Find offers or creators" onChange={(e)=>setSearchValue(e?.target?.value)}/>
				</div>
			</div>
			<div class="links">
				<h3>Popular tags</h3>
				<ul>
					{popularTag && popularTag?.length>0 && popularTag.map((x,index)=>{
						return(
							<li onClick={(e)=>{e.preventDefault();handleTagClick(x?.id)}}><a href="" className={selectedTagIds.includes(x?.id) ? 'active' : ''}>{x?.tag_name}</a> </li>
						)
					})}
					{/* <li><a href="">Art</a> </li>
					<li><a href="">Photo</a> </li>
					<li><a href="">Education</a> </li>
					<li><a href="">language Learning</a> </li>
					<li><a href="">Yoga</a> </li>
					<li><a href="">Meditation</a> </li>
					<li><a href="">Cooking</a> </li>
					<li><a href="">Health and Fitness</a> </li>
					<li><a href="">Business</a> </li>
					<li><a href="">Photo</a> </li>
					<li><a href="">Education</a> </li> */}
				</ul>
			</div>
		</div>
		</section> 

        <section class="discover">
			<div class="container">
        <UniqueOffering setSelectedCategoryId={setSelectedCategoryId} selectedCategoryId={selectedCategoryId}/>
        </div>
        </section>

        <DiscoverOffering selectedCategoryId={selectedCategoryId} selectedTagIds={selectedTagIds} searchValue={searchValue}/>
    </div>
  )
}

export default Discover