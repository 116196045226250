import React, { useEffect, useState } from 'react'
import bannerRight from '../../../assets/images/banner-right1.png'
import share from '../../../assets/images/share.svg'
import heart from '../../../assets/images/heart2.svg'
import clock from '../../../assets/images/clock.svg'
import calendar from '../../../assets/images/calendar.svg'
import people from '../../../assets/images/people.svg'
import mapPin from '../../../assets/images/map-pin.svg'
import productImg from '../../../assets/images/product-img.png'
import wellness from '../../../assets/images/wellness.svg'
import arrow from '../../../assets/images/arrow.svg'
import Slider from 'react-slick';
import yoga_boy from '../../../assets/images/yoga-boy.png'
// import yoga_boy2 from '../../../assets/images/yoga-boy2.svg'
import Main_foto from '../../../assets/images/Main_foto.png'
import yoga_girl from '../../../assets/images/yoga-girl.png'
import yoga_girl2 from '../../../assets/images/yoga-girl2.png'
import yoga_group from '../../../assets/images/yoga-group.png'
import yoga_hero from '../../../assets/images/yoga-hero.svg'
import dummy from '../../../assets/images/dummy-profile.jpg'
import { getApi } from '../../../Services/apiService'
import { useNavigate, useParams } from 'react-router-dom'
import dummyProfile from '../../../assets/images/dummy-profile.jpg'
import arrowBtn from '../../../assets/images/arrow-btn.svg'
import session from '../../../assets/images/session-calender.svg'
import hearts from '../../../assets/images/heart 2v.svg'

function DiscoverDetail() {
  const params = useParams();
  const navigate = useNavigate();
  const [userDetails,setUserDetails] = useState({})
  const [isExpanded, setIsExpanded] = useState(false);
  const [similarClass, setSimilarClass] = useState([])

  useEffect(()=>{
    handleGetSIngleData()
    // similarClasses();
  },[])

  const toggleText = () => {
    setIsExpanded(!isExpanded);
};

const description = userDetails?.description || '';
  const isLongDescription = description.length > 300;
  const truncatedDescription = description.length > 300 ? `${description.slice(0, 300)}...` : description;

  const handleGetSIngleData = async() =>{
    const response = await getApi(`/creators/infos?offerId=${params?.id}`)
    if(response?.statusCode === 200){
      setUserDetails(response?.data)
    }
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const items = [
    {
      title: "Morning Yoga",
      category: ["Workshop", "Fitness", "Yoga"],
      description:
        "Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat est velitolm.",
      instructor: "Annete Black",
    },
    {
      title: "Evening Meditation",
      category: ["Workshop", "Wellness", "Meditation"],
      description:
        "Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat est velitolm.",
      instructor: "John Doe",
    },
    {
      title: "Cardio Blast",
      category: ["Fitness", "Cardio", "Health"],
      description:
        "Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat est velitolm.",
      instructor: "Jane Smith",
    },
    {
      title: "Strength Training",
      category: ["Fitness", "Strength", "Health"],
      description:
        "Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat est velitolm.",
      instructor: "Mike Johnson",
    },
    {
      title: "Pilates Class",
      category: ["Fitness", "Pilates", "Health"],
      description:
        "Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat est velitolm.",
      instructor: "Emily Davis",
    },
    {
      title: "Dance Workout",
      category: ["Fitness", "Dance", "Health"],
      description:
        "Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat est velitolm.",
      instructor: "Sophia Brown",
    },
    {
      title: "HIIT Session",
      category: ["Fitness", "HIIT", "Health"],
      description:
        "Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat est velitolm.",
      instructor: "Chris Wilson",
    },
    {
      title: "Mindfulness Yoga",
      category: ["Workshop", "Wellness", "Yoga"],
      description:
        "Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat est velitolm.",
      instructor: "Ava Martinez",
    },
    {
      title: "Bootcamp",
      category: ["Fitness", "Bootcamp", "Health"],
      description:
        "Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat est velitolm.",
      instructor: "Liam Anderson",
    },
    {
      title: "Bootcamp",
      category: ["Fitness", "Bootcamp", "Health"],
      description:
        "Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat est velitolm.",
      instructor: "Liam Anderson",
    },
  ];

//   const uniqueClasses = similarClass?.filter(
//     (item, index, self) =>
//       index === self.findIndex((t) => t.id === item.id)
//   );

//   const similarClasses = async () => {
//     const response = await getApi(`/creator/similar-class`)
//     if (response?.statusCode === 200) {
//         setSimilarClass(response?.data)
//     }
// }

  return (
    <div className='discover-offering'>
      <section class="creators-top">
   
        <div class="container  divcover-container discove-web">
          <div class="row">
            <div class="col-sm-12 mb-4">
            <h2 onClick={() => navigate('/discovering')}><span><img src={arrowBtn} /> {userDetails?.title}</span> </h2>
            </div>
            <div class="col-sm-8">
              <div class="offering-left">
                {/* <h3>Yoga class on the roof, NY city</h3> */}
                {userDetails?.tags && userDetails?.tags?.length > 0 && userDetails?.tags?.map((x, index) => {
                  return (
                    <label key={index}>{x?.tag_name}</label>
                  )
                })}

                <div class="annete">
                <span><img src={userDetails?.userData?.user_profile_image || dummyProfile} alt='' /></span>
                  <div class="annete-text">
                  <h3>{userDetails?.userData && userDetails?.userData?.name}</h3>
                    {userDetails?.userData?.specializations.length ? (
                      <>
                        <h4>
                          {userDetails?.userData?.specializations[0]?.area_of_work}
                        </h4>
                        <h5>
                          {userDetails?.userData?.specializations[0]?.experience} of experience
                        </h5>
                      </>) :
                      <>
                      </>
                    }
                  </div>
                </div>

                <div class="about-left mb-3">
                  <h3>About Class</h3>
                  <p>
                    {isExpanded || !isLongDescription ? description : truncatedDescription}
                    <br />
                    {isLongDescription && (
                      <strong onClick={toggleText} style={{ cursor: 'pointer' }}>
                        {isExpanded ? 'Read less' : 'Read more'}
                      </strong>
                    )}
                  </p>
                </div>

              </div>
            </div>

            <div class="col-sm-4">
              <div class="offering-top-right">
              <h2>{userDetails?.title || 'Morning Yoga'}</h2>
                <div class="offering-share">
                  <ul>
                    <li><a href=""><img src={share} alt=''/></a></li>
                    <li><a href=""><img src={heart} alt=''/></a></li>
                  </ul>
                </div>
                <div class="week">
                  <ul>
                    <li><img src={clock} alt='' /> {userDetails?.session_duration} min</li>
                    {(userDetails?.session_type === '1' && userDetails?.session_offer_type == 1) && (
                      <li><img src={session} alt='' /> 1 session</li>
                    )}
                    {userDetails?.session_type === '1' && userDetails?.session_offer_type == 2 && (
                      <li><img src={calendar} alt='' /> {userDetails?.no_of_sessions}x per {userDetails?.package_period}, {userDetails?.package_timeframe} {userDetails?.package_period}</li>
                    )
                    }
                  </ul>
                </div>
                <div class="week">
                  <ul>
                    <li><img src={people} alt='' /> {userDetails?.session_type === '1' && userDetails?.session_offer_type == 1 ? (
                      <p>Private Session</p>
                    ) : userDetails?.session_type === '1' && userDetails?.session_offer_type == 2 ? (
                      <p>Private Multiple sessions</p>
                    ) : userDetails?.session_type === '2' && userDetails?.session_offer_type == 1 ? (
                      <p>{(userDetails?.max_group_size - userDetails?.seats) || '0'} booked, {userDetails?.max_group_size} max </p>
                      // <p>{userDetails?.booked || '-'} booked, {userDetails?.max_group_size} max </p>
                    ) : userDetails?.session_type === '2' && userDetails?.session_offer_type == 2 ? (
                      <p>{(userDetails?.max_group_size - userDetails?.seats) || '0'} booked, {userDetails?.max_group_size} max </p>
                    ) : null}</li>

                    {(userDetails?.userData?.address != [] && userDetails?.userData?.address_id != null) && <li><img src={mapPin} alt='' /> {(userDetails?.userData?.address && userDetails?.userData?.address_id != null) && userDetails?.userData?.address[0].address + ' ' + userDetails?.userData?.address[0].state + ' ' + userDetails?.userData?.address[0].city + ' ' + userDetails?.userData?.address[0].country}</li>}
                    {/* <li><img src={mapPin} alt='' /> Broome St, New York</li> */}
                    {/* <li><img src={checkTravel} alt='' /> Creator can travel</li> */}
                  </ul>
                </div>
                {/* <div class="time">
                  <h3>April 6(GMT+)</h3>
                  <button>12:00pm</button>
                  <button>03:00pm</button>
                </div>
                <div class="time">
                  <h3>April 12(GMT+)</h3>
                  <button>12:00pm</button>
                  <button>04:00pm</button>
                  <button>06:00pm</button>
                </div> */}
                <div class="offering-right-bottom">
                  {/* <h4 className='pb-0 mb-0'>Price <strong>${userDetails?.price || userDetails?.minimum_price || userDetails?.maximum_price}</strong></h4> */}

                  {userDetails?.pricing_type === '1' ?
                    <h4>Price <strong>${userDetails?.price || userDetails?.minimum_price || userDetails?.maximum_price}</strong></h4>
                    :
                    <h4>Price <strong>${userDetails?.minimum_price}-${userDetails?.maximum_price}</strong></h4>

                  }
                    <button onClick={()=>navigate('/user/login')}>Proceed to Checkout</button>
                </div>
              </div>
            </div>

            <div class="col-sm-8">
              {/* <div class="offering-gallery">
                <div class="gallery-left">
                  <img src={Main_foto} alt=''/>
                </div>
                <div class="gallery-small-main">
                  <div class="gallery-small">
                    <img src={yoga_boy} alt="" />
                  </div>
                  <div class="gallery-small">
                    <img src={yoga_girl} alt="" />
                  </div>
                  <div class="gallery-small">
                    <img src={yoga_girl2} alt="" />
                  </div>
                </div>

              </div> */}
              <div class="row">
                <div className='col-sm-10 col-xl-10 col-lg-10'>
                  <div className="main-image offiering-img-cs">
                    {userDetails.cover_image && userDetails.cover_image !== '' && userDetails.cover_image !== null ? (
                      <>
                        {userDetails?.cover_image.endsWith('.png') ||
                          userDetails.cover_image.endsWith('.jpg') ||
                          userDetails.cover_image.endsWith('.jpeg') ||
                          userDetails.cover_image.endsWith('.svg') ||
                          userDetails.cover_image.endsWith('.svg') ||
                          userDetails.cover_image.endsWith('.webp') ? (
                          <img src={userDetails?.cover_image} alt="Main"
                            // onClick={() => openModal(userDetails.cover_image, 0)} 
                            />
                        ) : userDetails.cover_image.endsWith('.mp4') ? (
                          <video controls 
                          // onClick={() => openModal(userDetails.cover_image, 0)}
                          >
                            <source src={userDetails.cover_image} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <p>No valid media found for the main gallery.</p>
                        )}
                      </>
                    ) :
                      <img src={''} alt="No Image found" />
                    }
                  </div>
                </div>
                {userDetails.offerMaterials?.length > 0 && (
                  <>
                    <div className='col-sm-2 col-xl-2 col-lg-2'>  {/*serviceMaterial */}
                      <div className={userDetails?.offerMaterials.length <= 3 ? 'gallery-small-main offiering-multiple' : 'gallery-small-main offiering-multiple image'}>
                        {userDetails.offerMaterials && Array.isArray(userDetails.offerMaterials) && userDetails.offerMaterials.map((material, index) => (
                          typeof material?.file_url === 'string' ? (
                            material.file_url.endsWith('.png') ||
                              material.file_url.endsWith('.jpg') ||
                              material.file_url.endsWith('.jpeg') ||
                              material.file_url.endsWith('.webp') ||
                              material.file_url.endsWith('.svg') ? (
                              <div className="gallery-small-one" key={index} 
                              // onClick={() => openModal(material?.file_url, index + 1)}
                              >
                                <img src={material?.file_url} alt={`Gallery ${index + 1}`} />
                              </div>
                            ) : material.file_url.endsWith('.mp4') ? (
                              <div className="gallery-small-one" key={index} 
                              // onClick={() => openModal(material?.file_url, index + 1)}
                              >
                                <video controls>
                                  <source src={material?.file_url} type="video/mp4" />
                                  Your browser does not support the video tag.
                                </video>
                              </div>
                            ) : null
                          ) : null
                        ))}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      {userDetails?.id && userDetails?.otherClassess?.length > 0 && (
      <section class="other-class-main">
        <div class="container">
          <h3>Other classes by {userDetails?.userData?.name}</h3>
          <button class="see-all">See all offers<img src={arrow} alt=''/></button>
          <Slider {...settings}>
            {userDetails?.id && (
              userDetails?.otherClassess.map((x, index) => (
                index < 12 &&
                <div className="col-sm-4 p-2" 
                // onClick={() => singleService(x?.id, x)} 
                key={index}>
                  <div className="product-box">
                    <div className="product-img">
                      <img src={x?.cover_image} alt='' />
                      <div className="product-image-data">
                        <span><img src={x?.category && x?.category?.category_image} alt='' /></span>
                        <h5>Offline</h5>
                        <button><img src={hearts} alt='' /></button>
                      </div>
                    </div>
                    <div className="product-data">
                      <h3>{x?.name} </h3>
                      <ul className='product-data-tags'>
                        {x?.tags && x?.tags.length > 0 && x?.tags.map((y, i) => (
                          <li className={i === 0 ? "active" : ''} key={i}>
                            {y?.tag_name}
                          </li>
                        ))}
                      </ul>
                      <p>{x?.description}</p>
                      <div className="product-data-bottom">
                        <span><img src={x?.user?.user_profile_image && x?.user?.user_profile_image || bannerRight} alt='' /></span>
                        <h6><strong>{x?.user && x?.user?.name}</strong> {x?.user?.specializations && x?.user?.specializations[0]?.area_of_work}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </Slider>
        </div>
      </section>
)}
  
  {/* {userDetails?.id && uniqueClasses.length > 0 && (
      <section class="other-class-main">
        <div class="container">
          <h3>Similar classes</h3>
          <button class="see-all">See all offers<img src={arrow} /></button>

          <Slider {...settings}>
            {userDetails?.id && (
              uniqueClasses?.map((x, index) => (
                // similarClass?.map((x, index) => (
                index < 12 &&
                <div className="col-sm-4 p-2"
                //  onClick={() => singleService(x?.id, x)}
                  key={index}>
                  <div className="product-box">
                    <div className="product-img">
                      <img src={x?.cover_image} alt='' />
                      <div className="product-image-data">
                        <span><img src={x?.category && x?.category?.category_image} alt='' /></span>
                        <h5>Offline</h5>
                        <button><img src={hearts} alt='' /></button>
                      </div>
                    </div>
                    <div className="product-data">
                      <h3>{x?.name} </h3>
                      <ul className='product-data-tags'>
                        {x?.tags && x?.tags.length > 0 && x?.tags.map((y, i) => (
                          <li className={i === 0 ? "active" : ''} key={i}>
                            {y?.tag_name}
                          </li>
                        ))}
                      </ul>
                      <p>{x?.description}</p>
                      <div className="product-data-bottom">
                        <span><img src={x?.user?.user_profile_image && x?.user?.user_profile_image || bannerRight} alt='' /></span>
                        <h6><strong>{x?.user && x?.user?.name}</strong> {x?.user?.specializations && x?.user?.specializations[0]?.area_of_work}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </Slider>
        </div>
      </section>
  )} */}

    </div>
  )
}

export default DiscoverDetail