import React, { useEffect, useState } from 'react'
import discoverIcon1 from '../../assets/images/discover-icon1.svg'
import discoverIcon2 from '../../assets/images/discover-icon2.svg'
import discoverIcon3 from '../../assets/images/discover-icon3.svg'
import discoverIcon4 from '../../assets/images/discover-icon4.svg'
import discoverIcon5 from '../../assets/images/discover-icon5.svg'
import { getApi } from '../../Services/apiService'

function UniqueOffering({setSelectedCategoryId,selectedCategoryId}) {
  const [categoryData,setCategoryData] = useState([])
  useEffect(()=>{
    getCategory()
  },[])

  const getCategory =async()=>{
    const response = await getApi('/category');
    if(response?.statusCode === 200){
      setCategoryData(response?.data)
    }
  }

  const handleCategoryClick = (categoryId) => {
    setSelectedCategoryId(prevCategoryId => prevCategoryId === categoryId ? null : categoryId);
    // setShouldFetch(true); // Trigger fetch when category changes
};

  return (
    <>
      <ul>
        {categoryData && categoryData?.length > 0 && categoryData.map((x, index) => {
          return (
            <li onClick={()=>handleCategoryClick(x?.id)} className={selectedCategoryId === x?.id ? 'active' : ''}>
              <img src={x?.category_image} />
              <h4>{x?.category_name}</h4>
            </li>
          )
        })}
        {/* <li>
          <img src={discoverIcon1} />
          <h4>Wellness</h4>
        </li>
        <li>
          <img src={discoverIcon2} />
          <h4>Creativity</h4>
        </li>
        <li>
          <img src={discoverIcon3} />
          <h4>Personal Growth</h4>
        </li>
        <li>
          <img src={discoverIcon4} />
          <h4>Abundance</h4>
        </li>
        <li>
          <img src={discoverIcon5} />
          <h4>Parenting</h4>
        </li> */}
      </ul>
    </>
  )
}

export default UniqueOffering