import React, { useState, useEffect } from 'react';
import db from '../FirebaseConfig/firebase';
import FirebaseChatService from '../FirebaseConfig/firebaseCharService';
import { addDoc, collection, doc, getDoc, onSnapshot, orderBy, query } from 'firebase/firestore';
import UserSidebar from '../../Layout/userSidebar';
import burger from '../../assets/images/burger-menu.svg';
import search from '../../assets/images/search.svg'
import action from '../../assets/images/three-dots.svg'
import chat_noData from '../../assets/images/chat-nodata.png'
import { getApi } from '../../Services/apiService';
import dummy from '../../assets/images/dummy-profile.jpg'

function ChatRoom() {
  const userDetails = JSON.parse(localStorage.getItem('userDetail'));
  const ids = userDetails?.id
  const user_role = localStorage.getItem('user_role')
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [pageLoader, setPageLoader] = useState(false);
  console.log("messagesmessages", messages);

  const [selectedUserId, setSelectedUserId] = useState(null);
  const [classState, setClassState] = useState(false);
  // const [users, setUsers] = useState([
  //   { id: 'user1', name: 'User 1' },
  //   { id: 'user2', name: 'User 2' },
  // ]);
  const [searchQuery, setSearchQuery] = useState('');
  const [dropdownMenu, setDropdownMenu] = useState(false)
  const [allUserData, setAllUserData] = useState([])
  const [blockUserData, setBlockUserData] = useState([])
  const [users, setUsers] = useState([])
  console.log("userData", users);


  const [senderId, setSenderId] = useState('')
  const id = JSON.parse(localStorage.getItem('full_user_detail'))
  const creatorDetailBooking = JSON.parse(localStorage.getItem('creatorDetail'))
  const userDetailBooking = JSON.parse(localStorage.getItem('userDetail'))
  // const senderId = id?.id;
  const [chatRoomSearchQuery, setChatRoomSearchQuery] = useState('');
  console.log("selectedUserIdselectedUserId", selectedUserId);
  const fetchMessages = async (chatRoomId) => {
    try {
      const result = await FirebaseChatService.getMessage(chatRoomId);

      if (result.success) {
        setMessages(result.data);
      } else {
        console.error('Error fetching messages:', result.error);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const listenForMessages = (chatRoomId) => {
    const messagesRef = collection(db, 'Chats', chatRoomId, 'messages');
    const q = query(messagesRef, orderBy('createdAt')); // Order messages by timestamp

    // Use Firestore's onSnapshot to listen for real-time updates
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedMessages = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMessages(fetchedMessages);
    });

    // Cleanup listener when component unmounts or chat room changes
    return () => unsubscribe();
  };

  const getAllUserData = async () => {
    const result = await FirebaseChatService.getAll()
    if (result?.success === true) {
      setAllUserData(result?.data)

    }
  }

  useEffect(() => {
    // setSelectedUserId(null);
  }, [users]);

  useEffect(() => {
    getAllUserData()

    if (userDetailBooking?.id) {
      createOrFetchChatRoom(creatorDetailBooking?.user_id, userDetailBooking?.id)
    }

  }, [])

  useEffect(() => {
    if (selectedUserId) {
      // const chatRoomId = `${selectedUserId}_${senderId}`;
      const [user1, user2] = [selectedUserId, senderId].sort();
      const chatRoomId = `${user1}_${user2}`;
      fetchMessages(chatRoomId);
      listenForMessages(chatRoomId)
    }
  }, [selectedUserId]);
  useEffect(() => {
    findBlockEntry(senderId, selectedUserId);
  }, [allUserData])

  const sendMessage = async (e) => {
    e.preventDefault();
    if (message.trim() === '') return;

    if (selectedUserId) {
      const [user1, user2] = [selectedUserId, senderId].sort();
      const chatRoomId = `${user1}_${user2}`;
      const receiverDoc = await getDoc(doc(db, 'users', selectedUserId));
      if (receiverDoc.exists() && receiverDoc.data().blockedUsers.includes(senderId)) {
        throw new Error('You cannot send a message to this user.');
      }

      // Check if the receiver is blocked by the sender
      const senderDoc = await getDoc(doc(db, 'users', senderId));
      if (senderDoc.exists() && senderDoc.data().blockedUsers.includes(selectedUserId)) {
        throw new Error('You cannot send a message to this user.');
      }
      const messagesRef = collection(db, 'Chats', chatRoomId, 'messages');

      await addDoc(messagesRef, {
        text: message,
        senderId: senderId,
        receiverId: selectedUserId,
        createdAt: new Date(),
      });

      fetchMessages(chatRoomId);
    }

    setMessage('');
  };


  const createOrFetchChatRoom = async (selectedUserId, sender) => {
    try {
      setPageLoader(true)
      setSenderId(sender);

      // Sort the IDs alphabetically to ensure a consistent chatRoomId
      const [user1, user2] = [selectedUserId, sender].sort();
      const chatRoomId = `${user1}_${user2}`;

      // Check if the chat room already exists
      const chatRoomExists = await FirebaseChatService.checkRoomExist(chatRoomId);
      // setTimeout(() => {
      //   setPageLoader(false)
      // }, 500);
      if (!chatRoomExists.success || !chatRoomExists.exists) {
        // Create a chat room document if it doesn't exist
        const chatData = {
          participants: [user1, user2],
          block: [{ id: '', senderId: sender, receiverId: selectedUserId, status: 0 }]
        };

        const result = await FirebaseChatService.create(chatData, chatRoomId);

        if (!result.success) {
          console.error('Error creating chat room:', result.error);
        }
        setPageLoader(false)
      }

      // Set the selected user
      setSelectedUserId(selectedUserId);
      setPageLoader(false)

      console.log('Selected user:', selectedUserId);
    } catch (error) {
      console.error('Error creating or fetching chat room:', error);
    }
  };


  // Determine whether the selected user has messages
  const userHasMessages = messages.some(msg => msg.user === selectedUserId);


  const handleSidebarClick = (event) => {
    event.stopPropagation();
    setClassState(!classState);
  };


  useEffect(() => {
    fetchUserData()
    const handleClick = () => setClassState(false);
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const fetchUserData = async () => {
    let response;
    if (user_role === 'creator') {
      response = await getApi('/creator/messages/users');
    }
    if (user_role === 'user') {
      response = await getApi('/user/messages/creators');
    }
    if (response?.statusCode === 200) {
      setUsers(response?.data)
    }
  }

  const deleteChatData = async (senderIds, recieverId) => {
    const [user1, user2] = [senderIds, recieverId].sort();
    const chatRoomId = `${user1}_${user2}`;
    const result = await FirebaseChatService.delete(chatRoomId);
    if (result?.success === true) {
      setMessages([])
      fetchMessages(chatRoomId);
    } else {
      console.error("Error deleting chat room:", result?.error);
    }
    console.log("result>>>>>>>>>", result)
  }

  const blockSingleUser = async (selectedUserId, sender, type) => {
    try {
      const [user1, user2] = [selectedUserId, sender].sort();
      const chatRoomId = `${user1}_${user2}`;

      // Check if the chat room already exists
      const chatRoomExists = await FirebaseChatService.checkRoomExist(chatRoomId);

      let chatData;
      if (type === 'block') {
        chatData = {
          participants: [user1, user2],
          block: [{ id: sender, senderId: sender, receiverId: selectedUserId, status: 1 }]
        };
      }
      if (type === 'unblock') {
        chatData = {
          participants: [user1, user2],
          block: [{ id: '', senderId: sender, receiverId: selectedUserId, status: 0 }]
        };
      }
      let result;
      if (chatRoomExists.success || chatRoomExists.exists) {
        // Create a chat room document if it doesn't exist
        result = await FirebaseChatService.update(chatData, chatRoomId);
        if (!result.success) {
          console.error('Error creating chat room:', result.error);
        }
        await getAllUserData();
        // await findBlockEntry(sender,selectedUserId)
      }
      else {
        result = await FirebaseChatService.create(chatData, chatRoomId);
        if (!result.success) {
          console.error('Error creating chat room:', result.error);
        }
        await getAllUserData();
        // await findBlockEntry(sender,selectedUserId)
      }

      if (!result.success) {
        console.error('Error creating chat room:', result.error);
      }



    } catch (error) {
      console.error('Error creating or fetching chat room:', error);
    }
  }
  const findBlockEntry = async (senderIds, receiverId) => {
    // r b2 s a1
    if (allUserData && allUserData.length > 0) {
      for (const user of allUserData) {
        if (user.participants.includes(senderIds) && user.participants.includes(receiverId)) {
          // const blockEntry = user.block?.find(entry => 
          //     entry.senderId == senderIds && entry.receiverId == receiverId
          // );
          // if (blockEntry) {
          //   setBlockUserData(blockEntry)
          //   return blockEntry;
          setBlockUserData(user?.block)
          return user?.block
          // }
        }
      }
    }
    return undefined
  };




  return (
    <div className="users meal-suggestion">
      <div className="HomeMainDiv">
        <UserSidebar classState={classState} />
        

          <div className={`${pageLoader ? 'right-main creator-chat ' : 'right-main creator-chat '} `}>
            {pageLoader ? 
             <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            :
            <div >
              <h2>
                <button className="burger-menu" onClick={handleSidebarClick}>
                  <img src={burger} alt='burger menu' />
                </button>
                Message
              </h2>

{/* {users?.length ===0 && selectedUserId===undefined 
?
<div className='blank-chat'>
                  <img src={chat_noData} alt='' />
                  <p>You have no messages yet.</p>

                </div> 
                :
                <> */}
              {(selectedUserId===null || selectedUserId ===undefined) && (
                <div className='blank-chat'>
                  <img src={chat_noData} alt='' />
                  <p>You have no messages yet.</p>

                </div>
              )}

              {selectedUserId && (

                <div className='col-6 creators-margin'>
                  <div class="form-group">
                    <div className="creators-input mt-1">
                      <img src={search} alt='' />
                      <input type="text" className="search ps-2" placeholder="Search or start new conversation" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                    </div>
                  </div>
                </div>
              )}
              <div className='row'>
                <div className='col-6'>
                  {users
                    .filter((user) =>
                      user.name.toLowerCase().includes(searchQuery.toLowerCase())
                    )
                    .map((user, index) => (
                      <>
                        <div class=" list-group-flush" key={index}>
                          <a href="#" class={`chat-group-item chat-group-item-action ${user.id === selectedUserId ? 'active' : ''}`}>
                            {/* <div class="d-flex align-items-center" onClick={() => {index ===0 ?createOrFetchChatRoom('b2','a1') : createOrFetchChatRoom('a1','b2')}}> */}
                            <div class="d-flex align-items-center" onClick={() => createOrFetchChatRoom(user.id, ids)}>
                              <span className='user-imgs'><img src={user?.user_profile_image || dummy} alt='' class=" me-3" /></span>
                              <div className={`w-100 my-2 `} >
                                <h6 class="mb-0 pb-1">{user.name} <span>{user?.specializations?.length>0 && user?.specializations[0]?.area_of_work}</span></h6>
                                {/* <h6 class="mb-0 pb-1">{index ===0 ?'ankit':'ankita'} <span>Yoga Instructor</span></h6> */}
                                {/* <p class="">Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat est velitolm.
                                        Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat est velitolm.
                                        Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat est velitolm.</p> */}
                              </div>
                            </div>
                          </a>
                        </div>
                      </>
                    ))}
                </div>
                <div className='col-6'>
                  {selectedUserId && (
                    <div class="sent-chat" >
                      <div class="chat-header p-3 d-flex justify-content-between align-items-center position-relative">
                        <div class="d-flex align-items-center">
                          <span className='user-imgs'><img src={users.find(user => user.id === selectedUserId)?.user_profile_image} alt='' class="rounded-circle " /></span>
                          {selectedUserId && (
                            <div>


                              <h6 class="mb-0">{users.find(user => user.id === selectedUserId)?.name}</h6>
                              <small class="text-muted">{users.find(user => user.id === selectedUserId)?.specializations?.length>0 && users.find(user => user.id === selectedUserId)?.specializations[0]?.area_of_work}</small>
                            </div>
                          )}
                        </div>
                        <div class="dropdown-action  px-3 cursor-pointer" onClick={() => setDropdownMenu(!dropdownMenu)}>
                          <img src={action} alt='' class="" />

                        </div>
                        {dropdownMenu &&
                          <div className='action-menu'>
                            <ul className='p-0 m-0'>
                              {blockUserData && blockUserData[0]?.id === senderId && blockUserData[0]?.id !== '' ?
                                <li className='pt-0 pb-2' onClick={() => blockSingleUser('b2', 'a1', 'unblock')}>UnBlock user</li>
                                :
                                <li className='pt-0 pb-2' onClick={() => blockSingleUser('b2', 'a1', 'block')}>Block user</li>
                              }
                              <li onClick={() => deleteChatData('b2', 'a1')}>Delete chat</li>
                            </ul>
                          </div>
                        }
                      </div>

                      <div class="chat-body p-3">
                        {messages
                          .filter((msg) =>
                            msg.text.toLowerCase().includes(chatRoomSearchQuery.toLowerCase())
                          )
                          .map((msg) => (
                            <div key={msg.id}
                              // style={{ marginBottom: '10px' }}
                              style={{
                                marginBottom: '10px',
                                alignSelf:
                                  msg.senderId === senderId ? 'flex-end' : 'flex-start',
                                backgroundColor:
                                  msg.senderId === senderId ? '#DCF8C6' : '#FFFFFF',
                                padding: '10px',
                                borderRadius: '10px',
                                maxWidth: '60%',
                              }}
                            >
                              {msg.text}
                            </div>
                          ))}
                      </div>

                      <div class="chat-footer p-3 ">
                        {selectedUserId && (
                          <form class="d-flex py-1" onSubmit={sendMessage}>
                            {blockUserData && blockUserData?.length > 0 && blockUserData[0]?.status === 1 ? 'you are block' :
                              <>
                                <input type="text" class="form-control border-0" placeholder="Write Your message..." value={message} onChange={(e) => setMessage(e.target.value)} />
                                <button type="submit" class="btn  ml-2">Send</button>
                              </>
                            }
                          </form>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* </>
} */}
            </div>
            
}
          </div>
        
      </div>
    </div>
  );
}

export default ChatRoom;
