import React, { useState } from 'react';
import { deleteApi, postApi } from '../../../Services/apiService';
import cross from '../../../assets/images/cross_nobg.svg'
import crossBG from '../../../assets/images/cros.svg'
import del from '../../../assets/images/del.svg'
import crossDark from '../../../assets/images/videocros.svg'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';


function BasicsGallery() {
    const userDetail = JSON.parse(localStorage.getItem('full_user_detail'))
    
    const navigate = useNavigate()
    const [video, setVideo] = useState(userDetail?.introduction_video || null);
    const [images, setImages] = useState([]);
    const [getImages, setGetImages] = useState( userDetail?.userGallery.map(image => ({ ...image, is_deleted: 0 })) || []);
    const [errorMessage, setErrorMessage] = useState('');
    const [isUploading, setIsUploading] = useState(false);

    const handleVideoChange = (event) => {
        const file = event.target.files[0];
        const maxSize = 200 * 1024 * 1024; // 200 MB in bytes

        if (file) {
            if (file.size > maxSize) {
                setErrorMessage('File size exceeds 200 MB. Please select a smaller file.');
                setVideo(null);
            } else {
                if (video && video.file) {
                    URL.revokeObjectURL(video.url);
                }
                setVideo({ file, url: URL.createObjectURL(file) });
                setErrorMessage('');
            }
        }
    };

    const handleImageChange = (event) => {
        const files = Array.from(event.target.files);
        const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
        let validImages = [];
        let error = '';

        files.forEach((file) => {
            if (file.size > maxSize) {
                error = 'One or more files exceed 5 MB. Please select smaller files.';
            } else {
                validImages.push({
                    file,
                    url: URL.createObjectURL(file),
                    name: file.name,
                });
            }
        });

        setImages([...images, ...validImages]);
        setErrorMessage(error);
    };

    const removeImage = (index) => {
        const updatedImages = images.filter((_, i) => i !== index);
        setImages(updatedImages);
    };
    const removeImageData = (index) => {
        setGetImages(prevImages =>
            prevImages.map((image, i) =>
                i === index ? { ...image, is_deleted: 1 } : image
            )
        );
    };

    const DelVideo = async() => {
        const resp = await deleteApi('/profile-video',video)
        if(resp?.statusCode ===200){
            toast.success('Introduction video deleted successfully')
        }
        setVideo(null)
    }

    const handleSubmit = async () => {
        setIsUploading(true); // Start the upload process
        const formData = new FormData();

        if (video && video.file) {
            formData.append('intro_video', video.file);
        }

        images.forEach((image, index) => {
            formData.append(`gallery_file[${index}]`, image.file);
        });
        const deletedIds = getImages.filter(image => image?.is_deleted === 1).map(image => image?.id); 
        if (deletedIds.length > 0) {
            formData.append('deleted_ids', deletedIds.join(','));
        }
        console.log('this is video', video, 'this is image', images);

        try {
            const response = await postApi('/creator/gallery', formData);
            console.log("response>>>>>>>", response);
            if (response?.statusCode === 200) {
                console.log('Upload successful!');
                navigate('/creator/home')
            } else {
                console.error('Upload failed.');
            }
            setIsUploading(false);
        } catch (error) {
            setIsUploading(false); // Start the upload process
            console.error('An error occurred while uploading:', error);
            // Handle error (e.g., show an error message)
        }
    };
    const isSaveDisabled = !video && images.length === 0;

    return (
        <div className="gallery pt-0">
            <div className="footer-buttons">
                <button className={`save ${isSaveDisabled || isUploading ? 'disabled' : ''}`} disabled={isSaveDisabled || isUploading} onClick={handleSubmit}>
                {isUploading &&
            <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>}Save
                    </button>
            </div>
            <br/>
            <div className="section first">
                <div className="top">
                    <h2>Introduction video</h2>
                    <h6>
                        Upload an introductory video and captivate your audience with dynamic visual content.
                        Let your product or service stand out from the crowd and highlight its unique features and benefits.
                    </h6>
                </div>
                <div className="bottom">
                    <div className="file-upload">
                        <label htmlFor="video-input" className="file-label">
                            {video === null ? 'Select Video' : 'Change Video'}
                        </label>
                        <input
                            type="file"
                            id="video-input"
                            className="file-input"
                            accept="video/*"
                            onChange={handleVideoChange}
                        />
                    </div>
                    {video === null && (
                        <h4>Any MP4, AVI files up to 200 MB</h4>
                    )}
                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                    {video && (

                        // <div className="video-preview">
                        <>
                            <button className='close' onClick={() => DelVideo()}><img src={del}></img></button>
                            <video key={video.url} className='gallery-video' controls>
                                <source src={video.url || userDetail?.introduction_video} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </>
                        // </div>
                    )}
                </div>
            </div>
            <div className="section second">
                <div className="top">
                    <h2>Media Gallery</h2>
                    <h6>Showcase Your Photos and Videos</h6>
                </div>
                <div className="bottom">
                    <div className="file-upload">
                        <label htmlFor="image-input" className="file-label">Select Image</label>
                        <input
                            type="file"
                            id="image-input"
                            className="file-input"
                            accept="image/jpeg,image/png,image/gif,video/*"
                            multiple
                            onChange={handleImageChange}
                        />
                    </div>

                    <h4>This image appears in the Microcosm Library, Discover, and Profile pages. Your image should be JPG, PNG, or GIF format up to 5 MB.</h4>
                    {errorMessage && <p className="error-message">{errorMessage}</p>}

                </div>
                <div className="image-previews">
                            <div  className="image-preview">
                                <div className='image-section'>
                                    <ul>
                                    {images.map((image, index) => (
                                        <li key={index}>
                                            {
                                                // image?.file?.type === 'image' ?
                                                image?.file?.type?.startsWith('image/') ?
                                                <img src={image.url} className='choose-img' alt={image.name} />
                                            :
                                                <video controls><source src={image?.url} type='video/mp4' /></video>
                                            }
                                            <button className='close' onClick={() => removeImage(index)}><img src={crossDark}></img></button>
                                        </li>
                                        ))}
                                    {getImages.map((image, index) => (
                                        image?.is_deleted==0 &&
                                        <li key={index}>
                                            {image?.media_type?.startsWith('image') ?
                                                <img src={image?.file_url} className='choose-img' alt={image?.media_type} />
                                            :
                                                <video controls><source src={image?.file_url} type='video/mp4' /></video>
                                            }
                                            <button className='close' onClick={() => removeImageData(index)}><img src={crossDark}></img></button>
                                        </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                    </div>
                {/* <div class="image-previews">
                    <div class="image-preview">
                        <div class="image-section">
                            <ul>
                                {images.map((image, index) => (
                                    <li key={index}>
                                        <div class="image-container">
                                            <img src={image.url} className="choose-img" alt={image.name} />
                                            <div class="button-wrapper">
                                                <button className="close" onClick={() => removeImage(index)}>
                                                    <img src={crossBG} alt="Close" />
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div> */}


            </div>
            {/* <div className="footer-buttons">
                <button className={`save ${isSaveDisabled || isUploading ? 'disabled' : ''}`} disabled={isSaveDisabled || isUploading} onClick={handleSubmit}>
                  Save
                    </button>
            </div> */}
        </div>
    );
}

export default BasicsGallery;
