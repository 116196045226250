import React, { useEffect, useState, useCallback } from 'react';
import UserSidebar from '../../Layout/userSidebar';
import burger from '../../assets/images/burger-menu.svg';
import cross from '../../assets/images/cross-button.svg';

function PushNotification(notification) {
console.log('this is notification',notification);
    //   }, [fetchData, searchQuery, selectedFilters]);

    return (
        <div className='notify-modal'>
            {/* <div className='text-end p-3 py-2'>
               <img src={cross} alt='' />
                </div> */}
            <ul class="ps-0 mb-0">
            <li class="list-group-item list-group-item-action dropdown-notifications-item">
                    <div class="d-flex">
                      <div class="flex-shrink-0 me-3">
                        <div class="avatar">
                         <span> <img src={notification?.notification?.image} alt="" class="rounded-circle"/></span>
                        </div>
                      </div>
                      <div class="flex-grow-1">
                        <h6 class="small mb-0">{notification?.notification?.title}</h6>
                        <small class="mb-1 d-block text-body">{notification?.notification?.body}</small>
                        <small class="text-muted">1h ago</small>
                      </div>
               
                    </div>
                  </li>
            </ul>
        </div>
    );
}
export default PushNotification;
