import React, { useEffect, useState } from 'react';
import UserSidebar from '../../Layout/userSidebar';
import burger from '../../assets/images/burger-menu.svg';
import { Link, useNavigate } from 'react-router-dom';
import { getApi, putApi } from '../../Services/apiService';
import call from '../../assets/images/callBlackFilled.svg';
import share from '../../assets/images/shareBlackFilled.svg';
import cross from '../../assets/images/cross-button.svg';
import checkCircle from '../../assets/images/check-circle-2.svg';
import Modal from 'react-modal';
import dummy from '../../assets/images/dummy-profile.jpg'
import search from '../../assets/images/search.svg';
import smile from '../../assets/images/lg-smile.svg';

function Requests() {
    const navigate = useNavigate();
    const [classState, setClassState] = useState(false);
    const [condition, setCondition] = useState('');
    const [userData, setUserData] = useState([]);
    const [creatorModalIsOpen, setCreatorModalIsOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [notes, setNotes] = useState('');
    const [actionType, setActionType] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [totalCount, setTotalCount] = useState({ approved: 0, declined: 0, pending: 0 });
    const [loader, setLoader] = useState(false)
    const [searchQuery, setSearchQuery] = useState('');
    const [successState, setSucessState] = useState(false);


    useEffect(() => {
        fetchData(searchQuery);
        const handleClick = () => setClassState(false);

        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [condition,searchQuery]);

    const handleSidebarClick = (event) => {
        event.stopPropagation();
        setClassState(!classState);
    };

    const fetchData = async (query = '') => {
        const response = await getApi(`/admin/request?search=${query}&status=${condition}`);
        if (response?.statusCode === 200) {
            setUserData(response?.data);
            setTotalCount(response?.totalCount || { approved: 0, declined: 0, pending: 0 });
        } else {
            localStorage.clear();
            navigate('/');
        }
    };

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    function convertTo12HourFormat(timeString) {
        const [hour, minute, second] = timeString.split(':').map(Number);
        const period = hour >= 12 ? 'PM' : 'AM';
        const adjustedHour = hour % 12 || 12;
        return `${String(adjustedHour).padStart(2, '0')}:${String(minute).padStart(2, '0')} ${period}`;
    }

    const handleUpdateData = async (status, id, notes) => {
        setLoader(true)
        const values = {
            status: status,
            creatorScheduleId: id,
            notes: notes
        };
        const response = await putApi(`/admin/request`, values);
        setLoader(false)
        return response;
    };

    const openModal = (id, actionType) => {
        setSelectedUserId(id);
        setActionType(actionType);
        setCreatorModalIsOpen(true);
        setSucessState(false)
    };

    const closeModal = () => {
        setCreatorModalIsOpen(false);
        setSelectedUserId(null);
        setNotes('');
        setSuccessMessage(''); // Clear success message on close
    };

    const handleAction = async () => {
        setLoader(true)
        const response = await handleUpdateData(actionType, selectedUserId, notes);
        if (response?.statusCode === 200) {
            setLoader(false)
            setSuccessMessage(actionType === 'approve' ? 'User Approved Successfully' : 'Request Is Declined Successfully');
            setSucessState(true)
            await fetchData(); // Refresh the data
        } else {
            setSuccessMessage('An error occurred');
        }
    };
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
      };
    return (
        <div className="users meal-suggestion">
            <div className="HomeMainDiv">
                <UserSidebar classState={classState} />
                <div className="request-member right-main">
                    <div className="members-top">
                        <h2> <button className="burger-menu" onClick={handleSidebarClick}><img src={burger} alt='' /></button> Requests</h2>
            
                    </div>

                    <div className="member-filter request">
                        <ul class="mb-0 ps-0">
                            <li onClick={() => { setCondition(''); setSearchQuery(''); }} className={condition === '' ? "active" : ''}>
                                <Link to="">New({totalCount.pending})</Link>
                            </li>
                            <li onClick={() => {setCondition('approve');setSearchQuery(''); }} className={condition === 'approve' ? "active" : ''}>
                                <Link to="">Approved({totalCount.approved})</Link>
                            </li>
                            <li onClick={() => {setCondition('decline');setSearchQuery(''); }} className={condition === 'decline' ? "active" : ''}>
                                <Link to="">Declined({totalCount.declined})</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="filter-search filter-right">
              <button><img src={search} alt="search" /></button>
              <input className="search" type="text" placeholder="Search"value={searchQuery} onChange={handleSearchChange}/>
            </div>
                    {userData && userData.length > 0 ? (
                        <>
                        <div className='overflow-scrolls'>
                            <div className="table-header">
                                <ul className='ps-0'>
                                    <li className="id">Id</li>
                                    <li className="name">Creator Details</li>
                                    <li className="email">Onboarding Call Details</li>
                                    <li className="date">
                                        {condition === 'approve' || condition === 'decline' ? 'Link' : 'Website/Social Media Link'}
                                    </li>
                                    {condition !== 'approve' && condition !== 'decline' && <li className="type">Call</li>}
                                    {(condition === 'approve' || condition === 'decline') && <li className="notes">Notes</li>}
                                    <li className="Action">Action</li>
                                </ul>
                            </div>
                            <div className="request-diff-cs">
                                {userData.map((x, index) => {
                                    return (
                                        <ul className="table-row" key={index}>
                                            <li className="id"> #{index + 1}</li>
                                            <li className="name">
                                                <span className='user-image'><img src={x?.creator?.user_profile_image || dummy} alt='' /></span>
                                                <div className="user-info request">
                                                    <h3>{x?.creator?.name}</h3>
                                                    <h4 className='emails'>Email: <span className='email ps-1'>{x?.creator?.email}</span></h4>
                                                    <h5>Field: {x?.creator?.specializations && x?.creator?.specializations?.length > 0 && x?.creator?.specializations[0]?.area_of_work}</h5>
                                                    <h6>Experience:{x?.creator?.specializations && x?.creator?.specializations?.length > 0 && x?.creator?.specializations[0]?.experience} </h6>
                                                </div>
                                            </li>
                                            <li className="email">{formatDate(x?.interview_date) + ' ' + convertTo12HourFormat(x?.interview_time)}</li>
                                            <li className="date"><p>{x?.creator?.link}</p></li>
                                            {(condition === 'approve' || condition === 'decline') && (
                                                <li className="notes">
                                                    <p>{x?.notes || '-'}</p>
                                                </li>
                                            )}
                                            {condition !== 'approve' && condition !== 'decline' && (
                                                <li className="type">
                                                    <div className='call-image d-flex'>
                                                        <div> <img className='type-img' src={call} alt='' /></div>
                                                        <div><img className='type-img' src={share} alt='' /></div></div>
                                                </li>
                                            )}
                                            {condition === 'approve' && (
                                                <li className="Action">
                                                    <button className='decline-btn' onClick={() => openModal(x?.id, 'decline')}>Decline</button>
                                                </li>
                                            )}

                                            {condition === 'decline' && (
                                                <li className="Action">
                                                    <button className='approve-btn' onClick={() => openModal(x?.id, 'approve')}>Approve</button>
                                                </li>
                                            )}

                                            {condition === '' && (
                                                <li className="Action">
                                                    <button className='approve-btn' onClick={() => openModal(x?.id, 'approve')}>Approve</button>
                                                    <button className='decline-btn' onClick={() => openModal(x?.id, 'decline')}>Decline</button>
                                                </li>
                                            )}


                                        </ul>
                                    );
                                })}
                            </div>
                            </div>
                        </>
                    ) : (
                        <div className="table-header">
                            {condition === '' && "Oops! No new requests."}
                            {condition === 'approve' && "Oops! No Approved requests."}
                            {condition === 'decline' && "Oops! No Declined requests."}
                        </div>
                    )}
                </div>
            </div>
            <Modal
                isOpen={creatorModalIsOpen}
                onRequestClose={closeModal}
                ariaHideApp={false}
                contentLabel="Request Action"
                shouldCloseOnOverlayClick={false}
                overlayClassName={`modal-overlay add-category delete request approve ${successState ? 'apiMessage' : ''}`}>

                {!successMessage ? (
                    <>
                        <h2>{(actionType === 'approve' ? 'Notes To Approve' : 'Notes To Decline')}</h2>
                    </>
                ) : (<></>)}

                {successMessage ? (
                    <>
                        <img className='check-circle' src={actionType === 'approve' ? checkCircle : smile } alt='close' />
                        <h2>{successMessage}</h2>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                        <button className='close-button' onClick={closeModal}>
                            <img src={cross} alt='close' />
                        </button>
                        {/* <button className="cancel-btn" onClick={closeModal}>Close Modal</button> */}
                    </>
                ) : (
                    <>
                        {/* <p>Are you sure you want to {actionType === 'approve' ? 'approve' : 'decline'} this request?</p> */}
                        <div className="modal-form">
                            {/* <div className="modal-content"> */}
                            <label htmlFor="notes">Notes (Optional)</label>
                            <textarea id="notes" value={notes} onChange={(e) => setNotes(e.target.value)} placeholder="Kindly add the notes to Approve the Request" className="form-control" />
                            {/* </div> */}
                        </div>
                        <div className="modal-buttons">
                            <button className="cancel-btn" onClick={closeModal}>Cancel</button>
                            <button className="save-btn" onClick={handleAction} disabled={loader}>
                                {loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}
                                {actionType === 'approve' ? 'Approve' : 'Decline'}
                            </button>
                        </div>

                    </>
                )}
            </Modal>
        </div>
    );
}

export default Requests;
