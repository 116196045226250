import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { insertOfferingData } from '../../../Counter/offeringSLice'
import { useDispatch } from 'react-redux'
import leftArrow from '../../../assets/images/backarrow.svg'
import ModalOfferingPlace from './modalOfferingplace'
import UserSidebar from '../../../Layout/userSidebar'
import burger from '../../../assets/images/burger-menu.svg'
import ActiveLinks from './activeLinks'
import OfferingDetails from './OfferingDetails'
import { getApi } from '../../../Services/apiService'
import TimezonePicker from 'react-timezone';
import dollar from '../../../assets/images/dollar.svg'
import choosearrow from '../../../assets/images/choose-arrow.svg'
import greentick from '../../../assets/images/green-tick.svg'
import DraftModal from './draftModal'

function SessionSessionHybrid({ nextStep, prevStep, step, data,submitForm,type }) {

    const dispatch = useDispatch();
    const [classMode, setClassMode] = useState(data?.classMode || '')
    const [timingZone, setTimingZone] = useState(data?.timingZone || '')
    const [invitationLink, setInvitationLink] = useState(data?.invitationLink || '')
    const [offeringType, setOfferingType] = useState(data?.personPlace || '')
    const [modalOpen, setModalOpen] = useState(false)
    const [chargeFees, setChargeFees] = useState(data?.chargeFees || '')
    const [classState, setClassState] = useState(false)
    const [errors, setErrors] = useState({});
    const [getAddress, setGetAddress] = useState([]);
    const [travelArea, setTravelArea] = useState(data?.travelOpen || false)
    const [chargeTravel, setChargeTravel] = useState(data?.chargeOpen || false)
    const [inputTravelArea, setInputTravelArea] = useState(data?.travelArea || '')
    const [inputChargeTravel, setInputChargeTravel] = useState(data?.chargeTravel || '')
    const [addressForm, setAddressForm] = useState(data?.locationInput || {})
    const [addressState,setAddressState] = useState(false);
    const [classHappen,setClassHappen] = useState(false)
    const [stateModal, setStateModal] = useState(false)
    const [timeZone, setTimeZone] = useState(data?.timingZone ||'');
    // const [timeZone, setTimeZone] = useState(data?.timingZone ||'Asia/Yerevan');
    const [isModal, setIsModal] = useState(false)
    const [tickSign,setTickSign] = useState(false)

    // const [errors,setErrors] =

    console.log("addressForm", addressForm)
    console.log("chargeFees", offeringType)
    // const offeringValue = ['At my place', 'I can travel', 'Both']
    const offeringValue = [{ name: 'At my place', label: 'place' }, { name: 'I can travel', label: 'travel' }, { name: 'Both', label: 'both' }]
    console.log("classMode", classMode);
    console.log("timingZone", timingZone);
    console.log("invitationLink", invitationLink);

    useEffect(() => {
        handleGetAddress();
    }, [addressState,classHappen])

    useEffect(()=>{
        handleTickSign()
    },[timeZone,invitationLink,travelArea,inputTravelArea,inputChargeTravel,addressForm])

    const handleTickSign = ()=>{
        if(classMode==='online'){
            timeZone!='' && invitationLink !='' ?setTickSign(true) : setTickSign(false)
        }
        if(classMode==='hybrid'){
            addressForm!={} && travelArea ===false ? setTickSign(true) : (inputTravelArea!='' && inputChargeTravel !='' ? setTickSign(true): setTickSign(false))
        }
        if(classMode==='in_person'){
            timeZone!='' && invitationLink !='' && addressForm!={} && travelArea ===false ? setTickSign(true) : (inputTravelArea!='' && inputChargeTravel !='' ? setTickSign(true): setTickSign(false))
        }
    }

    const handleGetAddress = async () => {
        const response = await getApi('/address')
        console.log('getAddress', response)
        setGetAddress(response?.data)
    }


    // const validateForm = () => {
    //     const newErrors = {};

    //     // if (!classMode) {
    //     //     newErrors.classMode = 'Class mode is required';
    //     // }

    //     // if (!timingZone) {
    //     //     newErrors.timingZone = 'Timing Zone is required';
    //     // }
    //     // if (!invitationLink) {
    //     //     newErrors.invitationLink = 'invitationLink is required';
    //     // }

    //     // if (classMode === 'in_person' && !offeringType) {
    //     //     newErrors.offeringType = 'Offering type is required';
    //     // }

    //     // if ((offeringType === 'I can travel' || offeringType === 'Both') && !chargeFees) {
    //     //     newErrors.chargeFees = 'Travel price details are required';
    //     // }

    //     // if (classMode === 'in_person' && !invitationLink) {
    //     //     newErrors.invitationLink = 'Invitation link is required';
    //     // }

    //     setErrors(newErrors);

    //     return Object.keys(newErrors).length === 0;
    // };

    const validateForm = () => {
        let newErrors = {};
    
        if (!classMode) {
            newErrors.classMode = "Please select how the class will happen.";
        }
    
        if (classMode !== 'in_person' && !timingZone) {
            newErrors.timingZone = "Please select a timing zone.";
        }
    
        if (classMode !== 'in_person' && !invitationLink) {
            newErrors.invitationLink = "Please enter the invitation link.";
        }
        else if (/\s/.test(invitationLink)) { // Check for spaces in the name
            newErrors.invitationLink = 'Invitation Link cannot contain spaces';
          }

        if(classMode!== 'online' && !addressForm?.address ){
            newErrors.location = 'please enter the location'
        }
    
        // if (classMode !== 'in_person' && invitationLink === '') {
        //     newErrors.invitationLink = "Please enter the invitation link.";
        // }
        if (classMode !== 'in_person' && !invitationLink) {
            newErrors.invitationLink = "Please enter the invitation link.";
          }
          else if (/\s/.test(invitationLink)) { // Check for spaces in the link
            newErrors.invitationLink = 'Invitation Link cannot contain spaces';
          }
          else if (!/^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-]*)?$/.test(invitationLink) && classMode !== 'in_person' ) {
            // Check if the link is valid using a basic URL regex
            newErrors.invitationLink = "Please enter a valid URL (e.g., https://example.com)";
          }
    
        // if (classMode !== 'online' && travelArea && !inputTravelArea) {
        //     newErrors.travelArea = "Please enter the travel area.";
        // }
        if (classMode !== 'online' && travelArea) {
            // const trimmedTravelArea = inputTravelArea.trim();
            if(!inputTravelArea){
                newErrors.travelArea = "Please enter the travel area.";
            }
            if(inputTravelArea.trim().length !== inputTravelArea.length){
                newErrors.travelArea = "Travel area cannot contain spaces";
            }
            // if (!trimmedTravelArea) {
            //     newErrors.travelArea = "Please enter the travel area.";
            // }
        }
    
        if (classMode !== 'online' && chargeTravel) {
            if(!inputChargeTravel){
                newErrors.chargeTravel = "Please enter the travel charge.";
            }
            if(inputChargeTravel.trim().length !== inputChargeTravel.length){
                newErrors.chargeTravel = "Travel charge cannot contain spaces";
            }
            // newErrors.chargeTravel = "Please enter the travel charge details.";
        }
    
        // return errors;
        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };
    

    const handleType = (value) => {
        setOfferingType(value)
        setModalOpen(true)
    }

    const closeModal = () => {
        setModalOpen(false)
    };

    const handleSave = async(type) => {
        const values = {
            classMode: classMode,
            timingZone: timingZone,
            invitationLink: invitationLink,
            personPlace: offeringType,
            travelArea: inputTravelArea,
            chargeTravel: inputChargeTravel,
            locationInput: addressForm,
            travelOpen:travelArea,
            chargeOpen:chargeTravel,
            step:step
            // in_person_option:3

        }
        if (chargeFees) {
            values.chargeFees = chargeFees
        }
        if(type=== 'prev'){
            dispatch(insertOfferingData(values))
            prevStep()
          }
          else{
        if (validateForm()) {
            dispatch(insertOfferingData(values))
            if(type=== 'draft'){
                // submitForm('draft',values)
                setStateModal(true)
                // if(type === 'product'){
            
                // } else{
                const resp = await submitForm('draft',values)
                if(resp === true){
                  setStateModal(false)
                  setIsModal(true)
                } else{
                  setStateModal(false)
                }
            }
            else{
                nextStep();
            }
        }
    }
    }

    const handleSetMode = (type) => {
        // setClassMode(type)
        const values = {
            classMode: type,
            step:step
        }
        dispatch(insertOfferingData(values))
        // nextStep('types', type);
    }

    useEffect(() => {
        const handleClick = () => setClassState(false);

        document.addEventListener('click', handleClick);

        // Cleanup event listener on component unmount
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [])
    const handleSidebarClick = (event) => {
        event.stopPropagation();
        setClassState(!classState);
    };


    const updateReduxData = (updatedValues) => {
        dispatch(insertOfferingData(updatedValues));
      };
    
      useEffect(() => {
        if (data?.editype === 'edit') {
          const updatedData = {
            classMode: classMode,
            timingZone: timingZone,
            invitationLink: invitationLink,
            personPlace: offeringType,
            travelArea: inputTravelArea,
            chargeTravel: inputChargeTravel,
            locationInput: addressForm,
            travelOpen:travelArea,
            chargeOpen:chargeTravel,
            chargeFees:chargeFees
          };
    
          updateReduxData(updatedData);
        }
      }, [
        classMode,
        timingZone,
        invitationLink,
        offeringType,
        inputTravelArea,
        inputChargeTravel,
        addressForm,
        travelArea,
        chargeTravel,
        chargeFees,
      ]);


    return (
        <div>
            <UserSidebar classState={classState} />
            <div className='right-main'>
                <div class="session step-2 where">
                    {type!== 'edit' &&
                    <>
                    <div class="header">
                        <h1><button class="burger-menu" onClick={handleSidebarClick}><img src={burger} alt='' /></button>Session or Service</h1>
                        <div class="top-buttons">
                            <button class="save-draft" onClick={()=>handleSave('draft')} disabled={stateModal}>{stateModal && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Save to Draft</button>
                            <button class="next" onClick={handleSave}>Next</button>
                        </div>
                    </div>
                    {/* <!-- active links --> */}
                    <div class="active-links">

                        <button className="back" onClick={() => handleSave('prev')}><img src={leftArrow} alt='' /> </button>

                        <ActiveLinks step={step} />
                    </div>
                    </>
}




                    {/* <!-- Main --> */}
                    <div class="main">
                        <div class="row">
                            <div class="col-sm-7">
                            {/* {errors.invitationLink && <div className="error">{errors.invitationLink}</div>}
                            {errors.timingZone && <div className="error">{errors.timingZone}</div>}
                            {errors.classMode && <div className="error">{errors.classMode}</div>}
                            {errors.location && <div className="error">{errors.location}</div>}
                            {errors.travelArea && <div className="error">{errors.travelArea}</div>}
                            {errors.chargeTravel && <div className="error">{errors.chargeTravel}</div>} */}
                                <div class="left">
                                    <h1>Where</h1>


                                    <div class="size-range bottom error-fixs">
                                        <h6>How class will happen?</h6>  {tickSign &&<img src={greentick} class="green-tick mb-1" alt='' />}
                                        {/* <div class="bottom-buttons">
                                            <label class="radio-check3">
                                                <input type="radio" checked={classMode === 'online'} name="radio" onClick={() => { setClassMode('online'); handleSetMode('online') }} />
                                                <span class="checkmark">Online</span>
                                            </label>
                                            <label class="radio-check3">
                                                <input type="radio" checked={classMode === 'in_person'} name="radio" onClick={() => { setClassMode('in_person'); handleSetMode('in_person') }} />
                                                <span class="checkmark">In Person</span>
                                            </label>
                                            <label class="radio-check3">
                                                <input type="radio" checked={classMode === 'hybrid'} name="radio" onClick={() => { setClassMode('hybrid'); handleSetMode('hybrid') }} />
                                                <span class="checkmark">Hybrid</span>
                                            </label>
                                        </div> */}
                                        <div className="bottom-buttons time-select-zone">
  <div 
    className={`radio-check3 ${classMode === 'online' ? 'selected' : ''}`} 
    // onClick={() => { setClassMode('online'); handleSetMode('online') }}
    onClick={()=>{
        if (data?.editype !== 'edit') {
            {setClassMode('online'); handleSetMode('online')}
          }
    }}
  >
    <span className="checkmark">Online</span>
  </div>

  <div 
    className={`radio-check3 ${classMode === 'in_person' ? 'selected' : ''}`} 
    // onClick={() => { setClassMode('in_person'); handleSetMode('in_person') }}
    onClick={()=>{
        if (data?.editype !== 'edit') {
            {setClassMode('in_person'); handleSetMode('in_person')}
          }
    }}
  >
    <span className="checkmark">In Person</span>
  </div>

  <div 
    className={`radio-check3 ${classMode === 'hybrid' ? 'selected' : ''}`} 
    // onClick={() => { setClassMode('hybrid'); handleSetMode('hybrid') }}
    onClick={()=>{
        if (data?.editype !== 'edit') {
            {setClassMode('hybrid'); handleSetMode('hybrid')}
          }
    }}
  >
    <span className="checkmark">Hybrid</span>
  </div>
</div>
                                        {errors.classMode && <div className="error">{errors.classMode}</div>}
                                        {classMode !== 'in_person' &&
                                            <>
                                            
                                                <div class="of-offering time-sett-cs mb-3">
                                                    <h6 className='d-block'>Time Zone *</h6>
                                                    {/* <form action="">
                                                        <select name="cars" id="cars" onChange={(e) => setTimingZone(e.target.value)} value={timingZone}>
                                                            <option value=''></option>
                                                            <option value="class">IST</option>
                                                            <option value="Workshop">UTC</option>
                                                            <option value="Photoshoot">EST</option>
                                                            <option value="Coaching">ET</option>
                                                        </select>
                                                        {errors.timingZone && <div className="error">{errors.timingZone}</div>}
                                                    </form> */}
                                                    {/* <div className='dropdown'> */}
                                                    {/* <button  className='time-select'>
                    
                        <img src={choosearrow} alt='' />
                      </button> */}
                      {/* <div id="myDropdown" className="dropdown-content"> */}
                      <div className='time-zone-cs ' id="">
                                                    <TimezonePicker
    value={timeZone}
    onChange={(timezones) => {setTimingZone(timezones);setTimeZone(timezones)}} 
    inputProps={{
      placeholder: 'Select time zone',
      name: 'timezone',
      className: 'daily-weeks-selct-input',
    //   readOnly: true, 
    }}
  />
  {/* </div> */}
  {errors.timingZone && <div className="error">{errors.timingZone}</div>}
  </div>
  {/* </div> */}
                                                    <p>If you change the time zone here, it will only apply to this offer. To update it permanently for all offers, please adjust it in your settings.</p>
                                                </div>
                                                <div class="invitation-link mb-0">
                                              
                                                    <label htmlFor="invitation-link" class="mb-0">Invitation link *</label>
                                                    <input type="text" id="invitation-link" placeholder="Enter link where class suppose to be" value={invitationLink} onChange={(e) => setInvitationLink(e.target?.value)}  onBlur={()=>validateForm()}/>
                                                    {errors.invitationLink && <div className="error">{errors.invitationLink}</div>}


                                                </div>
                                            </>
                                        }
                                        {classMode !== 'online' &&
                                            <>
                                                <div class="invitation-link invite-link location-set mt-3">
                                                    <label htmlFor="invitation-link " className='mb-0'>Location *</label>
                                                    <input type="text" id="invitation-link" placeholder="Street Name and Number, apartment number" value={`${addressForm?.address || ''} ${addressForm?.city || ''} ${addressForm?.state || ''} ${addressForm?.country || ''}`} onClick={() => setModalOpen(true)} />
                                                   <h3 class="change-loc mb-2" onClick={() => setModalOpen(true)}>Change place location</h3>
                                                   {/* {classMode === 'in_person' || (classMode==='hybrid' && addressForm != {})} <h3 class="change-loc mb-2">Change place location</h3> */}
                                                    <p>Please note: Your precise address will be visible to users only after purchasing the offer, ensuring safety.</p>
                                                    {errors.location && <div className="error">{errors.location}</div>}


                                                </div>

                                                <div className="switch-content can-travel">
                                                    <div className="switch-button">
                                                        <label className="switch">
                                                            <input type="checkbox" onClick={() => {setTravelArea(!travelArea);setChargeTravel(!chargeTravel)}} checked={travelArea} />
                                                            <span className="slider"></span>
                                                        </label>
                                                    </div>
                                                    <h3 className='mb-0'>I can Travel</h3>
                                                    {travelArea ?
                                                        <div className='form-group mt-1'>
                                                            <label>Travel Area *</label>
                                                            <input type="text" className='form-control' placeholder="Type your area (Downtown Austin, North Austin,Sou..." value={inputTravelArea} onChange={(e) => setInputTravelArea(e?.target?.value)}></input>
                                                            {errors.travelArea && <div className="error">{errors.travelArea}</div>}
                                                        </div>
                                                        :
                                                        <>
                                                            <p>Allow users to suggest an alternative location for the session, in addition to the fixed address. This gives clients/students the option to have the session at their home or office if they prefer.</p>
                                                        </>
                                                    }
                                                </div>
                                                {travelArea &&
                                                    <div className="switch-content can-travel">
                                                        <div className="switch-button">
                                                            <label className="switch">
                                                                <input type="checkbox" checked={chargeTravel} />
                                                                {/* <input type="checkbox" onClick={() => setChargeTravel(!chargeTravel)} checked={chargeTravel} /> */}
                                                                <span className="slider"></span>
                                                            </label>
                                                        </div>
                                                        <h3 className='mb-0'>Charge for Travel fee</h3>
                                                        {chargeTravel &&
                                                            <div className='form-group mt-1 min-session position-relative mb-0'>
                                                                <label>Enter price per mile *</label>
                                                                <input type="number" className='form-control ps-4' placeholder="Enter travel price details" value={inputChargeTravel} onChange={(e) => {e.target.value > 0 ? setInputChargeTravel(e?.target?.value) : setInputChargeTravel('')}} ></input>
                                                                <img src={dollar} class="input-img" alt='' />
                                                                {errors.chargeTravel && <div className="error">{errors.chargeTravel}</div>}
                                                            </div>
                                                        }
                                                    </div>
                                                }

                                            </>
                                        }
                                    </div>

                                    <ModalOfferingPlace offeringType={offeringType} closeModal={closeModal} modalOpen={modalOpen} type="hybrid" getAddress={getAddress} setAddressForm={setAddressForm} setAddressState={setAddressState} addressState={addressState} setClassHappen={setClassHappen} classHappen={classHappen} classMode={classMode} data={data} handleGetAddress={handleGetAddress}/>

                                </div>
                            </div>
                            {type !== 'edit' &&
                            <OfferingDetails data={data} />
}
                        </div>
                    </div>
                </div>
            </div>
            <DraftModal setIsModal={setIsModal} isModal={isModal}/>
        </div>
    )
}

export default SessionSessionHybrid