import React, { useEffect, useState } from "react";
import UserSidebar from "../../../Layout/userSidebar";
import share from "../../../assets/images/share1.svg";
import heart from "../../../assets/images/heart.svg";
import leftArrow from "../../../assets/images/backarrow.svg";
import setting from "../../../assets/images/setting-screw.svg";
import { formatDateandTime } from "../../../Helper/helperFunction";
import { Link, useNavigate, useParams } from "react-router-dom";
import cardJewellery from "../../../assets/images/card jwellery.png";
import dummyImage from "../../../assets/images/profile3.svg";
import { getApi } from "../../../Services/apiService";
import facebook from "../../../assets/images/creator-facebook.svg";
import twitter from "../../../assets/images/creator-twitter.svg";
import instagram from "../../../assets/images/Instagram-Logo.wine 1.svg";
import facebookgrey from "../../../assets/images/user-social1.svg";
import twittergrey from "../../../assets/images/twiter-grey.svg";
import instagramgrey from "../../../assets/images/Insta-grey.svg";
import session from "../../../assets/images/session-calender.svg";
import clock from "../../../assets/images/clock.svg";
import calendar from "../../../assets/images/calendar.svg";
import people from "../../../assets/images/people.svg";
import mapPin from "../../../assets/images/map-pin.svg";
import checkTravel from "../../../assets/images/check-travel.svg";

function SingleBooking() {
  const navigate = useNavigate();
  const [classState, setClassState] = useState(false);
  const [data, setUserData] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedUser, setIsExpandedUser] = useState(false);
  const params = useParams();

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };
  const toggleTextUser = () => {
    setIsExpandedUser(!isExpandedUser);
  };
  useEffect(() => {
    fetchData();
    // fetchData(searchQuery, selectedFilters); // Initial f../etch
    const handleClick = () => setClassState(false);
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const description = data?.offerService?.description || "";
  const isLongDescription = description?.length > 300;
  const truncatedDescription =
    description?.length > 300 ? `${description.slice(0, 300)}...` : description;
  const descriptionUser = data?.user?.about || "";
  const isLongDescriptionUser = descriptionUser?.length > 300;
  const truncatedDescriptionUser =
    descriptionUser?.length > 300
      ? `${descriptionUser.slice(0, 300)}...`
      : descriptionUser;
  const navBack = () => {
    navigate("/admin/booking");
  };

  const fetchData = async () => {
    try {
      const response = await getApi(
        `/admin/booking-info?orderId=${params?.id}`
      );
      if (response?.statusCode === 200) {
        setUserData(response?.data?.order);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <UserSidebar classState={classState} />
      <div className="right-main order-details-admin booking-admin">
        {/*  */}
        <div className="session  creators-top p-0">
          <div class="members-top">
            <h2>Order Details</h2>
            <div className="members-top-right">
              <button class="btn btn-share">
                <img src={share} />
                Share
              </button>{" "}
              <img src={heart} />
            </div>
          </div>

          {/* <div class="member-filter">
            <h3
              onClick={() => {
                navBack();
              }}
              className="text-start mb-0"
            >
              <img src={leftArrow} /> {data?.name}Necklace
            </h3>
            <h2> <button className="burger-menu" onClick={handleSidebarClick}><img src={burger} alt='' /></button> Order Details</h2>
            <ul className="processing-list ps-0 text-end mb-0">
              <li>
                <button className="btn order-pro">
                  <img src={setting} alt="order-img" className="pe-2" />
                  Order processing
                </button>{" "}
              </li>
            </ul>
          </div> */}
          {/* <div className="row order-payment-status align-items-center">
            <div className="col-sm-6">
              <div class="choose-tags m-0">
                <ul>
                  <li class="custom-checkbox mb-0">
                    <input disabled="" type="checkbox" id="option0" />
                    <label for="option0">Accessorise</label>
                  </li>
                  <li class="custom-checkbox mb-0">
                    <input disabled="" type="checkbox" id="option1" />
                    <label for="option1">Jewellery</label>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-6">
              <ul className="processing-list ps-0 text-end mb-0">
                <li>
                  Payment Status:{" "}
                  <button className="btn order-pro">Completed</button>{" "}
                </li>
              </ul>
            </div>
          </div> */}

          {/* ------------------------- */}
          <div className="order-address border-top-0 pt-2">
            <div className="row order-payment-status mt-2">
              <div className="col-sm-6">
                <h5 class="card-title">About Order</h5>
                <ul>
                  <li>
                    <strong>Booking id:</strong> #
                    {data?.offerService?.id?.slice(-6)}
                  </li>
                  <li>
                    <strong>Date & Time:</strong>{" "}
                    {formatDateandTime(data?.createdAt)}
                  </li>
                  <li>
                    <strong>Amount:</strong> ${data?.price}
                  </li>
                  <li>
                    <strong>Transaction ID:</strong> #
                    {data?.paymentDetails?.paymentIntentId.slice(-8)}
                  </li>
                  <li>
                    <strong>Payment Method:</strong> Credit card
                  </li>
                </ul>
              </div>
              <div className="col-sm-6">
                <ul className="processing-list ps-0 text-end mb-0">
                  <li>
                    Payment Status:{" "}
                    <button className="btn order-pro">Completed</button>{" "}
                  </li>
                </ul>
              </div>
              {/* <div className='col-sm-6'>
            <h5 class="card-title">Shipping Information</h5>
              <ul>
                <li><strong>Recipient Name:</strong> {data?.user?.user_name}</li>
                <li><strong>Address:</strong> {data?.user?.address ? data?.user?.address[0]?.address :''}</li>
                <li><strong>Email:</strong> {data?.user?.email}</li>
                <li><strong>Delivery Method:</strong> {data?.offerService?.delivery_option==='1'?'PickUp':(data?.offerService?.delivery_option==='2'?'Delivery':'Both')}</li>
              </ul>
            </div> */}
            </div>
          </div>
          {/* ------------------------ */}
        </div>
        {/* ----- */}
        <div class="session after-upload-produt">
          {/* <div class="active-links mb-2">
           <h2 onClick={() => { navBack() }} className='text-start'><img src={leftArrow} /> {data?.name}</h2>
            <h2> <button className="burger-menu" onClick={handleSidebarClick}><img src={burger} alt='' /></button> Order Details</h2>
          </div> */}
          <div class="main mt-0">
            <div class="row">
              <div class="col-sm-12 col-xl-7 col-lg-12">
                {data && data.tagName && data.tagName?.length > 0 && (
                  <div className="choose-tags mb-2">
                    <ul>
                      {data.tagName.map((tag, index) => (
                        <li key={tag.id} className="custom-checkbox">
                          <input
                            disabled
                            type="checkbox"
                            id={`option${index}`}
                          />
                          <label htmlFor={`option${index}`}>
                            {tag.tag_name}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <div class="col-sm-9">
                  <div className="creator-detail-right creator-product">
                    <h2 className="abouts"> About class and Creator</h2>
                    <div class="choose-tags m-0">
                      <ul>
                        <li class="custom-checkbox mb-0">
                          <input disabled="" type="checkbox" id="option0" />
                          <label for="option0">Accessorise</label>
                        </li>
                        <li class="custom-checkbox mb-0">
                          <input disabled="" type="checkbox" id="option1" />
                          <label for="option1">Jewellery</label>
                        </li>
                      </ul>
                    </div>
                    {/* <ul className='product-data-tags'>
                      {data?.offerService?.tags && data?.offerService?.tags.length > 0 && data?.offerService?.tags.map((y, i) => (
                        <li className={i === 0 ? "active" : ''} key={i}>
                          {y?.tag_name}
                        </li>
                      ))}
                    </ul> */}
                    <div className="col-sm-7 col-xl-7 col-lg-7 creator-order mb-0">
                      {/* <h2>Creator</h2> */}

                      <div class="annete res-creator-cs">
                        <span>
                          <img
                            src={
                              data?.offerService?.user?.user_profile_image ||
                              dummyImage
                            }
                            alt=""
                          />
                        </span>
                        <div class="annete-text create-dt-cs pb-0">
                          <h2 className="fw-bold">
                            {data?.offerService?.user?.creator_name || ""}
                          </h2>
                          <h4>
                            {data?.offerService?.user?.specializations[0]
                              ?.area_of_work || ""}
                          </h4>
                          <p className="d-block">
                            {data?.offerService?.user?.specializations[0]
                              ?.experience || ""}{" "}
                            of experience
                          </p>
                        </div>
                      </div>
                    </div>
                    <p>
                      {isExpanded || !isLongDescription
                        ? description
                        : truncatedDescription}
                      <br />
                      {isLongDescription && (
                        <strong
                          onClick={toggleText}
                          style={{ cursor: "pointer" }}
                        >
                          {isExpanded ? "Read less" : "Read more"}
                        </strong>
                      )}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-10 col-sm-10">
                    <div class="main-image">
                      <img
                        src={data?.offerService?.cover_image || cardJewellery}
                        alt=""
                        class="img-fluid "
                      />
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-2">
                    <div
                      className={
                        data?.offerService?.offerMaterials?.length < 3
                          ? "jewellery-img-height"
                          : "jewellery-img-height image"
                      }
                    >
                      <div className="row jw-cs">
                        {data?.offerService?.offerMaterials &&
                        data.offerService?.offerMaterials?.length > 0 ? (
                          data.offerService?.offerMaterials
                            .filter(
                              (item) =>
                                item.media_type === "image" ||
                                item.media_type === "video"
                            ) // Filter for images and videos
                            .map((item, index) => (
                              <div
                                className="col-6 col-lg-12 col-sm-12"
                                key={index}
                              >
                                <div className="jewellery-img">
                                  <img
                                    src={item.file_url}
                                    alt={`additional-image-${index}`}
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                            ))
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xl-5 col-sm-5">
                <div class="offering-top-right">
                  <h2>{data?.offerService?.title || "Morning Yoga"}</h2>
                  <div class="offering-share">
                    <ul>
                      <li>
                        <a href="">
                          <img src={share} alt="" />
                        </a>
                      </li>
                      <li className="ps-2">
                        <a href="">
                          <img src={heart} alt="" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="week">
                    <ul>
                      <li>
                        <img src={clock} alt="" />{" "}
                        {data?.offerService?.session_duration} min
                      </li>
                      {data?.offerService?.session_type === "1" &&
                        data?.offerService?.session_offer_type == 1 && (
                          <li>
                            <img src={session} alt="" /> 1 session
                          </li>
                        )}
                      {data?.offerService?.session_type === "1" &&
                        data?.offerService?.session_offer_type == 2 && (
                          <li>
                            <img src={calendar} alt="" />{" "}
                            {data?.offerService?.no_of_sessions}x per{" "}
                            {data?.offerService?.package_period},{" "}
                            {data?.offerService?.package_timeframe}{" "}
                            {data?.offerService?.package_period}
                          </li>
                        )}
                    </ul>
                  </div>
                  <div class="week">
                    <ul>
                      <li>
                        <img src={people} alt="" />{" "}
                        {data?.offerService?.session_type === "1" &&
                        data?.offerService?.session_offer_type == 1 ? (
                          <p>Private Session</p>
                        ) : data?.offerService?.session_type === "1" &&
                          data?.offerService?.session_offer_type == 2 ? (
                          <p>Private Multiple sessions</p>
                        ) : data?.offerService?.session_type === "2" &&
                          data?.offerService?.session_offer_type == 1 ? (
                          <p>
                            {data?.offerService?.max_group_size -
                              data?.offerService?.seats || "0"}{" "}
                            booked, {data?.offerService?.max_group_size} max{" "}
                          </p>
                        ) : // <p>{data?.offerService?.booked || '-'} booked, {data?.offerService?.max_group_size} max </p>
                        data?.offerService?.session_type === "2" &&
                          data?.offerService?.session_offer_type == 2 ? (
                          <p>
                            {data?.offerService?.max_group_size -
                              data?.offerService?.seats || "0"}{" "}
                            booked, {data?.offerService?.max_group_size} max{" "}
                          </p>
                        ) : null}
                      </li>

                      {data?.offerService?.userData?.address != [] &&
                        data?.offerService?.userData?.address_id != null && (
                          <li>
                            <img src={mapPin} alt="" />{" "}
                            {data?.offerService?.userData?.address &&
                              data?.offerService?.userData?.address_id !=
                                null &&
                              data?.offerService?.userData?.address[0].address +
                                " " +
                                data?.offerService?.userData?.address[0].state +
                                " " +
                                data?.offerService?.userData?.address[0].city +
                                " " +
                                data?.offerService?.userData?.address[0]
                                  .country}
                          </li>
                        )}
                      {/* <li><img src={mapPin} alt='' /> Broome St, New York</li> */}
                      {/* <li><img src={checkTravel} alt='' /> Creator can travel</li> */}
                    </ul>
                  </div>
                  {(data?.offerService?.location_type !== "1" ||
                    data?.offerService?.travel_area) && (
                    <div className="week">
                      <ul>
                        {/* <li><img src={people} alt='' /> {data?.offerService?.session_type === '1' ? 'Individual Session' : 'Group Session'}</li> */}
                        {/* <li>{data?.offerService?.location_type !== '1' && <><img src={mapPin} alt=''/> {data?.offerService?.address?.address + ' '+ data?.offerService?.address?.city} </> }</li> */}
                        <li>
                          {data?.offerService?.travel_area != "" &&
                            data?.offerService?.travel_area != null && (
                              <>
                                {" "}
                                <img src={checkTravel} alt="" /> Creator can
                                travel
                              </>
                            )}
                        </li>
                      </ul>
                    </div>
                  )}
                  <div class="offering-right-bottom">
                    {/* <h4 className='pb-0 mb-0'>Price <strong>${data?.offerService?.price || data?.offerService?.minimum_price || data?.offerService?.maximum_price}</strong></h4> */}

                    {data?.offerService?.pricing_type === "1" ? (
                      <h4>
                        Price{" "}
                        <strong>
                          $
                          {data?.offerService?.price ||
                            data?.offerService?.minimum_price ||
                            data?.offerService?.maximum_price}
                        </strong>
                      </h4>
                    ) : (
                      <h4>
                        Price{" "}
                        <strong>
                          ${data?.offerService?.minimum_price}-$
                          {data?.offerService?.maximum_price}
                        </strong>
                      </h4>
                    )}
                    {/* {!showHideComp && (
                                        <button onClick={() => setIsModal(true)}>Select time slots</button>
                                    )} */}
                  </div>
                </div>
              </div>
              </div>
              <div className="row about-data">
                <div className="col-sm-12 mt-4">
                  <div className="border-set"></div>
                </div>
              <div className="col-sm-12 col-xl-8 col-lg-12 creator-order mt-4">
                <h2>About User</h2>

                <div class="annete res-creator-cs d-flex">
                  <div className="d-ff-first">
                  <span className="me-0">
                    <img
                      src={data?.user?.user_profile_image || dummyImage}
                      alt=""
                    />
                  </span>
                  <div class="creator-soial w-auto text-start mt-3">
                      <ul>
                        <li>
                          <Link
                            to={
                              data?.user?.socials &&
                              data?.user?.socials[0]?.facebook_link
                                ? data?.user?.socials[0]?.facebook_link
                                : ""
                            }
                          >
                            <img
                              src={
                                data?.user?.socials &&
                                data?.user?.socials[0]?.facebook_link
                                  ? facebook
                                  : facebookgrey
                              }
                            />
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={
                              data?.user?.socials &&
                              data?.user?.socials[0]?.twitter_link
                                ? data?.user?.socials[0]?.twitter_link
                                : ""
                            }
                          >
                            <img
                              src={
                                data?.user?.socials &&
                                data?.user?.socials[0]?.twitter_link
                                  ? twitter
                                  : twittergrey
                              }
                            />
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={
                              data?.user?.socials &&
                              data?.user?.socials[0]?.instagram_link
                                ? data?.user?.socials[0]?.instagram_link
                                : ""
                            }
                          >
                            <img
                              src={
                                data?.user?.socials &&
                                data?.user?.socials[0]?.instagram_link
                                  ? instagram
                                  : instagramgrey
                              }
                            />
                          </Link>
                        </li>
                      </ul>
                    </div>
                    </div>
                  <div class="annete-text create-dt-cs d-ff-second">
                    <h2 className="fw-bold">{data?.user?.user_name || ""}</h2>
                    <h4>User</h4>
                    <h3>About</h3>
                    {/* <p>{data?.user?.about}</p> */}
                    <p>
                      {isExpandedUser || !isLongDescriptionUser
                        ? descriptionUser
                        : truncatedDescriptionUser}
                      <br />
                      {isLongDescriptionUser && (
                        <strong
                          onClick={toggleTextUser}
                          style={{ cursor: "pointer" }}
                        >
                          {isExpandedUser ? "Read less" : "Read more"}
                        </strong>
                      )}
                    </p>
                    <h3>Interests</h3>
                    <ul className="product-data-tags">
                      {data?.user?.userInterests &&
                        data?.user?.userInterests.length > 0 &&
                        data?.user?.userInterests.map((y, i) => {
                          return (
                            <li className={i === 0 ? "active" : ""} key={i}>
                              {y?.tag?.tag_name}
                            </li>
                          );
                        })}
                    </ul>
               
                  </div>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SingleBooking;
