import React, { useEffect, useState } from 'react'
import productImg from '../../assets/images/product-img.png'
import wellness from '../../assets/images/wellness.svg'
import heart from '../../assets/images/heart.svg'
import Ellipse from '../../assets/images/Ellipse 13.svg'
import { getApi } from '../../Services/apiService'
import dummy from '../../assets/images/dummy-profile.jpg'
import { useNavigate } from 'react-router-dom'

function ServiceOffering({id}) {

  const [page,setPage]= useState(1)
  const [pageSize,setPageSize]= useState(6)
  const [userData,setUserData] = useState([])
  const [totalRecords,setTotalRecords] = useState(0)
  const navigate = useNavigate()
  
  useEffect(()=>{
    handleGetData()
  },[page,pageSize])

  const handleGetData = async() =>{
    const response = await getApi(`creator/offer?userId=${id}&page=${page}&pageSize=${pageSize}`);
    if(response?.statusCode === 200){
      setUserData(response?.data)
      setTotalRecords(response?.totalCount)
    } else{

    }
  }

  const handleMoreData=()=>{
    // setPage(page+1)
    setPageSize(pageSize+6)
  }

  return (
    <>
    <section class="product product-home-page">
       {/* <div className='container'> */}
          <div class="row">
{userData && userData?.length>0 && userData.map((x,index)=>{
  return(
            <div class="col-sm-4" onClick={() => navigate(`/user/offering/${x?.id}`)} key={index}>
              <div class="product-box">
                <div class="product-img">
                  <img alt='' src={x?.cover_image} />
                  <div class="product-image-data">
                    <span><img alt='' src={x?.category?.category_image} /></span>
            <h5>
              {x?.service_type === "2"
                ? (x?.product_kind === "0" ? 'Physical Product' : 'Digital Product')
                : (x?.service_type === "1"
                  ? (x?.location_type === "1" ? 'Virtual'
                    : (x?.location_type === "2" ? 'In Person'
                      // : (x?.location_type === "2" ? 'Offline'
                      : (x?.location_type === "3" ? 'Virtual/In person' : '')))
                  : 'In Person')}
              {/* : 'Offline')} */}
            </h5>
                    <button><img alt='' src={heart} /></button>
                  </div>
                </div>
                <div class="product-data">
                  <h3>{x?.name} </h3>
                  <ul>
                    {x?.tags && x?.tags?.length>0 && x?.tags?.map((y,ind)=>{
                      return(
                        <li key={ind}>{y?.tag_name}</li>
                      )
                    })}
                    {/* <li class="active">Workshop</li>
                    <li>Fitness</li>
                    <li>Yoga</li> */}
                  </ul>
                  <p>{x?.description}</p>
                  <div class="product-data-bottom">
                    <span><img alt='' src={x?.user?.user_profile_image ||dummy} /></span>
                    <h6><strong>{x?.user?.name}</strong> {x?.user?.specializations && x?.user?.specializations.length>0 && x?.user?.specializations[0]?.area_of_work}</h6>
                  </div>
                </div>
              </div>
            </div>
  )
            })}
            {/* <div class="col-sm-4">
              <div class="product-box">
                <div class="product-img">
                  <img alt='' src={productImg} />
                  <div class="product-image-data">
                    <span><img alt='' src={wellness} /></span>
                    <h5>Offline</h5>
                    <button><img alt='' src={heart} /></button>
                  </div>
                </div>
                <div class="product-data">
                  <h3>Morning Yoga </h3>
                  <ul>
                    <li class="active">Workshop</li>
                    <li>Fitness</li>
                    <li>Yoga</li>
                  </ul>
                  <p>Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel
                    facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing
                    elit semper dalaracc lacus vel facilisis volutpat est velitolm. Lorem ipsum dolor
                    sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat
                    est velitolm.</p>
                  <div class="product-data-bottom">
                    <span><img alt='' src={Ellipse} /></span>
                    <h6><strong>Annete Black</strong> Yoga instructor</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="product-box">
                <div class="product-img">
                  <img alt='' src={productImg} />
                  <div class="product-image-data">
                    <span><img alt='' src={wellness} /></span>
                    <h5>Offline</h5>
                    <button><img alt='' src={heart} /></button>
                  </div>
                </div>
                <div class="product-data">
                  <h3>Morning Yoga </h3>
                  <ul>
                    <li class="active">Workshop</li>
                    <li>Fitness</li>
                    <li>Yoga</li>
                  </ul>
                  <p>Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel
                    facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing
                    elit semper dalaracc lacus vel facilisis volutpat est velitolm. Lorem ipsum dolor
                    sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat
                    est velitolm.</p>
                  <div class="product-data-bottom">
                    <span><img alt='' src={Ellipse} /></span>
                    <h6><strong>Annete Black</strong> Yoga instructor</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="product-box">
                <div class="product-img">
                  <img alt='' src={productImg} />
                  <div class="product-image-data">
                    <span><img alt='' src={wellness} /></span>
                    <h5>Offline</h5>
                    <button><img alt='' src={heart} /></button>
                  </div>
                </div>
                <div class="product-data">
                  <h3>Morning Yoga </h3>
                  <ul>
                    <li class="active">Workshop</li>
                    <li>Fitness</li>
                    <li>Yoga</li>
                  </ul>
                  <p>Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel
                    facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing
                    elit semper dalaracc lacus vel facilisis volutpat est velitolm. Lorem ipsum dolor
                    sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat
                    est velitolm.</p>
                  <div class="product-data-bottom">
                    <span><img alt='' src={Ellipse} /></span>
                    <h6><strong>Annete Black</strong> Yoga instructor</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="product-box">
                <div class="product-img">
                  <img alt='' src={productImg} />
                  <div class="product-image-data">
                    <span><img alt='' src={wellness} /></span>
                    <h5>Offline</h5>
                    <button><img alt='' src={heart} /></button>
                  </div>
                </div>
                <div class="product-data">
                  <h3>Morning Yoga </h3>
                  <ul>
                    <li class="active">Workshop</li>
                    <li>Fitness</li>
                    <li>Yoga</li>
                  </ul>
                  <p>Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel
                    facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing
                    elit semper dalaracc lacus vel facilisis volutpat est velitolm. Lorem ipsum dolor
                    sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat
                    est velitolm.</p>
                  <div class="product-data-bottom">
                    <span><img alt='' src={Ellipse} /></span>
                    <h6><strong>Annete Black</strong> Yoga instructor</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="product-box">
                <div class="product-img">
                  <img alt='' src={productImg} />
                  <div class="product-image-data">
                    <span><img alt='' src={wellness} /></span>
                    <h5>Offline</h5>
                    <button><img alt='' src={heart} /></button>
                  </div>
                </div>
                <div class="product-data">
                  <h3>Morning Yoga </h3>
                  <ul>
                    <li class="active">Workshop</li>
                    <li>Fitness</li>
                    <li>Yoga</li>
                  </ul>
                  <p>Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel
                    facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing
                    elit semper dalaracc lacus vel facilisis volutpat est velitolm. Lorem ipsum dolor
                    sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat
                    est velitolm.</p>
                  <div class="product-data-bottom">
                    <span><img alt='' src={Ellipse} /></span>
                    <h6><strong>Annete Black</strong> Yoga instructor</h6>
                  </div>
                </div>
              </div>
            </div> */}
          </div>

          {totalRecords>pageSize &&<button class="explore" onClick={()=>handleMoreData()}>Explore more offerings</button>}

          {/* </div> */}
      </section>
    </>
  )
}

export default ServiceOffering