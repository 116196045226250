import React from 'react'

import Modal from 'react-modal';
import cross from '../../../assets/images/cross-button.svg'
import { useNavigate } from 'react-router-dom';
import NewAddress from './newAddress';
import NewTravelArea from './newTravelArea';

function ModalOfferingPlace({ offeringType,closeModal,modalOpen,type,getAddress,setAddressForm,setAddressState,addressState,setClassHappen,classHappen,classMode,data,handleGetAddress }) {
	const navigate = useNavigate()

  return (
    <>
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => closeModal()}
        contentLabel="Availbility Modal"
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
        portalClassName={`address-modal address-modal-diff-cs ${getAddress?.length>0 ? '':'address-shows'}`}
      >
      <NewAddress closeModal={closeModal} getAddress={getAddress} setAddressForm={setAddressForm} setAddressState={setAddressState} addressState={addressState} setClassHappen={setClassHappen} classHappen={classHappen} classMode={classMode} data={data} handleGetAddress={handleGetAddress}/>
       {/* <button className='close-button' onClick={()=>closeModal()}><img src={cross} alt='' /></button> */}
        {/* {offeringType === 'place' && <NewAddress closeModal={closeModal} getAddress={getAddress} setAddressForm={setAddressForm}/>}
        {offeringType === 'travel' && <NewTravelArea closeModal={closeModal} getAddress={getAddress} setAddressForm={setAddressForm}/>}
        {offeringType === 'both' && <NewAddress closeModal={closeModal} getAddress={getAddress} setAddressForm={setAddressForm}/>} */}

      </Modal>
      {/* {offeringType === 'Daily' && modalOpen && navigate('/creator/offering/daily')} */}
    </>

  )
}

export default ModalOfferingPlace