// import logo from './logo.svg';
import './App.css';
import './Admin.css';
import Routing from './Routing';
import { GoogleOAuthProvider } from '@react-oauth/google';
import React, { useState, useEffect } from 'react';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { messaging } from './Component/FirebaseConfig/firebase';
import Modal from 'react-modal';
import PushNotification from './Component/AuthComponent/notification';
import cross from '../src/assets/images/cross-button.svg';
import { Elements } from '@stripe/react-stripe-js';
import { stripePromise } from './Config/stripeService';

const clientId = '319571023204-dr9kca64rv9addd686gpghfip8754o3a.apps.googleusercontent.com';

function App() {
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [notificationData, setNotificationData] = useState(null);

  const closeNotificationModal = () => {
    setNotificationModalOpen(false);
  };

  useEffect(() => {
    // Register the Firebase messaging service worker only if HTTPS is used
    if ('serviceWorker' in navigator && window.location.protocol === 'https:') {
      navigator.serviceWorker
        .register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`)
        .then((registration) => {
          console.log("Service Worker registered with scope:", registration.scope);
        })
        .catch((error) => {
          console.error("Service Worker registration failed:", error);
        });
    } else {
      console.warn("Service Worker not registered because the app is not running on HTTPS.");
    }

    // Attempt to initialize Firebase Messaging and listen for incoming messages
    try {
      const messagingInstance = getMessaging();

      // Listen for incoming messages
      const unsubscribe = onMessage(messagingInstance, (payload) => {
        console.log('Message received: ', payload);
        if (payload?.notification) {
          setNotificationData(payload.notification);
          setNotificationModalOpen(true);
          setTimeout(() => {
            setNotificationModalOpen(false);
          }, 3000);
        }
      });

      // Clean up the listener on component unmount
      return () => unsubscribe();

    } catch (error) {
      console.error("Error initializing Firebase messaging:", error);
    }
  }, []); // Empty dependency array ensures this effect only runs once

  return (
    <div>
      <GoogleOAuthProvider clientId={clientId}>
      <Elements stripe={stripePromise}>
        <Routing />
        </Elements>
      </GoogleOAuthProvider>
      
      <Modal
        isOpen={notificationModalOpen}
        onRequestClose={closeNotificationModal}
        contentLabel="Notification Modal"
        portalClassName="notify-section"
        ariaHideApp={false}
      >
        <button className='close-button' onClick={closeNotificationModal}>
          <img src={cross} alt='Close' />
        </button>
        
        {/* Pass notification data to PushNotification component */}
        <PushNotification notification={notificationData} />
      </Modal>
    </div>
  );
}

export default App;
