import React, { useEffect, useState, useCallback } from 'react';
import UserSidebar from '../../../../Layout/userSidebar';
import burger from '../../../../assets/images/burger-menu.svg'
import ActiveLinks from '../activeLinks';
import leftArrow from '../../../../assets/images/backarrow.svg'
import { useNavigate } from 'react-router-dom';
import confirmIcon from '../../../../assets/images/confirm-Icon.svg'
import dollar from '../../../../assets/images/dollar.svg'
import cross from '../../../../assets/images/videocros.svg'
import { toast } from 'react-toastify'
import addTag from '../../../../assets/images/add-tag.svg'
import ViewTag from '../viewTag';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux'
import { getApi } from '../../../../Services/apiService'
import choosefile from '../../../../assets/images/chose-files.svg'
import { insertProductData } from '../../../../Counter/productSlice';


function DefineProductOffering({ nextStep, step, submitForm, data, setDraftData,typess }) {
  console.log("datadatadatadatadatadatadatadatadatadata", data);
  const dispatch = useDispatch();
  const [classState, setClassState] = useState(false);
  const [discoveryPrice, setDiscoveryPrice] = useState(data?.discoveryPrice || 'fixed_price')
  const [discoverManPrice, setDiscoveryManPrice] = useState(data?.discoverManPrice || null)
  const [messageUser, setMessageUser] = useState(data?.messageUser || '')
  const [discoverMinPrice, setDiscoveryMinPrice] = useState(data?.discoverMinPrice || null)
  const [imagePreview, setImagePreview] = useState(data?.imagePreview || data?.showImagePreview || '');
  const [showImagePreview, setShowImagePreview] = useState(data?.showImagePreview || '');
  const [errors, setErrors] = useState({ productType: '', availability: '', additionalImages: '', quantity: '', title: '', discoveryMinPrice: '', discoveryManPrice: '', });
  const [additionalImageType, setAdditionalImageType] = useState('image')
  const [additionalImagesPreview, setAdditionalImagesPreview] = useState(data?.productMaterial || data?.offerMaterials || [])
  const [additionalImages, setAdditionalImages] = useState(data?.productMaterial || data?.additionalImages || data?.offerMaterials || [])
  const [tagName, setTagName] = useState(data?.tagName || []);
  const [tagModal, setTagModal] = useState(false);
  const [creatorTag, setCreatorTag] = useState(data?.tags || data?.tagName || '')
  const [tagData, setTagData] = useState([]);
  const [nameTag, setNameTag] = useState(data?.tagName || []);
  const [productType, setProductType] = useState(data?.productType || 'physical'); // Add state for product type
  const [availability, setAvailability] = useState(data?.availability || 'ready'); // Add state for availability status
  const [mediaError, setMediaError] = useState('');
  const [readyPublish, setReadyPublish] = useState(false)
  const [quantityOrders, setQuantityOrders] = useState(data?.quantityOrders || '')
  const [title, setTitle] = useState(data?.title || '')
  const [Maxquantity, setMaxquantity] = useState(data?.Maxquantity || data?.maximum_quantity_order || '')
  const [processing_time, setprocessing_time] = useState(data?.processing_time || '')
  const [additional_unit, setadditional_unit] = useState(data?.additional_unit || data?.additionalProcessingTime || '')
  const [description, setDescription] = useState(data?.description || '')
  const [link, setLink] = useState(data?.link || data?.digitalLink || '')
  const [digital_file, setDigital_file] = useState(data?.digital_file || [])
  const [error, setError] = useState('');
  const [loader, setLoader] = useState(false)
  const [removedImageIds, setRemovedImageIds] = useState([]);

  // let filteredB = [];
  // if (tagName.length > 0 && tagData.length > 0) {
  //   filteredB = tagData.filter(bItem => !creatorTag.some(aItem => aItem === bItem.id));
  // }
  // let matchingTags = []
  // if (creatorTag.length > 0) {
  //   matchingTags = (tagData.filter(tag => creatorTag.includes(tag?.id)));
  //   // matchingTags = tagData.filter(tag => 
  //   //   creatorTag.some(creatorTagItem => creatorTagItem.id === tag.id)
  //   // );
  //   console.log('this is tagData', tagData);
  //   console.log('this is creator tag', creatorTag);
  // }

  // console.log('matching tags', matchingTags);
  // console.log('filteredBfilteredB', filteredB);
  // console.log('tagNametagNametagName', tagName);
  // useEffect(() => {
  //   if (creatorTag.length > 0) {
  //     // matchingTags = []
  //     const matchingTagss = tagData.filter(tag => creatorTag.includes(tag.id));
  //     // matchingTags = matchingTagss
  //     setTagName(matchingTagss);
  //   }
  // }, [creatorTag, tagData])
  let filteredB =[];
  if(tagName.length>0 && tagData.length>0){
    //  filteredB = tagData.filter(item => !tagName.includes(item.tag_name));
     filteredB = tagData.filter(bItem => !creatorTag.some(aItem => aItem === bItem.id));
    //  filteredB = tagData.filter(bItem => !tagName.some(aItem => aItem.name === bItem.tag_name));
  }
  let matchingTags =[]
  if(creatorTag.length>0){
    matchingTags = tagData.filter(tag => creatorTag.includes(tag.id));
    // setTagName(matchingTags)
  }
  console.log("matchingTagsmatchingTags",matchingTags);
  
  useEffect(() => {
    if (creatorTag.length > 0) {
      const matchingTagss = tagData.filter(tag => creatorTag.includes(tag.id));
      setTagName(matchingTagss);
    }
  }, [creatorTag, tagData])
  const navigate = useNavigate()
  const handleSidebarClick = (event) => {
    event.stopPropagation();
    setClassState(!classState);
  };

  useEffect(() => {
    const handleClick = () => setClassState(false);
    document.addEventListener('click', handleClick);
    handleTag()
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  useEffect(()=>{
    if(!tagModal){
    handleTag()
    }
  },[tagModal])

  const backNav = () => {
    navigate('/creator/offering')
  }

  const closeModal = () => {
    setTagModal(false)
  }

  const handleVideoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type.startsWith('video/')) {
        setAdditionalImagesPreview((prevState) => [...prevState, file]); // Keep the file format
        const reader = new FileReader();
        reader.onloadend = () => {
          setAdditionalImages((prevState) => [...prevState, reader.result]); // Store the base64 preview
        };
        reader.readAsDataURL(file);
      } else {
        toast.error('Please upload a video file');
      }
    }
  };
  // const handleRemoveMedia = (indexToRemove) => {
  //   setAdditionalImages(prevState =>
  //     prevState.filter((_, index) => index !== indexToRemove)
  //   );
    
  //   setAdditionalImagesPreview(prevState =>
  //     prevState.filter((_, index) => index !== indexToRemove)
  //   );
  // };
  const handleRemoveMedia = (indexToRemove) => {
    const removedImage = additionalImages[indexToRemove];
    
    // Save the ID of the removed image, if it exists
    if (removedImage && removedImage.id) {
      setRemovedImageIds(prevIds => [...prevIds, removedImage.id]);
    }

    // Remove the image from additionalImages
    setAdditionalImages(prevState =>
      prevState.filter((_, index) => index !== indexToRemove)
    );
  };

  console.log('this is id of removed image',removedImageIds );



  const handleRemoveFile = (indexToRemove) => {
    setDigital_file(prevState =>
      prevState.filter((_, index) => index !== indexToRemove)
    );
  }
  const handleTag = async () => {
    // const response = await getApi('/creator/tag')
    const response = await getApi('/interest')
    if (response?.statusCode === 200) {
      // setTagData(response?.data?.tagData)
      setTagData(response?.data)
    }
    // const response = await getApi('/creator/product/tag')
  }

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type.startsWith('image/')) {
        setAdditionalImagesPreview((prevState) => [...prevState, file]); // Keep the file format
        const reader = new FileReader();
        reader.onloadend = () => {
          setAdditionalImages((prevState) => [...prevState, reader.result]); // Store the base64 preview
        };
        reader.readAsDataURL(file);
      } else {
        toast.error('Please upload an image file');
      }
    }
  };

  const handleFileChange = (e, type) => {
    // setUpdateProfileData(true)
    const file = e.target.files[0];
    if (file) {
      if (type === 'cover') {
        setImagePreview(file)
      } else {
        setAdditionalImagesPreview(prevState => [...prevState, file])
      }
      // setNewCategory(prevState => ({ ...prevState, image: file }));
      const reader = new FileReader();

      reader.onloadend = () => {
        if (type === 'cover') {
          setShowImagePreview(reader.result);
        } else {
          setAdditionalImages(prevState => [...prevState, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileChangeFile = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const validFiles = [];
    let errorMessages = [];

    selectedFiles.forEach((file) => {
      if (allowedTypes.includes(file.type)) {
        validFiles.push(file);
      } else {
        errorMessages.push(`${file.name} is not a supported file type.`);
      }
    });

    if (errorMessages.length) {
      setError(errorMessages.join(' '));
    } else {
      setError('');
    }

    setDigital_file((prevFiles) => [...prevFiles, ...validFiles]);
  };

  const handleRemoveDigital = (fileName) => {
    setDigital_file(digital_file.filter(file => file.name !== fileName));
  };
  const handleTagData = (newTag, tagname) => {
    setCreatorTag((prevTagData) => {
      if (prevTagData.includes(newTag)) {
        return prevTagData.filter(tag => tag !== newTag);
      } else {
        return [...prevTagData, newTag];
      }
    });
    setTagName((prevTagData) => {
      if (prevTagData.some(tag => tag.tag_name === tagname)) {
        return prevTagData.filter(tag => tag.tag_name !== tagname);
      } else {
        return [...prevTagData, { ids: newTag, name: tagname }];
      }
    });
    // setTagNamesetNameTag
    setNameTag((prevTagName) => {
      if (prevTagName.includes(tagname)) {
        return prevTagName.filter(tag => tag !== tagname);
      } else {
        return [...prevTagName, tagname];
      }
    });
  }

  const validateForm = () => {
    let errors = {};


    // Validation rules
    if (!productType) {
      errors.productType = 'Please select a product type.';
    }

    if (!availability) {
      errors.availability = 'Please select the availability status.';
    }

    if (productType === 'physical') {
      if (availability === 'ready') {
        if (!quantityOrders) {
          errors.quantity = 'Please enter the quantity.';
        }
      }
      else {
        if (!Maxquantity) {
          errors.Maxquantity = 'Please enter the max quantity.';
        } else if (!processing_time) {
          errors.processing_time = 'Please enter the procesing time.';
        }
      }
    } else {

    }



    if (productType === 'digital') {
      if (link.trim().length !== link.length) { // Check for spaces in the link
        errors.link = 'Link cannot contain spaces';
      } 
      else if (link && !/^https?:\/\/\S+$/.test(link)) { // Check only if link is not empty
        errors.link = 'Invalid link format';
      }
    }
    
    if (!title) {
      errors.title = 'Please enter a title.';
    } else if (title.trim() === '') {
    // } else if (title.trim().length !== title.length) {
      // else if (/\s/.test(name)) { // Check for spaces in the name
      errors.title = 'Title cannot contain spaces';
    }

    if (!description) {
      errors.description = 'Please enter description';
    // } else if (description.trim().length !== description.length) { // Check for spaces in the name
    } 
    // else if (description.trim() !== description) { // Check for spaces in the name
    //   // else if (/\s/.test(name)) { // Check for spaces in the name
    //   errors.description = 'Description cannot contain spaces';
    // }

    if (matchingTags.length === 0) {
      errors.matchingTags = 'Please select atleast one tag.';
    }
    if (imagePreview === '') {
      errors.coverImage = 'Please upload an image.';
    }
    if (discoveryPrice === 'fixed_price') {
      if (discoverMinPrice === null || discoverMinPrice === '') {
        errors.discoveryMinPrice = 'Please enter a price.';
      }
    }else if (discoveryPrice === 'variable_price') {
      if (discoverMinPrice === null || discoverMinPrice === '') {
        errors.discoveryMinPrice = 'Please enter a minimum price.';
      }
      if (discoverManPrice === null || discoverManPrice === '') {
        errors.discoveryManPrice = 'Please enter a maximum price.';
      }
      
      // Check if discoverMinPrice is less than discoverManPrice
      if (discoverMinPrice !== null && discoverManPrice !== null && discoverMinPrice !== '' && discoverManPrice !== '') {
        if (parseFloat(discoverMinPrice) >= parseFloat(discoverManPrice)) {
          errors.discoveryManPrice = 'Maximum price should be greater than the minimum price.';
        }
      }
    }
    

    setErrors(errors);
    return Object.keys(errors).length === 0;
    // Clear media error if validation passes
    // setMediaError('');
  }
  const allowedTypes = [
    'image/jpeg',
    'image/png',
    'video/mp4',
    'video/avi',
    'audio/mp3',
  ];
  // const updateReduxData = (key, value) => {
  //   const updatedValues = { [key]: value };
  //   dispatch(insertProductData(updatedValues));
  // };
  
  // useEffect(() => {typess === 'edit' && updateReduxData('productType', productType)}, [discoveryPrice]);
  // useEffect(() => {typess === 'edit' && updateReduxData('availability', availability)}, [availability]);
  // useEffect(() => {typess === 'edit' && updateReduxData('discoveryPrice', discoveryPrice)}, [discoveryPrice]);
  // useEffect(() => {typess === 'edit' && updateReduxData('discoverMinPrice', discoverMinPrice)}, [discoverMinPrice]);
  // useEffect(() => {typess === 'edit' && updateReduxData('discoverManPrice', discoverManPrice)}, [discoverManPrice]);
  // useEffect(() => {typess === 'edit' && updateReduxData('messageUser', messageUser)}, [messageUser]);
  // useEffect(() => {typess === 'edit' && updateReduxData('additionalImagesPreview', additionalImagesPreview)}, [additionalImagesPreview]);
  // useEffect(() => {typess === 'edit' && updateReduxData('tagName', matchingTags)}, [matchingTags]);
  // useEffect(() => {typess === 'edit' && updateReduxData('creatorTag', matchingTags)}, [matchingTags]);
  // useEffect(() => {typess === 'edit' && updateReduxData('tags', creatorTag)}, [creatorTag]);
  // useEffect(() => {typess === 'edit' && updateReduxData('imagePreview', imagePreview)}, [imagePreview]);
  // useEffect(() => {typess === 'edit' && updateReduxData('quantityOrders', quantityOrders)}, [quantityOrders]);
  // useEffect(() => {typess === 'edit' && updateReduxData('title', title)}, [title]);
  // useEffect(() => {typess === 'edit' && updateReduxData('showImagePreview', showImagePreview)}, [showImagePreview]);
  // useEffect(() => {typess === 'edit' && updateReduxData('additionalImages', additionalImages)}, [additionalImages]);
  // useEffect(() => {typess === 'edit' && updateReduxData('Maxquantity', Maxquantity)}, [Maxquantity]);
  // useEffect(() => {typess === 'edit' && updateReduxData('processing_time', processing_time)}, [processing_time]);
  // useEffect(() => {typess === 'edit' && updateReduxData('additional_unit', additional_unit)}, [additional_unit]);
  // useEffect(() => {typess === 'edit' && updateReduxData('description', description)}, [description]);
  // useEffect(() => {typess === 'edit' && updateReduxData('link', link)}, [link]);
  // useEffect(() => {typess === 'edit' && updateReduxData('digital_file', digital_file)}, [digital_file]);
  // useEffect(() => {typess === 'edit' && updateReduxData('deleted_ids', removedImageIds)}, [removedImageIds]);

  const updateReduxData = (updatedValues) => {
    dispatch(insertProductData(updatedValues));
  };

  useEffect(() => {
    if (typess === 'edit') {
      const updatedData = {
        // productType,
        // availability,
        // discoveryPrice,
        // discoverManPrice,
        // messageUser,
        // discoverMinPrice,
        // imagePreview,
        // showImagePreview,
        // additionalImageType,
        // additionalImagesPreview,
        // additionalImages,
        // tagName,
        // creatorTag,
        // tagData,
        // nameTag,
        // quantityOrders,
        // title,
        // Maxquantity,
        // processing_time,
        // additional_unit,
        // description,
        // link,
        // digital_file,
        // removedImageIds,
        productType: productType,
      availability: availability,
      discoveryPrice: discoveryPrice,
      discoverMinPrice: discoverMinPrice,
      discoverManPrice: discoverManPrice,
      messageUser: messageUser,
      additionalImagesPreview: additionalImagesPreview,
      tagName: matchingTags,
      creatorTag: matchingTags,
      tags: creatorTag,
      imagePreview: imagePreview,
      quantityOrders: quantityOrders,
      title: title,
      showImagePreview: showImagePreview,
      additionalImages: additionalImages,
      Maxquantity: Maxquantity,
      processing_time: processing_time,
      additional_unit: additional_unit,
      description: description,
      link: link,
      digital_file: digital_file,
      deleted_ids:removedImageIds
      };

      updateReduxData(updatedData);
    }
  }, [
    typess,
    productType,
    availability,
    discoveryPrice,
    discoverManPrice,
    messageUser,
    discoverMinPrice,
    imagePreview,
    showImagePreview,
    additionalImageType,
    additionalImagesPreview,
    additionalImages,
    tagName,
    creatorTag,
    tagData,
    nameTag,
    quantityOrders,
    title,
    Maxquantity,
    processing_time,
    additional_unit,
    description,
    link,
    digital_file,
    removedImageIds,
  ]);
  
  const handleSave = async (type) => {
    const values = {
      productType: productType,
      availability: availability,
      discoveryPrice: discoveryPrice,
      discoverMinPrice: discoverMinPrice,
      discoverManPrice: discoverManPrice,
      messageUser: messageUser,
      additionalImagesPreview: additionalImagesPreview,
      tagName: matchingTags,
      creatorTag: matchingTags,
      tags: creatorTag,
      imagePreview: imagePreview,
      quantityOrders: quantityOrders,
      title: title,
      showImagePreview: showImagePreview,
      additionalImages: additionalImages,
      Maxquantity: Maxquantity,
      processing_time: processing_time,
      additional_unit: additional_unit,
      description: description,
      link: link,
      digital_file: digital_file,
      deleted_ids:removedImageIds
    }
    // Proceed with saving the data or performing other actions

    if (validateForm()) {
      dispatch(insertProductData(values))
      if (type === 'draft') {
        setLoader(true)
        const resp = await submitForm('draft', values)
        setTimeout(() => {
          setLoader(false)
        }, 500);
      }
      else {

        nextStep()
      }
    }
    else {
    }
  };
  const handleQuantityChange = (e) => {
    const value = e.target.value;
  if ((value >= 1 && value <= 999) || value === '') {
    setQuantityOrders(value);
  }

    const numericValue = Number(value);

    // Validate only when the value is numeric and not 0
    if (value !== '' && numericValue <= 0) {
      setErrors((prev) => ({
        ...prev,
        quantity: '',
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        quantity: '',
      }));
    }
  };


  const handleProductType = (type) => {
    setProductType(type);
    const values = {
      productType: type,
    }
    {typess === 'edit' && updateReduxData('productType', type);}
    dispatch(insertProductData(values))
    if (type === 'digital') {
      nextStep('productType')
    }
  }

  console.log('images additional', additionalImages);
  return (
    <>
      <UserSidebar classState={classState} />
      <div className='right-main product session-spacing-cs'>
        <div class="session">
          <div class="header">
            <h1> <button className="burger-menu" onClick={handleSidebarClick}><img src={burger} alt='' /></button>{typess === 'edit' && 'Edit'} Product</h1>
            <div class="top-buttons">
              {typess === 'edit' ?
              <>
              <button class="save-draft" onClick={() => navigate('/creator/offering/home')} >Cancel</button>
              {/* <Link to='/creator/offering/availability'> */}
              <button class="next" onClick={() => submitForm()} disabled={loader}>{loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Save</button>
              {/* <button class="next" onClick={() => submitForm()} disabled={loader}>{loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}eye</button> */}
              {/* </Link> */}
              </>
              :
              <>
              <button class="save-draft" onClick={() => handleSave('draft')} disabled={loader}>{loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Save to Draft</button>
              {/* <Link to='/creator/offering/availability'> */}
              <button class="next" onClick={() => handleSave()}>Next</button>
              {/* </Link> */}
              </>
            }
            </div>
          </div>
          {typess !== 'edit' &&
          <div class="active-links">
            <button className="back" onClick={() => { backNav() }}><img src={leftArrow} alt='' /> </button>
            <ActiveLinks step={step + 1} type="product" productType={productType} />
          </div>
}
          <div class="main">
            <div class="row">
              <div class="col-sm-8 col-xl-7">
                <div class="left define-offer-cs error-fixs">
                  <h1>Define your offering </h1>
                  {/* Html code starts */}
                  <div class="offer-diffent">
                    <div class="size-range">
                      <h6 className='mb-3'>What Kind Of Product?<img src={confirmIcon} alt='' class="px-2" /></h6>
                      <div className="bottom-buttons">
                        <label className="radio-check3">
                          <input
                            type='radio'
                            id="physical_ck"
                            name="physical_check"
                            checked={productType === 'physical'}
                            onChange={() => {typess !=='edit' && handleProductType('physical')}}
                          />
                          <span className="checkmark">Physical</span>
                        </label>
                        <label className="radio-check3">
                          <input
                            type='radio'
                            id="digital_ck"
                            name="physical_check"
                            checked={productType === 'digital'}
                            onChange={() => {typess !=='edit' && handleProductType('digital')}}
                          // onChange={() => setProductType('digital')}
                          />
                          <span className="checkmark">Digital</span>
                        </label>
                      </div>
                      {errors.productType && <div className="error">{errors.productType}</div>}
                    </div>
                  </div>
                  {/* ---------available your order start------*/}
                  {productType === 'physical' && (
                    <div class=" offer-diffent">
                      <div class="size-range">
                        <h6 className='mb-3'>Is Your Product Available Now Or Made To Order?<img src={confirmIcon} alt='' class="px-2" /></h6>
                        <div className="bottom-buttons">
                          <label className="radio-check3">
                            <input
                              type='radio'
                              id="rady_ck"
                              name="available_order"
                              checked={availability === 'ready'}
                              onChange={() => {setAvailability('ready');setMaxquantity('');setprocessing_time('');setadditional_unit('')}}
                            />
                            <span className="checkmark">Yes, ready for sale</span>
                          </label>
                          <label className="radio-check3">
                            <input
                              type='radio'
                              id="order_ck"
                              name="available_order"
                              checked={availability === 'order'}
                              onChange={() => {setAvailability('order');setQuantityOrders('')}}
                            />
                            <span className="checkmark">Made to order</span>
                          </label>
                        </div>
                        {errors.availability && <div className="error">{errors.availability}</div>}

                      </div>
                      {availability === 'ready' && (
                        <>
                          <div class="form-group mb-0">
                            <label for="Quantity">Quantity of items? *</label>
                            <input type="number" class="form-control" id="quantity" aria-describedby="" placeholder="Type your quantity (1-999)" onChange={handleQuantityChange} value={quantityOrders}></input>
                          </div>
                          {errors.quantity && <div className="error">{errors.quantity}</div>}
                        </>
                      )
                      }
                      {availability === 'order' && (
                        <>
                          <div class="form-group mb-0">
                            <label for="Maxquantity">Maximum quantity per order *</label>
                            <input type="number" class="form-control" id="Maxquantity" aria-describedby="" placeholder="3 item" 
                            onChange={(e) => {
                              if (e?.target?.value >= 1 || e.target.value === '') {
                                setMaxquantity(e?.target?.value);
                              }
                            }}
                              min={1}
                             value={Maxquantity}></input>
                          </div>
                          {errors.Maxquantity && <div className="error">{errors.Maxquantity}</div>}

                          <div class="form-group mb-0">
                            <label for="processing_time">Processing time for 1 item *</label>
                            <input type="number" class="form-control" id="processing_time" aria-describedby="" placeholder="3 days"
                             onChange={(e) => {
                              if (e?.target?.value >= 1 || e.target.value === '') {
                                setprocessing_time(e?.target?.value);
                              }
                            }}
                              min={1}
                             value={processing_time}></input>
                          </div>
                          {errors.processing_time && <div className="error">{errors.processing_time}</div>}

                          <div class="form-group mb-0">
                            <label for="additional_unit">Processing time for additional unit</label>
                            <input type="number" class="form-control" id="additional_unit" aria-describedby="" placeholder="2 days" 
                            onChange={(e) => {
                              if (e?.target?.value >= 1 || e.target.value === '') {
                                setadditional_unit(e?.target?.value);
                              }
                            }}
                              min={1}
                            value={additional_unit}></input>
                          </div>
                          {/* {errors.additional_unit && <div className="error">{errors.additional_unit}</div>} */}

                        </>
                      )
                      }

                    </div>
                  )}

                  {/* ---------available your order  end------*/}
                  {/* ---------Product Details start------*/}
                  <div class=" offer-diffent">
                    <div class="size-range">
                      <h6>Product Details</h6>
                      <div class="form-group">
                        <label for="title">Title *</label>
                        <input type="text" class="form-control" id="title" aria-describedby="" placeholder="My title" onChange={(e) => setTitle(e.target.value)} value={title} ></input>
                        {errors.title && <div className="error">{errors.title}</div>}
                      </div>
                      <div class="choose-tags mt-0">
                        <h6>Choose tags that related to your offering</h6>
                        <ul>
                          {/* {matchingTags.length > 0 ?
                            <>
                              {matchingTags && matchingTags?.length > 0 && matchingTags.map((x, index) => {
                                return (
                                  index < 8 &&
                                  <li class="custom-checkbox" key={index} onClick={() => handleTagData(x?.id, x?.tag_name)}>
                                    <input type="checkbox" checked={true} />
                                    <label for="option1">{x?.tag_name}</label>
                                  </li>
                                )
                              })}
                              {filteredB && filteredB.length > 0 && filteredB.map((x, index) => {
                                return (
                                  index < 8 - matchingTags?.length &&
                                  <li class="custom-checkbox" key={index} onClick={() => handleTagData(x?.id, x?.tag_name)}>
                                    <input type="checkbox" id="" checked={tagName.includes(x)} />
                                    <label for="option1">{x?.tag_name}</label>
                                  </li>
                                )
                              })}
                            </>
                            :
                            <>
                              {tagData && tagData.length > 0 && tagData.map((x, index) => {
                                return (
                                  index < 8 &&
                                  <li class="custom-checkbox" key={index} onClick={() => handleTagData(x?.id, x?.tag_name)}>
                                    <input type="checkbox" id="" checked={creatorTag.includes(x?.id)} />
                                    <label for="option1">{x?.tag_name}</label>
                                  </li>
                                )
                              })}
                            </>
                          } */}
                           {matchingTags.length >0 ? 
                      <>
                      {matchingTags && matchingTags?.length>0 && matchingTags.map((x,index)=>{
                        return (
                          index<8 &&
                            <li class="custom-checkbox" key={index} onClick={() => handleTagData(x?.id, x?.tag_name)}>
                              <input type="checkbox" id="" checked={tagName.includes(x)} />
                              <label for="option1">{x?.tag_name}</label>
                            </li>
                          )
                      })}
                    {filteredB && filteredB.length > 0 && filteredB.map((x, index) => {
                      return (
                      index<8-matchingTags?.length &&
                        <li class="custom-checkbox" key={index} onClick={() => handleTagData(x?.id, x?.tag_name)}>
                          <input type="checkbox" id="" checked={creatorTag.includes(x?.id)} />
                          <label for="option1">{x?.tag_name}</label>
                        </li>
                      )
                    })}
                      </>
                    :
                    <>
                    
                    {tagData && tagData.length > 0 && tagData.map((x, index) => {
                      return (
                      index<8 &&
                        <li class="custom-checkbox" key={index} onClick={() => handleTagData(x?.id, x?.tag_name)}>
                          <input type="checkbox" id="" checked={creatorTag.includes(x?.id)} />
                          <label for="option1">{x?.tag_name}</label>
                        </li>
                      )
                    })}
                    </>
                    }
                          <div class="new-tags" onClick={() => setTagModal(true)}><img src={addTag} alt='' />Add new Tags </div>
                        </ul>
                        {errors.matchingTags && <div className="error">{errors.matchingTags}</div>}
                      </div>
                    </div>

                    {/* ---------available your order  end------*/}

                    {/* all left code here starts */}

                    <section class="first">
                      <h6>Cover image *</h6>
                      <div class={showImagePreview ? "cover-image now-select" :"cover-image"}>
                        {/* <button class="select-image">Select Image</button> */}
                        {showImagePreview && <img src={showImagePreview} alt='' class="browser-image" />}
                        {/* <input type='file' name='coverImage' placeholder='Select Image' onChange={(e) => handleFileChange(e, 'cover')} /> */}
                        <div class="upload_file_container">
                        {showImagePreview ? 'Change Image' :'Select Image'}
                          <input type="file" name="photo" accept=".png,.jpg,.jpeg,.avif,.svg" onChange={(e) => handleFileChange(e, 'cover')} />
                        </div>
                        <p>This image appears in the Microcosm Library, Discover and Profile pages. Your image should be JPG, PNG or GIF format up to 5 MB</p>
                        {errors.coverImage && <div className="error">{errors.coverImage}</div>}
                      </div>
                    </section>
                    <div class={`form-group mb-0 description  ${description && 'dailyinputenter'}`}>
                      <label for="description">Description *</label>
                      <textarea type="text" class="form-control descrpt" id="description" aria-describedby="" placeholder="Add more details about Your offer"
                      //  onChange={(e) => (e?.target?.value >= 1 || e.target.value !== '') && setDescription(e.target.value)} 
                      onChange={(e) => {
                        const textValue = e.target.value;
                        // if (textValue !== '' || textValue.trim() !== '') {
                        //   setDescription(textValue);
                        // }
                        setDescription(textValue.trim() !== '' ? textValue : '');
                      }}
                       value={description} ></textarea>
                      {errors.description && <div className="error">{errors.description}</div>}

                    </div>
                  </div>

                  <div className="section product-section offer-diffent">
                    <div className="size-range toggler-button">
                      <h6 className='mb-3'>Pricing</h6>
                      <div className="bottom-buttons">
                        <label className="radio-check3">
                          <input type="radio" checked={discoveryPrice === 'fixed_price'} name="radio" onClick={() => setDiscoveryPrice('fixed_price')} />
                          <span className="checkmark">Fixed price</span>
                        </label>
                        <label className="radio-check3">
                          <input type="radio" checked={discoveryPrice === 'variable_price'} name="radio" onClick={() => setDiscoveryPrice('variable_price')} />
                          <span className="checkmark">Variable price</span>
                        </label>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="minimum position-relative mt-3">
                            <label htmlFor="minimum">{discoveryPrice === 'fixed_price' ? 'Enter Price *' : 'Minimum *'}</label>
                            <input type="number" id="minimum" placeholder="0.00" className='ps-5' value={discoverMinPrice} onChange={(e) => {
                              if (e?.target?.value >= 1 || e.target.value === '') {
                                setDiscoveryMinPrice(e?.target?.value);
                              }
                            }}
                              min={1}
                            />
                            <img src={dollar} className="input-img" alt='' />
                            {errors.discoveryMinPrice && <div className="error">{errors.discoveryMinPrice}</div>}
                          </div>
                        </div>
                        {discoveryPrice === 'variable_price' &&
                          <div className="col-sm-6">
                            <div className="recommended position-relative my-3">
                              <label htmlFor="recommended">Maximum *</label>
                              <input type="number" id="recommended" placeholder="0.00" className='ps-5' value={discoverManPrice}
                                onChange={(e) => {
                                  if (e?.target?.value >= 1 || e.target.value === '') {
                                    setDiscoveryManPrice(e?.target?.value);
                                  }
                                }}
                                min={1}
                              />
                              <img src={dollar} className="input-img" alt='' />
                              {errors.discoveryManPrice && <div className="error">{errors.discoveryManPrice}</div>}
                            </div>
                          </div>
                        }
                        <div className="col-sm-12">
                          {discoveryPrice === 'variable_price' &&
                            <div className={`form-group minimum mb-0 ${messageUser && 'dailyinputenter'}`}>
                              <label htmlFor="textarea">Message to users <span className={`msg-option`}> (optional)</span></label>
                              <input type="text" className='form-control' id="textarea" placeholder="Tell your users why you support a sliding scale" value={messageUser} onChange={(e) => setMessageUser(e?.target?.value)}
                              />
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>

                  {productType !== 'physical' && (
                    <div class=" offer-diffent">
                      <div class="size-range">
                        <h6>Upload your product</h6>
                        <p>You can provide files or share a link. Buyers can download these files or links as soon as they complete their purchase.</p>
                        <div class="form-group">
                          <label for="link">Add link to the drive</label>
                          <input type="text" class="form-control" id="link" aria-describedby="" placeholder="enter your link" onChange={(e) => setLink(e.target.value)} value={link} ></input>
                          {errors.link && <div className="error">{errors.link}</div>}
                        
                        <div class="google-text">  <p className='google-pera mt-1'>Google Drive, Dropbox, OneDrive, iCloud, Box, etc.</p>
                        </div>
                        </div>
                        <div class="cover-image">
                          <label for="">Upload file</label>

                          <div class="upload_file_container upload-files">
                            <label for=""> <img src={choosefile} alt='' class="pe-2" />Choose file</label>
                            <input type="file" name="photo" multiple accept=".jpeg, .png, .gif, .mp4, .avi, .mp3," onChange={handleFileChangeFile} />
                          </div>

                          {errors.digital_file && <div className="error">{errors.digital_file}</div>}
                        </div>
                        {digital_file.length > 0 && (
                          <div class="bottom">
                            <ul className='d-flex my-2 justify-content-between'>
                              {digital_file.map((file, index) => (
                                <li key={index} className='bxt-text'>
                                  {file.name} - {(file.size / 1024 / 1024).toFixed(2)} MB
                                  <button onClick={() => handleRemoveDigital(file.name)}><img src={cross} /></button>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                        <div class="row upload-cs-data pt-3">
                          <div className='col-md-12'>
                            <p>You can upload digital files of the selected type:</p>
                          </div>
                          <div class="col-md-6">
                            <h6 className='py-2 mb-0'>Images: <span>JPEG, PNG, GIF</span></h6>
                            <h6 className='py-2 mb-0'>Audio: <span>MP3, WAV</span></h6>
                          </div>
                          <div class="col-md-6">
                            <h6 className='py-2 mb-0'>Video: <span>MP4, MOV, AVI</span></h6>
                            <h6 className='py-2 mb-0'>Archives: <span>MP4, MOV, AVI</span></h6>
                          </div>
                        </div>

                      </div>
                    </div>
                  )}

                  <div class='addition-meterial'>
                    <h5>Additional Materials </h5>
                    <h6>Add the file which help</h6>
                  </div>
                  <div class="left">
                    < div className='  section product-section offer-diffent '>
                      <section class="fifth m-0">
                        <div class="top">
                          <button class="image" onClick={() => setAdditionalImageType('image')} className={additionalImageType === 'image' && 'active'}>Image</button>
                          <button class="video" onClick={() => setAdditionalImageType('video')} className={additionalImageType === 'video' && 'active'}>Video</button>
                        </div>
                        <div class="middle">
                          {additionalImageType === 'image' ?
                            <div class="upload_file_container">
                              Select Image
                              <input type="file" accept=".png,.jpg,.jpeg,.avif,.svg" name="photo" onChange={(e) => handleImageUpload(e, 'additional')} />
                            </div>
                            :
                            <div class="upload_file_container">
                              Select Video
                              <input type="file" name="photo" onChange={(e) => handleVideoUpload(e, 'additional')} />
                            </div>
                          }
                          <p class="video-data">Any MP4, AVi files up to 200 MB</p>
                        </div>
                        <div class="bottom">
                          <ul>
                            {additionalImages && additionalImages.length > 0 && additionalImages.map((x, index) => {
                              // Check if 'x' is a string (base64 image) or an object (with file_url and media_type)
                              const isBase64Image = typeof x === 'string' && x.startsWith("data:image/");
                              const isImageObject = typeof x === 'object' && x.media_type === "image";
                              const isVideoObject = typeof x === 'object' && x.media_type === "video";

                              return (
                                <li key={index}>
                                  <button className="button" onClick={() => handleRemoveMedia(index)}>
                                    <img src={cross} className="cross-btn" alt='' />
                                  </button>

                                  {/* Check if it's an image (base64 or URL) */}
                                  {isBase64Image || isImageObject ? (
                                    <img
                                      src={isBase64Image ? x : x.file_url}
                                      alt=''
                                    />
                                  ) : isVideoObject ? (
                                    <video controls>
                                      <source src={x.file_url} type="video/mp4" />
                                    </video>
                                  ) : null}
                                </li>
                              );
                            })}
                          </ul>

                        </div>

                      </section>
                    </div>
                  </div>
                </div>


                {/* all left code here ends  */}



                {/* Html code ends */}
              </div>
              <div class="col-sm-4 col-xl-5">
                <div class="right second">
                  <div class="offering-detail">
                    <h1>Offering Details</h1>
                    <div class="right-social">
                    </div></div>
                  <ul>
                    <div class="detail-top">
                      <li><span>Type</span> <strong>{productType
                        ? productType.charAt(0).toUpperCase() + productType.slice(1)
                        : data?.productType
                          ? data.productType.charAt(0).toUpperCase() + data.productType.slice(1)
                          : ''
                      }</strong> </li>
                      <li><span> Availability</span> <strong>{(availability === 'ready' ? 'Ready for sale' : 'To order') || (data?.availability === 'ready' ? 'Ready for sale' : 'To order')}</strong> </li>
                      {/* <li><span>Type(what is it)</span> <strong></strong> </li> */}
                      <li><span>Tags</span> <strong>{matchingTags.map(x => x.tag_name).join(", ")} </strong> </li>
                    </div>
                    <div class="">
                      <li><span>Delivery option</span> <strong></strong> </li>
                      <li className='mb-0'><span>City</span> <strong></strong> </li>
                    </div>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={tagModal} onRequestClose={() => setTagModal(false)} contentLabel="Tag Modal" shouldCloseOnOverlayClick={true}
        portalClassName="add-tag-modal view-tag"
      >
        {/* <button className='close-button' onClick={() => setTagModal(false)}><img src={crossbutton} alt='' /></button> */}
        <ViewTag closeModal={closeModal} types="offering" creatorTag={creatorTag} setCreatorTag={setCreatorTag} tagName={tagName} setTagName={setTagName} setNameTag={setNameTag} />
      </Modal>
    </>
  );
}
export default DefineProductOffering
