import React, { useEffect, useState } from 'react'
import Header from '../../CreatorComponents/CreatorJoin/header'
import mailLogo from '../../../assets/images/mail-logo.svg'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { forgotValidation, loginValidation } from '../../../Validations/formValidation'
import { postApi } from '../../../Services/apiService'
import { useNavigate, useParams } from 'react-router-dom'
import eye from '../../../assets/images/Eye.svg'
import eyeClosed from '../../../assets/images/eye-icon-change.svg'
import EmailVerify from '../../CreatorComponents/CreatorJoin/emailVerify'
import Modal from 'react-modal';
import cross from '../../../assets/images/cross-button.svg'
import GoogleSignIn from '../../AuthComponent/googleSignIn'
import { messaging ,getToken} from '../../FirebaseConfig/firebase'
import { firebase_valid_key } from '../../FirebaseConfig/firebase';
import { requestNotificationPermission } from '../../FirebaseConfig/NotificationComponent'; // Import the function
import { clearOfferingData } from '../../../Counter/offeringSLice'
import { clearProductData } from '../../../Counter/productSlice'
import { useDispatch } from 'react-redux'

function LoginForm({ nextStep, prevStep,setUserModalIsOpen }) {
  
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState(null);
  const [step, setStep] = useState(5);

  const [showPassword, setShowPassword] = useState(false);
  const [loader,setLoader] = useState(false)
  const [showEmailVerify, setShowEmailVerify] = useState(false);
  const [passwordModal,setPasswordModal] = useState(false)
  const [forgotEmail,setForgotEmail] = useState('')
  const [sendEmailAgain, setSendEmailAgain] = useState(false)
  const [loaders,setLoaders] = useState(false)
  const [forgotMessage,setForgotMessage] = useState('')
  const [fcmToken,setFcmToken] = useState('')
  const [userType, setUserType] = useState('');
  console.log("fcmTokenfcmTokenfcmTokenfcmToken",fcmToken);
  useEffect(() => {
    const currentUrl = window.location.href;

    if (currentUrl.includes('/user/')) {
      setUserType('user');
    } else if (currentUrl.includes('/creator/')) {
      setUserType('creator');
    }
  }, []);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const nextSteps = () => {
    setStep(5);
  };

  const prevSteps = () => {
    setStep(5);
  };

  const forgetPassword = () =>{
    setPasswordModal(true)
  }
 

  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const onSubmit = async (values) => {
    setLoader(true);
    dispatch(clearOfferingData());
    dispatch(clearProductData())
 // Call the notification function and await the token
    const fcmToken = await requestNotificationPermission();
    // if (fcmToken !== 'not_granted') {
    //   console.error("FCM token could not be retrieved.");
    //   setLoader(false);
    //   return;
    // }
    console.log('this is fcm token', fcmToken);

    const value = {
      email: values?.email,
      password: values?.password,
      account_type:'',
      fcmtoken: fcmToken,
    };
    setFormValues(value); // Store the form values in the state
  




    const response = await postApi('/login', value);
    if (response?.statusCode === 200) {
      localStorage.setItem('userDetail', JSON.stringify(response?.user_details));
      localStorage.setItem('token', JSON.stringify(response?.user_details?.token));
      localStorage.setItem('user_role', response?.user_details?.roles[0]?.user_role);
      setLoader(false);
      // const userRole = response?.user_details?.roles[0]?.user_role;
      // if (userRole === 'creator') {
      //   navigate('/creator/home');
      // } else if (userRole === 'user') {
      //   navigate('/user/home');
      // } else if (userRole === 'admin') {
      //   navigate('/admin/members');
      // }
      const roles = response?.user_details?.roles || [];

const hasCreatorRole = roles.some(role => role.user_role === 'creator');

if (hasCreatorRole) {
  navigate('/creator/home');
} else if (roles.some(role => role.user_role === 'user')) {
  navigate('/user/home');
} else if (roles.some(role => role.user_role === 'admin')) {
  navigate('/admin/members');
}
      setUserModalIsOpen(false);
    } else if (response?.statusCode === 201) {
      setLoader(false);
      setShowEmailVerify(true); // Show the EmailVerify component
    } else {
      setLoader(false);
    }
  };
  
  const initialValues = {
    email: '',
    password: ''
  }

  const handleForgotPassword = async(val) =>{
    setLoaders(true)
    if(val?.email !== ''){
      setForgotMessage('')
    const values={
      email:val?.email
    }
    setForgotEmail(val?.email)
    const response = await postApi('/forgot-password',values)
    if(response?.statusCode === 200){
      setLoaders(false)
      setPasswordModal(false)
      setSendEmailAgain(true)
      console.log("successssssssssss")
    } else{
      setLoaders(false)
      console.log('errorrrrrrrrrrrrr')
    }
  } else{
    setLoaders(false)
    setForgotMessage('Email cant be empty')
  }
  }

  return (
    <>
    {showEmailVerify ? (
        <EmailVerify 
          nextStep={nextSteps} 
          prevStep={prevSteps} 
          showGoTo={'0'} 
          data={formValues} 
          loader={loader}
          comp={'login'} 
        />
      ) : (
    <div className='detail-page login signup-cs'>
      <Header/>
      {/* {showEmailVerify ? (
        <EmailVerify 
          nextStep={nextSteps} 
          prevStep={prevSteps} 
          showGoTo={'0'} 
          data={formValues} 
          loader={loader}
          comp={'login'} 
        />
      ) : ( */}
        <div className="detail-main">
          <h2>Log In</h2>
          <p>Enter your login details below</p>
          <Formik
            initialValues={initialValues}
            validationSchema={loginValidation}
            onSubmit={onSubmit}
          >
            <Form>
              <div className="form-group">
                <label htmlFor="email">Email *</label>
                <Field
                  type="text"
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder="Write your Email"
                />
                <ErrorMessage name="email" component="div" className="text-danger" />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password *</label>
                <Field
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  className="form-control"
                  placeholder="Enter your password"
                />
                <span className="eye-icon" onClick={togglePasswordVisibility}>
                  {showPassword ? <img src={eye} alt='' /> : <img src={eyeClosed} alt='' />}
                </span>
                <ErrorMessage name="password" component="div" className="text-danger" />
              </div>
             <div class="text-end "> <strong onClick={()=>forgetPassword()} className='cursor-pointer'>Forgot password?</strong></div>
              <button type="submit" className="button" disabled={loader}>
                {loader && <div className="spinner-border" role="status"><span className="sr-only"></span></div>}
                Log in
              </button>
            </Form>
          </Formik>
          <h5>Don't have an account yet? <strong onClick={() => nextStep()}><u>Sign up</u></strong> </h5>
          <h5 className='text-center'>Or</h5>
              <div className='middle-logins'><GoogleSignIn role='creatoruser' setUserModalIsOpen={setUserModalIsOpen} userType={userType}/></div>
        </div>
       {/* )} */}
       <Modal
        isOpen={passwordModal}
        onRequestClose={() => setPasswordModal(false)}
        contentLabel="Tag Modal"
        shouldCloseOnOverlayClick={true}
        portalClassName="logout-modal-lg forget-pass"
      >
         <button className='close-button' onClick={() => setPasswordModal(false)}><img src={cross} alt='' /></button>
         <div className='detail-page login'>
         <div className="detail-main">
          <h3>Reset Password</h3>
          <p>Please enter your email address and we’ll send you a link to reset your password.</p>
          <Formik
            initialValues={initialValues}
            validationSchema={forgotValidation}
            onSubmit={handleForgotPassword}
          >
            {/* <Form>
        <div className="form-group">
          <label>Enter email</label>
          <Field
                  type="email"
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder="Write your Email"
                />
             
          {forgotMessage !== '' && <div>{forgotMessage}</div>}
          </div>
        <button type='submit' disabled={loaders}>{loaders && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Send password reset email</button>
        </Form> */}
        {({ errors, touched }) => (
        <Form>
          <div className="form-group">
            <label htmlFor="email">Enter email</label>
            <Field
              type="email"
              id="email"
              name="email"
              className="form-control"
              placeholder="Write your Email"
            />
            {/* Show error message if the field has been touched and there's an error */}
            {errors.email && touched.email ? (
              <div className="error-message">{errors.email}</div>
            ) : null}
            {forgotMessage !== '' && <div>{forgotMessage}</div>}
          </div>
          <button type="submit" disabled={loaders}>
            {loaders && (
              <div className="spinner-border" role="status">
                <span className="sr-only"></span>
              </div>
            )}
            Send password reset email
          </button>
        </Form>
      )}
        </Formik>
        {/* <button onClick={()=>handleForgotPassword()}  disabled={loaders}>{loaders && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Send password reset email</button> */}
        <div className='helps-cs'>Need a help? Contact us</div>
        </div>
        </div>
       
      </Modal>
      <Modal
        isOpen={sendEmailAgain}
        onRequestClose={() => setSendEmailAgain(false)}
        contentLabel="Tag Modal"
        shouldCloseOnOverlayClick={true}
        portalClassName="logout-modal-lg forget-pass mail-setup"
      >
         <button className='close-button' onClick={() => setSendEmailAgain(false)}><img src={cross} alt='' /></button>
       <EmailVerify nextStep={''} prevStep={''} data={''} showGoTo={''} comp={''} emailData={forgotEmail} emailType='resetPAssword'/>
      </Modal>
    </div>
    )}
  </>
  );
  
}

export default LoginForm