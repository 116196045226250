import React, { useEffect, useState, useCallback } from 'react';
import cross from '../../../../assets/images/cross-button.svg';
import creditCard from '../../../../assets/images/credit-cards.svg';
import paypal from '../../../../assets/images/PayPal.svg';
import stripe from '../../../../assets/images/Stripe.svg';
import arrowBtn from '../../../../assets/images/arrow-btn.svg';
import checkCircle from '../../../../assets/images/check-mark.svg';
import message2 from '../../../../assets/images/msg-blank.svg';
import check from '../../../../assets/images/success.svg';
import remove from '../../../../assets/images/close-circle.svg';
import backbutton from '../../../../assets/images/backbutton.svg'


function ProductDeliveryAddressModal({ setProductDeliveryAddressModal,setFiltersModal, type,setAddressDetails,setProductDetailsModal}) {



    const gotoPayment = () => {
        if (validateForm()) {
            setAddressDetails(formData)
            setProductDeliveryAddressModal(false); // Close PaymentModal
            setFiltersModal(true);  // Open CheckoutSuccess Modal
        }
    };

    const [formData, setFormData] = useState({
        country: '',
        state: '',
        city: '',
        zipCode: '',
        cityArea: '',
        address: '',
    });
    const [errors, setErrors] = useState({});


    const validateForm = () => {
        let validationErrors = {};


        if (!formData.country) {
            validationErrors.country = 'Country is required';
        } else if (formData.country.trim().length !== formData.country.length) {
            validationErrors.country = 'Country cannot contain spaces';
        }

        // Validate required fields
        // if (!formData.cityArea) {
        //     validationErrors.cityArea = 'City area is required';
        // } else if (formData.cityArea.trim().length !== formData.cityArea.length) {
        //     validationErrors.cityArea = 'City cannot contain spaces';
        // }
        if (!formData.address) {
            validationErrors.address = 'Address is required';
        } else if (formData.address.trim().length !== formData.address.length) {
            validationErrors.address = 'Address cannot contain spaces';
        }

        if (!formData.zipCode) {
            validationErrors.zipCode = 'Zip Code is required';
        } else if (formData.zipCode.trim().length !== formData.zipCode.length) {
            validationErrors.zipCode = 'Zip Code cannot contain spaces';
        }

        if (!formData.state) {
            validationErrors.state = 'State is required';
        } else if (formData.state.trim().length !== formData.state.length) {
            validationErrors.state = 'State cannot contain spaces';
        }

        if (!formData.city) {
            validationErrors.city = 'City is required';
        } else if (formData.city.trim().length !== formData.city.length) {
            validationErrors.city = 'City cannot contain spaces';
        }
        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;

    }

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [id]: value
        }));
    };

    return (
        <>
            <div className='modal-header'>
            </div>
            <div>
                <div className="payment-modal">
                <button className='close-button back-btn' onClick={()=>{setProductDetailsModal(true);setProductDeliveryAddressModal(false)}}><img src={backbutton} alt='' /></button>
                </div>
            </div>
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header justify-content-center">
                        <h1 class="modal-title " id="staticBackdropLabel">Delivery Address</h1>

                    </div>
                    <div class="modal-body">
                        <div class="thanks">
                            <div className='row'>
                              
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <div className='form-group'>
                                            <label htmlFor="country">Country</label>
                                            <input type='text' className='form-control' id='country' value={formData.country} onChange={(e) => { handleChange(e) }} placeholder="USA" />
                                            {errors.country && <div className='error'>{errors.country}</div>}
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='form-group'>
                                            <label htmlFor="state">State</label>
                                            <input type='text' className='form-control' id='state' value={formData.state} onChange={(e) => { handleChange(e) }} placeholder="Texas" />
                                            {errors.state && <div className='error'>{errors.state}</div>}
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-6'>

                                        <div className='form-group mt-0'>
                                            <label htmlFor='zipCode'>Zip Code</label>
                                            <input type='text' className='form-control' id='zipCode' value={formData.zipCode} onChange={handleChange} placeholder="Type Code" />
                                            {errors.zipCode && <div className='error'>{errors.zipCode}</div>}
                                        </div>

                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='form-group'>
                                            <label>Your City</label>
                                            <input type='text' className='form-control' id='city' value={formData.city} onChange={handleChange} placeholder="Austin" />
                                            {errors.state && <div className='error'>{errors.state}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='form-group mt-0'>
                                        <label htmlFor='cityArea'>City area (Optional)</label>
                                        <input type='text' className='form-control' id='cityArea' value={formData.cityArea} onChange={handleChange} placeholder="Type your area (Downtown Austin, North Austin, South Austin etc.)." />
                                        {errors.cityArea && <div className='error'>{errors.cityArea}</div>}
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='form-group mb-2'>
                                        <label htmlFor='address'>Address</label>
                                        <input type='text' className='form-control' id='address' value={formData.address} onChange={handleChange} placeholder="Street Name and Number, apartment number" />
                                        {errors.address && <div className='error'>{errors.address}</div>}
                                    </div>
                                </div>
                                <button className="pay" onClick={() => gotoPayment()}> Go to Payment </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProductDeliveryAddressModal;