import React from 'react'
import checkCircle from '../../../assets/images/check-circle-2.svg'
import leftArrow from '../../../assets/images/left-arrow_black.svg'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { insertData } from '../../../Counter/signupSlice'

function SubmitForm({prevStep,submitForm,loader,loginStatus,data}) {
  const navigate = useNavigate()
  // const dispatch = useDispatch()
  // const onSubmit=async(values)=>{
  //   dispatch(insertData('end'));
  //   nextStep('end')
  // }
  const formatTimeTo12Hour = (time) => {
    if (!time) return "";
    const [hour, minute] = time.split(":").map(Number);
    const period = hour >= 12 ? "pm" : "am";
    const adjustedHour = hour % 12 || 12;
    return `${adjustedHour}:${minute.toString().padStart(2, "0")}${period}`;
  };
  return (
    <div className="detail-page congratulations signup-cs">

        <div className="detail-main">
            <div className="congratulations">
                <span> <img src={checkCircle}/> </span>
                <h1>Congratulations!</h1>
                <p>You have successfully registered! We will be in touch with you shortly.</p>
                <p>Onboarding call will be {new Date(data?.selectedDate || data?.interview_date).toLocaleDateString()} at {formatTimeTo12Hour(data?.selectedTimes || data?.interview_time)} </p>
                <h2>In the meantime, feel free to explore our platform and get acquainted with its features.</h2>
                {/* <Link to='/creator'> */}
                {loginStatus === 'googleLogin' || loginStatus === 'becomeCreator' ?
                  <button className="button" onClick={()=>navigate('/creator/home')}>Go to Creator Dashboard</button>
                  :
                  <button className="button" onClick={()=>submitForm()} disabled={loader}>{loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Go to Creator Dashboard</button>
                }
                  {/* </Link> */}
                <p className="bottom-p">*Please note: you won't be able to publish an offer until you complete the verification process.</p>
            </div>

        </div>
        {loginStatus !== 'googleLogin' &&
        <div className="back-button">
  <button className="button" onClick={()=>prevStep()}><img src={leftArrow}/> </button>
  </div>}
    </div>
  )
}

export default SubmitForm