import React, { useEffect, useState, useCallback } from 'react';
import exportSvg from '../../../assets/images/export.svg';
import exporticon from '../../../assets/images/exporticon.svg';
import add from '../../../assets/images/add.svg';
import search from '../../../assets/images/search.svg';
import filterList from '../../../assets/images/filter-list.svg';
import view from '../../../assets/images/view.svg';
import del from '../../../assets/images/del.svg';
import UserSidebar from '../../../Layout/userSidebar';
import burger from '../../../assets/images/burger-menu.svg';
import threeDots from '../../../assets/images/three-dots-horizontal.svg';
import threeDotsVer from '../../../assets/images/three-dots.svg';
import { deleteApi, getApi } from '../../../Services/apiService';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import cross from '../../../assets/images/cross-button.svg';
import { toast } from 'react-toastify';
import AdminMultiStep from '../../AdminComponents/adminMultistep'
import OrderFilter from './orderFilter';
import edit from '../../../assets/images/edit-icon-2.svg'
import share from '../../../assets/images/share1.svg'
import doubeBox from '../../../assets/images/double-box.svg'

function AdminOrders() {
  const navigate = useNavigate();
  const [classState, setClassState] = useState(false);
  const [userData, setUserData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filtersModal, setFiltersModal] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null); // State to track which dropdown is open
  const [selectedFilters, setSelectedFilters] = useState({
    user: false,
    creator: false,
    coCreator: false
  });
  const [productType, setProductType] = useState([]);
  const [productStatus, setProductStatus] = useState([]);

  const toggleDropdown = (id, event) => {
    event.stopPropagation(); // Prevent the click from bubbling up and triggering the global click handler
    setActiveDropdown((prevDropdown) => (prevDropdown === id ? null : id)); // Toggle the dropdown
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close the dropdown if clicked outside the dropdown list and button
      if (!event.target.closest('.arcived-list') && !event.target.closest('.dropdown-button')) {
        setActiveDropdown(null);
      }
    };

    // Add event listener for handling clicks outside
    document.addEventListener('click', handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const fetchData = async (query = '', filters = {}) => {
  // const fetchData = useCallback(async (query = '', filters = {}) => {
    try {
      const filterQuery = Object.entries(filters)
        .filter(([key, value]) => value)
        .map(([key]) => key)
        .join(',');

      const response = await getApi(`/admin/orders?search=${query}&status=${productStatus}&productKind=${productType}`);
      if (response?.statusCode === 200) {
        setUserData(response?.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  // }, [navigate]);

  useEffect(() => {
    fetchData(searchQuery, selectedFilters); // Initial fetch
    const handleClick = () => setClassState(false);
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [searchQuery, selectedFilters,productType,productStatus]);
  // }, [fetchData, searchQuery, selectedFilters]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSidebarClick = (event) => {
    event.stopPropagation();
    setClassState(!classState);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const openFiltersModal = () => {
    setFiltersModal(true);
  };

  const closeFiltersModal = () => {
    setFiltersModal(false);
  };

  return (
    <div className="users meal-suggestion admin">
      <div className="HomeMainDiv">
        <UserSidebar classState={classState} />
        <div className="right-main request-member orders">
          <div className="members-top">
            <h2>
              <button className="burger-menu" onClick={handleSidebarClick}>
                <img src={burger} alt='burger menu' />
              </button>
              Product Orders
            </h2>
            <div className="members-top-right">
              <button><img src={exportSvg} alt="export" />Export</button>
            </div>
          </div>

          <div className="member-filter">
            <div className="filter-search filter-respon">
              <button><img src={search} alt="search" /></button>
              <input
                className="search"
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
            <button className="filter-btn" onClick={openFiltersModal}>
              <img src={filterList} alt="filters" />Filters
            </button>
            {/* <button className="filter-btn">
              <img src={filterList} alt="filters" />Filters
            </button> */}
          </div>
          <div className="overflow-scrolls">
          <div className="table-header">
            
            <ul>
              <li className="id">Order Id</li>
              <li className="name">User Name</li>
              <li className="product">Product Info</li>
              <li className="creator">Creator Name</li>
              <li className="date">Date & Time</li>
              <li className="amount">Amount</li>
              <li className="status">Status</li>
              <li className="Action">Action</li>
            </ul>
          </div>

       
            {userData && userData.length > 0 && userData.map((x, index) => (
              <ul className="table-row table-respon" key={index}>
                <li className="id">#{index + 1}</li>
                <li className="name">


                  {x?.user?.user_name || '-'}

                </li>
                <li className="product">{x?.offerService?.title || '-'}</li>
                <li className="creator">{x?.offerService?.user?.creator_name || '-'}</li>
                <li className="date">{formatDate(x?.createdAt || '-')}</li>
                <li className="amount">${(x?.amount)}</li>
                {/* <li className="amount">${(x?.amount / 100).toFixed(0)}</li> */}
                <li className="status">{x?.status !== null &&
                  (<button className={`btn ${x?.status === '1' ? 'processing' : x?.status === '2' ? 'pickup' : x?.status === '3' ? 'accept' : x?.status === '4' ? 'decline' : x?.status === '5' ? 'completed' : ''}`}>
                    {x?.status === '1' ? 'Pending' : x?.status === '2' ? 'Processing' : x?.status === '3' ? 'Pickup' : x?.status === '4' ? 'Decline' : x?.status === '5' ? 'Completed' : ''}
                    {/* {x?.status === '1' ? 'Processing' : x?.status === '2' ? 'Pickup' : x?.status === '3' ? 'Accept' : x?.status === '4' ? 'Decline' : x?.status === '5' ? 'Completed' : ''} */}
                  </button>)}</li>
                <li className="Action">
                  <button><img   className='p-2' src={threeDotsVer} alt="view" onClick={(e) => toggleDropdown(x?.id, e)} /></button>
                  {activeDropdown === x?.id && (
                    <div className='arcived-list'>
                      <ul>

                        <li>
                          <img src={share} alt='box' />
                          Share
                        </li>
                        <li onClick={() => navigate('/admin/orders/details/' + x?.id)}>
                          <img src={doubeBox} alt='box' />
                          View order details
                        </li>
                        <li className='export'>
                          <img src={exporticon} alt='box' />
                          Export
                        </li>

                      </ul>
                    </div>
                  )}
                  {/* <button><img src={view} alt="view" /></button> */}
                  {/* <button onClick={() => openModal(x?.id)}><img src={del} alt="delete" /></button> */}
                </li>
              </ul>
            ))}
          </div>
          <Modal
            isOpen={filtersModal}
            onRequestClose={closeFiltersModal}
            contentLabel="Filters Modal"
            shouldCloseOnOverlayClick={false}
            ariaHideApp={false}
            overlayClassName={`modal-overlay member-sidebar booking-order-filter`}
          >
            <button className='close-button' onClick={closeFiltersModal}>
              <img src={cross} alt='close' />
            </button>
            <OrderFilter type={'Order'} setSelectedBookingType={''} setProductType={setProductType} setProductStatus={setProductStatus}></OrderFilter>

          </Modal>
        </div>
      </div>
    </div>
  );
}

export default AdminOrders;
