import React, { useEffect, useState } from 'react'
import leftArrow from '../../../assets/images/left-arrow_black.svg'
import { Link } from 'react-router-dom'
import calendar from '../../../assets/images/calender.png'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { insertUserData } from '../../../Counter/userSignUpSlice';
import { bookingInterviews, bookingInterviewsCreator } from '../../../Validations/formValidation';
import Header from '../../CreatorComponents/CreatorJoin/header';
import { getApi } from '../../../Services/apiService';
// import SubmitForm from '../../CreatorComponents/CreatorJoin/submitForm';

function BookInterviews({ nextStep, prevStep, data ,submitForm,loader}) {
  console.log('********data******ererer*',data);
  const initialValues = {
    selectedDate: data?.selectedDate || '',
    selectedTimes: data?.selectedTimes || '',
  }
  const [selectedTime, setSelectedTime] = useState('');
  const [value, onChange] = useState('');
  // const [value, onChange] = useState(new Date());
  const [monthDate,setMonthDate] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [activeMonth,setActiveMonth] = useState(new Date());
  const dispatch = useDispatch();

  useEffect(()=>{
    if(value !==''){
      handleDateSlotData();
      }
  },[value])
  useEffect(()=>{
    handleActiveStartDateChange();
  },[activeMonth])

  const handleDateSlotData = async() =>{
    const month = new Date(value).getMonth();
    const year = new Date(value).getFullYear();
    const currentDate = new Date(value).getDate();
    const response = await getApi(`/availbility-slot?date=${year}-${month+1}-${currentDate}`)
    if(response?.statusCode === 200){
      setTimeSlots(response?.data)
    }
    else{

    }
  }

  const handleActiveStartDateChange = async( ) => {
    const month = new Date(activeMonth).getMonth();
    const year = new Date(activeMonth).getFullYear();

    const response = await getApi(`/availbility-date?monthYear=${month+1}-${year}`)
    if(response?.statusCode === 200){
      const formattedDates = response?.data.map(dateTime => dateTime.slice(0, 10));
      setMonthDate(formattedDates)
    }
    // setActiveStartDate(activeStartDate);
    // Fetch availability for the new month
    // getAvailability();
  }

  const convertTo24HourFormat = (time) => {
    const [hours, minutesPart] = time.split(':');
    const [minutes, period] = minutesPart.match(/[a-zA-Z]+|[0-9]+/g);
    let hours24 = parseInt(hours, 10);
  
    if (period.toLowerCase() === 'pm' && hours24 !== 12) {
      hours24 += 12;
    }
    if (period.toLowerCase() === 'am' && hours24 === 12) {
      hours24 = 0;
    }
  
    return `${hours24.toString().padStart(2, '0')}:${minutes}`;
  };
  const onSubmit = async (datas) => {
    const selectedTime = timeSlots.find(time => time.id === datas?.selectedTimes);
    const values ={
      selectedDate:datas?.selectedDate || value,
      // selectedTimes:datas?.selectedTimes
      selectedTimes: `${selectedTime?.start_time} - ${selectedTime?.end_time}` ,
      slotId: datas?.selectedTimes,
    }
    dispatch(insertUserData(data));
    submitForm('booking',values)
    // nextStep()

  }
  // const timeSlots = [
  //   '07:00am', '08:00am', '09:00am', '10:00am', '12:00pm', '01:00pm', '02:00pm', '03:00pm', '04:00pm', '05:00pm',
  // ];

  function convertTo12HourFormat(time) {
    const [hours, minutes] = time.split(':');
    let period = 'AM';
    let hour = parseInt(hours, 10);
  
    if (hour >= 12) {
      period = 'PM';
      if (hour > 12) hour -= 12;
    } else if (hour === 0) {
      hour = 12;
    }
  
    return `${hour}:${minutes} ${period}`;
  }

  return (
    // <div className="detail-page tell-us booking">
    <div className="detail-page  booking signup-cs">
      <Header/>
      <div className="container">

        <h2>Now, Book An Onboarding Interview With A Member Of Our Team</h2>
        <h3>You can start exploring the platform and host your first offering after the onboarding call.</h3>
        <div className="detail-main">
          <Formik
            initialValues={initialValues}
            validationSchema={bookingInterviewsCreator}
            onSubmit={onSubmit}
          >
            {({ setFieldValue }) => (
              <Form>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="select-date">
                      <h4>Select date</h4>
                      <div className="calender">
                        <Calendar
                          onChange={(newValue)=>{onChange(newValue);setFieldValue("selectedTimes", "")}}
                          value={value}
                          minDate={new Date()}
                          onActiveStartDateChange={(e)=>setActiveMonth(e?.activeStartDate)}
                          tileClassName={({ date,view }) => {
                            // const formattedDate = date.toISOString().split('T')[0];
                            const formattedDate = date.toLocaleDateString('en-CA');
                            const isToday = date.getDate() === new Date().getDate() &&
                        date.getMonth() === new Date().getMonth() &&
                        date.getFullYear() === new Date().getFullYear();
                        const currentMonth = activeMonth.getMonth();
  const currentYear = activeMonth.getFullYear();

  const dateMonth = date.getMonth();
  const dateYear = date.getFullYear();
                        const isPreviousMonth = view === 'month' && (dateMonth < currentMonth && dateYear === currentYear || dateYear < currentYear);
                        const isNextMonth = view === 'month' && (dateMonth > currentMonth+1 && dateYear === currentYear || dateYear >  currentYear);
                            let classNames = '';
                            if (isPreviousMonth || !isNextMonth) {
                              classNames += ' previous-date';
                              // return 'previous-date'; // Add class for previous month dates
                            }
                            if (monthDate.includes(formattedDate)) {
                              // classNames += '';
                              // classNames += ' highlight';
                            }
                            else{
                              classNames += ' sanket'
                            }
                            if (value && formattedDate === new Date(value).toLocaleDateString('en-CA')) {
                              classNames += ' highlight selected-date'; // Custom class for the clicked date
                            }
                            return classNames;
                          }}
                        />
                        <ErrorMessage name="selectedDate" component="div" className="text-danger" />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="select-date select-date-respons">
                      <h4>Select time</h4>
                      <div className="row select-log-tag">
                        {timeSlots.map((time) => (
                          <div className="col-lg-5 col-sm-6" key={time}>
                            <label className="time">
                              <Field type="radio" name="selectedTimes" value={time?.id} />
                              <span className="checkmark">
                                {/* <h5>{time}</h5> */}
                                <h5>{convertTo12HourFormat(time?.start_time)} - {convertTo12HourFormat(time?.end_time)}</h5>
                              </span>
                            </label>
                          </div>
                        ))}
                        {timeSlots?.length === 0 && <p className='text-start'>Please select a date on the left calendar to view available time slots</p>}
                        <ErrorMessage name="selectedTimes" component="div" className="text-danger" />
                      </div>
                    
                      <button type="submit" className="choose" disabled={loader}>{loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Choose</button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="back-button">
          <button className="button" onClick={() => prevStep()}><img src={leftArrow} alt=''/> </button>
        </div>
      </div>
    </div>
  )
}

export default BookInterviews