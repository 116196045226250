// stripeService.js

import { loadStripe } from '@stripe/stripe-js';

export const stripePromise = loadStripe('pk_test_51QAmfWDxFZ8WBJP4t3k1DeKlN8ErwAbTbjSV4h55fUTnbhRmAgkYrOSvE8Q6mqEHjobKMMdbW0o5hj9sphTsPtUh000rse8Z0c');



// Function to create a token from card details
export const createCardToken = async (stripe, cardElement) => {
    try {
        // Generate token from card details
        const { token, error } = await stripe.createToken(cardElement);
        
        if (error) {
            throw new Error(error.message);
        }

        // Return the token
        return token;
    } catch (error) {
        console.error('Error creating card token:', error);
        throw error;
    }
};

// Function to create a payment intent
export const createPaymentIntent = async (amount) => {
    const stripe = await stripePromise;  // Ensure stripe.js is loaded

  try {
    const response = await fetch('/create-payment-intent', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ amount }),
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.error || 'Failed to create payment intent');
    }

    return data.client_secret;
  } catch (error) {
    console.error('Error creating payment intent:', error);
    throw error;
  }
};

// Function to confirm payment
export const confirmPayment = async (stripe, cardElement, clientSecret) => {
  try {
    const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
      },
    });

    if (error) {
      throw new Error(error.message);
    }

    return paymentIntent;
  } catch (error) {
    console.error('Error confirming payment:', error);
    throw error;
  }
};
