import React, { useEffect, useState } from 'react';
import search from '../../../assets/images/search.svg';
import tick from '../../../assets/images/green-tick.svg';
import { getApi } from '../../../Services/apiService';

 

function OrderFilter({type,setSelectedBookingType,setProductType,setProductStatus}) {

    const [searchQueryUsers, setSearchQueryUsers] = useState('');
    const [searchQueryCreator, setSearchQueryCreator] = useState('');
    console.log('this is type',type);
    const [selectedProductType, setSelectedProductType] = useState({
        physical: false,
        digital: false,
      });
    const [bookingType, setBookingType] = useState({
        private: false,
        group: false,
      });
      const [selectedStatus, setSelectedStatus] = useState({
        completed: false,
        declined: false,
        processing: false,
      });
      const [cretorList,setCreatorList] = useState([])
      const [userList,setUserList] = useState([])
      console.log("cretorListcretorList",cretorList)
      
    const handleProductTypeCheckboxChange = async(e) => {
        let { name, checked } = e.target;
        if(name==='physical'){
          name =0
        }if(name==='digital'){
          name =1
        }
        setProductType((prev) => {
          if (prev.includes(name)) {
            return prev.filter((item) => item !== name);
          } else {
            return [...prev, name];
          }
        });
        setSelectedProductType(prev => ({
          ...prev,
          [name]: checked
        }));
        // const resp = await getApi('')
      };
    const handleBookingTypeCheckboxChange = async(e) => {
        let { name, checked } = e.target;
        if(name==='private'){
          name =1
        }if(name==='group'){
          name =2
        }
        setSelectedBookingType((prev) => {
          if (prev.includes(name)) {
            return prev.filter((item) => item !== name);
          } else {
            return [...prev, name];
          }
        });
      };

      const handleStatusCheckboxChange = (e) => {
        let { name, checked } = e.target;
        if(name==='completed'){
          name =5
        }if(name==='declined'){
          name =4
        }
        if(name==='processing'){
          name =2
        }
        setProductStatus((prev) => {
          if (prev.includes(name)) {
            return prev.filter((item) => item !== name);
          } else {
            return [...prev, name];
          }
        });
        setSelectedStatus(prev => ({
          ...prev,
          [name]: checked
        }));
      };
      const handleCreatorsSearchChange = async(e) => {
        setSearchQueryCreator(e.target.value);
        let response;
        if(type==='Booking'){
         response = await getApi(`/admin/booking/creator?search=${e?.target?.value}`)
        } else{
         response = await getApi(`/admin/order/creator?search=${e?.target?.value}`)
        }
        if(response?.statusCode === 200){
          setCreatorList(response?.data)
        }
        if(e?.target?.value === ''){
          setCreatorList([])
        }
        
    };
      const handleUsersearchChange = async(e) => {
        setSearchQueryUsers(e.target.value);
        let response;
        if(type==='Booking'){
           response = await getApi(`/admin/booking/user?search=${e?.target?.value}`)
        } else{
          response = await getApi(`/admin/order/user?search=${e?.target?.value}`)
        }
        if(response?.statusCode === 200){  
          setUserList(response?.data)
        }
        if(e?.target?.value === ''){
          setUserList([])
        }
    };
    
    return (

    <>
        <div>
            <h3>Filters</h3>
                <h4>Creators</h4>
                <div className="popular-search position-relative">
                 
                    <input
                        type="text"
                        placeholder="Type Creator Name"
                        value={searchQueryCreator}
                        onChange={handleCreatorsSearchChange}
                    />
                       <button>
                        <img src={search} alt="search" />
                    </button>
                      {cretorList?.length >0 &&
                     <div className='creator-list'>
                    <ul>
                            {cretorList && cretorList?.length>0 && cretorList.map((x,index)=>{  
                              return(    
                        <li key={index}>
                          <div className='w-75'>
                          <span className='user me-3'><img src={x?.user_profile_image}  alt='img'></img></span> 
                           <p>{x?.name}</p>
                            </div> 
                            <div className='w-25 text-end'>
                            <span className='tick'><img src={tick}  alt='img'></img></span>
                            </div>
                            </li>
                              )
                            })}
                    </ul>
                </div>
                   } 
                </div>
               


                <h4>Users</h4>
                <div className="popular-search position-relative">
                 
                    <input
                        type="text"
                        placeholder="Type User Name"
                        value={searchQueryUsers}
                        onChange={handleUsersearchChange}
                    />
                       <button>
                        <img src={search} alt="search" />
                    </button>
                    {userList?.length > 0 &&
                    <div className='creator-list'>
                    <ul>
                    {userList && userList?.length>0 && userList.map((x,index)=>{  
                              return(    
                        <li key={index}>
                          <div className='w-75'>
                          <span className='user me-3'><img src={x?.user_profile_image}  alt='img'></img></span> 
                           <p>{x?.name}</p>
                            </div> 
                            <div className='w-25 text-end'>
                            <span className='tick'><img src={tick}  alt='img'></img></span>
                            </div>
                            </li>
                              )
                            })}
                    </ul>
                </div>
                }
                </div>
                


            {type==='Booking' &&
            <>
            <h4>Booking Type</h4>

            <div className=''>
                <div class=" cannvas-check mt-2">
                  <label className="container2">
                    Private session
                    <input
                      type="checkbox"
                      name="private"
                      checked={setBookingType.private}
                      onChange={handleBookingTypeCheckboxChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                

                <div class="cannvas-check ">
                  <label className="container2">
                    Group session
                    <input
                      type="checkbox"
                      name="group"
                      checked={setBookingType.group}
                      onChange={handleBookingTypeCheckboxChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
            </div>
            </>}
            {type==='Order' &&
            <>
            <h4>Product Type</h4>

            <div className=''>
                <div class=" cannvas-check mt-2">
                  <label className="container2">
                    Physical
                    <input
                      type="checkbox"
                      name="physical"
                      checked={setSelectedProductType.physical}
                      onChange={handleProductTypeCheckboxChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                

                <div class="cannvas-check ">
                  <label className="container2">
                    Digital
                    <input
                      type="checkbox"
                      name="digital"
                      checked={setSelectedProductType.digital}
                      onChange={handleProductTypeCheckboxChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
            </div>
            </>}
            {type==='Order' &&
            <>
            <h4>Status</h4>
            <div className=''>
                <div class=" cannvas-check mt-2">
                  <label className="container2">
                    Completed
                    <input
                      type="checkbox"
                      name="completed"
                      checked={setSelectedStatus.completed}
                      onChange={handleStatusCheckboxChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>

                <div class="cannvas-check ">
                  <label className="container2">
                    Declined
                    <input
                      type="checkbox"
                      name="declined"
                      checked={setSelectedStatus.declined}
                      onChange={handleStatusCheckboxChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div class="cannvas-check ">
                  <label className="container2">
                    Processing
                    <input
                      type="checkbox"
                      name="processing"
                      checked={setSelectedStatus.processing}
                      onChange={handleStatusCheckboxChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
            </div>
            </>
}

        </div>
        <div class=" show-btns">
        <button>Show results</button>
      </div>
      </>
    )
}
export default OrderFilter;
