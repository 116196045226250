import React from 'react'
import DefineProductOffering from './defineProductOffering'
import ProductDetailOffering from './productDetailOffering'
import DeliverPickupProductOffering from './deliverPickup'
import { useSelector } from 'react-redux';
import { postApi, putApi } from '../../../../Services/apiService';
import { useNavigate } from 'react-router-dom';

function EditProduct() {
  const formState = useSelector((state) => state.productCounter);
  const productType = formState?.productType
  const navigate = useNavigate();
let idAddress;
  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const handelSaveData = async() =>{
    console.log("edit product data",formState)
    if(formState?.address_id===null){
    const val = {
      country: '',
      state: formState?.state,
      zip_code: formState?.zipCode,
      city: formState?.city,
      travel_area: formState?.cityArea,
      address: formState?.address
    }
    const response = await postApi('/address', val)
    if (response?.statusCode === 200) {
      // formState.address_id = response?.data?.id
      idAddress =response?.data?.id
      // const updatedFormState = {
      //   ...formState,
      //   address_id: response?.data?.id
      // };
    }
  }

  await delay(1000);

  let productType = formState?.productType === 'digital'
    ? '1'
    : '0';

  let availability = formState?.availability === 'order'
    ? '1'
    : '0';

  let pricing_type = formState?.discoveryPrice === 'fixed_price'
    ? 1
    : 2;

    let selectedOption;

if(!formState){
if (formState?.selectedOption === 'Pick up') {
  selectedOption = 1;
} else if (formState?.selectedOption === 'Both') {
  selectedOption = 3;
}  else if (formState?.selectedOption === 'Delivery') {
  selectedOption = 2;
}  
}
else {
selectedOption = formState?.selectedOption === 'Pick up' ? 1
  : formState?.selectedOption === 'Delivery' ? 2
  : formState?.selectedOption === 'Both' ? 3
  : 1; // Default to 1 if formState?.selectedOption is undefined
}
  let deliveryChargesStatus = formState?.isChecked === false
    ? 0
    : (formState?.isChecked === false ? 0 : 1);
  let formData = new FormData()
  console.log('formData>>>>>>>>>>>>>>>.', formState)
  formData.append('title', formState?.title || '');
  formData.append('description', formState?.description  || '');
  formData.append('product_kind', productType);
  formData.append('product_availbility', availability);
  formData.append('quantity_items', formState?.quantityOrders || '');
  formData.append('maximum_quantity_order', formState?.Maxquantity || '');
  formData.append('processing_time', formState?.processing_time || '');
  formData.append('additional_processing_time', formState?.additional_unit || '');
  formData.append('pricing_type', pricing_type);
  formData.append('minimum_price', formState?.discoverMinPrice || '');
  formData.append('maximum_price', formState?.discoverManPrice || 0);
  formData.append('message_to_user', formState?.messageUser || '');
  formData.append('delivery_option', selectedOption );
  formData.append('product_address', formState?.address || '');
  formData.append('delivery_price', formState?.deliveryCharges || '');
  formData.append('address_id', formState?.address_id || idAddress || '');
  // formData.append('address_id', formState?.addressId || formState?.addressId || '');
  formData.append('delivery_time', formState?.deliveryTime || '');
  formData.append('delivery_charge', deliveryChargesStatus);
  formData.append('deleted_ids', formState?.deleted_ids || '');
  formData.append('location', formState?.address || '');
  formData.append('status', 1);
  // if (offerStatus === 'draft') {
  //   formData.append('status', 3);
  // } else {
  //   formData.append('status', 1);
  // }
  // formData.append('step', step);
  formData.append('service_type', 2);
  formData.append('digital_link', formState?.link || '');
  formData.append('product_tag', formState?.tags || '');
const isImageEmpty = (img) => img && typeof img === 'string' && img.startsWith('https://microcosmmtest.s3.us-east-1.amazonaws.com')
formData.append(
'product_image',
isImageEmpty(formState?.imagePreview) || isImageEmpty(formState?.imagePreview) || 
isImageEmpty(formState?.cover_image) || isImageEmpty(formState?.cover_image) 
  ? '' 
  : (formState?.imagePreview || formState?.cover_image || '')
);
  formData.append('id', formState?.product_id || formState?.id || '');
  if (formState?.digital_file?.length > 0) {
    formState.digital_file.forEach((file, index) => {
      formData.append(`digital_media[${index}]`, file);
    });
  } else if (formState?.digital_file?.length > 0) {
    formState.digital_file.forEach((file, index) => {
      formData.append(`digital_media[${index}]`, file);
    });
  }
  if (formState?.additionalImagesPreview?.length > 0) {
    formState.additionalImagesPreview.forEach((file, index) => {
      formData.append(`product_media[${index}]`, file);
    });
  } else if (formState?.additionalImagesPreview?.length > 0) {
    formState.additionalImagesPreview.forEach((file, index) => {
      formData.append(`product_media[${index}]`, file);
    });
  } else if (formState?.offerMaterials?.length > 0) {
    formState.offerMaterials.forEach((material, index) => {
      if (material.media_type === 'image') {
        formData.append(`product_media[${index}]`, material.file_url);
      }
    });
  } else if (formState?.offerMaterials?.length > 0) {
    formState.offerMaterials.forEach((material, index) => {
      if (material.media_type === 'image') {
        formData.append(`product_media[${index}]`, material.file_url);
      }
    });
  }

  const editApi = await putApi('/creator/product', formData);
  if(editApi?.statusCode === 200){
    navigate('/creator/offering/home')
  }else{

  }
  }



  return (
    <>
    <div className='edit-offering edit-produtcs'>
    <div className='edit-offer-one'>
    <DefineProductOffering nextStep={null} step={null} submitForm={handelSaveData} data={formState} setDraftData={null} typess={'edit'}/>
    </div>
    <div className='edit-offer-two'>
    <DeliverPickupProductOffering nextStep={null} prevStep={null} step={null} submitForm={null} data={formState} setDraftData={null} type={'edit'}/>
    </div>
    {productType ==='digital' || productType === '1' &&
    <div className='edit-offer-three'>
    <ProductDetailOffering nextStep={null} prevStep={null} step={null} data={formState} submitForm={null} setDraftData={null}  type={'edit'}/>
    </div>}

    {/* <ProductDetailOffering nextStep={null} prevStep={null} step={null} data={formState} submitForm={handelSaveData} setDraftData={null} />; */}
    </div>
    </>
  )
}

export default EditProduct