import AdminAccounts from "../../AdminComponents/SettingComps/account";
import facebook from '../../../assets/images/facebook5.svg'
import google from '../../../assets/images/google5.svg'
function BasicsAccount({ }) {

    return (
        <div className="account account-cs">
            <AdminAccounts type='creator'/>
            <div className="section first">
                {/* <h1>Update password</h1> */}

                <form>
                    {/* <div className="form-group">
                        <label htmlFor="old-password">Old password</label>
                        <input type="text" id="old-password" className="form-control" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="new-password">New password</label>
                        <input type="text" id="new-password" className="form-control" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="confirm-password">Confirm password</label>
                        <input type="text" id="confirm-password" className="form-control" />
                    </div>

                    <div className="account-button">
                        <button className="update-password">Update password</button>
                    </div> */}

                    <div className="social">
                        <div className="content">
                            <div className="image-content">
                                <span><img src={facebook} alt="facebook"></img> </span><h6>Log in with Facebook</h6>
                            </div>
                            <button className="connect">Connect</button>
                        </div>
                        <div className="content">
                            <div className="image-content">
                                <span><img src={google}></img> </span><h6>Log in with Google</h6>
                            </div>
                            <button className="connect">Connect</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default BasicsAccount;