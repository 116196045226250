import React, { useState, useEffect, useRef } from 'react';
import UserSidebar from '../../../../Layout/userSidebar';
import burger from '../../../../assets/images/burger-menu.svg';
import ActiveLinks from '../activeLinks';
import leftArrow from '../../../../assets/images/backarrow.svg';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux';
import { insertProductData } from '../../../../Counter/productSlice';
import { getApi, postApi } from '../../../../Services/apiService';
import dollar from '../../../../assets/images/dollar.svg'
import cross from '../../../../assets/images/gray-close5.svg'
import Modal from 'react-modal';
import TermsAndPolicy from '../../../AuthComponent/termsAndPolicy';
import { handleAddressChanges } from '../../../../Helper/helperFunction';
import greenTick from '../../../../assets/images/green-tick.svg'


function DeliverPickupProductOffering({ nextStep, prevStep, step, submitForm, data, setDraftData,type }) {
  console.log("data in deliver pickup", data);
  const dropdownRef = useRef(null);
  const [classState, setClassState] = useState(false);
  const [isChecked, setIsChecked] = useState(data?.deliveryCharge || false); // State to handle checkbox
  const [selectedOption, setSelectedOption] = useState(data?.selectedOption || 'Pick up'); // Default active option
  const [termsChecked, setTermsChecked] = useState(data?.termsChecked || 'false'); // State to handle terms checkbox
  const [loader, setLoader] = useState(false)
const [address_id, setAddressId] = useState('')
  const [formData, setFormData] = useState({
    state: data?.state || '',
    // state2: data?.state2 || '',
    city: data?.city || '',
    // city2: data?.city2 || '',
    cityArea:  data?.city  || '', //|| data?.cityArea
    zipCode: data?.zipCode || '',
    deliveryTime: data?.deliveryTime || '',
    deliveryPack: data?.deliveryPack || '',
    // deliveryTime: data?.delivery_time || '',
    address: data?.address || '',
    addresses: data?.addresses || '',
    deliveryCharges: data?.delivery_price || '',
    termsChecked: data?.termsChecked || termsChecked || 'false',
    selectedAddressId: data?.selectedAddressId || data?.address_id || '',

  });
  const [errors, setErrors] = useState({});
  const [getAddress, setGetAddress] = useState([]);
  const [classHappen, setClassHappen] = useState(false)
  const [addressState, setAddressState] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState(data?.address_id || data?.addressId || null); // State to store selected address id
  const [termslIsOpen, setTermslIsOpen] = useState(false);
  const [getAddressData,setGetAddressData] = useState([])
  const [currentAddress,setCurrentAddress] = useState()
  const [handleDayDropdown,setHandleDayDropdown] = useState(false)


  console.log('deliveryChargesdeliveryCharges',formData?.deliveryCharges);
  console.log('data?.delivery_timedelivery_timedelivery_timedelivery_time',data?.delivery_time);

  const dispatch = useDispatch()
  const navigate = useNavigate();

  useEffect(() => {
    if (currentAddress) {
    fetchAddressData(); 
    }
  }, [currentAddress]);

  const fetchAddressData = async () => {
      try {
        const res = await handleAddressChanges(currentAddress);
        if (res?.resp?.statusCode === 200) {
          setGetAddressData(res?.resp?.data);
        }
      } catch (error) {
        console.error('Error fetching address data:', error);
      }
  };

  const handleSidebarClick = (event) => {
    event.stopPropagation();
    setClassState(!classState);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle the checkbox state
    setFormData(prevData => ({
      ...prevData,
      ['deliveryCharges']: ''
    }));
  };

  const handleAddressChange = (e, addressId) => {
    if (selectedAddressId === addressId) {
      // If the clicked address is already selected, uncheck it
      setSelectedAddressId(null);
      setFormData(prevData => ({
        ...prevData,
        addresses: '',
        city: '',
        state: '',
        zipCode: '',
        cityArea: '',
        address: '',
        state2: '',
        city2: '',
      }));
    } else {
      // Else, select the clicked address
      const selectedAddress = getAddress.find(address => address.id === addressId);
  
      if (selectedAddress) {
        setFormData(prevData => ({
          ...prevData,
          addresses: selectedAddress.address,
          city: selectedAddress.city,
          state: selectedAddress.state,
          zipCode: selectedAddress.zip_code,
          cityArea: selectedAddress.city,
          address: selectedAddress.address,
          state2: selectedAddress.state,
          city2: selectedAddress.city,
        }));
        setSelectedAddressId(addressId);  // Store selected address ID
      }
    }
  };
  



  useEffect(() => {
    handleGetAddress();
  }, [addressState, classHappen])

  const handleGetAddress = async () => {
    const response = await getApi('/address')
    setGetAddress(response?.data)
    if(response?.data){
      response?.data.map((x,index)=>{
        x?.id === selectedAddressId && 
        setFormData({
          state: x?.state || '',
          state2: x?.state || '',
          city: x?.city || '',
          city2: x?.state || '',
          cityArea: x?.travel_area || '',
          zipCode: x?.zip_code || '',
          address: x?.address || '',
          addresses: x?.address || '',
          selectedAddressId: x?.id || ''
  
        })
      })
    }
   
  }


  const handleChange = (e) => {
    const { id, value } = e.target;
    if(id === "state" || id === "city" || id === "zipCode"|| id === "cityArea"|| id === "address" || id === "state2"|| id === "city2" ){
      setSelectedAddressId(null)
    }
    if(id ==='cityArea'){
      setCurrentAddress(value)
    }

    if(id==='deliveryTime'){
      if(value<31 && value>=0){
      setFormData(prevData => ({
        ...prevData,
        [id]: value
      }));
    }
    } else{
    setFormData(prevData => ({
      ...prevData,
      [id]: value
    }));
  }
  };

  const handleChanges = (value, key,unit) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };


  const validateForm = () => {
    console.log('this is formdata values',formData);

    let validationErrors = {};


    // Validate required fields
    // if (!formData.cityArea) {
    //   validationErrors.cityArea = 'City area is required';
    // } else if (formData.cityArea.trim().length !== formData.cityArea.length) {
    //   validationErrors.cityArea = 'City cannot contain spaces';
    // }
if(selectedOption !=='Delivery'){
    if (!formData.zipCode) {
      validationErrors.zipCode = 'Zip Code is required';
    } else if (formData.zipCode.trim().length !== formData.zipCode.length) {
      validationErrors.zipCode = 'Zip Code cannot contain spaces';
    }
  }

    if (!formData.state) {
      validationErrors.state = 'State is required';
    } else if (formData.state.trim().length !== formData.state.length) {
      validationErrors.state = 'State cannot contain spaces';
    }

    if (!formData.city) {
      validationErrors.city = 'City is required';
    } else if (formData.city.trim().length !== formData.city.length) {
      validationErrors.city = 'City cannot contain spaces';
    }

    if (selectedOption === 'Pick up'){

    
    // if (!formData.city2) {
    //   validationErrors.city2 = 'City is required';
    // } else if (formData.city2.trim().length !== formData.city2.length) {
    //   validationErrors.city2 = 'City cannot contain spaces';
    // }

    // if (!formData.state2) {
    //   validationErrors.state2 = 'State is required';
    // } else if (formData.state2.trim().length !== formData.state2.length) {
    //   validationErrors.state2 = 'State cannot contain spaces';
    // }
  }


    if (selectedOption === 'Delivery' || selectedOption === 'Both') {
      // Check if deliveryTime is provided
      if (!formData.deliveryTime) {
        validationErrors.deliveryTime = 'Delivery time is required';
      }
      // Check if deliveryTime contains leading or trailing spaces
      else if (formData.deliveryTime.trim().length !== formData.deliveryTime.length) {
        validationErrors.deliveryTime = 'Delivery time cannot contain spaces';
      }
    
      if (!formData.deliveryPack) {
        validationErrors.deliveryPack = 'Delivery pack is required';
      }
      // Check if deliveryTime contains leading or trailing spaces
      // else if (formData.deliveryTime.trim().length !== formData.deliveryTime.length) {
      //   validationErrors.deliveryPack = 'Delivery pack cannot contain spaces';
      // }
    }

    // Check if deliveryCharges validation should run only when isChecked is true
    if (isChecked) {
      if (!formData.deliveryCharges) {
        validationErrors.deliveryCharges = 'Delivery charges is required';
      } 
      else if (formData.deliveryCharges.toString().trim().length !== formData.deliveryCharges.toString().length) {
        validationErrors.deliveryCharges = 'Delivery charges cannot contain spaces';
      }
    }
    

    if (termsChecked === 'false') { // Validate terms checkbox
      toast.error('Please accept the Terms and Privacy Policy to proceed.');
      validationErrors.terms = 'Please select Terms and Privacy Policy';

    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;


  }

  const saveBakcData = () => {
    const values = formData
    values.selectedOption = selectedOption
    values.isChecked = isChecked
    values.addressId = selectedAddressId
    dispatch(insertProductData(values))
  }

  const updateReduxData = (key, value) => {
    const updatedValues = { [key]: value };
    dispatch(insertProductData(updatedValues));
  };
  
  useEffect(() => {type === 'edit' && updateReduxData('selectedOption', selectedOption)}, [selectedOption]);
  useEffect(() => {type === 'edit' && updateReduxData('address_id', selectedAddressId)}, [selectedAddressId]);

  useEffect(() => {
    if(type === 'edit'){
    dispatch(insertProductData(formData));
    }
  }, [formData]);

  const handleSave = async (type) => {
    console.log('deliveru time', formData);
    const values = formData
    if(values.deliveryTime === undefined){
      values.deliveryTime = data?.deliveryTime
      // values.deliveryTime = data?.delivery_time
      console.log('delivery value', values?.deliveryTime);
    }
    if(values.deliveryCharges === undefined){
      values.deliveryCharges = data?.delivery_charge_per_mile
      console.log('delivery value', values?.deliveryCharges);
    }
    
    if (validateForm()) {
      if (selectedAddressId === null) {
        const val = {
          country: '',
          state: formData?.state,
          zip_code: formData?.zipCode,
          city: formData?.city,
          travel_area: formData?.cityArea,
          address: formData?.address
        }
        const response = await postApi('/address', val)
        if (response?.statusCode === 200) {
          setSelectedAddressId(response?.data?.id)
          values.addressId = response?.data?.id
        }
      }
      else{
        values.addressId = selectedAddressId
      }
      values.selectedOption = selectedOption
      values.isChecked = isChecked
      dispatch(insertProductData(values))
      if (type === 'draft') {
        if (selectedAddressId === null) {
          const val = {
            country: '',
            state: formData?.state,
            zip_code: formData?.zipCode,
            city: formData?.city,
            travel_area: formData?.cityArea,
            address: formData?.address
          }
          const response = await postApi('/address', val)
          if (response?.statusCode === 200) {
            setSelectedAddressId(response?.data?.id)
            values.addressId = response?.data?.id
          }
        }
        else{
          values.addressId = selectedAddressId
        }
        values.isChecked = isChecked
        values.selectedOption = selectedOption
        dispatch(insertProductData(values))

        setLoader(true)
        const resp = await submitForm('draft', values)
        setTimeout(() => {
          setLoader(false)
        }, 500);      }
      else {
        nextStep()
      }
    }
    else {
    }

  };


  const openTermsModal = ()=>{

    setTermslIsOpen(true)
  }
  const closeTermsIsOpen = () => {
    setTermslIsOpen(false)

  }


  useEffect(() => {
    const handleClick = () => setClassState(false);
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const handleOptionClick = (option) => {
    setSelectedOption(option); // Update the selected option
  };

  const handleTermsChange = (e) => {
    const values = formData
    values.termsChecked = e.target.checked
    setTermsChecked(e.target.checked); // Update terms checkbox state
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setHandleDayDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  
  return (
    <>
      <UserSidebar classState={classState} />
      <div className='right-main product'>
        <div className='session'>
            {type !== 'edit' &&
          <div className='header'>
            <>
            <h1>
              <button className="burger-menu" onClick={handleSidebarClick}>
                <img src={burger} alt='' />
              </button>
              Product
            </h1>

            <div className='top-buttons'>
              <button className='save-draft' onClick={() => handleSave('draft')} disabled={loader}>{loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Save to Draft</button>
              <button className='next' onClick={handleSave}>Next</button>
            </div>
            </>
          </div>
            }
          {type !== 'edit' &&
          <div className='active-links'>
            <button className='back' onClick={() => { setDraftData(true); saveBakcData(); prevStep() }}>
              <img src={leftArrow} alt='' />
            </button>
            <ActiveLinks step={step + 1} type="product" />
          </div>
}
          <div className='main'>
            <div className='row'>
              <div className='col-sm-7'>
                <div className='left define-offer-cs'>
                  <h1>Delivery and pick up</h1>
                  <div className='offer-diffent define-left pb-2'>
                    <div className='size-range'>
                      <h6 class="option-cs">Which delivery option do you prefer?</h6>
                      <div className='bottom-buttons three-btns'>
                        <ul>
                          <li>
                            <a className={selectedOption === 'Pick up' ? 'active' : ''} onClick={() => handleOptionClick('Pick up')}>
                              Pick up
                            </a>
                          </li>
                          <li>
                            <a className={selectedOption === 'Delivery' ? 'active' : ''} onClick={() => handleOptionClick('Delivery')}>
                              Delivery
                            </a>
                          </li>
                          <li>
                            <a className={selectedOption === 'Both' ? 'active' : ''} onClick={() => handleOptionClick('Both')}>
                              Both
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <p className='pt-3 delivery-haed-text'>Delivery and pick-up available within the city limits only.</p>
                    <div className='row mt-3'>
                    <div className='col-sm-12'>
                        <div className='form-group mt-0'>
                          <label htmlFor='cityArea'>City area</label>
                          <input type='text' className='form-control' id='cityArea' value={formData.cityArea || formData?.city} onChange={handleChange} placeholder="Downtown Austin, North Austin etc." />
                          {errors.cityArea && <div className='error'>{errors.cityArea}</div>}
                        </div>
                      </div>
                      
                            {getAddressData && getAddressData.length > 0 &&
                          <div className='address-find'>
                                  {getAddressData && getAddressData.length > 0 && (
                <ul className="address-dropdown">
                  {getAddressData.map((x, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        setFormData(prevData => ({
                          ...prevData,
                          country: x?.country,
                          state: x?.state,
                          zipCode: x?.zipCode,
                          city: x?.city,
                          cityArea: x?.description,
                        }))
                        setGetAddressData([]);

                      }}
                      className="suggestion-item"
                    >
                      {x.description}
                    </li>
                  ))}
                </ul>
              )}
              </div>
}
                    </div>
                    <div className='row'>
                      <div className={selectedOption !== "Delivery" ? 'col-sm-4' :'col-sm-6'}>
                        <div className='form-group mt-0'>
                          <label htmlFor="state">State</label>
                          <input type='text' className='form-control' id='state' value={formData.state} onChange={(e) => { setSelectedAddressId(null); handleChange(e) }} placeholder="Texas" />
                          {errors.state && <div className='error'>{errors.state}</div>}
                        </div>
                      </div>
                      <div className={selectedOption !== "Delivery" ? 'col-sm-4' :'col-sm-6'}>
                        <div className='form-group mt-0'>
                          <label>Your City</label>
                          <input type='text' className='form-control' id='city' value={formData.city} onChange={handleChange} placeholder="Austin" />
                          {errors.city && <div className='error'>{errors.city}</div>}
                        </div>
                      </div>
                      {selectedOption !== "Delivery" &&
                      <div className='col-sm-4'>
                        <div className='form-group mt-0'>
                          <label htmlFor='zipCode'>*Zip Code</label>
                          <input type='text' className='form-control' id='zipCode' value={formData.zipCode} onChange={handleChange} placeholder="Type Code" />
                          {errors.zipCode && <div className='error'>{errors.zipCode}</div>}
                        </div>
                      </div>
}
                    </div>
                    <div className='row'>
                      {/* <div className='col-sm-6'>
                        <div className='form-group mt-0'>
                          <label htmlFor='cityArea'>City area</label>
                          <input type='text' className='form-control' id='cityArea' value={formData.cityArea} onChange={handleChange} placeholder="Downtown Austin, North Austin etc." />
                          {errors.cityArea && <div className='error'>{errors.cityArea}</div>}
                        </div>
                      </div>
                      <div className='col-sm-6'>
                        <div className='form-group mt-0'>
                          <label htmlFor='zipCode'>*Zip Code</label>
                          <input type='text' className='form-control' id='zipCode' value={formData.zipCode} onChange={handleChange} placeholder="Type Code" />
                          {errors.zipCode && <div className='error'>{errors.zipCode}</div>}
                        </div>
                      </div> */}

                      {(selectedOption === "Delivery" || selectedOption === "Both") && (
                        <>
                            <label htmlFor='deliveryTime'>Delivery time</label>
                        <div className='col-sm-6'>
                          <div className='form-group mt-0'>
                            <input type='number' className='form-control' id='deliveryTime' value={formData.deliveryTime || data?.deliveryTime} onChange={(e)=>handleChange(e)} 
                            min="0" 
                            onKeyDown={(e) => {
                              // Prevent typing 0 or empty
                              if (e.key === '0' && (e.target.value === '' || e.target.value === '0')) {
                                e.preventDefault();
                              }
                            }}placeholder="1-3 hours" />
                            {errors.deliveryTime && <div className='error'>{errors.deliveryTime}</div>}
                          </div>
                        </div>
                        <div className='col-sm-6 selects-dayshours-set'>
                          <div className='form-group mt-0' onClick={()=>setHandleDayDropdown(!handleDayDropdown)}>
                          <input type="text" placeholder="Select" name="" class="form-control daily-weeks-selct-input" value={formData.deliveryPack}/>
                          {handleDayDropdown && <div class="selects-dayshours" ref={dropdownRef}>
                            <ul className='mb-0'>
                              <li className={formData?.deliveryPack==='hours'?`active`:''} onClick={(e) => {handleChanges('hours', 'deliveryPack','unit');setHandleDayDropdown(false)}}>Hours {formData?.deliveryPack==='hours' && <img src={greenTick} alt='' className='float-end' />}</li>
                              <li className={formData?.deliveryPack==='days'?`active`:''} onClick={(e) => {handleChanges('days', 'deliveryPack','unit');setHandleDayDropdown(false)}}>Days {formData?.deliveryPack==='days' && <img src={greenTick} alt='' className='float-end' />}</li>
                              {/* <li>Days</li> */}
                            </ul>
                          </div>}
                            {/* <label htmlFor='deliveryTime'></label> */}
                            {/* <select
                              className="form-control"
                              id="deliveryPack"
                              value={formData.deliveryPack}
                              onChange={(e) => handleChange(e, 'unit')}
                            >
                              <option value="" disabled>Select</option>
                              <option value="hours">Hours</option>
                              <option value="days">Days</option>
                            </select> */}
                            {errors.deliveryPack && <div className='error'>{errors.deliveryPack}</div>}
                          </div>
                        </div>
                        </>
                      )}

                      {(selectedOption === 'Pick up' || selectedOption==='Both') && (
                        <>
                          <div className='col-sm-12'>
                            <div className='form-group mt-0'>
                              <label htmlFor='address'>Address (Optional)</label>
                              <input type='text' className='form-control' id='address' value={formData.address} onChange={handleChange}
                              onKeyDown={(e) => {
                                // Prevent typing 0 or empty
                                if (e.key === '0' && (e.target.value === '' || e.target.value === '0')) {
                                  e.preventDefault();
                                }
                              }} placeholder="Street Name and Number, apartment number" />
                            </div>
{/* 
                            {getAddressData && getAddressData.length > 0 &&
                          <div className='address-find'>
                                  {getAddressData && getAddressData.length > 0 && (
                <ul className="address-dropdown">
                  {getAddressData.map((x, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        setFormData(prevData => ({
                          ...prevData,
                          country: x?.country,
                          state: x?.state,
                          zipCode: x?.zipCode,
                          city: x?.city,
                          address: x?.description,
                        }))
                        setGetAddressData([]);

                      }}
                      className="suggestion-item"
                    >
                      {x.description}
                    </li>
                  ))}
                </ul>
              )}
              </div>
} */}
                            <p className='my-0 pick-pera pb-4'>*Please note: Your precise address will be visible to users only after purchasing the offer, ensuring safety.</p>
                          </div>
                          {/* <div className='col-sm-6'>
                            <div className='form-group'>
                              <label htmlFor='state'>*State</label>
                              <input type='text' className='form-control' id='state2' value={formData.state2} onChange={handleChange} placeholder="Texas" />
                              {errors.state2 && <div className='error'>{errors.state2}</div>}
                            </div>
                          </div>
                          <div className='col-sm-6'>
                            <div className='form-group'>
                              <label htmlFor='city2'>*Your city</label>
                              <input type='text' className='form-control' id='city2' value={formData.city2} onChange={handleChange} placeholder="Austin" />
                              {errors.city2 && <div className='error'>{errors.city2}</div>}

                            </div>
                          </div> */}
                        </>
                      )}

                        {getAddress?.length > 0 && (
                                              <div className='col-md-12'>
                                                <h6 className='saveaddresss'>Your saved addresses</h6>
                                              </div>
                        )}
                      {getAddress?.length > 0 ? (
                        getAddress.slice(0, 3).map((addressData, index) => (
                          <div className='col-sm-6 address-cs' key={addressData.id}>
                            <div className={(selectedAddressId === addressData.id) ? 'session-border p-3 checke-green active' : 'session-border p-3 checke-green'}>
                              <div>
                                <label className='session-check'>
                                  <input
                                    type="radio"
                                    name="address"
                                    value={addressData.address}
                                    checked={selectedAddressId === addressData.id}  // Check if the current address is selected
                                    onChange={(e) => handleAddressChange(e, addressData.id)}  // Handle the toggle of the selected address
                                  />

                                  <span className='checkmark'></span>
                                  Address {index + 1}
                                </label>
                              </div>
                              <p>{addressData.address}, {addressData.city}, {addressData.state}, {addressData.zip_code}, {addressData.country}</p>
                            </div>
                          </div>
                        ))
                      ) : (
                          null
                      )}



                    </div>
                  </div>
                </div>

                {(selectedOption === "Delivery" || selectedOption === "Both") && (

                  <div className="offer-diffent delivery-cs">
                    <div className="setting-right">
                      <div className="switch-button">
                        <label className="switch">
                          <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
                          <span className={`slider ${isChecked ? 'active-slider' : ''}`}></span>
                        </label>
                      </div>
                      <h3>Delivery charges</h3>
                    </div>

                    {isChecked && (
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group minimum position-relative mb-0">
                            <label className="mb-1">Enter price per mile</label>
                            <input type="number" className="form-control m-0 w-100 ps-5" id="deliveryCharges" value={formData?.deliveryCharges || data?.delivery_price} onChange={handleChange} 
                            min="0" 
                            onKeyDown={(e) => {
                              // Prevent typing 0 or space at the beginning
                              if ((e.key === '0' && (e.target.value === '' || e.target.value === '0')) || e.key === ' ') {
                                e.preventDefault();
                              }
                            }}
                            placeholder="Enter price per mile" />
                            <img src={dollar} className="input-img" alt='' />
                          </div>
                          {errors.deliveryCharges && <div className='error'>{errors.deliveryCharges}</div>}
                        </div>
                      </div>
                    )}
                    <p className='pt-3'>
                      By selecting this option, you assign a shipping cost to the customer. Please enter a cost per mile.
                    </p>
                  </div>
                )}
               {type !=='edit' &&
               <section class="footer">
                  <label class="custom-checkbox">
                    <input type="checkbox" checked={termsChecked === true} onChange={handleTermsChange}/>
                    <span class="checkmark"></span>
                  </label>
                  <p>I agree to microcosm’s <span onClick={() => openTermsModal()}> Terms of Use and Privacy Policy </span></p>
                </section>}
                {/* <div className='form-agree'>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="terms" checked={termsChecked === true} onChange={handleTermsChange} />
                    <label className="form-check-label px-2 pt-0" htmlFor="terms">
                      I agree to microcosm’s <span onClick={() => openTermsModal()}>Terms of Use and Privacy Policy</span> 
                    </label>
                   {errors.terms && <div className='error'>{errors.terms}</div>}
                  </div>
                </div> */}
              </div>

              {type !== 'edit' &&
              <div class="col-sm-4 col-xl-5">
                <div class="right second">
                  <div class="offering-detail">
                    <h1>Offering details</h1>
                    <div class="right-social">
                    </div></div>
                  <ul>
                    <div class="detail-top">
                      <li><span>Type</span> <strong>{data?.productType
                        ? data.productType.charAt(0).toUpperCase() + data.productType.slice(1)
                        : ''}</strong> </li>
                      <li><span> Availability</span> <strong>{(data?.availability === 'ready' ? 'Ready for sale' : 'To order')}</strong> </li>
                      {/* <li><span>Type(what is it)</span> <strong></strong> </li> */}
                      <li><span>Tags</span> <strong>{data?.tagName.map(x => (x?.name || x?.tag_name)).join(", ")}</strong> </li>
                    </div>
                    <div class="">
                      <li><span>Delivery option</span><strong>{selectedOption || data?.selectedOption}</strong> </li>
                      <li className="mb-0"><span>City</span> <strong>{formData?.city}</strong> </li>
                    </div>

                  </ul>
                </div>
              </div>
}
            </div>
          </div>
        </div>
        <Modal
                isOpen={termslIsOpen}
                onRequestClose={closeTermsIsOpen}
                ariaHideApp={false}
                shouldCloseOnOverlayClick={false}
                overlayClassName={`modal-overlay terms-condition`}
            >
              
              <button className='close-button' onClick={() => closeTermsIsOpen()}><img src={cross} alt='' /></button>
              <TermsAndPolicy  setTerms={setTermsChecked} closeTermsIsOpen={closeTermsIsOpen}></TermsAndPolicy>
                
            </Modal>
      </div>
    </>
  );
}

export default DeliverPickupProductOffering;
