import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { postApi } from '../../../../Services/apiService';
import { clearProductData, insertProductData } from '../../../../Counter/productSlice';
import DefineProductOffering from './defineProductOffering';
import DeliverPickupProductOffering from './deliverPickup';
import DefineOffering from '../defineOffering';
import ProductDetailOffering from './productDetailOffering';



function ProductOfferingStep() {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const formState = useSelector((state) => state.productCounter);

  const [loader, setLoader] = useState(false)
  const [switchType, setSwitchType] = useState('')
  const [step, setStep] = useState(formState?.step || 1);
  const [draftData, setDraftData] = useState(false)
  const productType = formState?.productType
  useEffect(() => {
  }, [formState, step, switchType]);
  const nextStep = (val) => {
    if (val === 'productType') {
      setStep(step)
    }
    else {

      setStep(step + 1);

    }
  };

  const prevStep = (val) => {
    setStep(step - 1);
  };
  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const submitForm = async (offerStatus, valu) => {
console.log('this is value----------valu',valu);
    await delay(1000);

    let productType = formState?.productType === 'digital'
      ? '1'
      : (valu?.productType === 'digital' ? '1' : '0');

    let availability = formState?.availability === 'order'
      ? '1'
      : (valu?.availability === 'order' ? '1' : '0');

    let pricing_type = formState?.discoveryPrice === 'fixed_price'
      ? 1
      : (valu?.discoveryPrice === 'fixed_price' ? 1 : 2);

      let selectedOption;

if(!valu){
  if (formState?.selectedOption === 'Pick up') {
    selectedOption = 1;
  } else if (formState?.selectedOption === 'Both') {
    selectedOption = 3;
  }  else if (formState?.selectedOption === 'Delivery') {
    selectedOption = 2;
  }  

}
else {
  selectedOption = valu?.selectedOption === 'Pick up' ? 1
    : valu?.selectedOption === 'Delivery' ? 2
    : valu?.selectedOption === 'Both' ? 3
    : 1; // Default to 1 if valu?.selectedOption is undefined
}

      

    let deliveryChargesStatus = formState?.isChecked === false
      ? 0
      : (valu?.isChecked === false ? 0 : 1);
    let formData = new FormData()
    console.log('formData>>>>>>>>>>>>>>>.', formState)
    formData.append('title', formState?.title || valu?.title || '');
    formData.append('description', formState?.description || valu?.description || '');
    formData.append('product_kind', productType);
    formData.append('product_availbility', availability);
    formData.append('quantity_items', formState?.quantityOrders || valu?.quantityOrders || '');
    formData.append('maximum_quantity_order', formState?.Maxquantity || valu?.Maxquantity || '');
    formData.append('processing_time', formState?.processing_time || valu?.processing_time || '');
    formData.append('additional_processing_time', formState?.additional_unit || valu?.additional_unit || '');
    formData.append('pricing_type', pricing_type);
    formData.append('minimum_price', formState?.discoverMinPrice || valu?.discoverMinPrice || '');
    formData.append('maximum_price', formState?.discoverManPrice || valu?.discoverManPrice || 0);
    formData.append('message_to_user', formState?.messageUser || valu?.messageUser || '');
    formData.append('delivery_option', selectedOption );
    formData.append('product_address', formState?.address || valu?.address || '');
    formData.append('delivery_price', Number(formState?.deliveryCharges) || Number(valu?.deliveryCharges) || '');
    formData.append('address_id', formState?.addressId || valu?.addressId || formState?.address_id || '');
    // formData.append('delivery_time', formState?.deliveryTime || valu?.deliveryTime || '');
    formData.append('delivery_time', (formState?.deliveryTime ? formState?.deliveryTime + ' '+ formState?.deliveryPack : valu?.deliveryTime +  ' '+valu?.deliveryPack) || '');
    formData.append('delivery_charge', deliveryChargesStatus);
    formData.append('deleted_ids', formState?.deleted_ids || valu?.deleted_ids || '');
    formData.append('location', formState?.address || valu?.address || '');
    if (offerStatus === 'draft') {
      formData.append('status', 3);
    } else {
      formData.append('status', 1);
    }
    formData.append('step', step);
    formData.append('service_type', 2);
    formData.append('digital_link', formState?.link || valu?.link || '');
    formData.append('product_tag', formState?.tags || valu?.tags || '');
// Function to check if an image URL should be empty
// const isImageEmpty = (img) => img && typeof img === 'string' && img.includes('https://microcosmmtest.s3.us-east-1.amazonaws.com');
const isImageEmpty = (img) => img && typeof img === 'string' && img.startsWith('https://microcosmmtest.s3.us-east-1.amazonaws.com');

// Append the product image to formData
formData.append(
  'product_image',
  isImageEmpty(formState?.imagePreview) || isImageEmpty(valu?.imagePreview) || 
  isImageEmpty(formState?.cover_image) || isImageEmpty(valu?.cover_image) 
    ? '' 
    : (formState?.imagePreview || valu?.imagePreview || formState?.cover_image || valu?.cover_image || '')
);

    // formData.append('product_image', formState?.imagePreview || valu?.imagePreview || formState?.cover_image || valu?.cover_image || '');
    formData.append('id', formState?.product_id || valu?.product_id || formState?.id || '');
    // Append digital files if available
    if (formState?.digital_file?.length > 0) {
      formState.digital_file.forEach((file, index) => {
        formData.append(`digital_media[${index}]`, file);
      });
    } else if (valu?.digital_file?.length > 0) {
      valu.digital_file.forEach((file, index) => {
        formData.append(`digital_media[${index}]`, file);
      });
    }

    // Append additional images if available
    if (formState?.additionalImagesPreview?.length > 0) {
      formState.additionalImagesPreview.forEach((file, index) => {
        formData.append(`product_media[${index}]`, file);
      });
    } else if (valu?.additionalImagesPreview?.length > 0) {
      valu.additionalImagesPreview.forEach((file, index) => {
        formData.append(`product_media[${index}]`, file);
      });
    } else if (formState?.offerMaterials?.length > 0) {
      formState.offerMaterials.forEach((material, index) => {
        if (material.media_type === 'image') {
          formData.append(`product_media[${index}]`, material.file_url);
        }
      });
    } else if (valu?.offerMaterials?.length > 0) {
      valu.offerMaterials.forEach((material, index) => {
        if (material.media_type === 'image') {
          formData.append(`product_media[${index}]`, material.file_url);
        }
      });
    }
    let response;
    if (offerStatus === 'draft') {
      console.log('this is before api call', formState);
      console.log('this is before api call value----0000000', valu);
      response = await postApi('/creator/product/draft', formData);
      if (response?.statusCode === 200) {
        const val = {
          product_id: response?.data?.id || ''
        }

        dispatch(insertProductData(val))
        return true;
      }
      else {
        return false
      }
    }
    else {
      response = await postApi('/creator/product', formData);
      if (response?.statusCode === 200) {
        dispatch(clearProductData())
        const product_view_image = {
          product_view_image: response?.data?.cover_image
        }
        dispatch(insertProductData(product_view_image))
        return true;
      } else {
        return false;
      }
    }
  };

  switch (step) {
    case 1:
      // return <DeliverPickupProductOffering nextStep={nextStep} prevStep={prevStep} step={step} data={formState} submitForm={submitForm} setDraftData={setDraftData}/>;

      return <DefineProductOffering nextStep={nextStep} step={step} submitForm={submitForm} data={formState} draftData={draftData} />;
    case 2:
      switch (productType) {
        case 'digital' || '1':
          return <ProductDetailOffering nextStep={nextStep} prevStep={prevStep} step={step} data={formState} submitForm={submitForm} setDraftData={setDraftData} />;
        case 'physical' || '0':
          return <DeliverPickupProductOffering nextStep={nextStep} prevStep={prevStep} step={step} data={formState} submitForm={submitForm} setDraftData={setDraftData} />;

      }

    // return <DeliverPickupProductOffering nextStep={nextStep} prevStep={prevStep} step={step} data={formState} submitForm={submitForm} setDraftData={setDraftData}/>;
    case 3:
      return <ProductDetailOffering nextStep={nextStep} prevStep={prevStep} step={step} data={formState} submitForm={submitForm} setDraftData={setDraftData} />;

    default:
      return <div>Unknown step</div>;
  }
};
export default ProductOfferingStep
