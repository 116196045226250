import React, { useEffect, useState } from 'react'
import bannerRight from '../../../assets/images/dummy-profile.jpg'
import share from '../../../assets/images/share.svg'
import heart from '../../../assets/images/heart2.svg'
import clock from '../../../assets/images/clock.svg'
import calendar from '../../../assets/images/calendar.svg'
import people from '../../../assets/images/people.svg'
import mapPin from '../../../assets/images/map-pin.svg'
import productImg from '../../../assets/images/product-img.png'
import wellness from '../../../assets/images/wellness.svg'
import arrow from '../../../assets/images/arrow.svg'
import Slider from 'react-slick';
import UserSidebar from '../../../Layout/userSidebar'
import burger from '../../../assets/images/burger-menu.svg'
import { useParams } from 'react-router-dom'
import { getApi,postApi } from '../../../Services/apiService'
import Modal from 'react-modal';
import BookingModal from './bookingModal'
import cross from '../../../assets/images/cross-button.svg'
import graycross from '../../../assets/images/gray-cross.svg'
import crosssuccess from '../../../assets/images/cross-success.svg'
import heart_filled_black from '../../../assets/images/heart_filled_black.svg'
import arrowBtn from '../../../assets/images/arrow-btn.svg';
import { useNavigate } from 'react-router-dom'
import location from '../../../assets/images/map-pin1.svg'
import dummyProfile from '../../../assets/images/dummy-profile.jpg'
import discoveryCall from '../../../assets/images/discovery-call.svg'
import checkTravel from '../../../assets/images/check-travel.svg'
import session from '../../../assets/images/session-calender.svg'
import PaymentModal from './payment'
import CheckoutSuccess from './checkoutSuccess'
import hearts from '../../../assets/images/heart.svg'
import CustomBookingModal from './customBookingModal'
import { stripePromise } from '../../../Config/stripeService';  // Import stripePromise from the service
import { Elements } from '@stripe/react-stripe-js';
import BookConsultation from '../CreatorListing/bookConsultation'
import MonthlyBookingModal from './monthlyBookingModal'
import CalendarLook from './calendarLook'
import rightTick from '../../../assets/images/right-tick-mark.svg'
import ConfirmLocation from './confirmLocation'
import leftArrow from '../../../assets/images/back-btns.svg'
import CaroselImage from '../../CreatorComponents/CreatorOffering/caroselImage'
import greencross from '../../../assets/images/cross-button.svg'
import OneYear from './oneYear'



function SingleOfferingData() {

    const [classState, setClassState] = useState(false)
    const [userDetails, setUserDetails] = useState([])
    const [isModal, setIsModal] = useState(false)
    const [customBookingModal, setCustomBookingModal] = useState(false)
    const [monthlyBookingModal, setMonthlybookingModal] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [filtersModal, setFiltersModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [showHideComp, setshowHideComp] = useState(false);
    const [similarClass, setSimilarClass] = useState([])
    const [dataToPaymentModal, setDataToPaymentModal] = useState('')
    const [discoveryModal,setDiscoveryModal]= useState(false)
    const [isModalLook,setIsModalLook] = useState(false);
    const [confirmLocation,setConfirmLocation] = useState(false)
    const [locationModal,setLocationModal] = useState(false)
    // const [paymentModal,setPaymentModal] = useState(false)
    // const [confirmLocationModal,setConfirmLocationModal] = useState(false)
    // const [locationModal,setLocationModal] = useState(false)
    const [locationProvide,setLocationProvide] = useState(false)
    const [addressId,setAddressId] = useState('')
    const [pageLoader,setPageLoader] = useState(false)
    const [startIndex,setStartIndex]= useState(null)
    const [oneYear,setOneYear] = useState(false)

    const userROle = localStorage.getItem('user_role')


    const openModal = (src,index) => {
        setModalContent(src);
        setModalIsOpen(true);
        setStartIndex(index)
    };

    const closeImgModal = () => {
        setModalIsOpen(false);
        setModalContent('');
    };
    console.log('dataToPaymentModaldataToPaymentModaldataToPaymentModal',dataToPaymentModal);

    const [isExpanded, setIsExpanded] = useState(false);

    const toggleText = () => {
        setIsExpanded(!isExpanded);
    };

    // Ensure description is defined and is a string
    const description = userDetails?.description || '';
    const isLongDescription = description.length > 300;
    const truncatedDescription = description.length > 300 ? `${description.slice(0, 300)}...` : description;

    const params = useParams();
    console.log("params>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", params?.id)

    useEffect(() => {
        const currentUrl = window.location.href;

        // Check if the URL contains 'creator/offering'
        if (currentUrl.includes('creator/offering')) {
            setshowHideComp(true)
        }
        fetchDetails();

    }, [params])

    const navigate = useNavigate();
    const singleService = (id) => {
        if (userROle === 'creator') {
            navigate(`/creator/offering/home`)
        }
        if (userROle === 'user') {
            navigate(`/user/offering/${id}`)
        }
    }
    const fetchDetails = async (type) => {
        if(type!=='favourite'){
        setPageLoader(true)
        }
        const response = await getApi(`/user/Service?offerId=${params?.id}`)
        if (response?.statusCode === 200) {
            setUserDetails(response?.data)
        }
        setPageLoader(false)
    }

    // Fetch similar classes once userDetails.id is available
    useEffect(() => {
        if (userDetails?.id) {
            similarClasses();
        }
    }, [userDetails?.id]); // This effect will trigger when userDetails.id is updated


    const similarClasses = async () => {
        const response = await getApi(`/user/similar-class?userId=${userDetails?.userData?.id}`)
        if (response?.statusCode === 200) {
            setSimilarClass(response?.data)
        }
    }
    const handleFavourite = async (val) => {
        console.log('this is value', val);

        const data = {
            status: val?.isFavorite === 0 ? '1' : '0', // Conditional check to toggle the favorite status
            offerId: val?.id
        };

        try {
            // Optimistically toggle isFavorite
            val.isFavorite = val.isFavorite === 0 ? 1 : 0;

            // Trigger a re-render to reflect the change in UI

            const response = await postApi('favorite', data);
            if (response?.statusCode === 200) {
                fetchDetails('favourite')
            similarClasses();



                // Optionally re-fetch the offerings if needed
            }
        } catch (error) {
            console.error('Error fetching offerings:', error);
        }
    };
    const settings = {
    dots: true,
    infinite: userDetails?.otherClassess?.length > 1 && true, // Disable infinite scroll for 1 item
    speed: 1500,
    slidesToShow: userDetails?.otherClassess?.length > 3 ? 3 : 1, // Adjust slides to show based on data length
    slidesToScroll: userDetails?.otherClassess?.length > 3 ? 3 : 1, // Adjust slides to scroll
    // centerMode: userDetails?.otherClassess?.length >2 ? false:true, // Enable center mode for a single slide
    // centerPadding: userDetails?.otherClassess?.length === 1 ? "30%" : "0", 
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: userDetails?.otherClassess?.length > 3 ? 3 : 1,
                slidesToScroll: userDetails?.otherClassess?.length > 3 ? 3 : 1,
                infinite: userDetails?.otherClassess?.length > 1 && true,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: userDetails?.otherClassess?.length > 2 ? 2 : 1,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};
    const settingsSimilar = {
        dots: true,
        infinite: true,
        speed: 1500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const closeModal = () => {
        setIsModal(false)
        // fetchDetails()
    }
    const closeModalOneYear = () => {
        setOneYear(false)
        // fetchDetails()
    }
    const closeCustomBookingModal = () => {
        setCustomBookingModal(false)
        // fetchDetails()
    }
    const closeMonthlyBookingModal = () => {
        setMonthlybookingModal(false)
        // fetchDetails()
    }
    useEffect(() => {
        const handleClick = () => setClassState(false);

        document.addEventListener('click', handleClick);

        // Cleanup event listener on component unmount
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [])
    const openBookingModal = () => {
        // if(userDetails?.availbility_period!=='1'){
        if ((userDetails?.session_offer_type === '2' && userDetails?.session_type === '1') && (userDetails?.package_type === '3') ) {
            setCustomBookingModal(true)
        }
        else if (((userDetails?.session_offer_type === '2' && userDetails?.session_type === '1') && (userDetails?.availbility_period === '1') && userDetails?.package_type === '3')){
            setCustomBookingModal(true)
        }
        else if((userDetails?.session_offer_type === '2' && userDetails?.session_type === '1') && (userDetails?.package_type === '2') ){
            setMonthlybookingModal(true)
        }
        else if((userDetails?.session_offer_type === '2' && userDetails?.session_type === '1') && (userDetails?.package_type === '1' && userDetails?.package_period === 'months')){
            setMonthlybookingModal(true)
        }
        else {
            setIsModal(true)
        }
    // }
    // else(userDetails?.availbility_period==='1'){
    //     setOneYear(true)
    // }
    }

    const handleSidebarClick = (event) => {
        event.stopPropagation();
        setClassState(!classState);
    };
const back = () => {

    navigate('/user/offering')

}

const handleDiscoveryCall = () =>{
    setDiscoveryModal(true)
}
const closeDiscoveryModal = () =>{
    setDiscoveryModal(false)
}

const handleBackArrow = () =>{
    if ((userDetails?.session_offer_type === '2' && userDetails?.session_type === '1') && (userDetails?.package_type === '3') ) {
        setCustomBookingModal(true)
        setConfirmLocation(false)
    }
    else if (((userDetails?.session_offer_type === '1' && userDetails?.session_type === '1') && (userDetails?.availbility_period === '1') )){
        setCustomBookingModal(true)
        setConfirmLocation(false)
    }
    else if((userDetails?.session_offer_type === '2' && userDetails?.session_type === '1') && (userDetails?.package_type === '2') ){
        setMonthlybookingModal(true)
        setConfirmLocation(false)
    }
    else {
        setIsModal(true)
        setConfirmLocation(false)
    }
}

const getPortalClassName = (userDetails) => {
    if (!userDetails) return "single-booking-ui-set"; // Default class if userDetails is undefined

    const { session_offer_type, session_type, pricing_type,package_type,package_period } = userDetails;

    if(pricing_type === '1'){
        return "fixed-price-one";
    }
    if(pricing_type === '2'){
        return "variable-price-one";
    }
    if (session_offer_type === '1' && session_type === '2' && pricing_type === '1') {
        return "group-fixed";
    }

    if (session_offer_type === '1' && session_type === '2' && pricing_type === '2') {
        return "group-variable";
    }

    if (session_offer_type === '2' && session_type === '1' && pricing_type === '2' && package_type === '3'	) {
        return "custom-variable";
    }

    if (session_offer_type === '1' && session_type === '1' && pricing_type === '1'	) {
        return "individual-session-single-session-fixed-price";
    }
    if (session_offer_type === '1' && session_type === '1' && pricing_type === '2'	) {
        return "individual-session-single-session-variable-price";
    }

    if (session_offer_type === '2' && session_type === '1'&& package_period	 === 'day' && pricing_type === '1'	) {
        return "individual-session-multiple-day-fixed-price";
    }
    
    if (session_offer_type === '1' && session_type === '1' && package_period === 'day' && pricing_type === '2'	) {
        return "individual-session-multiple-day-variable-price";
    }
    if (session_offer_type === '2' && session_type === '1' && package_period === 'day' && pricing_type === '2'	) {
        return "individual-session-multiple-day-variable-price-diff";
    }

    if (session_offer_type === '2' && session_type === '1'&& package_period	 === 'weeks' && pricing_type === '1'	) {
        return "individual-session-multiple-week-fixed-price";
    }
    
    if (session_offer_type === '1' && session_type === '1' && package_period === 'weeks' && pricing_type === '2'	) {
        return "individual-session-multiple-week-variable-price";
    }
    if (session_offer_type === '2' && session_type === '1'&& package_period	 === 'month' && pricing_type === '1'	) {
        return "individual-session-multiple-month-fixed-price";
    }
    
    if (session_offer_type === '2' && session_type === '2' && package_period === 'day' && package_type === '4'	&& pricing_type === '1'	) {
        return "Group-multiple-daily-package-day-fixed-price";
    }

    if (session_offer_type === '2' && session_type === '2' && package_period === 'day'  && package_type === '4' && pricing_type === '2'	) {
        return "Group-multiple-daily-package-day-variable-price";
    }
    if (session_offer_type === '2' && session_type === '2' && package_period === 'weeks' && package_type === '4'	&& pricing_type === '1'	) {
        return "Group-multiple-daily-package-day-fixed-price";
    }

    if (session_offer_type === '2' && session_type === '2' && package_period === 'weeks'  && package_type === '4' && pricing_type === '2'	) {
        return "Group-multiple-daily-package-day-variable-price";
    }
    if (session_offer_type === '2' && session_type === '2' && package_period === 'months' && package_type === '4'	&& pricing_type === '1'	) {
        return "Group-multiple-daily-package-day-fixed-price";
    }

    if (session_offer_type === '2' && session_type === '2' && package_period === 'months'  && package_type === '4' && pricing_type === '2'	) {
        return "Group-multiple-daily-package-day-variable-price";
    }

    if (session_offer_type === '2' && session_type === '2' && package_period === 'weeks'  && package_type === '1' && pricing_type === '1'	) {
        return "Group-Session-Multiple-Sessions-Weekly-package-fixed-price";
    }
    
    if (session_offer_type === '2' && session_type === '2' && package_period === 'weeks'  && package_type === '1' && pricing_type === '2'	) {
        return "Group-Session-Multiple-Sessions-Weekly-package-variable-price";
    }

    if (session_offer_type === '2') {
        return "multiple-booking-ui-set";
    }

    return "single-booking-ui-set"; // Default class if no conditions match
};
    return (

        <div class="creators-detail offering-user-side">
            <UserSidebar classState={classState} />
            {pageLoader ?
                <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                :
            <div className='discover-offering right-main dicovery-views-offers'>
                <section class="creators-top">
                    <h2 onClick={() => back()}><span><img src={arrowBtn} /> {userDetails?.title}</span></h2>

                    <div class="row">
                        <div class="col-sm-7 col-xl-8 col-lg-7">
                            <div class="offering-left">
                                <h3><button class="burger-menu" onClick={handleSidebarClick}><img src={burger} alt='' /></button></h3>
                                {userDetails?.tags && userDetails?.tags?.length > 0 && userDetails?.tags?.map((x, index) => {
                                    return (
                                        <label key={index}>{x?.tag_name}</label>
                                    )
                                })}
                                {/* <label>Yoga</label>
                                    <label>Fitness</label>
                                    <label>Meditation</label> */}

                                <div class="annete">
                                    <span><img src={userDetails?.userData?.user_profile_image || dummyProfile} alt='' /></span>
                                    <div class="annete-text">
                                        <h3>{userDetails?.userData && userDetails?.userData?.name}</h3>
                                        {userDetails?.userData?.specializations.length ? (
                                            <>
                                                <h4 className={userDetails?.userData?.specializations[0]?.area_of_work === '' && 'area-of-work'}>
                                                    {userDetails?.userData?.specializations[0]?.area_of_work}
                                                </h4>
                                                <h5 className={userDetails?.userData?.specializations[0]?.experience === '' && 'area-of-work'}>
                                                    {userDetails?.userData?.specializations[0]?.experience} of experience
                                                </h5>
                                            </>) :
                                            <>
                                            </>
                                        }
                                        {userDetails?.discovery_call === '1' && <button class="discovery" onClick={()=>handleDiscoveryCall()}>Discovery call <img src={discoveryCall} /></button>}
                                    </div>
                                </div>
                                <div className="about-left">
                                    <h3>About Class</h3>
                                    <p>
                                        {isExpanded || !isLongDescription ? description : truncatedDescription}
                                        <br />
                                        {isLongDescription && (
                                            <strong onClick={toggleText} style={{ cursor: 'pointer' }}>
                                                {isExpanded ? 'Read less' : 'Read more'}
                                            </strong>
                                        )}
                                    </p>
                                </div>

                            </div>
                            {(userDetails.offerMaterials?.length || userDetails.cover_image) ? (
                            <>
                                <div className=" mt-4 publish-after-offer">
                                    <div className="row">
                                        <div className="col-sm-10 col-xl-10 col-lg-10">
                                            {userDetails.cover_image && (
                                                <>
                                                <div className='main-image offiering-img-cs'>
                                                    <img
                                                        src={userDetails.cover_image}
                                                        alt="Main"
                                                        onClick={() => openModal(userDetails?.cover_image,0)}
                                                    />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        <div className="col-sm-2 col-xl-2 col-lg-2">
                                            <div className='jewellery-img-height image'>
                                        <div className="row">
                                            {userDetails.offerMaterials && userDetails.offerMaterials.map((material, index) => (
                                                material.media_type === 'image' ? (
                                                    // <div className="gallery-small" key={index} onClick={() => openModal(material.file_url)}>
                                                    <div className="col-6 col-lg-12 col-sm-12" key={index}>
                                                    <div className="gallery-small-one" key={index} onClick={() => openModal(material?.file_url,index+1)}>
                                                        <img src={material.file_url} alt={`Gallery ${index + 1}`} />
                                                    </div>
                                                    </div>
                                                ) : material.media_type === 'video' ? (
                                                    // <div className="gallery-small" key={index} onClick={() => openModal(material.file_url)}>
                                                    <div className="col-6 col-lg-12 col-sm-12 mb-3" key={index} onClick={() => openModal(material?.file_url,index+1)}>
                                                    <div className="gallery-small-one" key={index}>
                                                        <video controls>
                                                            <source src={material.file_url} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    </div>
                                                    </div>
                                                ) : null
                                            ))}
                                          
                                            </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </>

                        ) :
                            <></>
                        }
                        </div>

                        <div class="col-sm-5 col-xl-4 col-lg-5">
                            <div class="offering-top-right">
                            <div className='row'>
                            <div className='col-md-10'>
                                <h2>{userDetails?.name || 'Morning Yoga'}</h2>
                                {/* <h2>{userDetails?.offer_name || 'Morning Yoga'}</h2> */}
                                </div>
                                <div className='col-md-2'>
                                <div class="offering-share">
                                    <ul>
                                        <li><a><img src={share} alt='' /></a></li>
                                        <li onClick={() => { handleFavourite(userDetails) }}><a>
                                        <img src={userDetails?.isFavorite === 1 ? heart_filled_black : heart} alt='' />
                                            </a></li>
                                    </ul>
                                </div>
                                </div>
                                </div>
                                <div class="week">
                                    <ul>
                                        {(userDetails?.session_type !== '2' && userDetails?.session_offer_type !== 2) && (
                                            <li><img src={clock} alt='' /> {userDetails?.sessionDuration} min</li>
                                        )}
                                        {(userDetails?.session_type === '1' && userDetails?.session_offer_type == 1) && (
                                            <li><img src={session} alt='' /> 1 session</li>
                                        )}
                                        {(userDetails?.session_type === '1' && userDetails?.session_offer_type == 2 && userDetails?.package_type == 3) && (
                                            <li><img src={session} alt='' /> {userDetails?.no_of_sessions} session</li>
                                        )}
                                        {userDetails?.session_type === '1' && userDetails?.session_offer_type == 2 && userDetails?.package_type === '1' && (
                                            <li><img src={calendar} alt='' /> {userDetails?.no_of_sessions}x per {userDetails?.package_period}, {userDetails?.package_timeframe} {userDetails?.package_period}</li>
                                        )
                                        }
                                         {userDetails?.session_type ==='2' &&
                                        <li><img src={calendar} alt='' /> {userDetails?.totaldates} days</li>
                                        }
                                    </ul>
                                    {/* <ul>
                                        {userDetails?.session_type ==='2' &&
                                        <li><img src={calendar} alt='' /> {userDetails?.totaldates} days</li>
                                        }
                                    </ul> */}
                                </div>
                                <div class="week">
                                    <ul>
                                        <li><img src={people} alt='' /> {userDetails?.session_type === '1' && userDetails?.session_offer_type == 1 ? (
                                            <p>Individual Session</p>
                                        ) : userDetails?.session_type === '1' && userDetails?.session_offer_type == 2 ? (
                                            <p>Individual Multiple sessions</p>
                                        ) : userDetails?.session_type === '2' && userDetails?.session_offer_type == 1 ? (
                                            <p>{(userDetails?.max_group_size - userDetails?.seats) || '0'} booked, {userDetails?.max_group_size} max </p>
                                        ) : userDetails?.session_type === '2' && userDetails?.session_offer_type == 2 ? (
                                            <p>{(userDetails?.max_group_size - userDetails?.seats) || '0'} booked, {userDetails?.max_group_size} max </p>
                                        ) : null}</li>

                                        {/* <li><img src={mapPin} alt='' /> Broome St, New York</li>
                                        <li><img src={checkTravel} alt='' /> Creator can travel</li> */}
                                    </ul>
                                </div>
                                {(userDetails?.location_type == '3' && userDetails?.location_type =='2') || (userDetails?.travel_area!='' && userDetails?.travel_area != null) &&
                                <div className='week'>
                                    <ul>
                                        {/* <li><img src={people} alt='' /> {userDetails?.session_type === '1' ? 'Individual Session' : 'Group Session'}</li> */}
                                    <li>{userDetails?.location_type !== '1' && <><img src={mapPin} alt=''/> {userDetails?.address?.address + ' '+ userDetails?.address?.city} </> }</li>
                                    <li>{userDetails?.travel_area!='' && userDetails?.travel_area != null && <> <img src={checkTravel} alt=''/> Creator can travel</>}</li>
                                    </ul>
                                </div>
}
                                 {/* {userDetails?.travel_charge_fee !==''&& <><br/> <img src={rightTick} alt=''/> Creator can travel</>} */}

                                <div class="offering-right-bottom">
                                    {userDetails?.pricing_type === '1' ? 
                                    <h4>Price <strong>${userDetails?.price || userDetails?.minimum_price || userDetails?.maximum_price}</strong></h4>
                                            :
                                    <h4>Price <strong>${userDetails?.minimum_price}-${userDetails?.maximum_price}</strong></h4>

                                }
                                    {((((userDetails?.session_offer_type === '2' && userDetails?.session_type === '2')||(userDetails?.session_offer_type === '1' && userDetails?.session_type === '2')) && userDetails?.seats === 0)) ? (
                                        <button>Session slots are full</button>
                                    ):(
                                        <button onClick={() => { setAddressId('');openBookingModal() }}>{userDetails?.session_type ==='2' ? 'View' : 'Select'} time slots</button>

                                    )
                                    }
                                </div>
                            </div>
                        </div>
                        {/* {(userDetails.offerMaterials?.length || userDetails.cover_image) ? (
                            <>
                                <div className="col-sm-8 mt-4 publish-after-offer">
                                    <div className="row">
                                        <div className="col-sm-10 col-xl-10 col-lg-10">
                                            {userDetails.cover_image && (
                                                <>
                                                <div className='main-image offiering-img-cs'>
                                                    <img
                                                        src={userDetails.cover_image}
                                                        alt="Main"
                                                        onClick={() => openModal(userDetails?.cover_image,0)}
                                                    />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        <div className="col-sm-2 col-xl-2 col-lg-2">
                                            <div className='jewellery-img-height image'>
                                        <div className="row">
                                            {userDetails.offerMaterials && userDetails.offerMaterials.map((material, index) => (
                                                material.media_type === 'image' ? (
                                                    // <div className="gallery-small" key={index} onClick={() => openModal(material.file_url)}>
                                                    <div className="col-6 col-lg-12 col-sm-12" key={index}>
                                                    <div className="gallery-small-one" key={index} onClick={() => openModal(material?.file_url,index+1)}>
                                                        <img src={material.file_url} alt={`Gallery ${index + 1}`} />
                                                    </div>
                                                    </div>
                                                ) : material.media_type === 'video' ? (
                                                    // <div className="gallery-small" key={index} onClick={() => openModal(material.file_url)}>
                                                    <div className="col-6 col-lg-12 col-sm-12 mb-3" key={index} onClick={() => openModal(material?.file_url,index+1)}>
                                                    <div className="gallery-small-one" key={index}>
                                                        <video controls>
                                                            <source src={material.file_url} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    </div>
                                                    </div>
                                                ) : null
                                            ))}
                                          
                                            </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </>

                        ) :
                            <></>
                        } */}





                    </div>

                </section>
                {userDetails?.id && userDetails?.otherClasses?.length > 0 && (

                    <section class="other-class-main  mt-5 border-top-creator">

                        <h3 className='mt-3'>Other classes by {userDetails?.userData?.name}</h3>
                        <button class="see-all mt-3" onClick={()=>navigate(`/creator/detail/${userDetails?.user_id}`)}>See all offers<img src={arrow} alt='' /></button> {/*onClick={()=>navigate('/user/offering')} */}
                        <div className='row'>
                        {userDetails?.id && userDetails?.otherClasses?.length <3 ?
                        <>
                        {userDetails?.id && (userDetails?.otherClasses.map((x, index) => {
                                    return (

                                        <div className="col-sm-4 p-2" onClick={() => navigate(`/user/offering/${x?.id}`)} key={index}>
                                        {/* <div className="col-sm-4 p-2" onClick={() => singleService(x?.id)} key={index}> */}
                                            <div className="product-box">
                                                <div className="product-img">
                                                    <img src={x?.cover_image} alt='' />
                                                    <div className="product-image-data">
                                                    {x?.service_type === "1" && (
                                                            <span><img src={x?.category && x?.category?.category_image} alt='' /></span>
                                                        )}
                                                            <h5>
                                                                {x?.service_type === "2"
                                                                    ? (x?.product_kind === "0" ? 'Physical Product' : 'Digital Product')
                                                                    : (x?.service_type === "1"
                                                                        ? (x?.location_type === "1" ? 'Virtual'
                                                                            : (x?.location_type === "2" ? 'In Person'
                                                                                : (x?.location_type === "3" ? 'Virtual/In Person' : '')))
                                                                        : 'In Person')}
                                                            </h5>
                                                            <button onClick={(e)=>{e.stopPropagation();handleFavourite(x)}} className={`heart-btn ${x?.isFavorite === 1 ? "heart-filled" : "heart-outline"}`}><img src={hearts} alt='' /></button>
                                                        {/* <h6><img src={location} alt='' />Austin, Texas</h6> */}
                                                        {x?.address && x?.address!== null &&<h6><img src={location} alt='' />{x?.address?.city + ', ' + x?.address?.country}</h6>}
                                                    </div>
                                                </div>
                                                <div className="product-data">
                                                    <h3>{x?.name} </h3>
                                                    <ul className='product-data-tags'>
                                                        {x?.tags && x?.tags.length > 0 && x?.tags.slice(0, 3).map((y, i) => {
                                                            return (
                                                                <li className={i === 0 && "active"}>{y?.tag_name}</li>
                                                            )
                                                        })}
                                                    </ul>
                                                    <p>
                                                        {x?.description}
                                                    </p>
                                                    <div className="product-data-bottom">
                                                        <span><img src={x?.user?.user_profile_image && x?.user?.user_profile_image || bannerRight} alt='' /></span>
                                                        <h6><strong>{x?.user && x?.user?.name}</strong> {x?.user?.specializations && x?.user?.specializations[0]?.area_of_work}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }))}
                                </> :
                            <Slider {...(userDetails?.otherClasses?.length<2 ? settings : settingsSimilar)}>
                            {/* <Slider {...settings}> */}
                                {userDetails?.id && (userDetails?.otherClasses.map((x, index) => {
                                    return (

                                        <div className="col-sm-4 p-2" onClick={() => navigate(`/user/offering/${x?.id}`)} key={index}>
                                            <div className="product-box">
                                                <div className="product-img">
                                                    <img src={x?.cover_image} alt='' />
                                                    <div className="product-image-data">
                                                    {x?.service_type === "1" && (
                                                            <span><img src={x?.category && x?.category?.category_image} alt='' /></span>
                                                        )}
                                                            <h5>
                                                                {x?.service_type === "2"
                                                                    ? (x?.product_kind === "0" ? 'Physical Product' : 'Digital Product')
                                                                    : (x?.service_type === "1"
                                                                        ? (x?.location_type === "1" ? 'Virtual'
                                                                            : (x?.location_type === "2" ? 'In Person'
                                                                                : (x?.location_type === "3" ? 'Virtual/In Person' : '')))
                                                                        : 'In Person')}
                                                            </h5>
                                                            <button onClick={(e)=>{e.stopPropagation();handleFavourite(x)}} className={`heart-btn ${x?.isFavorite === 1 ? "heart-filled" : "heart-outline"}`}><img src={hearts} alt='' /></button>
                                                        {/* <h6><img src={location} alt='' />Austin, Texas</h6> */}
                                                        {x?.address && x?.address!== null &&<h6><img src={location} alt='' />{x?.address?.city + ', ' + x?.address?.country}</h6>}
                                                    </div>
                                                </div>
                                                <div className="product-data">
                                                    <h3>{x?.name} </h3>
                                                    <ul className='product-data-tags'>
                                                        {x?.tags && x?.tags.length > 0 && x?.tags.slice(0, 3).map((y, i) => {
                                                            return (
                                                                <li className={i === 0 && "active"}>{y?.tag_name}</li>
                                                            )
                                                        })}
                                                    </ul>
                                                    <p>
                                                        {x?.description}
                                                    </p>
                                                    <div className="product-data-bottom">
                                                        <span><img src={x?.user?.user_profile_image && x?.user?.user_profile_image || bannerRight} alt='' /></span>
                                                        <h6><strong>{x?.user && x?.user?.name}</strong> {x?.user?.specializations && x?.user?.specializations[0]?.area_of_work}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }))}
                            </Slider>
}
                        </div>
                    </section>
                )}


                {userDetails?.id && similarClass.length > 0 && (

                    <section class="other-class-main">

                        <h3>Similar classes</h3>
                        <button class="see-all" onClick={()=>navigate('/user/offering')}>See all offers<img src={arrow} /></button>

                        <Slider {...settingsSimilar}>
                            {userDetails?.id && (
                                similarClass?.map((x, index) => (
                                    <div className="col-sm-4 p-2" onClick={() => singleService(x?.id, x)} key={index}>
                                        <div className="product-box">
                                            <div className="product-img">
                                                <img src={x?.cover_image} alt='' />
                                                <div className="product-image-data">
                                                    <span><img src={x?.category && x?.category?.category_image} alt='' /></span>
                                                    <h5>Offline</h5>
                                                    <button><img src={hearts} alt='' /></button>
                                                </div>
                                            </div>
                                            <div className="product-data">
                                                <h3>{x?.name} </h3>
                                                <ul className='product-data-tags'>
                                                    {x?.tags && x?.tags.length > 0 && x?.tags.map((y, i) => (
                                                        <li className={i === 0 ? "active" : ''} key={i}>
                                                            {y?.tag_name}
                                                        </li>
                                                    ))}
                                                </ul>
                                                <p>{x?.description}</p>
                                                <div className="product-data-bottom">
                                                    <span><img src={x?.user?.user_profile_image && x?.user?.user_profile_image || bannerRight} alt='' /></span>
                                                    <h6><strong>{x?.user && x?.user?.name}</strong>  {x?.user?.specializations && x?.user?.specializations[0]?.area_of_work}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </Slider>

                    </section>
                )}

                <Modal
                    isOpen={isModal}
                    onRequestClose={() => closeModal()}
                    contentLabel="Login Modal"
                    portalClassName={`boookingModal booking-single-value ${getPortalClassName(userDetails)}`}
                    // portalClassName={`boookingModal booking-single-value ${userDetails?.session_offer_type ==='2' ?'multiple-booking-ui-set':'single-booking-ui-set'}`}
                    // portalClassName="boookingModal booking-single-value"
                    shouldCloseOnOverlayClick={false}
                >
                    <button className='close-button' onClick={() => closeModal()}><img src={greencross} alt='' /></button>
                    <BookingModal data={userDetails} closeModal={closeModal} setFiltersModal={setFiltersModal} setDataToPaymentModal={setDataToPaymentModal} setConfirmLocation={setConfirmLocation}/>
                </Modal>

                {/* for one year  and custom starts */}

                <Modal
                    isOpen={customBookingModal}
                    onRequestClose={() => closeCustomBookingModal()}
                    contentLabel="Login Modal"
                    portalClassName={`boookingModal custom  booking-single-value ${getPortalClassName(userDetails)}`}
                shouldCloseOnOverlayClick={false}
                >
                    <button className='close-button' onClick={() => closeCustomBookingModal()}><img src={cross} alt='' /></button>
                    <CustomBookingModal data={userDetails} closeModal={closeCustomBookingModal} setFiltersModal={setFiltersModal} setDataToPaymentModal={setDataToPaymentModal} setConfirmLocation={setConfirmLocation} />
                </Modal>

                {/* for one year  and custom ends*/}

                 {/* for monthly starts */}

                 <Modal
                    isOpen={monthlyBookingModal}
                    onRequestClose={() => closeMonthlyBookingModal()}
                    contentLabel="Login Modal"
                    portalClassName={`boookingModal custom booking-single-value  ${getPortalClassName(userDetails)}`}
                shouldCloseOnOverlayClick={false}
                >
                    <button className='close-button' onClick={() => closeMonthlyBookingModal()}><img src={cross} alt='' /></button>
                    <MonthlyBookingModal data={userDetails} closeModal={closeMonthlyBookingModal} setFiltersModal={setFiltersModal} setDataToPaymentModal={setDataToPaymentModal} setConfirmLocation={setConfirmLocation}/>
                </Modal>

                {/* for one year ends*/}

                {/* <Modal
                    isOpen={filtersModal}
                    onRequestClose={() => setFiltersModal(false)}
                    contentLabel="Login Modal"
                    portalClassName="boookingModal custom"
                shouldCloseOnOverlayClick={false}
                >
                    <button className='close-button' onClick={() => setFiltersModal(false)}><img src={cross} alt='' /></button>
                   <ConfirmLocation setPaymentModal={setPaymentModal} setLocationModal={setLocationModal} type={'offering'} itemDetails={userDetails} />
                </Modal> */}


            <Modal
                    isOpen={filtersModal} onRequestClose={() => setFiltersModal(false)} contentLabel="Payment Method"
                    overlayClassName={`modal-overlay checkout-pay digital`}
                    portalClassName="checkout">
                    <button className='close-button' onClick={() => setFiltersModal(false)}><img src={graycross} alt='' /></button>
          {/* <Elements stripe={stripePromise}> */}

                    <PaymentModal setFiltersModal={setFiltersModal} setSuccessModal={setSuccessModal} type={'offering'} itemDetails={userDetails} paymentDetails={dataToPaymentModal} addressId={addressId}></PaymentModal>
          {/* </Elements> */}

                </Modal>




                <Modal
                    isOpen={confirmLocation} onRequestClose={() => setConfirmLocation(false)} contentLabel="Payment Method"
                    overlayClassName={`modal-overlay `}
                    portalClassName="checkout cheeckout-discovvery-modal">
                    <button className='close-button' onClick={() => setConfirmLocation(false)}><img src={graycross} alt='' /></button>
                    <button className='back-button' onClick={() => handleBackArrow()}><img src={leftArrow} alt='' /></button>
                    <div className='modal-data-cs'>
                        <h2>Confirm Class location</h2>
                        <h3><span className='pe-1'>Creator's address:</span>{userDetails?.address?.address}</h3>
                        <p>The creator can travel to <span className='fw-bold'>{userDetails?.travel_area}.</span></p>
                        <p className='mb-2'>Would you like to have the session at the creator's place, or do you prefer a different location?</p>
                        <p>Travel charges of ${userDetails?.travel_charge_fee}/mile will be added to the final price based on the distance.</p>
                         <div className='buttons'>
                        <button onClick={()=>{setLocationProvide(true);setLocationModal(true);setConfirmLocation(false)}} className='btn cancel-btn'>Provide my address</button>
                        <button onClick={()=> {setLocationProvide(false);setFiltersModal(true);setConfirmLocation(false)}} className='btn save-btn'>Continue with Creator's Place</button>
                        </div>
                    </div>

                </Modal>

                <Modal
                    isOpen={locationModal}
                    onRequestClose={() => setLocationModal(false)}
                    contentLabel="Login Modal"
                    portalClassName="boookingModal custom address-for-booking"
                shouldCloseOnOverlayClick={true}
                >
                    {/* <button className='close-button' onClick={() => setLocationModal(false)}><img src={cross} alt='' /></button> */}
                   <ConfirmLocation setPaymentModal={''} setLocationModal={setLocationModal} type={'offering'} itemDetails={userDetails} setFiltersModal={setFiltersModal} setAddressId={setAddressId} addressId={addressId} setDataToPaymentModal={setDataToPaymentModal}/>
                </Modal>

                <Modal
                    isOpen={successModal}
                    onRequestClose={() => setSuccessModal(false)}
                    contentLabel="Payment Method"
                    portalClassName="checkout success"
                    overlayClassName={`modal-overlay thanks-pay`}
                    shouldCloseOnOverlayClick={false}

                >
                    <button className='close-button' onClick={() => setSuccessModal(false)}><img src={graycross} alt='' /></button>
                    <CheckoutSuccess  setFiltersModal={setFiltersModal} type='' itemDetails={userDetails} slotDisplay={dataToPaymentModal} locationProvide={locationProvide} ></CheckoutSuccess>
                </Modal>

                {/* <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeImgModal}
                    contentLabel="Media-Modal"
                    portalClassName="Media-Modal media-modal-clicks"
                    ariaHideApp={false}
                >
                    <button className='close-button' onClick={() => closeImgModal()}><img src={cross} alt='' /></button>

                    {modalContent && (modalContent.endsWith('.png') || modalContent.endsWith('.jpg') || modalContent.endsWith('.jpeg') || modalContent.endsWith('.svg')) ? (
                    <div className='media-click-img'><img src={modalContent} alt="Modal Content" /></div>
                    ) : modalContent.endsWith('.mp4') ? (
                        <video controls style={{ width: '100%' }}>
                            <source src={modalContent} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    ) : null}
                </Modal> */}

                <Modal
                    isOpen={discoveryModal}
                    onRequestClose={closeDiscoveryModal}
                    contentLabel="Login Modal"
                    portalClassName="availability-modal bookingconsultation"
                    ariaHideApp={false}
                >
                    <button className='close-button' onClick={() => closeDiscoveryModal()}><img src={cross} alt='' /></button>
<BookConsultation id={userDetails?.user_id} setIsModal={setDiscoveryModal} setIsModalLook={setIsModalLook} type={params?.id} name={userDetails?.userData?.name}/>
                    
                </Modal>
                {/* <Modal
        isOpen={isModalLook}
        onRequestClose={() => setIsModalLook(false)}
        contentLabel="Login Modal"
        portalClassName="availability-modal dilivery-call-sucess"
        shouldCloseOnOverlayClick={true}
      >
        <button className='close-button' onClick={() => setIsModalLook(false)}><img src={crosssuccess} alt='' /></button>
  <CalendarLook/>
      </Modal> */}

      <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeImgModal}
                    contentLabel="Media-Modal"
                    portalClassName="Media-Modal media-open-fullimg"
                    ariaHideApp={false}
                >
                    <button className='close-button' onClick={() => closeImgModal()}> <img src={cross} alt='' /></button>
                <CaroselImage userDetails={userDetails} startIndex={startIndex}/>
                </Modal>

                <Modal
                    isOpen={oneYear}
                    onRequestClose={() => closeModalOneYear()}
                    contentLabel="Login Modal"
                    portalClassName={`boookingModal booking-single-value year-modal-set ${getPortalClassName(userDetails)}`}
                    // portalClassName={`boookingModal booking-single-value ${userDetails?.session_offer_type ==='2' ?'multiple-booking-ui-set':'single-booking-ui-set'}`}
                    // portalClassName="boookingModal booking-single-value"
                    shouldCloseOnOverlayClick={false}
                >
                    <button className='close-button' onClick={() => closeModalOneYear()}><img src={greencross} alt='' /></button>
                    {/* <OneYear data={userDetails} closeModal={closeModalOneYear} setFiltersModal={setFiltersModal} setDataToPaymentModal={setDataToPaymentModal} setConfirmLocation={setConfirmLocation}/> */}
                </Modal>

            </div>
}
        </div>
    )
}

export default SingleOfferingData