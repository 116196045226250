
import addnew from '../../../assets/images/add5.svg';
import dummy from '../../../assets/images/profile3.svg';
function BasicsSpecialization({  }) {

    return (
        <div class="specialization">
             <div class="footer-button">
            <button class="cancel">Cancel</button>
            <button class="save">Save</button>
        </div>
        <div class="section first">
            <h1>Specialization</h1>
            <div class="yoga-instructor">
                <h3>Yoga instructor</h3>
                <div class="form-group">
                    <label for="experience">Experience</label>
                    <input type="text" id="experience" class="form-control" placeholder="2 years"/>
                </div>
            </div>
            <div class="add-new">
                <a href="#"> <img src={addnew} alt="Add icon"/>Add new specialization and experience</a>
            </div>
        </div>
        <div class="section second">
            <h1>Associated with</h1>
            <div class="middle">
                <div class="content">
                    <div class="image-content">
                        <span><img src={dummy} alt="Yoga Hero"/></span>
                        <h3>“Yoga Here Studio”</h3>
                    </div>
                    <button class="stop">Stop collaboration</button>
                </div>
                <div class="content">
                    <div class="image-content">
                        <span><img src={dummy} alt="Heal Platform"/></span>
                        <h3>“Heal Platform”</h3>
                    </div>
                    <button class="stop">Stop collaboration</button>
                </div>
                <div class="add-new">
                    <a href="#"> <img src={addnew} alt="Add icon"/>Add new association</a>
                </div>
            </div>
        </div>

       
    </div>
    );
}

export default BasicsSpecialization;