import React, { useEffect, useRef, useState } from 'react'
import greenTick from '../../../assets/images/green-tick.svg'
import wellness from '../../../assets/images/wellness.svg'
import creativity from '../../../assets/images/creativity.svg'
import growth from '../../../assets/images/growth.svg'
import wellness2 from '../../../assets/images/wellness2.svg'
import parenting from '../../../assets/images/parenting.svg'
import addTag from '../../../assets/images/add-tag.svg'
import UserSidebar from '../../../Layout/userSidebar'
import burger from '../../../assets/images/burger-menu.svg'
import { Link, useNavigate } from 'react-router-dom'
import { clearOfferingData, insertOfferingData } from '../../../Counter/offeringSLice'
import { useDispatch } from 'react-redux'
import leftArrow from '../../../assets/images/backarrow.svg'
import ActiveLinks from './activeLinks'
import OfferingDetails from './OfferingDetails'
import { getApi, postApi } from '../../../Services/apiService'
import choosearrow from '../../../assets/images/choose-arrow.svg'
import CreatorTags from '../DashboardCreator/creatorTags'
import crossbutton from '../../../assets/images/cross-button.svg'
import Modal from 'react-modal';
import ViewTag from './viewTag'
import DraftModal from './draftModal'
import eyeimage from '../../../assets/images/eye-icon.svg'

function DefineOffering({ nextStep, prevStep, data, step,submitForm,setDraftData,typess,submitEditData,loader}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [classState, setClassState] = useState(false)
  const [name, setName] = useState(data?.name || '');
  const [type, setType] = useState(data?.type ||'');
  const [sessionType, setSessionType] = useState(data?.sessionTypes || "private_session")
  const [minGroupSize, setMinGroupSize] = useState(data?.minGroupSize || null);
  const [maxGroupSize, setMaxGroupSize] = useState(data?.maxGroupSize || null);
  const [categoryData, setCategoryData] = useState([]);
  const [tagData, setTagData] = useState([]);
  const [tagName, setTagName] = useState([]);
  const [nameTag, setNameTag] = useState(data?.tagName || []);
  const [inputTagData, setInputTagData] = useState(data?.tagName || [])
  const [creatorTag, setCreatorTag] = useState(data?.tags || '')
  const [selectedCategory, setSelectedCategory] = useState(data?.categoryId || '')
  const [selectedCategoryName, setSelectedCategoryName] = useState(data?.categoryName || '')
  const [errors, setErrors] = useState({});
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [tagModal, setTagModal] = useState(false)
  const [offerValue, setOfferValue] = useState('')
  const [stateModal, setStateModal] = useState(false)
  const [isModal, setIsModal] = useState(false)
  const[offeringType,setOfferingType] = useState([])
  const[offerTypeValue,setOfferTypeValue] = useState(data?.offerTypeValue || '')
  // const [description, setDescription] = useState(data?.description || '')
  const dropdownRef = useRef(null);
  console.log("tagNametagName",tagName);
  console.log("tagDatatagData",tagData);
  
  let filteredB =[];
  if(tagName.length>0 && tagData.length>0){
    //  filteredB = tagData.filter(item => !tagName.includes(item.tag_name));
     filteredB = tagData.filter(bItem => !creatorTag.some(aItem => aItem === bItem.id));
    //  filteredB = tagData.filter(bItem => !tagName.some(aItem => aItem.name === bItem.tag_name));
  }
  let matchingTags =[]
  if(creatorTag.length>0){
    // matchingTags = tagData.filter(tag => creatorTag.includes(tag.id));
    matchingTags = tagData.filter(tag => creatorTag.includes(tag.id)).map(tag => ({ name: tag.tag_name, id: tag.id }));
    // setTagName(matchingTags)
  }
  console.log("matchingTagsmatchingTags",matchingTags);
  
  useEffect(() => {
    if (creatorTag.length > 0) {
      // const matchingTagss = tagData.filter(tag => creatorTag.includes(tag.id));
      const matchingTagss = tagData.filter(tag => creatorTag.includes(tag.id)).map(tag => ({ name: tag.tag_name, id: tag.id }));
      setTagName(matchingTagss);
    }else{
      setTagName([])
    }
  }, [creatorTag, tagData])
console.log("stepstepstepstepstepstepstepstep",step);

const handleClickOutside = (event) => {
  if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    setIsDropdownVisible(false); // Close the dropdown
  }
};

useEffect(() => {
  document.addEventListener('mousedown', handleClickOutside);
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, []);

  console.log("//////////////////", creatorTag)

  const validateForm = () => {
    const newErrors = {};

    // if (!name) {
    //   newErrors.name = 'Name is required';
    // }
    // else if (name.trim().length !== name.length) { // Check for spaces in the name
    // // else if (/\s/.test(name)) { // Check for spaces in the name
    //   newErrors.name = 'Name cannot contain spaces';
    // }

    if (!selectedCategory) {
      newErrors.selectedCategory = 'Category is required';
    }

    if (!type) {
      newErrors.type = 'Type of offering is required';
    }

    if (creatorTag.length === 0) {
      newErrors.creatorTag = 'At least one tag is required';
    }

    if (!sessionType) {
      newErrors.sessionType = 'Session type is required';
    }

    if (sessionType === 'group_session') {
      // if (!minGroupSize) {
      //   newErrors.minGroupSize = 'Minimum group size is required';
      // }
      if (!maxGroupSize || Number(maxGroupSize) <= 0) {
        newErrors.maxGroupSize = 'Maximum group size is required';
      }
    }

  //   if (!description) {
  //     newErrors.description = 'Description is required.';
  // }
  // else if (description.trim().length === 0) {
  //   newErrors.description = 'Description is required';
  // }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    const handleClick = () => setClassState(false);

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [])

  useEffect(()=>{
    if(!tagModal){
    handleTag()
    }
  },[tagModal])


  useEffect(() => {
    handleCategory()
    handleTag()
    getTypeOffering()
  }, [])

  const handleCategory = async () => {
    const response = await getApi('/categories');
    if (response?.statusCode === 200) {
      setCategoryData(response?.data);
    }
  }

  const handleTag = async () => {
    const response = await getApi('/interest')
    if (response?.statusCode === 200) {
      setTagData(response?.data)
    }
  }

  const handleSidebarClick = (event) => {
    event.stopPropagation();
    setClassState(!classState);
  };

  const handleSave = async (val) => {
    
    const values = {
      name: name,
      type: type,
      sessionTypes: sessionType,
      categoryId: selectedCategory,
      tags: creatorTag,
      tagName: nameTag,
      // tagName: tagName,
      SelectedDayss: [],
      // description: description,
      categoryName:selectedCategoryName,
      offerTypeValue:offerTypeValue,
      step:step
    }
    if (minGroupSize > 0) {
      values.minGroupSize = minGroupSize
    }
    if (maxGroupSize > 0) {
      values.maxGroupSize = maxGroupSize
    }
    if(val ==='prev'){
      dispatch(insertOfferingData(values))
      prevStep()
    }
    else{
    if (validateForm()) {
      // dispatch(clearOfferingData())
      dispatch(insertOfferingData(values))
      // navigate('/creator/offering/availability');
      
      if(val==='draft'){
        // submitForm('draft',values)
        setStateModal(true)
        // if(type === 'product'){
    
        // } else{
        const resp = await submitForm('draft',values)
        if(resp === true){
          setStateModal(false)
          setIsModal(true)
        } else{
          setStateModal(false)
        }
      }
      else{

        nextStep()
      }
    }
  }
  }

  const handleTagData = (newTag, tagname) => {
    
    setCreatorTag((prevTagData) => {
      if (prevTagData.includes(newTag)) {
        return prevTagData.filter(tag => tag !== newTag);
      } else {
        return [...prevTagData, newTag];
      }
    });
    // setTagName((prevTagName) => {
    //   if (prevTagName.includes(tagname)) {
    //     return prevTagName.filter(tag => tag !== tagname);
    //   } else {
    //     return [...prevTagName, tagname];
    //   }
    // });
    setTagName((prevTagData) => {
      if (prevTagData.some(tag => tag.name === newTag?.tag_name)) {
        return prevTagData.filter(tag => tag.name !== newTag?.tag_name);
      } else {
        return [...prevTagData, { name: tagname, id: newTag }];
        // return [...prevTagData, { tag_name: tagname, id: newTag }];
      }
    });
    // setTagNamesetNameTag
    setNameTag((prevTagName) => {
      if (prevTagName.includes(tagname)) {
        return prevTagName.filter(tag => tag !== tagname);
      } else {
        return [...prevTagName, tagname];
      }
    });
  }
  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleOfferingType = (val) =>{
    setType(val?.id)
    setOfferTypeValue(val?.offer_name)
    setIsDropdownVisible(!isDropdownVisible);
  }

  const closeModal = () => {
    setTagModal(false)
  }

  const getTypeOffering = async() =>{
    const response = await getApi('/creator/offer-types')
    if(response?.statusCode === 200){
      setOfferingType(response?.data)
      if(type !=''){
        // const filterData = response?.data?.offerType?.length>0 && response?.data?.offerType.filter(item=> item.id == type)
        // if(filterData){
        //   setOfferTypeValue(filterData[0]?.offer_name)
        // }
      }
    }
  }
  const handleAddOffering = async() =>{
    
    const valu={
      typename:offerValue
    }
    const response = await postApi('/creator/offer-types', valu);
    if (response?.statusCode === 200) {
      handleOfferingType(response?.data)
      setOfferValue('')
      getTypeOffering()
      setIsDropdownVisible(false)
    }
    setIsDropdownVisible(false)
  }
  
  const updateReduxData = (updatedValues) => {
    dispatch(insertOfferingData(updatedValues));
  };

  useEffect(() => {
    if (data?.editype === 'edit') {
      const updatedData = {
        name: name,
        type: type,
        sessionTypes: sessionType,
        categoryId: selectedCategory,
        tags: creatorTag,
        tagName: nameTag,
        SelectedDayss: [],
        categoryName:selectedCategoryName,
        offerTypeValue:offerTypeValue,
      };

      updateReduxData(updatedData);
    }
  }, [
    name,
    type,
    sessionType,
    selectedCategory,
    creatorTag,
    nameTag,
    // ,
    selectedCategoryName,
    offerTypeValue,
  ]);

  // const handleSetCategory = ()
  return (
    <div>
      <UserSidebar classState={classState} />
      <div className='right-main session-spacing-cs'>
        <div class="session">
          <div class="header">
            <h1> <button className="burger-menu" onClick={handleSidebarClick}><img src={burger} alt='' /></button> {data?.editype==='edit' ? 'Edit Offering' :'Session Or Service'}</h1>
            <div class="top-buttons">
            {data?.editype==='edit' ? 
              <button class="save-draft" onClick={()=>navigate('/creator/offering/home')} disabled={stateModal}>{stateModal && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Cancel</button>
              :
              <button class="save-draft" onClick={()=>handleSave('draft')} disabled={stateModal}>{stateModal && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Save to Draft</button>
            }
              {/* <Link to='/creator/offering/availability'> */}
              {data?.editype==='edit' ? <><button class="next" onClick={()=>submitEditData()} disabled={loader}>{loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}save</button>
              <button class="eye" ><img src={eyeimage} alt=''/></button>
              </>
              :
              <button class="next" onClick={()=>handleSave()}>Next</button>}
              {/* <button class="next" onClick={()=>data?.editype==='edit' ? submitEditData() : handleSave()}>Next</button> */}
              {/* </Link> */}
            </div>
          </div>
{typess!=='edit' &&
          <div class="active-links">

            <button className="back" onClick={() =>{setDraftData(true); handleSave('prev')}}><img src={leftArrow} alt='' /> </button>
            {/* <button className="back" onClick={() =>{setDraftData(true); prevStep()}}><img src={leftArrow} alt='' /> </button> */}

            <ActiveLinks step={step} />
          </div>
}





          {/* <!-- Main --> */}
          <div class="main session-spacing">
            <div class="row">
              <div class="col-sm-7">
                <div class="left">
                  <h1 className='define-offer'>Define your offering</h1>

                  {/* <div class="form-group">
                    <label htmlFor="exampleInputEmail1">Name</label>
                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Give your offer name" value={name} onChange={(e) => setName(e?.target?.value)} />
                    {errors.name && <div className="error">{errors.name}</div>}
                  </div> */}
                  {/* <div class="description">
                        <label for="description">Description</label>
                        <textarea id="description" name="description" placeholder="Add more details about Your offer" value={description} onChange={(e) => setDescription(e?.target?.value)} ></textarea>
                        {errors.description && <div className="error">{errors.description}</div>}
                      </div> */}

                  <div class="choose-category">
                    <h6>Choose Category {selectedCategory !=='' &&<img src={greenTick} alt='' />} </h6>
                    <ul>
                      {categoryData && categoryData?.length > 0 && categoryData.map((x, index) => {
                        return (
                          <li key={index} className={(selectedCategory === x?.id) && 'active'} onClick={() => {setSelectedCategory(x?.id);setSelectedCategoryName(x?.category_name)}}>
                            <img src={x?.category_image} alt='' />
                            <h4>{x?.category_name}</h4>
                          </li>
                        )
                      })}
                    </ul>
                    {errors.selectedCategory && <div className="error">{errors.selectedCategory}</div>}

                  </div>
                
                   <div class="of-offering">
                    <h6>Choose Type Of Offering  {offerTypeValue !=='' && <img src={greenTick} alt='' />}</h6>
                    <div className={`dropdown oper-drop-down-cs ${offerTypeValue ? 'change-onselect' :''}`} onClick={toggleDropdown}>
                      <button >
                        {offerTypeValue || 'Select type of Offering'}
                        <img src={choosearrow} alt='' />
                      </button>
                      {isDropdownVisible && (

                        <div id="myDropdown" className="dropdown-content" ref={dropdownRef}>
                          <div className='offering-dropdown-list'>
                          {offeringType && offeringType?.length>0 && offeringType.map((y,ind)=>{
                            return(
                              <>
                              <div onClick={(e) => {handleOfferingType(y);setIsDropdownVisible(false)}} >{y?.offer_name} {type === y?.id &&<img src={greenTick} alt='' />}</div>
                              </>
                            )
                          })}
                        </div>
                          <div className='offer-search' onClick={(e) => e.stopPropagation()}>
                            <input type="text" className='offer-input' placeholder='Enter your type' onChange={(e)=>setOfferValue(e?.target?.value)}></input>
                            <button onClick={()=>{handleAddOffering()}}>Add</button>
                          </div>
                        </div>
                      )}
                     
                    </div>
                    {errors.type && <div className="error">{errors.type}</div>}
                    </div>

                  <div class="choose-tags choose-tags-float">
                    <h6>Choose tags that related with your offering</h6>
                    <ul className='choose-taglist'>
                      {matchingTags.length >0 ? 
                      // {filteredB.length >0 ? 
                      <>
                      {matchingTags && matchingTags?.length>0 && matchingTags.map((x,index)=>{
                        return (
                          index<8 &&
                            <li class="custom-checkbox" key={index} onClick={() => handleTagData(x?.id, x?.tag_name)}>
                              <input type="checkbox" id="" checked={tagName.some(tag => tag.id === x?.id)} />
                              {/* <input type="checkbox" id="" checked={tagName.includes(x)} /> */}
                              <label for="option1">{x?.name || x?.tag_name}</label>
                            </li>
                          )
                      })}
                      {/* {tagName && tagName?.length>0 && tagName.map((x,index)=>{
                        return (
                          index<8 &&
                            <li class="custom-checkbox" key={index} onClick={() => handleTagData(x?.ids, x?.name)}>
                              <input type="checkbox" id="" checked={tagName.includes(x)} />
                              <label for="option1">{x?.name}</label>
                            </li>
                          )
                      })} */}
                      {/* {filteredB && filteredB.length > 0 && filteredB.map((x, index) => {
                      return (
                      index< (8 - tagName?.length)&&
                        <li class="custom-checkbox" key={index} onClick={() => handleTagData(x?.id, x?.tag_name)}>
                          <input type="checkbox" id="" checked={creatorTag.includes(x?.id)} />
                          <label for="option1">{x?.tag_name}</label>
                        </li>
                      )
                    })} */}
                    {filteredB && filteredB.length > 0 && filteredB.map((x, index) => {
                      return (
                      index<8-matchingTags?.length &&
                        <li class="custom-checkbox" key={index} onClick={() => handleTagData(x?.id, x?.tag_name)}>
                          <input type="checkbox" id="" checked={creatorTag.includes(x?.id)} />
                          <label for="option1">{x?.tag_name}</label>
                        </li>
                      )
                    })}
                      </>
                    :
                    <>
                    
                    {tagData && tagData.length > 0 && tagData.map((x, index) => {
                      return (
                      index<8 &&
                        <li class="custom-checkbox" key={index} onClick={() => handleTagData(x?.id, x?.tag_name)}>
                          <input type="checkbox" id="" checked={creatorTag.includes(x?.id)} />
                          <label for="option1">{x?.tag_name}</label>
                        </li>
                      )
                    })}
                    </>
                    }
                     
                      <div class="new-tags"  onClick={() => setTagModal(true)}><img src={addTag} alt='' />View All </div>
                    </ul>
                    {errors.creatorTag && <div className="error">{errors.creatorTag}</div>}
                  </div>
                  <div className='group-range-main'>
                    <div class="size-range">
                      <h6>Group size range?</h6>
                      <div class="bottom-buttons time-select-zone">
                        {/* <label class="radio-check3">
                          <input type="radio" id="radio1" checked={sessionType === 'private_session'} name="radio" onClick={() => {(sessionType ==='group_session' && dispatch(clearOfferingData()));setSessionType('private_session');setMaxGroupSize('')}} />
                          <span class="checkmark">Individual Session</span>
                        </label> */}
  <div 
    className={`radio-check3 ${sessionType === 'private_session' ? 'selected' : ''}`} 
    // onClick={() => {data?.editype!=='edit' && (sessionType ==='group_session' && dispatch(clearOfferingData()));setSessionType('private_session');setMaxGroupSize('')}}
    onClick={() => {
      if (data?.editype !== 'edit') {
        if (sessionType === 'group_session') {dispatch(clearOfferingData());
        setSessionType('private_session');setMaxGroupSize('');}
      }
    }}
  >
    <span className="checkmark">Individual Session</span>
  </div>
  <div 
    className={`radio-check3 ${sessionType === 'group_session' ? 'selected' : ''}`} 
    // onClick={() => {data?.editype!=='edit' && (sessionType ==='private_session' && dispatch(clearOfferingData()));setSessionType('group_session');}}
    onClick={() => {
      if (data?.editype !== 'edit') {
        if (sessionType === 'private_session'){ dispatch(clearOfferingData());
        setSessionType('group_session');}
      }
    }}
  >
    <span className="checkmark">Group Session</span>
  </div>
                        {/* <label class="radio-check3">
                          <input type="radio" id="radio1" checked={sessionType === 'group_session'} name="radio" onClick={() => {(sessionType ==='private_session' && dispatch(clearOfferingData()));setSessionType('group_session');}} />
                          <span class="checkmark">Group session</span>
                        </label> */}
                      </div>
                      {errors.sessionType && <div className="error">{errors.sessionType}</div>}
                    </div>

                    {sessionType === 'group_session' &&
                      <div className='week-data'>
                        {/* <h3>How Many Participants Will Be There</h3> */}
                        <div className='row'>
                          {/* <div className='col-sm-6'>
                            <label htmlFor="">Min Group Size</label>
                            <input type="number" className='form-control' value={minGroupSize} onChange={(e) => setMinGroupSize(e?.target?.value)} />
                            {errors.minGroupSize && <div className="error">{errors.minGroupSize}</div>}
                          </div> */}
                          <div className='col-sm-12'>
                            <label htmlFor="">Max Group Size</label>
                            {/* <input type="number" className='form-control' value={maxGroupSize} onChange={(e) => setMaxGroupSize(e?.target?.value)} min={1} max={10}/> */}
                            <input type="number" className='form-control' placeholder='Enter max number of people for this session' value={maxGroupSize} onChange={(e) => {
    const value = e.target.value;
    if (value <= 999 && value >0) {
      setMaxGroupSize(value);
    } else if(value <=0){
      setMaxGroupSize('');
    }else {
      setMaxGroupSize(maxGroupSize);
    }
  }}  min={1}/>
                            {errors.maxGroupSize && <div className="error">{errors.maxGroupSize}</div>}
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
              {type !== 'edit' &&
              <OfferingDetails data={data} />
}
            </div>
          </div>
        </div>
        <Modal
                  isOpen={tagModal}
                  onRequestClose={() => setTagModal(false)}
                  contentLabel="Tag Modal"
                  shouldCloseOnOverlayClick={true}
                  portalClassName="add-tag-modal"
                >
                  {/* <button className='close-button' onClick={() => setTagModal(false)}><img src={crossbutton} alt='' /></button> */}
                  <ViewTag closeModal={closeModal} types="offering" creatorTag={creatorTag} setCreatorTag={setCreatorTag} tagName={tagName} setTagName={setTagName} setNameTag={setNameTag}/>
                </Modal>
                <DraftModal setIsModal={setIsModal} isModal={isModal}/>
      </div>
    </div>
  )
}

export default DefineOffering