import React from "react";

const DeleteModal = ({setDeclineModal,setIsModalDelete}) =>{

    return(
        <>
    <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
          <div className="ready-publish">
            {/* <button className='close-button'></button> */}
            <h3>Are you sure you want to decline the order?</h3>
            <p>The payment will be refunded to the user.</p>
            <div className="bottom-button">
              <button className='draft' onClick={()=>setIsModalDelete(false)} ><span class="sr-only"></span>Cancel</button>
              <button className='publish' onClick={()=>{setDeclineModal(true);setIsModalDelete(false)}}><span class="sr-only"></span>Yes, decline</button>
              {/* <button className='publish' onClick={() => handlePublish('live')}>Publish</button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
        {/* <button onClick={()=>{setDeclineModal(true);setIsModalDelete(false)}}>Confirm Delete</button> */}
        </>
    )
}

export default DeleteModal