import React, { useEffect, useRef, useState } from 'react'
import UserSidebar from '../../../Layout/userSidebar';
import burger from '../../../assets/images/burger-menu.svg';
import search from '../../../assets/images/search.svg'
import orderdata from '../../../assets/images/order-box.svg'
import righttick from '../../../assets/images/right-tick-mark.svg'
import crosstick from '../../../assets/images/cross-tick-image.svg' 
import graycross from '../../../assets/images/gray-cross.svg'
import Modal from 'react-modal'
import AcceptModal from './acceptModal';
import DeleteModal from './DeleteModal';
import DeclineModals from './declineModals';
import { getApi, postApi } from '../../../Services/apiService';
import setting from '../../../assets/images/setting-screw.svg'
import declineorder from '../../../assets/images/order-decline.svg'
import pickup from '../../../assets/images/pickup-img.svg'
import smiley from '../../../assets/images/sm-smile.svg'
import favNoData from '../../../assets/images/empty-order.svg'

function Orders() {
  const [classState, setClassState] = useState(false);
  const [isModal,setIsModal] = useState(false)
  const [isModalDelete,setIsModalDelete] = useState(false)
  const [declineModal,setDeclineModal] = useState(false)
  const [orderStatus,setOrderStatus] = useState('')
  const [orderData,setOrderData] = useState([])
  const [singleOrderData,setSingleOrderData] = useState('')
  const [declineMessage,setDeclineMessage] = useState('')
  const [changeOrderStatus,setChangeOrderStatus] = useState(false)
  const [changeOrderStatusId,setChangeOrderStatusId] = useState('')
  const [loader,setLoader] = useState(false)
  const [totalCounts,setTotalCounts] = useState({})
  const [pageLoader,setPageLoader] = useState(false)
  const [declineLoader,setDeclineLoader] = useState(false)
  const divRef = useRef(null);

  useEffect(() => {
    // Function to handle clicks outside the div
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setChangeOrderStatus(false); // Close the div
      }
    };

    // Add event listener to the document
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSidebarClick = (event) => {
    event.stopPropagation();
    setClassState(!classState);
  };


  useEffect(() => {
    const handleClick = () => setClassState(false);
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

useEffect(()=>{
  fetchOrderDetail()
},[orderStatus])
const fetchOrderDetail = async()=>{
  setPageLoader(true)
  setChangeOrderStatus(false)
  const response = await getApi(`/creator/orders?status=${orderStatus}`)
  if(response?.statusCode === 200){
    setOrderData(response?.data)
    setTotalCounts(response?.totalCount)
  }
  setPageLoader(false)
}
const UpdateOrderDetail = async(orderID,ststus,declinedMessage)=>{
  if (orderStatus === '5') return;

  if (orderStatus !== '5' || ststus !== '5') {
    if(ststus !=='4'){
  setLoader(true)
    }else{
      setDeclineLoader(true)
    }
  const values = {
    orderID:orderID,
    status:ststus,
    declinedMessage:declinedMessage ? declinedMessage :null
  }
  const response = await postApi(`creator/order/status`,values)
  if(response?.statusCode === 200){
    fetchOrderDetail();
    setLoader(false)
    // setOrderData(response?.data)
  }
  if(ststus==='4'){
    setDeclineLoader(false)
    setDeclineModal(false)
  }
}
} 
  
  return (
    <div className="users meal-suggestion creator-chat">
      <div className="creator-offering home min-height-pera">
        <UserSidebar classState={classState} />
        <div className="RightSideDiv right-main order-page order-ui">
          <div className='header'>

            <button className="burger-menu" onClick={handleSidebarClick}>
              <img src={burger} alt='burger menu' />
            </button>
            <h2>  My Orders
            </h2>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='top-buttons'>
                <div className='input-container w-100'>
                  <img src={search} alt="Add tag" />
                  <input
                    type="text"
                    placeholder='Find Offerings or Creators'
                  />
                </div>
              </div>

            </div>
            <div className='overflow-scrolls'>
            <div className=' order-tabs'>
              <div class="creator-offering-tab">
                <ul>
                  <li><a className={orderStatus === '1' && 'active'} onClick={()=>setOrderStatus('1')}> New ({totalCounts?.pending})</a> </li>
                  <li><a className={orderStatus === '2' && 'active'} onClick={()=>setOrderStatus('2')}>Processing({totalCounts?.processing})</a> </li>
                  <li><a className={orderStatus === '4' && 'active'} onClick={()=>setOrderStatus('4')}>Cancelled({totalCounts?.declined})</a></li>
                  <li><a className={orderStatus === '5' && 'active'} onClick={()=>setOrderStatus('5')}>Completed({totalCounts?.completed})</a></li>
                  <li><a className={orderStatus === '' && 'active'} onClick={()=>setOrderStatus('')}>All({totalCounts?.total})</a></li>
                  {/* <li><a className={orderStatus === '1' && 'active'} onClick={()=>setOrderStatus('1')}> New {orderStatus === '1' ? `(${orderData?.length})`: `(${totalCounts?.pending})`}</a> </li>
                  <li><a className={orderStatus === '2' && 'active'} onClick={()=>setOrderStatus('2')}>Processing{orderStatus === '2' ? `(${orderData?.length})`: `(${totalCounts?.processing})`}</a> </li>
                  <li><a className={orderStatus === '4' && 'active'} onClick={()=>setOrderStatus('4')}>Cancelled{orderStatus === '4' ? `(${orderData?.length})`: `(${totalCounts?.declined})`}</a></li>
                  <li><a className={orderStatus === '5' && 'active'} onClick={()=>setOrderStatus('5')}>Completed{orderStatus === '5' ? `(${orderData?.length})`: `(${totalCounts?.completed})`}</a></li>
                  <li><a className={orderStatus === '' && 'active'} onClick={()=>setOrderStatus('')}>All{orderStatus === '' ? `(${orderData?.length})`: `(${totalCounts?.pickup})`}</a></li> */}
                </ul>
              </div>
            </div>
            {/* blank data */}
            {/* <div className='col-md-12'>
            <div className='fav-div-sec'>
                    <div className=''>
                        <div className='mb-2'>
                       <img src={orderdata} alt='' />
                       </div>
                        <p>You don't have any orders yet.</p>
                    </div>
                    </div>
            </div> */}
            {/* end */}

            {/* ------------------------------new tab data------------ */}
            {pageLoader ?
            <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            :
            <div className='order-max-height'>
              {orderData && orderData?.length ===0 ?
                   <div className='fav-div-sec'>
                                    <div className=''>
                                        <div className='mb-3'>
                                            <img src={favNoData} alt='' />
                                        </div>
                                        <p>You don't have any orders yet.</p>
                                    </div>
                                </div>
                                :
                                <>
            {orderData && orderData?.length >0 && orderData.map((x,index)=>{
              return(
      
            <div className='col-md-12 '>
              <div className='define-left position-relative'>
                <div className='row'>
                  <div className='col-xl-2 col-lg-2 col-md-4 col-sm-12'>
                    <span className='product-imgs'>
                    <img src={x?.offerService?.cover_image} alt="order-img" />
                    </span>
                  </div>
                  <div className='col-xl-6 col-lg-6 col-md-4  col-sm-12'>
                    <div class="order-info">
                      <h5>{x?.offerService?.title}</h5>
                      <p>Quantity <span className='fw-bold ps-2'>{x?.quantity}</span></p>
                      <p className='mb-2'><span className='fw-bold pe-2'>Order #</span>  {x?.offer_service_id}</p>
                      <p>Customer<span className='fw-bold ps-2'>{x?.user?.name}</span></p>
                      <p className='pick-up' >Pick up from <span className='fw-bold ps-2'>{x?.offerService?.address && x?.offerService?.address?.address +`${x?.offerService?.address?.address!=''?', ':' '}`+x?.offerService?.address?.city +`${x?.offerService?.address?.city!=''?', ':' '}`+x?.offerService?.address?.state +`${x?.offerService?.address?.country!=''?', ':' '}`+x?.offerService?.address?.country}</span></p>
                      {/* <p className='pick-up' >Pick up from <span className='fw-bold ps-2'>123 Main Street, Anytown</span></p> */}
                      <p>Ordered<span className='fw-bold ps-2'>{x?.updatedAt?.split('T')[0]}</span> </p>
                    
                      <p class="text-success">{orderStatus === '4' ? 'Payment refunded to client' :'Payment successful'}</p>
                      <p class="text-success">{x?.declined_message}</p>
                    </div>
                  </div>
                  <div className='col-xl-4 col-lg-4 col-md-4  col-sm-12'>
                  {orderStatus === '1' &&
                    <div class="btn-group">
                    <button class="btn btn-decline mx-3" onClick={()=>{setSingleOrderData(x);setIsModalDelete(true)}} disabled={loader}>{loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Decline<img src={crosstick} alt="order-img" className='ps-2' /></button>
                      <button class="btn btn-acept mx-3" onClick={()=>{setSingleOrderData(x);UpdateOrderDetail(x?.id,'2');setIsModal(true)}} disabled={loader}>{loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Accept<img src={righttick} alt="order-img" className='ps-2' /></button>
                    </div>
            }
                  {orderStatus === '2' &&
                  <>
                  <div className='order-details-list'>
                     <ul className='processing-list ps-0 text-end'>
                     <li><button className='btn order-pro'><img src={setting} alt="order-img" className='pe-2' />Order processing</button> 
                     <div className='proces-status' onClick={()=>{setChangeOrderStatusId(x?.id);setChangeOrderStatus(!changeOrderStatus)}}>
                        <p>Change order status</p>
                     </div>
                     </li>
                     </ul>
                     </div>
                     {changeOrderStatus && x?.id === changeOrderStatusId &&
                      <div className='process-on-click' ref={divRef}>
                      <p onClick={()=>{setChangeOrderStatusId('');setChangeOrderStatus(false)}}>Change to</p>
                      <ul  className='processing-list ps-0'>
                      <li className='text-start mb-2'><button className='btn order-dine' onClick={()=>{UpdateOrderDetail(x?.id,'4');setChangeOrderStatus(false)}}><img src={declineorder} alt="order-img" className='pe-2' />Order declined</button></li>
                      <li className='text-start mb-2'><button className='btn order-pro' onClick={()=>{UpdateOrderDetail(x?.id,'2');setChangeOrderStatus(false)}} disabled={loader}>{loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}<img src={setting} alt="order-img" className='pe-2' />Order processing</button></li>
                      <li className='text-start mb-2'><button className='btn ready-to-pick' onClick={()=>{setChangeOrderStatus(false)}}><img src={pickup} alt="order-img" className='pe-2' />Ready to pick up</button></li>
                      <li className='text-start mb-2'><button className='btn order-complete' onClick={()=>{ UpdateOrderDetail(x?.id,'5');setChangeOrderStatus(false)}} disabled={loader}>{loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}<img src={smiley} alt="order-img" className='pe-2' />Completed</button></li>
                      </ul>
                      </div>
            }
                      </>
            }

            {orderStatus === '4' &&
             <div className='order-details-list'>
                     <ul className='processing-list ps-0 text-end'>
                     <li><button className='btn order-pro'><img src={setting} alt="order-img" className='pe-2' />Order Declined</button> 
                     <div className='proces-status'>
                        <p>Change order status</p>
                     </div>
                     </li>
                     </ul>
                     </div>
            }
             {orderStatus === '5' &&
                  <>
                  <div className='order-details-list'>
                     <ul className='processing-list ps-0 text-end'>
                     <li><button className='btn order-pro'><img src={setting} alt="order-img" className='pe-2' />Completed</button> 
                     <div className='proces-status' onClick={()=>{setChangeOrderStatusId(x?.id);setChangeOrderStatus(!changeOrderStatus)}}>
                        <p>Change order status</p>
                     </div>
                     </li>
                     </ul>
                     </div>
                     {changeOrderStatus && x?.id === changeOrderStatusId &&
                      <div className='process-on-click' ref={divRef}>
                      <p onClick={()=>{setChangeOrderStatusId('');setChangeOrderStatus(false)}}>Change to</p>
                      <ul  className='processing-list ps-0'>
                      <li className='text-start mb-2'><button className='btn order-dine' onClick={()=>{setChangeOrderStatus(false)}}><img src={declineorder} alt="order-img" className='pe-2' />Order declined</button></li>
                      <li className='text-start mb-2'><button className='btn order-pro' onClick={()=>{UpdateOrderDetail(x?.id,'2');setChangeOrderStatus(false)}} disabled={loader}>{loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}<img src={setting} alt="order-img" className='pe-2' />Order processing</button></li>
                      <li className='text-start mb-2'><button className='btn ready-to-pick' onClick={()=>{setChangeOrderStatus(false)}}><img src={pickup} alt="order-img" className='pe-2' />Ready to pick up</button></li>
                      <li className='text-start mb-2'><button className='btn order-complete' onClick={()=>{UpdateOrderDetail(x?.id,'5');setChangeOrderStatus(false)}} disabled={loader}>{loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}<img src={smiley} alt="order-img" className='pe-2' />Completed</button></li>
                      </ul>
                      </div>
            }
                      </>
            }
                  </div>
                </div>
              </div>
            </div>
           
            )
            
            })}
            </>
          }
             </div>
}
            </div>
            {/* <div className='col-md-12'>
              <div className='define-left'>
                <div className='row'>
                  <div className='col-sm-2'>
                  <span className='product-imgs'>
                    <img src='' alt="order-img" />
                    </span>
                  </div>
                  <div className='col-sm-6'>
                    <div class="order-info">
                      <h5>Necklace</h5>
                      <p>Quantity <span className='fw-bold ps-2'>2</span></p>
                      <p className='mb-2'><span className='fw-bold pe-2'>Order #</span>  232456789</p>
                      <p>Customer<span className='fw-bold ps-2'>Jenny Wilson</span></p>
                      <p>Pick up from <span className='fw-bold ps-2'>123 Main Street, Anytown</span></p>
                      <p>Ordered<span className='fw-bold ps-2'>06 March 2024</span> </p>
                    
                      <p class="text-success">Payment successful</p>
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div class="btn-group">
                    <button class="btn btn-decline mx-3">Decline<img src={crosstick} alt="order-img" className='ps-2' /></button>
                    <button class="btn btn-acept mx-3">Accept<img src={righttick} alt="order-img" className='ps-2' /></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-12'>
              <div className='define-left'>
                <div className='row'>
                  <div className='col-sm-2'>
                  <span className='product-imgs'>
                    <img src='' alt="order-img" />
                    </span>
                  </div>
                  <div className='col-sm-6'>
                    <div class="order-info">
                      <h5>Necklace</h5>
                      <p>Quantity <span className='fw-bold ps-2'>2</span></p>
                      <p className='mb-2'><span className='fw-bold pe-2'>Order #</span>  232456789</p>
                      <p>Customer<span className='fw-bold ps-2'>Jenny Wilson</span></p>
                      <p>Pick up from <span className='fw-bold ps-2'>123 Main Street, Anytown</span></p>
                      <p>Ordered<span className='fw-bold ps-2'>06 March 2024</span> </p>
                    
                      <p class="text-success">Payment successful</p>
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div class="btn-group">
                    <button class="btn btn-decline mx-3">Decline<img src={crosstick} alt="order-img" className='ps-2' /></button>
                    <button class="btn btn-acept mx-3">Accept<img src={righttick} alt="order-img" className='ps-2' /></button>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>

        </div>
      </div>
      <Modal
            isOpen={isModal}
            onRequestClose={()=>{setIsModal(false)}}
            contentLabel="Accept Modal"
            shouldCloseOnOverlayClick={true}
            ariaHideApp={false}
            overlayClassName={`modal-overlay add-category  accept-modal creatorPublishModal `}
          >
            <button onClick={()=>{setIsModal(false)}} className='close-button'><img src={graycross} alt=''/></button>
            {/* <h2>Confirm Accept</h2> */}
            <AcceptModal singleOrderData={singleOrderData} />
            
          </Modal>
      <Modal
            isOpen={isModalDelete}
            onRequestClose={()=>setIsModalDelete(false)}
            contentLabel="Accept Modal"
            shouldCloseOnOverlayClick={false}
            ariaHideApp={false} 
            overlayClassName={`modal-overlay creatorLiveModal decline`}
          >
            {/* <button onClick={()=>setIsModalDelete(false)}><img src={graycross} alt=''/></button> */}
            <DeleteModal setDeclineModal={setDeclineModal} setIsModalDelete={setIsModalDelete}/>
            {/* <button onClick={()=>{setDeclineModal(true);setIsModalDelete(false)}}>Confirm Delete</button> */}
            
          </Modal>
      <Modal
            isOpen={declineModal}
            onRequestClose={()=>setDeclineModal(false)}
            contentLabel="Accept Modal"
            shouldCloseOnOverlayClick={true}
            ariaHideApp={false}
            overlayClassName={`modal-overlay add-category delete creatorPublishModal accept-modal`}
          >
           {/* <button onClick={()=>setDeclineModal(false)}><img src={graycross} alt=''/></button> */}
            <DeclineModals setDeclineMessage={setDeclineMessage} declineMessage={declineMessage} data={singleOrderData} UpdateOrderDetail={UpdateOrderDetail} declineLoader={declineLoader}/>
            {/* <h2>Confirm Decline</h2> */}
            
          </Modal>
    </div>
  )
}

export default Orders

