import React, { useEffect, useState } from 'react'
import Sidebar from '../../../Layout/sidebar'
import annete from '../../../assets/images/annete.png'
import discoveryCall from '../../../assets/images/discovery-call.svg'
import facebook from '../../../assets/images/creator-facebook.svg'
import twitter from '../../../assets/images/creator-twitter.svg'
import instagram from '../../../assets/images/Instagram-Logo.wine 1.svg'
import facebookgrey from '../../../assets/images/facebook2.svg'
import twittergrey from '../../../assets/images/twiter-grey.svg'
import instagramgrey from '../../../assets/images/Insta-grey.svg'
import yogaHero from '../../../assets/images/yoga-hero.svg'
import healPlatform from '../../../assets/images/heal-platform.svg'
import Gallery from '../../HomeComponent/CreatorComponent/gallery'
import { getApi, postApi } from '../../../Services/apiService'
import UserSidebar from '../../../Layout/userSidebar'
import burger from '../../../assets/images/burger-menu.svg'
import { Link, useNavigate } from 'react-router-dom'
import arrowShare from '../../../assets/images/arrow-share.png'
import videoDummy from '../../../assets/images/video-profile-dummy.svg'
import bannerRight from '../../../assets/images/dummy-profile.jpg'
import hearts from '../../../assets/images/heart.svg'
import dummyProfile from '../../../assets/images/dummy-profile.jpg'
import { toast } from 'react-toastify'


function DetailCreator() {
	const [classState, setClassState] = useState(false)
	const [userDetails, setUserDetails] = useState('')
	const [apiResponse, setApiResponse] = useState('')
	const [offeringData, setOfferingData] = useState('')
	const [page, setPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	const [isExpanded, setIsExpanded] = useState(false);
	const [pageLoader,setPageLoader] = useState(false)
	const [videoLoader,setVideoLoader] =useState(false)
	const [imageLoader,setImageLoader] = useState(false)

	const toggleText = () => {
		setIsExpanded(!isExpanded);
	};

	// Ensure description is defined and is a string

	const navigate = useNavigate()

	// const [userData,setUserData] = useState([])
	const localDataUser = JSON.parse(localStorage.getItem('full_user_detail'));
	const id = localDataUser?.id;
	useEffect(() => {
		fetchUserDeatils()
		fetchUserOfferings()
		const handleClick = () => setClassState(false);

		document.addEventListener('click', handleClick);

		// Cleanup event listener on component unmount
		return () => {
			document.removeEventListener('click', handleClick);
		};
	}, [page])
	const userData = JSON.parse(localStorage.getItem('full_user_detail'));
	const userSocialLink = JSON.parse(localStorage.getItem('user_social_links'))
	console.log('this is user details from local storage', userData);
	const handleSidebarClick = (event) => {
		event.stopPropagation();
		setClassState(!classState);
	};

	const fetchUserDeatils = async () => {
		setPageLoader(true)
		const response = await getApi('/creator/home');
		if (response?.statusCode === 200) {
			setUserDetails(response.data)
		}
		else {
			localStorage.clear();
			navigate('/')
		}
		setPageLoader(false)
	}
	const singleService = (id) => {
		navigate(`/creator/offering/${id}`)
	}
	const fetchUserOfferings = async () => {
		const response = await getApi(`/creator/home-services?page=${page}&pageSize=9`);
		if (response?.statusCode === 200) {
			setApiResponse(response)
			setTotalCount(response.totalCount); // Set the total count from API response
			setOfferingData((prevOfferings) => [...prevOfferings, ...response.data]); // Append new data to existing offerings
		}
		// else {
		// 	localStorage.clear();
		// 	navigate('/');
		// }
	};

	//   / Function to handle loading more offerings
	const loadMoreOfferings = () => {
		setPage((prevPage) => prevPage + 1); // Increment the page number
	};
	console.log('this is user details', userDetails);


	// Ensure description is defined and is a string



	const navSetting = () => {
		navigate('/creator/settings')
	}
	const description = userData?.about || '';
	const isLongDescription = description.length > 400;
	const truncatedDescription = description.length > 400 ? `${description.slice(0, 400)}...` : description;

	const handleVideoChange = async(event) =>{
		setVideoLoader(true)
		const file = event.target.files[0]
		// const file = e?.target?.value
		const maxSize = 50 * 1024 * 1024; // 200 MB in bytes
		let video;
		if (file) {
			if (file.size > maxSize) {
				toast.error('File size exceeds 50 MB. Please select a smaller file.');
				video = null;
			} else {
				if (video && video.file) {
					URL.revokeObjectURL(video.url);
				}
				video = { file, url: URL.createObjectURL(file) };
			}
		}
		const formData = new FormData();
	  
		if (video && video.file) {
			formData.append('profile_video', video.file);
		}
		const response = await postApi('/profile-video',formData)
		if (response?.statusCode === 200) {
		  fetchUserDeatils()
		  console.log('Upload successful!');
		  // navigate('/creator/home')
	  } else {
		  console.error('Upload failed.');
	  }
	  setVideoLoader(false)
	  }
	  const handleImageChange = async(event) => {
		setImageLoader(true)
		const files = Array.from(event.target.files);
		const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
		let validImages = [];
		let images=[];
	  
		files.forEach((file) => {
			if (file.size > maxSize) {
				toast.error('One or more files exceed 5 MB. Please select smaller files.');
			} else {
				validImages.push({
					file,
					url: URL.createObjectURL(file),
					name: file.name,
				});
			}
		});
	  
		images = ([...images, ...validImages]);
		const formData = new FormData();
	  
		images.forEach((image, index) => {
		  formData.append(`profile_image`, image.file);
	  });
		const response = await postApi('/profile-img',formData)
		if (response?.statusCode === 200) {
		  fetchUserDeatils()
		  console.log('Upload successful!');
		  // navigate('/creator/home')
	  } else {
		  console.error('Upload failed.');
	  }
	  setImageLoader(false)
	  };

	return (
		<div class="creators-detail">
			<UserSidebar classState={classState} name={userData} />
			{pageLoader ?
			<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
			:
			<div class="right-main creators-detail-right creator-my-page">
				<section class="creators-top detail my-page-creator">

					<div class="my-page"> <button class="burger-menu" onClick={handleSidebarClick}><img src={burger} alt='' /></button>My Page</div>
					<div class="row">
						<div class="col-xl-3 col-lg-4 col-sm-4 ">
							<div class="creator-detail-left">
							<div className=''>
								<div className='discovery-call-flex'>
								{/* <div class="creator-detail-img"><span><img src={userData?.user_profile_image || dummyProfile} /></span>
								</div> */}
								 <div class="creator-detail-img bg-none creator-detail-diff">
                   <div className=''>
                  <label htmlFor="image-input" className="file-label">
                     <span>
                    {/* <img src={userData?.user_profile_image || dummyProfile} alt='' /> */}
                    {imageLoader ?
                    <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    :<img src={userDetails?.user_profile_image || userData?.user_profile_image || dummyProfile} alt='' />}
                  
                    </span>
                    <p className={`selectext ${userDetails?.user_profile_image!='' && userDetails?.user_profile_image!=null ? 'dispaly':''}`}>Select Image</p>
                   </label>
                   
                    </div>
                    {userDetails?.user_profile_image === null &&
                    <>
                       
                        <input
                            type="file"
                            id="image-input"
                            className="file-input"
                            accept="image/jpeg,image/png,image/gif,video/*"
                            multiple
                            onChange={handleImageChange}
                        />
                        </>}
                   
                  </div>
								<div class="setting-right justify-content-between"><h3 className='mx-0'>Discovery call</h3><div class="switch-button text-end">
									<label class="switch"><input type="checkbox" disabled="" checked={localDataUser?.discovery_call ==='1'}  onClick={() => navSetting()}/>
									{/* <label class="switch"><input type="checkbox" disabled="" checked="checked"  onClick={() => navSetting()}/> */}
									<span class="slider"></span></label></div></div>
									</div>
								{/* <button class="discovery" onClick={() => navSetting()}>Discovery call <img src={discoveryCall} /></button> */}
								<div class="creator-soial">
									<ul>
										<li><Link to={userSocialLink && userSocialLink[0]?.facebook_link ? userSocialLink[0]?.facebook_link : ''}><img src={userSocialLink && userSocialLink[0]?.facebook_link ? facebook : facebookgrey} /></Link></li>
										<li><Link to={userSocialLink && userSocialLink[0]?.twitter_link ? userSocialLink[0]?.twitter_link : ''}><img src={userSocialLink && userSocialLink[0]?.twitter_link ? twitter : twittergrey} /></Link></li>
										<li><Link to={userSocialLink && userSocialLink[0]?.instagram_link ? userSocialLink[0]?.instagram_link : ''}><img src={userSocialLink && userSocialLink[0]?.instagram_link ? instagram : instagramgrey} /></Link></li>
									</ul>
								</div>
								</div>
								{/* <div class="associated">
									<h4>Associated with</h4>
									<ul>
										<li><span><img src={yogaHero} /> </span>
											<h4>“Yoga here studio”</h4>
										</li>
										<li><span><img src={healPlatform} /></span>
											<h4>“Heal platform”</h4>
										</li>
									</ul>
								</div> */}
							</div>
						</div>
						<div class="col-xl-6 col-lg-8 col-sm-8">
							<div class="creator-detail-right">
								<h3>{userData?.name}</h3>
								<h4>{userData?.specializations && userData?.specializations?.length > 0 && userData?.specializations[0]?.experience}</h4>
								{(localDataUser?.tags && localDataUser?.tags?.length > 0) && 
								<div className='scrooll-tag-home'>{localDataUser?.tags && localDataUser?.tags?.length > 0 && localDataUser?.tags?.map((x, index) => {
									return (
										<label key={index}>{x}</label>
									)
								})}
								</div>
								}
								<h5>About Me</h5>
								<h6 className='exper'>{userData?.specializations && userData?.specializations?.length > 0 && userData?.specializations[0]?.experience && 'Experience:'} {userData?.specializations && userData?.specializations?.length > 0 && userData?.specializations[0]?.experience}</h6>
								<h6 className='locate'>{userData?.location !== undefined && userData?.location !==null && userData?.location !=='' && 'Location: '+ userData.location.replace(/,(\S)/g, ', $1')}</h6>
								<p>
									{isExpanded || !isLongDescription ? description : truncatedDescription}
									<br />
									{isLongDescription && (
										<strong onClick={toggleText} style={{ cursor: 'pointer' }} className='float-end'>
											{isExpanded ? 'Read less' : 'Read more'}
										</strong>
									)}
								</p>
							</div>
						</div>
						<div className='col-xl-3 col-lg-12 col-sm-12 '>
							{/* <div className={`upload-video uploaded  ${userData?.introduction_video ? 'choose-video add' : ''}`}>
								{userData?.introduction_video ? (
									<video controls width="100%">
										<source src={userData.introduction_video} type="video/mp4" />
										Your browser does not support the video tag.
									</video>
								) : (
									<>
										<span><img src={videoDummy} alt="Video Placeholder" /></span>
										<h5>Upload introduction video <img src={arrowShare} alt="Share Icon" /></h5>

									</>
								)}
							</div> */}
							<div className={`upload-video ${(userDetails?.introduction_video || userData?.introduction_video) ?'add':''}`}>
                  <diiv className="">
                  <label htmlFor="video-input" className="file-label">
                  <span><img src={videoDummy} /></span>
                  Upload introduction video
                        </label>
                  </diiv>
               
                
                  {videoLoader ?<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                  :
				  userDetails?.introduction_video || userData?.introduction_video ? (
                  <video controls width="100%">
                  {/* <source src={userData.introduction_video} type="video/mp4" /> */}
                  <source src={userDetails?.introduction_video || userData.introduction_video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                  
                  ) : (
									<>
                       
               
                        <input
                            type="file"
                            id="video-input"
                            className="file-input"s
                            accept="video/*"
                            onChange={handleVideoChange}
                        />
                    {/* <img src={arrowShare} /> */}
										{/* <h5>Upload introduction video <img src={arrowShare} alt="Share Icon" /></h5> */}

									</>
								)}
                  {/* <h5>Upload introduction video</h5> */}
                
                </div>
						</div>
					</div>
				</section>
				<section class="offerings pt-0">
					<h3 class="offering">Offerings by {userDetails?.name}</h3>

					{offeringData && offeringData?.length <= 0 ? 
						<div>No offering found</div>
					:
					<>
					<div class="links mention-tags">
					{apiResponse?.tag && apiResponse?.tag?.length > 0 &&<h4>Mention tags</h4>}

						<ul>
							{apiResponse?.tag && apiResponse?.tag?.length > 0 && apiResponse?.tag.map((x, index) => {
								return (
									<>
										<li><a >{x?.tag_name}</a> </li>
									</>
								)
							}
							)
							}
						</ul>
					</div>


					<div class="row">
						{offeringData && offeringData?.length > 0 && offeringData.map((x, index) => {
							return (
								<div class="col-sm-4" onClick={() => singleService(x?.id)} key={index} >
									<div class="product-box">
										<div class="product-img">
											<img src={x?.cover_image} alt='' />
											<div class="product-image-data">
												{x?.category && x?.category?.category_image && <span><img src={x?.category && x?.category?.category_image} alt='' /></span>}
												<h5>{x?.service_type === "2"
                                                                                ? (x?.product_kind === "0" ? 'Physical Product' : 'Digital Product')
                                                                                : (x?.service_type === "1"
                                                                                    ? (x?.location_type === "1" ? 'Virtual'
                                                                                        : (x?.location_type === "2" ? 'In Person'
                                                                                        // : (x?.location_type === "2" ? 'Offline'
                                                                                            : (x?.location_type === "3" ? 'Virtual/In person' : '')))
                                                                                    : 'In Person')}</h5>
												<button><img src={hearts} alt='' /></button>
											</div>
										</div>
										<div class="product-data">
											<h3>{x?.name} </h3>
											<ul className='product-data-tags'>
												{x?.tags && x?.tags?.length > 0 && x?.tags.map((y, i) => {
													return (
														<li class={i === 0 && "active"}>{y?.tag_name}</li>
													)
												})}
											</ul>
											<p>
												{x?.description}
											</p>
											<div class="product-data-bottom">
												<span><img src={x?.user?.user_profile_image && x?.user?.user_profile_image || bannerRight} alt='' /></span>
												<h6><strong>{x?.user && x?.user?.name}</strong> {x?.user?.specializations && x?.user?.specializations[0]?.area_of_work}</h6>
											</div>
										</div>
									</div>
								</div>
							)
						}
						)
						}


					</div>
					{offeringData?.length < totalCount && (
						<button className="explore" onClick={loadMoreOfferings}>
							Load more Offerings
						</button>
					)}
</>
}
				</section>
				{userDetails?.userGallery?.length !== 0 && (
					<Gallery galleryImages={userDetails?.userGallery || []} />
				)}
			</div>
}
		</div>
	)
}

export default DetailCreator