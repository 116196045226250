import React, { useEffect, useState } from 'react'
import Sidebar from '../../../Layout/sidebar'
import background3 from '../../../assets/images/background3.svg'
import cross from '../../../assets/images/gray-close5.svg'
import addtag from '../../../assets/images/add-tag.svg'
import whiteArrow from '../../../assets/images/white-arrow.svg'
import { Link, useNavigate } from 'react-router-dom'
import annete from '../../../assets/images/annete.png'
import { getApi, postApi } from '../../../Services/apiService'
import UserSidebar from '../../../Layout/userSidebar'
import burger from '../../../assets/images/burger-menu.svg'
import dummy from '../../../assets/images/dummy-profile.jpg'
import Modal from 'react-modal';
import crossbutton from '../../../assets/images/cross-button.svg'
import CreatorTags from './creatorTags'
import arrowShare from '../../../assets/images/arrow-share.png'
import videoDummy from '../../../assets/images/video-profile-dummy.svg'
import { toast } from 'react-toastify'
import dummyProfile from '../../../assets/images/profile3.svg'
import unauthorized from '../../../assets/images/puzzle_popup.svg'
import timeslot from '../../../assets/images/clocck-setting.svg'
import popupedit from '../../../assets/images/popup-edit-img.svg'
import stresreduse from '../../../assets/images/stress-reduction.svg'
import medical from '../../../assets/images/medical_discover.svg'





function CreatorHome() {
  const [classState, setClassState] = useState(false)
  const [userData, setUserData] = useState([])
  const [tagModal, setTagModal] = useState(false)
  const [profileCustomize,setProfileCustomize] = useState(false)
  const [firstOffering,setFirstOffering] = useState(false)
  const [paymentmethod,setPaymentMethod] = useState(false)
  const [publishOffering,setPublishOffering] = useState(false)
  const [openModal,setOpenModal] = useState(false);
  const [pageLoader,setPageLoader] = useState(false)
  const [imageLoader,setImageLoader] = useState(false)
  const [videoLoader,setVideoLoader] = useState(false)

  const navigate = useNavigate()
  const localDataUser = JSON.parse(localStorage.getItem('userDetail'));
  const userRole = localStorage.getItem('user_role')
  const id = localDataUser?.id;
  useEffect(() => {
    fetchUserDeatils()
    const handleClick = () => setClassState(false);

    document.addEventListener('click', handleClick);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [])
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleText = () => {
      setIsExpanded(!isExpanded);
  };

  // Ensure description is defined and is a string
  const description = userData?.about || '';
  const isLongDescription = description.length > 400;
  const truncatedDescription = description.length > 400 ? `${description.slice(0, 400)}...` : description;
let fullUSerDetails=JSON.parse(localStorage.getItem('full_user_detail'));

  const fetchUserDeatils = async () => {
    setPageLoader(true)
    let response;
    if(userRole==='creator')
     response = await getApi('/creator/dashboard');
  if(userRole==='admin'){
    response = await getApi('/admin/creators/info');
  }
    if (response?.statusCode === 200) {
      localStorage.setItem('full_user_detail', JSON.stringify(response?.data))
      localStorage.setItem('user_role', response?.data?.roles[0]?.user_role)
      localStorage.setItem('user_social_links', JSON.stringify(response?.data?.social_links))
      // Navigate to dashboard if customization_profile is 1
      setPageLoader(false)
      if (response?.data?.is_user_updated === 1) {
        navigate('/creator/dashboard');
      }
      setUserData(response?.data);
    }
    else {
      setPageLoader(false)
      // localStorage.clear();
      // navigate('/')
    }
  }

  const handleSidebarClick = (event) => {
    event.stopPropagation();
    setClassState(!classState);
  };

  const closeModal = () => {
    setTagModal(false)
  }


  const navSetting = () => {
    navigate('/creator/settings')
  }

  const handleUpdateInformation = async() =>{
    // if(userData?.onboarding_interview === 1 && profileCustomize && paymentmethod && firstOffering && publishOffering){
    if(userData?.onboarding_interview === 1 && userData?.user_profile_image!==null && userData?.about!==null && userData?.location!==null && userData?.is_connected && userData?.publish_offer===1){
      const data = {

      }
      const response = await postApi('/creator/updateHomePage');
      if(response?.statusCode === 200){
        navigate('/creator/dashboard')
      }
    }
    else{
      toast.error("Please first check all checkboxes to update the information")
    }
  }

  const closeModals = () =>{
    setOpenModal(false)
}

const handleVideoChange = async(event) =>{
  setVideoLoader(true)
  const file = event.target.files[0]
  // const file = e?.target?.value
  const maxSize = 50 * 1024 * 1024; // 200 MB in bytes
  let video;
  if (file) {
      if (file.size > maxSize) {
          toast.error('File size exceeds 50 MB. Please select a smaller file.');
          video = null;
      } else {
          if (video && video.file) {
              URL.revokeObjectURL(video.url);
          }
          video = { file, url: URL.createObjectURL(file) };
      }
  }
  const formData = new FormData();

  if (video && video.file) {
      formData.append('profile_video', video.file);
  }
  const response = await postApi('/profile-video',formData)
  if (response?.statusCode === 200) {
    fetchUserDeatils()
    console.log('Upload successful!');
    // navigate('/creator/home')
} else {
    console.error('Upload failed.');
}
setVideoLoader(false)
}

const handleImageChange = async(event) => {
  setImageLoader(true)
  const files = Array.from(event.target.files);
  const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
  let validImages = [];
  let images=[];

  files.forEach((file) => {
      if (file.size > maxSize) {
          toast.error('One or more files exceed 5 MB. Please select smaller files.');
      } else {
          validImages.push({
              file,
              url: URL.createObjectURL(file),
              name: file.name,
          });
      }
  });

  images = ([...images, ...validImages]);
  const formData = new FormData();

  images.forEach((image, index) => {
    formData.append(`profile_image`, image.file);
});
  const response = await postApi('/profile-img',formData)
  if (response?.statusCode === 200) {
    fetchUserDeatils()
    console.log('Upload successful!');
    // navigate('/creator/home')
} else {
    console.error('Upload failed.');
}
setImageLoader(false)
};

  return (
    <div class="home">
      <UserSidebar classState={classState} name={userData} />
     

      <div class="right-main home-right welcome-mircorcosm">
        <div class="header">
          <span><img src={background3} alt='' /> </span>

          <div class="header-content">

            <h1><button class="burger-menu" onClick={handleSidebarClick}><img src={burger} alt='' /></button> Welcome to Microcosm!</h1>
            <h2>Do what you love for a living!</h2>
          </div>
          {/* <button class="cross"><img src={cross} alt='' /> </button> */}
        </div>
        {pageLoader ?
      <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      :
        <div class="home-main">
          <section class="creators-top detail res-creator-cs">
            <div class="my-page">My Page</div>
            <div class="row">
              <div class="col-sm-5 col-xl-3 col-lg-5">
                <div class="creator-detail-left">
                  <div class="creator-detail-img bg-none creator-detail-diff">
                   <div className=''>
                  <label htmlFor="image-input" className="file-label">
                     <span>
                    {/* <img src={userData?.user_profile_image || dummyProfile} alt='' /> */}
                    {imageLoader ?
                    <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    :<img src={userData?.user_profile_image || dummyProfile} alt='' />}
                  
                    </span>
                    <p className={`selectext ${userData?.user_profile_image!='' && userData?.user_profile_image!=null ? 'dispaly':''}`}>Select Image</p>
                   </label>
                   
                    </div>
                    {userData?.user_profile_image === null &&
                    <>
                       
                        <input
                            type="file"
                            id="image-input"
                            className="file-input"
                            accept="image/jpeg,image/png,image/gif,video/*"
                            multiple
                            onChange={handleImageChange}
                        />
                        </>}
                   
                  </div>
                  {/* <div class="associated top">
                    <h4 onClick={() => navSetting()}>Discovery call</h4>
                    <div class="switch-button">
                      <label class="switch">
                        <input type="checkbox" />
                        <span class="slider"></span>
                      </label>
                    </div>
                    <h5>Offer a free call to help users  <br />
                      understand your offering</h5>
                  </div> */}
                  <div class="associated top border-0 pt-0">
                    <div class="header-section">
                      <h4 >Discovery call</h4>
                      <div class="switch-button" onClick={() => navSetting()}>
                        <label class="switch">
                          <input type="checkbox" checked={fullUSerDetails?.discovery_call ==='1'}/>
                          <span class="slider"></span>
                        </label>
                      </div>
                    </div>
                    <h5>Offer a free call to help users <br />understand your offering</h5>
                  </div>

                  <div class="associated bottom ">
                    <h4>Associated with</h4>
                    <h5>Add people or places <br />
                      you collaborate with here</h5>
                  </div>
                </div>
              </div>
              <div class="col-sm-7 col-xl-6 col-lg-7">
                <div class="creator-detail-right">
                  <h3>{userData?.name}</h3>
                  <h4>{userData?.specializations && userData?.specializations.length > 0 && userData?.specializations[0]?.area_of_work}</h4>
                  {userData?.tags && userData?.tags?.length > 0 &&
                  <div class="links mention-tags">

						<ul className='w-100'>
							{userData?.tags && userData?.tags?.length > 0 && userData?.tags.map((x, index) => {
								return (
									<>
										<li><a >{x}</a> </li>
									</>
								)
							}
							)
							}
						</ul>
					</div>
}
                  <button class="add-tags" onClick={() => setTagModal(true)}>
                    <img src={addtag} alt='' /><span>Add tags</span>
                  </button>

                  <h5>About Me</h5>
                  <h6>{userData?.specializations && userData?.specializations.length > 0 && userData?.specializations[0]?.experience && 'Experience:'} {userData?.specializations && userData?.specializations.length > 0 && userData?.specializations[0]?.experience}</h6>
                  <h6>{userData?.location !== undefined && userData?.location !==null && userData?.location !=='' && 'Location: '+ userData.location.replace(/,(\S)/g, ', $1')}</h6>
                  {/* <p>{userData?.about}</p> */}
                  <p>
                                        {isExpanded || !isLongDescription ? description : truncatedDescription}
                                        <br />
                                        {isLongDescription && (
                                            <strong onClick={toggleText} style={{ cursor: 'pointer' }}>
                                                {isExpanded ? 'Read less' : 'Read more'}
                                            </strong>
                                        )}
                                    </p>
                  {/* {userData?.about != null && <p>Don't forget to complete the 'About Me' section</p>} */}
                </div>
                <Modal
                  isOpen={tagModal}
                  onRequestClose={() => setTagModal(false)}
                  contentLabel="Tag Modal"
                  shouldCloseOnOverlayClick={false}
                  portalClassName="add-tag-modal"
                >
                  {/* <button className='close-button' onClick={() => setTagModal(false)}><img src={crossbutton} alt='' /></button> */}
                  <CreatorTags closeModal={closeModal} fetchUserDeatils={fetchUserDeatils}/>
                </Modal>
              </div>
              <div className='col-sm-12 col-lg-12 col-xl-3'>
              {/* <div className='col-sm-12 col-lg-12 col-xl-3' onClick={() => navSetting()}> */}
                <div className={`upload-video ${userData?.introduction_video ?'add':''}`}>
                  <diiv className="">
                  <label htmlFor="video-input" className="file-label">
                  <span><img src={videoDummy} /></span>
                  Upload introduction video
                        </label>
                  </diiv>
               
                
                  {videoLoader ?<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                  :
								userData?.introduction_video ? (
                  <video controls width="100%">
                  <source src={userData.introduction_video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                  
                  ) : (
									<>
                       
               
                        <input
                            type="file"
                            id="video-input"
                            className="file-input"s
                            accept="video/*"
                            onChange={handleVideoChange}
                        />
                    {/* <img src={arrowShare} /> */}
										{/* <h5>Upload introduction video <img src={arrowShare} alt="Share Icon" /></h5> */}

									</>
								)}
                  {/* <h5>Upload introduction video</h5> */}
                
                </div>
              </div>
            </div>

          </section>
          <div class="cretor-middle">
            <h1 onClick={()=>setOpenModal(true)}>Getting started</h1>
            {/* <Link to="/creator/settings"><button class="update">Update information <img src={whiteArrow} alt='' /> </button></Link> */}
            <button class="update" onClick={()=>handleUpdateInformation()}>Update information <img src={whiteArrow} alt='' /> </button>
          </div>

          <div class="creator-bottom">
            <div class="bottom-left text-decoration">
              <label class="container2 myDIV">
                Onboarding call
                <input type="checkbox" checked={userData?.onboarding_interview === 1} />
                <span class="checkmark"></span>
              </label>
              <div className='hide'>
                Please book and complete an onboarding call. This brief interview will help us tailor your experience and schedule your first session</div>
              <label class="container2 myDIV">
                Customize your profile
                <input type="checkbox" checked={userData?.user_profile_image!==null && userData?.user_profile_image!=='' && userData?.about!==null && userData?.about!=='' && userData?.location!==null && userData?.location!==''} onClick={()=>navigate('/creator/settings')}/>
                {/* <input type="checkbox" checked={profileCustomize} onClick={()=>setProfileCustomize(!profileCustomize)}/> */}
                {/* <input type="checkbox" checked={userData?.customization_profile === 1} /> */}
                <span class="checkmark"></span>
              </label>
              <div className='hide'>
              <div>Personalize your profile to reflect your unique skills and preferences</div>
              </div>
              
              <label class={`container2 myDIV ${(userData?.onboarding_interview===1)? '' : 'disabled'}`}>
                Create your first offering
                <input type="checkbox" checked={(userData?.is_connected && userData?.publish_offer===1)} onClick={()=>navigate('/creator/offering/home')}/> {/* onClick={()=>{setFirstOffering(!firstOffering);}} */}
                {/* <input type="checkbox" checked={(userData?.is_connected && userData?.publish_offer===1) ||firstOffering} onClick={()=>navigate('/creator/offering/home')}/> onClick={()=>{setFirstOffering(!firstOffering);}} */}
                <span class="checkmark"></span>
              </label>
              <div className='hide'>
              <div >Ready to get started? Click here to create your first offering and showcase your expertise</div>
              </div>
            </div>
            <div class="bottom-right text-decoration">
              {/* <label className={`container2 myDIV ${(userData?.is_connected && userData?.publish_offer===1)   ? '' : 'disabled'}`}> */}
              <label className={`container2 myDIV ${(userData?.onboarding_interview===1)   ? '' : 'disabled'}`}>
              {/* <label className={`container2 ${userData?.first_offer !== 0 ? '' : 'disabled'}`}> */}
                Enter payment method
                <input type="checkbox" checked={userData?.is_connected} onClick={()=>userData?.is_connected==false && navigate('/creator//creator/offering/home')} />
                {/* <input type="checkbox" checked={paymentmethod} onClick={()=>setPaymentMethod(!paymentmethod)} /> */}
                <span class="checkmark"></span>
              </label>
              <div className='hideshow'>
              <div>Set up your payment method to start earning. Click here to enter your details securely</div>
              </div>
              {/* <label className={`container2 ${userData?.payment_method === 1 ? '' : 'disabled'}`}> */}
              <label className={`container2 myDIV ${(userData?.onboarding_interview===1) ? '' : 'disabled'}`}>
              {/* <label className={`container2 myDIV ${(userData?.is_connected && userData?.publish_offer===1) ? '' : 'disabled'}`}> */}
                Publish your first offering
                <input type="checkbox" checked={userData?.publish_offer===1} onClick={()=>userData?.publish_offer!==1 && navigate('/creator//creator/offering/home')}/>
                {/* <input type="checkbox" checked={publishOffering} onClick={()=>setPublishOffering(!publishOffering)}/> */}
                <span class="checkmark"></span>
              </label>
              <div className='hidelast'>
              <div>Publish it and let the world see what you’ve got</div>
              </div>
            </div>

          </div>
          {/* <div class="cretor-middle">
            <h1 >My Offerings</h1>
            <div className='mx-auto text-center'>
              <div className=''>
              <img src={medical} alt='medical'/>
              </div>
              <div className=''>
              <p>You haven't published any offers yet.</p>
              </div>
              <button class="btn-create-offer" ><img src={addtag} alt='' className='pe-2' />Create first offering </button>
            </div>
                      
          
          </div> */}


        </div>
}
</div>

      {/* <Modal
        isOpen={openModal}
        onRequestClose={closeModals}
        contentLabel="Delete Modal"
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
        overlayClassName={`modal-overlay  verification-modal puzzle-modal`} >
          <button className='close-button ' onClick={() => closeModals()}><img src={cross} alt='' /></button>
        <h1>Update Your Selection</h1>
        <h2 class="text-center ">You’ve selected a recurrence of 2 times a week. To add 3 times, please update your recurrence settings.</h2>
        <span class="d-block text-center"> <img src={unauthorized} /></span>
        <p></p>
      </Modal> */}

   {/* <Modal
        isOpen={openModal}
        onRequestClose={closeModals}
        contentLabel="Delete Modal"
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
        overlayClassName={`modal-overlay  verification-modal timeslot`} >
          <button className='close-button ' onClick={() => closeModals()}><img src={cross} alt='' /></button>
        <h1>Please fill the Friday timeslot.</h1> 
      <span class="d-block text-center"> <img src={timeslot} /></span>
        <p></p>
      </Modal> */}
            
{/*             
            <Modal
            isOpen={openModal}
            onRequestClose={closeModals}
            contentLabel="Delete Modal"
            shouldCloseOnOverlayClick={false}
            ariaHideApp={false}
            overlayClassName={`modal-overlay  verification-modal time-avaliable timeslot`} >
              <button className='close-button ' onClick={() => closeModals()}><img src={cross} alt='' /></button>
            <h1>Please fill in the time slots when you're available in the section below.</h1>
            <span class="d-block text-center"> <img src={timeslot} /></span>
            <p></p>
          </Modal> */}
{/* 
        <Modal
        isOpen={openModal}
        onRequestClose={closeModals}
        contentLabel="Delete Modal"
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
        overlayClassName={`modal-overlay  verification-modal timeslot`} >
          <button className='close-button ' onClick={() => closeModals()}><img src={cross} alt='' /></button>
        <h1>fill the required field</h1> 
         <span class="d-block text-center"> <img src={popupedit} /></span>
        <p></p>
      </Modal> */}
            
       <Modal
        isOpen={openModal}
        onRequestClose={closeModals}
        contentLabel="Delete Modal"
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
        overlayClassName={`modal-overlay  verification-modal stress-reduse`} >
          <button className='close-button ' onClick={() => closeModals()}><img src={cross} alt='' /></button>
        <h1>Save to drafts</h1>
        <h2 class="text-center ">You can edit and publish your post at any time.</h2>
        <span class="d-block text-center"> <img src={stresreduse} /></span>
        <p></p>
      </Modal> 
    </div>
  )
}

export default CreatorHome