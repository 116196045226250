import React, { useEffect, useState, useCallback } from 'react';
import exportSvg from '../../assets/images/export.svg';
import add from '../../assets/images/add.svg';
import search from '../../assets/images/search.svg';
import filterList from '../../assets/images/filter-list.svg';
import view from '../../assets/images/view.svg';
import del from '../../assets/images/del.svg';
import UserSidebar from '../../Layout/userSidebar';
import burger from '../../assets/images/burger-menu.svg';
import { deleteApi, getApi } from '../../Services/apiService';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import cross from '../../assets/images/cross-button.svg';
import { toast } from 'react-toastify';
import AdminMultiStep from '../AdminComponents/adminMultistep'
import { clearUserData } from '../../Counter/userSignUpSlice';
import { useDispatch, useSelector } from 'react-redux';
import dummy from '../../assets/images/dummy-profile.jpg'

import jsPDF from 'jspdf';
import autoTable from "jspdf-autotable";

function Members() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [pageLoader, setPageLoader] = useState(true);
  const [pageCircleLoader, setPageCircleLoader] = useState(true);
  const [classState, setClassState] = useState(false);
  const [userData, setUserData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [userId, setUserId] = useState('');
  const [totalCount, setTotalCount] = useState('');
  const [filtersModal, setFiltersModal] = useState(false);
  const [loader, setLoader] = useState(false)
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState({
    user: false,
    creator: false,
    all: false
  });

  const fetchData = useCallback(async (query = '', filters = {}) => {
    try {
      setPageLoader(true)
      setPageCircleLoader(true)
      
      const filterQuery = Object.entries(filters)
        .filter(([key, value]) => value)
        .map(([key]) => key)
        .join(',');

      const response = await getApi(`/admin/users?search=${query}&user_role=${filterQuery}`);
      
      if (response?.statusCode === 200) {
        setPageLoader(false)
      setPageCircleLoader(true)

        setUserData(response?.data);
        if(filterQuery !== ''){
          setTotalCount(response?.data?.length)
        }
        else{
          setTotalCount('0')
        }
      } else {
        localStorage.clear();
        navigate('/');
      }
      setPageLoader(false)
    } catch (error) {
      setPageCircleLoader(true)

      console.error('Error fetching data:', error);
    }
  }, [navigate]);

  useEffect(() => {
    fetchData(searchQuery, selectedFilters); // Initial fetch
    const handleClick = () => setClassState(false);
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [fetchData, searchQuery, selectedFilters]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSidebarClick = (event) => {
    event.stopPropagation();
    setClassState(!classState);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleDelete = async () => {
    setLoader(true)
    try {
      const response = await deleteApi(`/admin/user?userId=${userId}`, { userId });
      if (response?.statusCode === 200) {
        setLoader(false)
        toast.success(response?.message);
        setDeleteModal(false);
        fetchData(searchQuery, selectedFilters); // Refresh data after delete
      } else {
        localStorage.clear();
        navigate('/');
      }
    } catch (error) {
      setLoader(false)
      console.error('Error deleting user:', error);
    }
  };

  const handleModalClose = () => {
    fetchData(searchQuery, selectedFilters); // Refresh data when modal closes
  };

  const openModal = (id) => {
    setUserId(id);
    setDeleteModal(true);
  };

  const closeModal = (type) => {
    setUserId('');
    setDeleteModal(false);
    setShowModal(false)
  };

  const openFiltersModal = () => {
    setFiltersModal(true);
  };

  const closeFiltersModal = () => {
    setFiltersModal(false);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setSelectedFilters(prev => ({
      ...prev,
      [name]: checked
    }));
  };
 
  

  const handleShowChanges = () => {
    fetchData(searchQuery, selectedFilters); // Fetch data with selected filters
    closeFiltersModal();
  };

  const handleDownloadData = () =>{
    const doc = new jsPDF();
    // let y = 10;
    // const pageHeight = doc.internal.pageSize.height;
    // const lineHeight = 10;
  
    // userData.forEach((item, index) => {
    //   doc.text(`Id: ${item.id}`, 10, y);
    //   y += lineHeight;
    //   doc.text(`Name: ${item.name}`, 10, y);
    //   y += lineHeight;
    //   doc.text(`Specialization: ${item.specializations}`, 10, y);
    //   y += lineHeight;
    //   doc.text(`Email: ${item.email}`, 10, y);
    //   y += lineHeight;
    //   doc.text(`Date: ${item.createdAt}`, 10, y);
    //   y += lineHeight;
    //   doc.text(`Roles: ${item.roles}`, 10, y);
    //   y += lineHeight + 10;
    //   if (y + lineHeight > pageHeight) {
    //     doc.addPage();
    //     y = 10;
    //   }
    // });


  const columns = ["ID", "Name", "Specialization", "Email", "Date", "Roles"];

  const rows = userData.map((item) => [
    item.id,
    item.name,
    item.specializations,
    item.email,
    item.createdAt,
    item.roles,
  ]);

  autoTable(doc, {
    head: [columns], // Column headers
    body: rows, // Data rows
    startY: 10, // Start position on the Y-axis
    styles: { halign: "left" }, // Align content to the left
    headStyles: { fillColor: [22, 160, 133] }, // Header styling (teal color)
  });
  
    doc.save("jsonData.pdf");
  }

  // const handleUserNavigate = (x) =>{
  //   if (x?.roles.some((role) => role.user_role === "creator")) {
  //     navigate('/creator/home')
  //   } else {
  //     // console.log("user");
  //   }
  // }

  return (
    <div className="users meal-suggestion admin">
      <div className="HomeMainDiv">
        <UserSidebar classState={classState} />
        {/* {pageLoader && (
            <>
            {pageLoader && <div class="spinner"></div>
}
            </>
          )}

        <div className={`${pageLoader ? 'right-main request-member blurred' : 'right-main request-member '} `}> */}
        <div className= 'right-main request-member '>
   
          <div className="members-top">
            <h2>
              <button className="burger-menu" onClick={handleSidebarClick}>
                <img src={burger} alt='burger menu' />
              </button>
              Manage Members
            </h2>
            <div className="members-top-right">
              <button onClick={()=>handleDownloadData()}><img src={exportSvg} alt="export"/>Export</button>
              <button onClick={() => {navigate('/admin/add-member');dispatch(clearUserData());}}><img src={add} alt="add" />Add</button>
              {/* <button onClick={() => setShowModal('true')}><img src={add} alt="add" />Add</button> */}
            </div>
          </div>

          <div className="member-filter">
            <div className="filter-search filter-respon">
              <button><img src={search} alt="search" /></button>
              <input
                className="search"
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
            <button className="filter-btn" onClick={openFiltersModal}>
              <img src={filterList} alt="filters" />Filters
            </button>
            {/* <button className="filter-btn">
              <img src={filterList} alt="filters" />Filters
            </button> */}
          </div>

          <div className="overflow-scrolls">
          <div className="table-header">
            <ul>
              <li className="id">Id</li>
              <li className="name">Name</li>
              <li className="email">Email</li>
              <li className="date">Date of Joining</li>
              <li className="type">Type</li>
              {/* <li className="status">Status</li> */}
              <li className="Action">Action</li>
            </ul>
          </div>

        
            {userData && userData.length > 0 && userData.map((x, index) => (
              <ul className="table-row table-respon" key={index}>
                <li className="id">#{index + 1}</li>
                <li className="name">
                <span className='user-image'><img src={x?.User?.user_profile_image || dummy} alt='' /></span>
                <div className="user-info">
                    <h3>{x?.name}</h3>
                    <h4>{x?.specializations && x?.specializations[0]?.area_of_work || '-'}</h4>
                  </div>
                </li>
                <li className="email">{x?.email}</li>
                <li className="date">{formatDate(x?.createdAt)}</li>
                <li className="type">
                  {x?.roles && x?.roles.length > 0 && x?.roles.map((role, index) => (
                    <p className='type-1' key={index}>{role?.user_role}</p>
                  ))}
                </li>
                {/* <li className="status">
                  {x?.profile_status !== null && (
                  <button
                    className={`btn ${x?.profile_status === 'approve' ? 'approve' : x?.profile_status === 'decline' ? 'decline' : 'pending'}`}
                  >
                    {x?.profile_status}
                  </button>
                  )}
                </li> */}

                <li className="Action">
                  <button ><img src={view} alt="view" /></button> {/* onClick={()=>handleUserNavigate(x)} */}
                  <button onClick={() => openModal(x?.id)}><img src={del} alt="delete" /></button>
                </li>
              </ul>
            ))}
          </div>

          <Modal
            isOpen={showModal}
            onRequestClose={() => closeModal('admin')}
            contentLabel="Login Modal"
            shouldCloseOnOverlayClick={false}
          >
            <AdminMultiStep userModalIsOpen={showModal} setUserModalIsOpen={setShowModal} onClose={handleModalClose} />
          </Modal>

          <Modal
            isOpen={deleteModal}
            onRequestClose={closeModal}
            contentLabel="Delete Modal"
            shouldCloseOnOverlayClick={false}
            ariaHideApp={false}
            overlayClassName={`modal-overlay add-category delete member`}
          >
            <h2>Are you sure you want to delete this user?</h2>
            <p>Deleting this user will permanently remove their account and all associated data. This action cannot be undone.</p>
            <div className="modal-buttons">
              <button className="cancel-btn" onClick={closeModal}>Cancel</button>
              <button className="save-btn" onClick={handleDelete} disabled={loader}>
                {loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Yes, I'm sure</button>
            </div>
          </Modal>

          <Modal
            isOpen={filtersModal}
            onRequestClose={closeFiltersModal}
            contentLabel="Filters Modal"
            shouldCloseOnOverlayClick={false}
            ariaHideApp={false}
            overlayClassName={`modal-overlay member-sidebar booking-order-filter`}
          >
            <button className='close-button' onClick={closeFiltersModal}>
              <img src={cross} alt='close' />
            </button>
            <div>
              <h3>Filters</h3>
              <h4>Member Type</h4>

              {/* <label class="container">One
                <input type="checkbox" checked="checked" />
                <span class="checkmark"></span>
              </label>
 */}
              <div className=''>
                <div class=" cannvas-check mt-2">
                  <label className="container2">
                    Creators
                    <input
                      type="checkbox"
                      name="creator"
                      checked={selectedFilters.creator}
                      onChange={handleCheckboxChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>

                <div class="cannvas-check ">
                  <label className="container2">
                    Users
                    <input
                      type="checkbox"
                      name="user"
                      checked={selectedFilters.user}
                      onChange={handleCheckboxChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>

                {/* <div class="cannvas-check">
                  <label className="container2">
                    All
                    <input
                      type="checkbox"
                      name="all"
                      checked={selectedFilters.all}
                      onChange={handleCheckboxChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div> */}
              </div>
            </div>
            <div class=" show-btns">
              <button onClick={handleShowChanges}>Show results({totalCount || '0'})</button>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default Members;
