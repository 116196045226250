import React, { useCallback, useEffect, useRef, useState } from 'react'
import UserSidebar from '../../../Layout/userSidebar'
import burger from '../../../assets/images/burger-menu.svg'
import 'react-datepicker/dist/react-datepicker.css';
import { datetime, RRule, RRuleSet } from 'rrule';
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import leftArrow from '../../../assets/images/backarrow.svg'
import ActiveLinks from './activeLinks'
import OfferingDetails from './OfferingDetails'
import Calendar from 'react-calendar';
import add from '../../../assets/images/add-slot.svg'
import { insertOfferingData } from '../../../Counter/offeringSLice';
import manageIcon from '../../../assets/images/manage-icon.svg'
import deleteImg from '../../../assets/images/delete-2.svg';
import { toast } from 'react-toastify';
import TimePicker from './CreateProduct/testing';
import unauthorized from '../../../assets/images/puzzle_popup.svg'
import Modal from 'react-modal'
import cross from '../../../assets/images/gray-close5.svg'
import timeslot from '../../../assets/images/clocck-setting.svg'
import DraftModal from './draftModal';
import selcttime from '../../../assets/images/select-time.svg'
import greenTick from '../../../assets/images/green-tick.svg'
import choosearrow from '../../../assets/images/choose-arrow.svg'
import { getApi } from '../../../Services/apiService';


const daysOfWeek = [
  { label: 'Monday', value: RRule.MO, name: 'MO' },
  { label: 'Tuesday', value: RRule.TU, name: 'TU' },
  { label: 'Wednesday', value: RRule.WE, name: 'WE' },
  { label: 'Thursday', value: RRule.TH, name: 'TH' },
  { label: 'Friday', value: RRule.FR, name: 'FR' },
  { label: 'Saturday', value: RRule.SA, name: 'SA' },
  { label: 'Sunday', value: RRule.SU, name: 'SU' },
];


function AvailabilityOffering({ nextStep, prevStep, step, data, submitForm, type }) {

  console.log("data>>>>>>>>>>>>>>>>>>>>>>>>>>", data);

  const formState = useSelector((state) => state.offerCounter);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const dropdownPackageRef = useRef(null);
  const dtstartnext = new Date();
  dtstartnext.setMonth(dtstartnext.getMonth() + 3);
  const formattedDate = dtstartnext.toISOString().split('T')[0];

  const [classState, setClassState] = useState(false)
  const [offeringType, setOfferingType] = useState(data?.offeringType || '')
  // const [sameTime, setSameTime] = useState(data?.sameTime || true)
  const [sameTime, setSameTime] = useState(data?.sameTime ?? true)
  const [recurrences, setRecurrences] = useState([])
  // const [recurrences, setRecurrences] = useState(data?.recurrences || [])
  const [sessionType, setSessionType] = useState(data?.sessionType || 'single_session')
  const [specifyTime, setSpecifyTime] = useState(data?.specifyTime || 'availabliity')
  const [value, onChange] = useState(new Date());
  const [availabilityPeriod, setAvailabilityPeriod] = useState(data?.availabilityPeriod || 'date')
  const [selectedDayLabel, setSelectedDayLabel] = useState(data?.selectedDayLabel || []);
  // const [selectedDayLabel, setSelectedDayLabel] = useState(data?.selectedDayLabel || []);
  const [dayLabel, setDayLabel] = useState(data?.dayLabel || []);
  // const [dayLabel, setDayLabel] = useState(data?.dayLabel || []);
  const [key, setKey] = useState(0);
  const [moreDiv, setMoreDiv] = useState([])
  const [sessionRepeat, setSessionRepeat] = useState(data?.sessionRepeat || '')
  const [typeValue, setTypeValue] = useState(data?.typeValue || { date: formattedDate })
  const [monthRepeatValue, setMonthRepeatValue] = useState('')
  const [sessionDuration, setSessionDuration] = useState(data?.sessionDuration || '')
  const [sameTimeDuratioon, setSameTimeDuration] = useState(data?.sameTimeDuratioon || [{ startTime: '', endTime: '' }])
  const [sessionMultipleType, setSessionMultipleType] = useState(data?.sessionMultipleType || '')
  const [sessionNumber, setSessionNumber] = useState(data?.sessionPackage || '')
  const [timeFrame, setTimeFrame] = useState(data?.timeFrame || { number: '', months: '' })
  const [errors, setErrors] = useState({});
  const [markedDates, setMarkedDates] = useState([]);
  const [addDates, setAddDates] = useState([])
  const [weekDay, setWeekDay] = useState([])
  const [rdates, setRdates] = useState(data?.rdates || []);
  const [exdates, setExdates] = useState(data?.exdates || []);
  const [addDateCalendar, setAddDateCalendar] = useState(data?.addDatesCalendar || data?.addDateCalendar || [])
  const [saveState, setSaveState] = useState(false)
  const [manageCalendar, setManageCalendar] = useState(data?.manageCalendar || false)
  const [showManage, setShowManage] = useState(data?.showManage || false)
  const [calendarDate, setCalendarDate] = useState(data?.calendarDate || new Date())
  const [svaeSlotWeek, setSaveSlotWeek] = useState(false);
  const [stateModal, setStateModal] = useState(false)
  const [showSlot, setShowSlot] = useState(data?.showSlot || false)
  // const [isOpen, setIsOpen] = useState(false);
  const [isOpen, setIsOpen] = useState([]);
  const [openTimePickerIndex, setOpenTimePickerIndex] = useState(null); // State to manage the open index for TimePicker
  const [addOnDay, setAddOnDay] = useState(data?.addOnDay || [])
  const [selectedDayLabelModal, setSelectedDayLabelModal] = useState(false)
  const [weekDayname, setWeekDayName] = useState('')
  const [activeStartDate, setActiveStartDate] = useState(new Date());
  const [isModal, setIsModal] = useState(false)
  const [totalWeeksFrame, setTotalweeksFrame] = useState({})
  const [dayDropdown, setDayDropdown] = useState(false)
  const [addDatesCancel,setAddDatesCancel] = useState([])
  const [addRDatesCancel,setRAddDatesCancel] = useState([])
  const [addExDatesCancel,setExAddDatesCancel] = useState([])
  const [editDates,setEditDates] = useState([])

console.log("recurrencesrecurrences",recurrences);

  
  useEffect(()=>{
    handleDayLabelChange()
  },[dayLabel])
  const handleDayLabelChange = () =>{
    const dayIndices = dayLabel.map((label) =>
      ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].indexOf(label)
    ).filter((index) => index !== -1);
    if (dayIndices.length > 0) {
      setExdates((prevExdate) =>
        prevExdate.filter((dateStr) => {
          const date = new Date(dateStr);
          return dayIndices.includes(date.getDay());
        })
      );
  }
  }
  useEffect(() => {
    if(sessionMultipleType === 'weekly' || sessionMultipleType==='monnthly'){
    if (dayLabel.length >= Number(sessionNumber)) {
      setShowManage(true);
    }
    else{
      setShowManage(false)
    }
  }else if(sessionMultipleType==='custom'){
    if(markedDates?.length >= Number(sessionNumber)){
      setShowManage(true)
    }
  }
  else{
    if(sessionType!=='multiple_session'){
    setShowManage(true)
    }
  }
  }, [dayLabel]);
  useEffect(()=>{
    // if(timseFrame?.months==='months'){
      handleUntilTime('date',calculateTwoMonthsAhead())
    // }
  },[timeFrame])

  const calculateTwoMonthsAhead = () => {
    const today = new Date();
    const twoMonthsAhead = new Date(today);
    if(timeFrame?.months === 'months'){
    twoMonthsAhead.setMonth(today.getMonth() + Number(timeFrame?.number));
    }
    if(timeFrame?.months === 'weeks'){
      twoMonthsAhead.setDate(today.getDate() + (Number(timeFrame?.number || 0) * 7));
    }
    if(timeFrame?.months === 'day'){
      twoMonthsAhead.setDate(today.getDate() + Number(timeFrame?.number));
    }
  
    // Format as "YYYY-MM-DD" if required
    return twoMonthsAhead.toISOString().split("T")[0];
  };
  
  // Update handleUntilTime to accept a value
  const handleUntilTime = (type, value) => {
    if(new Date(typeValue?.date) < new Date(value)){
    setTypeValue({ [type]: value });
    }
  };

  const handleTimePickerToggle = (index) => {
    setOpenTimePickerIndex(prevIndex => (prevIndex === index ? null : index)); // Toggle the TimePicker open state
  };

  // useEffect(()=>{
  //   handleSetSelectedDay()
  // },[markedDates])

  // const handleSetSelectedDay = () =>{
  //   const markedWeekdays = markedDates.map((date) =>
  //     new Date(date).toLocaleDateString('en-US', { weekday: 'long' }).toUpperCase()
  //   );

  //   // Filter selectedDayLabel based on matching weekday labels from markedDay
  //   const matchingWeekdays = selectedDayLabel.filter((dayObj) =>
  //     markedWeekdays.includes(dayObj.label.toUpperCase())
  //   );

  //   // setSelectedDayLabel(matchingWeekdays)
  //   return matchingWeekdays;
  // }
  useEffect(() => {
    if (sessionMultipleType === 'weekly') {
      handleCheckWeekDate()
    }
    if (sessionMultipleType === 'monthly') {
      handleCheckMonthDate()
    }
  }, [markedDates])

  // const handleCheckWeekDate = () => {
  //   const weekMap = new Map();

  //   // Group marked dates by their week (Monday to Sunday)
  //   markedDates.forEach((dateStr) => {
  //     const date = new Date(dateStr);
  //     const startOfWeek = new Date(date);
  //     startOfWeek.setDate(date.getDate() - date.getDay() + 1); // Get Monday of the week
  //     const weekKey = startOfWeek.toLocaleDateString('en-CA'); // Use the week start as the key

  //     if (!weekMap.has(weekKey)) {
  //       weekMap.set(weekKey, []);
  //     }
  //     weekMap.get(weekKey).push(dateStr); // Add dates to their respective weeks
  //   });

  //   // Collect only valid dates (weeks with 2 or more dates)
  //   const validDates = [];
  //   weekMap.forEach((dates) => {
  //     if (dates.length >= Number(sessionNumber)) {
  //       validDates.push(...dates); // Keep the week if it has 2 or more dates
  //     } 
  //   });

  //   // Update markedDates state only if changes are needed
  //   if (validDates.length !== markedDates.length) {
  //     setMarkedDates(validDates);
  //   }
  // };
  const isSameDate = (d1, d2) =>
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate();

  const handleCheckWeekDate = () => {
    const weekMap = new Map();

    // Group marked dates by their week (Monday to Sunday)
    markedDates.forEach((dateStr) => {
      const date = new Date(dateStr);

      // Calculate the Monday of the current week
      const startOfWeek = new Date(date);
      const day = date.getDay(); // Get the day index (0 = Sunday, 6 = Saturday)

      // If it's Sunday (0), move back to previous Monday (6 days back)
      const mondayOffset = day === 0 ? -6 : 1 - day;
      startOfWeek.setDate(date.getDate() + mondayOffset);
      // Format the week key as 'YYYY-MM-DD'
      const weekKey = startOfWeek.toISOString().split('T')[0];

      // Add the date to its corresponding week
      if (!weekMap.has(weekKey)) {
        weekMap.set(weekKey, []);
      }
      weekMap.get(weekKey).push(dateStr);
    });

    // Collect only valid dates (weeks with 2 or more dates)
    const validDates = [];
    weekMap.forEach((dates) => {
      if (dates.length >= Number(sessionNumber)) {
        validDates.push(...dates); // Keep dates from weeks with 2 or more
      }
    });

    // Update markedDates state only if changes are needed
    if (validDates.length !== markedDates.length) {
      setMarkedDates(validDates);
    }
  };




  const handleCheckMonthDate = () => {
    const monthMap = new Map();

    // Group marked dates by their month (YYYY-MM format)
    markedDates.forEach((dateStr) => {
      const date = new Date(dateStr);
      const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`; // Format: YYYY-MM

      if (!monthMap.has(monthKey)) {
        monthMap.set(monthKey, []);
      }
      monthMap.get(monthKey).push(dateStr); // Add the date to its month
    });

    // Collect valid dates (months with 2 or more marked dates)
    const validDates = [];
    monthMap.forEach((dates) => {
      if (dates.length >= Number(sessionNumber)) {
        validDates.push(...dates); // Keep all dates from valid months
      }
    });

    // Update markedDates state only if changes are needed
    if (validDates.length !== markedDates.length) {
      setMarkedDates(validDates);
    }
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.time')) {
        setOpenTimePickerIndex(null)
      }

    };
    if (openTimePickerIndex) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openTimePickerIndex]);

  useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownPackageRef.current && !dropdownPackageRef.current.contains(event.target)) {
              setDayDropdown(false); // Close the dropdown
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

  console.log("dayLabeldayLabeldayLabeldayLabel", dayLabel);
  console.log("exdatesexdatesexdatesexdates", exdates);
  console.log("rdatesrdatesrdatesrdates", rdates);
  console.log("addOnDayaddOnDayaddOnDayaddOnDay", addOnDay);


  useEffect(() => {
    const handleClick = () => setClassState(false);
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [])

  useEffect(() => {
    console.log('useeffect')
    if (addOnDay?.length > 0) {
      handleDateClickAddon()
    } else {
      generateRecurrenceStrings()
    }
    // handleDateClickAddon()
  }, [selectedDayLabel, typeValue, sessionDuration,availabilityPeriod])
  useEffect(() => {
    // if (sessionType === 'multiple_session') {
    handleDateClickAddon()
    // }
  }, [addDateCalendar, rdates, exdates, sessionDuration, addOnDay])

  useEffect(() => {
    handleAddonWeekDay()
  }, [addDateCalendar])

  const handleSidebarClick = (event) => {
    event.stopPropagation();
    setClassState(!classState);
  };


  // const handleAddonWeekDay = () =>{
  //   const tempArray = [];
  //   console.log("tempArraytempArraytempArraytempArray",tempArray);
  //   // setAddOnDay([])
  //   addDateCalendar && addDateCalendar?.length>0 && addDateCalendar.map((x,index)=>{
  //     let dayOfWeeks = x?.date.toLocaleDateString('en-US', { weekday: 'long' }).toUpperCase();
  //     if(!tempArray.includes(dayOfWeeks)){
  //       tempArray.push(dayOfWeeks);
  //     }
  //       setAddOnDay(prevDays => {
  //         const dayExists = prevDays.some(dayObj => dayObj.day === dayOfWeeks); 

  //         if (!dayExists) {
  //   return [...prevDays, { day: dayOfWeeks, timeSlots:[{startTime: '', endTime: '' }]}];
  // }
  // return prevDays;
  //       });
  //   })
  // }

  const handleAddonWeekDay = () => {
    const tempArray = [];

    addDateCalendar && addDateCalendar?.length > 0 && addDateCalendar.map((x) => {
      var localData = new Date(x?.date);
      let dayOfWeeks = localData.toLocaleDateString('en-US', { weekday: 'long' }).toUpperCase();
      if (!tempArray.includes(dayOfWeeks)) {
        tempArray.push(dayOfWeeks);
      }
    });

    setAddOnDay(prevDays => {
      const filteredDays = prevDays.filter(dayObj => tempArray.includes(dayObj.day));

      tempArray.forEach(dayOfWeeks => {
        const dayExists = filteredDays.some(dayObj => dayObj.day === dayOfWeeks);

        if (!dayExists) {
          filteredDays.push({ day: dayOfWeeks, timeSlots: [{ startTime: '', endTime: '' }] });
        }
      });

      return filteredDays;
    });
  };

  const validateForm = () => {

    let formErrors = {};

    if (!sessionType) {
      formErrors.sessionType = 'Please select a session type.';
    }

    if (sessionType === 'multiple_session' && !sessionMultipleType) {
      formErrors.sessionMultipleType = 'Please select a package type.';
    }

    if (sessionType === 'multiple_session' && sessionMultipleType !== 'daily' && !sessionNumber) {
      formErrors.sessionNumber = 'Please specify the number of sessions.';
    }

    if (sessionMultipleType && sessionMultipleType !== 'custom' && (timeFrame.number === '' || timeFrame.months === '')) {
      // if (sessionMultipleType && sessionMultipleType !== 'custom' && sessionMultipleType !== 'daily' && (timeFrame.number === '' || timeFrame.months === '')) {
      formErrors.timeFrame = 'Please specify the timeframe of the package.';
    }

    // if ((data?.sessionTypes !== 'group_session' && !specifyTime)) {
    //   formErrors.specifyTime = 'Please specify the time when it will happen.';
    // }

    if (specifyTime === 'exact' && !sessionRepeat) {
      formErrors.sessionRepeat = 'Please specify how often the session should repeat.';
    }
    if (availabilityPeriod === '' && sessionRepeat !== 'one') {
      formErrors.availabilityPeriod = 'Please select the availability period';
    }

    if (availabilityPeriod === 'date' && !typeValue.date) {
      formErrors.availabilityPeriod = 'Please specify the end date of your availability period.';
    }

    if (availabilityPeriod === 'after' && !typeValue.after) {
      formErrors.availabilityPeriod = 'Please specify the number of times after which the period ends.';
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const isValidForm = () => {
    if (sameTime) {
      return sameTimeDuratioon.startTime && sameTimeDuratioon.endTime;
    } else {
      return selectedDayLabel.every((dates, index) => {
        return dates[index]?.startTime && dates[index]?.endTime;
      });
    }
  };

  function convertTo24Hour(time) {
    if (!time || typeof time !== 'string') {
      return null; // Return null or some default value if no valid time is provided
    }
    const [hour, minutePart] = time.split(':');
    const minute = minutePart.slice(0, 2);
    const period = minutePart.slice(-2);

    let hour24 = parseInt(hour, 10);

    if (period === 'PM' && hour24 !== 12) {
      hour24 += 12;
    }
    if (period === 'AM' && hour24 === 12) {
      hour24 = 0;
    }
    console.log("${String(hour24).padStart(2, '0')}:${minute}", `${String(hour24).padStart(2, '0')}:${minute}`)
    return `${String(hour24).padStart(2, '0')}:${minute}`;
  }


  const generateRecurrenceStrings = (date, dayOfWeek, isMarked, type, updatedDatess, rdatesAdd, exdatesAdd) => {
    // setManageCalendar(true)
    // setShowManage(true)

    let startDate = new Date().getDate();
    let fullyear = new Date().getFullYear();
    let month = new Date().getMonth();
    const dayName = new Date().getDay();
    if (sessionMultipleType === 'daily') {
      startDate = new Date(calendarDate).getDate();
      fullyear = new Date(calendarDate).getFullYear();
      month = new Date(calendarDate).getMonth();
    }

    setWeekDay([])
    setRecurrences([]);

    const dayMap = {
      Sunday: RRule.SU,
      Monday: RRule.MO,
      Tuesday: RRule.TU,
      Wednesday: RRule.WE,
      Thursday: RRule.TH,
      Friday: RRule.FR,
      Saturday: RRule.SA,
    };

    // const newRecurrenceRules = [...recurrences];
    const newRecurrenceRules = [];

    // if(sessionType !== 'multiple_session'){
    selectedDayLabel.forEach((x) => {
      let ruleOptions;
      const dtstart = new Date();
      dtstart.setUTCHours(0, 0, 0, 0);
      // const dtStartWeek = date ?new Date(date) : new Date()
      //       dtStartWeek.setUTCHours(0, 0, 0, 0);
      // alert(dtStartWeek)
      let monthlyDaysRepeat = [];
      selectedDayLabel.forEach((x) => {
        const a = dayMap[x?.label];
        monthlyDaysRepeat.push(a)
      })
      if (offeringType === 'Monthly') {
        ruleOptions = {
          freq: RRule.MONTHLY,
          dtstart: new Date(Date.UTC(fullyear, month, startDate, 0, 0)),
          // dtstart: dtstart,
          bysetpos: monthRepeatValue,
          byweekday: [dayMap[x?.label]],
        };
      }
      else {
        ruleOptions = {
          freq: RRule.WEEKLY,
          dtstart: new Date(Date.UTC(fullyear, month, startDate, 0, 0)),
          // dtstart: dtstart,
          byweekday: [dayMap[x?.label]],
        };
      }
      if (sessionMultipleType === 'monthly') {
        ruleOptions = {
          freq: RRule.MONTHLY,
          dtstart: new Date(Date.UTC(fullyear, month, startDate, 0, 0)),
          // dtstart: dtstart,
          bysetpos: monthRepeatValue,
          byweekday: [dayMap[x?.label]],
        };
      }
      if (sessionMultipleType === 'custom') {
        ruleOptions = {
          freq: RRule.WEEKLY,
          // freq: RRule.DAILY,
          dtstart: new Date(Date.UTC(fullyear, month, startDate, 0, 0)),
          // dtstart:  dtStartWeek ,
          // count: Number(sessionNumber),
          byweekday: [dayMap[x?.label]],
        };
      }
      if (availabilityPeriod === 'date') {
        ruleOptions.until = new Date(typeValue?.date);
      } else if (availabilityPeriod === 'after') {
        ruleOptions.count = typeValue?.after;
      }
      // if (sessionType === 'single_session') {
      //   const dtstartnext = new Date();
      //   dtstartnext.setMonth(dtstart.getMonth() + 1);
      //   ruleOptions.until = new Date(dtstartnext)
      // }

      if (sessionRepeat === 'one') {
        ruleOptions.count = 1;
      }
      // if (sessionMultipleType === 'custom') {
      //   ruleOptions.count =  Number(timeFrame?.number);
      // }
      if (availabilityPeriod === 'indefinitely') {
        // const lastDateOfYear = new Date(new Date().getFullYear(), 11, 31);
        // ruleOptions.until = new Date(lastDateOfYear);
        const today = new Date();
  const nextYearToday = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());
  ruleOptions.until = nextYearToday;
      }

      const rru = new RRuleSet();
      rru.rrule(new RRule(ruleOptions));
      const rdate = new Date(Date.UTC(fullyear, month, startDate, 0, 0));


      console.log(">>>>>>>>>>>rdates", rdates);

      rdates && rdates.length > 0 && rdates.map((y) => {
        let dayOfWeekr = y.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase(); // Get the day of the week in 'SU', 'MO', etc.
        dayOfWeekr = dayOfWeekr && dayOfWeekr.slice(0, 2);
        const startDate = new Date(y).getDate();
        const fullyear = new Date(y).getFullYear();
        const month = new Date(y).getMonth();
        const rdater = new Date(Date.UTC(fullyear, month, startDate, 0, 0));
        if (x?.name == dayOfWeekr) {

          rru.rdate(rdater);
        }
      })
      console.log(">>>>>>>>exdates", exdates)
      exdates && exdates.length > 0 && exdates.map((y) => {

        let dayOfWeekex = y.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase(); // Get the day of the week in 'SU', 'MO', etc.
        dayOfWeekex = dayOfWeekex && dayOfWeekex.slice(0, 2);
        const startDate = new Date(y).getDate();
        const fullyear = new Date(y).getFullYear();
        const month = new Date(y).getMonth();
        const rdatex = new Date(Date.UTC(fullyear, month, startDate, 0, 0));
        if (x?.name == dayOfWeekex) {

          rru.exdate(rdatex);
        }
      })
      let updatedTimeDurations;
      if (!sameTime) {
        // if (!sameTime || selectedDayLabel?.length === 1) {
        updatedTimeDurations = x.dates.map((timeSlot) => {
          return {
            ...timeSlot,
            startTime: convertTo24Hour(timeSlot.startTime) || '',
            endTime: convertTo24Hour(timeSlot.endTime) || ''
          };
        });
      }
      // x.dates.forEach((timeSlot) => {
      //   timeSlot.startTime = convertTo24Hour(timeSlot?.startTime) || '';
      //   timeSlot.endTime = convertTo24Hour(timeSlot?.endTime) || '';
      // });
      // x.dates[0].startTime= convertTo24Hour(x.dates[0].startTime) || ''
      // x.dates[0].endTime= convertTo24Hour(x.dates[0].endTime) || ''
      if (sameTime) {
        // if (sameTime && selectedDayLabel?.length > 1) {
        updatedTimeDurations = sameTimeDuratioon.map((timeSlot) => {
          return {
            ...timeSlot,
            startTime: convertTo24Hour(timeSlot.startTime) || '',
            endTime: convertTo24Hour(timeSlot.endTime) || ''
          };
        });
        // sameTimeDuratioon.forEach((timeSlot) => {
        //   timeSlot.startTime = convertTo24Hour(timeSlot.startTime) || '';
        //   timeSlot.endTime = convertTo24Hour(timeSlot.endTime) || '';
        // });
      }
      // let obj = sameTime
      let obj = sameTime && selectedDayLabel?.length !== 1
        ? {
          dayOfWeek: x?.name,
          sessionDuration: Number(sessionDuration),
          // timeSlots: sameTimeDuratioon,
          timeSlots: updatedTimeDurations,
          rruleString: rru.toString(),
        }
        : {
          dayOfWeek: x?.name,
          sessionDuration: Number(sessionDuration),
          timeSlots: updatedTimeDurations,
          // timeSlots: x?.dates,
          rruleString: rru.toString(),
        };
      setWeekDay((prev) => {
        // If x?.name is an array of day names, use it directly
        const newDays = Array.isArray(x?.name) ? x.name : [x?.name];
        // Concatenate the previous state with the new days and remove duplicates
        const updatedWeekDay = [...prev, ...newDays];
        return [...new Set(updatedWeekDay)];
      });
      newRecurrenceRules.push(obj);
    });
    console.log('newdate', date)


    updatedDatess && updatedDatess.length > 0 ? updatedDatess.forEach(rule => {
      if (!newRecurrenceRules.some(existingRule => existingRule.rruleString === rule.rruleString)) {
        newRecurrenceRules.push(rule);
      }
    })
      :
      addDates.forEach(rule => {
        if (!newRecurrenceRules.some(existingRule => existingRule.rruleString === rule.rruleString)) {
          newRecurrenceRules.push(rule);
        }
      });


    console.log("newRecurrenceRulesnewRecurrenceRules", newRecurrenceRules)
    console.log('recurrences>>>>>>>>>>>>>>>>>', recurrences)
    console.log('addDates>>>>>>>>>>>>>>>>>>>>>', addDates)
    setRecurrences((prev) => [...prev, ...newRecurrenceRules]);
    let recurresRecord = [];


    console.log("recurresRecordrecurresRecord", recurresRecord);
    const startDates = new Date(Date.UTC(fullyear, month, startDate, 0, 0)); // Start date
    const nextYearToday = new Date(startDates.getFullYear() + 1, startDates.getMonth(), startDates.getDate());
const endDatess= availabilityPeriod === 'date' ?  new Date(typeValue?.date) : nextYearToday

// if(sessionMultipleType === 'weekly'){
//     if (!validateAllRules(newRecurrenceRules, startDate, endDatess)) {
//       alert(`Error: No week has at least ${Number(sessionNumber)} occurrences.`)
//       return;
//     }
//   }
    updateMarkedDates(newRecurrenceRules, isMarked);
    // updateMarkedDates(recurresRecord, isMarked)
    console.log("recurrenceString", newRecurrenceRules);
  };


  const validateAllRules = (rules, startDate, endDate) => {
    // Iterate over all rules and validate each one
    for (const rule of rules) {
      const rruleString = rule.rruleString; // Assuming each object in newRecurrenceRules has an 'rruleString' property
      // if(timeFrame?.months === 'weeks'){
      if (!validateRRule(rruleString, startDate, endDate)) {
        return false;
      }
    // }
    //   if(timeFrame?.months === 'months'){
    //   if(!validateRRuleMonth(rruleString)){
    //     return false
    //   }
    // }
    }
    return true;
  };
  const validateRRule = (rruleString, startDates, endDate) => {
    const rule = RRule.fromString(rruleString);
  
    // Generate all occurrences
    
    const allOccurrences = rule.between(new Date(startDates), new Date(endDate), true);
    // Group dates by week
    // const weeklyOccurrences = groupByWeek(allOccurrences);
    const weekdayOccurrences = allOccurrences.reduce((acc, date) => {
      const weekday = date.getUTCDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6
      acc[weekday] = (acc[weekday] || 0) + 1;
      return acc;
    }, {});
      const totalWeekdayOccurrences = Object.values(weekdayOccurrences).reduce(
      (total, count) => total + count,
      0
    );
    const getWeekdayIndex = (name) => {
      const daysMap = {
        SU: 0, // Sunday
        MO: 1, // Monday
        TU: 2, // Tuesday
        WE: 3, // Wednesday
        TH: 4, // Thursday
        FR: 5, // Friday
        SA: 6, // Saturday
      };
      return daysMap[name];
    };
    const getDayNameToCode = (dayName) => {
      const nameToCodeMap = {
        Sunday: "SU",
        Monday: "MO",
        Tuesday: "TU",
        Wednesday: "WE",
        Thursday: "TH",
        Friday: "FR",
        Saturday: "SA",
      };
      return nameToCodeMap[dayName];
    };
    
    const updatedDayLabels = selectedDayLabel.map(day => {
      const dayIndex = getWeekdayIndex(day.name);
      if (weekdayOccurrences[dayIndex] === undefined) {
        return day;
      }
      return weekdayOccurrences[dayIndex] >= Number(sessionNumber) ? day : null;
    }).filter(Boolean); 
    const updatedDayLabelStrings = dayLabel.map(day => {
      const dayIndex = getWeekdayIndex(getDayNameToCode(day));
      if (weekdayOccurrences[dayIndex] === undefined) {
        return day;
      }
      return weekdayOccurrences[dayIndex] >= Number(sessionNumber) ? day : null;
    }).filter(Boolean); 
    if (updatedDayLabels.length !== selectedDayLabel.length) {
      setSelectedDayLabel(updatedDayLabels);
    }
  
    if (updatedDayLabelStrings.length !== dayLabel.length) {
      setDayLabel(updatedDayLabelStrings);
    }
  
    // if (updatedDayLabels.length === 0) {
    //   alert('Error: No weekday has enough occurrences.');
    //   setDayLabel([]);
    // }
    
    // Check if any week has at least 3 occurrences
    return totalWeekdayOccurrences >= Number(sessionNumber);
    // return Array.from(weekdayOccurrences.values()).some((count) => count >= Number(timeFrame?.number));
  };


  const handleDayChange = async (day, label, name) => {
    if(data?.editype==='edit'){
      const resp = await getApi(`/creator/edit/session-date?day=${name}&offer_id=${data?.id}`);
      if(resp?.statusCode ===200){
        if(resp?.data ==1){
          alert(resp?.message)
          return;
        }
      }
    }
    // if((!manageCalendar && !showManage) || (manageCalendar && showManage)) {
    // if (!svaeSlotWeek) {
    setSaveSlotWeek(true); setManageCalendar(false); 
    // setShowManage(true);
    let updatedDayts = [...selectedDayLabel]
    const labelExists = updatedDayts.some(d => d.label === label);
    if (labelExists) {
      // return prevDays.filter(d => d?.label !== label);
      updatedDayts = updatedDayts.filter(d => d.label !== label);
      // return updatedDayts;
    } else {
      // return [...prevDays, { label: label, name: name, dates: [{ startTime: '', endTime: '' }] }];
      updatedDayts = [...updatedDayts, { label: label, name: name, dates: [{ startTime: '', endTime: '' }] }];
      // return updatedDayts
    }
    if (sessionRepeat === 'one') {
      setDayLabel([label]);
      setSelectedDayLabel([{ label: label, name: name, dates: [{ startTime: '', endTime: '' }] }])
    }
    if (sessionRepeat === '' || sessionRepeat === 'daily' || sessionRepeat === 'weekly') {
      setDayLabel(prevDays =>
        prevDays.includes(label)
          ? prevDays.filter(d => d !== label)
          : [...prevDays, label]
      );

      setSelectedDayLabel(prevDays => {
        const labelExists = prevDays.some(d => d.label === label);
        if (labelExists) {
          return prevDays.filter(d => d?.label !== label);
          //   updatedDayts = prevDays.filter(d => d.label !== label);
          // return updatedDayts;
        } else {
          return [...prevDays, { label: label, name: name, dates: [{ startTime: '', endTime: '' }] }];
          // updatedDayts = [...prevDays, { label: label, name: name, dates: [{ startTime: '', endTime: '' }] }];
          // return updatedDayts
        }
      });
    }

    // }
  };


  const parseRRule = (rruleString) => {
    console.log('rruleString', rruleString)
    if (typeof rruleString !== 'string') {
      console.error('Expected a string but got:', typeof rruleString, rruleString);
      return []; // Return an empty array if rruleString is not a string
    }
    // Split the rruleString into lines to handle RDATE separately
    const lines = rruleString.split('\n');
    let rruleLine = '';
    let rdates = [];
    // let rdates = [];
    let dtstart = [];
    let exdates = [];


    for (let i = lines.length - 1; i >= 0; i--) {
      const line = lines[i];
      console.log('line', line)
      if (line.startsWith('RRULE')) {
        rruleLine += line.split(':')[1]; // Extract the RRULE part after ':'
        // rruleLine += line.split(':')[1]; // Extract the RRULE part after ':'
      } else if (line.startsWith('DTSTART')) {
        const dtstart = line.split(':')[1]; // Extract the DTSTART part after ':'
        const dtstartDate = dtstart.split('T')[0]; // Get the date part before 'T'
        rruleLine += `;DTSTART=${dtstart}`; // Append DTSTART date to RRULE
      } else if (line.startsWith('RDATE')) {
        const rdateLine = line.split(':')[1];

        // Split the RDATE string into individual date-time strings
        const dateStrings = rdateLine.split(',');

        // Extract the date part (before the 'T') and push it to the rdates array
        dateStrings.forEach(dateStr => {
          // const datePart = dateStr.split('T')[0]; // Extract the date part only
          rdates.push(dateStr);
        });
      }
      else if (line.startsWith('EXDATE')) {
        console.log()
        // const rdateLine = line.split(':')[1];
        // exdates.push(rdateLine);
        const rdateLine = line.split(':')[1];
        const dateStrings = rdateLine.split(',');
        dateStrings.forEach(dateStr => {
          // const datePart = dateStr.split('T')[0]; // Extract the date part only
          exdates.push(dateStr);
        });
      }
    }

    let rruleStrings = rruleLine;

    let finalRRuleString = `rrulestr('${rruleStrings}')`
    console.log('rruleLine', finalRRuleString)


    const ruleSet = new RRuleSet();
    const rrule = RRule.fromString(rruleStrings);

    ruleSet.rrule(rrule);


    exdates.forEach(dateStr => {
      // Convert EXDATE format to ISO 8601
      const isoDateStr = dateStr.replace(/(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})Z/, '$1-$2-$3T$4:$5:$6Z');
      const exdate = new Date(isoDateStr);
      if (!isNaN(exdate.getTime())) {
        ruleSet.exdate(exdate);
      } else {
        console.error('Invalid EXDATE:', dateStr);
      }
    });

    // Add RDATEs
    rdates.forEach(dateStr => {
      // Convert EXDATE format to ISO 8601
      const isoDateStr = dateStr.replace(/(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})Z/, '$1-$2-$3T$4:$5:$6Z');
      const exdate = new Date(isoDateStr);
      if (!isNaN(exdate.getTime())) {
        ruleSet.rdate(exdate);
      } else {
        console.error('Invalid RDATE:', dateStr);
      }
    });
    const rruleDates = ruleSet.all();
    // Combine the RRULE dates with the RDATEs
    // const rruleDates = rule.after(rule, true); // Use `true` to include the clickedDate itself if it matches

    console.log("rruleDatesrruleDates", rruleDates)


    const allDates = [...rruleDates];
    // const allDates = [...rruleDates, ...rdates];
    console.log("allDates", allDates)
    // Format the dates as YYYY-MM-DD
    const formattedDate = allDates.map(date => {
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
      const day = String(date.getUTCDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    });
    console.log("formattedDate", formattedDate);
    return formattedDate;
  };


  const updateMarkedDates = (updatedRecurrences, ismarkedDates) => {
    console.log("updatedRecurrences", updatedRecurrences)
    if (updatedRecurrences && updatedRecurrences.length > 0) {
      const newMarkedDates = updatedRecurrences.reduce((acc, occurrence) => {
        console.log('ifffffff');
        // const abc= 'DTSTART:20240831T183000Z\nRRULE:FREQ=WEEKLY;COUNT=1';
        const dates = parseRRule(occurrence?.rruleString);
        console.log("datesrecurrence", dates)
        return [...acc, ...dates];
      }, []);
      console.log("newMarkedDatesnewMarkedDates", newMarkedDates);


      setMarkedDates(newMarkedDates);
    } else {
      setMarkedDates([]);
    }
  };

  const handleSaveCalendar = (date, isMarked) => {
    setSaveState(true)
    // const formattedDate = date.toLocaleDateString('en-CA');
    setAddDateCalendar((prev) => {
      const dateExists = prev.some((item) => new Date(item.date).toISOString() === new Date(date).toISOString());
      console.log('dateExists', dateExists)
      // If the date exists, remove it; otherwise, add it
      if (dateExists) {
        return prev.filter((item) => new Date(item.date).toISOString() !== new Date(date).toISOString());
      } else {
        return [...prev, { date: date, isMarked: isMarked, startTime: '', endTime: '' }];
      }

      // const newRRuleString = generateRecurrenceStrings(date, dayOfWeek, isMarked, 'single');
      // console.log('newRRuleString', newRRuleString)

    })

    handleDateClick(date, isMarked)
  }


  const handleDateClickAddon = (date, isMarked, typess) => {
    let updatedDatess = [];
    let rdatesAdd = [];
    let exdatesAdd = [];
    let upDate = [];
    let upDayWeek = [];
    let upIsmarked = [];

    addDateCalendar && addDateCalendar.length > 0 && addDateCalendar.forEach((x, index) => {

      var dateLocal = new Date(x?.date)
      let dayOfWeek = dateLocal.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase(); // Get the day of the week in 'SU', 'MO', etc.
      dayOfWeek = dayOfWeek.slice(0, 2);
      upDate.push(dateLocal)
      upDayWeek.push(dayOfWeek)
      upIsmarked.push(x?.isMarked)


      if (x?.isMarked === false || x?.isMarked === true) {
        const startDate = new Date(x?.date).getDate();
        const fullyear = new Date(x?.date).getFullYear();
        const month = new Date(x?.date).getMonth();
        let newRuleStrings;
        let newRule;
        const validDate = new Date(x?.date);
        if (isNaN(validDate)) {
          console.error("Invalid date provided:", x?.date);
          return; // Exit the function if the date is invalid
        }
        const getDayNum = validDate.getDay();
        const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const getDay = dayNames[getDayNum];
        if (sessionMultipleType === 'monthly') {
          const eventDate = new Date(x?.date);
          const dayOfMonth = eventDate.getDate(); // Get the day of the month from the start date

          newRule = {
            freq: RRule.MONTHLY,     // Repeat every month
            dtstart: eventDate,      // Start date of the recurrence
            bymonthday: dayOfMonth,  // Repeat on this exact date every month
            count: Number(timeFrame?.number) || 1 //Number(sessionNumber),
          };
        } else {
          newRule = {
            freq: RRule.WEEKLY,
            dtstart: new Date(Date.UTC(fullyear, month, startDate, 0, 0)),
            count: 1
          };
        }
        const rrus = new RRuleSet();
        rrus.rrule(new RRule(newRule));

        let dayOfWeek = dateLocal.toLocaleDateString('en-US', { weekday: 'long' }).toUpperCase();
        let existingDay = addOnDay.find(day => day.day === dayOfWeek);

        // if (existingDay) {
        //   return existingDay;
        // } else {
        //   return null;
        // }

        let updatedTimeDurations;
        if (!sameTime) {
          updatedTimeDurations = existingDay?.timeSlots.map((timeSlot) => {
            return {
              ...timeSlot,
              startTime: convertTo24Hour(timeSlot.startTime) || '',
              endTime: convertTo24Hour(timeSlot.endTime) || ''
            };
          });
        }
        if (sameTime) {
          // updatedTimeDurations = [{
          //   startTime: convertTo24Hour(sameTimeDuratioon[0]?.startTime) || '',
          //   endTime: convertTo24Hour(sameTimeDuratioon[0]?.endTime) || ''
          // }];
          updatedTimeDurations = sameTimeDuratioon.map((timeSlot) => {
            return {
              ...timeSlot,
              startTime: convertTo24Hour(timeSlot.startTime) || '',
              endTime: convertTo24Hour(timeSlot.endTime) || ''
            };
          })
        }
        // x.timeSlots.forEach((timeSlot) => {
        //   timeSlot.startTime = convertTo24Hour(timeSlot.startTime) || '';
        //   timeSlot.endTime = convertTo24Hour(timeSlot.endTime) || '';
        // });
        let newRuleString;
        dayOfWeek = dayOfWeek.slice(0, 2);
        newRuleString = {
          dayOfWeek: dayOfWeek,
          sessionDuration: Number(sessionDuration),
          timeSlots: updatedTimeDurations,
          // timeSlots: x?.timeSlots,
          rruleString: rrus.toString(),
        };
        const uniqueDates = addDates.reduce((acc, current) => {
          const x = acc.find(item => item.rruleString === current.rruleString);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);

        let newRRuleString = uniqueDates.length > 0 ? [...uniqueDates] : [];
        console.log('updatedDatess &--------------', updatedDatess)
        console.log("markedDates*******", markedDates);
        console.log("updatedDates>>>>>>>>>>>>>>>", updatedDatess)
        console.log("newRRuleString after adding:", updatedDatess);
        if (sessionType === 'single_session') {
          updatedDatess.push(newRuleString)
        }
        if (sessionType === 'multiple_session') {
          updatedDatess.push(newRuleString)
        }
      }
      if (sessionType === 'single_session') {

        updatedDatess = updatedDatess && updatedDatess?.length > 0 ? updatedDatess : addDates
      }

      console.log("updatedDatess55555555555", updatedDatess);
      rdatesAdd = rdatesAdd && rdatesAdd?.length > 0 ? rdatesAdd : rdates
      exdatesAdd = exdatesAdd && exdatesAdd?.length > 0 ? exdatesAdd : exdates

    })

    if (typess === 'erdate') {
      if (isMarked === false) {
        if (sessionMultipleType === 'daily') {
          // if (markedDates.length < (Number(timeFrame?.number) * 7)) {
          setRdates((prevRdates) => {
            console.log('date', date)
            const updatedRdates = [...prevRdates, date];
            const finalRdates = updatedRdates.filter((d, index, self) =>
              // index === self.findIndex((t) => t.getTime() === d.getTime())
              index === self.findIndex((t) => new Date(t).getTime() === new Date(d).getTime())
            );
            setExdates((prevExdates) => prevExdates && prevExdates.filter((d) => new Date(d).getDate() !== new Date(date).getDate()));
            // setExdates((prevExdates) => prevExdates && prevExdates.filter((d) => d.getTime() !== date.getTime()));
            setRAddDatesCancel((prevCancel) => {
              const cancelExists = prevCancel.some((item) =>
                isSameDate(new Date(item.date), new Date(date))
              );
          
              if (!cancelExists) {
                // Add new entry to addDatesCancel
                return [...prevCancel, { date, isMarked: isMarked }];
              } else {
                // If date exists, return previous state
                return prevCancel;
              }
            });
            rdatesAdd = finalRdates
            return finalRdates;
          });
          // }
          // else {
          //   alert('first remove the recurrence');
          //   return;
          // }
        }
        else {

          // if (exdates?.length > 0 && markedDates.length < (sessionNumber * timeFrame?.number)) {
          setRdates((prevRdates) => {
            console.log('date', date)
            const updatedRdates = [...prevRdates, date];
            const finalRdates = updatedRdates.filter((d, index, self) =>
              index === self.findIndex((t) => new Date(t).getTime() === new Date(d).getTime())
              // index === self.findIndex((t) => t.getTime() === d.getTime())
            );
            setExdates((prevExdates) => prevExdates && prevExdates.filter((d) => new Date(d).getDate() !== new Date(date).getDate()));
            // setExdates((prevExdates) => prevExdates && prevExdates.filter((d) => d.getTime() !== date.getTime()));
            setRAddDatesCancel((prevCancel) => {
              const cancelExists = prevCancel.some((item) =>
                isSameDate(new Date(item.date), new Date(date))
              );
          
              if (!cancelExists) {
                // Add new entry to addDatesCancel
                return [...prevCancel, { date, isMarked: isMarked }];
              } else {
                // If date exists, return previous state
                return prevCancel;
              }
            });
            rdatesAdd = finalRdates
            return finalRdates;
          });
          // }
          // else {
          //   alert('first remove the recurrence');
          //   return;
          // }
        }
      }
      if (isMarked === true) {
        setExdates((prevExdates) => {
          const updatedExdates = [...prevExdates, date];
          const finalExdates = updatedExdates.filter((d, index, self) =>
            index === self.findIndex((t) => t.getTime() === d.getTime())
          );
          setRdates((prevRdates) => prevRdates && prevRdates.filter((d) => d.getTime() !== date.getTime()));
          setExAddDatesCancel((prevCancel) => {
            const cancelExists = prevCancel.some((item) =>
              isSameDate(new Date(item.date), new Date(date))
            );
        
            if (!cancelExists) {
              // Add new entry to addDatesCancel
              return [...prevCancel, { date, isMarked: isMarked }];
            } else {
              // If date exists, return previous state
              return prevCancel;
            }
          });
          exdatesAdd = finalExdates
          return finalExdates;
        });

      }
      rdatesAdd = rdatesAdd && rdatesAdd?.length > 0 ? rdatesAdd : rdates
      exdatesAdd = exdatesAdd && exdatesAdd?.length > 0 ? exdatesAdd : exdates
    }

    const newRRuleString = generateRecurrenceStrings(date, upDayWeek, upIsmarked, 'single', updatedDatess, rdatesAdd, exdatesAdd);
    console.log('newRRuleString', newRRuleString)
    setKey(prevKey => prevKey + 1);
  };

  const handleDateClicks = (date, isMarked) => {
    setManageCalendar(true)
  }

  const handleDateClick = (date, isMarked) => {
    if (sessionType !== 'multiple_session') {
      const formattedDate = date.toLocaleDateString('en-CA'); // Format as YYYY-MM-DD

      let dayOfWeeks = date.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase();
      let dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase(); // Get the day of the week in 'SU', 'MO', etc.
      dayOfWeek = dayOfWeek.slice(0, 2);

      let updatedDatess = [];
      let rdatesAdd = [];
      let exdatesAdd = [];
      // let updatedDatess = [...addDates];
      if (!weekDay.includes(dayOfWeek)) {


        if (isMarked === false || isMarked === true) {
          const startDate = new Date(date).getDate();
          const fullyear = new Date(date).getFullYear();
          const month = new Date(date).getMonth();
          let newRuleStrings;
          // addDates.map()
          let newRule;
          const validDate = new Date(date);
          if (isNaN(validDate)) {
            console.error("Invalid date provided:", date);
            return; // Exit the function if the date is invalid
          }
          const getDayNum = validDate.getDay();
          const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
          const getDay = dayNames[getDayNum];
          if (offeringType === 'Monthly') {
            newRule = {
              freq: RRule.MONTHLY,
              dtstart: new Date(Date.UTC(fullyear, month, startDate, 0, 0)),
              // dtstart: validDate,
              bysetpos: monthRepeatValue,
              // byweekday: [dayMap[getDay]],
              count: 1,
            };
          } else {
            newRule = {
              freq: RRule.WEEKLY,
              dtstart: new Date(Date.UTC(fullyear, month, startDate, 0, 0)),
              // dtstart: validDate,
              // byweekday: [dayMap[getDay]],
              count: 1
            };
          }
          const rrus = new RRuleSet();
          rrus.rrule(new RRule(newRule));
          let newRuleString = {
            dayOfWeek: dayOfWeek,
            sessionDuration: 60,
            timeSlots: [[{ startTime: '10:00', endTime: '12:00' }]],
            rruleString: rrus.toString(),
          };
          // newRecurrenceRules.push(newRuleString);
          const uniqueDates = addDates.reduce((acc, current) => {
            const x = acc.find(item => item.rruleString === current.rruleString);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);

          let newRRuleString = uniqueDates.length > 0 ? [...uniqueDates] : [];
          // let updatedDatess =[];
          setAddDates(prev => {
            let updatedDates = [...prev];
            // let updatedDates = [...prev];
            const existingIndex = updatedDates.findIndex(item => item.rruleString === newRuleString.rruleString);
            console.log("existingIndex", existingIndex)
            console.log("updatedDatesupdatedDates", updatedDates)
            if (existingIndex < 0) {
              console.log('ifffffffffffffffff')
              console.log('newRuleString', newRuleString)
              updatedDates.push(newRuleString);
            } else {
              console.log('elseeeeeeeeeeeeeeeeeee')
              updatedDates.splice(existingIndex, 1);
            }



            console.log("newRRuleString after updating:", newRRuleString);
            console.log('updatedDates', updatedDates)
            console.log('updatedDates00000', updatedDatess)
            // updatedDatess = updatedDates
            updatedDatess.push(...updatedDates);
            // updatedDatess = Array.from(new Set([...updatedDates, ...updatedDatess].map(item => item.rruleString)))
            // .map(rruleString => {
            //   return [...updatedDates, ...updatedDatess].find(item => item.rruleString === rruleString);
            // });
            console.log('updatedDatess &************', updatedDatess)
            return updatedDates
          });

          console.log('updatedDatess &--------------', updatedDatess)
          console.log("markedDates*******", markedDates);
          console.log("updatedDates>>>>>>>>>>>>>>>", updatedDatess)
          console.log("newRRuleString after adding:", updatedDatess);
          // updatedDatess.length>0 && updatedDatess.forEach(rule => {
          //   if (!newRecurrenceRules.some(existingRule => existingRule.rruleString === rule.rruleString)) {
          //     newRecurrenceRules.push(rule);
          //   }
          // });
        }
        if (sessionMultipleType === '') {
          setAddDateCalendar((prev) => {
            const isSameDate = (d1, d2) =>
              d1.getFullYear() === d2.getFullYear() &&
              d1.getMonth() === d2.getMonth() &&
              d1.getDate() === d2.getDate();

            const dateExists = prev.some((item) => isSameDate(new Date(item.date), new Date(date)));
            console.log('dateExists', dateExists);

            if (dateExists) {
              // return prev.filter((item) => !isSameDate(new Date(item.date), new Date(date)));
              const removedItem = prev.find(
                (item) => new Date(item.date).toISOString() === new Date(date).toISOString()
              );
          
              setAddDatesCancel((prevCancel) => {
                const cancelExists = prevCancel.some(
                  (item) => new Date(item.date).toISOString() === new Date(date).toISOString()
                );
          
                if (!cancelExists) {
                  return [
                    ...prevCancel,
                    { ...removedItem, isMarked: !removedItem.isMarked }, // Toggle or update `isMarked`
                  ];
                }
          
                // Update `isMarked` if it already exists in `addDatesCancel`
                return prevCancel.map((item) =>
                  new Date(item.date).toISOString() === new Date(date).toISOString()
                    ? { ...item, isMarked: !item.isMarked } // Toggle `isMarked`
                    : item
                );
              });
              return prev.filter(
                (item) => new Date(item.date).toISOString() !== new Date(date).toISOString()
              );
            } else {          
              // return [
              //   ...prev,
              //   { date: date, isMarked: isMarked, timeSlots: [{ startTime: '', endTime: '' }] },
              // ];
              const newEntry = {
                date: date,
                isMarked: isMarked,
                timeSlots: [{ startTime: '', endTime: '' }],
              };
              setAddDatesCancel((prevCancel) => {
                const cancelExists = prevCancel.some((item) =>
                  isSameDate(new Date(item.date), new Date(date))
                );
          
                if (!cancelExists) {
                  return [...prevCancel, newEntry];
                } else {
                  return prevCancel.map((item) =>
                    isSameDate(new Date(item.date), new Date(date))
                      ? { ...item, isMarked: isMarked }
                      : item
                  );
                }
              });
          
              return [...prev, newEntry];
            }
          });
          // setAddOnDay(prevDays => {
          //   if (!prevDays.includes(dayOfWeeks)) {
          //     return [...prevDays, { day: dayOfWeeks, startTime: '', endTime: '' }]; // Add new weekday if it doesn't exist
          //     // return [...prevDays, dayOfWeeks]; // Add new weekday if it doesn't exist
          //   }
          //   return prevDays; // If already exists, return as is
          // });
        }
      }
      else {
        if (isMarked === false) {
          setRdates((prevRdates) => {
            console.log('date', date)
            // Add date to Rdates if not already present
            const updatedRdates = [...prevRdates, date];

            // Remove any duplicate dates by comparing the time value
            const finalRdates = updatedRdates.filter((d, index, self) =>
              index === self.findIndex((t) => t.getTime() === d.getTime())
            );

            // Also update Exdates by removing the date if it was there
            setExdates((prevExdates) => prevExdates && prevExdates.filter((d) => d.getTime() !== date.getTime()));
            setRAddDatesCancel((prevCancel) => {
              const cancelExists = prevCancel.some((item) =>
                isSameDate(new Date(item.date), new Date(date))
              );
          
              if (!cancelExists) {
                // Add new entry to addDatesCancel
                return [...prevCancel, { date, isMarked: isMarked }];
              } else {
                // If date exists, return previous state
                return prevCancel;
              }
            });
            rdatesAdd = finalRdates
            return finalRdates;
          });



        }
        if (isMarked === true) {
          setExdates((prevExdates) => {
            // Add date to Exdates if not already present
            const updatedExdates = [...prevExdates, date];

            // Remove any duplicate dates by comparing the time value
            const finalExdates = updatedExdates.filter((d, index, self) =>
              index === self.findIndex((t) => t.getTime() === d.getTime())
            );

            // Also update Rdates by removing the date if it was there
            setRdates((prevRdates) => prevRdates && prevRdates.filter((d) => d.getTime() !== date.getTime()));
            setExAddDatesCancel((prevCancel) => {
              const cancelExists = prevCancel.some((item) =>
                isSameDate(new Date(item.date), new Date(date))
              );
          
              if (!cancelExists) {
                // Add new entry to addDatesCancel
                return [...prevCancel, { date, isMarked: isMarked }];
              } else {
                // If date exists, return previous state
                return prevCancel;
              }
            });
            exdatesAdd = finalExdates
            return finalExdates;
          });

        }
      }



      updatedDatess = updatedDatess && updatedDatess?.length > 0 ? updatedDatess : addDates

      console.log("updatedDatess55555555555", updatedDatess);
      rdatesAdd = rdatesAdd && rdatesAdd?.length > 0 ? rdatesAdd : rdates
      exdatesAdd = exdatesAdd && exdatesAdd?.length > 0 ? exdatesAdd : exdates

      const newRRuleString = generateRecurrenceStrings(date, dayOfWeek, isMarked, 'single', updatedDatess, rdatesAdd, exdatesAdd);
      console.log('newRRuleString', newRRuleString)
      const newRecurrence = {
        dayOfWeek: dayOfWeek.slice(0, 2),
        sessionDuration: 60,
        startTime: undefined,
        endTime: undefined,
        rruleString: newRRuleString?.rruleString,
      };
      console.log('newRecurrence', newRecurrence)



      // setRecurrences(prev => [...prev, newRecurrence]);
      // updateMarkedDates(updatedRecurrences);
      setKey(prevKey => prevKey + 1);

      setSaveState(true)

    }
    else {
      // setShowManage(true)
      setCalendarDate(date)
      setAddDateCalendar([{ date: date, isMarked: isMarked, startTime: '', endTime: '' }]);
      let dayOfWeeks = date.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase();
      // setAddOnDay(prevDays => {
      //   if (!prevDays.includes(dayOfWeeks)) {
      //     return [...prevDays, { day: dayOfWeeks, startTime: '', endTime: '' }]; // Add new weekday if it doesn't exist
      //     // return [...prevDays, dayOfWeeks]; // Add new weekday if it doesn't exist
      //   }
      //   return prevDays; // If already exists, return as is
      // });


      // const newRRuleString = generateRecurrenceStrings(date, dayOfWeek, isMarked, 'single');
      // console.log('newRRuleString', newRRuleString)

      generateRecurrenceStrings(date)
    }

  };



  const handleTImeSession = (type) => {
    setSessionRepeat(type)
    setDayLabel([])
    setSelectedDayLabel([])
    if (type === 'weekday') {
      setDayLabel(['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'])
      setSelectedDayLabel([
        { label: 'Monday', name: 'MO', dates: [{ startTime: '', endTime: '' }] },
        { label: 'Tuesday', name: 'TU', dates: [{ startTime: '', endTime: '' }] },
        { label: 'Wednesday', name: 'WE', dates: [{ startTime: '', endTime: '' }] },
        { label: 'Thursday', name: 'TH', dates: [{ startTime: '', endTime: '' }] },
        { label: 'Friday', name: 'FR', dates: [{ startTime: '', endTime: '' }] }
      ])
    }
    if (type === 'weekend') {
      setDayLabel(['Saturday', 'Sunday'])
      setSelectedDayLabel([
        { label: 'Saturday', name: 'SA', dates: [{ startTime: '', endTime: '' }] },
        { label: 'Sunday', name: 'SU', dates: [{ startTime: '', endTime: '' }] }
      ])
    }
  }

  const handleChangeData = (type, e) => {
    setAvailabilityPeriod(type)
    
    const newDateValue = new Date(e?.target?.value);
  const previousDateValue = new Date(typeValue?.date);
  const today = new Date();
  const twoDaysAhead = new Date(today);
  twoDaysAhead.setDate(today.getDate() + Number(timeFrame?.number));
  const twoWeeksAhead = new Date(today);
  twoWeeksAhead.setDate(today.getDate() + (Number(timeFrame?.number)*7));
  const twoMonthsAhead = new Date(today);
  twoMonthsAhead.setMonth(today.getMonth() + Number(timeFrame?.number));
    if (sessionMultipleType === 'weekly') {
    if (timeFrame?.months==='weeks' && newDateValue >= today && newDateValue >= twoWeeksAhead.setHours(0, 0, 0, 0)) {
      setTypeValue({ [type]: e?.target?.value });
    } else if(timeFrame?.months==='months' && newDateValue >= today && newDateValue >= twoMonthsAhead.setHours(0, 0, 0, 0)){
      setTypeValue({ [type]: e?.target?.value });
    }else {
      if((newDateValue.getDate() !== previousDateValue.getDate())){
        setWeekDayName('untilDate')
        setSelectedDayLabelModal(true)
        }
      setTypeValue({ [type]: previousDateValue.toISOString().split("T")[0] });
    }
  } else if (sessionMultipleType === 'monthly' && timeFrame?.months === 'months') {
    if (newDateValue >= today && newDateValue >= twoMonthsAhead.setHours(0, 0, 0, 0)) {
      setTypeValue({ [type]: e?.target?.value });
    } else {
      if((newDateValue.getDate() !== previousDateValue.getDate())){
      setWeekDayName('untilDate')
      setSelectedDayLabelModal(true)
      }
      setTypeValue({ [type]: previousDateValue.toISOString().split("T")[0] });
    }
  } else if(sessionMultipleType === 'daily'){
    if(timeFrame?.months==='weeks' && newDateValue >= today && newDateValue >= twoWeeksAhead.setHours(0, 0, 0, 0)){
      setTypeValue({ [type]: e?.target?.value });
    }
    else if(timeFrame?.months==='months' && newDateValue >= today && newDateValue >= twoMonthsAhead.setHours(0, 0, 0, 0)){
      setTypeValue({ [type]: e?.target?.value });
    }
    else if(timeFrame?.months==='day' && newDateValue >= today && newDateValue >= twoDaysAhead.setHours(0, 0, 0, 0)){
      setTypeValue({ [type]: e?.target?.value });
    }
    else{
      if((newDateValue.getDate() !== previousDateValue.getDate())){
        setWeekDayName('untilDate')
        setSelectedDayLabelModal(true)
        }
      setTypeValue({ [type]: previousDateValue.toISOString().split("T")[0] });
    }
  } 
  else {
    setTypeValue({ [type]: e?.target?.value });
  }
  }
  const handleInputChange = (index, field, value, typenumber, typeCalendar, date, ind) => {
    // Helper to validate time sequence for the current slot
    const isTimeSequenceValid = (slots, currentIndex, field, newValue) => {
      // const { startTime, endTime } = slots[currentIndex];
      // if (field === 'startTime') {
      //   if (endTime == '') {
      //     return true
      //   } else {
      //     return newValue < endTime; // startTime should be before endTime
      //   }
      // } else if (field === 'endTime') {
      //   return startTime < newValue; // endTime should be after startTime
      // }
      // return true;
      const currentSlot = slots[currentIndex];
  const valueTime = convertTo24HourSame(newValue);

  const slotStart = convertTo24HourSame(currentSlot.startTime);
  const slotEnd = convertTo24HourSame(currentSlot.endTime);

  if (field === 'startTime') {
    if (currentSlot.endTime == '') {
          return true
        } else {
    return valueTime < slotEnd;
    }
  } else if (field === 'endTime') {
    return slotStart < valueTime;
    // return valueTime > slotStart;
  }
  return true;
    };

    // Helper to validate no overlapping with other slots for the same day
    // const isValueValid = (slots, newValue, currentIndex) =>
    //   !slots.some(
    //     (slot, i) =>
    //       i !== currentIndex &&
    //       slot.startTime < newValue &&
    //       newValue < slot.endTime
    //   );
    const isValueValid = (slots, newValue, currentIndex, field) => {
      // return !slots.some((slot, i) => {
      //   if (i === currentIndex) return false; 
      //   const slotStart = convertTo24HourSame(slot.startTime);
      //   const slotEnd = convertTo24HourSame(slot.endTime);
      //   if (field === 'startTime') {
      //     return (
      //       (newValue >= slotStart && newValue < slotEnd) || 
      //       newValue === slotStart
      //       // || newValue === slot.endTime
      //     );
      //   } else if (field === 'endTime') {
      //     return (
      //       (newValue > slotStart && newValue <= slotEnd) ||
      //       newValue === slotStart || 
      //       newValue === slotEnd 
      //     );
      //   }
      //   return false;
      // });
      return !slots.some((slot, i) => {
        if (i === currentIndex) return false; // Skip the current slot being edited
        
        const slotStart = convertTo24HourSame(slot.startTime);
        const slotEnd = convertTo24HourSame(slot.endTime);
        const valueTime = convertTo24HourSame(newValue);
        if (field === 'startTime') {
          return (
            valueTime >= slotStart && valueTime < slotEnd 
          );
        } else if (field === 'endTime') {
          return (
            valueTime > slotStart && valueTime <= slotEnd 
          );
        }
        return false; // Default case
      });
    };
  
    // Determine the array to update based on typeCalendar
    const selectedArray = typeCalendar === 'addon' ? addOnDay : selectedDayLabel;
    const dayData = selectedArray.find((d) => d.day === date || d.label === date);
    if (!dayData) return;

    const previousValue = dayData.timeSlots ? dayData.timeSlots[ind][field] : dayData.dates[ind][field];
    if (previousValue === value) return;

    const slots = dayData.timeSlots || dayData.dates;
    // Check if time sequence and overlap validations pass
    if (!isTimeSequenceValid(slots, ind, field, value)) {
      setWeekDayName('endTimeLess');
      setSelectedDayLabelModal(true);
      return;
    }
    if (!isValueValid(slots, value, ind,field)) {
      setWeekDayName('sameTime');
      setSelectedDayLabelModal(true);
      return;
    }

    // Update the state if all validations pass
    if (typeCalendar === 'addon') {
      setAddOnDay(prevState =>
        prevState.map(item =>
          item.day === date
            ? {
              ...item,
              timeSlots: item.timeSlots.map((slot, i) =>
                i === ind ? { ...slot, [field]: value } : slot
              ),
            }
            : item
        )
      );
    } else {
      setSelectedDayLabel(prevState =>
        prevState.map(item =>
          item.label === date
            ? {
              ...item,
              dates: item.dates.map((slot, i) =>
                i === ind ? { ...slot, [field]: value } : slot
              ),
            }
            : item
        )
      );
    }

    return true;
  };



  const handleNext = async (type) => {
    const values = {
      sessionType: sessionType,
      specifyTime: specifyTime,
      sessionRepeat: sessionRepeat,
      recurrences: recurrences,
      availabilityPeriod: availabilityPeriod,
      typeValue: typeValue,
      // offeringType: offeringType,
      // offeringTime: timeStatus,
      // recurrenceRule: newRecurrenceRules,
      // SelectedDayss: selectedDayss,
      classMode: data?.classMode || 'online',
      sameTime: sameTime,
      sessionPackage: sessionNumber,
      timeFrame: timeFrame,
      sessionMultipleType: sessionMultipleType,
      selectedDayLabel: selectedDayLabel,
      dayLabel: dayLabel,
      sessionDuration: sessionDuration,
      showManage: showManage,
      manageCalendar: manageCalendar,
      // selectedDayLabel: selectedDayLabel,
      calendarDate: calendarDate,
      sameTimeDuratioon: sameTimeDuratioon,
      addDateCalendar: addDateCalendar,
      exdates: exdates,
      rdates: rdates,
      addOnDay: addOnDay,
      showSlot: showSlot,
      step:step
    };
    if (type === 'prev') {
      dispatch(insertOfferingData(values))
      prevStep()
    }
    else {
      if (validateForm()) {
        dispatch(insertOfferingData(values))
        // if (!isValidForm()) {
        //   alert("Please fill in all required fields before generating the recurrence strings.");
        //   return;
        // }
        // if (!manageCalendar && showManage) {
        if (type === 'draft') {
          // submitForm('draft', values)
          setStateModal(true)
          // if(type === 'product'){

          // } else{
          const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
          await delay(1000)
          const resp = await submitForm('draft', values)
          if (resp === true) {
            setStateModal(false)
            setIsModal(true)
          } else {
            setStateModal(false)
          }
        }
        else {
          nextStep();
        }
        // } else {
        //   toast.error('fill the required field')
        // }
      }
    }
  }

  const handleSessionType = (type) => {
    setSessionType(type)
    setRecurrences([])
    setAddDateCalendar([])
    setDayLabel([])
    setAddDates([])
    setSessionMultipleType('')
    // setTypeValue({})
    setSessionDuration('')
    setAddDateCalendar([])
    // setAvailabilityPeriod('')
    setSelectedDayLabel([])
    setMarkedDates([])
    setShowManage(false)
    setManageCalendar(false)
    // setSameTime(false)
    // updateMarkedDates('','')
    // generateRecurrenceStrings()

  }

  const handleNaxtButton = (types) => {
    //  checkWeeksBetweenDates(typeValue?.date,timeFrame?.number);
    if (selectedDayLabel?.length < Number(sessionNumber) && sessionMultipleType === 'weekly') {
      setWeekDayName('dayWeek')
      setSelectedDayLabelModal(true)

    } else if (Number(sessionNumber) > markedDates?.length && sessionMultipleType === 'custom') {
      setWeekDayName('dayCustom')
      setSelectedDayLabelModal(true)
    } else {
      if (validateForm()) {
        handleTimeSlot(types)
      }
    }
  }
  const formatDateForSlotsAPI = (date) => {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
};
  const validateSelectedDates = () => {
    const validateDates = [];
    markedDates.forEach((entry) => {
      const [datePart] = entry.split(",");
      const [year, month, day] = datePart.trim().split("-");
      const combineDate = `${year}-${month}-${day}`;
      const date = new Date(combineDate);
      const formattedDate = formatDateForSlotsAPI(date);
      const monthKey = `${year}-${month}`;
      validateDates.push({ date: formattedDate, month: monthKey });
    });
    const months = validateDates.reduce((acc, { month }) => {
      if (!acc[month]) {
        acc[month] = 1;
      } else if (acc[month] < Number(sessionNumber)) {
        acc[month] += 1;
      }
      return acc;
    }, {});
    console.log("Months object:", months);
    console.log("Number of sessions required per month:", data?.no_of_sessions);
    const validMonthsCount = Object.values(months).filter(
      (count) => count >= Number(sessionNumber)
    ).length;
    console.log("Valid months count:", validMonthsCount);
    const result = validMonthsCount >= Number(timeFrame?.number);
    console.log("Validation result:", result);
  
    return result;
  };

  const checkWeeksBetweenDates = (types) => {
    const today = new Date();
    const untilDate = new Date(typeValue?.date);

    if (untilDate < today) {
      return "Error: Until date is in the past.";
    }

    const diffInTime = untilDate.getTime() - today.getTime();
    const diffInDays = Math.ceil(diffInTime / (1000 * 60 * 60 * 24));

    const totalWeeks = Math.ceil(diffInDays / 7);

    const totalMonths = (untilDate.getFullYear() - today.getFullYear()) * 12 + (untilDate.getMonth() - today.getMonth());

    if (sessionMultipleType === 'weekly') {
      if (totalWeeks >= Number(timeFrame?.number) && markedDates?.length>= (Number(timeFrame?.number) * Number(sessionNumber))) {
        // alert("Success: Enough weeks are available.")
        // return;
        handleNaxtButton(types)
      } else {
        // alert(`Error: Only ${totalWeeks} weeks available, but ${timeframeWeeks} weeks are required.`)
        setTotalweeksFrame({ totalWeeks: totalWeeks, timeframeWeeks: Number(timeFrame?.number), type: 'week' })
        setWeekDayName('timeframe')
        setSelectedDayLabelModal(true)
        return;
      }
    }
    else if (sessionMultipleType === 'monthly') {
      if (totalMonths >= Number(timeFrame?.number)) {
        // alert("Success: Enough weeks are available.")
        // return;
        if (validateSelectedDates()) {
        handleNaxtButton(types)
        }else{
        setTotalweeksFrame({ totalWeeks: totalMonths, timeframeWeeks: Number(timeFrame?.number), type: 'month' })
        setWeekDayName('timeframes')
        setSelectedDayLabelModal(true)
        return;
        }
      } else {
        // alert(`Error: Only ${totalWeeks} weeks available, but ${timeframeWeeks} weeks are required.`)
        setTotalweeksFrame({ totalWeeks: totalMonths, timeframeWeeks: Number(timeFrame?.number), type: 'month' })
        setWeekDayName('timeframe')
        setSelectedDayLabelModal(true)
        return;
      }
    }
    else if(sessionMultipleType === 'daily'){
      if(timeFrame?.months==='day'){
        if(markedDates?.length>=Number(timeFrame?.number)){
          handleNaxtButton(types)
        }else {
          setTotalweeksFrame({ totalWeeks: Number(timeFrame?.number), timeframeWeeks: Number(timeFrame?.number), type: 'session' })
        setWeekDayName('timeframe')
        setSelectedDayLabelModal(true)
        }
      }else if(timeFrame?.months==='weeks'){
        if(markedDates?.length>=(Number(timeFrame?.number)*7)){
          handleNaxtButton(types)
        }else {
          setTotalweeksFrame({ totalWeeks: (Number(timeFrame?.number)*7), timeframeWeeks: Number(timeFrame?.number), type: 'weeks' })
        setWeekDayName('timeframe')
        setSelectedDayLabelModal(true)
        }
      }else if(timeFrame?.months==='months'){
        if(markedDates?.length>=(Number(timeFrame?.number)*30)){
          handleNaxtButton(types)
        }else {
          setTotalweeksFrame({ totalWeeks: (Number((timeFrame?.number)*30)), timeframeWeeks: Number(timeFrame?.number), type: 'months' })
        setWeekDayName('timeframe')
        setSelectedDayLabelModal(true)
        }
      }
      
    }
    else {
      handleNaxtButton(types)
    }
  };

  const handleTimeSlot = (types) => {
    if (sessionMultipleType === 'daily' && sessionType === 'multiple_session') {
      if (calendarDate === '') {
        setWeekDayName('date')
        setSelectedDayLabelModal(true)
        // alert('Please select the date');
        return;
      }
    }
    if (sameTime && selectedDayLabel?.length !== 1) {
      const isEmptyTimeSlot = sameTimeDuratioon?.some((x) =>
        x?.startTime === '' && x?.endTime === ''
      );

      if (isEmptyTimeSlot) {
        setWeekDayName('');
        setSelectedDayLabelModal(true);
        return;
      }
    }
    if (sameTime) {
      const isEmptyTimeSlot = sameTimeDuratioon?.some((x) =>
        x?.startTime === '' && x?.endTime === '' || x?.startTime === '00:00 PM' || x?.startTime === '00:00 AM' || x?.endTime === '00:00 PM' || x?.endTime === '00:00 AM'
      );

      if (isEmptyTimeSlot) {
        setWeekDayName('');
        setSelectedDayLabelModal(true);
        return;
      }
    }
    // if (sameTime && selectedDayLabel?.length !== 1 && sameTimeDuratioon[0]?.startTime === '' && sameTimeDuratioon[0]?.endTime === '') {
    //   setWeekDayName('')
    //   setSelectedDayLabelModal(true)
    //   // alert('Please fill the time slot');
    //   return;
    // }
    if (sameTime) {
      // if (sameTime && selectedDayLabel?.length !== 1) {
      const isInvalidSession = sameTimeDuratioon?.some((x) => {
        // if (!x?.startTime || !x?.endTime) return false; // Ensure valid time values
        if (!x?.startTime || !x?.endTime){
          setWeekDayName('');
          setSelectedDayLabelModal(true);
          return true;
        } 

        const [startTimeString, startPeriod] = x.startTime.split(' ');
        const [endTimeString, endPeriod] = x.endTime.split(' ');

        const [startHour, startMinute] = startTimeString.split(':').map(Number);
        const [endHour, endMinute] = endTimeString.split(':').map(Number);

        const startDate = new Date();
        startDate.setHours(convertTo24Hour(startHour, startPeriod), startMinute, 0);

        const endDate = new Date();
        endDate.setHours(convertTo24Hour(endHour, endPeriod), endMinute, 0);

        const timeDifference = endDate - startDate;
        const differenceInMinutes = Math.floor(timeDifference / (1000 * 60));

        if (sessionDuration !== '' && Number(sessionDuration) > differenceInMinutes) {
          setWeekDayName('session');
          setSelectedDayLabelModal(true);
          return true;
        }

        return false;
      });

      if (isInvalidSession) return;
    }
    // if (sameTime && selectedDayLabel?.length !== 1 && sameTimeDuratioon[0]?.startTime !== '' && sameTimeDuratioon[0]?.endTime !== '') {
    //   const [startTimeString, startPeriod] = sameTimeDuratioon[0]?.startTime.split(' ');
    //   const [endTimeString, endPeriod] = sameTimeDuratioon[0]?.endTime.split(' ');

    //   const [startHour, startMinute] = startTimeString.split(':').map(Number);
    //   const [endHour, endMinute] = endTimeString.split(':').map(Number);

    //   const startDate = new Date();
    //   startDate.setHours(convertTo24Hour(startHour, startPeriod), startMinute, 0);

    //   const endDate = new Date();
    //   endDate.setHours(convertTo24Hour(endHour, endPeriod), endMinute, 0);
    //   const timeDifference = endDate - startDate;
    //   const differenceInMinutes = Math.floor(timeDifference / (1000 * 60));
    //   if (sessionDuration !== '' && (Number(sessionDuration) > Number(differenceInMinutes))) {
    //     setWeekDayName('session')
    //     setSelectedDayLabelModal(true)
    //     // alert('same Session time must be shorter than your availability')
    //     return;
    //   }
    // }
    // if (!sameTime && selectedDayLabel && selectedDayLabel.length > 0) {
    //   for (let i = 0; i < selectedDayLabel.length; i++) {
    //     const x = selectedDayLabel[i];
    //     if (x?.dates[0]?.startTime === '' || x?.dates[0]?.endTime === '') {
    //       setWeekDayName(x?.label)
    //       setSelectedDayLabelModal(true)
    //       return;
    //     }
    //   }
    // }
    if (!sameTime && selectedDayLabel && selectedDayLabel.length > 0) {
      for (const x of selectedDayLabel) {
        if (
          x?.dates?.length > 0 &&
          x.dates.some((y) => y?.startTime === '' || y?.endTime === '' || y?.startTime === '00:00 PM' || y?.startTime === '00:00 AM' || y?.endTime === '00:00 PM' || y?.endTime === '00:00 AM')
        ) {
          setWeekDayName(x?.label);
          setSelectedDayLabelModal(true);
          return; // Early exit when condition is met
        }
      }
    }
    // if (!sameTime && selectedDayLabel && selectedDayLabel.length > 0) {
    //   for (let i = 0; i < selectedDayLabel.length; i++) {
    //     const x = selectedDayLabel[i];
    //     if (x?.dates[0]?.startTime !== '' || x?.dates[0]?.endTime !== '') {

    //       const [startHour, startMinute] = x?.dates[0]?.startTime.split(':').map(Number);
    //       const [endHour, endMinute] = x?.dates[0]?.endTime.split(':').map(Number);
    //       const startDate = new Date();
    //       startDate.setHours(startHour, startMinute, 0);
    //       const endDate = new Date();
    //       endDate.setHours(endHour, endMinute, 0);
    //       const timeDifference = endDate - startDate;
    //       const differenceInMinutes = Math.floor(timeDifference / (1000 * 60));
    //       if (sessionDuration !== '' && (Number(sessionDuration) > Number(differenceInMinutes))) {
    //         alert('Session time must be shorter than your availability')
    //         return;
    //       }
    //     }
    //   }
    // }

    // if (!sameTime && selectedDayLabel && selectedDayLabel.length > 0) {
    //   for (const x of selectedDayLabel) {
    //     const firstDate = x?.dates?.[0];

    //     if (firstDate?.startTime && firstDate?.endTime) {
    //       // Extract start and end times with period (AM/PM)
    //       const [startTimeString, startPeriod] = firstDate.startTime.split(' ');
    //       const [endTimeString, endPeriod] = firstDate.endTime.split(' ');

    //       const [startHour, startMinute] = startTimeString.split(':').map(Number);
    //       const [endHour, endMinute] = endTimeString.split(':').map(Number);

    //       // Create Date objects for start and end times
    //       const startDate = new Date();
    //       startDate.setHours(convertTo24Hour(startHour, startPeriod), startMinute, 0);

    //       const endDate = new Date();
    //       endDate.setHours(convertTo24Hour(endHour, endPeriod), endMinute, 0);

    //       // Check if start time is not earlier than end time
    //       if (startDate >= endDate) {
    //         alert('Selected start time must be earlier than the end time.');
    //         return;
    //       }

    //       // Calculate time difference in minutes
    //       const timeDifference = Math.floor((endDate - startDate) / (1000 * 60));
    //       // Check if session duration exceeds the available time
    //       if ((sessionDuration!=='' && (Number(sessionDuration) > timeDifference))) {
    //         setWeekDayName('session');
    //         setSelectedDayLabelModal(true);
    //         return;
    //       }
    //     }
    //   }
    // }
    if (!sameTime && selectedDayLabel && selectedDayLabel.length > 0) {
      for (const day of selectedDayLabel) {
        if (day?.dates && day.dates.length > 0) {
          for (const timeSlot of day.dates) {
            const { startTime, endTime } = timeSlot;

            if (startTime && endTime) {
              // Extract start and end times with period (AM/PM)
              const [startTimeString, startPeriod] = startTime.split(' ');
              const [endTimeString, endPeriod] = endTime.split(' ');

              const [startHour, startMinute] = startTimeString.split(':').map(Number);
              const [endHour, endMinute] = endTimeString.split(':').map(Number);

              // Create Date objects for start and end times
              const startDate = new Date();
              startDate.setHours(convertTo24Hour(startHour, startPeriod), startMinute, 0);

              const endDate = new Date();
              endDate.setHours(convertTo24Hour(endHour, endPeriod), endMinute, 0);

              // Check if start time is not earlier than end time
              if (startDate >= endDate) {
                // alert('Selected start time must be earlier than the end time.');
                setWeekDayName('session');
                setSelectedDayLabelModal(true);
                return;
              }

              // Calculate time difference in minutes
              const timeDifference = Math.floor((endDate - startDate) / (1000 * 60));

              // Check if session duration exceeds the available time
              if (sessionDuration !== '' && Number(sessionDuration) > timeDifference) {
                setWeekDayName('session');
                setSelectedDayLabelModal(true);
                return;
              }
            }
          }
        }
      }
    }

    // Helper function to convert 12-hour time to 24-hour format
    function convertTo24Hour(hour, period) {
      let hourInt = parseInt(hour, 10);
      if (period === 'PM' && hourInt !== 12) {
        hourInt += 12;
      } else if (period === 'AM' && hourInt === 12) {
        hourInt = 0; // Handle midnight case
      }
      return hourInt;
    }

    if (sessionDuration === '') {
      setWeekDayName('null')
      setSelectedDayLabelModal(true)
      // alert("please fill the time slots");
      return;
    } else if (!/^\d+$/.test(sessionDuration)) {
      alert("Session duration must be a valid number.");
      return;
    }
    // const trimmedSessionDuration = sessionDuration.trim();

    // if (trimmedSessionDuration === '') {
    //     alert("Duration can not contain white spaces");
    //     return;
    // }

    if (!sameTime && addOnDay && addOnDay.length > 0) {
      for (let i = 0; i < addOnDay.length; i++) {
        const x = addOnDay[i];
        if (x?.timeSlots[0]?.startTime === '' || x?.timeSlots[0]?.endTime == '' || x?.timeSlots[0]?.startTime === '00:00 PM' || x?.timeSlots[0]?.startTime === '00:00 AM' || x?.timeSlots[0]?.endTime === '00:00 PM' || x?.timeSlots[0]?.endTime === '00:00 AM') {
          setWeekDayName(x?.day)
          setSelectedDayLabelModal(true)
          // alert(`Please fill the time slot`);
          // alert(`Please fill the ${x?.date} time slot`);
          return;
        }
      }
    }
    if (!sameTime && addOnDay && addOnDay.length > 0) {
      for (const day of addOnDay) {
        if (day?.timeSlots && day.timeSlots.length > 0) {
          for (const slot of day.timeSlots) {
            const { startTime, endTime } = slot;

            // Ensure both startTime and endTime are non-empty
            if (startTime && endTime) {
              const [startTimeString, startPeriod] = startTime.split(' ');
              const [endTimeString, endPeriod] = endTime.split(' ');

              const [startHour, startMinute] = startTimeString.split(':').map(Number);
              const [endHour, endMinute] = endTimeString.split(':').map(Number);

              // Create Date objects for start and end times
              const startDate = new Date();
              startDate.setHours(convertTo24Hour(startHour, startPeriod), startMinute, 0);

              const endDate = new Date();
              endDate.setHours(convertTo24Hour(endHour, endPeriod), endMinute, 0);

              // Check if startTime is later than or equal to endTime
              if (startDate >= endDate) {
                setWeekDayName('session');
                setSelectedDayLabelModal(true);
                return;
              }

              // Calculate the time difference in minutes
              const timeDifference = endDate - startDate;
              const differenceInMinutes = Math.floor(timeDifference / (1000 * 60));

              // Ensure sessionDuration is a valid number and compare it
              if (sessionDuration !== '' && Number(sessionDuration) > differenceInMinutes) {
                setWeekDayName('session');
                setSelectedDayLabelModal(true);
                return;
              }
            }
          }
        }
      }
    }

    handleDateClickAddon();
    setShowManage(true);
    if (types === 'manage') {
      setManageCalendar(false)
    }
    handleNext(types)

  };
  const stripTime = (date) => new Date(date.getFullYear(), date.getMonth(), date.getDate());

  const handleSaveRecurrence = async(date, isMarked, types) => {
    console.log('yessssssss')
    if(data?.editype === 'edit'){
      const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
        const day = String(date.getDate()).padStart(2, '0')
        const formattedDate = `${year}-${month}-${day}`;
        const resp = await getApi(`creator/edit/single-date?date=${formattedDate}&offer_id=${data?.id}`)
        if(resp?.statusCode ===200){
          if(resp?.data== 0){
            
            return;
          } else{
            toast.error(resp?.message)
            const formattedDates = formatDateForAPI(new Date(formattedDate));
            await fetchData(formattedDates)
            return;
          }
        }
    }
    if (sessionMultipleType === 'daily') {
      if (types === 'first') {
        setSaveSlotWeek(true); setManageCalendar(false); setShowManage(true);
        // const dates = new Date(date);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
        const day = String(date.getDate()).padStart(2, '0')
        const formattedDate = `${year}-${month}-${day}`;
        // const formattedDate = dates.toISOString().split('T')[0];
        if (formattedDate < typeValue?.date) {
          setCalendarDate(date)
        } else {
          alert('please select the before until date')
          return;
        }
      }
    }
    // setIsMArkedDates(isMarked)
    // setAddOnDates(date)
    // setAddDateCalendar((prev) => [...prev, { date: date, isMarked: isMarked }])

    if (sessionType === 'single_session' && types !== 'first') {
      let dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase(); // Get the day of the week in 'SU', 'MO', etc.
      dayOfWeek = dayOfWeek.slice(0, 2);
      if (!weekDay.includes(dayOfWeek)) {
        // if (exdates?.length > 0) {
        //   
        const today = new Date(calendarDate);
        const twoWeeksFromNow = new Date(typeValue?.date);
        const timeDifference = twoWeeksFromNow - today;
        const differenceInDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

        // const isWithinTwoWeeks = selectedDate >= today && selectedDate < twoWeeksFromNow;

        // if ((markedDates.length < (sessionNumber * timeFrame?.number) || isMarked === true)) {
        setAddDateCalendar((prev) => {
          const isSameDate = (d1, d2) =>
            d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDate() === d2.getDate();

            const stripTime = (date) =>
              new Date(date.getFullYear(), date.getMonth(), date.getDate());
            const strippedDate = stripTime(new Date(date));

          const dateExists = prev.some((item) => isSameDate(stripTime(new Date(item.date)), strippedDate));
          // const dateExists = prev.some((item) => isSameDate(new Date(item.date), new Date(date)));
          console.log('dateExists', dateExists);

          if (dateExists) {
            // return prev.filter((item) => !isSameDate(new Date(item.date), new Date(date)));
            const removedItem = prev.find(
              (item) => isSameDate(stripTime(new Date(item.date)), strippedDate)
              // (item) => new Date(item.date).toISOString() === new Date(date).toISOString()
            );
        
            setAddDatesCancel((prevCancel) => {
              const cancelExists = prevCancel.some(
                (item) => isSameDate(stripTime(new Date(item.date)), strippedDate)
                // (item) => new Date(item.date).toISOString() === new Date(date).toISOString()
              );
        // debugge
              if (!cancelExists) {
                return [
                  ...prevCancel,
                  { ...removedItem, isMarked: !removedItem.isMarked }, // Toggle or update `isMarked`
                ];
              }
        
              // Update `isMarked` if it already exists in `addDatesCancel`
              return prevCancel.map((item) =>
                new Date(item.date).getDate() === new Date(date).getDate()
                // new Date(item.date).toISOString() === new Date(date).toISOString()
                  ? { ...item, isMarked: !item.isMarked } // Toggle `isMarked`
                  : item
              );
            });
            return prev.filter(
              (item) => !isSameDate(stripTime(new Date(item.date)), strippedDate)
              // (item) => new Date(item.date).toISOString() !== new Date(date).toISOString()
            );
          } else {   
            // return [
            //   ...prev,
            //   { date: date, isMarked: isMarked, timeSlots: [{ startTime: '', endTime: '' }] },
            // ];
            const newEntry = {
              date: date,
              isMarked: isMarked,
              timeSlots: [{ startTime: '', endTime: '' }],
            };
            setAddDatesCancel((prevCancel) => {
              const cancelExists = prevCancel.some((item) =>
                isSameDate(new Date(item.date), new Date(date))
              );
        
              if (!cancelExists) {
                return [...prevCancel, newEntry];
              } else {
                return prevCancel.map((item) =>
                  isSameDate(new Date(item.date), new Date(date))
                    ? { ...item, isMarked: isMarked }
                    : item
                );
              }
            });
        
            return [...prev, newEntry];
          }
        });
        let dayOfWeeks = date.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase();
        // setAddOnDay(prevDays => {
        //   if (!prevDays.includes(dayOfWeeks)) {
        //     return [...prevDays, { day: dayOfWeeks, startTime: '', endTime: '' }]; // Add new weekday if it doesn't exist
        //     // return [...prevDays, dayOfWeeks]; // Add new weekday if it doesn't exist
        //   }
        //   return prevDays; // If already exists, return as is
        // });



      } else {
        handleDateClickAddon(date, isMarked, 'erdate')
      }
      // handleDateClick(date, isMarked)
    } else {
      // if(sessionType ==='multiple_session' && sessionMultipleType === 'daily'){
      if (sessionType === 'multiple_session') {
        // if (types !== 'first' && sessionMultipleType !== 'monthly') {
        if (types !== 'first') {
          let dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase(); // Get the day of the week in 'SU', 'MO', etc.
          dayOfWeek = dayOfWeek.slice(0, 2);
          if (!weekDay.includes(dayOfWeek)) {
            // if (exdates?.length > 0) {
            //   
            const today = new Date(calendarDate);
            const twoWeeksFromNow = new Date(typeValue?.date);
            const timeDifference = twoWeeksFromNow - today;
            const differenceInDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

            // const isWithinTwoWeeks = selectedDate >= today && selectedDate < twoWeeksFromNow;

            // if ((markedDates.length < (sessionNumber * timeFrame?.number) || isMarked === true)) {
            // setAddDateCalendar((prev) => {
            //   const dateExists = prev.some((item) => new Date(item.date).toISOString() === new Date(date).toISOString());
            //   console.log('dateExists', dateExists)
            //   // If the date exists, remove it; otherwise, add it
            //   if (dateExists) {
            //     return prev.filter((item) => new Date(item.date).toISOString() !== new Date(date).toISOString());
            //   } else {
            //     return [...prev, { date: date, isMarked: isMarked, timeSlots: [{ startTime: '', endTime: '' }] }];
            //     // return [...prev, { date: date, isMarked: isMarked, startTime: '', endTime: '' }];
            //   }

            //   // const newRRuleString = generateRecurrenceStrings(date, dayOfWeek, isMarked, 'single');
            //   // console.log('newRRuleString', newRRuleString)

            // })
            setAddDateCalendar((prev) => {
              const isSameDate = (d1, d2) =>
                d1.getFullYear() === d2.getFullYear() &&
                d1.getMonth() === d2.getMonth() &&
                d1.getDate() === d2.getDate();
                const stripTime = (date) =>
                  new Date(date.getFullYear(), date.getMonth(), date.getDate());
              
                const strippedDate = stripTime(new Date(date));
              const dateExists = prev.some((item) => isSameDate(stripTime(new Date(item.date)), strippedDate));
              // const dateExists = prev.some((item) => isSameDate(new Date(item.date), new Date(date)));
              console.log('dateExists', dateExists);

              if (dateExists) {
                // return prev.filter((item) => !isSameDate(new Date(item.date), new Date(date)));
                const removedItem = prev.find(
                  (item) => isSameDate(stripTime(new Date(item.date)), strippedDate)
                  // (item) => new Date(item.date).toISOString() === new Date(date).toISOString()
                );
            
                setAddDatesCancel((prevCancel) => {
                  const cancelExists = prevCancel.some(
                    (item) => isSameDate(stripTime(new Date(item.date)), strippedDate)
                    // (item) => new Date(item.date).toISOString() === new Date(date).toISOString()
                  );
            
                  if (!cancelExists) {
                    return [
                      ...prevCancel,
                      { ...removedItem, isMarked: !removedItem.isMarked }, // Toggle or update `isMarked`
                    ];
                  }
            
                  // Update `isMarked` if it already exists in `addDatesCancel`
                  return prevCancel.map((item) =>
                    isSameDate(stripTime(new Date(item.date)), strippedDate)
                    // new Date(item.date).toISOString() === new Date(date).toISOString()
                      ? { ...item, isMarked: !item.isMarked } // Toggle `isMarked`
                      : item
                  );
                });
                return prev.filter(
                  (item) => !isSameDate(stripTime(new Date(item.date)), strippedDate)
                  // (item) => new Date(item.date).toISOString() !== new Date(date).toISOString()
                );
              } else {  
                // return [
                //   ...prev,
                //   { date: date, isMarked: isMarked, timeSlots: [{ startTime: '', endTime: '' }] },
                // ];
                const newEntry = {
                  date: date,
                  isMarked: isMarked,
                  timeSlots: [{ startTime: '', endTime: '' }],
                };
                setAddDatesCancel((prevCancel) => {
                  const cancelExists = prevCancel.some((item) =>
                    isSameDate(new Date(item.date), new Date(date))
                  );
            
                  if (!cancelExists) {
                    return [...prevCancel, newEntry];
                  } else {
                    return prevCancel.map((item) =>
                      isSameDate(new Date(item.date), new Date(date))
                        ? { ...item, isMarked: isMarked }
                        : item
                    );
                  }
                });
            
                return [...prev, newEntry];
              }
            });
            let dayOfWeeks = date.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase();
            // setAddOnDay(prevDays => {
            //   if (!prevDays.includes(dayOfWeeks)) {
            //     return [...prevDays, { day: dayOfWeeks, startTime: '', endTime: '' }]; // Add new weekday if it doesn't exist
            //     // return [...prevDays, dayOfWeeks]; // Add new weekday if it doesn't exist
            //   }
            //   return prevDays; // If already exists, return as is
            // });


          }
          else {
            // if((rdates.length < exdates.length) || (markedDates.length<4)){
            if (sessionMultipleType === 'daily') {

              // if ((markedDates.length < Number(timeFrame?.number) * 7) || isMarked === true) {
              const today = new Date(calendarDate);
              const twoWeeksFromNow = new Date(typeValue?.date);

              const selectedDate = new Date(date);
              const isWithinTwoWeeks = selectedDate >= today && selectedDate < twoWeeksFromNow;
              if (!isWithinTwoWeeks) {
                // setAddDateCalendar((prev) => {
                //   const dateExists = prev.some((item) => new Date(item.date).toISOString() === new Date(date).toISOString());
                //   console.log('dateExists', dateExists)
                //   if (dateExists) {
                //     return prev.filter((item) => new Date(item.date).toISOString() !== new Date(date).toISOString());
                //   } else {
                //     return [...prev, { date: date, isMarked: isMarked, timeSlots: [{ startTime: '', endTime: '' }] }];
                //   }

                // })
                setAddDateCalendar((prev) => {
                  const isSameDate = (d1, d2) =>
                    d1.getFullYear() === d2.getFullYear() &&
                    d1.getMonth() === d2.getMonth() &&
                    d1.getDate() === d2.getDate();
                    const stripTime = (date) =>
                      new Date(date.getFullYear(), date.getMonth(), date.getDate());
                  
                    const strippedDate = stripTime(new Date(date));
                  const dateExists = prev.some((item) => isSameDate(stripTime(new Date(item.date)), strippedDate));
                  console.log('dateExists', dateExists);

                  if (dateExists) {
                    // return prev.filter((item) => !isSameDate(new Date(item.date), new Date(date)));
                    const removedItem = prev.find(
                      (item) => isSameDate(stripTime(new Date(item.date)), strippedDate)
                      // (item) => new Date(item.date).toISOString() === new Date(date).toISOString()
                    );
                
                    setAddDatesCancel((prevCancel) => {
                      const cancelExists = prevCancel.some(
                        (item) =>isSameDate(stripTime(new Date(item.date)), strippedDate)
                        // (item) => new Date(item.date).toISOString() === new Date(date).toISOString()
                      );
                
                      if (!cancelExists) {
                        return [
                          ...prevCancel,
                          { ...removedItem, isMarked: !removedItem.isMarked }, // Toggle or update `isMarked`
                        ];
                      }
                
                      // Update `isMarked` if it already exists in `addDatesCancel`
                      return prevCancel.map((item) =>
                        isSameDate(stripTime(new Date(item.date)), strippedDate)
                        // new Date(item.date).toISOString() === new Date(date).toISOString()
                          ? { ...item, isMarked: !item.isMarked } // Toggle `isMarked`
                          : item
                      );
                    });
                    return prev.filter(
                      (item) => !isSameDate(stripTime(new Date(item.date)), strippedDate)
                      // (item) => new Date(item.date).toISOString() !== new Date(date).toISOString()
                    );
                  } else {
                        
                    // return [
                    //   ...prev,
                    //   { date: date, isMarked: isMarked, timeSlots: [{ startTime: '', endTime: '' }] },
                    // ];
                    const newEntry = {
                      date: date,
                      isMarked: isMarked,
                      timeSlots: [{ startTime: '', endTime: '' }],
                    };
                    setAddDatesCancel((prevCancel) => {
                      const cancelExists = prevCancel.some((item) =>
                        isSameDate(stripTime(new Date(item.date)), strippedDate)
                        // isSameDate(new Date(item.date), new Date(date))
                      );
                
                      if (!cancelExists) {
                        return [...prevCancel, newEntry];
                      } else {
                        return prevCancel.map((item) =>
                          isSameDate(stripTime(new Date(item.date)), strippedDate)
                          // isSameDate(new Date(item.date), new Date(date))
                            ? { ...item, isMarked: isMarked }
                            : item
                        );
                      }
                    });
                
                    return [...prev, newEntry];
                  }
                });
                let dayOfWeeks = date.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase();
                // setAddOnDay(prevDays => {
                //   if (!prevDays.includes(dayOfWeeks)) {
                //     return [...prevDays, { day: dayOfWeeks,  startTime: '', endTime: '' }]; // Add new weekday if it doesn't exist
                //     // return [...prevDays, dayOfWeeks]; // Add new weekday if it doesn't exist
                //   }
                //   return prevDays; // If already exists, return as is
                // });
              }

            }
            handleDateClickAddon(date, isMarked, 'erdate')
            // }
            // else{
            //   alert('first remove the recurrence');
            //   return;

            // }
          }
        }
        if (types === 'first' && sessionMultipleType === 'daily') {
          setSelectedDayLabel([
            { label: 'Monday', name: 'MO', dates: [{ startTime: '', endTime: '' }] },
            { label: 'Tuesday', name: 'TU', dates: [{ startTime: '', endTime: '' }] },
            { label: 'Wednesday', name: 'WE', dates: [{ startTime: '', endTime: '' }] },
            { label: 'Thursday', name: 'TH', dates: [{ startTime: '', endTime: '' }] },
            { label: 'Friday', name: 'FR', dates: [{ startTime: '', endTime: '' }] },
            { label: 'Saturday', name: 'SA', dates: [{ startTime: '', endTime: '' }] },
            { label: 'Sunday', name: 'SU', dates: [{ startTime: '', endTime: '' }] }
          ])
        }


        if (types === 'first') {

          generateRecurrenceStrings(date, '', isMarked, types)
        } else {
          // handleDateClickAddon()
        }
      }
      if (sessionType === 'single_session') {
        if (types === 'first') {
          generateRecurrenceStrings(date, '', isMarked, types)
        }
      }
    }
  }

  const handleRemoveTimeSlot = (index) => {
    setSameTimeDuration(sameTimeDuratioon.filter((_, i) => i !== index));
  };


  const convertTo24HourSame = (time) => {
    if (!time || typeof time !== 'string') {
      console.error('Invalid time format:', time);
      return NaN;
    }
    const trimmedTime = time.trim().toUpperCase();
    const [timePart, period] = trimmedTime.split(' ');
    if (!timePart || !period) {
      console.error('Invalid time format:', time);
      return NaN;
    }
  
    const [hourStr, minuteStr] = timePart.split(':');
    const hour = Number(hourStr);
    const minute = Number(minuteStr);
  
    if (isNaN(hour) || isNaN(minute)) {
      console.error('Invalid time components:', hour, minute);
      return NaN;
    }
  
    const isPM = period === 'PM';
    return isPM ? (hour % 12) + 12 + minute / 60 : (hour % 12) + minute / 60;
  };

  const handleSameTimeChange = (index, field, value) => {
    // const isValueValid = (slots, newValue) =>
    //   !slots.some(
    //     (slot) =>
    //       slot.startTime <= newValue && newValue < slot.endTime
    //   );

    // const previousValue = sameTimeDuratioon[index][field];
    // if (previousValue === value) {
    //   // alert("No change detected");
    //   return;
    // }

    // if (!isValueValid(sameTimeDuratioon, value)) {
    //   setWeekDayName('sameTime');
    //   setSelectedDayLabelModal(true);
    //   // alert("Invalid value: Time overlaps with existing slot");
    //   return;
    // }
    const isTimeSequenceValid = (slots, currentIndex, field, newValue) => {
      // const { startTime, endTime } = slots[currentIndex];
      // if (field === 'startTime') {
      //   if (endTime == '') {
      //     return true
      //   } else {
      //     return newValue < endTime; // startTime should be before endTime
      //   }
      // } else if (field === 'endTime') {
      //   return startTime < newValue; // Current startTime must be less than new endTime
      // }
      // return true;
      const currentSlot = slots[currentIndex];
  const valueTime = convertTo24HourSame(newValue);

  const slotStart = convertTo24HourSame(currentSlot.startTime);
  const slotEnd = convertTo24HourSame(currentSlot.endTime);

  if (field === 'startTime') {
    if (currentSlot.endTime == '') {
          return true
        } else {
    return valueTime < slotEnd;
    }
  } else if (field === 'endTime') {
    return slotStart < valueTime;
    // return valueTime > slotStart;
  }
  return true;
    };
    // const isValueValid = (slots, newValue, currentIndex) =>
    //   !slots.some(
    //     (slot, i) =>
    //       i !== currentIndex && 
    //       slot.startTime < newValue && newValue < slot.endTime
    //   );
    const isValueValid = (slots, newValue, currentIndex, field) => {
      return !slots.some((slot, i) => {
        if (i === currentIndex) return false; // Skip the current slot being edited
        
        const slotStart = convertTo24HourSame(slot.startTime);
        const slotEnd = convertTo24HourSame(slot.endTime);
        const valueTime = convertTo24HourSame(newValue);
        if (field === 'startTime') {
          return (
            valueTime >= slotStart && valueTime < slotEnd 
          );
        } else if (field === 'endTime') {
          return (
            valueTime > slotStart && valueTime <= slotEnd 
          );
        }
        return false; // Default case
      });
    };
  
    const previousValue = sameTimeDuratioon[index][field];
    if (previousValue === value) {
      return;
    }
    if (!isTimeSequenceValid(sameTimeDuratioon, index, field, value)) {
      setWeekDayName('endTimeLess');
      setSelectedDayLabelModal(true);
      // alert("Invalid time sequence: End time must be greater than start time.");
      return;
    }
  
    if (!isValueValid(sameTimeDuratioon, value, index,field)) {
      setWeekDayName('sameTime');
      setSelectedDayLabelModal(true);
      return;
    }
    const updatedTimeSlots = sameTimeDuratioon.map((slot, i) =>
      i === index ? { ...slot, [field]: value } : slot
    );
    setSameTimeDuration(updatedTimeSlots);
    setSelectedDayLabel((prevState) =>
      prevState.map((item) => {
        const updatedDates = item.dates.map((slot, i) =>
          i === index ? { ...slot, [field]: value } : slot
        );
        return { ...item, dates: updatedDates }; // Return updated item
      })
    );
    setAddOnDay((prevState) =>
      prevState.map((item) => {
        const updatedDates = item.timeSlots.map((slot, i) =>
          i === index ? { ...slot, [field]: value } : slot
        );
        return { ...item, timeSlots: updatedDates }; // Return updated item
      })
    );
    return true;
  };
  const handleAddTimeSlot = () => {
    setSameTimeDuration([...sameTimeDuratioon, { startTime: '', endTime: '' }]);
  };

  const handleUpdateSelectedTimeSlots = (label) => {
    setSelectedDayLabel((prev) => {
      return prev.map((item) => {
        if (item?.label === label) {
          // Add a new empty time slot to the timeSlots array
          const updatedTimeSlots = [...item.dates, { startTime: '', endTime: '' }];

          // Return the updated date object with the new time slot added
          return { ...item, dates: updatedTimeSlots };
        }
        return item;
      });
    });
  };

  const handleRemoveSelectedTimeSlot = (label, indexToRemove) => {
    setSelectedDayLabel((prev) => {
      return prev.map((item) => {
        if (item?.label === label) {
          // Remove the time slot at the specified index
          const updatedTimeSlots = item.dates.filter((_, i) => i !== indexToRemove);

          // Return the updated date object with the modified timeSlots array
          return { ...item, dates: updatedTimeSlots };
        }
        return item;
      });
    });
  };

  const handleUpdateTimeSlots = (date) => {
    // setAddDateCalendar((prev) => {
    //   return prev.map((item) => {
    //     if (new Date(item.date).toISOString() === new Date(date).toISOString()) {
    //       // Add a new empty time slot to the timeSlots array
    //       const updatedTimeSlots = [...item.timeSlots, { startTime: '', endTime: '' }];

    //       // Return the updated date object with the new time slot added
    //       return { ...item, timeSlots: updatedTimeSlots };
    //     }
    //     return item;
    //   });
    // });
    setAddOnDay((prev) => {
      return prev.map((item) => {
        if (item.day === date) {
          // Add a new empty time slot to the timeSlots array
          const updatedTimeSlots = [...item.timeSlots, { startTime: '', endTime: '' }];

          // Return the updated date object with the new time slot added
          return { ...item, timeSlots: updatedTimeSlots };
        }
        return item;
      });
    });
    // let dayOfWeeks = date.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase();
    // setAddOnDay(prevDays => {
    //   if (!prevDays.includes(dayOfWeeks)) {
    //     return [...prevDays, { day: dayOfWeeks, startTime: '', endTime: '' }]; // Add new weekday if it doesn't exist
    //     // return [...prevDays, dayOfWeeks]; // Add new weekday if it doesn't exist
    //   }
    //   return prevDays; // If already exists, return as is
    // });
  };

  const handleRemoveAddDatesTimeSlot = (date, indexToRemove) => {
    // setAddDateCalendar((prev) => {
    //   return prev.map((item) => {
    //     if (new Date(item.date).toISOString() === new Date(date).toISOString()) {
    //       // Remove the time slot at the specified index
    //       const updatedTimeSlots = item.timeSlots.filter((_, i) => i !== indexToRemove);

    //       // Return the updated date object with the modified timeSlots array
    //       return { ...item, timeSlots: updatedTimeSlots };
    //     }
    //     return item;
    //   });
    // });
    setAddOnDay((prev) => {
      return prev.map((item) => {
        if (item?.day === date) {
          // Remove the time slot at the specified index
          const updatedTimeSlots = item.timeSlots.filter((_, i) => i !== indexToRemove);

          // Return the updated date object with the modified timeSlots array
          return { ...item, timeSlots: updatedTimeSlots };
        }
        return item;
      });
    });
    // let dayOfWeeks = date.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase();
    // setAddOnDay(prevDays => {
    //   if (!prevDays.includes(dayOfWeeks)) {
    //     return [...prevDays, { day: dayOfWeeks, startTime: '', endTime: '' }]; // Add new weekday if it doesn't exist
    //     // return [...prevDays, dayOfWeeks]; // Add new weekday if it doesn't exist
    //   }
    //   return prevDays; // If already exists, return as is
    // });
  };

  const handleManage = () => {
    if (calendarDate === '' && sessionMultipleType === 'daily') {
      setWeekDayName('date')
      setSelectedDayLabelModal(true)
    } else {
      setSaveSlotWeek(true); setManageCalendar(false); setShowManage(true)
    }
  }


  const handleActiveStartDateChange = useCallback(({ activeStartDate }) => {
    setActiveStartDate(activeStartDate);
  }, []);
  const fetchData = useCallback(async (formattedDate) => {
    try {
      const response = await getApi(`/creator/edit/date?offerId=${data?.id}&monthYear=${formattedDate}`);
      const dates = response.data;
      // Get the current date and time
      const currentDate = new Date();
      // Filter the dates to include only those greater than the current date
      const filteredDates = dates.filter(dateStr => {
        const date = new Date(dateStr);
        return date > currentDate;
      });
      // Convert dates from the API to the desired format
      const formattedDates = filteredDates.map(dateStr => {
        const date = new Date(dateStr);
        // Set time to midnight
        date.setUTCHours(0, 0, 0, 0);
        return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
      });

      setEditDates(formattedDates);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [data?.id]);
  const fetchInitialData = useCallback(async () => {
    const formattedDate = formatDateForAPI(activeStartDate);
    if(data?.editype==='edit'){
    await fetchData(formattedDate);
    }
  }, [activeStartDate, fetchData]);
  useEffect(() => {
    fetchInitialData();
  }, [activeStartDate, fetchInitialData]);
  const formatDateForAPI = (date) => {
    const month = date.getMonth() + 1; // Months are zero-indexed
    const year = date.getFullYear();
    return `${month}-${year}`;
  };
  

 

  const handleAddDateRemove = () =>{
    // setAddDateCalendar((prevCalendar) =>
    //   prevCalendar.filter(
    //     (calendarItem) =>
    //       !addDatesCancel.some((cancelItem) =>
    //         new Date(calendarItem.date).toISOString() === 
    //         new Date(cancelItem.date).toISOString()
    //       )
    //   )
    // );
    
    setAddDateCalendar((prevCalendar) => {
      if (prevCalendar.length === 0) {
        const updatedCalendar = addDatesCancel.map((cancelItem) => ({
          date: cancelItem.date,
          isMarked: false,
        }));
        return updatedCalendar;
      }
      let updatedCalendar = [...prevCalendar]; // Make a copy of the previous calendar
      
      addDatesCancel.forEach((cancelItem) => {
        const cancelDate = cancelItem.date;
        const isDateInCalendar = prevCalendar.some((calendarItem) =>
          new Date(calendarItem.date).toISOString() === new Date(cancelDate).toISOString()
        );
    
        if (cancelItem.isMarked && isDateInCalendar) {
          // If the date is marked in addDatesCancel and not already in addDatesCalendar, add it
          updatedCalendar.push({
            date: cancelDate,
            isMarked: false, // Set isMarked to false when adding
          });
        } else if (!cancelItem.isMarked && isDateInCalendar) {
          // If the date is not marked and is present in the calendar, remove it
          updatedCalendar = updatedCalendar.filter(
            (calendarItem) => new Date(calendarItem.date).toISOString() !== new Date(cancelDate).toISOString()
          );
        }
      });
    
      return updatedCalendar;
    });
    if (exdates.length > 0) {
      const updatedExDate = exdates.filter(date => {
        const formattedExDate = new Date(date).toISOString();
        return !addExDatesCancel.some(cancelItem =>
          new Date(cancelItem.date).toISOString() === formattedExDate
        );
      });
      setExdates(updatedExDate);
    }
    if (rdates.length > 0) {
      const updatedRDate = rdates.filter(date => {
        const formattedExDate = new Date(date).toISOString();
        return !addRDatesCancel.some(cancelItem =>
          new Date(cancelItem.date).toISOString() === formattedExDate
        );
      });
      setRdates(updatedRDate);
    }
  }

  const updateReduxData = (updatedValues) => {
    dispatch(insertOfferingData(updatedValues));
  };

  useEffect(() => {
    if (data?.editype === 'edit') {
      const updatedData = {
        sessionType: sessionType,
      specifyTime: specifyTime,
      sessionRepeat: sessionRepeat,
      recurrences: recurrences,
      availabilityPeriod: availabilityPeriod,
      typeValue: typeValue,
      classMode: data?.classMode || 'online',
      sameTime: sameTime,
      sessionPackage: sessionNumber,
      timeFrame: timeFrame,
      sessionMultipleType: sessionMultipleType,
      selectedDayLabel: selectedDayLabel,
      dayLabel: dayLabel,
      sessionDuration: sessionDuration,
      showManage: showManage,
      manageCalendar: manageCalendar,
      // selectedDayLabel: selectedDayLabel,
      calendarDate: calendarDate,
      sameTimeDuratioon: sameTimeDuratioon,
      addDateCalendar: addDateCalendar,
      exdates: exdates,
      rdates: rdates,
      addOnDay: addOnDay,
      showSlot: showSlot,
      };

      updateReduxData(updatedData);
    }
  }, [
    sessionType,
    specifyTime,
    sessionRepeat,
    recurrences,
    availabilityPeriod,
    typeValue,
    sameTime,
    sessionNumber,
    timeFrame,
    sessionMultipleType,
    selectedDayLabel,
    dayLabel,
    sessionDuration,
    showManage,
    manageCalendar,
    calendarDate,
    sameTimeDuratioon,
    addDateCalendar,
    exdates,
    rdates,
    addOnDay,
    showSlot
  ]);

  return (
    <div>
      <UserSidebar classState={classState} />
      <div className='right-main'>
        <div className="session step-2 session-cs ">
          {type !== 'edit' &&
            <>
              <div className="header">
                <h1><button class="burger-menu" onClick={handleSidebarClick}><img src={burger} alt='' /></button> Session or Service</h1>
                <div className="top-buttons">
                  {/* <button className="save-draft" onClick={() => handleNext('draft')} disabled={stateModal}>{stateModal && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Save to Draft</button> */}
                  <button className="save-draft" onClick={() => checkWeeksBetweenDates('draft')} disabled={stateModal}>{stateModal && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Save to Draft</button>
                  {/* <button className="next" onClick={() => { handleNext() }}>Next</button> */}
                  <button className="next" onClick={() => { checkWeeksBetweenDates(); }}>Next</button>
                </div>
              </div>
              <div class="active-links">

                <button className="back" onClick={() => handleNext('prev')}><img src={leftArrow} alt='' /> </button>
                {/* <button className="back" onClick={() => prevStep()}><img src={leftArrow} alt='' /> </button> */}

                <ActiveLinks step={step} />
              </div>
            </>
          }




          <div className="main session-spacing session-spacing-group">
            <div className="row">

              <div className="col-sm-7">
                <h1 className='define-offer'>Select session dates and times</h1>
                <div className='define-left for-label-cs'>
                  <div className='define-top error-fixs'>
                    <h3>How would you like to package your offering?</h3>
                    {/* {(sessionType === 'single_session' || data?.editype !== 'edit') && */}
                    <div className='session'>
                      <label class="session-check" onClick={() => { handleSessionType('single_session'); setSessionMultipleType(''); setMarkedDates([]); setAddDateCalendar([]); setAddOnDay([]); setSessionDuration(''); setRecurrences([]); setDayLabel([]); setSelectedDayLabel([]); setManageCalendar(false); setShowManage(false);; setAddDates([]); setWeekDay([]); setExdates([]); setRdates([]); setCalendarDate(''); setMarkedDates([]); setSameTimeDuration([{ startTime: '', endTime: '' }]); setSaveSlotWeek(false); setShowSlot(false); }}>
                        {/* <label class="session-check" onClick={() => setSessionType('single_session')}> */}
                        <input type="radio" checked={sessionType === 'single_session'} name="session-type" onChange={() => setSessionType('single_session')} />
                        <span class="checkmark"></span>
                      </label>
                      <div className='session-check-text' >
                        <h4>Single Session </h4>
                        <p>Allow users to book one session at a time (Eg.  one  time  workshop,  consultation).</p>
                      </div>
                    </div>
{/* }
{(sessionType === 'multiple_session' || data?.editype !== 'edit') && */}
                    <div className='session'>
                      <label class="session-check" onClick={() => { handleSessionType('multiple_session'); setSessionMultipleType(''); setMarkedDates([]); setAddDateCalendar([]); setAddOnDay([]); setSessionDuration(''); setRecurrences([]); setDayLabel([]); setSelectedDayLabel([]); setManageCalendar(false); setShowManage(false);; setAddDates([]); setWeekDay([]); setExdates([]); setRdates([]); setCalendarDate(''); setMarkedDates([]); setSameTimeDuration([{ startTime: '', endTime: '' }]); setSaveSlotWeek(false); setShowSlot(false); }}>
                        <input type="radio" checked={sessionType === 'multiple_session'} name="session-type" onChange={() => setSessionType('multiple_session')} />
                        <span class="checkmark"></span>
                      </label>
                      <div className='session-check-text' >
                        <h4>Multiple Sessions</h4>
                        <p>Offer a package of sessions (Eg. classes,  monthly  therapy,  weekly consultation,  fixed  number  of  sessions).</p>
                      </div>
                    </div>
{/* } */}
                    {errors.sessionType && <div className="error">{errors.sessionType}</div>}
                  </div>
                  {/* <div className='border'>

                 </div> */}
                  {sessionType === 'multiple_session' &&
                    <div className='define-top error-fixs pt-2 difine-top-cs'>
                      <h3 className='pt-3 mb-2'>What type package do you offer</h3>

                      <div className='session'>
                        <label class="session-check" onClick={() => { setSessionMultipleType('daily'); setMarkedDates([]); setAddDateCalendar([]); setAddOnDay([]); setSessionDuration(''); setRecurrences([]); setDayLabel([]); setSelectedDayLabel([]); setManageCalendar(false); setShowManage(false);; setAddDates([]); setWeekDay([]); setExdates([]); setRdates([]); setCalendarDate(''); setMarkedDates([]); setSameTimeDuration([{ startTime: '', endTime: '' }]); setSaveSlotWeek(false); setShowSlot(false); setSessionNumber(''); setTimeFrame(prevState => ({ ...prevState, months: 'day' })); }}>
                          <input type="radio" checked={sessionMultipleType === 'daily'} name="mulsession-type" onChange={() => setSessionMultipleType('daily')} />
                          <span class="checkmark"></span>
                        </label>
                        <div className='session-check-text' >
                          <h4>Daily Package </h4>
                          {sessionMultipleType === 'daily' &&
                            <>
                              {/* <p>Session will happen every day.</p> */}
                              <>
                                <p>Session will happen every day.</p>
                                <div class="weekly-main p-0">
                                  <h3 class="pt-3 mb-0">Specify timeframe of the package. *</h3>
                                  <p className='py-1'>overall period during which the sessions are offered</p>
                                  <div className='d-flex'>
                                    <div className=''>
                                      <input type="number" placeholder='2' value={timeFrame?.number}
                                        // onChange={(e) => {e?.target?.value >0 ? setTimeFrame(prevState => ({ ...prevState, number: e.target.value })) : setTimeFrame(prevState => ({ ...prevState, number: '' }))}} 
                                        onChange={(e) => {
                                          const value = e.target.value;  // Parse input value as an integer
                                          if (value > 0 && value < 100) {
                                            setTimeFrame(prevState => ({ ...prevState, number: e.target.value }))
                                          } else if (value == 0) {
                                            setTimeFrame(prevState => ({ ...prevState, number: '' }))
                                          } else {
                                            setTimeFrame(prevState => ({ ...prevState, number: timeFrame?.number }))
                                          }
                                        }}
                                        className={`daily-input ${timeFrame?.number ? 'dailyinputenter' :''}`} />
                                    </div>
                                    {/* <input type="text" placeholder='Weeks' value={timeFrame?.months || 'weeks'} className='daily-input' /> */}
                                    <div className='selct-weklys select-dayas-months'>
                                      {/* <button  className='time-select'>
                    
                    <img src={selcttime} alt='' />
                  </button> */}
                                       <div className="dropdown oper-drop-down-cs daily-weeks-selct-ontime" onClick={()=>setDayDropdown(!dayDropdown)} ref={dropdownPackageRef}>
                      <input placeholder="Day" class={`daily-weeks-selct-ontime ${timeFrame?.months !=='' ? 'dailyinputenter':''}`} value={timeFrame?.months==='day' ? 'Days' : timeFrame?.months.charAt(0).toUpperCase() + timeFrame?.months.slice(1).toLowerCase()}></input>
                      {dayDropdown && (

                        <div id="myDropdown" className="selct-weklys-dropdown p-0">
                         <div className='active' onClick={()=>{setTimeFrame(prevState => ({ ...prevState, months: 'day' }))}}>Day(s) {timeFrame?.months === 'day' && <img className='float-end' src={greenTick} alt='' />}</div>
                        <div onClick={()=>{setTimeFrame(prevState => ({ ...prevState, months: 'weeks' }))}}>Week(s) {timeFrame?.months === 'weeks' && <img className='float-end' src={greenTick} alt='' />} </div>
                        <div className='mb-0' onClick={()=>{setTimeFrame(prevState => ({ ...prevState, months: 'months' }))}}>Month(s) {timeFrame?.months === 'months' && <img className='float-end' src={greenTick} alt='' />}</div>
                        </div>
                      )}
                     
                    </div>
                                      {/* <div class="selct-weklys-dropdown p-0" value={timeFrame?.months} onClick={()=>setDayDropdown(true)}>
                                        {dayDropdown &&<>
                                        <div className='active' onClick={()=>{setTimeFrame(prevState => ({ ...prevState, months: 'day' }));setDayDropdown(false)}}>Day  <img className='float-end' src={greenTick} alt='' /></div>
                                        <div onClick={()=>{setTimeFrame(prevState => ({ ...prevState, months: 'weeks' }));setDayDropdown(false)}}>Weeks </div>
                                        <div className='mb-0' onClick={()=>{setTimeFrame(prevState => ({ ...prevState, months: 'months' }));setDayDropdown(false)}}>Months </div>
                                        </>}
                                        </div> */}
                                      {/* <select
                                        value={timeFrame?.months}
                                        onChange={(e) => {
                                          const selectedValue = e.target.value;
                                          setTimeFrame(prevState => ({ ...prevState, months: selectedValue }))
                                          // handleTimeFrameChange(selectedValue);
                                        }}
                                        className='daily-input daily-week daily-weeks-selct'
                                      >

                                        <option value="day">Day</option>
                                        <option value="weeks">Weeks</option>
                                        <option value="months">Months</option>

                                      </select> */}
                                    </div>
                                  </div>
                                  {/* <input type="text" placeholder='Weeks' value={timeFrame?.months} onChange={(e) => setTimeFrame(prevState => ({ ...prevState, months: e.target.value }))} /> */}
                                  {errors.timeFrame && <div className="error">{errors.timeFrame}</div>}
                                </div>
                              </>
                            </>
                          }

                        </div>
                      </div>

                      <div className='session'>
                        <label class="session-check" onClick={() => { setSessionMultipleType('weekly'); setMarkedDates([]); setAddDateCalendar([]); setAddOnDay([]); setSessionDuration(''); setRecurrences([]); setDayLabel([]); setSelectedDayLabel([]); setManageCalendar(false); setShowManage(false);; setAddDates([]); setWeekDay([]); setExdates([]); setRdates([]); setCalendarDate('');; setMarkedDates([]); setSameTimeDuration([{ startTime: '', endTime: '' }]); setTimeFrame({ number: '', months: 'weeks' }); setSaveSlotWeek(false); setShowSlot(false); setSessionNumber(''); }}>
                          <input type="radio" checked={sessionMultipleType === 'weekly'} name="mulsession-type" onChange={() => setSessionMultipleType('weekly')} />
                          <span class="checkmark"></span>
                        </label>
                        <div className='session-check-text' >
                          <h4>Weekly Package </h4>
                          {sessionMultipleType === 'weekly' &&
                            <>
                              <p>Set a number of sessions per week.</p>
                              <div class="weekly-main p-0 pt-1">
                                <h3 class="my-2">Specify the number of sessions per week. *</h3>
                                <input type="number" placeholder='2 Times' value={sessionNumber}
                                  onChange={(e) => {
                                    const value = e.target.value;  // Parse input value as an integer
                                    if (value > 0 && value < 8) {
                                      setSessionNumber(value);
                                    } else if (value == 0) {
                                      setSessionNumber('');
                                    } else {
                                      setSessionNumber(sessionNumber);
                                    }
                                  }}
                                  className={`daily-input ${sessionNumber && 'dailyinputenter'}`} />
                                {errors.sessionNumber && <div className="error">{errors.sessionNumber}</div>}
                              </div>
                              <div class="weekly-main p-0">
                                <h3 class="pt-3 mb-0">Specify timeframe of the package. *</h3>
                                <p className='py-1'>overall period during which the sessions are offered</p>
                                <div className='d-flex'>
                                  <div className=''>
                                    <input type="number" placeholder='2' value={timeFrame?.number}
                                      // onChange={(e) => {e?.target?.value >0 ? setTimeFrame(prevState => ({ ...prevState, number: e.target.value })) : setTimeFrame(prevState => ({ ...prevState, number: '' }))}} 
                                      onChange={(e) => {
                                        const value = e.target.value;  // Parse input value as an integer
                                        if (value > 0 && value < 100) {
                                          setTimeFrame(prevState => ({ ...prevState, number: e.target.value }))
                                        } else if (value == 0) {
                                          setTimeFrame(prevState => ({ ...prevState, number: '' }))
                                        } else {
                                          setTimeFrame(prevState => ({ ...prevState, number: timeFrame?.number }))
                                        }
                                      }}
                                      className={`daily-input ${timeFrame?.number && 'dailyinputenter'}`} />
                                  </div>
                                  {/* <input type="text" placeholder='Weeks' value={timeFrame?.months || 'weeks'} className='daily-input' /> */}
                                  <div className='selct-weklys'>
                                    {/* <button  className='time-select'>
                    
                    <img src={selcttime} alt='' />
                  </button> */}
                                    {/* <select
                                      value={timeFrame?.months}
                                      onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        setTimeFrame(prevState => ({ ...prevState, months: selectedValue }))
                                        // handleTimeFrameChange(selectedValue);
                                      }}
                                      className='daily-input daily-week daily-weeks-selct'
                                    >
                                      <option value="weeks">Weeks</option>
                                      <option value="months">Months</option>
                                    </select> */}
                                    <div className="daily-input dropdown oper-drop-down-cs daily-weeks-selct-ontime" onClick={()=>setDayDropdown(!dayDropdown)} ref={dropdownPackageRef}>
                      <input placeholder="Day" class={`daily-weeks-selct-ontime ${timeFrame?.months !=='' ? 'dailyinputenter':''}`} value={timeFrame?.months==='day' ? 'Days' : timeFrame?.months.charAt(0).toUpperCase() + timeFrame?.months.slice(1).toLowerCase()}></input>
                      {dayDropdown && (

                        <div id="myDropdown" className="selct-weklys-dropdown p-0">
                        <div onClick={()=>{setTimeFrame(prevState => ({ ...prevState, months: 'weeks' }))}}>Week(s) {timeFrame?.months === 'weeks' && <img className='float-end' src={greenTick} alt='' />} </div>
                        <div className='mb-0' onClick={()=>{setTimeFrame(prevState => ({ ...prevState, months: 'months' }))}}>Month(s) {timeFrame?.months === 'months' && <img className='float-end' src={greenTick} alt='' />}</div>
                        </div>
                      )}
                     
                    </div>
                                  </div>
                                </div>
                                {/* <input type="text" placeholder='Weeks' value={timeFrame?.months} onChange={(e) => setTimeFrame(prevState => ({ ...prevState, months: e.target.value }))} /> */}
                                {errors.timeFrame && <div className="error">{errors.timeFrame}</div>}
                              </div>
                            </>
                          }
                        </div>
                      </div>

                      <div className='session'>
                        <label class="session-check" onClick={() => { setSessionMultipleType('monthly'); setMarkedDates([]); setAddDateCalendar([]); setAddOnDay([]); setSessionDuration(''); setRecurrences([]); setDayLabel([]); setSelectedDayLabel([]); setManageCalendar(false); setShowManage(false);; setAddDates([]); setWeekDay([]); setExdates([]); setRdates([]); setCalendarDate(''); setMarkedDates([]); setSameTimeDuration([{ startTime: '', endTime: '' }]); setTimeFrame({ number: '', months: 'months' }); setSaveSlotWeek(false); setShowSlot(false); setSessionNumber(''); }}>
                          <input type="radio" checked={sessionMultipleType === 'monthly'} name="mulsession-type" onChange={() => setSessionMultipleType('monthly')} />
                          <span class="checkmark"></span>
                        </label>
                        <div className='session-check-text' >
                          <h4>Monthly Package</h4>
                          {sessionMultipleType === 'monthly' &&
                            <>
                              <p>Provide session over a month.</p>
                              <div class="weekly-main p-0">
                                <h3 class="my-2">Specify the number of sessions per month. *</h3>
                                <input type="text" placeholder='2 Times' value={sessionNumber}
                                  onChange={(e) => {
                                    const value = e.target.value;  // Parse input value as an integer
                                    if (value > 0 && value < 32) {
                                      setSessionNumber(value);
                                    } else if (value == 0) {
                                      setSessionNumber('');
                                    } else {
                                      setSessionNumber(sessionNumber);
                                    }
                                  }}
                                  className={`daily-input ${sessionNumber && 'dailyinputenter'}`} />
                                {errors.sessionNumber && <div className="error">{errors.sessionNumber}</div>}
                              </div>
                              <div class="weekly-main p-0">
                                <h3 class="pt-3 mb-0">Specify timeframe of the package. *</h3>
                                <p className='py-1'>overall period during which the sessions are offered</p>
                                <input type="text" placeholder='2' value={timeFrame?.number}
                                  // onChange={(e) => {e?.target?.value >0 ? setTimeFrame(prevState => ({ ...prevState, number: e.target.value })) : setTimeFrame(prevState => ({ ...prevState, number: '' }))}}
                                  onChange={(e) => {
                                    const value = e.target.value;  // Parse input value as an integer
                                    if (value > 0 && value < 100) {
                                      setTimeFrame(prevState => ({ ...prevState, number: e.target.value }))
                                    } else if (value == 0) {
                                      setTimeFrame(prevState => ({ ...prevState, number: '' }))
                                    } else {
                                      setTimeFrame(prevState => ({ ...prevState, number: timeFrame?.number }))
                                    }
                                  }}
                                  className={`daily-input ${timeFrame?.number && 'dailyinputenter'}`} />
                                <input type="text" placeholder='Months' value={timeFrame?.months || 'months'} className='daily-input daily-input-month' />
                                {/* <input type="text" placeholder='Months' value={timeFrame?.months} onChange={(e) => setTimeFrame(prevState => ({ ...prevState, months: e.target.value }))} /> */}
                                {errors.timeFrame && <div className="error">{errors.timeFrame}</div>}

                              </div>
                            </>
                          }
                        </div>
                      </div>
                      {data?.sessionTypes !== 'group_session' &&
                        <div className='session'>
                          <label class="session-check" onClick={() => { setSessionMultipleType('custom'); setMarkedDates([]); setAddDateCalendar([]); setAddOnDay([]); setSessionDuration(''); setRecurrences([]); setDayLabel([]); setSelectedDayLabel([]); setManageCalendar(false); setShowManage(false);; setAddDates([]); setWeekDay([]); setExdates([]); setRdates([]); setCalendarDate(''); setMarkedDates([]); setSameTimeDuration([{ startTime: '', endTime: '' }]); setSaveSlotWeek(false); setShowSlot(false); setSessionNumber(''); }}>
                            <input type="radio" checked={sessionMultipleType === 'custom'} name="mulsession-type" onChange={() => setSessionMultipleType('custom')} />
                            <span class="checkmark"></span>
                          </label>
                          <div className='session-check-text' >
                            <h4>Custom Package</h4>
                            {sessionMultipleType === 'custom' &&
                              <>
                                <p class="pb-2 pt-0">Create a package with a specific number of sessions..</p>
                                <div>
                                  <h3 className='my-2 specify-sessn'>Specify number of session in the package. *</h3>
                                  {/* <input type="text" placeholder='2 Times' value={sessionNumber} onChange={(e) => {(e?.target?.value >0 && e?.target?.value<100 )? setSessionNumber(e?.target?.value) : setSessionNumber(sessionNumber)}} className='daily-input' /> */}
                                  <input type="number" placeholder='2 Times' value={sessionNumber}
                                    onChange={(e) => {
                                      const value = e.target.value;  // Parse input value as an integer
                                      if (value > 0 && value < 100) {
                                        setSessionNumber(value);
                                      } else if (value == 0) {
                                        setSessionNumber('');
                                      } else {
                                        setSessionNumber(sessionNumber);
                                      }
                                    }}
                                    className={`daily-input ${sessionNumber && 'dailyinputenter'}`} />
                                  {errors.sessionNumber && <div className="error">{errors.sessionNumber}</div>}
                                </div>
                              </>
                            }
                          </div>
                        </div>}
                      {errors.sessionMultipleType && <div className="error">{errors.sessionMultipleType}</div>}
                    </div>
                  }

                  {/* {(specifyTime === 'exact' || data?.sessionTypes === 'group_session') && ( */}
                  {(((sessionMultipleType === 'weekly' || sessionMultipleType === 'monthly' || sessionMultipleType === 'custom') && sessionType === 'multiple_session') || (sessionType === 'single_session' && data?.sessionTypes !== 'group_session')) &&
                    <div className='define-top time fggdfhd'>
                      {sessionType !== '' &&
                        // {sessionType !== '' && data?.sessionTypes !== 'group_session' &&
                        <>
                          {(((sessionMultipleType === 'weekly' || sessionMultipleType === 'monthly' || sessionMultipleType === 'custom') && sessionType === 'multiple_session') || (sessionType === 'single_session' && data?.sessionTypes !== 'group_session')) &&
                            //  {(sessionMultipleType !== 'daily' && sessionType === 'multiple_session') || (sessionType !== '' && data?.sessionTypes !== 'group_session') &&
                            <>
                              {/* <h3>Time when will it happen?</h3> */}
                              {/* <div className='session'>
                          <label class="session-check" onClick={() => setSpecifyTime('exact')}>
                            <input type="radio" checked={specifyTime === 'exact'} name="specify_time"></input>
                            <span class="checkmark"></span>
                          </label>
                          <div className='session-check-text'>
                            <h4>Specify exact time</h4>
                            <p>Set a fixed time for each session (e.g., Mon at 11:00 AM, Fri at 9:00 AM). This option is suitable for both individual and group sessions.</p>
                          </div>
                        </div> */}

                              <div className='session'>
                                {/* <label class="session-check" onClick={() => { setSpecifyTime('availability'); setSessionRepeat('') }}>
                            <input type="radio" checked={specifyTime === 'availability'} name="specify_time"></input>
                            <span class="checkmark"></span>
                          </label> */}
                                <div className='session-check-text ps-0' >
                                  <h4 className='share-available'>Share your availability </h4>
                                  <p>You share your availability so users can select a convenient slot.</p>
                                </div>
                              </div>
                              {errors.specifyTime && <div className="error">{errors.specifyTime}</div>}
                            </>
                        /* } */}
                        </>

                      }
                      {(specifyTime === 'exact' || data?.sessionTypes === 'group_session') && (
                        <div className='session-week'>
                          <h3>How often should this session repeat?</h3>
                          <ul>
                            <li >
                              <label class="session-check" onClick={() => handleTImeSession("one")}>
                                <input type="radio" checked={sessionRepeat === 'one'} name="sessiontime"></input>
                                <span class="checkmark"></span>
                              </label>
                              <h4>One-time session</h4>
                            </li>
                            <li >
                              <label class="session-check" onClick={() => handleTImeSession("daily")}>
                                <input type="radio" checked={sessionRepeat === 'daily'} name="sessiontime"></input>
                                <span class="checkmark"></span>
                              </label>
                              <h4>Daily</h4>
                            </li>
                            <li >
                              <label class="session-check" onClick={() => handleTImeSession("weekly")}>
                                <input type="radio" checked={sessionRepeat === 'weekly'} name="sessiontime"></input>
                                <span class="checkmark"></span>
                              </label>
                              <h4>Weekly</h4>
                            </li>
                            <li >
                              <label class="session-check" onClick={() => handleTImeSession("monthly")}>
                                <input type="radio" checked={sessionRepeat === 'monthly'} name="sessiontime"></input>
                                <span class="checkmark"></span>
                              </label>
                              <h4>Monthly</h4>
                            </li>
                            <li >
                              <label class="session-check" onClick={() => handleTImeSession("weekday")}>
                                <input type="radio" checked={sessionRepeat === 'weekday'} name="sessiontime"></input>
                                <span class="checkmark"></span>
                              </label>
                              <h4>Every weekday(from Monday to Friday)</h4>
                            </li>
                            <li >
                              <label class="session-check" onClick={() => handleTImeSession("weekend")} >
                                <input type="radio" checked={sessionRepeat === 'weekend'} name="sessiontime"></input>
                                <span class="checkmark"></span>
                              </label>
                              <h4>Every weekends(Saturday and Sunday)</h4>
                            </li>

                          </ul>
                          {errors.sessionRepeat && <div className="error">{errors.sessionRepeat}</div>}
                        </div>
                      )
                      }


                    </div>
                  }
                  {/* )} */}

                  {/* <div className='select-day'>
                    {sessionType === 'multiple_session' && sessionMultipleType === 'weekly' && (
                      <>
                        <h3>Select Days</h3>

                        <ul>
                          {daysOfWeek.map(day => (
                            <li key={day?.value} onClick={() => handleDayChange(day.value, day?.label, day?.name)} className={dayLabel.includes(day?.label) && 'active'} >
                              {day.name}
                            </li>
                          ))}
                        </ul>
                      </>
                    )
                    }
                  </div> */}
                  <>
                    {((sessionMultipleType !== 'daily' && sessionType === 'multiple_session' && sessionMultipleType !== '') || (sessionType === 'single_session')) &&
                      // {(sessionMultipleType !== 'daily' && sessionType === 'multiple_session') || (sessionType === 'single_session' && data?.sessionTypes !== 'group_session') &&
                      <div className='select-day'>
                        {((specifyTime === 'exact' && sessionRepeat !== '') || specifyTime === 'availabliity' || data?.sessionTypes === 'group_session') && (
                          <>
                            {sessionRepeat === 'monthly'
                              ?
                              <>
                                <h3>Select Day to repeat</h3>
                                <input type='text' onChange={(e) => setMonthRepeatValue(e?.target?.value)} />
                              </>
                              :
                              <h3>{(specifyTime === 'exact' || data?.sessionTypes === 'group_session') ? 'Select Recurring Days' : 'Select Days'}</h3>
                            }
                            <ul>
                              {daysOfWeek.map(day => (
                                <li key={day?.value} onClick={() => handleDayChange(day.value, day?.label, day?.name)} className={dayLabel.includes(day?.label) && 'active'} >
                                  {day.name}
                                </li>
                              ))}
                            </ul>
                          </>
                        )
                        }
                      </div>
                    }

                    {sessionType === 'multiple_session' && sessionMultipleType !== 'daily' && sessionMultipleType !== '' && specifyTime === 'availability' &&

                      <div className='select-day'>
                        <h3>Select Days</h3>
                        <ul>
                          {daysOfWeek.map(day => (
                            <li key={day?.value} onClick={() => handleDayChange(day.value, day?.label, day?.name)} className={dayLabel.includes(day?.label) && 'active'} >
                              {day.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    }

                    {/* {(specifyTime !== '' || (data?.sessionTypes === 'group_session' && (sessionRepeat !== 'one' && sessionRepeat !== '')) || (sessionMultipleType === 'daily' && sessionType === 'multiple_session') ) && */}
                    {((specifyTime === 'availabliity' && sessionType === 'single_session') || (data?.sessionTypes === 'group_session' && (sessionRepeat !== 'one' && sessionRepeat !== '')) || (sessionType === 'multiple_session' && sessionMultipleType !== '')) &&
                      <div className='define-top time period add-time-r border-none-cs pt-0'>
                        <h3>{(specifyTime === 'exact' || data?.sessionTypes === 'group_session') ? 'Recurred Period Duration' : 'Availability Period'}</h3>
                        <p>Please specify when your availability period ends.</p>

                        <div className='session margin' onClick={() => setAvailabilityPeriod('indefinitely')}>
                          <label class="session-check">
                            <input type="radio" checked={availabilityPeriod === 'indefinitely'} name="availability_periods"></input>
                            <span class="checkmark"></span>
                          </label>
                          <div className='session-check-text pt-2'>
                            <h4>For 1 year</h4>
                          </div>
                        </div>
                        <div className='session margin mar-bott' onClick={() => setAvailabilityPeriod('date')}>
                          <label class="session-check">
                            <input type="radio" checked={availabilityPeriod === 'date'} name="availability_periods"></input>
                            <span class="checkmark"></span>
                          </label>
                          <div className='session-check-text until pt-2'>
                            <h4>Until a specific date</h4>
                            <input type='date' className='form-control' value={typeValue?.date} onChange={(e) => handleChangeData('date', e)} min={new Date().toISOString().split("T")[0]} max={new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()).toISOString().split("T")[0]}/>
                            {/* <input type='date' className='form-control' value={typeValue?.date} onChange={(e) => handleChangeData('date', e)} min={new Date().toISOString().split("T")[0]} disabled={!manageCalendar && showManage} /> */}
                          </div>
                        </div>
                        {(specifyTime === 'exact' || (data?.sessionTypes === 'group_session' && (sessionRepeat !== 'one' && sessionRepeat !== ''))) &&
                          <div className='session margin mar-bott' onClick={() => setAvailabilityPeriod('after')}>
                            <label class="session-check">
                              <input type="radio" checked={availabilityPeriod === 'after'} name="availability_periods"></input>
                              <span class="checkmark"></span>
                            </label>
                            <div className='session-check-text until'>
                              <h4>After</h4>
                              <input type='text' className='form-control' placeholder='12 times' value={typeValue?.after} onChange={(e) => handleChangeData('after', e)} />
                            </div>
                          </div>
                        }
                        {errors.availabilityPeriod && <div className="error">{errors.availabilityPeriod}</div>}
                      </div>
                    }
                  </>
                </div>

                {/* {selectedDayLabel && selectedDayLabel.length > 0 &&
                  <div className='define-left'>
                    <div className='define-top'>
                      <h3>Available time slot</h3>
                      <div className="switch-content">
                        <div className="switch-button">
                          <label className="switch">
                            <input type="checkbox" checked={sameTime} onClick={() => setSameTime(!sameTime)} />
                            <span className="slider"></span>
                          </label>
                        </div>
                        <h3>Same for all days</h3>
                      </div>
                    </div>



                    <div className='time-slot'>
                      {sameTime ?
                        <div className='row'>
                          <div className='col-sm-5'>
                            <div className='form-group'>
                              <label>From</label>
                              <input type='time' className='form-control' onChange={(e) => setSameTimeDuration(prevState => ({ ...prevState, startTime: e.target.value }))} required />
                            </div>
                          </div>
                          <div className='col-sm-5'>
                            <div className='form-group'>
                              <label>To</label>
                              <input type='time' className='form-control' onChange={(e) => setSameTimeDuration(prevState => ({ ...prevState, endTime: e.target.value }))} required />
                            </div>
                          </div>
                          <div className='col-sm-2'>
                            <div className='form-group'>
                              <button className='add-btn'><img src={add} alt='' /></button>
                            </div>

                          </div>
                        </div>
                        :
                        <>
                          {selectedDayLabel && selectedDayLabel.length > 0 && selectedDayLabel.map((x, index) => {
                            return (
                              <div key={index}>
                                <h4>{x?.label}</h4>
                                <div className='row'>
                                  <div className='col-sm-5'>
                                    <div className='form-group'>
                                      <label>From</label>
                                      <input type='time' className='form-control' onChange={(e) => handleInputChange(index, 'startTime', e.target.value, 1)} required />
                                    </div>
                                  </div>
                                  <div className='col-sm-5'>
                                    <div className='form-group'>
                                      <label>To</label>
                                      <input type='time' className='form-control' onChange={(e) => handleInputChange(index, 'endTime', e.target.value, 1)} required />
                                    </div>
                                  </div>
                                  <div className='col-sm-2'>
                                    <div className='form-group'>
                                      <button className='add-btn' onClick={() => handleMorediv(x?.label)}>
                                        <img src={add} alt='' />
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                {moreDiv && moreDiv.length > 0 && moreDiv.map((y, ind) => {
                                  if (x?.label === y?.label) {
                                    if (ind < y?.count) {
                                      return (
                                        <div className='row' key={ind}>
                                          <div className='col-sm-5'>
                                            <div className='form-group'>
                                              <label>From</label>
                                              <input type='time' className='form-control' onChange={(e) => handleInputChange(index, 'startTime', e.target.value, 2)} required />
                                            </div>
                                          </div>
                                          <div className='col-sm-5'>
                                            <div className='form-group'>
                                              <label>To</label>
                                              <input type='time' className='form-control' onChange={(e) => handleInputChange(index, 'endTime', e.target.value, 2)} required />
                                            </div>
                                          </div>
                                          <div className='col-sm-2'>
                                            <div className='form-group'>
                                              <button className='add-btn' onClick={() => handleMorediv(x?.label)}>
                                                <img src={add} alt='' />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  }
                                  return null; // Return null if the labels don't match or condition is not met
                                })}

                                {/* {moreDiv && moreDiv.length > 0 && selectedDayLabel && selectedDayLabel.length > 0 && selectedDayLabel.map((x, index) => {
                                // Find the matching item in moreDiv
                                const matchingItem = moreDiv.find(y => y.label === x?.label);

                                return (
                                  <div key={index}>
                                    <h4>{x?.label}</h4>

                                    {matchingItem && [...Array(matchingItem.count)].map((_, ind) => (
                                      <div className='row' key={ind}>
                                        <div className='col-sm-5'>
                                          <div className='form-group'>
                                            <label>From</label>
                                            <input type='date' className='form-control' />
                                          </div>
                                        </div>
                                        <div className='col-sm-5'>
                                          <div className='form-group'>
                                            <label>To</label>
                                            <input type='date' className='form-control' />
                                          </div>
                                        </div>
                                        <div className='col-sm-2'>
                                          <div className='form-group'>
                                            <button className='add-btn' onClick={() => handleMorediv(x?.label)}>
                                              <img src={add} alt='' />
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                );
                              })} ///////////
                              </div>
                            );
                          })}
                        </>
                      }
                      <div className='row'>
                        <div className='col-sm-12'>
                          <div className='form-group'>
                            <label>Enter Session Duration</label>
                            <input type='text' className='form-control' placeholder='60min' onChange={(e) => setSessionDuration(e?.target?.value)} required />
                          </div>
                        </div>
                      </div>
                    </div>

                    <button onClick={generateRecurrenceStrings}>Generate</button>


                  </div>
                } */}
                <div className='define-left schedule'>
                  <h3>Date Schedule</h3>

                  {/* {!saveState && <button>Saves</button>}
                  {saveState} &&  */}
                  {/* {recurrences && recurrences.length>0 ?
                  <button onClick={generateRecurrenceStrings}>Save</button>
                  : */}
                  {(showManage === false || selectedDayLabel.length === 0) && <button onClick={() => handleManage()} className='save-button disable-btn' disabled={selectedDayLabel.length === 0}>Save</button>}
                  {/* {showManage === false && <button onClick={()=>{handleTimeSlot(); setShowManage(true)}}>Save</button>} */}
                  {/* {showManage === false && <button onClick={()=>generateRecurrenceStrings}>Save</button>} */}
                  {/* } */}
                  {!manageCalendar && showManage && selectedDayLabel.length !== 0  && <button className='manage float-end' onClick={() => { setAddDatesCancel([]);setRAddDatesCancel([]);setExAddDatesCancel([]);sessionType !== 'multiple_session' ? handleDateClicks() : handleDateClickAddon(); setManageCalendar(true) }}><img src={manageIcon} alt='' />Manage </button>}
                  {manageCalendar &&
                    // {manageCalendar && sessionType !== 'multiple_session' && 
                    <>
                      <div className='manage-btns'>
                        <button className='manage' onClick={() => { handleAddDateRemove();setManageCalendar(false); setShowManage(true) }} >Cancel </button>
                        {/* <button onClick={()=>handleDateClickAddon()}>Save</button> */}
                        <button onClick={() => { setShowSlot(true); setManageCalendar(false);setAddDatesCancel([]);setExAddDatesCancel([]);setRAddDatesCancel([]); }} className='save-button disable-btn'>Save</button>
                        {/* <button onClick={() => handleTimeSlot('manage')} className='save-button'>Save</button> */}
                      </div>
                    </>
                  }
                  {(showManage === false || selectedDayLabel.length === 0) && <h4>Please select {(sessionType === 'multiple_session') ? 'the start date' : 'dates'} for your session.</h4>}
                  {!manageCalendar && showManage && selectedDayLabel.length !== 0 && <h4>You can individually edit your availability schedule.</h4>}
                  {manageCalendar && <h4>Add or remove dates by clicking on + or -. View and change time slots by clicking on  the date slot.</h4>}
                  <div className="calender">
                    <Calendar
                      // onChange={onChange}
                      onChange={(date) => {
                        const formattedDate = date.toLocaleDateString('en-CA');
                        const isMarked = markedDates.includes(formattedDate);
                        //  handleSaveCalendar(date, isMarked);
                        { showManage === false ? handleSaveRecurrence(date, isMarked, 'first') : manageCalendar && handleSaveRecurrence(date, isMarked) }
                        // handleDateClick(date, isMarked)
                        // manageCalendar ? handleDateClick(date, isMarked) : handleSaveCalendar(date, isMarked);
                        onChange(date); // Update the selected date in your state
                      }}
                      onActiveStartDateChange={handleActiveStartDateChange}
                      value={value}
                      minDate={new Date()}
                      maxDate={availabilityPeriod !== 'indefinitely' && new Date(typeValue?.date)}
                      tileClassName={({ date, view }) => {
                        // const formattedDate = date.toISOString().split('T')[0];
                        const formattedDate = date.toLocaleDateString('en-CA');
                        // Get the current month and year of the selected date
                        const isToday = date.getDate() === new Date().getDate() &&
                          date.getMonth() === new Date().getMonth() &&
                          date.getFullYear() === new Date().getFullYear();
                        const currentMonth = activeStartDate.getMonth();
                        const currentYear = activeStartDate.getFullYear();

                        const dateMonth = date.getMonth();
                        const dateYear = date.getFullYear();
                        const isPreviousMonth = view === 'month' && (dateMonth < currentMonth && dateYear === currentYear || dateYear < currentYear);
                        const isNextMonth = view === 'month' && (dateMonth > currentMonth + 1 && dateYear === currentYear || dateYear > currentYear);
                        let className = '';
                        if (isPreviousMonth || isNextMonth) {
                          className += ' previous-date';
                          // return 'previous-date'; // Add class for previous month dates
                        }
                        if (markedDates.includes(formattedDate)) {
                          className += ' highlight';
                          // return 'highlight';
                        }
                        if (isToday) {
                          className += ' black-current';
                        }
                        if (date < new Date().setHours(0, 0, 0, 0)) {
                          className += ' previousDayDate';
                        }
                        if(editDates.includes(formattedDate)){
                          className += ' non-editable-date'
                        }
                        // return null;
                        return className.trim() || null;
                      }}
                      tileContent={({ date }) => {
                        const formattedDate = date.toLocaleDateString('en-CA');;
                        const isMarked = markedDates.includes(formattedDate);
                        return (
                          // <span
                          //   // onClick={() => handleSaveCalendar(date, isMarked)}
                          //   // onClick={() => handleDateClick(date, isMarked)}
                          //   style={{ cursor: 'pointer', userSelect: 'none' }}
                          // >
                          //   {manageCalendar && (isMarked ? '-' : '+')}
                          // </span>
                          <span
                            className={manageCalendar && (isMarked ? 'minus-sign' : 'plus-sign')}  // Add conditional class
                            style={{ cursor: 'pointer', userSelect: 'none' }}
                          >
                            {manageCalendar && (isMarked ? '-' : '+')}
                          </span>
                        );
                      }}
                    />
                  </div>
                  {/* {addDateCalendar && addDateCalendar?.length > 0 && addDateCalendar.map((x, index) => (
                    // return {
                    <div key={index} className='slot-add'>
                      <h3>{x?.date.toLocaleDateString('en-CA')}</h3>
                      <div className='row' key={index}>
                        <div className='col-sm-5'>
                          <div className='form-group'>
                            <label>From</label>
                            <input type='time' className='form-control' onChange={(e) => handleInputChange(index, 'startTime', e.target.value, 2, 'addon')} required />
                          </div>
                        </div>
                        <div className='col-sm-5'>
                          <div className='form-group'>
                            <label>To</label>
                            <input type='time' className='form-control' onChange={(e) => handleInputChange(index, 'endTime', e.target.value, 2, 'addon')} required />
                          </div>
                        </div>
                        <div className='col-sm-2'>
                        <div className='form-group'>
                          <button className='add-btn' onClick={() => handleMorediv(x?.label)}>
                            <img src={add} alt='' />
                          </button>
                        </div>
                      </div>
                      </div>
                    </div>
                    // }
                  ))} */}
                  {showSlot &&
                    <>

                      {/* {!sameTime && addDateCalendar && addDateCalendar?.length > 0 &&
                    <div className='define-left slot-add-diff'>
                     
                      {addOnDay && addOnDay?.length > 0 && addOnDay.map((x, index) => (
                        <>
                          <div key={index} className='slot-add'>
                            <h4>Available slot for {x?.day}</h4>
                            {x?.timeSlots && x?.timeSlots?.length > 0 && x?.timeSlots.map((y, ind) => (
                              <div className='time-slot'>
                                <div className='row' key={index}>
                                  <div className='col-sm-5'>
                                    <div className='form-group'>
                                      <label>From</label>
                                      <TimePicker values={y?.startTime} handleInputChange={handleInputChange} index={index} timeLimit={'startTime'} val={''} num={2} strin={'addon'} label={x?.day} position={ind} manageCalendar={true}  showManage={true} isOpen={openTimePickerIndex === `${index}-${ind}-astart`} setIsOpen={() => handleTimePickerToggle(`${index}-${ind}-astart`)} dropdownRef={dropdownRef}/>
                                    </div>
                                  </div>
                                  <div className='col-sm-5'>
                                    <div className='form-group'>
                                      <label>To</label>
                                      <TimePicker values={y?.endTime} handleInputChange={handleInputChange} index={index} timeLimit={'endTime'} val={''} num={2} strin={'addon'} label={x?.day} position={ind} manageCalendar={true}  showManage={true} isOpen={openTimePickerIndex === `${index}-${ind}-aend`} setIsOpen={() => handleTimePickerToggle(`${index}-${ind}-aend`)} dropdownRef={dropdownRef}/>
                                    </div>
                                  </div>
                                  <div className='col-sm-2 text-center'>
                                    <div className='form-group'>
                                      {ind === 0 ? <button className='add-btn' onClick={() => handleUpdateTimeSlots(x?.date, ind)} disabled={!manageCalendar && showManage}>
                                        <img src={add} alt='' />
                                      </button> :
                                        <button className='add-btn' onClick={() => handleRemoveAddDatesTimeSlot(x?.date, ind)} disabled={!manageCalendar && showManage}>
                                          <img src={deleteImg} alt='' />
                                        </button>}
                                    </div>
                                  </div>
                                </div>
                                <div className='border-btm mb-3'></div>
                              </div>

                            ))}
                          </div>
                        </>
                      ))}
                    </div>
                  } */}

                      {sessionType === 'multiple_session' && addDateCalendar && addDateCalendar?.length > 0 && selectedDayLabel.length === 0 &&
                        <div className='row'>
                          <div className='col-sm-12'>
                            <div className='form-group time'>
                              <label>Enter Session Duration *</label>
                              <input type='number' className='form-control' placeholder='60min' value={sessionDuration} onChange={(e) => setSessionDuration(e?.target?.value)} required disabled={data?.editype==='edit'} />
                            </div>
                          </div>
                        </div>}
                    </>}
                </div>

                {/* {sessionType !== 'multiple_session' && selectedDayLabel && selectedDayLabel.length > 0 && */}
                {/* {selectedDayLabel && selectedDayLabel.length > 0 && !manageCalendar && */}
                {!manageCalendar && markedDates?.length !== 0 && <>
                  {/* {showSlot &&
                  <> */}
                  {selectedDayLabel && selectedDayLabel.length > 0 &&
                    <div className='define-left'>
                      {/* {selectedDayLabel?.length !== 1 && */}
                      <div className='define-top'>
                        <h3>Available time slot</h3>
                        <div className="switch-content">
                          <div className="switch-button">
                            <label className="switch">
                              <input type="checkbox" checked={sameTime} onClick={() => setSameTime(!sameTime)} />
                              <span className={`slider ${sameTime && 'active-slider'}`}></span>
                            </label>
                          </div>
                          <h3>Same for all days</h3>
                        </div>
                      </div>
                      {/* } */}



                      <div className='time-slot'>
                        {sameTime ?
                          // <div className='row'>
                          //   <div className='col-sm-5'>
                          //     <div className='form-group'>
                          //       <label>From</label>
                          //       <input type='time' className='form-control' onChange={(e) => setSameTimeDuration(prevState => ({ ...prevState, startTime: e.target.value }))} required />
                          //     </div>
                          //   </div>
                          //   <div className='col-sm-5'>
                          //     <div className='form-group'>
                          //       <label>To</label>
                          //       <input type='time' className='form-control' onChange={(e) => setSameTimeDuration(prevState => ({ ...prevState, endTime: e.target.value }))} required />
                          //     </div>
                          //   </div>
                          //   <div className='col-sm-2'>
                          //     <div className='form-group'>
                          //       <button className='add-btn'><img src={add} alt='' /></button>
                          //     </div>

                          //   </div>
                          // </div>
                          <>
                            {sameTimeDuratioon.map((slot, index) => (
                              <>
                                <div className='row' key={index}>
                                  <div className='col-sm-5'>
                                    <div className='form-group'>
                                      <label>From *</label>
                                      {/* <input
                                      type='time'
                                      className='form-control'
                                      value={slot.startTime}
                                      onChange={(e) => handleSameTimeChange(index, 'startTime', e.target.value)}
                                      disabled={!manageCalendar && showManage}
                                      required
                                    /> */}

                                      <TimePicker values={slot?.startTime} handleInputChange={handleSameTimeChange} index={index} timeLimit={'startTime'} val={''} num={''} strin={''} label={''} position={''} manageCalendar={true} showManage={true} isOpen={openTimePickerIndex === `${index}-0-start`} setIsOpen={() => handleTimePickerToggle(`${index}-0-start`)} dropdownRef={dropdownRef} />

                                    </div>
                                  </div>
                                  <div className='col-sm-5'>
                                    <div className='form-group'>
                                      <label>To *</label>
                                      {/* <input
                                      type='time'
                                      className='form-control'
                                      value={slot.endTime}
                                      onChange={(e) => handleSameTimeChange(index, 'endTime', e.target.value)}
                                      disabled={!manageCalendar && showManage}
                                      required
                                    /> */}
                                      <TimePicker values={slot?.endTime} handleInputChange={handleSameTimeChange} index={index} timeLimit={'endTime'} val={''} num={''} strin={''} label={''} position={''} manageCalendar={true} showManage={true} isOpen={openTimePickerIndex === `${index}-0-end`} setIsOpen={() => handleTimePickerToggle(`${index}-0-end`)} dropdownRef={dropdownRef} />
                                    </div>
                                  </div>
                                  {/* {index === sameTimeDuratioon.length - 1 && ( */}
                                  <div className='col-sm-2 text-center'>
                                    <div className='form-group'>
                                      {index === 0 ? <button className='add-btn' onClick={handleAddTimeSlot} >
                                        {/* {index === 0 ? <button className='add-btn' onClick={handleAddTimeSlot} disabled={!manageCalendar && showManage}> */}
                                        <img src={add} alt='' />
                                      </button> :
                                        <button className='add-btn' onClick={() => handleRemoveTimeSlot(index)} >
                                          <img src={deleteImg} alt='' />
                                        </button>}
                                    </div>
                                  </div>
                                  {/* )} */}
                                </div>
                              </>
                            ))}
                            <div className='border-btm mb-3'></div>
                          </>
                          :
                          // <>
                          //   {selectedDayLabel && selectedDayLabel.length > 0 && selectedDayLabel.map((x, index) => {
                          //     return (
                          //       <div key={index}>
                          //         <h4>{x?.label}</h4>
                          //         <div className='row'>
                          //           <div className='col-sm-5'>
                          //             <div className='form-group'>
                          //               <label>From</label>
                          //               <input type='time' className='form-control' onChange={(e) => handleInputChange(index, 'startTime', e.target.value, 1)} required />
                          //             </div>
                          //           </div>
                          //           <div className='col-sm-5'>
                          //             <div className='form-group'>
                          //               <label>To</label>
                          //               <input type='time' className='form-control' onChange={(e) => handleInputChange(index, 'endTime', e.target.value, 1)} required />
                          //             </div>
                          //           </div>
                          //           <div className='col-sm-2'>
                          //             <div className='form-group'>
                          //               <button className='add-btn' onClick={() => handleMorediv(x?.label)}>
                          //                 <img src={add} alt='' />
                          //               </button>
                          //             </div>
                          //           </div>
                          //         </div>

                          //         {moreDiv && moreDiv.length > 0 && moreDiv.map((y, ind) => {
                          //           if (x?.label === y?.label) {
                          //             if (ind < y?.count) {
                          //               return (
                          //                 <div className='row' key={ind}>
                          //                   <div className='col-sm-5'>
                          //                     <div className='form-group'>
                          //                       <label>From</label>
                          //                       <input type='time' className='form-control' onChange={(e) => handleInputChange(index, 'startTime', e.target.value, 2)} required />
                          //                     </div>
                          //                   </div>
                          //                   <div className='col-sm-5'>
                          //                     <div className='form-group'>
                          //                       <label>To</label>
                          //                       <input type='time' className='form-control' onChange={(e) => handleInputChange(index, 'endTime', e.target.value, 2)} required />
                          //                     </div>
                          //                   </div>
                          //                   <div className='col-sm-2'>
                          //                     <div className='form-group'>
                          //                       <button className='add-btn' onClick={() => handleMorediv(x?.label)}>
                          //                         <img src={add} alt='' />
                          //                       </button>
                          //                     </div>
                          //                   </div>
                          //                 </div>
                          //               );
                          //             }
                          //           }
                          //           return null; // Return null if the labels don't match or condition is not met
                          //         })}

                          //         {/* {moreDiv && moreDiv.length > 0 && selectedDayLabel && selectedDayLabel.length > 0 && selectedDayLabel.map((x, index) => {
                          //         // Find the matching item in moreDiv
                          //         const matchingItem = moreDiv.find(y => y.label === x?.label);

                          //         return (
                          //           <div key={index}>
                          //             <h4>{x?.label}</h4>

                          //             {matchingItem && [...Array(matchingItem.count)].map((_, ind) => (
                          //               <div className='row' key={ind}>
                          //                 <div className='col-sm-5'>
                          //                   <div className='form-group'>
                          //                     <label>From</label>
                          //                     <input type='date' className='form-control' />
                          //                   </div>
                          //                 </div>
                          //                 <div className='col-sm-5'>
                          //                   <div className='form-group'>
                          //                     <label>To</label>
                          //                     <input type='date' className='form-control' />
                          //                   </div>
                          //                 </div>
                          //                 <div className='col-sm-2'>
                          //                   <div className='form-group'>
                          //                     <button className='add-btn' onClick={() => handleMorediv(x?.label)}>
                          //                       <img src={add} alt='' />
                          //                     </button>
                          //                   </div>
                          //                 </div>
                          //               </div>
                          //             ))}
                          //           </div>
                          //         );
                          //       })} */}
                          //       </div>
                          //     );
                          //   })}
                          // </>
                          <>
                            {selectedDayLabel && selectedDayLabel.length > 0 && (markedDates?.length !== addDateCalendar?.length) && selectedDayLabel.map((x, index) => {
                              return (
                                <>
                                  <div key={index}>
                                    <h4>On {x?.label}</h4>

                                    {x?.dates && x?.dates.length > 0 && x?.dates.map((y, ind) => {
                                      // const handleTimeChange = (field, hour, minute, label, dateIndex) => {
                                      //   const fullTime = `${hour}:${minute}`;
                                      //   handleInputChange(index, field, fullTime, 1, '', label, dateIndex);
                                      // };

                                      // const [startHour, startMinute] = y?.startTime?.split(':') || ['00', '00'];
                                      // const [endHour, endMinute] = y?.endTime?.split(':') || ['00', '00'];

                                      return (
                                        <>
                                          <div className='row'>
                                            <div className='col-sm-5'>
                                              <div className='form-group'>
                                                <label>From *</label>
                                                {/* <input type='time' className='form-control' value={y?.startTime} onChange={(e) => handleInputChange(index, 'startTime', e.target.value, 1, '', x?.label, ind)} disabled={!manageCalendar && showManage} required step="1800" /> */}
                                                {/* <TimePicker value={y?.startTime} onChange={(e) => handleInputChange(index, 'startTime', e.target.value, 1, '', x?.label, ind)}/> */}
                                                <TimePicker values={y?.startTime} handleInputChange={handleInputChange} index={index} timeLimit={'startTime'} val={''} num={1} strin={''} label={x?.label} position={ind} manageCalendar={true} showManage={true} isOpen={openTimePickerIndex === `${index}-${ind}-start`} setIsOpen={() => handleTimePickerToggle(`${index}-${ind}-start`)} dropdownRef={dropdownRef} />
                                                {/* <div className='d-flex'>
                    <div className="time-picker-wrapper">
                      <input
                        type='number'
                        className='form-control'
                        value={startHour}
                        onChange={(e) => handleTimeChange('startTime', e.target.value.padStart(2, '0'), startMinute, x?.label, ind)}
                        min="00"
                        max="23"
                        step="1"
                        disabled={!manageCalendar && showManage}
                      />
                      <select
                        className='form-control'
                        value={startMinute}
                        onChange={(e) => handleTimeChange('startTime', startHour, e.target.value, x?.label, ind)}
                        disabled={!manageCalendar && showManage}
                      >
                        <option value="00">00</option>
                        <option value="30">30</option>
                      </select>
                    </div>
                  </div> */}
                                              </div>
                                            </div>
                                            <div className='col-sm-5'>
                                              <div className='form-group'>
                                                <label>To *</label>
                                                {/* <input type='time' className='form-control' value={y?.endTime} onChange={(e) => handleInputChange(index, 'endTime', e.target.value, 1, '', x?.label, ind)} disabled={!manageCalendar && showManage} required step="1800" /> */}
                                                <TimePicker values={y?.endTime} handleInputChange={handleInputChange} index={index} timeLimit={'endTime'} val={''} num={1} strin={''} label={x?.label} position={ind} manageCalendar={true} showManage={true} isOpen={openTimePickerIndex === `${index}-${ind}-end`} setIsOpen={() => handleTimePickerToggle(`${index}-${ind}-end`)} dropdownRef={dropdownRef} />
                                              </div>
                                            </div>
                                            <div className='col-sm-2 text-center'>
                                              <div className='form-group'>
                                                {/* <button className='add-btn' onClick={() => handleMorediv(x?.label)}>
                                        <img src={add} alt='' />
                                      </button> */}
                                                {ind === 0 ? <button className='add-btn' onClick={() => handleUpdateSelectedTimeSlots(x?.label)} >
                                                  <img src={add} alt='' />
                                                </button> :
                                                  <button className='add-btn' onClick={() => handleRemoveSelectedTimeSlot(x?.label, ind)}>
                                                    <img src={deleteImg} alt='' />
                                                  </button>}
                                              </div>
                                            </div>
                                          </div>
                                          {/* <div className='border-btm mb-3'></div> */}
                                        </>
                                      )
                                    })}
                                  </div>
                                  <div className='border-btm mb-3'></div>
                                </>
                              );
                            })}
                            {addOnDay && addOnDay?.length > 0 && addOnDay.map((x, index) => (
                              // return {
                              <>
                                <div key={index} className='slot-add'>
                                  <h4>On {x?.day.charAt(0).toUpperCase() + x?.day.slice(1).toLowerCase()}</h4>
                                  {/* <h4>Available slot for {x?.day}</h4> */}
                                  {/* <h4>Available slot for {new Date(x?.date).toLocaleDateString('en-CA', { weekday: 'long' })}</h4> */}
                                  {x?.timeSlots && x?.timeSlots?.length > 0 && x?.timeSlots.map((y, ind) => (
                                    <div className='time-slot'>
                                      <div className='row' key={index}>
                                        <div className='col-sm-5'>
                                          <div className='form-group'>
                                            <label>From *</label>
                                            {/* <input type='time' className='form-control' value={y?.startTime} onChange={(e) => handleInputChange(index, 'startTime', e.target.value, 2, 'addon', x?.date, ind)} disabled={!manageCalendar && showManage} required /> */}
                                            <TimePicker values={y?.startTime} handleInputChange={handleInputChange} index={index} timeLimit={'startTime'} val={''} num={2} strin={'addon'} label={x?.day} position={ind} manageCalendar={true} showManage={true} isOpen={openTimePickerIndex === `${index}-${ind}-astart`} setIsOpen={() => handleTimePickerToggle(`${index}-${ind}-astart`)} dropdownRef={dropdownRef} />
                                          </div>
                                        </div>
                                        <div className='col-sm-5'>
                                          <div className='form-group'>
                                            <label>To *</label>
                                            {/* <input type='time' className='form-control' value={y?.endTime} onChange={(e) => handleInputChange(index, 'endTime', e.target.value, 2, 'addon', x?.date, ind)} disabled={!manageCalendar && showManage} required /> */}
                                            <TimePicker values={y?.endTime} handleInputChange={handleInputChange} index={index} timeLimit={'endTime'} val={''} num={2} strin={'addon'} label={x?.day} position={ind} manageCalendar={true} showManage={true} isOpen={openTimePickerIndex === `${index}-${ind}-aend`} setIsOpen={() => handleTimePickerToggle(`${index}-${ind}-aend`)} dropdownRef={dropdownRef} />
                                            {/* <TimePicker values={y?.endTime} handleInputChange={handleInputChange} index={index} timeLimit={'endTime'} val={''} num={2} strin={'addon'} label={x?.date} position={ind} manageCalendar={manageCalendar}  showManage={showManage} isOpen={isOpen} setIsOpen={setIsOpen} dropdownRef={dropdownRef}/> */}
                                          </div>
                                        </div>
                                        <div className='col-sm-2 text-center'>
                                          <div className='form-group'>
                                            {ind === 0 ? <button className='add-btn' onClick={() => handleUpdateTimeSlots(x?.day, ind)} >
                                              {/* {ind === 0 ? <button className='add-btn' onClick={() => handleUpdateTimeSlots(x?.date, ind)} > */}
                                              <img src={add} alt='' />
                                            </button> :
                                              <button className='add-btn' onClick={() => handleRemoveAddDatesTimeSlot(x?.day, ind)} >
                                                <img src={deleteImg} alt='' />
                                              </button>}
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  ))}
                                  <div className='border-btm mb-3'></div>
                                </div>
                              </>
                              // }
                            ))}
                          </>
                        }
                        <div className='row'>
                          <div className='col-sm-12'>
                            {/* <div className='form-group mt-0 mb-0 time-duration position-relative '>
                              <label>Enter Session Duration</label>
                              <input type='number' className='form-control' placeholder='60min' value={sessionDuration} onChange={(e) => {(e?.target?.value >0 && e?.target?.value <=120 )? setSessionDuration(e?.target?.value):(e?.target?.value<=0 ? setSessionDuration('') :setSessionDuration(sessionDuration)) }} min={1} required />
                              <span>{sessionDuration !== '' && 'min'}</span>
                            </div> */}
                            <div className='form-group mt-0 mb-0 time-duration position-relative '>
                              <label>Enter Session Duration *</label>
                              <select
                                className='form-control'
                                value={sessionDuration}
                                onChange={(e) =>  setSessionDuration(e.target.value)}
                                required
                                disabled={data?.editype==='edit'}
                              >
                                <option value=''>Select duration</option>
                                <option value='30'>30 min</option>
                                <option value='60'>60 min</option>
                                <option value='90'>90 min</option>
                                <option value='120'>120 min</option>
                              </select>
                              {/* <span>{sessionDuration !== '' && 'min'}</span> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <button onClick={generateRecurrenceStrings}>Generate</button> */}


                    </div>
                  }
                  {/* </>} */}
                </>}
              </div>
              {type !== 'edit' &&
                <OfferingDetails data={data} />
              }
            </div>

          </div>
        </div>
      </div>
      <Modal
        isOpen={selectedDayLabelModal}
        onRequestClose={() => setSelectedDayLabelModal(false)}
        contentLabel="Delete Modal"
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
        overlayClassName={`modal-overlay  verification-modal offiering-modals-change ${weekDayname !== 'null' && weekDayname !== 'session' && 'timeslot'} ${weekDayname === 'null' && 'time-avaliable timeslot'} ${weekDayname === 'session' && 'time-avaliable timeslot'}`} >
        <button className='close-button ' onClick={() => setSelectedDayLabelModal(false)}><img src={cross} alt='' /></button>
        {weekDayname !== 'null' && weekDayname !== 'session' && weekDayname !== 'date' && weekDayname !== 'dayWeek' && weekDayname !== 'dayCustom' && weekDayname !== 'timeframe' && weekDayname !== 'timeframes' && weekDayname !== 'sameTime' && weekDayname !== 'endTimeLess' && weekDayname !== 'untilDate' && <h1>Please fill the {weekDayname} timeslot.</h1>}
        {weekDayname === 'null' && <h1>Please fill in the time slots when you're available in the section below.</h1>}
        {weekDayname === 'session' && <h1>Session time must be shorter than your availability.</h1>}
        {weekDayname === 'date' && <h1>Please select the date.</h1>}
        {weekDayname === 'dayWeek' && <h1>Please select the required weekDay.</h1>}
        {weekDayname === 'sameTime' && <h1>Time overlaps with existing slot.</h1>}
        {weekDayname === 'endTimeLess' && <h1>End time must be greater than start time.</h1>}
        {weekDayname === 'dayCustom' && <h1>Only {markedDates?.length} session are available, but at least {Number(sessionNumber)} session are needed.</h1>}
        {weekDayname === 'timeframe' && <h1>Please select at least {totalWeeksFrame?.timeframeWeeks} {timeFrame?.months.replace(/ly$/, '')} from your availability to reflect it on calendar, based on number of sessions you have selected per {timeFrame?.months.replace(/ly$/, '')}.</h1>}
        {weekDayname === 'timeframes' && <h1>Please select at least {sessionNumber} date from your availability to reflect it on calendar, based on number of sessions you have selected per {timeFrame?.months.replace(/ly$/, '')}.</h1>}
        {/* {weekDayname === 'timeframe' && <h1>Please set your availability for at least {totalWeeksFrame?.timeframeWeeks} {sessionMultipleType.replace(/ly$/, '')}, as this package includes {totalWeeksFrame?.timeframeWeeks} sessions per {sessionMultipleType.replace(/ly$/, '')}.</h1>} */}
        {weekDayname === 'untilDate' && <h1>You must set the availability to at least match the package you selected.</h1>}
        {/* {weekDayname === 'timeframe' && <h1>Only {totalWeeksFrame?.totalWeeks} {totalWeeksFrame?.type} are available, but at least {totalWeeksFrame?.timeframeWeeks} {totalWeeksFrame?.type} are needed.</h1>} */}
        <span class="d-block text-center"> <img src={timeslot} /></span>
        <p></p>
      </Modal>
      <DraftModal setIsModal={setIsModal} isModal={isModal} />
    </div >
  )
}

export default AvailabilityOffering