
import React, { useEffect, useState, useCallback } from 'react';
import NoDataImg from '../../assets/images/no-data-img.svg'

function NoDataFound() {
    

    return (
        <div className="fav-div-sec">
            <div className=''>
                <div className='mb-3'>
                <img src={NoDataImg}/>
                </div>
                <p>Sorry, no data found matching your search.</p>
            </div>
        
                    
                  
        </div>
    );
}
export default NoDataFound;