import React, { useCallback, useEffect, useRef, useState } from 'react'
import UserSidebar from '../../../Layout/userSidebar'
import burger from '../../../assets/images/burger-menu.svg'
import 'react-datepicker/dist/react-datepicker.css';
import { datetime, RRule, RRuleSet } from 'rrule';
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import leftArrow from '../../../assets/images/backarrow.svg'
import ActiveLinks from './activeLinks'
import OfferingDetails from './OfferingDetails'
import Calendar from 'react-calendar';
import add from '../../../assets/images/add-slot.svg'
import { insertOfferingData } from '../../../Counter/offeringSLice';
import manageIcon from '../../../assets/images/manage-icon.svg';
import deleteImg from '../../../assets/images/delete-2.svg';
import { toast } from 'react-toastify';
import TimePicker from './CreateProduct/testing';
import Modal from 'react-modal'
import cross from '../../../assets/images/gray-close5.svg'
import timeslot from '../../../assets/images/clocck-setting.svg'
import DraftModal from './draftModal';
import greenTick from '../../../assets/images/green-tick.svg'
import { getApi } from '../../../Services/apiService';



const daysOfWeek = [
  { label: 'Sunday', value: RRule.SU, name: 'SU' },
  { label: 'Monday', value: RRule.MO, name: 'MO' },
  { label: 'Tuesday', value: RRule.TU, name: 'TU' },
  { label: 'Wednesday', value: RRule.WE, name: 'WE' },
  { label: 'Thursday', value: RRule.TH, name: 'TH' },
  { label: 'Friday', value: RRule.FR, name: 'FR' },
  { label: 'Saturday', value: RRule.SA, name: 'SA' },
];


function AvailabilityOfferingGroup({ nextStep, prevStep, step, data, submitForm,type }) {

  console.log("data>>>>>>>>>>>>>>>>>>>>>>>>>>", data);

  const formState = useSelector((state) => state.offerCounter);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [classState, setClassState] = useState(false)
  const [offeringType, setOfferingType] = useState(data?.offeringType || '')
  const [sameTime, setSameTime] = useState(data?.sameTime ?? true)
  // const [sameTime, setSameTime] = useState((data && data?.sameTime && data?.sameTime !== null) ? data?.sameTime : true)
  console.log("sameTimesameTimesameTimesameTime",sameTime);
  
  const [recurrences, setRecurrences] = useState(data?.recurrencess || [])
  const [sessionType, setSessionType] = useState(data?.sessionType || 'single_session')
  const [specifyTime, setSpecifyTime] = useState(data?.specifyTime || '')
  const [value, onChange] = useState(new Date());
  const [availabilityPeriod, setAvailabilityPeriod] = useState(data?.availabilityPeriod || '')
  const [selectedDayLabel, setSelectedDayLabel] = useState(data?.selectedDayLabel || []);
  const [dayLabel, setDayLabel] = useState(data?.dayLabel ||  []);
  const [key, setKey] = useState(0);
  const [moreDiv, setMoreDiv] = useState([])
  const [sessionRepeat, setSessionRepeat] = useState(data?.sessionRepeat || '')
  const [typeValue, setTypeValue] = useState(data?.typeValue || {})
  const [monthRepeatValue, setMonthRepeatValue] = useState('')
  const [sessionDuration, setSessionDuration] = useState(data?.sessionDuration || 0)
  // const [sameTimeDuratioon, setSameTimeDuration] = useState({ startTime: '', endTime: '' })
  const [sameTimeDuratioon, setSameTimeDuration] = useState(data?.sameTimeDuratioon || [{ startTime: '', endTime: '',duration:'' }])
  const [sessionMultipleType, setSessionMultipleType] = useState(data?.sessionMultipleType || '')
  const [sessionNumber, setSessionNumber] = useState(data?.sessionPackage || '')
  const [timeFrame, setTimeFrame] = useState(data?.timeFrame || { number: '', months: '' })
  const [errors, setErrors] = useState({});
  const [markedDates, setMarkedDates] = useState([]);
  const [addDates, setAddDates] = useState([])
  const [weekDay, setWeekDay] = useState([])
  const [rdates, setRdates] = useState(data?.rdates || []);
  const [exdates, setExdates] = useState(data?.exdates || []);
  const [addOnDates, setAddOnDates] = useState('');
  const [isMarkedDates, setIsMArkedDates] = useState('');
  const [addDatesCalendar, setAddDateCalendar] = useState(data?.addDatesCalendar || data?.addDateCalendar || [])
  const [showManage, setShowManage] = useState(data?.showManage || false)
  const [manageCalendar, setManageCalendar] = useState(data?.manageCalendar || false)
  const [calendarDate, setCalendarDate] = useState(data?.calendarDate || '')
  const [stateModal, setStateModal] = useState(false)
  // const [addDateCalendar, setAddDateCalendar] = useState([])
  const [openTimePickerIndex, setOpenTimePickerIndex] = useState(null); 
  const [draftGroup,setDraftGroup] = useState(data?.setDraftGroup || false)
  const [selectedDayLabelModal,setSelectedDayLabelModal] = useState(false)
  const[weekDayname,setWeekDayName] =useState('')
  const [addOnDay,setAddOnDay] = useState(data?.addOnDay || [])
  const [activeStartDate, setActiveStartDate] = useState(new Date());
  const [isModal, setIsModal] = useState(false)
  const [dayDropdown,setDayDropdown] = useState(false)
  const [repeatWeekDays,setRepeatWeekDays] = useState(data?.repeatWeekDays || [])
  const [addDatesCancel,setAddDatesCancel] = useState([])
  const [addRexDatesCancel,setRAddDatesCancel] = useState([])
  const [addExDatesCancel,setExAddDatesCancel] = useState([])
  const [countMark,setCountMark] = useState()
  const [editDates,setEditDates] = useState([])

  console.log("addDatesCalendaraddDatesCalendar",editDates);
  
  const dropdownPackageRef = useRef(null);
  const handleTimePickerToggle = (index) => {
    setOpenTimePickerIndex(prevIndex => (prevIndex === index ? null : index)); // Toggle the TimePicker open state
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.time')) {
        setOpenTimePickerIndex(null)
        // setIsOpen(false);
      }
    
    };

    // Add event listener when the dropdown is open
    if (openTimePickerIndex) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openTimePickerIndex]);

  useEffect(() => {
    const handleClickOutside = (event) => {
        if (dropdownPackageRef.current && !dropdownPackageRef.current.contains(event.target)) {
          setDayDropdown(false); // Close the dropdown
        }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, []);

  useEffect(()=>{
    if(sessionMultipleType==='monthly'){
      handleDateClickAddon()
    }

  },[timeFrame])

  console.log("showManage>>>>>>>>>>>>>>", showManage);
  console.log('markedDatesmarkedDates', markedDates);

  console.log("addDatesCalendar", addDatesCalendar);
  console.log("recurrences", recurrences);
  console.log("addDatesaddDates", addDates);

  console.log('sameTimeDuratioonsameTimeDuratioon', sameTimeDuratioon)
  console.log('selectedDayLabelselectedDayLabel', selectedDayLabel)


  useEffect(() => {
    const handleClick = () => setClassState(false);
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [])

  useEffect(() => {
    generateRecurrenceStrings()
  // }, [rdates, exdates,calendarDate])
  }, [rdates, exdates,selectedDayLabel])

  useEffect(()=>{
    if(draftGroup === true){
      generateRecurrenceStrings()
    }
  },[draftGroup])

useEffect(()=>{

})

  useEffect(() => {
    if (sessionType === 'multiple_session') {
      handleDateClickAddon()
    }
  }, [addDatesCalendar,addOnDay,sameTime])

  useEffect(()=>{
    handleAddonWeekDay()
  },[addDatesCalendar])
  
  useEffect(()=>{
    if (sessionType === 'single_session') {
    handleDateClickAddon()
    }
  },[addOnDay,sameTimeDuratioon])

  const handleSidebarClick = (event) => {
    event.stopPropagation();
    setClassState(!classState);
  };

  const handleAddonWeekDay = () => {
    const tempArray = [];
    addDatesCalendar && addDatesCalendar?.length > 0 && addDatesCalendar.map((x) => {
      var localData = new Date(x?.date);
      let dayOfWeeks = localData.toLocaleDateString('en-US', { weekday: 'long' }).toUpperCase();
      if (!tempArray.includes(dayOfWeeks)) {
        tempArray.push(dayOfWeeks);
      }
    });

    setAddOnDay(prevDays => {
      const selectedLabels = selectedDayLabel.map((item) =>
        item?.label?.toUpperCase()
      );
      const filteredDays = prevDays.filter(dayObj => tempArray.includes(dayObj.day) && !selectedLabels.includes(dayObj.day));

      tempArray.forEach(dayOfWeeks => {
        const dayExists = filteredDays.some(dayObj => dayObj.day === dayOfWeeks);

        if (!dayExists && !selectedLabels.includes(dayOfWeeks)) {
          filteredDays.push({ day: dayOfWeeks, timeSlots: [{ startTime: '', endTime: '' }] });
        }
      });

      return filteredDays;
    });
};

  function convertTo24Hour(time) {
    if (!time || typeof time !== 'string') {
      return null; // Return null or some default value if no valid time is provided
    }
    const [hour, minutePart] = time.split(':');
    const minute = minutePart.slice(0, 2);
    const period = minutePart.slice(-2);
    
    let hour24 = parseInt(hour, 10);
    
    if (period === 'PM' && hour24 !== 12) {
      hour24 += 12;
    }
    if (period === 'AM' && hour24 === 12) {
      hour24 = 0;
    }
  
    return `${String(hour24).padStart(2, '0')}:${minute}`;
  }

  const validateForm = () => {
    
    let formErrors = {};

    if (!sessionType) {
      formErrors.sessionType = 'Please select a session type.';
    }

    if (sessionType === 'multiple_session' && !sessionMultipleType) {
      formErrors.sessionMultipleType = 'Please select a package type.';
    }

    if (sessionType === 'multiple_session' && !sessionNumber && sessionMultipleType !== 'daily') {
      formErrors.sessionNumber = 'Please specify the number of sessions.';
    }

    if (sessionMultipleType && sessionMultipleType !== 'custom' && (timeFrame.number === '' || timeFrame.months === '')) {
      formErrors.timeFrame = 'Please specify the timeframe of the package.';
    }

    if (specifyTime === 'exact' && !sessionRepeat) {
      formErrors.sessionRepeat = 'Please specify how often the session should repeat.';
    }

    if (availabilityPeriod === 'date' && !typeValue.date) {
      formErrors.availabilityPeriod = 'Please specify the end date of your availability period.';
    }

    if (availabilityPeriod === 'after' && !typeValue.after) {
      formErrors.availabilityPeriod = 'Please specify the number of times after which the period ends.';
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const isValidForm = () => {
    if (sameTime) {
      return sameTimeDuratioon.startTime && sameTimeDuratioon.endTime;
    } else {
      return selectedDayLabel.every((dates, index) => {
        return dates[index]?.startTime && dates[index]?.endTime;
      });
    }
  };

  const handleDateClicks = (date, isMarked) => {
    setManageCalendar(true)
  }
  function calculateEndTime(startTime, duration) {
    if (startTime !== '') {
        let [startHour, startMinute] = startTime.split(/[:\s]/).map((val, index) => index === 0 ? parseInt(val) : val); 
        let isPM = startTime.includes('PM');

        if (isPM && startHour < 12) {
            startHour += 12;
        }
        if (!isPM && startHour === 12) {
            startHour = 0; 
        }

        let totalMinutes = parseInt(startMinute) + parseInt(duration);
        let endHour = startHour + Math.floor(totalMinutes / 60);
        let endMinute = totalMinutes % 60;

        endHour = endHour % 24; 
        const period = endHour >= 12 ? 'PM' : 'AM';
        if (endHour > 12) endHour -= 12;
        if (endHour === 0) endHour = 12; 
        
        const formattedEndHour = String(endHour).padStart(2, '0');
        const formattedEndMinute = String(endMinute).padStart(2, '0');

        return `${formattedEndHour}:${formattedEndMinute} ${period}`;
    }
}

  const generateRecurrenceStrings = (date, dayOfWeek, isMarked, type, updatedDatess, rdatesAdd, exdatesAdd) => {
    date = date !== undefined && (date?.length > 0 ? calendarDate : date?.length === 0 ? calendarDate : date)
    const startDate = new Date(date || calendarDate).getDate();
    const fullyear = new Date(date || calendarDate).getFullYear();
    const month = new Date(date || calendarDate).getMonth();
    const dayName = new Date(date || calendarDate).getDay();

    setRecurrences([]);

    const dayMap = {
      Sunday: RRule.SU,
      Monday: RRule.MO,
      Tuesday: RRule.TU,
      Wednesday: RRule.WE,
      Thursday: RRule.TH,
      Friday: RRule.FR,
      Saturday: RRule.SA,
    };


    const newRecurrenceRules = [];
    const countOccurrences = (allWeekdays, targetName) => {
      
      const occurrences = allWeekdays.filter((day) => day.name === targetName);
      return {
        count: occurrences.length,
      };
    };

    function generateWeekdayData(date, calendarDate, timeFrame) {
      const monthsDay = new Date(date || calendarDate);
      const nextDate = new Date(monthsDay);
      nextDate.setMonth(nextDate.getMonth() + Number(timeFrame?.number));
    
      const totalDays = Math.floor((nextDate - monthsDay) / (1000 * 60 * 60 * 24)) + 1;
    
      const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const shortNames = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
    
      const result = [];
    
      for (let i = 0; i < totalDays; i++) {
        const currentDate = new Date(monthsDay);
        currentDate.setDate(monthsDay.getDate() + i);
    
        const dayIndex = currentDate.getDay();
        const dayLabel = daysOfWeek[dayIndex];
        const dayName = shortNames[dayIndex];
    
        const existingDay = result.find(item => item.label === dayLabel);
          result.push({
            label: dayLabel,
            name: dayName,
            dates: [{ startTime: "", endTime: "", duration: "" }]
          });
      }
    
      return result;
    }

    selectedDayLabel.forEach((x) => {
      
      let ruleOptions;
      const dtstart = new Date();
      dtstart.setUTCHours(0, 0, 0, 0);
      const dtStartWeek = new Date(date)
      dtStartWeek.setUTCHours(0, 0, 0, 0);
      if (sessionMultipleType === 'monthly') {
      }
      let weeklyDays = [];
      selectedDayLabel.forEach((x) => {
        const a = dayMap[x?.label];
        weeklyDays.push(a)
      })

      if (sessionMultipleType === 'weekly') {
        ruleOptions = {
          freq: RRule.WEEKLY,
          dtstart: new Date(dtstart),
          byweekday: [dayMap[x?.label]],// weeklyDays,
          count: Number(timeFrame?.number)
        };
      }
      if (sessionMultipleType === 'daily') {
        if(timeFrame?.months === 'weeks'){
        ruleOptions = {
          freq: RRule.WEEKLY,
          dtstart: new Date(Date.UTC(fullyear, month, startDate, 0, 0)),
          byweekday: [dayMap[x?.label]],
          count: Number(timeFrame?.number)
        };
      } if(timeFrame?.months === 'day'){
        const checkCount = countOccurrences(repeatWeekDays, x?.name)
        ruleOptions = {
          freq: RRule.WEEKLY,
          dtstart: new Date(Date.UTC(fullyear, month, startDate, 0, 0)),
          byweekday: [dayMap[x?.label]],
          count: checkCount?.count //Number(timeFrame?.number)
        };
      } if(timeFrame?.months === 'months'){
  //       const monthsDay = new Date(date || calendarDate)
  //       const nextDate = new Date(monthsDay);
  // nextDate.setMonth(nextDate.getMonth() + Number(timeFrame?.number));
  // const totalDays = Math.floor((nextDate - monthsDay) / (1000 * 60 * 60 * 24)) + 1;
  const data = generateWeekdayData(null, (date || calendarDate), timeFrame);
  const checkCount = countOccurrences(data, x?.name)
        ruleOptions = {
          freq: RRule.WEEKLY,
          dtstart: new Date(Date.UTC(fullyear, month, startDate, 0, 0)),
          byweekday: [dayMap[x?.label]],
          // until: new Date(monthsDay.getFullYear(), monthsDay.getMonth() + 2, monthsDay.getDate(),0,0),
          count: checkCount?.count//totalDays,
        };
      }
        // ruleOptions = {
        //   freq: RRule.WEEKLY,
        //   dtstart: new Date(Date.UTC(fullyear, month, startDate, 0, 0)),
        //   byweekday: [dayMap[x?.label]],
        //   count: Number(timeFrame?.number)
        // };
      }
      if (sessionType === 'single_session') {
        ruleOptions = {
          freq: RRule.DAILY,
          dtstart: new Date(Date.UTC(fullyear, month, startDate, 0, 0)),
          count: 1
        };
      }
      const rru = new RRuleSet();
      rru.rrule(new RRule(ruleOptions));
      const rdate = new Date(Date.UTC(fullyear, month, startDate, 0, 0));

      console.log(">>>>>>>>>>>rdates", rdates);

      rdates && rdates.length > 0 && rdates.map((y) => {
        let dayOfWeekr = y.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase(); // Get the day of the week in 'SU', 'MO', etc.
        dayOfWeekr = dayOfWeekr && dayOfWeekr.slice(0, 2);
        const startDate = new Date(y).getDate();
        const fullyear = new Date(y).getFullYear();
        const month = new Date(y).getMonth();
        const rdater = new Date(Date.UTC(fullyear, month, startDate, 0, 0));
        if (x?.name == dayOfWeekr) {

          rru.rdate(rdater);
        }
      })
      console.log(">>>>>>>>exdates", exdates)
      exdates && exdates.length > 0 && exdates.map((y) => {

        let dayOfWeekex = y.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase(); // Get the day of the week in 'SU', 'MO', etc.
        dayOfWeekex = dayOfWeekex && dayOfWeekex.slice(0, 2);
        const startDate = new Date(y).getDate();
        const fullyear = new Date(y).getFullYear();
        const month = new Date(y).getMonth();
        const rdatex = new Date(Date.UTC(fullyear, month, startDate, 0, 0));
        if (x?.name == dayOfWeekex) {

          rru.exdate(rdatex);
        }
      })
        let updatedTimeDurations;
        updatedTimeDurations = x.dates.map((timeSlot) => {
          return {
            ...timeSlot,
            startTime: convertTo24Hour(timeSlot.startTime) || '',
            endTime: convertTo24Hour(timeSlot.endTime) || ''
          };
        });
        if(sameTime){
          updatedTimeDurations = sameTimeDuratioon.map((timeSlot) => {
            return {
              ...timeSlot,
              startTime: convertTo24Hour(timeSlot.startTime) || '',
              endTime: convertTo24Hour(timeSlot.endTime) || ''
            };
          });
        }
      let obj;
      obj = sameTime
        ? {
          dayOfWeek: x?.name,
          sessionDuration: Number(sessionDuration),
          timeSlots: updatedTimeDurations,
          rruleString: rru.toString(),
        }
        : {
          dayOfWeek: x?.name,
          sessionDuration: Number(sessionDuration),
          timeSlots: updatedTimeDurations,
          rruleString: rru.toString(),
        };
      // selectedDayLabel.forEach((x) => {
      //   setWeekDay((prev) => {
      //     const newDays = Array.isArray(x?.name) ? x.name : [x?.name];
      //     const updatedWeekDay = [...prev, ...newDays];
      //     return [...new Set(updatedWeekDay)];
      //   });
      // })
      let newWeekDays = [];
      selectedDayLabel.forEach((x) => {
        const newDays = Array.isArray(x?.name) ? x.name : [x?.name];
        newWeekDays = [...newWeekDays, ...newDays];
      });
      setWeekDay([...new Set(newWeekDays)]);

      newRecurrenceRules.push(obj);

    })
    console.log('newdate', date)

    updatedDatess && updatedDatess.length > 0 ? updatedDatess.forEach(rule => {
      if (!newRecurrenceRules.some(existingRule => existingRule.rruleString === rule.rruleString)) {
        newRecurrenceRules.push(rule);
      }
    })
      :
      addDates.forEach(rule => {
        if (!newRecurrenceRules.some(existingRule => existingRule.rruleString === rule.rruleString)) {
          newRecurrenceRules.push(rule);
        }
      });



    console.log("newRecurrenceRulesnewRecurrenceRules", newRecurrenceRules)
    setRecurrences((prev) => [...prev, ...newRecurrenceRules]);
    updateMarkedDates(newRecurrenceRules, isMarked);
    console.log("recurrenceString", newRecurrenceRules);
  };

  const generateRecurrenceStringsMonthly = (date, dayOfWeek, isMarked, type, updatedDatess, rdatesAdd, exdatesAdd) => {

    date = date !== undefined && (date?.length > 0 ? calendarDate : date?.length === 0 ? calendarDate : date)
    const startDate = new Date(date || calendarDate).getDate();
    const fullyear = new Date(date || calendarDate).getFullYear();
    const month = new Date(date || calendarDate).getMonth();
    const dayName = new Date(date || calendarDate).getDay();

    setRecurrences([]);

    const dayMap = {
      Sunday: RRule.SU,
      Monday: RRule.MO,
      Tuesday: RRule.TU,
      Wednesday: RRule.WE,
      Thursday: RRule.TH,
      Friday: RRule.FR,
      Saturday: RRule.SA,
    };


    const newRecurrenceRules = [];
    let ruleOptions;
    const dtstart = new Date();
    dtstart.setUTCHours(0, 0, 0, 0);
    const dtStartWeek = new Date(date)
    dtStartWeek.setUTCHours(0, 0, 0, 0);
    if (sessionMultipleType === 'monthly') {
      const eventDate = new Date(dtstart);
      const dayOfMonth = eventDate.getDate(); // Get the day of the month from the start date

      ruleOptions = {
        freq: RRule.MONTHLY,     // Repeat every month
        dtstart: eventDate,      // Start date of the recurrence
        bymonthday: dayOfMonth,  // Repeat on this exact date every month
      };
    }


    const rru = new RRuleSet();
    rru.rrule(new RRule(ruleOptions));
    const rdate = new Date(Date.UTC(fullyear, month, startDate, 0, 0));

    let obj;
    obj = sameTime
      ? {
        sessionDuration: 0,
        timeSlots: [sameTimeDuratioon],
        rruleString: rru.toString(),
      }
      : {
        sessionDuration: 0,
        rruleString: rru.toString(),
      };
    newRecurrenceRules.push(obj);
    console.log('newdate', date)

    updatedDatess && updatedDatess.length > 0 && updatedDatess.forEach(rule => {
      if (!newRecurrenceRules.some(existingRule => existingRule.rruleString === rule.rruleString)) {
        newRecurrenceRules.push(rule);
      }
    });


    console.log("newRecurrenceRulesnewRecurrenceRules", newRecurrenceRules)
    setRecurrences((prev) => [...prev, ...newRecurrenceRules]);
    updateMarkedDates(newRecurrenceRules, isMarked);
    console.log("recurrenceString", newRecurrenceRules);
  };

  const handleDayChange = async(day, label, name) => {

    // if((!manageCalendar && !showManage) ) {
    if((!manageCalendar) ) {
      if(data?.editype==='edit'){
        const resp = await getApi(`/creator/edit/session-date?day=${name}&offer_id=${data?.id}`);
        if(resp?.statusCode ===200){
          if(resp?.data ==1){
            alert(resp?.message)
            return;
          }
        }
      }
    if (sessionRepeat === 'one') {
      setDayLabel([label]);
      setSelectedDayLabel([{ label: label, name: name, dates: [{ startTime: '', endTime: '',duration:'' }] }])
    }
    if (sessionRepeat === '' || sessionRepeat === 'daily' || sessionRepeat === 'weekly') {
      const nameExists = selectedDayLabel.some(item => item.name === name);
      if(selectedDayLabel.length < Number(sessionNumber) || nameExists){
      setDayLabel(prevDays =>
        prevDays.includes(label)
          ? prevDays.filter(d => d !== label)
          : [...prevDays, label]
      );
      setSelectedDayLabel(prevDays => {
        const labelExists = prevDays.some(d => d.label === label);
        if (labelExists) {
          return prevDays.filter(d => d?.label !== label);
        } else {
          return [...prevDays, { label: label, name: name, dates: [{ startTime: '', endTime: '',duration:'' }] }];
        }
      });
    } else{
      setWeekDayName('selected')
      setSelectedDayLabelModal(true)
      // alert(`you selected only ${sessionNumber} times`)
    }

    }
  }
  };

  const parseRRule = (rruleString) => {
    console.log('rruleString', rruleString)
    if (typeof rruleString !== 'string') {
      console.error('Expected a string but got:', typeof rruleString, rruleString);
      return [];
    }
    const lines = rruleString.split('\n');
    let rruleLine = '';
    let rdates = [];
    let dtstart = [];
    let exdates = [];

    for (let i = lines.length - 1; i >= 0; i--) {
      const line = lines[i];
      console.log('line', line)
      if (line.startsWith('RRULE')) {
        rruleLine += line.split(':')[1];
      } else if (line.startsWith('DTSTART')) {
        const dtstart = line.split(':')[1];
        const dtstartDate = dtstart.split('T')[0];
        rruleLine += `;DTSTART=${dtstart}`;
      } else if (line.startsWith('RDATE')) {
        const rdateLine = line.split(':')[1];

        const dateStrings = rdateLine.split(',');

        dateStrings.forEach(dateStr => {
          rdates.push(dateStr);
        });
      }
      else if (line.startsWith('EXDATE')) {
        console.log()
        const rdateLine = line.split(':')[1];
        const dateStrings = rdateLine.split(',');
        dateStrings.forEach(dateStr => {
          exdates.push(dateStr);
        });
      }
    }

    let rruleStrings = rruleLine;
    let finalRRuleString = `rrulestr('${rruleStrings}')`
    console.log('rruleLine', finalRRuleString)

    const ruleSet = new RRuleSet();
    const rrule = RRule.fromString(rruleStrings);

    ruleSet.rrule(rrule);
    exdates.forEach(dateStr => {
      const isoDateStr = dateStr.replace(/(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})Z/, '$1-$2-$3T$4:$5:$6Z');
      const exdate = new Date(isoDateStr);
      if (!isNaN(exdate.getTime())) {
        ruleSet.exdate(exdate);
      } else {
        console.error('Invalid EXDATE:', dateStr);
      }
    });

    rdates.forEach(dateStr => {
      const isoDateStr = dateStr.replace(/(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})Z/, '$1-$2-$3T$4:$5:$6Z');
      const exdate = new Date(isoDateStr);
      if (!isNaN(exdate.getTime())) {
        ruleSet.rdate(exdate);
      } else {
        console.error('Invalid RDATE:', dateStr);
      }
    });
    const rruleDates = ruleSet.all();
    console.log("rruleDatesrruleDates", rruleDates)


    const allDates = [...rruleDates];
    console.log("allDates", allDates)
    const formattedDate = allDates.map(date => {
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const day = String(date.getUTCDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    });

    console.log("formattedDate", formattedDate);
    return formattedDate;
  };

  const updateMarkedDates = (updatedRecurrences, ismarkedDates) => {

    console.log("updatedRecurrences", updatedRecurrences)
    const newMarkedDates = updatedRecurrences.reduce((acc, occurrence) => {
      console.log('ifffffff');
      const dates = parseRRule(occurrence?.rruleString);
      console.log("datesrecurrence", dates)
      return [...acc, ...dates];
    }, []);
    console.log("newMarkedDatesnewMarkedDates", newMarkedDates);

    // setMarkedDates(newMarkedDates);
    setMarkedDates([...new Set(newMarkedDates)]);
    // setMarkedDates((prevMarkedDates) => {
    //   const combinedDates = [...prevMarkedDates, ...newMarkedDates];
    //   const uniqueDates = [...new Set(combinedDates)];
    //   return uniqueDates;
    // });
  };


  const handleDateClick = (date, isMarked, type) => {
    { type === 'manage' ? setShowManage(false) : setShowManage(true) }
    // setRecurrences([])
    // addDatesCalendar && addDatesCalendar?.length>0 && addDatesCalendar.map((x,index)=>{

    addDatesCalendar?.forEach((x) => {
      const dateLocal = new Date(x?.date);

      const formattedDate = dateLocal.toLocaleDateString('en-CA');

      let dayOfWeek = dateLocal.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase();

      let updatedDatess = [];
      let rdatesAdd = [];
      let exdatesAdd = [];
      if (!weekDay.includes(dayOfWeek)) {


        if (x?.isMarked === false || x?.isMarked === true) {
          const startDate = new Date(x?.date).getDate();
          const fullyear = new Date(x?.date).getFullYear();
          const month = new Date(x?.date).getMonth();
          let newRuleStrings;
          let newRule;
          const validDate = new Date(x?.date);
          if (isNaN(validDate)) {
            console.error("Invalid date provided:", x?.date);
            return;
          }
          const getDayNum = validDate.getDay();
          const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
          const getDay = dayNames[getDayNum];
          if (offeringType === 'Monthly') {
            newRule = {
              freq: RRule.MONTHLY,
              dtstart: new Date(Date.UTC(fullyear, month, startDate, 0, 0)),
              bysetpos: monthRepeatValue,
              count: 1,
            };
          } else {
            newRule = {
              freq: RRule.WEEKLY,
              dtstart: new Date(Date.UTC(fullyear, month, startDate, 0, 0)),
              count: 1
            };
          }
          const rrus = new RRuleSet();
          rrus.rrule(new RRule(newRule));
          let newRuleString = {
            dayOfWeek: dayOfWeek,
            sessionDuration: 0,
            timeSlots: [x?.timeSlots],
            // timeSlots: [[{ startTime: '10:00', endTime: '12:00' }]],
            rruleString: rrus.toString(),
          };
          const uniqueDates = addDates.reduce((acc, current) => {
            const x = acc.find(item => item.rruleString === current.rruleString);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);

          let newRRuleString = uniqueDates.length > 0 ? [...uniqueDates] : [];
          setAddDates(prev => {
            let updatedDates = [...prev];
            const existingIndex = updatedDates.findIndex(item => item.rruleString === newRuleString.rruleString);
            console.log("existingIndex", existingIndex)
            console.log("updatedDatesupdatedDates", updatedDates)
            if (existingIndex < 0) {
              console.log('ifffffffffffffffff')
              console.log('newRuleString', newRuleString)
              updatedDates.push(newRuleString);
            } else {
              console.log('elseeeeeeeeeeeeeeeeeee')
              updatedDates.splice(existingIndex, 1);
            }



            console.log("newRRuleString after updating:", newRRuleString);
            console.log('updatedDates', updatedDates)
            console.log('updatedDates00000', updatedDatess)
            updatedDatess.push(...updatedDates);
            console.log('updatedDatess &************', updatedDatess)
            return updatedDates
          });

          console.log('updatedDatess &--------------', updatedDatess)
          console.log("markedDates*******", markedDates);
          console.log("updatedDates>>>>>>>>>>>>>>>", updatedDatess)
          console.log("newRRuleString after adding:", updatedDatess);
        }
      }
      else {
        if (isMarked === false) {
          setRdates((prevRdates) => {
            console.log('date', date)
            const updatedRdates = [...prevRdates, date];

            const finalRdates = updatedRdates.filter((d, index, self) =>
              index === self.findIndex((t) => t.getTime() === d.getTime())
            );

            setExdates((prevExdates) => prevExdates && prevExdates.filter((d) => d.getTime() !== date.getTime()));
            setRAddDatesCancel((prevCancel) => {
              const cancelExists = prevCancel.some((item) =>
                isSameDate(new Date(item.date), new Date(date))
              );
          
              if (!cancelExists) {
                // Add new entry to addDatesCancel
                return [...prevCancel, { date, isMarked: isMarked }];
              } else {
                // If date exists, return previous state
                return prevCancel;
              }
            });
            rdatesAdd = finalRdates
            return finalRdates;
          });
        }
        if (isMarked === true) {
          setExdates((prevExdates) => {
            const updatedExdates = [...prevExdates, date];

            const finalExdates = updatedExdates.filter((d, index, self) =>
              index === self.findIndex((t) => t.getTime() === d.getTime())
            );

            setRdates((prevRdates) => prevRdates && prevRdates.filter((d) => d.getTime() !== date.getTime()));
            setExAddDatesCancel((prevCancel) => {
              const cancelExists = prevCancel.some((item) =>
                isSameDate(new Date(item.date), new Date(date))
              );
          
              if (!cancelExists) {
                // Add new entry to addDatesCancel
                return [...prevCancel, { date, isMarked: isMarked }];
              } else {
                // If date exists, return previous state
                return prevCancel;
              }
            });
            exdatesAdd = finalExdates
            return finalExdates;
          });
        }
      }

      updatedDatess = updatedDatess && updatedDatess?.length > 0 ? updatedDatess : addDates

      console.log("updatedDatess55555555555", updatedDatess);
      rdatesAdd = rdatesAdd && rdatesAdd?.length > 0 ? rdatesAdd : rdates
      exdatesAdd = exdatesAdd && exdatesAdd?.length > 0 ? exdatesAdd : exdates

      const newRRuleString = generateRecurrenceStrings(date, dayOfWeek, isMarked, 'single', updatedDatess, rdatesAdd, exdatesAdd);
      console.log('newRRuleString', newRRuleString)
      const newRecurrence = {
        dayOfWeek: dayOfWeek.slice(0, 2),
        sessionDuration: 0,
        startTime: undefined,
        endTime: undefined,
        rruleString: newRRuleString?.rruleString,
      };
      console.log('newRecurrence', newRecurrence)
      setKey(prevKey => prevKey + 1);
    })
  };

  const isSameDate = (d1, d2) =>
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate();

    const formatDateForAPI = (date) => {
      const month = date.getMonth() + 1; // Months are zero-indexed
      const year = date.getFullYear();
      return `${month}-${year}`;
    };

    const fetchData = useCallback(async (formattedDate) => {
      try {
        const response = await getApi(`/creator/edit/date?offerId=${data?.id}&monthYear=${formattedDate}`);
        const dates = response.data;
        // Get the current date and time
        const currentDate = new Date();
        // Filter the dates to include only those greater than the current date
        const filteredDates = dates.filter(dateStr => {
          const date = new Date(dateStr);
          return date > currentDate;
        });
        // Convert dates from the API to the desired format
        const formattedDates = filteredDates.map(dateStr => {
          const date = new Date(dateStr);
          // Set time to midnight
          date.setUTCHours(0, 0, 0, 0);
          return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
        });
  
        setEditDates(formattedDates);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }, [data?.id]);

    const fetchInitialData = useCallback(async () => {
      const formattedDate = formatDateForAPI(activeStartDate);
      if(data?.editype==='edit'){
      await fetchData(formattedDate);
      }
    }, [activeStartDate, fetchData]);
    useEffect(() => {
      fetchInitialData();
    }, [activeStartDate, fetchInitialData]);

  const handleSaveRecurrence = async(date, isMarked, types) => {
    if(data?.editype === 'edit'){
      const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
        const day = String(date.getDate()).padStart(2, '0')
        const formattedDate = `${year}-${month}-${day}`;
        const resp = await getApi(`creator/edit/single-date?date=${formattedDate}&offer_id=${data?.id}`)
        if(resp?.statusCode ===200){
          if(resp?.data== 0){
            
            // return;
          } else{
            toast.error(resp?.message)
            const formattedDates = formatDateForAPI(new Date(formattedDate));
            await fetchData(formattedDates)
            return;
          }
        }
    }
    console.log('yessssssss')
    if(selectedDayLabel.length !== Number(sessionNumber) && sessionMultipleType === 'weekly' ){
      setWeekDayName('weekDay')
      setSelectedDayLabelModal(true)
      return
    }
    if (types === 'first') {
      setCalendarDate(date)
    }
    setIsMArkedDates(isMarked)
    setAddOnDates(date)
    const stripTime = (date) =>
      new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const strippedDate = stripTime(new Date(date));
    if (sessionType === 'single_session') {
      setAddDateCalendar((prev) => {
        const dateExists = prev.some((item) => isSameDate(stripTime(new Date(item.date)), strippedDate));
        // const dateExists = prev.some((item) => new Date(item.date).toISOString() === new Date(date).toISOString());
        console.log('dateExists', dateExists)
        if (dateExists) {
          // return prev.filter((item) => new Date(item.date).toISOString() !== new Date(date).toISOString());
          const removedItem = prev.find(
            (item) => isSameDate(stripTime(new Date(item.date)), strippedDate)
            // (item) => new Date(item.date).toISOString() === new Date(date).toISOString()
          );
      
          setAddDatesCancel((prevCancel) => {
            const cancelExists = prevCancel.some(
              (item) => isSameDate(stripTime(new Date(item.date)), strippedDate)
              // (item) => new Date(item.date).toISOString() === new Date(date).toISOString()
            );
      
            if (!cancelExists) {
              return [
                ...prevCancel,
                { ...removedItem, isMarked: !removedItem.isMarked }, // Toggle or update `isMarked`
              ];
            }
      
            // Update `isMarked` if it already exists in `addDatesCancel`
            return prevCancel.map((item) =>
              isSameDate(stripTime(new Date(item.date)), strippedDate)
              // new Date(item.date).toISOString() === new Date(date).toISOString()
                ? { ...item, isMarked: !item.isMarked } // Toggle `isMarked`
                : item
            );
          });
          return prev.filter(
            (item) => !isSameDate(stripTime(new Date(item.date)), strippedDate)
            // (item) => new Date(item.date).toISOString() !== new Date(date).toISOString()
          );
        } else {
          // return [...prev, { date: date, isMarked: isMarked, timeSlots: [{ startTime: '', endTime: '',duration:'' }] }];
          const newEntry = {
            date: date,
            isMarked: isMarked,
            timeSlots: [{ startTime: '', endTime: '' }],
          };
          setAddDatesCancel((prevCancel) => {
            const cancelExists = prevCancel.some((item) =>
              isSameDate(new Date(item.date), new Date(date))
            );
      
            if (!cancelExists) {
              return [...prevCancel, newEntry];
            } else {
              return prevCancel.map((item) =>
                isSameDate(new Date(item.date), new Date(date))
                  ? { ...item, isMarked: isMarked }
                  : item
              );
            }
          });
      
          return [...prev, newEntry];
        }

      })
    } else {
      if (sessionType === 'multiple_session') {
        

        if((timeFrame?.months==='day' || timeFrame?.months === 'months') && sessionMultipleType === 'daily' && types !=='first'){
            
            let sessionCount;
            if(timeFrame?.months === 'day' && sessionMultipleType==='daily'){
              sessionCount = timeFrame?.number
            }
            else if(timeFrame?.months === 'weeks' && sessionMultipleType==='daily'){
              sessionCount = timeFrame?.number*7
            }
            else if(timeFrame?.months === 'months' && sessionMultipleType==='daily'){
              sessionCount = timeFrame?.number*31
            }
            else{
              sessionCount = sessionNumber * timeFrame?.number
            }
            if ((markedDates.length < sessionCount) || isMarked === true) {
            // if ((markedDates.length < Number(timeFrame?.number) * 7) || isMarked === true) {
            const today = new Date(calendarDate);
              const twoWeeksFromNow = new Date(calendarDate);
              if(timeFrame?.months === 'day'){
              twoWeeksFromNow.setDate(today.getDate() + Number(timeFrame?.number));
              }
              if(timeFrame?.months === 'months'){
              twoWeeksFromNow.setDate(today.getDate() + (Number(timeFrame?.number)*31));
              }

              const selectedDate = new Date(date);
              const isWithinTwoWeeks = selectedDate >= today && selectedDate <= twoWeeksFromNow;
             
              if (!isWithinTwoWeeks) {
                setAddDateCalendar((prev) => {
                  const dateExists = prev.some((item) => isSameDate(stripTime(new Date(item.date)), strippedDate));
                  // const dateExists = prev.some((item) => new Date(item.date).toISOString() === new Date(date).toISOString());
                  console.log('dateExists', dateExists)
                  if (dateExists) {
                    // return prev.filter((item) => new Date(item.date).toISOString() !== new Date(date).toISOString());
                    const removedItem = prev.find(
                      (item) => isSameDate(stripTime(new Date(item.date)), strippedDate)
                      // (item) => new Date(item.date).toISOString() === new Date(date).toISOString()
                    );
                
                    setAddDatesCancel((prevCancel) => {
                      const cancelExists = prevCancel.some(
                        (item) => isSameDate(stripTime(new Date(item.date)), strippedDate)
                        // (item) => new Date(item.date).toISOString() === new Date(date).toISOString()
                      );
                
                      if (!cancelExists) {
                        return [
                          ...prevCancel,
                          { ...removedItem, isMarked: !removedItem.isMarked }, // Toggle or update `isMarked`
                        ];
                      }
                
                      // Update `isMarked` if it already exists in `addDatesCancel`
                      return prevCancel.map((item) =>
                        // new Date(item.date).toISOString() === new Date(date).toISOString()
                      isSameDate(stripTime(new Date(item.date)), strippedDate)
                          ? { ...item, isMarked: !item.isMarked } // Toggle `isMarked`
                          : item
                      );
                    });
                    return prev.filter(
                      (item) => !isSameDate(stripTime(new Date(item.date)), strippedDate)
                      // (item) => new Date(item.date).toISOString() !== new Date(date).toISOString()
                    );
                  } else {
                    // return [...prev, { date: date, isMarked: isMarked, timeSlots: [{ startTime: '', endTime: '',duration:'' }] }];
                    const newEntry = {
                      date: date,
                      isMarked: isMarked,
                      timeSlots: [{ startTime: '', endTime: '' }],
                    };
                    setAddDatesCancel((prevCancel) => {
                      const cancelExists = prevCancel.some((item) =>
                        isSameDate(new Date(item.date), new Date(date))
                      );
                
                      if (!cancelExists) {
                        return [...prevCancel, newEntry];
                      } else {
                        return prevCancel.map((item) =>
                          isSameDate(new Date(item.date), new Date(date))
                            ? { ...item, isMarked: isMarked }
                            : item
                        );
                      }
                    });
                
                    return [...prev, newEntry];
                  }

                })
              }
              else{
                
                handleDateClickAddon(date, isMarked, 'erdate')
                return;
              }

            }
            else {
              setWeekDayName('remove')
    setSelectedDayLabelModal(true)
              return;
            }
          }
       

        if (types !== 'first' && sessionMultipleType !== 'monthly' && timeFrame?.months!=='day' && timeFrame?.months!=='months') {
          let dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase(); // Get the day of the week in 'SU', 'MO', etc.
          dayOfWeek = dayOfWeek.slice(0, 2);
          if (!weekDay.includes(dayOfWeek)) {
            if (exdates?.length > 0) {
              
              let sessionCount;
              if(timeFrame?.months === 'day' && sessionMultipleType==='daily'){
                sessionCount = timeFrame?.number
              }
              else if(timeFrame?.months === 'weeks' && sessionMultipleType==='daily'){
                sessionCount = timeFrame?.number*7
              }
              else if(timeFrame?.months === 'months' && sessionMultipleType==='daily'){
                sessionCount = timeFrame?.number*31
              }
              else{
                sessionCount = sessionNumber * timeFrame?.number
              }
              if ((markedDates.length < (sessionCount) || isMarked === true)) {
              // if ((markedDates.length < (sessionNumber * timeFrame?.number) || isMarked === true)) {
                setAddDateCalendar((prev) => {
                  const dateExists = prev.some((item) => isSameDate(stripTime(new Date(item.date)), strippedDate));
                  // const dateExists = prev.some((item) => new Date(item.date).toISOString() === new Date(date).toISOString());
                  console.log('dateExists', dateExists)
                  if (dateExists) {
                    // return prev.filter((item) => new Date(item.date).toISOString() !== new Date(date).toISOString());
                    const removedItem = prev.find(
                      (item) => isSameDate(stripTime(new Date(item.date)), strippedDate)
                      // (item) => new Date(item.date).toISOString() === new Date(date).toISOString()
                    );
                
                    setAddDatesCancel((prevCancel) => {
                      const cancelExists = prevCancel.some(
                        (item) => isSameDate(stripTime(new Date(item.date)), strippedDate)
                        // (item) => new Date(item.date).toISOString() === new Date(date).toISOString()
                      );
                
                      if (!cancelExists) {
                        return [
                          ...prevCancel,
                          { ...removedItem, isMarked: !removedItem.isMarked }, // Toggle or update `isMarked`
                        ];
                      }
                
                      // Update `isMarked` if it already exists in `addDatesCancel`
                      return prevCancel.map((item) =>
                        isSameDate(stripTime(new Date(item.date)), strippedDate)
                        // new Date(item.date).toISOString() === new Date(date).toISOString()
                          ? { ...item, isMarked: !item.isMarked } // Toggle `isMarked`
                          : item
                      );
                    });
                    return prev.filter(
                      (item) => !isSameDate(stripTime(new Date(item.date)), strippedDate)
                      // (item) => new Date(item.date).toISOString() !== new Date(date).toISOString()
                    );
                  } else {
                    // return [...prev, { date: date, isMarked: isMarked, timeSlots: [{ startTime: '', endTime: '',duration:'' }] }];
                    const newEntry = {
                      date: date,
                      isMarked: isMarked,
                      timeSlots: [{ startTime: '', endTime: '' }],
                    };
                    setAddDatesCancel((prevCancel) => {
                      const cancelExists = prevCancel.some((item) =>
                        isSameDate(new Date(item.date), new Date(date))
                      );
                
                      if (!cancelExists) {
                        return [...prevCancel, newEntry];
                      } else {
                        return prevCancel.map((item) =>
                          isSameDate(new Date(item.date), new Date(date))
                            ? { ...item, isMarked: isMarked }
                            : item
                        );
                      }
                    });
                
                    return [...prev, newEntry];
                  }

                })

              }
              else {
                setWeekDayName('remove')
      setSelectedDayLabelModal(true)
                return;
              }
            } else {
              setWeekDayName('remove')
      setSelectedDayLabelModal(true)
              return;
            }
          }
          else {
            if (sessionMultipleType === 'daily') {
              
              let sessionCount;
              if(timeFrame?.months === 'day' && sessionMultipleType==='daily'){
                sessionCount = timeFrame?.number
              }
              else if(timeFrame?.months === 'weeks' && sessionMultipleType==='daily'){
                sessionCount = timeFrame?.number*7
              }
              else if(timeFrame?.months === 'months' && sessionMultipleType==='daily'){
                sessionCount = timeFrame?.number*31
              }
              else{
                sessionCount = sessionNumber * timeFrame?.number
              }
              if ((markedDates.length < sessionCount) || isMarked === true) {
              // if ((markedDates.length < Number(timeFrame?.number) * 7) || isMarked === true) {
              const today = new Date(calendarDate);
                const twoWeeksFromNow = new Date(calendarDate);
                twoWeeksFromNow.setDate(today.getDate() + 14);

                const selectedDate = new Date(date);
                const isWithinTwoWeeks = selectedDate >= today && selectedDate < twoWeeksFromNow;
                if (!isWithinTwoWeeks) {
                  setAddDateCalendar((prev) => {
                    const dateExists = prev.some((item) => isSameDate(stripTime(new Date(item.date)), strippedDate));
                    // const dateExists = prev.some((item) => new Date(item.date).toISOString() === new Date(date).toISOString());
                    console.log('dateExists', dateExists)
                    if (dateExists) {
                      // return prev.filter((item) => new Date(item.date).toISOString() !== new Date(date).toISOString());
                      const removedItem = prev.find(
                        (item) => isSameDate(stripTime(new Date(item.date)), strippedDate)
                        // (item) => new Date(item.date).toISOString() === new Date(date).toISOString()
                      );
                  
                      setAddDatesCancel((prevCancel) => {
                        const cancelExists = prevCancel.some(
                          (item) => isSameDate(stripTime(new Date(item.date)), strippedDate)
                          // (item) => new Date(item.date).toISOString() === new Date(date).toISOString()
                        );
                  
                        if (!cancelExists) {
                          return [
                            ...prevCancel,
                            { ...removedItem, isMarked: !removedItem.isMarked }, // Toggle or update `isMarked`
                          ];
                        }
                  
                        // Update `isMarked` if it already exists in `addDatesCancel`
                        return prevCancel.map((item) =>
                          isSameDate(stripTime(new Date(item.date)), strippedDate)
                          // new Date(item.date).toISOString() === new Date(date).toISOString()
                            ? { ...item, isMarked: !item.isMarked } // Toggle `isMarked`
                            : item
                        );
                      });
                      return prev.filter(
                        (item) => !isSameDate(stripTime(new Date(item.date)), strippedDate)
                        // (item) => new Date(item.date).toISOString() !== new Date(date).toISOString()
                      );
                    } else {
                      // return [...prev, { date: date, isMarked: isMarked, timeSlots: [{ startTime: '', endTime: '',duration:'' }] }];
                      const newEntry = {
                        date: date,
                        isMarked: isMarked,
                        timeSlots: [{ startTime: '', endTime: '' }],
                      };
                      setAddDatesCancel((prevCancel) => {
                        const cancelExists = prevCancel.some((item) =>
                          isSameDate(new Date(item.date), new Date(date))
                        );
                  
                        if (!cancelExists) {
                          return [...prevCancel, newEntry];
                        } else {
                          return prevCancel.map((item) =>
                            isSameDate(new Date(item.date), new Date(date))
                              ? { ...item, isMarked: isMarked }
                              : item
                          );
                        }
                      });
                  
                      return [...prev, newEntry];
                    }

                  })
                }
                else{
                  handleDateClickAddon(date, isMarked, 'erdate')
                  return;
                }

              }
              else {
                setWeekDayName('remove')
      setSelectedDayLabelModal(true)
                return;
              }
            }
            handleDateClickAddon(date, isMarked, 'erdate')
          }
        }
        if (types === 'first' && sessionMultipleType === 'daily') {
          if(timeFrame?.months !== 'day'){
          setSelectedDayLabel([
            { label: 'Monday', name: 'MO', dates: [{ startTime: '', endTime: '' ,duration:''}] },
            { label: 'Tuesday', name: 'TU', dates: [{ startTime: '', endTime: '',duration:'' }] },
            { label: 'Wednesday', name: 'WE', dates: [{ startTime: '', endTime: '' ,duration:''}] },
            { label: 'Thursday', name: 'TH', dates: [{ startTime: '', endTime: '',duration:'' }] },
            { label: 'Friday', name: 'FR', dates: [{ startTime: '', endTime: '',duration:'' }] },
            { label: 'Saturday', name: 'SA', dates: [{ startTime: '', endTime: '',duration:'' }] },
            { label: 'Sunday', name: 'SU', dates: [{ startTime: '', endTime: '',duration:'' }] }
          ])
        }else{
          setSelectedDayLabel([])
          const calculateUniqueDays = (startDate, daysToAdd) => {
            const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
          
            const start = new Date(startDate);
            const uniqueDates = new Set();
            const allWeekdays = []; 
          
            const results = Array.from({ length: daysToAdd })
              .map((_, i) => {
                const date = new Date(start);
                date.setDate(start.getDate() + i);
                return {
                  label: weekdays[date.getDay()], name: weekdays[date.getDay()].split('').slice(0,2).join('').toUpperCase(), dates: [{ startTime: '', endTime: '' ,duration:''}] 
                };
              })
              .filter(({ label }) => {
                if (uniqueDates.has(label)) return false;
                uniqueDates.add(label);
                return true;
              })
              .slice(0, daysToAdd);
          
            return results;
          };
          
          const result = calculateUniqueDays(date, Number(timeFrame?.number));
          
          setSelectedDayLabel(result)
          const calculateUniqueDayss = (startDate, daysToAdd) => {
            const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
          
            const start = new Date(startDate);
            const uniqueDates = new Set(); // To store unique weekdays
            const allWeekdays = [];        // To store all weekdays with repetitions
          
            // Generate all dates and categorize by weekdays
            const results = Array.from({ length: daysToAdd  }).map((_, i) => {
              const date = new Date(start);
              date.setDate(start.getDate() + i);
          
              const label = weekdays[date.getDay()];
              const name = weekdays[date.getDay()].slice(0, 2).toUpperCase();
          
              const dayObject = {
                label,
                name,
                dates: [{ startTime: '', endTime: '', duration: '' }],
              };
          
              // Add to unique dates if not already present
              if (!uniqueDates.has(label)) {
                uniqueDates.add(label);
              }
          
              // Add to all weekdays (including repetitions)
              allWeekdays.push(dayObject);
          
              return dayObject;
            });
          
            // Filter for unique weekdays only
            const uniqueWeekdays = Array.from(uniqueDates).map((label) =>
              results.find((day) => day.label === label)
            );
          
            return { uniqueWeekdays, allWeekdays };
          };
          const { uniqueWeekdays, allWeekdays } = calculateUniqueDayss(date,  Number(timeFrame?.number));
          // setSelectedDayLabel(uniqueWeekdays)
          setRepeatWeekDays(allWeekdays)
        }
        }
        if (sessionMultipleType === 'monthly') {
          const isDatePresent = addDatesCalendar && addDatesCalendar.length > 0 && addDatesCalendar.some(item => {
            const itemDate = new Date(item.date);
            return itemDate.getDate() === date.getDate();
          });

          if (addDatesCalendar?.length < Number(sessionNumber) || isDatePresent) {


            setAddDateCalendar((prev) => {
              const dateExists = prev.some((item) => isSameDate(stripTime(new Date(item.date)), strippedDate));
              // const dateExists = prev.some((item) => new Date(item.date).toISOString() === new Date(date).toISOString());
              console.log("prev>>>>>>>>>>",prev)
              
              console.log('dateExists', dateExists)
              if (dateExists) {
                // return prev.filter((item) => new Date(item.date).toISOString() !== new Date(date).toISOString());
                const removedItem = prev.find(
                  (item) => isSameDate(stripTime(new Date(item.date)), strippedDate)
                  // (item) => new Date(item.date).toISOString() === new Date(date).toISOString()
                );
            
                setAddDatesCancel((prevCancel) => {
                  const cancelExists = prevCancel.some(
                    (item) => isSameDate(stripTime(new Date(item.date)), strippedDate)
                    // (item) => new Date(item.date).toISOString() === new Date(date).toISOString()
                  );
            
                  if (!cancelExists) {
                    return [
                      ...prevCancel,
                      { ...removedItem, isMarked: !removedItem.isMarked }, // Toggle or update `isMarked`
                    ];
                  }
            
                  // Update `isMarked` if it already exists in `addDatesCancel`
                  return prevCancel.map((item) =>
                    isSameDate(stripTime(new Date(item.date)), strippedDate)
                    // new Date(item.date).toISOString() === new Date(date).toISOString()
                      ? { ...item, isMarked: !item.isMarked } // Toggle `isMarked`
                      : item
                  );
                });
                return prev.filter(
                  (item) => !isSameDate(stripTime(new Date(item.date)), strippedDate)
                  // (item) => new Date(item.date).toISOString() !== new Date(date).toISOString()
                );
              } else {
                // return [...prev, { date: date, isMarked: isMarked, timeSlots: [{ startTime: '', endTime: '',duration:'' }] }];
                const newEntry = {
                  date: date,
                  isMarked: isMarked,
                  timeSlots: [{ startTime: '', endTime: '' }],
                };
                setAddDatesCancel((prevCancel) => {
                  const cancelExists = prevCancel.some((item) =>
                    isSameDate(new Date(item.date), new Date(date))
                  );
            
                  if (!cancelExists) {
                    return [...prevCancel, newEntry];
                  } else {
                    return prevCancel.map((item) =>
                      isSameDate(new Date(item.date), new Date(date))
                        ? { ...item, isMarked: isMarked }
                        : item
                    );
                  }
                });
            
                return [...prev, newEntry];
              }
            })
          }
          else {
            setWeekDayName('maximum')
            setSelectedDayLabelModal(true)
            return;
          }
        }
        if (sessionMultipleType === 'monthly') {

        }
        if (types === 'first') {

          generateRecurrenceStrings(date, '', isMarked, types)
        } else {
          // handleDateClickAddon()
        }
      }

    }
  }

  const handleMorediv = (label) => {
    setMoreDiv((prevMoreDiv) => {
      const existingLabel = prevMoreDiv.find((item) => item.label === label);
      if (existingLabel) {
        return prevMoreDiv.map((item) =>
          item.label === label ? { ...item, count: item.count + 1 } : item
        );
      } else {
        return [...prevMoreDiv, { label: label, count: 1 }];
      }
    });
  }

  const handleUpdateTimeSlots = (date) => {
    setAddDateCalendar((prev) => {
      return prev.map((item) => {
        if (new Date(item.date).toISOString() === new Date(date).toISOString()) {
          // Add a new empty time slot to the timeSlots array
          const updatedTimeSlots = [...item.timeSlots, { startTime: '', endTime: '',duration:'' }];

          // Return the updated date object with the new time slot added
          return { ...item, timeSlots: updatedTimeSlots };
        }
        return item;
      });
    });
  };

  const handleRemoveAddDatesTimeSlot = (date, indexToRemove) => {
    setAddDateCalendar((prev) => {
      return prev.map((item) => {
        if (new Date(item.date).toISOString() === new Date(date).toISOString()) {
          // Remove the time slot at the specified index
          const updatedTimeSlots = item.timeSlots.filter((_, i) => i !== indexToRemove);

          // Return the updated date object with the modified timeSlots array
          return { ...item, timeSlots: updatedTimeSlots };
        }
        return item;
      });
    });
  };

  const handleUpdateSelectedTimeSlots = (label) => {
    setSelectedDayLabel((prev) => {
      return prev.map((item) => {
        if (item?.label === label) {
          const updatedTimeSlots = [...item.dates, { startTime: '', endTime: '',duration:'' }];

          return { ...item, dates: updatedTimeSlots };
        }
        return item;
      });
    });
  };

  const handleRemoveSelectedTimeSlot = (label, indexToRemove) => {
    setSelectedDayLabel((prev) => {
      return prev.map((item) => {
        if (item?.label === label) {
          const updatedTimeSlots = item.dates.filter((_, i) => i !== indexToRemove);

          return { ...item, dates: updatedTimeSlots };
        }
        return item;
      });
    });
  };

  const handleTImeSession = (type) => {
    setSessionRepeat(type)
    setDayLabel([])
    setSelectedDayLabel([])
    if (type === 'weekday') {
      setDayLabel(['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'])
      setSelectedDayLabel([
        { label: 'Monday', name: 'MO', dates: [{ startTime: '', endTime: '',duration:'' }] },
        { label: 'Tuesday', name: 'TU', dates: [{ startTime: '', endTime: '' ,duration:''}] },
        { label: 'Wednesday', name: 'WE', dates: [{ startTime: '', endTime: '',duration:'' }] },
        { label: 'Thursday', name: 'TH', dates: [{ startTime: '', endTime: '',duration:'' }] },
        { label: 'Friday', name: 'FR', dates: [{ startTime: '', endTime: '',duration:'' }] }
      ])
    }
    if (type === 'weekend') {
      setDayLabel(['Saturday', 'Sunday'])
      setSelectedDayLabel([
        { label: 'Saturday', name: 'SA', dates: [{ startTime: '', endTime: '',duration:'' }] },
        { label: 'Sunday', name: 'SU', dates: [{ startTime: '', endTime: '',duration:'' }] }
      ])
    }
  }

  const handleChangeData = (type, e) => {
    setAvailabilityPeriod(type)
    setTypeValue({ [type]: e?.target?.value })
  }

  const convertTo24HourSame = (time) => {
    if (!time || typeof time !== 'string') {
      console.error('Invalid time format:', time);
      return NaN;
    }
    const trimmedTime = time.trim().toUpperCase();
    const [timePart, period] = trimmedTime.split(' ');
    if (!timePart || !period) {
      console.error('Invalid time format:', time);
      return NaN;
    }
  
    const [hourStr, minuteStr] = timePart.split(':');
    const hour = Number(hourStr);
    const minute = Number(minuteStr);
  
    if (isNaN(hour) || isNaN(minute)) {
      console.error('Invalid time components:', hour, minute);
      return NaN;
    }
  
    const isPM = period === 'PM';
    return isPM ? (hour % 12) + 12 + minute / 60 : (hour % 12) + minute / 60;
  };

  const handleInputChange = (index, field, value, typenumber, typeCalendar, date, ind) => {
    const isTimeSequenceValid = (slots, currentIndex, field, newValue) => {
      // const { startTime, endTime } = slots[currentIndex];
      // if (field === 'startTime') {
      //   if(endTime ==''){
      //     return true
      //   } else{
      //   return newValue < endTime; // startTime should be before endTime
      //   }
      // } else if (field === 'endTime') {
      //   return startTime < newValue; // endTime should be after startTime
      // }
      // return true;
      const currentSlot = slots[currentIndex];
  const valueTime = convertTo24HourSame(newValue);

  const slotStart = convertTo24HourSame(currentSlot.startTime);
  const slotEnd = convertTo24HourSame(currentSlot.endTime);

  if (field === 'startTime') {
    if (currentSlot.endTime == '') {
          return true
        } else {
    return valueTime < slotEnd;
    }
  } else if (field === 'endTime') {
    return slotStart < valueTime;
  }
  return true;
    };
  
    const isValueValid = (slots, newValue, currentIndex) =>
      !slots.some(
        (slot, i) =>
          i !== currentIndex &&
          slot.startTime < newValue &&
          newValue < slot.endTime
      );
  
    const selectedArray = typeCalendar === 'addon' ? addOnDay : selectedDayLabel;
    const dayData = selectedArray.find((d) => d.day === date || d.label === date);
    if (!dayData) return;
  
    const previousValue = dayData.timeSlots ? dayData.timeSlots[ind][field] : dayData.dates[ind][field];
    if (previousValue === value) return;
  
    const slots = dayData.timeSlots || dayData.dates;
  
    if (!isTimeSequenceValid(slots, ind, field, value)) {
      setWeekDayName('endTimeLess');
      setSelectedDayLabelModal(true);
      return;
    }
    if (!isValueValid(slots, value, ind)) {
      setWeekDayName('sameTime');
      setSelectedDayLabelModal(true);
      return;
    }
    if (typeCalendar === 'addon') {
      setAddOnDay(prevState => {
        return prevState.map(item => {
          if (item.day=== date) {
            const updatedTimeSlots = item.timeSlots.map((slot, i) => {
              if (i === ind) {
                return { ...slot, [field]: value };
              }
              return slot;
            });

            return { ...item, timeSlots: updatedTimeSlots };
          }
          return item;
        });
      });
      return true
    }
    else {
      setSelectedDayLabel(prevState => {
        return prevState.map(item => {
          if (item?.label === date) {
            const updatedTimeSlots = item.dates.map((slot, i) => {
              if (i === ind) {
                return { ...slot, [field]: value };
              }
              return slot;
            });

            return { ...item, dates: updatedTimeSlots };
          }
          return item;
        });
      });
      return true
    }
  };


  const handleNext = async(type) => {
    const values = {
      sessionType: sessionType,
      specifyTime: specifyTime,
      sessionRepeat: sessionRepeat,
      recurrences: recurrences,
      availabilityPeriod: availabilityPeriod,
      typeValue: typeValue,
      classMode: data?.classMode || 'online',
      sameTime: sameTime,
      sessionPackage: sessionNumber,
      timeFrame: timeFrame,
      sessionMultipleType: sessionMultipleType,
      selectedDayLabel: selectedDayLabel,
      dayLabel: dayLabel,
      sessionDuration: sessionDuration,
      addDatesCalendar:addDatesCalendar,
      sameTimeDuratioon:sameTimeDuratioon,
      calendarDate:calendarDate,
      showManage:showManage,
      manageCalendar:manageCalendar,
      addOnDay:addOnDay,
      exdates:exdates,
      rdates:rdates,
      repeatWeekDays:repeatWeekDays,
      step:step
    };
    if(type=== 'prev'){
      dispatch(insertOfferingData(values))
      prevStep()
    }
    else{
    if (validateForm()) {
      if(!manageCalendar && showManage){
        dispatch(insertOfferingData(values))
      if (type === 'draft') {
        // submitForm('draft', values)
        setStateModal(true)
        // if(type === 'product'){
    
        // } else{
          const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
          await delay(1000)
        const resp = await submitForm('draft',values)
        if(resp === true){
          setStateModal(false)
          setIsModal(true)
        } else{
          setStateModal(false)
        }
      }
      else {
        nextStep();
      }
    } else{
      setWeekDayName('required')
      setSelectedDayLabelModal(true)
    }
    }
  }
  }

  const isDateMarked = (formattedDate) => {
    return addDatesCalendar && addDatesCalendar.some(item => {
      console.log("adddddddddddddddddddd");

      // const formattedItemDate = item?.dat;
      const dateLocal = new Date(item?.date)
      const formattedItemDate = dateLocal?.toLocaleDateString('en-CA');
      return formattedItemDate === formattedDate;
    });
  };


  const handleDateClickAddon = (date, isMarked, typess) => {
    // if(sessionType === 'multiple_session'){
    //   generateRecurrenceStrings()
    // }
    // else{
    // if (sessionType !== 'multiple_session') {
    //   setManageCalendar(true)
    // }
    // const formattedDate = date.toLocaleDateString('en-CA'); // Format as YYYY-MM-DD
    let updatedDatess = [];
    // let updatedDatess = [...addDates];
    let rdatesAdd = [];
    let exdatesAdd = [];
    let upDate = [];
    let upDayWeek = [];
    let upIsmarked = [];
    addDatesCalendar && addDatesCalendar.length > 0 && addDatesCalendar.forEach((x, index) => {

const dateLocal = new Date(x?.date)
      let dayOfWeek = dateLocal.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase(); // Get the day of the week in 'SU', 'MO', etc.
      dayOfWeek = dayOfWeek.slice(0, 2);
      upDate.push(x?.date)
      upDayWeek.push(dayOfWeek)
      upIsmarked.push(x?.isMarked)
      // let updatedDatess = [...addDates];
      // if (!weekDay.includes(dayOfWeek)) {


      if (x?.isMarked === false || x?.isMarked === true) {
        const startDate = new Date(x?.date).getDate();
        const fullyear = new Date(x?.date).getFullYear();
        const month = new Date(x?.date).getMonth();
        let newRuleStrings;
        // addDates.map()
        let newRule;
        const validDate = new Date(x?.date);
        if (isNaN(validDate)) {
          console.error("Invalid date provided:", x?.date);
          return; // Exit the function if the date is invalid
        }
        const getDayNum = validDate.getDay();
        const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const getDay = dayNames[getDayNum];
        if (sessionMultipleType === 'monthly') {
          // newRule = {
          //   freq: RRule.MONTHLY,
          //   dtstart: new Date(Date.UTC(fullyear, month, startDate, 0, 0)),
          //   // dtstart: validDate,
          //   bysetpos: monthRepeatValue,
          //   // byweekday: [dayMap[getDay]],
          //   count: 1,
          // };
          const eventDate = new Date(x?.date);
          const dayOfMonth = eventDate.getDate(); // Get the day of the month from the start date
          const dayOfWeek = eventDate.toLocaleDateString('en-US', { weekday: 'long' }); 
          const dayMap = {
            Sunday: RRule.SU,
            Monday: RRule.MO,
            Tuesday: RRule.TU,
            Wednesday: RRule.WE,
            Thursday: RRule.TH,
            Friday: RRule.FR,
            Saturday: RRule.SA,
          };
          // const getOccurrenceOfWeekday = (date) => {
          //   let day = date.getDay();
          //   let weekOfMonth = Math.ceil((date.getDate() + (7 - day)) / 7) ;
          //   return weekOfMonth;
          // };
          const getOccurrenceOfWeekday = (date) => {
            let firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
            let firstDayOfWeek = firstDayOfMonth.getDay();
            firstDayOfWeek = (firstDayOfWeek === 0) ? 7 : firstDayOfWeek;
            let dayOfMonth = date.getDate();
            let dayOfWeek = date.getDay();
            dayOfWeek = (dayOfWeek === 0) ? 7 : dayOfWeek;
            let firstWeekdayInMonth = (7 - firstDayOfWeek + dayOfWeek) % 7;
            // let occurrence = Math.ceil((dayOfMonth - (7 - dayOfWeek + firstDayOfWeek) % 7) / 7);
            let occurrence = Math.ceil((dayOfMonth - firstWeekdayInMonth) / 7);
            return occurrence;
        };
          const weekdayOccurrence = getOccurrenceOfWeekday(eventDate); 
          
          newRule = {
            // freq: RRule.MONTHLY,     // Repeat every month
            // dtstart: eventDate,      // Start date of the recurrence
            // bymonthday: dayOfMonth,  // Repeat on this exact date every month
            // count: Number(timeFrame?.number) || 1 //Number(sessionNumber),
            freq: RRule.MONTHLY,
          byweekday: [dayMap[dayOfWeek]],   // Repeat on this weekday (e.g., 'MO' for Monday)
          bysetpos: [weekdayOccurrence],   // Set occurrence (e.g., 2nd Monday)
          dtstart: eventDate,
          count: Number(timeFrame?.number) || 1,
          };
        } else {
          newRule = {
            freq: RRule.WEEKLY,
            dtstart: new Date(Date.UTC(fullyear, month, startDate, 0, 0)),
            // dtstart: validDate,
            // byweekday: [dayMap[getDay]],
            count: 1
          };
        }
        const rrus = new RRuleSet();
        rrus.rrule(new RRule(newRule));
        let newRuleString;
        
        let updatedTimeDurationss;
        if(!sameTime){
        let dayOfWeek = dateLocal.toLocaleDateString('en-US', { weekday: 'long' }).toUpperCase();
        let dayOfWeeks = dateLocal.toLocaleDateString('en-US', { weekday: 'long' });
        let existingDay = addOnDay.find(day => day.day === dayOfWeek);
        let existingDays = selectedDayLabel.find(day => day.label === dayOfWeeks)
        if(existingDay !== undefined){
        updatedTimeDurationss = existingDay?.timeSlots.map((timeSlot) => {
          return {
            ...timeSlot,
            startTime: convertTo24Hour(timeSlot.startTime) || '',
            endTime: convertTo24Hour(timeSlot.endTime) || ''
          };
        });
      } else{
        updatedTimeDurationss = existingDays?.dates.map((timeSlot) => {
          return {
            ...timeSlot,
            startTime: convertTo24Hour(timeSlot.startTime) || '',
            endTime: convertTo24Hour(timeSlot.endTime) || ''
          };
        });
      }
      }
        if(sameTime){
          updatedTimeDurationss = [{
              startTime: convertTo24Hour(sameTimeDuratioon[0]?.startTime) || '',
              endTime: convertTo24Hour(sameTimeDuratioon[0]?.endTime) || ''
            }];
        }
        newRuleString = {
          dayOfWeek: dayOfWeek,
          sessionDuration: 0,
          timeSlots: updatedTimeDurationss,
          rruleString: rrus.toString(),
        };
        const uniqueDates = addDates.reduce((acc, current) => {
          const x = acc.find(item => item.rruleString === current.rruleString);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);

        let newRRuleString = uniqueDates.length > 0 ? [...uniqueDates] : [];
        console.log('updatedDatess &--------------', updatedDatess)
        console.log("markedDates*******", markedDates);
        console.log("updatedDates>>>>>>>>>>>>>>>", updatedDatess)
        console.log("newRRuleString after adding:", updatedDatess);
        if (sessionType === 'single_session') {
          updatedDatess.push(newRuleString)
        }
        if (sessionType === 'multiple_session') {
          // if(sessionMultipleType!=='monthly'){
          updatedDatess.push(newRuleString)
          // }
        }
      }

      if (sessionType === 'single_session') {

        updatedDatess = updatedDatess && updatedDatess?.length > 0 ? updatedDatess : addDates
      }

      console.log("updatedDatess55555555555", updatedDatess);
      rdatesAdd = rdatesAdd && rdatesAdd?.length > 0 ? rdatesAdd : rdates
      exdatesAdd = exdatesAdd && exdatesAdd?.length > 0 ? exdatesAdd : exdates

    })

    if (typess === 'erdate') {
      if (isMarked === false) {
        if(sessionMultipleType === 'daily'){
          if (markedDates.length < (Number(timeFrame?.number)*7)) {
            setRdates((prevRdates) => {
              console.log('date', date)
              // Add date to Rdates if not already present
              const updatedRdates = [...prevRdates, date];
  
              // Remve any duplicate dates by comparing the time value
              const finalRdates = updatedRdates.filter((d, index, self) =>
                index === self.findIndex((t) => t.getTime() === d.getTime())
              );
  
              // Also update Exdates by removing the date if it was there
              setExdates((prevExdates) => prevExdates && prevExdates.filter((d) => d.getTime() !== date.getTime()));
              setRAddDatesCancel((prevCancel) => {
                const cancelExists = prevCancel.some((item) =>
                  isSameDate(new Date(item.date), new Date(date))
                );
            
                if (!cancelExists) {
                  // Add new entry to addDatesCancel
                  return [...prevCancel, { date, isMarked: isMarked }];
                } else {
                  // If date exists, return previous state
                  return prevCancel;
                }
              });
              rdatesAdd = finalRdates
              return finalRdates;
            });
          }
          else {
            setWeekDayName('remove')
      setSelectedDayLabelModal(true)
            // alert('first remove the recurrence');
            return;
          }
        }
        else{

        if (exdates?.length > 0 && markedDates.length < (sessionNumber * timeFrame?.number)) {
          setRdates((prevRdates) => {
            console.log('date', date)
            // Add date to Rdates if not already present
            const updatedRdates = [...prevRdates, date];

            // Remve any duplicate dates by comparing the time value
            const finalRdates = updatedRdates.filter((d, index, self) =>
              index === self.findIndex((t) => t.getTime() === d.getTime())
            );

            // Also update Exdates by removing the date if it was there
            setExdates((prevExdates) => prevExdates && prevExdates.filter((d) => d.getTime() !== date.getTime()));
            setRAddDatesCancel((prevCancel) => {
              const cancelExists = prevCancel.some((item) =>
                isSameDate(new Date(item.date), new Date(date))
              );
          
              if (!cancelExists) {
                // Add new entry to addDatesCancel
                return [...prevCancel, { date, isMarked: isMarked }];
              } else {
                // If date exists, return previous state
                return prevCancel;
              }
            });
            rdatesAdd = finalRdates
            return finalRdates;
          });
        }
        else {
          setWeekDayName('remove')
      setSelectedDayLabelModal(true)
          // alert('first remove the recurrence');
          return;
        }
      }

      }
      if (isMarked === true) {
        setExdates((prevExdates) => {
          // Add date to Exdates if not already present
          const updatedExdates = [...prevExdates, date];

          // Remove any duplicate dates by comparing the time value
          const finalExdates = updatedExdates.filter((d, index, self) =>
            index === self.findIndex((t) => t.getTime() === d.getTime())
          );

          // Also update Rdates by removing the date if it was there
          setRdates((prevRdates) => prevRdates && prevRdates.filter((d) => d.getTime() !== date.getTime()));
          setExAddDatesCancel((prevCancel) => {
            const cancelExists = prevCancel.some((item) =>
              isSameDate(new Date(item.date), new Date(date))
            );
        
            if (!cancelExists) {
              // Add new entry to addDatesCancel
              return [...prevCancel, { date, isMarked: isMarked }];
            } else {
              // If date exists, return previous state
              return prevCancel;
            }
          });
          exdatesAdd = finalExdates
          return finalExdates;
        });

      }
      rdatesAdd = rdatesAdd && rdatesAdd?.length > 0 ? rdatesAdd : rdates
      exdatesAdd = exdatesAdd && exdatesAdd?.length > 0 ? exdatesAdd : exdates
    }

    const newRRuleString = generateRecurrenceStrings(upDate, upDayWeek, upIsmarked, 'single', updatedDatess, rdatesAdd, exdatesAdd);
    // const newRRuleString = generateRecurrenceStrings(x?.date, dayOfWeek, x?.isMarked, 'single', updatedDatess, rdatesAdd, exdatesAdd);
    console.log('newRRuleString', newRRuleString)
    setKey(prevKey => prevKey + 1);
  };

  const handleTimeSlot = async(types) => {
if(sessionMultipleType === 'monthly'){
  if(markedDates?.length < (Number(sessionNumber)*Number(timeFrame?.number))){
    setWeekDayName('dayCustom')
    setSelectedDayLabelModal(true)
    setCountMark((Number(sessionNumber)*Number(timeFrame?.number)))
    return;
  }
  if(addDatesCalendar && addDatesCalendar.length !== Number(sessionNumber)){
    setWeekDayName('specifyDate')
    setSelectedDayLabelModal(true)
    return;
  }
}
if(sessionType === 'single_session'){
  if(addDatesCalendar && addDatesCalendar.length <=0){
    setWeekDayName('firstDate')
    setSelectedDayLabelModal(true)
    // alert('first select the date')
    return;
  }
}
if(sessionMultipleType === 'weekly'){
  if(markedDates?.length < (Number(timeFrame?.number)*Number(sessionNumber))){
    setWeekDayName('dayCustom')
    setSelectedDayLabelModal(true)
    setCountMark(Number(timeFrame?.number)*Number(sessionNumber))
    return;
  }
}
if(sessionMultipleType === 'daily'){
// if(sessionMultipleType === 'daily' || sessionMultipleType === 'weekly'){
let occurencesLength;
if(timeFrame?.months === 'day'){
   occurencesLength = Number(timeFrame?.number)
}
if(timeFrame?.months === 'weeks'){
   occurencesLength = Number(timeFrame?.number) * 7
}
if(timeFrame?.months === 'months'){
   occurencesLength = Number(timeFrame?.number) * 30
}
  if(markedDates?.length < occurencesLength){
    setWeekDayName('dayCustom')
    setSelectedDayLabelModal(true)
    setCountMark(occurencesLength)
    return;
  }
  if(calendarDate === ''){
    setWeekDayName('date')
      setSelectedDayLabelModal(true)
    return;
  }
}

    if (sameTime && sameTimeDuratioon[0]?.startTime === '' && sameTimeDuratioon[0]?.endTime === '' && sessionMultipleType !== 'monthly' ) {
      setWeekDayName('')
      setSelectedDayLabelModal(true)
      return;
    }
    if (sameTime && (sameTimeDuratioon[0]?.startTime === '' || sameTimeDuratioon[0]?.endTime === '' || sameTimeDuratioon[0]?.startTime ==='00:00 PM' || sameTimeDuratioon[0]?.startTime ==='00:00 AM' || sameTimeDuratioon[0]?.endTime ==='00:00 PM' || sameTimeDuratioon[0]?.endTime ==='00:00 AM')) {
      setWeekDayName('')
      setSelectedDayLabelModal(true)
      return;
    }

    if (!sameTime && addOnDay && addOnDay.length > 0) {
      for (let i = 0; i < addOnDay.length; i++) {
        const x = addOnDay[i];
        if (x?.timeSlots[0]?.startTime === '' || x?.timeSlots[0]?.endTime == '' || x?.timeSlots[0]?.startTime ==='00:00 PM' || x?.timeSlots[0]?.startTime ==='00:00 AM' || x?.timeSlots[0]?.endTime ==='00:00 PM' || x?.timeSlots[0]?.endTime ==='00:00 AM') {
        // if (x?.timeSlots[0]?.startTime === '' || x?.timeSlots[0]?.duration == '') {
          setWeekDayName('')
          setSelectedDayLabelModal(true)
          return;
        }
      }
    }

    if (!sameTime && selectedDayLabel && selectedDayLabel.length > 0) {
      for (let i = 0; i < selectedDayLabel.length; i++) {
        const x = selectedDayLabel[i];
        // { label: label, name: name, dates: [{ startTime: '', endTime: '' }] }
        if (x?.dates[0]?.startTime === '' || x?.dates[0]?.endTime == '' || x?.dates[0]?.startTime ==='00:00 PM' || x?.dates[0]?.startTime ==='00:00 AM' || x?.dates[0]?.endTime ==='00:00 PM' || x?.dates[0]?.endTime ==='00:00 AM') {
          setWeekDayName(x?.label)
      setSelectedDayLabelModal(true)
          return;
        }
      }
    }

    // { date: date, isMarked: isMarked, startTime: '', endTime: '' }
    if (sessionType === 'single_session') {
      handleDateClickAddon()
    }

    if (sessionType === 'multiple_session') {
      if (types === 'manage') {
        handleDateClickAddon();
      } else if (sessionMultipleType === 'monthly') {
        handleDateClickAddon()
      }
      else {
        if(addDatesCalendar?.length >0){
        handleDateClickAddon()
        } else{
        generateRecurrenceStrings(calendarDate)
        }
      }
    }

      handleNext(types)
  };

  const handleAddTimeSlot = () => {
    setSameTimeDuration([...sameTimeDuratioon, { startTime: '', endTime: '',duration:'' }]);
  };

  const handleRemoveTimeSlot = (index) => {
    setSameTimeDuration(sameTimeDuratioon.filter((_, i) => i !== index));
  };


  const handleSameTimeChange = (index, field, value) => {
    const isTimeSequenceValid = (slots, currentIndex, field, newValue) => {
      // const { startTime, endTime } = slots[currentIndex];
      // if (field === 'startTime') {
      //   if(endTime ==''){
      //     return true
      //   } else{
      //   return newValue < endTime; // startTime should be before endTime
      //   }
      // } else if (field === 'endTime') {
      //   return startTime < newValue; // Current startTime must be less than new endTime
      // }
      // return true;
      const currentSlot = slots[currentIndex];
  const valueTime = convertTo24HourSame(newValue);

  const slotStart = convertTo24HourSame(currentSlot.startTime);
  const slotEnd = convertTo24HourSame(currentSlot.endTime);

  if (field === 'startTime') {
    if (currentSlot.endTime == '') {
          return true
        } else {
    return valueTime < slotEnd;
    }
  } else if (field === 'endTime') {
    return slotStart < valueTime;
  }
  return true;
    };
    const isValueValid = (slots, newValue, currentIndex) =>
      !slots.some(
        (slot, i) =>
          i !== currentIndex && 
          slot.startTime < newValue && newValue < slot.endTime
      );
  
    const previousValue = sameTimeDuratioon[index][field];
    if (previousValue === value) {
      return;
    }
    if (!isTimeSequenceValid(sameTimeDuratioon, index, field, value)) {
      setWeekDayName('endTimeLess');
      setSelectedDayLabelModal(true);
      return;
    }
  
    if (!isValueValid(sameTimeDuratioon, value, index)) {
      setWeekDayName('sameTime');
      setSelectedDayLabelModal(true);
      return;
    }
    const updatedTimeSlots = sameTimeDuratioon.map((slot, i) =>
      i === index ? { ...slot, [field]: value } : slot
    );
    setSameTimeDuration(updatedTimeSlots);
    setSelectedDayLabel((prevState) =>
      prevState.map((item) => {
        const updatedDates = item.dates.map((slot, i) =>
          i === index ? { ...slot, [field]: value } : slot
        );
        return { ...item, dates: updatedDates }; // Return updated item
      })
    );
    setAddOnDay((prevState) =>
      prevState.map((item) => {
        const updatedDates = item.timeSlots.map((slot, i) =>
          i === index ? { ...slot, [field]: value } : slot
        );
        return { ...item, timeSlots: updatedDates }; // Return updated item
      })
    );
    return true;
  };

  const handleActiveStartDateChange = useCallback(({ activeStartDate }) => {
    setActiveStartDate(activeStartDate);
  }, []);

  const handleAddDateRemove = () =>{
    // setAddDateCalendar((prevCalendar) =>
    //   prevCalendar.filter(
    //     (calendarItem) =>
    //       !addDatesCancel.some((cancelItem) =>
    //         new Date(calendarItem.date).toISOString() === 
    //         new Date(cancelItem.date).toISOString()
    //       )
    //   )
    // );
    setAddDateCalendar((prevCalendar) => {
      if (prevCalendar.length === 0) {
        const updatedCalendar = addDatesCancel.map((cancelItem) => ({
          date: cancelItem.date,
          isMarked: false,
        }));
        return updatedCalendar;
      }
      let updatedCalendar = [...prevCalendar]; // Make a copy of the previous calendar
    
      addDatesCancel.forEach((cancelItem) => {
        const cancelDate = cancelItem.date;
        const isDateInCalendar = prevCalendar.some((calendarItem) =>
          new Date(calendarItem.date).toISOString() === new Date(cancelDate).toISOString()
        );
    
        if (cancelItem.isMarked && isDateInCalendar) {
          // If the date is marked in addDatesCancel and not already in addDatesCalendar, add it
          updatedCalendar.push({
            date: cancelDate,
            isMarked: false, // Set isMarked to false when adding
          });
        } else if (!cancelItem.isMarked && isDateInCalendar) {
          // If the date is not marked and is present in the calendar, remove it
          updatedCalendar = updatedCalendar.filter(
            (calendarItem) => new Date(calendarItem.date).toISOString() !== new Date(cancelDate).toISOString()
          );
        }
      });
    
      return updatedCalendar;
    });
    if (exdates.length > 0) {
      const updatedExDate = exdates.filter(date => {
        const formattedExDate = new Date(date).toISOString();
        return !addRexDatesCancel.some(cancelItem =>
          new Date(cancelItem.date).toISOString() === formattedExDate
        );
      });
      setExdates(updatedExDate);
    }
    if (rdates.length > 0) {
      const updatedRDate = rdates.filter(date => {
        const formattedExDate = new Date(date).toISOString();
        return !addRexDatesCancel.some(cancelItem =>
          new Date(cancelItem.date).toISOString() === formattedExDate
        );
      });
      setRdates(updatedRDate);
    }
    // setAddDateCalendar((prevCalendar) => {
    //   const updatedCalendar = [...prevCalendar];
    
    //   addDatesCancel.forEach((cancelItem) => {
    //     if (cancelItem.isMarked && !prevCalendar.some((calendarItem) =>
    //         new Date(calendarItem.date).toISOString() === new Date(cancelItem.date).toISOString())) {
          
    //       updatedCalendar.push({
    //         date: cancelItem.date,
    //         isMarked: false, 
    //       });
    //     }
    //   });
    //   return updatedCalendar;
    // });
  }

  const updateReduxData = (updatedValues) => {
    dispatch(insertOfferingData(updatedValues));
  };

  useEffect(() => {
    if (data?.editype === 'edit') {
      const updatedData = {
        sessionType: sessionType,
        specifyTime: specifyTime,
        sessionRepeat: sessionRepeat,
        recurrences: recurrences,
        availabilityPeriod: availabilityPeriod,
        typeValue: typeValue,
        classMode: data?.classMode || 'online',
        sameTime: sameTime,
        sessionPackage: sessionNumber,
        timeFrame: timeFrame,
        sessionMultipleType: sessionMultipleType,
        selectedDayLabel: selectedDayLabel,
        dayLabel: dayLabel,
        sessionDuration: sessionDuration,
        addDatesCalendar:addDatesCalendar,
        sameTimeDuratioon:sameTimeDuratioon,
        calendarDate:calendarDate,
        showManage:showManage,
        manageCalendar:manageCalendar,
        addOnDay:addOnDay,
        exdates:exdates,
        rdates:rdates,
        repeatWeekDays:repeatWeekDays,
      };

      updateReduxData(updatedData);
    }
  }, [
    sessionType,
    specifyTime,
    sessionRepeat,
    recurrences,
    availabilityPeriod,
    typeValue,
    sameTime,
    sessionNumber,
    timeFrame,
    sessionMultipleType,
    selectedDayLabel,
    dayLabel,
    sessionDuration,
    addDatesCalendar,
    sameTimeDuratioon,
    calendarDate,
    showManage,
    manageCalendar,
    exdates,
    rdates,
    addOnDay,
    repeatWeekDays
  ]);

  return (
    <div>
      <UserSidebar classState={classState} />
      <div className='right-main'>
        <div className="session step-2 session-cs ">
          {type!=='edit'&&
          <>
          <div className="header">
            <h1><button class="burger-menu" onClick={handleSidebarClick}><img src={burger} alt='' /></button> Session or Service</h1>
            <div className="top-buttons">
              <button className="save-draft" onClick={() => handleTimeSlot('draft')} disabled={stateModal}>{stateModal && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Save to Draft</button>
              {/* <button className="save-draft" onClick={() => handleNext('draft')} disabled={stateModal}>{stateModal && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Save to Draft</button> */}
              <button className="next" onClick={()=>handleTimeSlot('')}>Next</button>
              {/* <button className="next" onClick={handleNext}>Next</button> */}
            </div>
          </div>
          <div class="active-links">

            <button className="back" onClick={() => handleNext('prev')}><img src={leftArrow} alt='' /> </button>

            <ActiveLinks step={step} />
          </div>
          </>
}




          <div className="main  session-spacing session-spacing-group">
            <div className="row">

              <div className="col-sm-7">
              <h1 className='define-offer'>Select session dates and times</h1>
                <div className='define-left for-label-cs'>
                  <div className='define-top error-fixs'>
         
             
                    <h3>How would you like to package your offering?</h3>
                    {/* {(sessionType === 'single_session' || data?.editype !== 'edit') && */}
                      <div className='session'>
                        <label class="session-check" onClick={() => { setSessionType('single_session'); setRecurrences([]); setTimeFrame({ number: '', months: '' }); setMarkedDates([]); setDayLabel([]); setAddOnDates(''); setAddDates([]); setAddDateCalendar([]); setShowManage(false); setManageCalendar(false); setSelectedDayLabel([]); setSessionDuration(0); setExdates([]); setRdates([]); setSessionMultipleType(''); setMarkedDates([]); }}>
                          <input type="radio" checked={sessionType === 'single_session'} name="session-type" onChange={() => setSessionType('single_session')} />
                          <span class="checkmark"></span>
                        </label>
                        <div className='session-check-text' >
                          <h4>Single Session </h4>
                          <p>Allow users to book one session at a time.</p>
                        </div>
                      </div>
                    {/* }

                    {(sessionType === 'multiple_session' || data?.editype !== 'edit') && */}
                      <div className='session'>
                        <label class="session-check" onClick={() => { setSessionType('multiple_session'); setRecurrences([]); setTimeFrame({ number: '', months: '' }); setMarkedDates([]); setAddDates([]); setAddOnDates(''); setAddDates([]); setAddDateCalendar([]); setShowManage(false); setManageCalendar(false); setSelectedDayLabel([]); setSessionDuration(0); setExdates([]); setRdates([]); setSessionMultipleType(''); setMarkedDates([]); }}>
                          <input type="radio" checked={sessionType === 'multiple_session'} name="session-type" onChange={() => setSessionType('multiple_session')} />
                          <span class="checkmark"></span>
                        </label>
                        <div className='session-check-text' >
                          <h4>Multiple Sessions</h4>
                          <p>Offer a package of sessions (e.g., weekly, monthly, or a set number of sessions).</p>
                        </div>
                      </div>

                    {/* } */}
                    {errors.sessionType && <div className="error">{errors.sessionType}</div>}
                  </div>

                  {sessionType === 'multiple_session' &&
                    <div className='define-top error-fixs pt-2 difine-top-cs'>
                      <h3 className='pt-3 mb-2' >What type package do you offer</h3>

                      <div className='session'>
                        <label class="session-check" onClick={() => { setSessionMultipleType('daily'); setRecurrences([]); setTimeFrame({ number: '', months: '' }); setMarkedDates([]); setDayLabel([]); setAddDates([]); setAddDateCalendar([]); setSessionDuration(0); setSelectedDayLabel([]); setShowManage(false); setManageCalendar(false); setSameTimeDuration([{ startTime: '', endTime: '',duration:'' }]); setWeekDay([]); setExdates([]); setRdates([]);setCalendarDate('');setTimeFrame(prevState => ({ ...prevState, months: 'weeks' })); }}>
                          <input type="radio" checked={sessionMultipleType === 'daily'} name="mulsession-type" onChange={() => setSessionMultipleType('daily')} />
                          <span class="checkmark"></span>
                        </label>
                        <div className='session-check-text' >
                          <h4>Daily Package</h4>
                          {sessionMultipleType === 'daily' &&
                            <>
                              <p>Session will happen every day</p>
                              <div className='weekly-main pt-1'>
                                <h3 className='mt-2 mb-0'>Specify timeframe of the package. *</h3>
                                <p className='py-1'>Overall period during which the session are offered</p>
                                {/* <input type="text" placeholder='2 Times' value={sessionNumber} onChange={(e) => setSessionNumber(e?.target?.value)} />
                                  {errors.sessionNumber && <div className="error">{errors.sessionNumber}</div>} */}
                                  <div className='d-flex'>
                                  <div className=''>
                                <input type="number" className={`daily-input ${timeFrame?.number && 'dailyinputenter'}` } placeholder='2' value={timeFrame?.number} 
                                // onChange={(e) => {e?.target?.value >0 ? setTimeFrame(prevState => ({ ...prevState, number: e.target.value })) : setTimeFrame(prevState => ({ ...prevState, number: '' }))}} 
                                onChange={(e) =>{const value = e.target.value;  // Parse input value as an integer
                                  if (value > 0 && value < 100) {
                                    setTimeFrame(prevState => ({ ...prevState, number: e.target.value }))
                                  } else if (value == 0) {
                                    setTimeFrame(prevState => ({ ...prevState, number: '' }))
                                  } else {
                                    setTimeFrame(prevState => ({ ...prevState, number: timeFrame?.number }))
                                  }}}
                                />
                                </div>
                                <span className='arrow'></span>
                                {/* <input type="text" className='daily-input daily-input-month' placeholder='Weeks' value={timeFrame?.months} /> */}
                                <div className='selct-weklys select-dayas-months'>
                                <div className="dropdown oper-drop-down-cs daily-weeks-selct-ontime" onClick={()=>setDayDropdown(!dayDropdown)} ref={dropdownPackageRef}>
                      <input placeholder="Day" class={`daily-weeks-selct-ontime ${timeFrame?.months !=='' ? 'dailyinputenter':''}`} value={timeFrame?.months==='day' ? 'Days' : timeFrame?.months.charAt(0).toUpperCase() + timeFrame?.months.slice(1).toLowerCase()}></input>
                      {dayDropdown && (

                        <div id="myDropdown" className="selct-weklys-dropdown p-0">
                         <div className='active' onClick={()=>{setTimeFrame(prevState => ({ ...prevState, months: 'day' }))}}>Day(s) {timeFrame?.months === 'day' && <img className='float-end' src={greenTick} alt='' />}</div>
                        <div onClick={()=>{setTimeFrame(prevState => ({ ...prevState, months: 'weeks' }))}}>Week(s) {timeFrame?.months === 'weeks' && <img className='float-end' src={greenTick} alt='' />} </div>
                        <div className='mb-0' onClick={()=>{setTimeFrame(prevState => ({ ...prevState, months: 'months' }))}}>Month(s) {timeFrame?.months === 'months' && <img className='float-end' src={greenTick} alt='' />}</div>
                        </div>
                      )}
                    </div>
                    </div>
                    </div>
                                {/* <input type="text" className='daily-input' placeholder='Weeks' value={timeFrame?.months} onChange={(e) => setTimeFrame(prevState => ({ ...prevState, months: e.target.value }))} /> */}
                                {errors.timeFrame && <div className="error">{errors.timeFrame}</div>}
                              </div>
                            </>
                          }
                        </div>
                      </div>


                      <div className='session'>
                        <label class="session-check" onClick={() => { setSessionMultipleType('weekly'); setRecurrences([]); setTimeFrame({ number: '', months: '' }); setMarkedDates([]); setDayLabel([]); setAddDates([]); setAddDateCalendar([]); setSessionDuration(0); setSelectedDayLabel([]); setShowManage(false); setManageCalendar(false);setSameTimeDuration([{ startTime: '', endTime: '',duration:'' }]);setWeekDay([]);setExdates([]); setRdates([]);setCalendarDate('');setTimeFrame(prevState => ({ ...prevState, months: 'weeks' }));setSessionNumber(''); }}>
                          <input type="radio" checked={sessionMultipleType === 'weekly'} name="mulsession-type" onChange={() => setSessionMultipleType('weekly')} />
                          <span class="checkmark"></span>
                        </label>
                        <div className='session-check-text' >
                          <h4>Weekly Package </h4>
                          {sessionMultipleType === 'weekly' &&
                            <>
                              <p>Set a number of sessions per week.</p>
                              <div className='weekly-main p-0 pt-1'>
                                <h3 className='my-2'>Specify the number of sessions per week. *</h3>
                                <input type="number" className={`daily-input ${sessionNumber && 'dailyinputenter'}` } placeholder='2 Times' value={sessionNumber}
                                //  onChange={(e) => {e?.target?.value >0 ? setSessionNumber(e?.target?.value) : setSessionNumber('')}}
                                onChange={(e) =>{const value = e.target.value;  // Parse input value as an integer
                                  if (value > 0 && value < 8) {
                                    setSessionNumber(value);
                                  } else if (value == 0) {
                                    setSessionNumber('');
                                  } else {
                                    setSessionNumber(sessionNumber);
                                  }}}
                                  />
                                {errors.sessionNumber && <div className="error">{errors.sessionNumber}</div>}
                                <h3 className='pt-3 mb-0'>Specify timeframe of the package. *</h3>
                                <p className='py-1'>overall period during which the sessions are offered</p>
                                <input type="number" className={`daily-input ${timeFrame?.number && 'dailyinputenter'}` } placeholder='2' value={timeFrame?.number} 
                                // onChange={(e) => {e?.target?.value >0 ? setTimeFrame(prevState => ({ ...prevState, number: e.target.value })) : setTimeFrame(prevState => ({ ...prevState, number: '' }))}} 
                                onChange={(e) =>{const value = e.target.value;  // Parse input value as an integer
                                  if (value > 0 && value < 100) {
                                    setTimeFrame(prevState => ({ ...prevState, number: e.target.value }))
                                  } else if (value == 0) {
                                    setTimeFrame(prevState => ({ ...prevState, number: '' }))
                                  } else {
                                    setTimeFrame(prevState => ({ ...prevState, number: timeFrame?.number }))
                                  }}}
                                />
                                <input type="text" className='daily-input daily-input-month' placeholder='Weeks' value={timeFrame?.months} />
                                {errors.timeFrame && <div className="error">{errors.timeFrame}</div>}
                              </div>



                            </>
                          }
                        </div>
                      </div>

                      <div className='session'>
                        <label class="session-check" onClick={() => { setSessionMultipleType('monthly'); setRecurrences([]); setTimeFrame({ number: '', months: '' }); setMarkedDates([]); setDayLabel([]); setAddDates([]); setAddDateCalendar([]); setSessionDuration(0); setSelectedDayLabel([]); setShowManage(false); setManageCalendar(false);setSameTimeDuration([{ startTime: '', endTime: '',duration:'' }]);setWeekDay([]);setExdates([]); setRdates([]); setCalendarDate('');setTimeFrame(prevState => ({ ...prevState, months: 'months' }));setSessionNumber('');}}>
                          <input type="radio" checked={sessionMultipleType === 'monthly'} name="mulsession-type" onChange={() => setSessionMultipleType('monthly')} />
                          <span class="checkmark"></span>
                        </label>
                        <div className='session-check-text' >
                          <h4>Monthly Package</h4>
                          {sessionMultipleType === 'monthly' &&
                            <>
                              <p>Provide session over a month.</p>
                              <div className='weekly-main p-0'>
                                <h3 className='my-2'>Specify the number of sessions per month. *</h3>
                                <input type="number" className={`daily-input ${sessionNumber && 'dailyinputenter'}` } placeholder='2 Times' value={sessionNumber} 
                                // onChange={(e) => {e?.target?.value >0 ? setSessionNumber(e?.target?.value) : setSessionNumber('')}} 
                                onChange={(e) =>{const value = e.target.value;  // Parse input value as an integer
                                  if (value > 0 && value < 32) {
                                    setSessionNumber(value);
                                  } else if (value == 0) {
                                    setSessionNumber('');
                                  } else {
                                    setSessionNumber(sessionNumber);
                                  }}}
                                />
                                {errors.sessionNumber && <div className="error">{errors.sessionNumber}</div>}
                                <h3 className='pt-3 mb-0'>Specify timeframe of the package. *</h3>
                                <p className='py-1'>overall period during which the sessions are offered</p>
                                <input type="number" className={`daily-input ${timeFrame?.number && 'dailyinputenter'}` } placeholder='2' value={timeFrame?.number} 
                                // onChange={(e) => {e?.target?.value >0 ? setTimeFrame(prevState => ({ ...prevState, number: e.target.value })) : setTimeFrame(prevState => ({ ...prevState, number: '' }))}} 
                                onChange={(e) =>{const value = e.target.value;  // Parse input value as an integer
                                  if (value > 0 && value < 100) {
                                    setTimeFrame(prevState => ({ ...prevState, number: e.target.value }))
                                  } else if (value == 0) {
                                    setTimeFrame(prevState => ({ ...prevState, number: '' }))
                                  } else {
                                    setTimeFrame(prevState => ({ ...prevState, number: timeFrame?.number }))
                                  }}} 
                                />
                                <input type="text" className='daily-input daily-input-month' placeholder='Months' value={timeFrame?.months} />
                                {/* <input type="text" className='daily-input' placeholder='Months' value={timeFrame?.months} onChange={(e) => setTimeFrame(prevState => ({ ...prevState, months: e.target.value }))} /> */}
                                {errors.timeFrame && <div className="error">{errors.timeFrame}</div>}

                              </div>



                            </>
                          }
                        </div>
                      </div>

                      {errors.sessionMultipleType && <div className="error">{errors.sessionMultipleType}</div>}
                      {/* <button onClick={() => generateRecurrenceStrings()}>Generate</button> */}
                    </div>
                  }


                  {/* <div className='define-top time'>
                    {sessionType !== '' && data?.sessionTypes !== 'group_session' &&
                      <>
                        <h3>Time when will it happen?</h3>
                       
                        <div className='session'>
                          <label class="session-check" onClick={() => { setSpecifyTime('availability'); setSessionRepeat('') }}>
                            <input type="radio" checked={specifyTime === 'availability'} name="specify_time"></input>
                            <span class="checkmark"></span>
                          </label>
                          <div className='session-check-text' >
                            <h4>Share availability </h4>
                            <p>You share your availability so users can select a convenient slot.</p>
                          </div>
                        </div>
                        {errors.specifyTime && <div className="error">{errors.specifyTime}</div>}
                      </>
                    }
                    {(specifyTime === 'exact' || data?.sessionTypes === 'group_session') && (
                      <div className='session-week'>
                        <h3>How often should this session repeat?</h3>
                        <ul>
                          <li >
                            <label class="session-check" onClick={() => handleTImeSession("one")}>
                              <input type="radio" checked={sessionRepeat === 'one'} name="sessiontime"></input>
                              <span class="checkmark"></span>
                            </label>
                            <h4>One-time session</h4>
                          </li>
                          <li >
                            <label class="session-check" onClick={() => handleTImeSession("daily")}>
                              <input type="radio" checked={sessionRepeat === 'daily'} name="sessiontime"></input>
                              <span class="checkmark"></span>
                            </label>
                            <h4>Daily</h4>
                          </li>
                          <li >
                            <label class="session-check" onClick={() => handleTImeSession("weekly")}>
                              <input type="radio" checked={sessionRepeat === 'weekly'} name="sessiontime"></input>
                              <span class="checkmark"></span>
                            </label>
                            <h4>Weekly</h4>
                          </li>
                          <li >
                            <label class="session-check" onClick={() => handleTImeSession("monthly")}>
                              <input type="radio" checked={sessionRepeat === 'monthly'} name="sessiontime"></input>
                              <span class="checkmark"></span>
                            </label>
                            <h4>Monthly</h4>
                          </li>
                          <li >
                            <label class="session-check" onClick={() => handleTImeSession("weekday")}>
                              <input type="radio" checked={sessionRepeat === 'weekday'} name="sessiontime"></input>
                              <span class="checkmark"></span>
                            </label>
                            <h4>Every weekday(from Monday to Friday)</h4>
                          </li>
                          <li >
                            <label class="session-check" onClick={() => handleTImeSession("weekend")} >
                              <input type="radio" checked={sessionRepeat === 'weekend'} name="sessiontime"></input>
                              <span class="checkmark"></span>
                            </label>
                            <h4>Every weekends(Saturday and Sunday)</h4>
                          </li>

                        </ul>
                        {errors.sessionRepeat && <div className="error">{errors.sessionRepeat}</div>}
                      </div>
                    )
                    }


                  </div> */}
{sessionType === 'multiple_session' && sessionMultipleType === 'weekly' &&
                  <div className='select-day select-day-border mt-3 pt-4 '>
                    {sessionType === 'multiple_session' && sessionMultipleType === 'weekly' && (
                      <>
                        <h3>Select Days</h3>

                        <ul>
                          {daysOfWeek.map(day => (
                            <li key={day?.value} onClick={() => handleDayChange(day.value, day?.label, day?.name)} className={dayLabel.includes(day?.label) && 'active'} >
                              {day.name}
                            </li>
                          ))}
                        </ul>
                      </>
                    )
                    }
                  </div>
                  }

                  {/* <>
                    <div className='select-day'>
                      {((specifyTime === 'exact' && sessionRepeat !== '') || specifyTime === 'availability' || data?.sessionTypes === 'group_session') && (
                        <>
                          {sessionRepeat === 'monthly'
                            ?
                            <>
                              <h3>Select Day to repeat</h3>
                              <input type='text' onChange={(e) => setMonthRepeatValue(e?.target?.value)} />
                            </>
                            :
                            <h3>{(specifyTime === 'exact' || data?.sessionTypes === 'group_session') ? 'Select Recurring Days' : 'Select Days'}</h3>
                          }
                          <ul>
                            {daysOfWeek.map(day => (
                              <li key={day?.value} onClick={() => handleDayChange(day.value, day?.label, day?.name)} className={dayLabel.includes(day?.label) && 'active'} >
                                {day.name}
                              </li>
                            ))}
                          </ul>
                        </>
                      )
                      }
                    </div>

                    {(specifyTime !== '' || (data?.sessionTypes === 'group_session' && (sessionRepeat !== 'one' && sessionRepeat !== ''))) &&
                      <div className='define-top time period'>
                        <h3>{(specifyTime === 'exact' || data?.sessionTypes === 'group_session') ? 'Recurred Period Duration' : 'Availability Period'}</h3>
                        <p>Please specify when your availability period ends.</p>

                        <div className='session margin' onClick={() => setAvailabilityPeriod('indefinitely')}>
                          <label class="session-check">
                            <input type="radio" checked={availabilityPeriod === 'indefinitely'} name="availability_periods"></input>
                            <span class="checkmark"></span>
                          </label>
                          <div className='session-check-text'>
                            <h4>Indefinitely</h4>
                          </div>
                        </div>
                        <div className='session margin mar-bott' onClick={() => setAvailabilityPeriod('date')}>
                          <label class="session-check">
                            <input type="radio" checked={availabilityPeriod === 'date'} name="availability_periods"></input>
                            <span class="checkmark"></span>
                          </label>
                          <div className='session-check-text until'>
                            <h4>Until a specific date</h4>
                            <input type='date' className='form-control' value={typeValue?.date} onChange={(e) => handleChangeData('date', e)} />
                          </div>
                        </div>
                        {(specifyTime === 'exact' || (data?.sessionTypes === 'group_session' && (sessionRepeat !== 'one' && sessionRepeat !== ''))) &&
                          <div className='session margin mar-bott' onClick={() => setAvailabilityPeriod('after')}>
                            <label class="session-check">
                              <input type="radio" checked={availabilityPeriod === 'after'} name="availability_periods"></input>
                              <span class="checkmark"></span>
                            </label>
                            <div className='session-check-text until'>
                              <h4>After</h4>
                              <input type='text' className='form-control' placeholder='12 times' value={typeValue?.after} onChange={(e) => handleChangeData('after', e)} />
                            </div>
                          </div>
                        }
                        {errors.availabilityPeriod && <div className="error">{errors.availabilityPeriod}</div>}
                      </div>
                    }
                  </> */}
                </div>

                {/* {selectedDayLabel && selectedDayLabel.length > 0 &&
                  <div className='define-left'>
                    <div className='define-top'>
                      <h3>Available time slot</h3>
                      <div className="switch-content">
                        <div className="switch-button">
                          <label className="switch">
                            <input type="checkbox" checked={sameTime} onClick={() => setSameTime(!sameTime)} />
                            <span className="slider"></span>
                          </label>
                        </div>
                        <h3>Same for all days</h3>
                      </div>
                    </div>



                    <div className='time-slot'>
                      {sameTime ?
                        <div className='row'>
                          <div className='col-sm-5'>
                            <div className='form-group'>
                              <label>From</label>
                              <input type='time' className='form-control' onChange={(e) => setSameTimeDuration(prevState => ({ ...prevState, startTime: e.target.value }))} required />
                            </div>
                          </div>
                          <div className='col-sm-5'>
                            <div className='form-group'>
                              <label>To</label>
                              <input type='time' className='form-control' onChange={(e) => setSameTimeDuration(prevState => ({ ...prevState, endTime: e.target.value }))} required />
                            </div>
                          </div>
                          <div className='col-sm-2'>
                            <div className='form-group'>
                              <button className='add-btn'><img src={add} alt='' /></button>
                            </div>

                          </div>
                        </div>
                        :
                        <>
                          {selectedDayLabel && selectedDayLabel.length > 0 && selectedDayLabel.map((x, index) => {
                            return (
                              <div key={index}>
                                <h4>{x?.label}</h4>
                                <div className='row'>
                                  <div className='col-sm-5'>
                                    <div className='form-group'>
                                      <label>From</label>
                                      <input type='time' className='form-control' onChange={(e) => handleInputChange(index, 'startTime', e.target.value, 1)} required />
                                    </div>
                                  </div>
                                  <div className='col-sm-5'>
                                    <div className='form-group'>
                                      <label>To</label>
                                      <input type='time' className='form-control' onChange={(e) => handleInputChange(index, 'endTime', e.target.value, 1)} required />
                                    </div>
                                  </div>
                                  <div className='col-sm-2'>
                                    <div className='form-group'>
                                      <button className='add-btn' onClick={() => handleMorediv(x?.label)}>
                                        <img src={add} alt='' />
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                {moreDiv && moreDiv.length > 0 && moreDiv.map((y, ind) => {
                                  if (x?.label === y?.label) {
                                    if (ind < y?.count) {
                                      return (
                                        <div className='row' key={ind}>
                                          <div className='col-sm-5'>
                                            <div className='form-group'>
                                              <label>From</label>
                                              <input type='time' className='form-control' onChange={(e) => handleInputChange(index, 'startTime', e.target.value, 2)} required />
                                            </div>
                                          </div>
                                          <div className='col-sm-5'>
                                            <div className='form-group'>
                                              <label>To</label>
                                              <input type='time' className='form-control' onChange={(e) => handleInputChange(index, 'endTime', e.target.value, 2)} required />
                                            </div>
                                          </div>
                                          <div className='col-sm-2'>
                                            <div className='form-group'>
                                              <button className='add-btn' onClick={() => handleMorediv(x?.label)}>
                                                <img src={add} alt='' />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  }
                                  return null;
                                })}

                               
                              </div>
                            );
                          })}
                        </>
                      }
                      <div className='row'>
                        <div className='col-sm-12'>
                          <div className='form-group'>
                            <label>Enter Session Duration</label>
                            <input type='text' className='form-control' placeholder='60min' onChange={(e) => setSessionDuration(e?.target?.value)} required />
                          </div>
                        </div>
                      </div>
                    </div>

                    <button onClick={generateRecurrenceStrings}>Generate</button>


                  </div>
                } */}
                <div className='define-left schedule'>
                  <h3>Date Schedule</h3>
                  {showManage === false && sessionType !== '' && <button onClick={() => { setAddDatesCancel([]);sessionType==='multiple_session' && sessionMultipleType !== 'daily' && sessionMultipleType !== 'monthly' ? ((selectedDayLabel?.length === Number(sessionNumber)) && setShowManage(true)) : setShowManage(true)}}
                   className={`save-button 
                    ${sessionType === 'single_session' ? markedDates?.length > 0 ? '' : 'fade-save':''} 
                    ${sessionType === 'multiple_session' && sessionMultipleType==='' ?'fade-save':''}
                    ${(sessionType === 'multiple_session' && sessionMultipleType ==='daily') ?
                      (markedDates?.length === ((sessionNumber === '' ? 1 : Number(sessionNumber)) *
                      (timeFrame?.number * 
                        (timeFrame?.months === 'day' ? 1 : 
                         timeFrame?.months === 'weeks' ? 7 : 
                         timeFrame?.months === 'months' ? 30:1)
                      )) && timeFrame?.number !='' ? '' : 'fade-save'):''}
                      ${sessionType === 'multiple_session' && (sessionMultipleType ==='weekly' || sessionMultipleType ==='monthly')  ?
                        (markedDates?.length === ((Number(sessionNumber) * Number(timeFrame?.number))) && timeFrame?.number !='' && sessionNumber !='' ? '' : 'fade-save'):''}`} 
                  disabled={(sessionType === 'single_session' && addDatesCalendar?.length === 0) || (sessionType === 'multiple_session' && sessionMultipleType !== 'monthly' )&& selectedDayLabel?.length === 0 || (sessionType === 'multiple_session' && sessionMultipleType === 'monthly') && addDatesCalendar?.length === 0 || markedDates?.length === 0}>Save</button>}
                  {/* {showManage === false && sessionType !== '' && <button onClick={() => { setAddDatesCancel([]);sessionType==='multiple_session' && sessionMultipleType !== 'daily' && sessionMultipleType !== 'monthly' ? ((selectedDayLabel?.length === Number(sessionNumber)) && setShowManage(true)) : setShowManage(true)}} className='save-button' disabled={(sessionType === 'single_session' && addDatesCalendar?.length === 0) || (sessionType === 'multiple_session' && sessionMultipleType !== 'monthly' )&& selectedDayLabel?.length === 0 || (sessionType === 'multiple_session' && sessionMultipleType === 'monthly') && addDatesCalendar?.length === 0 || markedDates?.length === 0}>Save</button>} */}
                  
                  {!manageCalendar && showManage && <button className='manage float-end' onClick={() =>  {setExAddDatesCancel([]);setRAddDatesCancel([]);setAddDatesCancel([]);setManageCalendar(true)}}><img src={manageIcon} alt='' />Manage </button>}
                  {manageCalendar &&
                    <>
                      <div className='manage-btns'>
                        <button className='manage' onClick={() =>  {handleAddDateRemove();setManageCalendar(false)}}>Cancel </button>
                        {/* <button onClick={() => setManageCalendar(false)}>Save</button> */}
                        <button onClick={() => {setExAddDatesCancel([]);setRAddDatesCancel([]);setAddDatesCancel([]);setManageCalendar(false)}} className='save-button'>Save</button>
                        {/* <button onClick={() => handleTimeSlot('manage')} className='save-button'>Save</button> */}
                      </div>
                    </>
                  }
                  {showManage === false && sessionType !== '' && <h4>Please select date for your sesion.</h4>}
                  {!manageCalendar && showManage && <h4>You can individually edit your availability schedule.</h4>}
                  {manageCalendar && <h4>Add or remove dates by clicking on + or -. View and change time slots by clicking on  the date slot.</h4>}
                  {/* <h4>You can individually edit your availability schedule.</h4> */}
                  <div className="calender">
                    <Calendar
                      onChange={(date) => {
                        const formattedDate = date.toLocaleDateString('en-CA');
                        const isMarked = markedDates.includes(formattedDate);
                        { showManage === false ? handleSaveRecurrence(date, isMarked, 'first') : manageCalendar && handleSaveRecurrence(date, isMarked) }
                        onChange(date); // Update the selected date in your state
                      }}
                      onActiveStartDateChange={handleActiveStartDateChange}
                      value={value}
                      minDate={new Date()}
                      tileClassName={({ date,view }) => {
                        const formattedDate = date.toLocaleDateString('en-CA');
                        const currentMonth = activeStartDate.getMonth();
                        const currentYear = activeStartDate.getFullYear();
                      
                        const dateMonth = date.getMonth();
                        const dateYear = date.getFullYear();
                        const isToday = date.getDate() === new Date().getDate() &&
                        date.getMonth() === new Date().getMonth() &&
                        date.getFullYear() === new Date().getFullYear();
                        const isPreviousMonth = view === 'month' && (dateMonth < currentMonth && dateYear === currentYear || dateYear < currentYear);
                        const isNextMonth = view === 'month' && (dateMonth > currentMonth+1 && dateYear === currentYear || dateYear >  currentYear);
                        let className = '';
                        if (isPreviousMonth || isNextMonth) {
                        className += ' previous-date';
                        // return 'previous-date'; // Add class for previous month dates
                        }
                        console.log("formattedDate", formattedDate)
                        if (isDateMarked(formattedDate)) {
                          className += ' highlight';
                          // return 'highlight';
                        }
                        const isMarked = markedDates.includes(formattedDate);
                        if (isMarked) {
                          className += ' highlight';
                          // return 'highlight';
                        }
                        if (isToday) {
                          className += ' black-current';
                        }
                        if (date < new Date().setHours(0, 0, 0, 0)) {
                          className += ' previousDayDate';
                        }
                        if(editDates.includes(formattedDate)){
                          className += ' non-editable-date'
                        }
                        // return null;
                        return className.trim() || null;

                      }}
                      tileContent={({ date }) => {

                        const formattedDate = date.toLocaleDateString('en-CA');;
                        const isMarked = markedDates.includes(formattedDate);
                        console.log("recurrences", recurrences)
                        return (
                          // <span

                          //   style={{ cursor: 'pointer', userSelect: 'none' }}
                          // >

                          //   {recurrences && recurrences?.length > 0 && manageCalendar && (isMarked ? '-' : '+')}
                          // </span>
                          <span
        className={recurrences && recurrences?.length > 0 && manageCalendar && (isMarked ? 'minus-sign' : 'plus-sign')}  // Add conditional class
        style={{ cursor: 'pointer', userSelect: 'none' }}
      >
        {manageCalendar && (isMarked ? '-' : '+')}
        {/* {recurrences && recurrences?.length > 0 && manageCalendar && (isMarked ? '-' : '+')} */}
      </span>
                        );
                      }}
                    />

                    {/* <Calendar
  onChange={(date) => {
    const formattedDate = date.toLocaleDateString('en-CA');
    const isMarked = markedDates.includes(formattedDate);
    handleSaveRecurrence(date, isMarked);
    onChange(date); // Update the selected date in your state
  }}
  value={value}
  tileClassName={({ date }) => {
    const formattedDate = date.toLocaleDateString('en-CA');
    if (isDateMarked(formattedDate)) {
      return 'highlight';
    }
    return null;
  }}
  tileContent={({ date }) => {
    const formattedDate = date.toLocaleDateString('en-CA');
    const isMarked = markedDates.includes(formattedDate);
    return (
      <span
        style={{ cursor: 'pointer', userSelect: 'none' }}
      >
        {recurrences && recurrences?.length > 0 ? (isMarked ? '-' : '+') : (isMarked ? '+' : '+')}
      </span>
    );
  }}
/> */}

                  </div>
                </div>
                
                {/* {addDatesCalendar && addDatesCalendar?.length > 0 && selectedDayLabel?.length === 0 &&
                  <div className='row'>
                    <div className='col-sm-12'>
                      <div className='time-slot'>
                        <div className='form-group'>
                          <label>Enter Session Duration</label>
                          <input type='number' className='form-control' placeholder='60min' value={sessionDuration} onChange={(e) => setSessionDuration(e?.target?.value)} disabled ={!manageCalendar && showManage} required />
                        </div>
                      </div>
                    </div>
                  </div>} */}

                {/* {sessionType !== '' && selectedDayLabel && selectedDayLabel.length > 0 && !manageCalendar && */}
                {sessionType !== '' && !manageCalendar && showManage &&
                  // {sessionType !== 'multiple_session' && selectedDayLabel && selectedDayLabel.length > 0 &&
                  <div className='define-left'>
                    <div className='define-top'>
                      <h3>Time when will it happen?</h3>
                      {/* <h3>Available time slot</h3> */}
                      <div className="switch-content">
                        <div className="switch-button">
                          <label className="switch">
                            <input type="checkbox" checked={sameTime} onClick={() => setSameTime(!sameTime)}/>
                            {/* <input type="checkbox" checked={sameTime} onClick={() => setSameTime(!sameTime)} disabled ={!manageCalendar && showManage}/> */}
                            <span className={`slider ${sameTime && 'active-slider'}`} ></span>
                          </label>
                        </div>
                        <h3>Same for all days</h3>
                      </div>
                    </div>



                    <div className='time-slot'>
                      {sameTime ?
                        // <div className='row'>
                        //   <div className='col-sm-5'>
                        //     <div className='form-group'>
                        //       <label>From</label>
                        //       <input type='time' className='form-control' onChange={(e) => setSameTimeDuration(prevState => ({ ...prevState, startTime: e.target.value }))} required />
                        //     </div>
                        //   </div>
                        //   <div className='col-sm-5'>
                        //     <div className='form-group'>
                        //       <label>To</label>
                        //       <input type='time' className='form-control' onChange={(e) => setSameTimeDuration(prevState => ({ ...prevState, endTime: e.target.value }))} required />
                        //     </div>
                        //   </div>
                        //   <div className='col-sm-2'>
                        //     <div className='form-group'>
                        //       <button className='add-btn' onClick={() => handleMorediv()}><img src={add} alt='' /></button>
                        //     </div>

                        //   </div>
                        // </div>
                        <>
                          {sameTimeDuratioon.map((slot, index) => (
                            <>
                            <div className='row' key={index}>
                              <div className='col-sm-5'>
                                <div className='form-group'>
                                  <label>Start time *</label>
                                  {/* <input
                                    type='time'
                                    className='form-control'
                                    value={slot.startTime}
                                    onChange={(e) => handleSameTimeChange(index, 'startTime', e.target.value)}
                                    disabled ={!manageCalendar && showManage}
                                    required
                                  /> */}
                                  <TimePicker values={slot?.startTime} handleInputChange={handleSameTimeChange} index={index} timeLimit={'startTime'} val={''} num={''} strin={''} label={''} position={''} manageCalendar={true}  showManage={true} isOpen={openTimePickerIndex === `${index}-0-start`} setIsOpen={() => handleTimePickerToggle(`${index}-0-start`)}/>
                                  
                                </div>
                              </div>
                              <div className='col-sm-5'>
                                <div className='form-group'>
                                  <label>End time *</label>
                                  {/* <input
                                    type='time'
                                    className='form-control'
                                    value={slot.endTime}
                                    onChange={(e) => handleSameTimeChange(index, 'endTime', e.target.value)}
                                    disabled ={!manageCalendar && showManage}
                                    required
                                  /> */}
                                  <TimePicker values={slot?.endTime} handleInputChange={handleSameTimeChange} index={index} timeLimit={'endTime'} val={''} num={''} strin={''} label={''} position={''} manageCalendar={true}  showManage={true} isOpen={openTimePickerIndex === `${index}-0-end`} setIsOpen={() => handleTimePickerToggle(`${index}-0-end`)}/>
                                </div>
                              </div>
                              {/* <div className='col-sm-5'>
                                <div className='form-group time-duration position-relative'>
                                  <label>Duration</label>
                                  <input
                                    type='number'
                                    className='form-control'
                                    value={slot.duration}
                                    onChange={(e) => handleSameTimeChange(index, 'duration', e.target.value)}
                                    disabled ={!manageCalendar && showManage}
                                    required
                                  />
                                  <span>{slot?.duration !=='' &&'min'}</span>
                                </div>
                              </div> */}
                              {/* {index === sameTimeDuratioon.length - 1 && ( */}
                              {/* <div className='col-sm-2 text-center'>
                                <div className='form-group'>
                                  {index === 0 ? <button className='add-btn' onClick={handleAddTimeSlot} disabled ={!manageCalendar && showManage}>
                                    <img src={add} alt='' />
                                  </button> :
                                    <button className='add-btn' onClick={() => handleRemoveTimeSlot(index)} disabled ={!manageCalendar && showManage}>
                                      <img src={deleteImg} alt='' />
                                    </button>}
                                </div>
                              </div> */}
                              {/* )} */}
                            </div>
                            {/* <div className='border-btm mb-3'></div> */}
                            </>
                          ))}
                        </>
                        :
                        <>
                          {selectedDayLabel && selectedDayLabel.length > 0 && selectedDayLabel.map((x, index) => {
                            return (
                              <div key={index}>
                                <h4>On {x?.label}</h4>
                                {x?.dates && x?.dates.length > 0 && x?.dates.map((y, ind) => {
                                  return (
                                    <>
                                    <div className='row'>
                                      <div className='col-sm-5'>
                                        <div className='form-group'>
                                          <label>Start time *</label>
                                          {/* <input type='time' className='form-control' value={y?.startTime} onChange={(e) => handleInputChange(index, 'startTime', e.target.value, 1, '', x?.label, ind)} disabled ={!manageCalendar && showManage} required /> */}
                                          <TimePicker values={y?.startTime} handleInputChange={handleInputChange} index={index} timeLimit={'startTime'} val={''} num={1} strin={''} label={x?.label} position={ind} manageCalendar={true}  showManage={true} isOpen={openTimePickerIndex === `${index}-${ind}-start`} setIsOpen={() => handleTimePickerToggle(`${index}-${ind}-start`)}/>
                                        </div>
                                      </div>
                                      <div className='col-sm-5'>
                                        <div className='form-group'>
                                          <label>End time *</label>
                                          {/* <input type='time' className='form-control' value={y?.endTime} onChange={(e) => handleInputChange(index, 'endTime', e.target.value, 1, '', x?.label, ind)} disabled ={!manageCalendar && showManage} required /> */}
                                          <TimePicker values={y?.endTime} handleInputChange={handleInputChange} index={index} timeLimit={'endTime'} val={''} num={1} strin={''} label={x?.label} position={ind} manageCalendar={true}  showManage={true} isOpen={openTimePickerIndex === `${index}-${ind}-end`} setIsOpen={() => handleTimePickerToggle(`${index}-${ind}-end`)}/>
                                        </div>
                                      </div>
                                      {/* <div className='col-sm-5'>
                                        <div className='form-group time-duration position-relative'>
                                          <label>Duration</label>
                                          <input type='number' className='form-control' value={y?.duration} onChange={(e) => handleInputChange(index, 'duration', e.target.value, 1, '', x?.label, ind)} disabled ={!manageCalendar && showManage} required />
                                          <span>{y?.duration !=='' &&'min'}</span>
                                        </div>
                                      </div> */}
                                      {/* <div className='col-sm-2 text-center'>
                                        <div className='form-group'>
                                          {ind === 0 ? <button className='add-btn' onClick={() => handleUpdateSelectedTimeSlots(x?.label)} disabled ={!manageCalendar && showManage}>
                                            <img src={add} alt='' />
                                          </button> :
                                            <button className='add-btn' onClick={() => handleRemoveSelectedTimeSlot(x?.label, ind)} disabled ={!manageCalendar && showManage}>
                                              <img src={deleteImg} alt='' />
                                            </button>}
                                        </div>
                                      </div> */}
                                    </div>
                                    <div className='border-btm mb-3'></div>
                                    </>
                                  )
                                })}

                                {/* {moreDiv && moreDiv.length > 0 && moreDiv.map((y, ind) => {
                                  if (x?.label === y?.label) {
                                    if (ind < y?.count) {
                                      return (
                                        <div className='row' key={ind}>
                                          <div className='col-sm-5'>
                                            <div className='form-group'>
                                              <label>From</label>
                                              <input type='time' className='form-control' onChange={(e) => handleInputChange(index, 'startTime', e.target.value, 2)} required />
                                            </div>
                                          </div>
                                          <div className='col-sm-5'>
                                            <div className='form-group'>
                                              <label>To</label>
                                              <input type='time' className='form-control' onChange={(e) => handleInputChange(index, 'endTime', e.target.value, 2)} required />
                                            </div>
                                          </div>
                                          <div className='col-sm-2'>
                                            <div className='form-group'>
                                              <button className='add-btn' onClick={() => handleMorediv(x?.label)}>
                                                <img src={add} alt='' />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  }
                                  return null; // Return null if the labels don't match or condition is not met
                                })} */}

                                {/* {moreDiv && moreDiv.length > 0 && selectedDayLabel && selectedDayLabel.length > 0 && selectedDayLabel.map((x, index) => {
                                // Find the matching item in moreDiv
                                const matchingItem = moreDiv.find(y => y.label === x?.label);

                                return (
                                  <div key={index}>
                                    <h4>{x?.label}</h4>

                                    {matchingItem && [...Array(matchingItem.count)].map((_, ind) => (
                                      <div className='row' key={ind}>
                                        <div className='col-sm-5'>
                                          <div className='form-group'>
                                            <label>From</label>
                                            <input type='date' className='form-control' />
                                          </div>
                                        </div>
                                        <div className='col-sm-5'>
                                          <div className='form-group'>
                                            <label>To</label>
                                            <input type='date' className='form-control' />
                                          </div>
                                        </div>
                                        <div className='col-sm-2'>
                                          <div className='form-group'>
                                            <button className='add-btn' onClick={() => handleMorediv(x?.label)}>
                                              <img src={add} alt='' />
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                );
                              })} */}
                              </div>
                            );
                          })}
                           {addDatesCalendar && addDatesCalendar?.length > 0 &&
                // <div className='define-left'>
               
                addOnDay && addOnDay?.length > 0 && addOnDay.map((x, index) => (
                  // return {
                  <>
                      <div key={index} className='slot-add mb-0'>
                        <h4>On {x?.day.charAt(0).toUpperCase() + x?.day.slice(1).toLowerCase()}</h4>
                        {/* <h4>Available slot for {new Date(x?.date).toLocaleDateString('en-CA')}</h4> */}
                        {x?.timeSlots && x?.timeSlots?.length > 0 && x?.timeSlots.map((y, ind) => (
                          <div className='time-slot'>
                            <div className='row' key={index}>
                              <div className='col-sm-5'>
                                <div className='form-group'>
                                  <label>Start time *</label>
                                  {/* <input type='time' className='form-control' value={y?.startTime} onChange={(e) => handleInputChange(index, 'startTime', e.target.value, 2, 'addon', x?.date, ind)} disabled ={!manageCalendar && showManage} required /> */}
                                  <TimePicker values={y?.startTime} handleInputChange={handleInputChange} index={index} timeLimit={'startTime'} val={''} num={2} strin={'addon'} label={x?.day} position={ind} manageCalendar={true}  showManage={true} isOpen={openTimePickerIndex === `${index}-${ind}-astart`} setIsOpen={() => handleTimePickerToggle(`${index}-${ind}-astart`)}/>
                                </div>
                              </div>
                               <div className='col-sm-5'>
                                <div className='form-group'>
                                  <label>End time *</label>
                                  {/* <input type='time' className='form-control' value={y?.endTime} onChange={(e) => handleInputChange(index, 'endTime', e.target.value, 2, 'addon', x?.date, ind)} disabled ={!manageCalendar && showManage} required /> */}
                                  <TimePicker values={y?.endTime} handleInputChange={handleInputChange} index={index} timeLimit={'endTime'} val={''} num={2} strin={'addon'} label={x?.day} position={ind} manageCalendar={true}  showManage={true} isOpen={openTimePickerIndex === `${index}-${ind}-aend`} setIsOpen={() => handleTimePickerToggle(`${index}-${ind}-aend`)}/>
                                </div>
                              </div> 
                              {/* <div className='col-sm-5'>
                                <div className='form-group time-duration position-relative'>
                                  <label>Duration</label>
                                  <input type='number' className='form-control' value={y?.duration} onChange={(e) => handleInputChange(index, 'duration', e.target.value, 2, 'addon', x?.date, ind)} disabled ={!manageCalendar && showManage} required />
                                  <span>{y?.duration !=='' &&'min'}</span>
                                </div>
                              </div> */}
                              {/* <div className='col-sm-2 text-center'>
                                <div className='form-group'>
                                  {ind === 0 ? <button className='add-btn' onClick={() => handleUpdateTimeSlots(x?.date, ind)} disabled ={!manageCalendar && showManage}>
                                    <img src={add} alt='' />
                                  </button> :
                                    <button className='add-btn' onClick={() => handleRemoveAddDatesTimeSlot(x?.date, ind)} disabled ={!manageCalendar && showManage}>
                                      <img src={deleteImg} alt='' />
                                    </button>}
                                </div>
                              </div> */}
                            </div>
                            <div className='border-btm mb-3'></div>
                          </div>

                        ))}
                        
                      </div>
                  </>
                  // }
                ))}
                
                {/* </div> */}

                        </>
                      }
                      {/* <div className='row'>
                        <div className='col-sm-12'>
                          <div className='form-group'>
                            <label>Enter Session Duration</label>
                            <input type='number' className='form-control' placeholder='60min' value={sessionDuration} onChange={(e) => setSessionDuration(e?.target?.value)} disabled ={!manageCalendar && showManage} required />
                          </div>
                        </div>
                      </div> */}
                    </div>

                    {/* <button onClick={generateRecurrenceStrings}>Generate</button> */}


                  </div>
                }

              </div>
              {type !== 'edit' &&
              <OfferingDetails data={data} />
}
            </div>

          </div>
        </div>
      </div>
      <Modal
        isOpen={selectedDayLabelModal}
        onRequestClose={()=>setSelectedDayLabelModal(false)}
        contentLabel="Delete Modal"
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
        overlayClassName={`modal-overlay  verification-modal offiering-modals-change ${weekDayname !== 'null' && weekDayname!=='session' && 'timeslot'} ${weekDayname === 'null' && 'time-avaliable timeslot'} ${weekDayname === 'session' && 'time-avaliable timeslot'}`} >
          <button className='close-button ' onClick={() => setSelectedDayLabelModal(false)}><img src={cross} alt='' /></button>
          {weekDayname !== 'null' && weekDayname!=='session' && weekDayname !== 'date' && weekDayname !=='required' && weekDayname !=='specifyDate' && weekDayname !=='firstDate' && weekDayname !=='maximum' && weekDayname !== 'selected' && weekDayname !== 'weekDay' && weekDayname !== 'remove' && weekDayname!=='endTimeLess' && weekDayname !=='dayCustom' && <h1>Please fill the {weekDayname} timeslot.</h1>} 
          {weekDayname === 'null' && <h1>Please fill in the time slots when you're available in the section below.</h1>}
          {weekDayname === 'session' && <h1>Session time must be shorter than your availability.</h1>}
          {weekDayname === 'date' && <h1>Please select the date.</h1>}
          {weekDayname === 'required' && <h1>fill the required field.</h1>}
          {weekDayname === 'specifyDate' && <h1>select the specify number of date.</h1>}
          {weekDayname === 'firstDate' && <h1>first select the date.</h1>}
          {weekDayname === 'maximum' && <h1>Maximum limit exceeds.</h1>}
          {weekDayname === 'selected' && <h1>you selected only {sessionNumber} times.</h1>}
          {weekDayname === 'weekDay' && <h1>please select the {sessionNumber} weekDay.</h1>}
          {weekDayname === 'remove' && <h1>first remove the recurrence.</h1>}
          {weekDayname === 'endTimeLess' && <h1>End time must be greater than start time.</h1>}
          {weekDayname === 'dayCustom' && <h1>Only {markedDates?.length} session are available, but at least {countMark} session are needed.</h1>}
      <span class="d-block text-center"> <img src={timeslot} /></span>
        <p></p>
      </Modal>
      <DraftModal setIsModal={setIsModal} isModal={isModal}/>
    </div >
  )
}

export default AvailabilityOfferingGroup