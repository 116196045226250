import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AboutDetail from '../../CreatorComponents/CreatorJoin/aboutDetail';
import BookInterview from '../../CreatorComponents/CreatorJoin/bookInterview';
import SubmitForm from '../../CreatorComponents/CreatorJoin/submitForm';
import BookInterviews from '../UserJoin/bookInterviews';


function BecomeCreator() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(false)
  const [step, setStep] = useState(1);
  const [interviewData,setInterviewData] = useState({})
  const formState = useSelector((state) => state.counter);
  useEffect(() => {
    console.log('Current Form State:', formState);
    console.log('step:', step);
  }, [formState, step]);
  const nextStep = () => {
        setStep(step + 1);
  };


  const prevStep = () => {
    if(step === 1){
        navigate('/user/home')
    }
      setStep(step - 1);
  };

  switch (step) {
    	case 1:
            return <AboutDetail nextStep={nextStep} prevStep={prevStep} data={''} typeUser={'userSide'} />;
    	case 2:
            return  <BookInterview nextStep={nextStep} prevStep={prevStep} data={formState} submitForm={''} loader={''} loginStatus={'becomeCreator'} setDashboardModal={'setDashboardModal'} setBookingInterviw={'setBookingInterviw'} setInterviewData={setInterviewData}/>
    	case 3:
            return  <SubmitForm prevStep={prevStep} submitForm={''} loader={''} loginStatus={'becomeCreator'} data={interviewData}/>
            
    		default:
    			return <div>Unknown step</div>;
    		}
};

export default BecomeCreator