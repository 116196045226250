import React, { useEffect, useState, useCallback } from 'react';
import UserSidebar from '../../../Layout/userSidebar';
import burger from '../../../assets/images/burger-menu.svg';
import notifications from '../../../assets/images/notifications.svg';
import cardnotify from '../../../assets/images/notifycards.png';
import details from '../../../assets/images/ep_top-right.svg';
import pin from '../../../assets/images/pin-image.svg';
import { getApi, postApi, putApi } from '../../../Services/apiService';

function CreatorNotification() {
    const [classState, setClassState] = useState(false);
    const [notificationData, setNotificationData] = useState([]);
    const [todayNotifications, setTodayNotifications] = useState([]);
    const [earlierNotifications, setEarlierNotifications] = useState([]);

    const handleSidebarClick = (event) => {
        event.stopPropagation();
        setClassState(!classState);
    };

    useEffect(() => {
        const handleClick = () => setClassState(false);
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const response = await getApi(`/notifications`);
        if (response?.statusCode === 200) {
            const notifications = response?.data;
            const today = new Date().toISOString().split('T')[0];

            const todayNotifications = notifications.filter(notification =>
                new Date(notification.createdAt).toISOString().split('T')[0] === today
            );
            const earlierNotifications = notifications.filter(notification =>
                new Date(notification.createdAt).toISOString().split('T')[0] !== today
            );

            setTodayNotifications(todayNotifications);
            setEarlierNotifications(earlierNotifications);
            setNotificationData(notifications);
        }
    };
       // Format time difference function
       const timeAgo = (dateString) => {
        const now = new Date();
        const date = new Date(dateString);
        const diffInSeconds = Math.floor((now - date) / 1000);

        const intervals = [
            { label: 'year', seconds: 31536000 },
            { label: 'month', seconds: 2592000 },
            { label: 'day', seconds: 86400 },
            { label: 'hour', seconds: 3600 },
            { label: 'minute', seconds: 60 },
            { label: 'second', seconds: 1 },
        ];

        for (const interval of intervals) {
            const count = Math.floor(diffInSeconds / interval.seconds);
            if (count >= 1) {
                return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;
            }
        }
        return 'Just now';
    };

    const readMessage = async (noti) => {
        if(noti?.is_read === false){
           const  notifiData ={
                notificationId: noti?.id,
            }
            const response = await putApi('/notifications', notifiData);
            console.log("response>>>>>>>", response);
            if (response?.statusCode === 200) {  
                    fetchData()
                  }
                }
    };
    return (
        <div className="users meal-suggestion creator-notification">
            <div className="HomeMainDiv">
                <UserSidebar classState={classState} />
                <div className="RightSideDiv right-main">
                    <div className='notify'>
                        <h2>
                            <button className="burger-menu" onClick={handleSidebarClick}>
                                <img src={burger} alt='burger menu' />
                            </button>
                            My Notifications
                        </h2>
                        <div className='d-flex'>
                            <div className="switch-content">
                                <div className="switch-button">
                                    <label className="switch">
                                        <input type="checkbox" checked={false} />
                                        <span className="slider"></span>
                                    </label>
                                </div>
                            </div>
                            <ul className='notify-list ps-0'>
                                <li className='list-head'>Push Notifications</li>
                                <li className='list'>Automatically send new notifications</li>
                            </ul>
                        </div>
                    </div>

                    {/* Notification Display */}
                    {notificationData && notificationData.length > 0 ? (
                        <div className="container container-notify">
                            {todayNotifications.length > 0 && (
                                <div className="today-notifications">
                                    <h5>New</h5>
                                    <div className='notify-scroll'>
                                    {todayNotifications.map((notification, index) => (
                                        <div className="card mb-3" key={index}>
                                            <div className={`${notification?.is_read=== false ? 'row no-gutters unread' : 'row no-gutters'}`} onClick={() => readMessage(notification)}>
                                                <div className="col-md-2">
                                                    <span>
                                                        <img src={cardnotify} className="card-img" alt="Notification" />
                                                    </span>
                                                </div>
                                                <div className="col-md-10">
                                                    <div className="card-body p-0">
                                                        <div className="first-sec d-flex justify-content-between align-items-center">
                                                            <h5 className="card-title">{notification?.title}<small className="text-muted"> {timeAgo(notification?.createdAt)}</small></h5>
                                                            <ul className="ps-0 mb-0">
                                                                <li className="text-end">Details <span className="right-arrow"><img src={details} className="card-img" alt="Details" /></span></li>
                                                            </ul>
                                                        </div>
                                                        <div className="second-sec d-flex justify-content-between align-items-center">
                                                            <div className="">
                                                                <p className="card-text d-block">{notification?.message}</p>
                                                                {/* <p className="person-name d-block">with {notification.person}</p> */}
                                                            </div>
                                                            <div className="">
                                                                <ul className="ps-0 mb-0">
                                                                    <li className="text-end">
                                                                        <button className="btn btn-consult"><img src={pin} className="pe-1" alt="Pin" />{notification.linkType}</button>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    </div>
                                </div>
                            )}

                            {earlierNotifications.length > 0 && (
                                <div className="earlier-notifications earlier-default">
                                    <h5>Earlier</h5>
                                    <div className='notify-scroll'>
                                    {earlierNotifications.map((notification, index) => (
                                        <div className="card mb-3" key={index} onClick={() => readMessage(notification)}>
                                            <div className={`${notification?.is_read=== false ? 'row no-gutters unread' : 'row no-gutters'}`}>
                                                <div className="col-md-2">
                                                    <span>
                                                        <img src={cardnotify} className="card-img" alt="Notification" />
                                                    </span>
                                                </div>
                                                <div className="col-md-10">
                                                    <div className="card-body p-0">
                                                        <div className="first-sec d-flex justify-content-between align-items-center">
                                                            <h5 className="card-title">{notification?.title}<small className="text-muted"> {timeAgo(notification?.createdAt)}</small></h5>
                                                            <ul className="ps-0 mb-0">
                                                                <li className="text-end">Details <span className="right-arrow"><img src={details} className="card-img" alt="Details" /></span></li>
                                                            </ul>
                                                        </div>
                                                        <div className="second-sec d-flex justify-content-between align-items-center">
                                                            <div className="">
                                                                <p className="card-text d-block">{notification?.message}</p>
                                                                {/* <p className="person-name d-block">with {notification.person}</p> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="no-notifications">
                            <div className="fav-div-sec">
                                <div className="">
                                    <div className="mb-3">
                                        <img src={notifications} alt="No notifications" />
                                    </div>
                                    <p>You don't have any notifications yet.</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default CreatorNotification;
