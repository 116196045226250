import React from 'react'
import message from '../../../assets/images/msg.svg';
import dummy from '../../../assets/images/dummy-profile.jpg'
function AttendeeList({ attendeeData,userData }) {
  
  return (
    <>
      <div className='attendas-data'>
        <h2>Attendees of your “{userData?.title || userData?.offerService?.title}” session</h2>
        <ul className='ps-0 mb-0'>
        {attendeeData && attendeeData?.length>0 && attendeeData.map((x,index)=>{
        return(
          <li className='d-flex justify-content-between align-items-center'>
            <div className='d-flex'>
            <span><img src={x?.user_profile_image || dummy} alt="img"/>
            </span>
           
            <div class="user-info request ps-2">
              <h3><strong>{x?.name}</strong></h3>
              <h4>{x?.email}</h4>
            </div>
            </div>
            <div className=''>
                <button className='btn message-btn'><img src={message} alt="img"/>Message user</button>
            </div>
          </li>
        )
      })}
        </ul>
      </div>
      {/* {attendeeData && attendeeData?.length>0 && attendeeData.map((x,index)=>{
        return(
            <>
            <img src={x?.user_profile_image} alt=''/>
            {x?.name}
            {'User'}
            <button>Message User</button>
            </>
        )
    })} */}
    </>
  )
}

export default AttendeeList