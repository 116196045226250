import React, { useState, useRef } from 'react';

function GalleryCarousel({ userDetails,startIndex }) {
  const carouselRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalSlides = userDetails?.length ;

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex < totalSlides - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const slides = userDetails.map((x) => ({ type: 'material', src: x.file_url }));

  const reorderedSlides = [
    ...slides.slice(startIndex), // Start from the desired index
    ...slides.slice(0, startIndex), // Add the earlier items at the end
  ];

  return (
    <div className="carousel slide" ref={carouselRef}>
      <div className="carousel-inner">
        {/* {userDetails.cover_image && (
          <div className={`carousel-item ${currentIndex === 0 ? 'active' : ''}`} key="cover-image">
            <img className="d-block w-100" src={userDetails.cover_image} alt="Cover Slide" />
          </div>
        )}
        {userDetails.offerMaterials && userDetails.offerMaterials.map((x, index) => (
          <div
            className={`carousel-item ${currentIndex === (userDetails.cover_image ? index + 1 : index) ? 'active' : ''}`}
            key={index + 1}
          >
            <img className="d-block w-100" src={x?.file_url} alt={`Slide ${index + 1}`} />
          </div>
        ))} */}
        {reorderedSlides.map((slide, index) => (
          <div
            className={`carousel-item ${currentIndex === index ? 'active' : ''}`}
            key={index}
          >
            {slide.type === 'cover' ? (
              <img className="d-block w-100" src={slide.src} alt="Cover Slide" />
            ) : (
              // <img className="d-block w-100" src={slide.src} alt={`Slide ${index + 1}`} />
              slide.src.endsWith('.mp4') ? (
                <video className="d-block w-100" controls>
                  <source src={slide.src} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )
              : (
                <img className="d-block w-100" src={slide.src} alt={`Slide ${index + 1}`} />
              )
            )}
          </div>
        ))}
      </div>

      {/* Conditionally render Previous button */}
      {currentIndex > 0 && (
        <a className="carousel-control-prev" role="button" onClick={handlePrev}>
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        </a>
      )}

      {/* Conditionally render Next button */}
      {currentIndex < totalSlides - 1 && (
        <a className="carousel-control-next" role="button" onClick={handleNext}>
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
        </a>
      )}
    </div>
  );
}

export default GalleryCarousel