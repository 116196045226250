import React, { useEffect, useState } from 'react'
import UniqueOffering from '../../HomeComponent/uniqueOffering'
import DiscoverOffering from '../../HomeComponent/DiscoveringComponent/discoverOffering'
import search from '../../../assets/images/search.svg'
import { getApi, postApi } from '../../../Services/apiService'
import UserSidebar from '../../../Layout/userSidebar'
import burger from '../../../assets/images/burger-menu.svg'
import OfferingData from './offeringData'
import OfferingRecommended from './offeringRecommended'
import Slider from 'react-slick';
import hearts from '../../../assets/images/heart.svg'
import { useNavigate } from 'react-router-dom'
import bannerRight from '../../../assets/images/dummy-profile.jpg'
import arrow from '../../../assets/images/arrow.svg'
import location from '../../../assets/images/map-pin1.svg'
import heart_filled_black from '../../../assets/images/heart_filled_black.svg'
import searchdata from '../../../assets/images/search-data.svg'



function OfferingListing() {

    const [offeringData, setOfferingData] = useState([])
    const [recommendedData, setRecommendedData] = useState([])
    const [classState, setClassState] = useState(false)
    const [loadData, setLoadData] = useState(9);
    const [tags, setTags] = useState([]);
    const [category, setCategory] = useState([]);
    const [selectedTagIds, setSelectedTagIds] = useState([]); // New state for selected tag IDs
    const [selectedCategoryId, setSelectedCategoryId] = useState(null); // Single selected category ID
    const [selectedLocationTypes, setSelectedLocationTypes] = useState([]);
    const [searchText, setSearchText] = useState(''); // New state for search text
    const [shouldFetch, setShouldFetch] = useState(true); // State to trigger fetch based on filters
    const [showSearchResults, setShowSearchResults] = useState(false); // New state for managing search results
    const [, setRender] = useState(false);
    const [currentPage, setCurrentPage] = useState(1); // Current page
    const [totalCount, setTotalCount] = useState(0); // Total count of offerings
    const [pageSize,setPageSize] =useState(9)
    // const pageSize = 9; // Number of offerings per page
    const [loader, setLoader] = useState(false)
    const [pageLoader, setPageLoader] = useState(false)
    const [locationSearch,setLocationSearch] = useState('')


    const navigate = useNavigate();


    const singleService = (id, x) => {
        if (x.service_type === '2') {
            navigate(`/user/offering/product/${id}`)
        }
        else {
            navigate(`/user/offering/${id}`)
        }
    }
    const handleLoadData = () => {
        // setLoader(true)
        if (offeringData.length < totalCount) { // Check if more data is available
            setPageSize(prevSize =>prevSize +9)
            // setCurrentPage(prevPage => prevPage + 1); // Increment the page number
            setShouldFetch(true); // Trigger the fetch function to load more data
            // setLoader(false)

        }
    };



    const fetchTags = async () => {
        const response = await getApi('/user/popular-tag');
        if (response?.statusCode === 200) {
            setTags(response?.data);
        }
    };

    const fetchCategory = async () => {
        const response = await getApi('/user/category');
        if (response?.statusCode === 200) {
            setCategory(response?.data);
        }
    };

    const handleFetchOffering = async () => {
        if (shouldFetch === true) {
            setLoader(true)
        }
        const tagIdsParam = selectedTagIds.join(',');
        const categoryIdParam = selectedCategoryId || '';
        const locationTypeIdsParam = selectedLocationTypes.join(',');
        const searchParam = searchText ? `&search=${encodeURIComponent(searchText)}` : '';

        if (tagIdsParam.length || categoryIdParam !== '' || locationTypeIdsParam.length || searchParam !== '') {
            setShowSearchResults(true); // Show search results section
        }
        else {
            setShowSearchResults(false); // Remove search results section
        }

        try {
            if (offeringData?.length <= 0) {
                setPageLoader(true)
            }
            const response = await getApi(`/user/services?tag_ids=${tagIdsParam}&category_id=${categoryIdParam}&location_type=${locationTypeIdsParam}${searchParam}&page=${currentPage}&pageSize=${pageSize}`);
            if (response?.statusCode === 200) {
                setLoader(false)

                if (currentPage === 1) {
                    setOfferingData(response?.data); // Append new data to existing data
                }
                else {
                    setOfferingData(prevData => [...prevData, ...response?.data]); // Append new data to existing data
                }
                setTotalCount(response?.totalCount); // Set the total count from response
            }
            setPageLoader(false)

            const res = await getApi('/user/recommend');
            if (res?.statusCode === 200) {
                setRecommendedData(res?.data);
            }
        } catch (error) {
            console.error('Error fetching offerings:', error);
        }
    };

    const handleFavourite = async (val) => {
        console.log('this is value', val);

        const data = {
            status: val?.isFavorite === 0 ? '1' : '0', // Conditional check to toggle the favorite status
            offerId: val?.id
        };

        try {
            // Optimistically toggle isFavorite
            val.isFavorite = val.isFavorite === 0 ? 1 : 0;

            // Trigger a re-render to reflect the change in UI
            setRender(prev => !prev);

            const response = await postApi('favorite', data);
            if (response?.statusCode === 200) {
                // Optionally re-fetch the offerings if needed
            }
        } catch (error) {
            console.error('Error fetching offerings:', error);
        }
    };



    useEffect(() => {
        fetchTags();
        fetchCategory();
        setShouldFetch(true); // Initial fetch call
    }, []);

    useEffect(() => {
        if (shouldFetch) {
            handleFetchOffering();
            setShouldFetch(false); // Reset fetch trigger after fetching
        }
    }, [selectedTagIds, selectedCategoryId, selectedLocationTypes, searchText, currentPage, shouldFetch,pageSize,locationSearch]);



    const handleLocationTypeChange = (typeId) => {
        setSelectedLocationTypes(prevState => {
            return prevState.includes(typeId)
                ? prevState.filter(id => id !== typeId)
                : [...prevState, typeId];
        });
        setShouldFetch(true); // Trigger fetch when location type changes
    };



    // useEffect(() => {
    //     fetchTags();
    //     fetchCategory();
    //     handleFetchOffering();

    //     const handleClick = () => setClassState(false);
    //     document.addEventListener('click', handleClick);

    //     return () => {
    //         document.removeEventListener('click', handleClick);
    //     };
    // }, []);



    const handleSearch = () => {
        setShouldFetch(true); // Trigger fetch when search is initiated
    };


    const handleTagClick = (tagId) => {
        let updatedTagIds = [...selectedTagIds];
        if (updatedTagIds.includes(tagId)) {
            updatedTagIds = updatedTagIds.filter(id => id !== tagId);
        } else {
            updatedTagIds.push(tagId);
        }
        setSelectedTagIds(updatedTagIds);
        setShouldFetch(true); // Trigger fetch when tags change
    };

    const handleCategoryClick = (categoryId) => {
        setSelectedCategoryId(prevCategoryId => prevCategoryId === categoryId ? null : categoryId);
        setShouldFetch(true); // Trigger fetch when category changes
    };

    const handleSidebarClick = (event) => {
        event.stopPropagation();
        setClassState(!classState);
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 1500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    const [suggestions, setSuggestions] = useState([]); // Dynamic suggestions
    const [isLoading, setIsLoading] = useState(false); // Loader state

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent default behavior
            handleSearch(); // Call the search function
        }
    };

    const fetchSuggestions = async (query) => {
        if (!query) {
            setSuggestions([]);
            return;
        }

        setIsLoading(true); // Show loading indicator

        try {
            // Mock API call for suggestions
            const response = await getApi(`/user/services?search=${query}`);
            const data = await response.json();
            setSuggestions(data || []);
        } catch (error) {
            console.error("Error fetching suggestions:", error);
        } finally {
            setIsLoading(false); // Hide loading indicator
        }
    };

    useEffect(() => {
        // Trigger suggestions when searchText changes
        const delayDebounceFn = setTimeout(() => {
            fetchSuggestions(searchText);
        }, 300); // Debounce for 300ms

        return () => clearTimeout(delayDebounceFn); // Clear timeout on unmount or new input
    }, [searchText]);

    const handleSuggestionClick = (suggestion) => {
        setSearchText(suggestion); // Set clicked suggestion to input
        setSuggestions([]); // Hide suggestions
        handleSearch(); // Optionally perform a search
    };

    return (
        <div className="creators-detail offering-user-side">
            <UserSidebar classState={classState} />
            <div className='creators right-main min-height-pera creator-tab discover-offer-cs'>
                <section className="discover-top">

                    <div className="section mt-0">
                        <h1><button className="burger-menu" onClick={handleSidebarClick}><img src={burger} alt='' /></button>Discover</h1>
                        <div className="creators-input">
                            <img src={search} alt='' />
                            <input
                                type="text"
                                className="search"
                                placeholder="What can we help you find?"
                                value={searchText}
                                onChange={(e) => {
                                    setSearchText(e.target.value); // Update search text state
                                    if (e.target.value === '') {
                                        setShouldFetch(true); // Trigger API call when the input is cleared
                                    }
                                }}
                  onKeyDown={handleKeyDown}

                                />
                            <button onClick={handleSearch} className="search-btn">Search</button> {/* Search button */}

                            {/* Suggestions Dropdown */}
            {suggestions.length > 0 && (
                <ul className="suggestions-list">
                    {suggestions.map((suggestion, index) => (
                        <li
                            key={index}
                            className="suggestion-item"
                            onClick={() => handleSuggestionClick(suggestion)}
                        >
                            {suggestion}
                        </li>
                    ))}
                </ul>
            )}
                        </div>
                    </div>

                    <div className="links">
                        <h3>Popular tags</h3>
                        <ul>
                            {tags && tags.length > 0 && tags.map((tag, index) => (
                                <li key={index}>
                                    <a
                                        onClick={() => handleTagClick(tag.id)}
                                        className={selectedTagIds.includes(tag.id) ? 'active' : ''}
                                    >
                                        {tag?.tag_name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>



                </section>

                <section className="discover category">

                    <h3>Category</h3>
                    {/* <UniqueOffering /> */}
                    <ul >
                        {category && category.length > 0 && category.map((cat, index) => (
                            <li
                                key={index}
                                onClick={() => handleCategoryClick(cat.id)}
                                className={selectedCategoryId === cat.id ? 'active' : ''}
                            >
                                <img src={cat?.category_image} alt='' />
                                <h4>{cat?.category_name}</h4>
                            </li>
                        ))}
                    </ul>

                </section>

                <div className="what-are-you">
                    <p>What are you looking for</p>
                    <ul>
                        <li>
                            <label className="container2">
                                Virtual Session
                                <input
                                    type="checkbox"
                                    checked={selectedLocationTypes.includes(1)}
                                    onChange={() => handleLocationTypeChange(1)}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </li>
                        <li>
                            <label className="container2">
                                In Person Session
                                <input
                                    type="checkbox"
                                    checked={selectedLocationTypes.includes(2)}
                                    onChange={() => handleLocationTypeChange(2)}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </li>
                        <li>
                            <label className="container2">
                                Physical product
                                <input
                                    type="checkbox"
                                    checked={selectedLocationTypes.includes(3)}
                                    onChange={() => handleLocationTypeChange(3)}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </li>
                        <li>
                            <label className="container2">
                                Digital product
                                <input
                                    type="checkbox"
                                    checked={selectedLocationTypes.includes(4)}
                                    onChange={() => handleLocationTypeChange(4)}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </li>
                    </ul>
                </div>
                {/* <div className='divs'>
                            <label className="container2">
                                Select Your Location
                                <div className='form-group mt-0' >
                          
                         
                          </div>
                          <input type="text" placeholder="Select" name="" class="form-control daily-weeks-selct-input" value={locationSearch} onChange={(e)=>setLocationSearch(e?.target?.value)}/>
                            </label>
                            </div> */}

                {pageLoader ?
                    <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    :
                    <>
                        {offeringData.length ? (
                            <>
                                {showSearchResults ? (
                                    <section className="other-class-main">

                                        <h3>Search Results</h3>
                                        {/* <OfferingData data={offeringData} type='offering' /> */}

                                        <div className="row">
                                            {offeringData && offeringData.length > 0 && offeringData.map((x, index) => {
                                                return (
                                                    <div className="col-sm-4" key={index} >
                                                        <div className="product-box" >
                                                            <div className="product-img" onClick={() => singleService(x?.id, x)} key={index}>
                                                                <img src={x?.cover_image} alt='' />
                                                                <div className="product-image-data">
                                                                    {x?.service_type === "1" && (
                                                                        <span><img src={x?.category && x?.category?.category_image} alt='' /></span>
                                                                    )}
                                                                    <h5>
                                                                        {x?.service_type === "2"
                                                                            ? (x?.product_kind === "0" ? 'Physical Product' : 'Digital Product')
                                                                            : (x?.service_type === "1"
                                                                                ? (x?.location_type === "1" ? 'Virtual'
                                                                                    : (x?.location_type === "2" ? 'In Person'
                                                                                        // : (x?.location_type === "2" ? 'Offline'
                                                                                        : (x?.location_type === "3" ? 'Virtual/In Person' : '')))
                                                                                : 'In Person')}
                                                                        {/* : 'Offline')} */}
                                                                    </h5>
                                                                    {x?.address !== null && <h6><img src={location} alt='' />{x?.address?.city + ', ' + x?.address?.country}</h6>}
                                                                    {/* <h6><img src={location} alt='' />Austin, Texas</h6> */}
                                                                </div>
                                                            </div>
                                                            {/* <button className="heart-btn" onClick={() => { handleFavourite(x) }}>
                                                        <img src={x?.isFavorite === 1 ? heart_filled_black : hearts} alt='' />
                                                    </button> */}
                                                            <button
                                                                className={`heart-btn ${x?.isFavorite === 1 ? "heart-filled" : "heart-outline"}`}
                                                                onClick={() => handleFavourite(x)}
                                                            >
                                                                <img src={x?.isFavorite === 1 ? heart_filled_black : hearts} alt='' />
                                                            </button>
                                                            <div className="product-data" onClick={() => singleService(x?.id, x)} key={index}>
                                                                <h3>{x?.name} </h3>
                                                                <ul>
                                                                    {x?.tags && x?.tags.length > 0 && x?.tags.slice(0, 3).map((y, i) => {
                                                                        return (
                                                                            <li className={i === 0 && "active"} key={i}>{y?.tag_name}</li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                                <p>
                                                                    {x?.description}
                                                                </p>
                                                                <div className="product-data-bottom">
                                                                    <span><img src={x?.user?.user_profile_image && x?.user?.user_profile_image || bannerRight} alt='' /></span>
                                                                    <h6><strong>{x?.user && x?.user?.name}</strong> {x?.user?.specializations && x?.user?.specializations[0]?.area_of_work}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        {/* {type === 'offering' && */}
                                        {offeringData.length < totalCount && (

                                            <button className='explore' onClick={handleLoadData} disabled={loader}>
                                                {loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}

                                                Load more</button>

                                        )}

                                    </section>


                                ) : (
                                    <>

                                        {recommendedData && recommendedData.length < 0 && (

                                            <section className="other-class-main">
                                                <h3>Recommended Classes</h3>
                                                <button className="see-all">See all offers<img src={arrow} alt='' /></button>

                                                {/* <OfferingRecommended data={recommendedData} type='recommended'/> */}

                                                <div>
                                                    <Slider {...settings}>
                                                        {recommendedData && recommendedData.length > 0 && recommendedData.map((x, index) => {
                                                            return (

                                                                <div className="col-sm-4 p-2" key={index}>
                                                                    <div className="product-box">
                                                                        <div className="product-img" onClick={() => singleService(x?.id, x)} key={index}>
                                                                            <img src={x?.cover_image} alt='' />
                                                                            <div className="product-image-data">
                                                                                {x?.service_type === "1" && (
                                                                                    <span><img src={x?.category && x?.category?.category_image} alt='' /></span>
                                                                                )}
                                                                                <h5>
                                                                                    {x?.service_type === "2"
                                                                                        ? (x?.product_kind === "0" ? 'Physical Product' : 'Digital Product')
                                                                                        : (x?.service_type === "1"
                                                                                            ? (x?.location_type === "1" ? 'Virtual'
                                                                                                : (x?.location_type === "2" ? 'In Person'
                                                                                                    : (x?.location_type === "3" ? 'Virtual/In Person' : '')))
                                                                                            : 'In Person')}
                                                                                </h5>
                                                                                {x?.address !== null && <h6><img src={location} alt='' />{x?.address?.city + ', ' + x?.address?.country}</h6>}
                                                                                {/* <h6><img src={location} alt='' />Austin, Texas</h6> */}
                                                                            </div>
                                                                        </div>
                                                                        {/* <button className="heart-btn" onClick={() => { handleFavourite(x) }}>
                                                                            <img src={x?.isFavorite === 1 ? heart_filled_black : hearts} alt='' />
                                                                        </button> */}
                                                                        <button
                                                                            className={`heart-btn ${x?.isFavorite === 1 ? "heart-filled" : "heart-outline"}`}
                                                                            onClick={() => handleFavourite(x)}
                                                                        >
                                                                            <img src={x?.isFavorite === 1 ? heart_filled_black : hearts} alt='' />
                                                                        </button>
                                                                        <div className="product-data" onClick={() => singleService(x?.id, x)} key={index}>
                                                                            <h3>{x?.name} </h3>
                                                                            <ul >
                                                                                {x?.tags && x?.tags.length > 0 && x?.tags.slice(0, 3).map((y, i) => {
                                                                                    return (
                                                                                        <li className={i === 0 && "active"} key={i}>{y?.tag_name}</li>
                                                                                    )
                                                                                })}
                                                                            </ul>
                                                                            <p>
                                                                                {x?.description}
                                                                            </p>
                                                                            <div className="product-data-bottom">
                                                                                <span><img src={x?.user?.user_profile_image && x?.user?.user_profile_image || bannerRight} alt='' /></span>
                                                                                <h6><strong>{x?.user && x?.user?.name}</strong> {x?.user?.specializations && x?.user?.specializations[0]?.area_of_work}</h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </Slider>
                                                </div>
                                            </section>

                                        )}


                                        <section className="other-class-main">

                                            <h3>Offerings</h3>
                                            {/* <OfferingData data={offeringData} type='offering' /> */}
                                            <div className='data-scroll-offer'>
                                                <div className="row">
                                                    {offeringData && offeringData.length > 0 && offeringData.map((x, index) => {
                                                        return (
                                                            <div className="col-sm-4" key={index} >
                                                                <div className="product-box">
                                                                    <div className="product-img" onClick={() => singleService(x?.id, x)} key={index}>
                                                                        <img src={x?.cover_image} alt='' />
                                                                        <div className="product-image-data">
                                                                            {x?.service_type === "1" && (
                                                                                <span><img src={x?.category && x?.category?.category_image} alt='' /></span>
                                                                            )}
                                                                            <h5>
                                                                                {x?.service_type === "2"
                                                                                    ? (x?.product_kind === "0" ? 'Physical Product' : 'Digital Product')
                                                                                    : (x?.service_type === "1"
                                                                                        ? (x?.location_type === "1" ? 'Virtual'
                                                                                            : (x?.location_type === "2" ? 'In Person'
                                                                                                : (x?.location_type === "3" ? 'Virtual/In Person' : '')))
                                                                                        : 'In Person')}
                                                                            </h5>
                                                                            {x?.address !== null && <h6><img src={location} alt='' />{x?.address?.city + ', ' + x?.address?.country}</h6>}
                                                                            {/* <h6><img src={location} alt='' />Austin, Texas</h6> */}
                                                                        </div>
                                                                    </div>
                                                                    {/* <button className="heart-btn" onClick={() => { handleFavourite(x) }}>
                                                                        <img src={x?.isFavorite === 1 ? heart_filled_black : hearts} alt='' />
                                                                    </button> */}
                                                                    <button
                                                                        className={`heart-btn ${x?.isFavorite === 1 ? "heart-filled" : "heart-outline"}`}
                                                                        onClick={() => handleFavourite(x)}
                                                                    >
                                                                        <img src={x?.isFavorite === 1 ? heart_filled_black : hearts} alt='' />
                                                                    </button>
                                                                    <div className="product-data" onClick={() => singleService(x?.id, x)} key={index}>
                                                                        <h3 className='mt-3'>{x?.name} </h3>
                                                                        <ul className='product-tag-overflow'>
                                                                            {x?.tags && x?.tags.length > 0 && x?.tags.slice(0, 3).map((y, i) => {
                                                                                return (
                                                                                    <li className={i === 0 && "active"} key={i}>{y?.tag_name}</li>
                                                                                )
                                                                            })}
                                                                        </ul>
                                                                        <p>
                                                                            {x?.description}
                                                                        </p>
                                                                        <div className="product-data-bottom">
                                                                            <span><img src={x?.user?.user_profile_image && x?.user?.user_profile_image || bannerRight} alt='' /></span>
                                                                            <h6><strong>{x?.user && x?.user?.name}</strong> {x?.user?.specializations && x?.user?.specializations[0]?.area_of_work}</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            {/* {type === 'offering' && */}
                                            {offeringData.length < totalCount && (

                                                <button className="explore" onClick={handleLoadData} disabled={loader}>
                                                    {loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}

                                                    Load more</button>
                                            )}


                                        </section>
                                    </>
                                )
                                }
                            </>
                        ) : (
                            <>
                                <div className='fav-div-sec search-user'>
                                    <div className=''>
                                        <div className='mb-3'>
                                            <img src={searchdata} alt='' />
                                        </div>
                                        <p>Sorry, no tags found matching your search.</p>
                                    </div>
                                </div>
                            </>
                        )
                        }
                    </>
                }

                {/* <DiscoverOffering /> */}
            </div>
        </div>
    )
}

export default OfferingListing