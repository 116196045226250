import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from '../Component/HomeComponent/home';
import Layout from '../Layout';
import LoginPage from '../Pages/login';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrivateRoute from '../Utils/privateRoute';
import Creator from '../Component/HomeComponent/CreatorComponent/creator';
import SingleDetail from '../Component/HomeComponent/CreatorComponent/singleDetail';
import Discover from '../Component/HomeComponent/DiscoveringComponent/discover';
import DiscoverDetail from '../Component/HomeComponent/DiscoveringComponent/discoverDetail';
import MultiStep from '../Component/CreatorComponents/CreatorJoin/multiStep';
import UserMultiStep from '../Component/UserComponents/UserJoin/userMultistep'
import CreatorHome from '../Component/CreatorComponents/DashboardCreator/creatorHome';
import DetailCreator from '../Component/CreatorComponents/DashboardCreator/detailCreator';
import UserHome from '../Component/UserComponents/DashboardUser/userHome';
import UserDetail from '../Component/UserComponents/DashboardUser/userDetail';
import OfferingHome from '../Component/CreatorComponents/CreatorOffering/offeringHome';
import OfferingActive from '../Component/CreatorComponents/CreatorOffering/offeringActive';
import NewOffering from '../Component/CreatorComponents/CreatorOffering/newOffering';
import DefineOffering from '../Component/CreatorComponents/CreatorOffering/defineOffering';
import Members from '../Component/AdminComponents/members';
import AvailabilityOffering from '../Component/CreatorComponents/CreatorOffering/availabilityOffering';
import DailyOffering from '../Component/CreatorComponents/CreatorOffering/dailyOffering';
import Requests from '../Component/AdminComponents/requests';
import Settings from '../Component/AdminComponents/settings';
import AdminAvailability from '../Component/AdminComponents/availability'
import SessionService from '../Component/CreatorComponents/CreatorOffering/sessionService';
import SessionServiceWhere from '../Component/CreatorComponents/CreatorOffering/sessionServiceWhere';
import SessionDiscovery from '../Component/CreatorComponents/CreatorOffering/sessionDiscovery';
import AvailabilityOffer from '../Component/CreatorComponents/CreatorOffering/availabilityOffer';
import Basics from '../Component/CreatorComponents/CreatorSetting/basics';
import SessionServiceCharge from '../Component/CreatorComponents/CreatorOffering/sessionServiceCharge';
import OfferingStep from '../Component/CreatorComponents/CreatorOffering/offeringStep';
import OfferingListing from '../Component/UserComponents/UserDiscovery/offeringListing';
import SingleOfferingData from '../Component/UserComponents/UserDiscovery/singleOfferingData';
import AdminMultiStep from '../Component/AdminComponents/adminMultistep';
import CreatorOfferingHome from '../Component/CreatorComponents/CreatorOffering/creatorOfferingHome';
import AllCreatorList from '../Component/CreatorComponents/DashboardCreator/allCreatorList';
import ProductOfferingStep from '../Component/CreatorComponents/CreatorOffering/CreateProduct/productOfferingStep';
import DeliverPickupProductOffering from '../Component/CreatorComponents/CreatorOffering/CreateProduct/deliverPickup';
import CreatorSingleOfferingData from '../Component/CreatorComponents/CreatorOffering/creatorSingleOfferingData';
import ChangePassword from '../Component/AuthComponent/ResetPassword/changePassword';
import ProductDetailOffering from '../Component/CreatorComponents/CreatorOffering/CreateProduct/productDetailOffering';
import SingleProductView from '../Component/CreatorComponents/CreatorOffering/CreateProduct/singleProductView';
import BecomeCreator from '../Component/UserComponents/DashboardUser/becomeCreator';
import TimePicker from '../Component/CreatorComponents/CreatorOffering/CreateProduct/testing';
import AdminOffering from '../Component/AdminComponents/Offering/adminOffering';
import AdminOfferingStep from '../Component/AdminComponents/Offering/adminOfferingStep';
import UserSingleProductView from '../Component/UserComponents/UserDiscovery/userSingleProductView';
import CreatorChat from '../Component/CreatorComponents/CreatorChat/creatorChat';
import UserFavourite from '../Component/UserComponents/UserFavourite/userFavourite';
import ChatRoom from '../Component/ChatComponent/chatRoom';
import Orders from '../Component/CreatorComponents/CreatorOrder/orders';
import List from '../Component/UserComponents/CreatorListing/list';
import SingleDetailList from '../Component/UserComponents/CreatorListing/singleDetailList';
import Processing from '../Component/CreatorComponents/CreatorOrder/processing';
import SessionList from '../Component/CreatorComponents/CreatorSession/sessionList';
import AdminOrders from '../Component/AdminComponents/Orders/adminOrders';
import AdminBooking from '../Component/AdminComponents/Bookings/adminBooking';
import EditOffering from '../Component/CreatorComponents/CreatorOffering/editOffering';
import EditProduct from '../Component/CreatorComponents/CreatorOffering/CreateProduct/editProduct';
import Journey from '../Component/CreatorComponents/CreatorOffering/CreatorJourney/journey';
import CreatorNotification from '../Component/CreatorComponents/CreatorNotification/notification';
import AdminOrderDetails from '../Component/AdminComponents/Orders/adminOrderDetails';
import AdminNotification from '../Component/AdminComponents/Notifications/adminNotification';
import UserNotification from '../Component/UserComponents/UserNotifications/userNotifications';
import SingleBooking from '../Component/AdminComponents/Bookings/singleBooking';

export default function Routing() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        {/* <Route path='/creator/login' element={<Login />} /> */}
        <Route path='/creator/login' element={<MultiStep />} />
        <Route path='/user/login' element={<UserMultiStep />} />
        <Route path='/admin/add-user' element={<AdminMultiStep />} />
        {/* <Route path='/creator/login' element={<Login />} />
        <Route path='/creator/detail' element={<SignUpDetail />} />
        <Route path='/creator/about-detail' element={<AboutDetail />} />
        <Route path='/creator/book-interview' element={<BookInterview />} />
        <Route path='/creator/submit' element={<SubmitForm />} /> */}

        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='/creator' >
            <Route index element={<Creator />} />
            <Route path='/creator/:id' element={<SingleDetail />} />
          </Route>
          <Route path='/discovering'>
            <Route index element={<Discover />} />
            <Route path='/discovering/:id' element={<DiscoverDetail />} />
          </Route>
          {/* <Route index element={<PrivateRoute  component={Home} />} /> */}
          {/* <Route path="/about" element={<PrivateRoute  component={About} />} /> */}
        </Route>
        {/* <Route path='/creator/dashboard'> */}
          <Route path='/user/become-creator' element={<BecomeCreator/>}/>
          <Route path='/creator/home' element={<PrivateRoute  component={CreatorHome} />}/>
          {/* <Route path='/creator/home' element={<CreatorHome/>}/> */}
          <Route path='/creator/dashboard' element={<PrivateRoute  component={DetailCreator} />}/>
          <Route path='/creator/allCreator' element={<PrivateRoute  component={AllCreatorList} />}/>
          <Route path='/creator/offering/home' element={<PrivateRoute  component={CreatorOfferingHome}/>} />
          <Route path='/creator/offering/:id' element={<PrivateRoute  component={CreatorSingleOfferingData}/>} />
          <Route path='/creator/offering/product/:id' element={<PrivateRoute  component={SingleProductView}/>} />
          <Route path='/creator/offering' element={<PrivateRoute  component={OfferingStep}/>} />
          <Route path='/creator/offering/product' element={<PrivateRoute  component={ProductOfferingStep}/>} />
          <Route path='/creator/offering/product2' element={<PrivateRoute  component={TimePicker}/>} />
          <Route path='/testing' element={<PrivateRoute  component={UserFavourite}/>} />
          <Route path='/user/favourites' element={<PrivateRoute  component={UserFavourite}/>} />
          <Route path='/user/favourites/offering-details/:id' element={<PrivateRoute  component={SingleOfferingData}/>} />
          <Route path='/user/favourites/product-details/:id' element={<PrivateRoute  component={UserSingleProductView}/>} />
          <Route path='/user/offering' element={<PrivateRoute  component={OfferingListing}/>} />
          <Route path='/user/offering/:id' element={<PrivateRoute  component={SingleOfferingData}/>} />
          <Route path='/user/offering/product/:id' element={<PrivateRoute  component={UserSingleProductView}/>} />
          <Route path='/creator/message' element={<PrivateRoute  component={CreatorChat}/>} />
          <Route path='/creator/messages' element={<PrivateRoute  component={ChatRoom}/>} />
          <Route path='/user/messages' element={<PrivateRoute  component={ChatRoom}/>} />
          <Route path='/user/creators' element={<PrivateRoute  component={List}/>} />
          <Route path='/creator/detail/:id' element={<PrivateRoute  component={SingleDetailList}/>} />
          <Route path='/creator/session' element={<PrivateRoute  component={SessionList}/>} />
          <Route path='/creator/edit-offering' element={<PrivateRoute  component={EditOffering}/>} />
          <Route path='/creator/edit-product' element={<PrivateRoute  component={EditProduct}/>} />
          <Route path='/creator/notification' element={<PrivateRoute  component={CreatorNotification}/>} />
          <Route path='/user/notifications' element={<UserNotification/>}/>
          {/* <Route path='/creator/offering' element={<OfferingHome/>} />
          <Route path='/creator/offering/list' element={<OfferingActive/>} />
          <Route path='/creator/offering/new' element={<NewOffering/>} />
          <Route path='/creator/offering/define' element={<DefineOffering/>} />
          <Route path='/creator/offering/availability' element ={<AvailabilityOffering/>} /> */}


          <Route path='/creator/offering/session' element ={<SessionService/>} />
          <Route path='/creator/offering/sessionwhere' element ={<SessionServiceWhere/>} />
          <Route path='/creator/offering/sessioncharge' element ={<SessionServiceCharge/>} />
          <Route path='/creator/offering/sessiondiscovery' element ={<SessionDiscovery/>} />
          <Route path='/change-password/:id' element={<ChangePassword/>}/>


          <Route path='/creator/orders' element ={<PrivateRoute  component={Orders} />} />
          {/* <Route path='/creator/orders' element ={<PrivateRoute  component={Processing} />} /> */}
          <Route path='/creator/offering/daily' element ={<DailyOffering/>} />
          <Route path='/creator/settings' element={<PrivateRoute  component={Basics} />}/>
          <Route path='/creator/settings/:card' element={<PrivateRoute  component={Basics} />}/>
          <Route path='/user/settings' element={<PrivateRoute  component={Basics} />}/>
          <Route path='/user/journey' element={<PrivateRoute  component={Journey} />}/>
          <Route path='/user/home' element={<PrivateRoute  component={UserHome} />}/>
          <Route path='/user/dashboard' element={<PrivateRoute  component={UserDetail} />}/>
          <Route path='/admin/members' element={<Members/>}/>
          <Route path='/admin/request' element={<Requests/>}/>
          <Route path='/admin/notification' element={<AdminNotification/>}/>
          <Route path='/admin/availability' element={<AdminAvailability/>}/>
          <Route path='/admin/orders' element={<AdminOrders/>}/>
          <Route path='/admin/orders/details/:id' element={<AdminOrderDetails/>}/>
          <Route path='/admin/booking' element={<AdminBooking/>}/>
          <Route path='/admin/single-booking/:id' element={<SingleBooking/>}/>
          <Route path='/admin/settings' element={<Settings/>}/>
          <Route path='/admin/offering' element={<AdminOffering/>}/>
          <Route path='/admin/offering/create' element={<PrivateRoute  component={AdminOfferingStep}/>} />
          <Route path='/admin/add-member' element={<PrivateRoute  component={AdminMultiStep}/>} />
          <Route path='/admin/creator/offering/:id' element={<PrivateRoute  component={CreatorSingleOfferingData}/>} />
          <Route path='/admin/creator/offering/product/:id' element={<PrivateRoute  component={SingleProductView}/>} />


         {/* </Route> */}
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
}
